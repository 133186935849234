import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Functions} from '../../../services/functions';
import {UserService} from '../../../services/user.service';
import {ConstService} from '../../../services/const-service';
import {FormBuilder, Validators} from '@angular/forms';
import {TextService} from '../../../services/text.service';
import {FbService} from '../../../services/fb.service';
import {SequencesService} from '../../../services/sequences.service';

@Component({
  templateUrl: './rule-dialog.component.html',
  styleUrls: ['./rule-dialog.component.scss']
})

export class RuleDialogComponent implements OnInit {
  public errors: any = {};
  public conversationList: any;
  public submitForm = this.fb.group({
    name: ['', [Validators.required]],
    status: ['', [Validators.required]],
    conversation: ['', [Validators.required]],
    timeFrame: ['', [Validators.required]],
  });
  public ruleData = {
    publicId: this.userService.currentUser.id,
    funnelId: null,
    ruleId: undefined,
    conversationId: null,
    days: null,
    pageId: null,
    pageToken: null,
    ruleStatus: true,
    ruleName: null,
  };

constructor(public userService: UserService,
              public fb: FormBuilder,
              public constService: ConstService,
              public textService: TextService,
              public sequencesService: SequencesService,
              public fbService: FbService,
              public ref: ChangeDetectorRef,
              public functions: Functions,
              public matDialog: MatDialog,
              public dialogRef: MatDialogRef<RuleDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit(): void {
    this.userService.dialogLoader = true;
    this.fbService.getConversationsNames(this.userService.currentUser.id, this.data.sequence.pageId).subscribe((data) => {
      this.conversationList = data;
      this.userService.dialogLoader = false;
      if (this.data.rule) {
        this.setData();
      } else {
        this.submitForm.controls.status.setValue(true);
      }
    });
  }

  public saveRule() {
    if (this.checkForm()) {
      this.getDataToSave();
      this.sequencesService.saveRule(this.ruleData).subscribe( res => {
        if (res.isSuccess) {
          this.userService.showNotification = true;
          this.userService.notificationTitle = this.textService.currentText.success;
           this.userService.notificationMessage = this.textService.currentText.saveRuleSuccessText;
           this.dialogRef.close('getNewData');
        }
      });
    }
  }

  public setData() {
    this.submitForm.controls.name.setValue(this.data.rule.ruleName);
    this.submitForm.controls.status.setValue(this.data.rule.ruleStatus);
    for (let i = 0; this.conversationList.length > i; i++) {
      if (this.conversationList[i].conversationId === this.data.rule.conversationId) {
        this.submitForm.controls.conversation.setValue(this.conversationList[i]);
      }
    }
    for (let i = 0; this.constService.selectsDays.length > i; i++) {
      if (this.constService.selectsDays[i].value === this.data.rule.days) {
        this.submitForm.controls.timeFrame.setValue(this.constService.selectsDays[i]);
      }
    }
  }


  public getDataToSave() {
    this.ruleData.ruleStatus = this.submitForm.controls['status'].value,
    this.ruleData.ruleName = this.submitForm.controls['name'].value,
    this.ruleData.conversationId = this.submitForm.controls['conversation'].value.conversationId;
    this.ruleData.days = this.submitForm.controls['timeFrame'].value.value;
    this.ruleData.pageId = this.data.sequence.pageId;
    this.ruleData.pageToken = this.data.sequence.pageToken;
    this.ruleData.funnelId = this.data.sequence.funnelId;
    if (this.data.rule) {
      this.ruleData.ruleId = this.data.rule.ruleId;
    }
}

  public checkForm(): boolean {
    if (!this.submitForm.valid) {
      if (!this.submitForm.controls.name.valid) {
        this.errors.name = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.conversation.valid) {
        this.errors.conversation = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.timeFrame.valid) {
        this.errors.timeFrame = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      return false;
    }
    return this.submitForm.valid;
  }

  private displayError(message?: string): void {
    if (!this.submitForm.controls.name.valid) {
      this.errors.name = true;
      const old_value = this.submitForm.value.name;
      this.submitForm.patchValue({
        name: message
      });
      this.submitForm.controls['name'].disable();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.conversation.valid) {
      this.errors.conversation = true;
      this.resetErrorState('empty');
      return;
    }
    if (!this.submitForm.controls.timeFrame.valid) {
      this.errors.timeFrame = true;
      this.resetErrorState('empty');
      return;
    }
  }


  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.name) {
        this.submitForm.patchValue({
          name: val,
        });
        this.errors.name = false;
        this.submitForm.controls['name'].enable();
        return;
      }
      if (this.errors.conversation) {
        this.errors.conversation = false;
      }
      if (this.errors.timeFrame) {
        this.errors.timeFrame = false;
      }
    }, 2000);
  }
}
