import { Component, OnInit, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { EChartOption } from 'echarts';
import { debounceTime } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { StorageBrowser } from 'src/app/services/storage.browser';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html'
})
export class BarchartComponent implements OnInit {
  @Input() barChartData: any;
  barChartValue: any = {
    time: '',
    units: '0',
    ppc: '0',
    promotions: '0',
    profit: '0',
    roi: '0',
    refunds: '0',
    cost: '0',
    revenue: '0',
    margin: '0',
    tACoS : '0',
    ppcExpenses : '0'
  };
  // @ViewChild('matrixsSel', { static: true }) matrixsSel: MatSelect;
  // selectMatrix = new FormControl();
  barMaxWidth = 10;
  // allSelected = false;
  selected: any;
  echartPPUBarChangeOptions: any;
  echartPPUBarOptions: EChartOption = {
    color: ['rgba(77,153,255,0.49)', 'rgba(77,255,178,0.49)', 'rgba(77,107,255,0.49)', '#f48229', '#ff7aac', '#d9df20', '#5792cc', '#8bc53e', '#6bf7aa','#343a40', '#dc3545'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: ['Units Sold', 'PPC Units', 'Promotions Units', 'Profit', 'Revenue', 'Cost', 'Margin', 'TACOS', 'Roi', 'Refunds', 'PPC Expenses' , 'Organic Sales'],
      // data: ['Units Sold', 'PPC Units', 'Promotions Units', 'Profit'],
      left: window.innerWidth > 767 ? '350px' : '',
      selected: this.storageBrowser.get('Legend') !== null ? this.storageBrowser.get('Legend') : {
        'Revenue': false,
        'Cost': false,
        'Margin': false,
        'Roi': false,
        'Refunds': false,
        'PPC Expenses' : false,
        'TACOS' : false,
        'Organic Sales' : false
      }
    },
    calculable: true,
    grid: {
      left: window.innerWidth > 767 ? '350px' : '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: [],

        axisLine: {
          lineStyle: {
            // color: "rgba(36, 56, 83, 0.5)"
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'red' // color at 0% position
              }],
              global: false // false by default
            }
          },
        }
      },
      {
        type: 'category',
        axisTick: { show: false },
        data: [],
        axisLine: {
          lineStyle: {
            // color: "rgba(36, 56, 83, 0.5)"
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'red' // color at 0% position
              }],
              global: false // false by default
            }

          },
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: "rgba(36, 56, 83, 0.5)"
          },
        },
        axisLabel: {
          formatter: function (value) {
            return value >= 1000 ? value / 1000 + 'k' : value;
          },
          textStyle: {
            color: function (value, index) {
              return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
            }
          }
        },
        splitLine: {
          show: false
        },
        name: 'Units Sold',
        nameGap: 37,
        nameLocation: 'center'
      },
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: "rgba(36, 56, 83, 0.5)"
          }
        },
        axisLabel: {
          // formatter: function (value) {
          //   return value >= 1000 ? '$' + value / 1000 + 'k' : '$' + value;
          // },
          // textStyle: {
          //   color: function (value, index) {
          //     return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
          //   }
          // },
        },
        name: 'Profit',
        nameGap: 45,
        nameRotate: -90,
        nameLocation: 'center',
      }
    ],
    dataZoom: [
      {
        type: "inside"
      }
    ],
    //   toolbox: {
    //     feature: {
    //         //dataView: {show: true, readOnly: false, title:"Data View"},
    //         magicType: {show: true, type: ['line', 'bar'], title:{"line":"Line","bar":"Bar"}},
    //         restore: {show: true, title:"Restore"},
    //         saveAsImage: {show: true, title:"Download"}
    //     }
    // },
    series: [
      {
        name: 'Units Sold',
        type: 'bar',
        data: [],
        smooth: true,
        showSymbol: false,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth
      },
      {
        name: 'PPC Units',
        type: 'bar',
        data: [],
        smooth: true,
        showSymbol: false,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth
      },
      {
        name: 'Promotions Units',
        type: 'bar',
        barGap: '-100%',  // this changed
        //barCategoryGap: '30%', // this changed
        data: [],
        smooth: true,
        showSymbol: false,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth
      },
      {
        name: 'Profit',
        type: 'line',
        smooth: true,
        showSymbol: false,
        data: [],
        yAxisIndex: 1,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth,
        lineStyle: { width: 3 }
      }
    ]
  };
  currencySymbol: any;
  constructor(public storageBrowser: StorageBrowser, public mixpanelService: MixpanelService) {
    this.currencySymbol = this.storageBrowser.get('$Currency');
    this.mixpanelService.init(null);
  }

  ngOnInit() {
    if (this.barChartData) {
      // this.selectMatrix.setValue(['sales', 'ppc', 'promotion', 'profit']);
      this.echartPPUBarChangeOptions = JSON.parse(JSON.stringify(this.echartPPUBarOptions));
      this.echartPPUBarOptions.xAxis[0].data = this.barChartData.time.map(item => moment(item).format("MMM D, YYYY"));
      // this.echartPPUBarOptions.series[0].data = this.barChartData.units;
      // this.echartPPUBarOptions.series[1].data = this.barChartData.ppc;
      // this.echartPPUBarOptions.series[2].data = this.barChartData.promotions;
      // this.echartPPUBarOptions.series[3].data = this.barChartData.profit;
      this.echartPPUBarOptions.series = [
        this.getBarChartData('Units Sold', this.barChartData.units),
        this.getBarChartData('PPC Units', this.barChartData.ppc),
        this.getBarChartData('Promotions Units', this.barChartData.promotions),
        this.getLineChartData('Profit', this.barChartData.profit),
        this.getLineChartData('Roi', this.barChartData.roi),
        this.getBarChartData('Refunds', this.barChartData.refunds),
        this.getLineChartData('Cost', this.barChartData.cost),
        this.getLineChartData('Revenue', this.barChartData.revenue),
        this.getBarChartData('Margin', this.barChartData.margin),
        this.getBarChartData("TACOS",this.barChartData.tACoS),
        this.getLineChartData('PPC Expenses', this.barChartData.ppcExpenses),
        this.getLineChartData('Organic Sales', this.barChartData.organicSales)
      ];
      let currency_name = this.storageBrowser.get('$Currency');
      this.echartPPUBarOptions.yAxis[1].axisLabel = {
        // axisLabel: {
        formatter: function (value) {
          let currency_symbols = {
            'USD': '$', // US Dollar
            'AUD': '$',
            'CAD': '$',
            'MXN': '$',
            'GBP': '£', // British Pound Sterling
            'INR': '₹', // Indian Rupee
            'JPY': '¥', // Japanese Yen
          };
          if (currency_symbols[currency_name] !== undefined) {
            return value >= 1000 ? currency_symbols[currency_name] + (value / 1000) + 'k' : currency_symbols[currency_name] + value;
          } else {
            return value;
          }
        },
        textStyle: {
          color: function (value, index) {
            return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
          }
        },
        // },
      }
      // this.echartPPUBarOptions.tooltip = {
      //   position: [0, 0],
      //   trigger: 'axis',
      //   axisPointer: {
      //     type: 'shadow'
      //   },
      //   transitionDuration: 0,
      //   textStyle: { color: '#a5a4bf', fontSize: 13 },
      //   backgroundColor: 'white',
      //   formatter: function (params) {
      //     // console.log("params", params);
      //     return '<div class="card" style="background:#f0f2f8; width:300px; height:296px;border-radius: 5px; border: solid 1px #d5ddf6;"><div class="card-body"><p style="color:#243853;font-weight:bold">' + params[0].axisValue + '</p><table style="width:100%"><tbody>' +  
      //       params.map((item) => { 
      //         if(item.seriesName == 'Roi' || item.seriesName == 'Margin') {
      //           return '<tr><td><span style="width:24px;height:13px;display:inline-block;border-radius:2px;margin-right:10px;background:' + item.color  + '"></span>' + item.seriesName + '</td><td>' + item.value + '%</td></tr>'
      //         } else if(item.seriesName == 'Profit' || item.seriesName == 'Cost' || item.seriesName == 'Revenue') {
      //           return '<tr><td><span style="width:24px;height:13px;display:inline-block;border-radius:2px;margin-right:10px;background:' + item.color  + '"></span>' + item.seriesName + '</td><td>' + item.value + '</td></tr>'
      //         } else {
      //           return '<tr><td><span style="width:24px;height:13px;display:inline-block;border-radius:2px;margin-right:10px;background:' + item.color  + '"></span>' + item.seriesName + '</td><td>' + item.value + '</td></tr>'
      //         }
      //       })
      //     +'</tbody></table></div></div>';
      //   },
      // }
      // this.selectMatrix.valueChanges.pipe().subscribe(value => {
      //   this.echartPPUBarOptions.series = [];
      //   if (value.length > 0) {
      //     this.echartPPUBarOptions = JSON.parse(JSON.stringify(this.echartPPUBarOptions));
      //     if (value.indexOf('sales') > -1) {
      //       this.echartPPUBarOptions.series[value.indexOf('sales')] = this.getBarChartData('Units Sold', this.barChartData.units);
      //     }
      //     if (value.indexOf('ppc') > -1) {
      //       this.echartPPUBarOptions.series[value.indexOf('ppc')] = this.getBarChartData('PPC Units', this.barChartData.ppc);
      //     }
      //     if (value.indexOf('promotion') > -1) {
      //       this.echartPPUBarOptions.series[value.indexOf('promotion')] = this.getBarChartData('Promotions Units', this.barChartData.promotions);
      //     }
      //     if (value.indexOf('profit') > -1) {
      //       this.echartPPUBarOptions.series[value.indexOf('profit')] = this.getLineChartData('Profit', this.barChartData.profit);
      //     }
      //     if (value.indexOf('roi') > -1) {
      //       this.echartPPUBarOptions.series[value.indexOf('roi')] = this.getLineChartData('Roi', this.barChartData.roi);
      //     }
      //     if (value.indexOf('refunds') > -1) {
      //       this.echartPPUBarOptions.series[value.indexOf('refunds')] = this.getBarChartData('Refunds', this.barChartData.refunds);
      //     }
      //     if (value.indexOf('cost') > -1) {
      //       this.echartPPUBarOptions.series[value.indexOf('cost')] = this.getLineChartData('Cost', this.barChartData.cost);
      //     }
      //     if (value.indexOf('revenue') > -1) {
      //       this.echartPPUBarOptions.series[value.indexOf('revenue')] = this.getLineChartData('Revenue', this.barChartData.revenue);
      //     }
      //     if (value.indexOf('margin') > -1) {
      //       this.echartPPUBarOptions.series[value.indexOf('margin')] = this.getLineChartData('Margin', this.barChartData.margin);
      //     }
      //   } else {
      //     this.echartPPUBarChangeOptions.xAxis[0].data = this.barChartData.time.map(item => moment(item).format("MMM D, YYYY"));
      //     this.echartPPUBarChangeOptions.series[0].data = this.barChartData.units;
      //     this.echartPPUBarChangeOptions.series[1].data = this.barChartData.ppc;
      //     this.echartPPUBarChangeOptions.series[2].data = this.barChartData.promotions;
      //     this.echartPPUBarChangeOptions.series[3].data = this.barChartData.profit;
      //     this.echartPPUBarOptions = JSON.parse(JSON.stringify(this.echartPPUBarChangeOptions));
      //   }

      // });

    }
  }

  onChartMouseOver(event) {
    if (event.dataIndex || event.dataIndex == 0) {
      this.barChartValue.units = this.barChartData.units[event.dataIndex];
      this.barChartValue.ppc = this.barChartData.ppc[event.dataIndex];
      this.barChartValue.promotions = this.barChartData.promotions[event.dataIndex];
      this.barChartValue.profit = this.barChartData.profit[event.dataIndex];
      this.barChartValue.roi = this.barChartData.roi[event.dataIndex];
      this.barChartValue.refunds = this.barChartData.refunds[event.dataIndex];
      this.barChartValue.cost = this.barChartData.cost[event.dataIndex];
      this.barChartValue.revenue = this.barChartData.revenue[event.dataIndex];
      this.barChartValue.margin = this.barChartData.margin[event.dataIndex];
      this.barChartValue.tACoS = this.barChartData.tACoS[event.dataIndex];
      this.barChartValue.time = moment(this.barChartData.time[event.dataIndex]).format("MMM D, YYYY");
      this.barChartValue.ppcExpenses = this.barChartData.ppcExpenses[event.dataIndex];
    }
  }
  getLineChartData(type, data) {
    return {
      name: type,
      type: 'line',
      smooth: true,
      showSymbol: false,
      data: data,
      yAxisIndex: 1,
      itemStyle: {
        emphasis: {
          barBorderRadius: [50, 50]
        },
        normal: {
          barBorderRadius: [50, 50, 0, 0]
        }
      },
      barMaxWidth: this.barMaxWidth,
      lineStyle: { width: 3 }
    }
  }
  getBarChartData(type, data) {
    // 
    return {
      name: type,
      type: 'bar',
      smooth: true,
      showSymbol: false,
      data: data,
      barGap: '-100%',
      itemStyle: {
        emphasis: {
          barBorderRadius: [50, 50]
        },
        normal: {
          barBorderRadius: [50, 50, 0, 0]
        }
      },
      barMaxWidth: this.barMaxWidth
    }
  }
  onChangeLegend(event) {
    this.storageBrowser.set('Legend',event.selected);
  }
  // toggleAllSelection() {
  //   this.allSelected = !this.allSelected;  // to control select-unselect
  //   if (this.allSelected) {
  //     this.matrixsSel.options.forEach((item: MatOption) => item.select());
  //   } else {
  //     this.matrixsSel.options.forEach((item: MatOption) => { item.deselect() });
  //   }
  // }
};