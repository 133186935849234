<div class="dashboard" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container" [class.with-bottom-padding]="!sequencesList?.length">
    <div class="dashboard-header">
      <div class="head-container">
          <h2>{{textService.currentText.sequences}}</h2>
        <button mat-button
                mat-raised-button
                (click)="showCreateNewSequenceDialog()"
                class="primary-btn">
          {{textService.currentText.createNewSequence}}
        </button>
      </div>
      <div class="select-holder custom-selects">
        <div (click)="functions.setDropDownPosition(pageFrame, 'pages')" #pageFrame
             [class.active]="pageId.panelOpen"
             class="select-wrapper">
          <mat-form-field>
            <mat-select [placeholder]="textService.currentText.selectPage"
                        shouldPlaceholderFloat="false"
                        panelClass="default-dropdown pages"
                        disableRipple
                        [(ngModel)]="currentPage"
                        (selectionChange)="sortByPageId()"
                        #pageId
                        name="pageName">
              <mat-option *ngFor="let select of pages" [value]="select">
                {{ select.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header" [class.full-width]="sequencesListPaginator?.length <= 7">
            <div class="td"></div>
            <div class="td" (click)="functions.sortColumns(sequencesList, 'funnelName', true); getUpdatePaginator(0)">{{textService.currentText.sequenceName}}</div>
            <div class="td" (click)="functions.sortColumns(sequencesList, 'funnelStatus'); getUpdatePaginator(0)">{{textService.currentText.status}}</div>
            <div class="td" (click)="functions.sortColumns(sequencesList, 'conversationCount'); getUpdatePaginator(0)">{{textService.currentText.conversationCoun}}</div>
            <div class="td" (click)="functions.sortColumns(sequencesList, 'funnelTrigger'); getUpdatePaginator(0)">{{textService.currentText.sequenceTrigger}}</div>
            <div class="td" (click)="functions.sortColumns(sequencesList, 'funnelTagName', true); getUpdatePaginator(0)">{{textService.currentText.tag}}</div>
            <div class="td">{{textService.currentText.seeFollowupRules}}</div>
            <div class="td">{{textService.currentText.createNewRule}}</div>
          </div>
        </div>
        <div class="table-holder">
          <div class="table">
            <ng-container *ngFor="let sequence of sequencesListPaginator">
              <div class="tr">
                <div class="td">
                  <button mat-button [matMenuTriggerFor]="menu"
                          (click)="$event.stopPropagation()"></button>
                  <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                    <button mat-menu-item (click)="showDeleteSequenseDialog(sequence.funnelId)">
                      <div class="list-inner-container">
                        <div class="menu-icon icon-delete"></div>
                        {{textService.currentText.delete}}
                      </div>
                    </button>
                  </mat-menu>
                </div>
                <div class="td">
                  {{sequence.funnelName}}
                </div>
                <div class="td">
                  <mat-slide-toggle [(ngModel)]="sequence.funnelStatus"
                                    [checked]="sequence.funnelStatus"
                                    (click)="changeStatus(sequence.funnelId, sequence.funnelStatus)"
                                    [class.uncheckedStatus]="!sequence.funnelStatus"
                                    disableRipple>
                    {{valueToggle(sequence.funnelStatus)}}
                  </mat-slide-toggle>
                  </div>
                <div class="td">
                  {{sequence.conversationCount}}
                </div>
                <div class="td">
                  {{sequence.funnelTrigger}}
                </div>
                <div class="td">
                  {{sequence.funnelTrigger === 'Tagged' ? sequence.funnelTagName : 'N/A'}}
                </div>
                <div class="td">
                  <span class="highlighted pointer" [class.selected]="sequence?.funnelId === selectedSequence?.funnelId" (click)="getFollowupRules(sequence); scrollBottom()">
                    {{textService.currentText.seeFollowupRules}}</span>
                </div>
                <div class="td">
                  <span class="highlighted pointer" (click)="getFollowupRules(sequence, true); scrollBottom()">
                    {{textService.currentText.createNewRule}}</span>
                </div>
              </div>
            </ng-container>
            <div class="tr" *ngIf="sequencesList?.length === 0">
              <div class="td not-found">
                {{textService.currentText.noDataFound}}.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="sequencesList?.length">
      <mat-paginator [length]="sequencesList?.length"
                     [pageSize]="pageSize"
                     (page)="getUpdatePaginator($event.pageIndex)">
      </mat-paginator>
    </ng-container>
  </div>

  <div class="dashboard-container rules">
    <div class="dashboard-header">
      <div class="head-container">
        <h2>{{textService.currentText.rulesOfSequence}}</h2>
        <!--<button mat-button-->
                <!--mat-raised-button-->
                <!--[disabled]="!selectedSequence"-->
                <!--(click)="showCreateNewRuleDialog()"-->
                <!--class="primary-btn">-->
          <!--{{textService.currentText.createNewRule}}-->
        <!--</button>-->
      </div>
      <div class="sub-head-container">
        <p *ngIf="!selectedSequence">
          {{textService.currentText.noSequenceSelected}}
        </p>
        <p *ngIf="selectedSequence">
          {{textService.currentText.selectedSequence}} <span class="highlighted">{{selectedSequence.funnelName}}</span>
        </p>
      </div>
    </div>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header" [class.full-width]="followupRulesListPaginator?.length <= 4">
            <div class="td"></div>
            <div class="td" (click)="functions.sortColumns(followupRules, 'ruleName'); getUpdateRulesPaginator(0)">{{textService.currentText.ruleName}}</div>
            <div class="td" (click)="functions.sortColumns(followupRules, 'ruleStatus'); getUpdateRulesPaginator(0)">{{textService.currentText.status}}</div>
            <div class="td" (click)="functions.sortColumns(followupRules, 'days'); getUpdateRulesPaginator(0)">{{textService.currentText.numberOfDays}}</div>
            <div class="td"></div>
            <div class="td"></div>
            <div class="td"></div>
          </div>
        </div>
        <div class="table-holder" >
          <div class="table" *ngIf="selectedSequence">
            <div class="tr" *ngFor="let rule of followupRulesListPaginator">
              <div class="td">
                <button mat-button [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"></button>
                <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                  <button mat-menu-item (click)="showCreateNewRuleDialog(selectedSequence, rule)">
                    <div class="list-inner-container">
                      <div class="menu-icon icon-edit"></div>
                      {{textService.currentText.edit}}
                    </div>
                  </button>
                  <button mat-menu-item (click)="deleteRule(rule.ruleId)">
                    <div class="list-inner-container">
                      <div class="menu-icon icon-delete"></div>
                      {{textService.currentText.delete}}
                    </div>
                  </button>
                </mat-menu>
              </div>
              <div class="td">{{rule.ruleName}}</div>
              <div class="td" >
                <mat-slide-toggle [(ngModel)]="rule.ruleStatus"
                                  [checked]="rule.ruleStatus"
                                  (click)="changeRuleStatus(rule)"
                                  [class.uncheckedStatus]="!rule.ruleStatus"
                                  disableRipple>
                  {{valueToggle(rule.ruleStatus)}}
                </mat-slide-toggle>
              </div>
              <div class="td">{{rule.days}}</div>
              <div class="td"></div>
              <div class="td"></div>
              <div class="td"></div>
            </div>
            <div class="tr" *ngIf="followupRulesListPaginator?.length === 0">
              <div class="td not-found">
                {{textService.currentText.noDataFound}}.
              </div>
            </div>
          </div>
          <div *ngIf="!selectedSequence" class="sequence-not-selected">{{textService.currentText.chooseSequence}}</div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="followupRules?.length">
      <mat-paginator [length]="followupRules?.length"
                     [pageSize]="rulesPageSize"
                     (page)="getUpdateRulesPaginator($event.pageIndex)">
      </mat-paginator>
    </ng-container>
  </div>
</div>
