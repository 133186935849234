import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';

@Injectable()

export class DashboardService extends UserService {

  public getDashboardData(data: any): Observable<any> {
    return this.post('dashboard/get_dashboard_data', data);
  }

  public getOrders(data: any): Observable<any> {
    return this.post('dashboard/get_orders', data);
  }

  public getPromotions(data: any): Observable<any> {
    return this.post('dashboard/get_promotions', data);
  }

  public getPromotionsSummary(data: any): Observable<any> {
    return this.post('dashboard/promotions_summary', data);
  }

  public getStatistic(data: any): Observable<any> {
    return this.post('dashboard/get_statistic', data);
  }

  public getLastYearData(publicId: string): Observable<any> {
    return this.post('dashboard/get_last_year_data', {
      publicId: publicId
    });
  }

  public getTotalProfit(publicId: string, start: any, end: any, time: any, isAll: any, channel: any, skusList: any): Observable<any> {
    return this.post('dashboard/get_total_profit', {
      publicId: publicId,
      start: start,
      end: end,
      time: time,
      isAll: isAll,
      channel: channel,
      skusList: skusList,
    });
  }

  public getSystemMessages(publicId: string): Observable<any> {
    return this.post('dashboard/system_messages', {
      publicId: publicId
    });
  }

  public clearSystemMessages(publicId: string): Observable<any> {
    return this.post('dashboard/clear_system_messages', {
      publicId: publicId
    });
  }
}
