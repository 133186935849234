<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container">
    <div class="dashboard-header">
      <h2>{{textService.currentText.affiliatesTitle}}</h2>
      <div class="sub-head-container">
        <p>{{textService.currentText.affiliateSubTitle}} <span class="action-el" (click)="openTAC()">
          {{textService.currentText.TermsConditions}}</span>
        </p>
      </div>
    </div>
    <div class="affiliate-holder" *ngFor="let affiliatesTable of currentAffiliates">
      <div class="affiliates-link">
        {{textService.currentText.affiliateLink}}
        <a class="action-el" target="_blank" [href]="affiliatesTable.link">
          {{affiliatesTable.link}}
        </a>
      </div>
      <div class="table-container">
        <div class="table-wrapper">
          <div class="table-header-wrapper">
            <div class="table-header fullWidth">
              <div class="tr">
                <div class="td">{{textService.currentText.tableHeaders.earnedAmount}}</div>
                <div class="td">{{textService.currentText.tableHeaders.userPublicID}}</div>
                <div class="td">{{textService.currentText.registration}} <br> {{textService.currentText.date}}</div>
                <div class="td">{{textService.currentText.full}} <br> {{textService.currentText.registration}}</div>
                <div class="td">{{textService.currentText.tableHeaders.fullRegistration}} <br> {{textService.currentText.date}}</div>
                <div class="td">{{textService.currentText.tableHeaders.planType}}</div>
                <div class="td">{{textService.currentText.tableHeaders.agreementStatus}}</div>
                <div class="td">{{textService.currentText.tableHeaders.lastUpdated}}</div>
              </div>
            </div>
          </div>
          <div class="table-holder">
            <div class="table">
              <div class="tr" *ngFor="let affiliate of affiliatesTable.affiliates">
                <div class="td">
                  <ng-container *ngIf="affiliate.affiliatePaid?.length === 0">
                    <span class="green">Eligible <br> for payment</span>
                  </ng-container>
                  <ng-container *ngIf="affiliate.affiliatePaid.length > 0">

                  </ng-container>
                </div>
                <div class="td">{{affiliate.publicId}}</div>
                <div class="td">{{affiliate.registrationDate}}</div>
                <div class="td">{{affiliate.validatedDetails}}</div>
                <div class="td">{{affiliate.fullRegistrationDate}}</div>
                <div class="td">{{affiliate.planType}}</div>
                <div class="td">{{affiliate.agreementStatus}}</div>
                <div class="td">{{affiliate.lastUpdated}}</div>
              </div>
              <div class="tr" *ngIf="affiliatesTable.affiliates.length === 0">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button mat-button mat-raised-button class="default-btn" type="button"
            (click)="googleAnalyticsEventsService.emitEvent('Button', 'Affiliates Get Another Link', 'Click', 1); getLink()">
      {{textService.currentText.getAnotherLink}}
    </button>
  </div>
</div>
