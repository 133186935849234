import { UpgradeNewComponent } from './components/upgrade-new/upgrade-new.component';
import { PaymentDialogComponent } from './components/dialogs/payment-dialog/payment-dialog.component';
import { MailUnsubscribeComponent } from './components/main-page/mail-unsubscribe/mail-unsubscribe.component';
import { AlertsService } from './services/alerts.service';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRouteModule } from './app-route.module';
import { MainPageComponent } from './components/main-page/main-page.component';
import { LoginPageComponent } from './components/main-page/login-page/login-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NinjaMaterialModule } from './material-module';
import { SignUpPageComponent } from './components/main-page/sign-up-page/sign-up-page.component';
import { UserService } from './services/user.service';
// import {Http, HttpModule} from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResetPasswordPageComponent } from './components/main-page/reset-password/reset-password.component';
import { ConfirmNewPasswordPageComponent } from './components/main-page/confirm-new-password/confirm-new-password.component';
import { ChooseRegionPageComponent } from './components/main-page/choose-region/choose-region.component';
import { TokenService } from './services/token.service';
import { StorageBrowser } from './services/storage.browser';
import { SessionStorageBrowser } from './services/session.storage.browser';
import { BaseStorage } from './services/storage.swaps';
import { ConstService } from './services/const-service';
import { TermsDialogComponent } from './components/dialogs/terms-condition-dialog/terms-dialog.component';
import { Functions } from './services/functions';
import { EditDialogComponent } from './components/dialogs/edit-dialog/edit-dialog.component';
import { EditShortNameDialogComponent } from './components/dialogs/edit-short-name/edit-short-name-dialog.component';
import { EditProductCostDialogComponent } from './components/dialogs/edit-product-cost/edit-product-cost-dialog.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
// import { DashboardHeaderComponent } from './components/dashboard/dashboard-header/dashboard-header.component';
// import { DashboardSidenavComponent } from './components/dashboard/dashboard-sidenav/dashboard-sidenav.component';
// import { DashboardComponent } from './components/dashboard/dashboard.component';
// import { FbaComponent } from './components/dashboard/automated-messaging/fba/fba.component';
// import { BulkComponent } from './components/dashboard/automated-messaging/bulk/bulk.component';
// import { FbmComponent } from './components/dashboard/automated-messaging/fbm/fbm.component';
// import { CreateNewTemplateComponent } from './components/dashboard/automated-messaging/create-new-template/create-new-template.component';
import { ExpenceDialogComponent } from './components/dialogs/expence-dialog/expence-dialog.component';
import { BulkDialogComponent } from './components/dialogs/bulk-dialog/bulk-dialog.component';
// import { UserSettingsComponent } from './components/dashboard/user-settings/user-settings.component';
// import { AccountSettingsComponent } from './components/dashboard/user-settings/account-settings/account-settings.component';
// import { ProductSettingsComponent } from './components/dashboard/user-settings/product-settings/product-settings.component';
// import { ExpensesComponent } from './components/dashboard/user-settings/expenses/expenses.component';
// import { NotificationComponent } from './components/notification/notification.component';
import { SelectSpecificProductsDialogComponent } from './components/dialogs/select-specific-products-dialog/select-specific-products.component';
import { TemplateService } from './services/template.service';
// import { CanActivateTeam, CanDeactivateGuard } from './components/dashboard/diactivate.guadr';
import { AccountSettings } from './services/account-settings';
import { TooltipComponent } from './components/tooltip/tooltip.component';
// import { DashboardSchedulesDialogComponent } from './components/dialogs/dashboard-shedules-dialog/dashboard-schedules-dialog.component';
// import { PieChartComponent } from './components/dashboard/dashboard-schedules/pie-chart/pie-chart.component';
// import { SmallChartComponent } from './components/dashboard/dashboard-schedules/small-chart/small-chart.component';
import { Page404Component } from './components/404-page/404-page.component';
//import { UpgradeComponent } from './components/dashboard/upgrade/upgrade.component';
import { GrantAccessComponet } from './components/main-page/grant-access/grant-access.component';
import { AppStoreComponent } from './components/main-page/appstore/appstore.component';
import { SpaComponent } from './components/main-page/spa/spa.component';
// import { SuperUserComponent } from './components/dashboard/super-user/super-user.component';
// import { SubscribersComponent } from './components/dashboard/facebook-bot-messanger/subscribers/subscribers.component';
// import { ConversationManagerComponent } from './components/dashboard/facebook-bot-messanger/conversation-manager/conversation-manager.component';
// import { AutoResponseComponent } from './components/dashboard/facebook-bot-messanger/auto-response/auto-response.component';
// import { FacebookNotificationsComponent } from './components/dashboard/facebook-notifications/facebook-notifications.component';
// import { OrdersComponent } from './components/dashboard/orders/orders.component';
// import { CouponsComponent } from './components/dashboard/coupons/coupons.component';
import { CouponDialogComponent } from './components/dialogs/coupon-dialog/coupon-dialog.component';
// import { HijackedComponent } from './components/dashboard/hijacked/hijacked.component';
// import { KeywordsOptimizerComponent } from './components/dashboard/keywords-optimizer/keywords-optimizer.component';
// import { MoneyBackComponent } from './components/dashboard/money-back/money-back.component';
import { MoneyBackDialogComponent } from './components/dialogs/money-back-dialog/money-back-dialog.component';
// import { FacebookGrantAccessComponent } from './components/dashboard/facebook-bot-messanger/facebook-grant-access/facebook-grant-access.component';
// import { BotMenuComponent } from './components/dashboard/facebook-bot-messanger/bot-menu/bot-menu.componet';
// import { CreateNewConversationComponent } from './components/dashboard/facebook-bot-messanger/create-new-conversation/create-new-conversation.component';
// import { AddRegionComponent } from './components/dashboard/add-region/add-region.component';
import { AutoResponseDialogComponent } from './components/dialogs/auto-response-dialog/auto-response-dialog.component';
import { SelectPostDialogComponent } from './components/dialogs/select-post-dialog/select-post-dialog.component';
// import { DownloadReportComponent } from './components/dashboard/orders/download-report/download-report.component';
// import { SendMessageComponent } from './components/dashboard/orders/send-message/send-message.component';
import { ContactUsDialogComponent } from './components/dialogs/contact-us-dialog/contact-us-dialog.component';
// import { AsinSpotComponent } from './components/dashboard/asin-spot/asin-spot.component';
// import { AffiliateComponent } from './components/dashboard/affiliate/affiliate.component';
// import { InviteFriendComponent } from './components/dashboard/invite-friends/invite-friend.component';
// import { BotMenuOptionConstructorComponent } from './components/dashboard/facebook-bot-messanger/bot-menu/bot-menu-option-constructor/bot-menu-option-constructor.component';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
// import { RefundTooltipComponent } from './components/dashboard/orders/refund-tooltip/refund-tooltip.component';
// import { ConversationBotOptionConstructorComponent } from './components/dashboard/facebook-bot-messanger/create-new-conversation/conversation-bot-option-constructor/conversation-bot-option-constructor.component';
//import { ConversationBotMenuOptionComponent } from './components/dashboard/facebook-bot-messanger/create-new-conversation/conversation-bot-menu-option/conversation-bot-menu-option.component';
//import { ConversationPreviewComponent } from './components/dashboard/facebook-bot-messanger/create-new-conversation/conversation-preview/conversation-preview.component';
import { FbService } from './services/fb.service';
//import { ConversationBotMenuNavigationComponent } from './components/dashboard/facebook-bot-messanger/create-new-conversation/conversation-bot-menu-navigation/conversation-bot-menu-navigation.component';
import { UpgradeBusinessDetailsComponent } from './components/dialogs/upgrade-business-details-dialog/upgrade-business-details-dialog.component';
import { AuthenticatedHttpService } from './services/auth-http.service';
import { GoogleAnalyticsEventsService } from './services/google-analytics.service';
//import { FAQComponent } from './components/dashboard/FAQ/FAQ.component';
import { MoneyBackService } from './services/money-back.service';
import { CouponsService } from './services/coupons.service';
import { OrdersService } from './services/orders.service';
import { UpgradeService } from './services/upgrade.service';
import { DashboardService } from './services/dashboard.service';
import { NotificationService } from './services/notification.service';
import { TextService } from './services/text.service';
// import { InvoicingComponent } from './components/dashboard/invoicing/invoicing.component';
// import { CreateNewInvoiceComponent } from './components/dashboard/invoicing/create-new-invoice/create-new-invoice.component';
// import { DndDirective } from './components/dashboard/invoicing/create-new-invoice/appDnd.directive';
import { InvoicingService } from './services/invoicing.service';
// import { BotMenuPreviewComponent } from './components/dashboard/facebook-bot-messanger/bot-menu/bot-menu-preview/bot-menu-preview.component';
// import { SequencesComponent } from './components/dashboard/facebook-bot-messanger/sequences/sequences.component';
import { SequencesService } from './services/sequences.service';
import { SequenceDialogComponent } from './components/dialogs/sequence-dialog/sequence-dialog.component';
import { RuleDialogComponent } from './components/dialogs/rule-dialog/rule-dialog.component';
import { ManageTagsDialogComponent } from './components/dialogs/manage-tags-dialog/manage-tags-dialog.component';
import { SystemMessagesService } from './services/system-messages.service';
// import { LastMessagesSentComponent } from './components/dashboard/automated-messaging/last-messages-sent/last-messages-sent.component';
// import { BillingComponent } from './components/dashboard/account/billing/billing.component';
// import { UserPermissionsComponent } from './components/dashboard/account/user-permissions/user-permissions.component';
// import { LinkAccountsComponent } from './components/dashboard/account/link-accounts/link-accounts.component';
import { ManagedAccountDialogComponent } from './components/dialogs/managed-account-dialog/managed-account-dialog.component';
import { AccountPermissionsDialogComponent } from './components/dialogs/account-permissions-dialog/account-permissions-dialog.component';
import { SendInvoiceDialogComponent } from './components/dialogs/send-invoice-dialog/send-invoice-dialog.component';
import { FacebookModule } from 'ngx-facebook';
// import { AsinAlertsComponent } from './components/dashboard/asin-alerts/asin-alerts.component';
import {
    CreateAudienceDialogComponent
} from './components/dialogs/create-audience-dialog/create-audience-dialog.component';
import { AddAsinDialogComponent } from './components/dialogs/add-asin-dialog/add-asin-dialog.component';
// import { AsinChartComponent } from "./components/dashboard/asin-spot/asin-chart/asin-chart.component";
import { CustomCurrencyPipe } from './pipes/currency.pipe';
import { MessangerVideoDialogComponent } from './components/dialogs/messenger-video-dialog/messenger-video-dialog.component';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
// import { InlineEditComponent } from './components/controls/inline-edit/inline-edit.component';
import { ShareService } from './services/share.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { AppConfigService } from './services/app-config.service';
import { AddressDialogComponent } from './components/dialogs/address-dialog/address-dialog.component';
import { ErrorService } from './services/error.service';
import { UpgradeDialogComponent } from './components/dialogs/upgrade-dialog/upgrade-dialog.component';
import { WhyDialogComponent } from './components/dialogs/why-dialog/why-dialog.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { AbTestsModule } from 'angular-ab-tests';
import {
    GoogleLoginProvider,
    FacebookLoginProvider,
} from 'angularx-social-login';
import { PricingService } from './services/pricing.service';
import { MfaComponent } from './components/main-page/mfa/mfa.component';
export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
    return () => overlay.scrollStrategies.block();
}

const appInitializerFn = (appConfig: AppConfigService) => { // to load env-config by host
    return () => {
        return appConfig.loadAppConfig();
    };
};

@NgModule({
    declarations: [
        // TodaysectionComponent,
        // ProfitsectionComponent, BestandworstsectionComponent, ProfitbreakdownComponent, DoughnutchartComponent, BarchartComponent, ProfitbreakdownpopupComponent, ProfitbreakdownwithoutgroupComponent, ListComponent, ErrorsComponent, ProfitbreakdownonegroupComponent, ProfitbreakdowntwogroupComponent, 
        //AccountSettingsComponent,
        AppComponent,
        //BulkComponent,
        BulkDialogComponent,
        ChooseRegionPageComponent,
        ConfirmationDialogComponent,
        //CreateNewTemplateComponent,
        //DashboardComponent,
        //DashboardHeaderComponent,
        //DashboardSidenavComponent,
        ExpenceDialogComponent,
        //ExpensesComponent,
        EditDialogComponent,
        EditShortNameDialogComponent,
        EditProductCostDialogComponent,
        //FbaComponent,
        //FbmComponent,
        LoginPageComponent,
        MfaComponent,
        MainPageComponent,
        // NotificationComponent,
        //ProductSettingsComponent,
        ResetPasswordPageComponent,
        ConfirmNewPasswordPageComponent,
        SelectSpecificProductsDialogComponent,
        SignUpPageComponent,
        TermsDialogComponent,
        TooltipComponent,
        //UserSettingsComponent,
        // DashboardSchedulesDialogComponent,
        //PieChartComponent,
        //SmallChartComponent,
        Page404Component,
        //UpgradeComponent,
        GrantAccessComponet,
        AppStoreComponent,
        SpaComponent,
        //SuperUserComponent,
        //SubscribersComponent,
        //ConversationManagerComponent,
        //AutoResponseComponent,
        //FacebookNotificationsComponent,
        //OrdersComponent,
        //CouponsComponent,
        CouponDialogComponent,
        //HijackedComponent,
        //KeywordsOptimizerComponent,
        //MoneyBackComponent,
        MoneyBackDialogComponent,
        //FacebookGrantAccessComponent,
        //BotMenuComponent,
        //CreateNewConversationComponent,
        //AddRegionComponent,
        AutoResponseDialogComponent,
        SelectPostDialogComponent,
        //DownloadReportComponent,
        //SendMessageComponent,
        ContactUsDialogComponent,
        //AsinSpotComponent,
        //AffiliateComponent,
        //InviteFriendComponent,
        //BotMenuOptionConstructorComponent,
        //RefundTooltipComponent,
        //ConversationBotOptionConstructorComponent,
        //ConversationBotMenuOptionComponent,
        //ConversationPreviewComponent,
        //ConversationBotMenuNavigationComponent,
        UpgradeBusinessDetailsComponent,
        //FAQComponent,
        //InvoicingComponent,
        //CreateNewInvoiceComponent,
        //DndDirective,
        //BotMenuPreviewComponent,
        //SequencesComponent,
        ManageTagsDialogComponent,
        SequenceDialogComponent,
        RuleDialogComponent,
        //LastMessagesSentComponent,
        //UserPermissionsComponent,
        //LinkAccountsComponent,
        ManagedAccountDialogComponent,
        //BillingComponent,
        AccountPermissionsDialogComponent,
        SendInvoiceDialogComponent,
        CreateAudienceDialogComponent,
        AddAsinDialogComponent,
        //AsinChartComponent,
        CustomCurrencyPipe,
        MessangerVideoDialogComponent,
        AddressDialogComponent,
        //AsinAlertsComponent,
        //TruncateTextPipe,
        // InlineEditComponent,
        MailUnsubscribeComponent,
        UpgradeDialogComponent,
        WhyDialogComponent,
        PaymentDialogComponent,
        UpgradeNewComponent,
    ],
    imports: [
        DashboardModule,
        HttpClientModule,
        NinjaMaterialModule,
        BrowserAnimationsModule,
        AppRouteModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        SocialLoginModule,
        ReactiveFormsModule,
        FacebookModule.forRoot(),
        NgxDaterangepickerMd.forRoot(),
        AbTestsModule.forRoot([
                {
                    versions: [ 'buttons-up', 'buttons-down' ],
                    versionForCrawlers: 'buttons-up',
                    //expiration: 45,
                  }
              ]),        
    ],
    providers: [
        ErrorService,
        TokenService,
        TextService,
        FbService,
        SessionStorageBrowser,
        StorageBrowser,
        BaseStorage,
        ConstService,
        Functions,
        TemplateService,
        AccountSettings,
        // CanDeactivateGuard,
        // CanActivateTeam,
        GoogleAnalyticsEventsService,
        MoneyBackService,
        CouponsService,
        OrdersService,
        UpgradeService,
        DashboardService,
        NotificationService,
        InvoicingService,
        SequencesService,
        SystemMessagesService,
        UserService,
        DatePipe,
        AlertsService,
        PricingService,
        { provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [AppConfigService] },
        { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticatedHttpService,
            multi: true
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('142114196208163'),
                    },
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('34907726966-s2oj51pjj7505q4sc96db7c591ji1po9.apps.googleusercontent.com'),                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                    },
                ],
            } as SocialAuthServiceConfig,
        }
    ],
    entryComponents: [
        AddressDialogComponent,
        TermsDialogComponent,
        ConfirmationDialogComponent,
        EditDialogComponent,
        BulkDialogComponent,
        ExpenceDialogComponent,
        SelectSpecificProductsDialogComponent,
        // DashboardSchedulesDialogComponent,
        CouponDialogComponent,
        MoneyBackDialogComponent,
        AutoResponseDialogComponent,
        SelectPostDialogComponent,
        ContactUsDialogComponent,
        UpgradeBusinessDetailsComponent,
        ManageTagsDialogComponent,
        SequenceDialogComponent,
        RuleDialogComponent,
        ManagedAccountDialogComponent,
        AccountPermissionsDialogComponent,
        SendInvoiceDialogComponent,
        CreateAudienceDialogComponent,
        AddAsinDialogComponent,
        MessangerVideoDialogComponent,
        UpgradeDialogComponent,
        PaymentDialogComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {
}
