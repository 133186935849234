import { UserService } from './../../../services/user.service';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './mail-unsubscribe.component.html',
  styleUrls: [ './mail-unsubscribe.component.scss']
})
export class MailUnsubscribeComponent {

  public repJson = {
  publicId:null,
  fbSales : "true",
  fbComp : "true",
  emailNegativeFeedback : "true",
  dailyEmailCounter: "true",
  emailHijack: "true",
  emailComp: "true"
  };
  
  constructor( private activatedRoute: ActivatedRoute,
              private userService : UserService ) 
  {
//    let params = this.activatedRoute.snapshot.queryParamMap;
//    if (params)
      //this.repJson.publicId=params.params.get("pub");
      this.repJson.publicId=this.activatedRoute.snapshot.queryParams.pub;
    return;
  }

  public onUpdate() : any
  {
    this.userService.unsubscribeService(this.repJson).subscribe();
    return;
  }
}
