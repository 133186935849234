import { UserSubEmail } from './../../../models/BaseModels';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleLoginProvider, SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { TokenService } from '../../../services/token.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConstService } from '../../../services/const-service';
import { TextService } from '../../../services/text.service';
import { GoogleAnalyticsEventsService } from '../../../services/google-analytics.service';
import { FbService } from '../../../services/fb.service';
import { StorageBrowser } from '../../../services/storage.browser';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { CookieService } from 'ngx-cookie-service';
import { TermsDialogComponent } from '../../dialogs/terms-condition-dialog/terms-dialog.component';
import { AbTestsService } from 'angular-ab-tests';

@Component({
    selector: 'ninja-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent implements OnInit {
    @ViewChild('email', { static: false }) loginInput: ElementRef;
    @ViewChild('pass', { static: false }) passInput: ElementRef;
    public loginUser: string;
    public password: string;
    public rememberMe = true;
    public emailInput: boolean;
    public passwordInput: boolean;
    public loginIsHide = false;
    public passwordInputType = 'password';
    public capsOn;
    public user;
    public queryParams: any;
    public errors: any = {};
    
    public loginForm = this.fb.group({
        email: ['',
            [Validators.required,
            Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        password: ['',
            [Validators.required]]
    });

    constructor(private router: Router,
        public userService: UserService,
        public fbService: FbService,
        public constService: ConstService,
        public storageBrowser: StorageBrowser,
        public textService: TextService,
        private authService: SocialAuthService,
        public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
        public activatedRoute: ActivatedRoute,
        public matDialog: MatDialog,
        public mixpanelService: MixpanelService,
        public tokenService: TokenService,
        public fb: FormBuilder,
        public cookieService : CookieService,
        private abTestsService: AbTestsService) 
    {
        this.mixpanelService.init(null);
        var ABVer=this.abTestsService.getVersion();
        this.mixpanelService.track('q login page - view - ' + ABVer);
    }

    public ngOnInit(): void {
        this.userService.loader = false;
        if (this.activatedRoute.snapshot.queryParams.code) {
            this.userService.loader = true;
            this.fbService.getFbToken(this.userService.currentUser.id,
                this.activatedRoute.snapshot.queryParams.code).subscribe(res => {
                    this.userService.loader = false;
                    if (res.isSuccess) {
                        this.mixpanelService.track('q login page - fb - conversation-manager');
                        this.router.navigate(['dashboard/facebook-bot-manager/conversation-manager'], { queryParams: { 'success': 'true' } });
                    } else if (!res.isSuccess) {
                        this.mixpanelService.track('q login page - fb - grant-access');
                        this.router.navigate(['dashboard/facebook-bot-manager/grant-access'], { queryParams: { 'success': 'true' } });
                    }
                });
        } else {
            this.loginIsHide = true;
        }
        if (this.activatedRoute.snapshot.queryParams.forgotPasswordToken) {
            this.userService.checkTokenForgotPass(this.activatedRoute.snapshot.queryParams.forgotPasswordToken).subscribe((data) => {
                
                if (data.isValid) {
                    this.router.navigate(['confirm-password'], { queryParams: { user: this.activatedRoute.snapshot.queryParams.forgotPasswordToken } });
                } else if (data.status === 'INVALID_USED') {
                    const message = this.textService.currentText.tokenUsedMessage
                    this.openForgotPassTokenDialog(message);
                } else if (data.status === 'INVALID_TIMEOUT') {
                    const message = this.textService.currentText.tokenExpiredMessage
                    this.openForgotPassTokenDialog(message);
                } else if (data.status === 'INVALID_NOT_EXISTS') {
                    const message = this.textService.currentText.tokenNotExistMessage
                    this.openForgotPassTokenDialog(message);
                } else if (data.status === 'UNKNOWN') {
                    const message = this.textService.currentText.tokenInvalidMessage
                    this.openForgotPassTokenDialog(message);
                }
            });
            this.mixpanelService.track('q login page - forgotPassword');
            return;
        }
        if (this.activatedRoute.snapshot.queryParams.unsubscribe_funnels) {
            this.matDialog.open(ConfirmationDialogComponent, {
                data: {
                    'dialogFor': 'unsubscribe-email',
                },
            }).afterClosed().subscribe((res) => {
                if (res) {
                    this.userService.unsubscribeMarketing(this.activatedRoute.snapshot.queryParams.unsubscribe_funnels).subscribe();
                }
            });
            this.mixpanelService.track('q login page - unsubscribe_funnels');
            return;
        }
        if (this.activatedRoute.snapshot.queryParams.unsubscribe_email_report) {
            this.matDialog.open(ConfirmationDialogComponent, {
                data: {
                    'dialogFor': 'unsubscribe-email',
                },
            }).afterClosed().subscribe((res) => {
                if (res) {
                    this.userService.unsubscribeEmail(this.activatedRoute.snapshot.queryParams.unsubscribe_email_report).subscribe();
                }
            });
            this.mixpanelService.track('q login page - unsubscribe_email_report');
            return;
        }
        if (this.tokenService.getId()) {
            let remember=this.tokenService.getToken().rememberMe;
            this.userService.tokenCheck().subscribe((data) => {
                if (data.isLogged && this.userService.currentUser.user.isVerified) {
                    if (!remember)
                    {
                        if (!this.cookieService.check('KeepSession'))
                        {
                            return ;
                        }
                    }
                    this.mixpanelService.track('q login page - KeepSession');
                    this.SetIdentity(this.userService.currentUser.user);
                    this.router.navigate(['dashboard/orders-dashboard']);
                } else {
                    return ;
                }
            });
        }
    }

    public goToResetPassword(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.a_id) {
                this.router.navigate(['reset-password'],
                    { queryParams: { a_id: params.a_id } });
            } else {
                this.router.navigate(['reset-password']);
            }
        });
    }

    public goToSignUp(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.a_id) {
                this.router.navigate(['registration'],
                    { queryParams: { a_id: params.a_id } });
            } else {
                this.router.navigate(['registration']);
            }
        });
    }

    @HostListener('window:keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent): void {
        this.capsOn = event.getModifierState && event.getModifierState('CapsLock');
    }

    public signInWithGoogle() {
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res) => {
            console.log("Google", res)
            if (res.idToken && res.id) {
                this.loginWithGoogle(res.idToken);
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    public loginWithGoogle(userToken) {
        this.userService.loader = true;
        this.userService.login({ googleAccessToken: userToken }).subscribe(
            (userData) => {
                this.userService.loader = false;
                this.userService.superUserActive = false;
                if (userData.id === 0) {
                    this.userService.currentUser = userData;
                    this.displayError(this.userService.currentUser.message);
                    this.userService.loader = false;
                    this.mixpanelService.track('q login page - loginWithGoogle - error');
                    return;
                }
                this.SetIdentity(userData);
             if (userData.id !== 0) {
                    if (this.activatedRoute.snapshot.queryParams.amazon_state !== undefined &&
                        this.activatedRoute.snapshot.queryParams.selling_partner_id !== undefined &&
                        this.activatedRoute.snapshot.queryParams.amazon_callback_uri !== undefined) {
                        var user;
                        if (userData.emails) {
                            const data = userData.emails.find(data => (data.submarket === userData.marketplace));
                            user = data.subid;
                        } else {
                            user = userData.userId;
                        }
                        console.log("userId G", user);
                        const redirect_uri = 'https://webapp.quantifyninja.com/spa'
                        const amazonUrl = 'https://sellercentral.amazon.com/apps/authorize/confirm/amzn1.sellerapps.app.ca82764b-41ed-46b1-83c6-298a96554243?amazon_state=' + this.activatedRoute.snapshot.queryParams.amazon_state + '&state=' + user + '&redirect_uri=' + redirect_uri;
                        window.location.replace(amazonUrl);
                    } else {

                        if (userData.userId) {
                            this.userService.currentUser['userId'] = userData.userId;
                        }
                        if (userData.isSuspended) {
                            this.showAccountOnHoldDialog();
                            return;
                        }
                        if (!userData.isVerified) {
                            for (const prop in this.userService.currentUser) { if (this.userService.currentUser.hasOwnProperty(prop)) { delete this.userService.currentUser[prop]; } }
                            this.userService.currentUser.id = userData.id;
                            this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
                            this.tokenService.setToken(userData,this.rememberMe);
                            this.router.navigate(['choose-region']);
                        } else {
                            console.log(userData.sellerId);
                            this.userService.currentUser = userData;
                            this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
                            if (this.userService.currentUser.language && this.constService.languages.indexOf(this.userService.currentUser.language) > 0) {
                                this.textService.setLangBadge(this.userService.currentUser.language);
                                this.textService.changeLang(this.textService.currentText, this.textService[`text${this.userService.currentUser.language}`]);
                            } else {
                                this.textService.setLangBadge('EN');
                                this.textService.changeLang(this.textService.currentText, this.textService.textEN);
                            }
                            // this.tokenService.savePublicId(data.id);
                            if (this.checkRegion(this.constService.arrayRegions, userData.marketplace)) {
                                this.userService.currentUser.marketplace = userData.marketplace;
                            }

                            this.tokenService.setToken(userData,this.rememberMe);
                            this.constService.setRegions(userData);
                            if (userData.change_payment_method)
                                this.openPaypalReregister();
                            else
                                this.checkDestination();
                        }
                    }
                }
            },
            error => {
                this.userService.loader = false;
                this.matDialog.open(ConfirmationDialogComponent, {
                   data: {
                       'dialogFor': 'custom',
                       'title': ' ',
                       'message': 'server is currently unavailable'
                   },
               }).afterClosed().subscribe();
            });
    }

    public signInWithFB() {
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((res) => {
            if (res.authToken && res.id) {
                this.LoginWithFB(res.authToken, res.id);
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    public LoginWithFB(userToken, userId) {
        this.userService.loader = true;
        this.userService.login({ fbAccessToken: userToken, fbUserId: userId }).subscribe(
            (userData) => {
                this.userService.loader = false;
                this.userService.superUserActive = false;
                if (userData.id === 0) {
                    this.userService.currentUser = userData;
                    this.displayError(this.userService.currentUser.message);
                    this.userService.loader = false;
                    this.mixpanelService.track('q login page - LoginWithFB - error');
                    return;
                }
                this.SetIdentity(userData);
                
                if (userData.id !== 0) {
                    if (this.activatedRoute.snapshot.queryParams.amazon_state !== undefined &&
                        this.activatedRoute.snapshot.queryParams.selling_partner_id !== undefined &&
                        this.activatedRoute.snapshot.queryParams.amazon_callback_uri !== undefined) {
                        var user;
                        if (userData.emails) {
                            const data = userData.emails.find(data => (data.submarket === userData.marketplace));
                            user = data.subid;
                        } else {
                            user = userData.userId;
                        }
                        console.log("userId F", user);
                        const redirect_uri = 'https://webapp.quantifyninja.com/spa'
                        const amazonUrl = 'https://sellercentral.amazon.com/apps/authorize/confirm/amzn1.sellerapps.app.ca82764b-41ed-46b1-83c6-298a96554243?amazon_state=' + this.activatedRoute.snapshot.queryParams.amazon_state + '&state=' + user + '&redirect_uri=' + redirect_uri;
                        window.location.replace(amazonUrl);
                    } else {
                        if (userData.userId) {
                            this.userService.currentUser['userId'] = userData.userId;
                        }
                        if (userData.isSuspended) {
                            this.showAccountOnHoldDialog();
                            return;
                        }
                        if (!userData.isVerified) {
                            for (const prop in this.userService.currentUser) { if (this.userService.currentUser.hasOwnProperty(prop)) { delete this.userService.currentUser[prop]; } }
                            this.userService.currentUser.id = userData.id;
                            this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
                            this.tokenService.setToken(userData,this.rememberMe);
                            this.router.navigate(['choose-region']);
                        } else {
                            console.log(userData.sellerId);
                            this.userService.currentUser = userData;
                            this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
                            if (this.userService.currentUser.language && this.constService.languages.indexOf(this.userService.currentUser.language) > 0) {
                                this.textService.setLangBadge(this.userService.currentUser.language);
                                this.textService.changeLang(this.textService.currentText, this.textService[`text${this.userService.currentUser.language}`]);
                            } else {
                                this.textService.setLangBadge('EN');
                                this.textService.changeLang(this.textService.currentText, this.textService.textEN);
                            }
                            // this.tokenService.savePublicId(data.id);
                            if (this.checkRegion(this.constService.arrayRegions, userData.marketplace)) {
                                this.userService.currentUser.marketplace = userData.marketplace;
                            }
                            this.tokenService.setToken(userData,this.rememberMe);
                            this.constService.setRegions(userData);
                            if (userData.change_payment_method)
                                this.openPaypalReregister();
                            else
                                this.checkDestination();
                        }
                    }
                }
            },
            error => {
                this.userService.loader = false;
                this.matDialog.open(ConfirmationDialogComponent, {
                   data: {
                       'dialogFor': 'custom',
                       'title': ' ',
                       'message': 'server is currently unavailable'
                   },
               }).afterClosed().subscribe();
            });
    }

    public login(): void {
        if (!this.checkForm()) {
            return;
        }
        this.userService.loader = true;
        this.userService.login({
            'login': this.loginUser,
            'pass': this.password
        }).subscribe(
            (userData) => {
                this.userService.loader = false;
                this.userService.superUserActive = false;
                if (userData.isMfa) {
                    this.userService.loginUser = { 'login': this.loginUser, 'pass': this.password }
                    this.router.navigate(['mfa']);
                } else {
                if (userData.id === 0) {
                    this.userService.currentUser = userData;
                    this.displayError(this.userService.currentUser.message);
                    this.userService.loader = false;
                    this.mixpanelService.track('q login page - Login - error');
                    return;
                }
                this.SetIdentity(userData);
                if (userData.id !== 0) {
                    if (this.activatedRoute.snapshot.queryParams.amazon_state !== undefined &&
                        this.activatedRoute.snapshot.queryParams.selling_partner_id !== undefined &&
                        this.activatedRoute.snapshot.queryParams.amazon_callback_uri !== undefined) {
                        var user;
                        if (userData.emails) {
                            const data = userData.emails.find(data => (data.submarket === userData.marketplace));
                            user = data.subid;
                        } else {
                            user = userData.userId;
                        }
                        console.log("userId L", user);
                        const redirect_uri = 'https://webapp.quantifyninja.com/spa'
                        const amazonUrl = 'https://sellercentral.this.rememberMeamazon.com/apps/authorize/confirm/amzn1.sellerapps.app.ca82764b-41ed-46b1-83c6-298a96554243?amazon_state=' + this.activatedRoute.snapshot.queryParams.amazon_state + '&state=' + user + '&redirect_uri=' + redirect_uri;
                        window.location.replace(amazonUrl);
                    } else {

                        if (userData.userId) {
                            this.userService.currentUser['userId'] = userData.userId;
                        }
                        if (userData.isSuspended) {
                            this.showAccountOnHoldDialog();
                            return;
                        }
                        if (!userData.isVerified) {
                            for (const prop in this.userService.currentUser) { if (this.userService.currentUser.hasOwnProperty(prop)) { delete this.userService.currentUser[prop]; } }
                            this.userService.currentUser.id = userData.id;
                            this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
                            this.tokenService.setToken(userData,this.rememberMe);
                            this.router.navigate(['choose-region']);
                        } else {
                            console.log(userData.sellerId);
                            this.userService.currentUser = userData;
                            this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
                            if (this.userService.currentUser.language && this.constService.languages.indexOf(this.userService.currentUser.language) > 0) {
                                this.textService.setLangBadge(this.userService.currentUser.language);
                                this.textService.changeLang(this.textService.currentText, this.textService[`text${this.userService.currentUser.language}`]);
                            } else {
                                this.textService.setLangBadge('EN');
                                this.textService.changeLang(this.textService.currentText, this.textService.textEN);
                            }
                            // this.tokenService.savePublicId(data.id);
                            if (this.checkRegion(this.constService.arrayRegions, userData.marketplace)) {
                                this.userService.currentUser.marketplace = userData.marketplace;
                            }
                            this.tokenService.setToken(userData,this.rememberMe);
                            this.constService.setRegions(userData);
                            if (userData.change_payment_method)
                                this.openPaypalReregister();
                            else
                                this.checkDestination();
                        }
                    }
                }
                }
            },
            error => {
                this.userService.loader = false;
                this.matDialog.open(ConfirmationDialogComponent, {
                   data: {
                       'dialogFor': 'custom',
                       'title': ' ',
                       'message': 'server is currently unavailable'
                   },
               }).afterClosed().subscribe();
            });
    }

    public checkRegion(data: any, region: any): boolean {
        for (let i = 0; data.length > i; i++) {
            if (data[i] === region) {
                return true;
            }
        }
    }

    public checkForm(): boolean {
        this.errors = {};
        if (!this.loginForm.valid) {
            if (!this.loginForm.controls.email.valid) {
                this.errors.emailInvalid = true;
                if (this.loginForm.controls.email.errors && this.loginForm.controls.email.errors['required']) {
                    this.displayError(this.textService.currentText.errorRequired);
                } else {
                    this.displayError(this.textService.currentText.errorIncorectEmail);
                }
                return false;
            }
            if (this.password && this.password.length === 0) {
                this.errors.passwordInvalid = true;
                this.displayError(this.textService.currentText.errorRequired);
                return false;
            }
        }
        return this.loginForm.valid;
    }

    private displayError(message: string): void {
        this.userService.loader = false;
        if (message === 'Login failed. Please check your username and password and try again.') {
            this.errors.emailInvalid = true;
            const old_value = this.loginForm.value.email;
            this.loginForm.patchValue({
                email: 'Login is invalid'
            });
            this.loginForm.controls['email'].disable();
            this.loginInput.nativeElement.blur();
            this.resetErrorState(old_value);
            return;
        }
        if (message === 'Login failed. Please check your username and password and try again.') {
            this.passwordInputType = 'text';
            this.errors.passwordInvalid = true;
            const old_value = this.loginForm.value.password;
            this.loginForm.patchValue({
                password: message
            });
            this.loginForm.controls['password'].disable();
            this.passInput.nativeElement.blur();
            this.resetErrorState(old_value);
            return;
        }
        if (this.errors.emailInvalid) {
            const old_value = this.loginForm.value.email;
            this.loginForm.patchValue({
                email: message
            });
            this.loginForm.controls['email'].disable();
            this.loginInput.nativeElement.blur();
            this.resetErrorState(old_value);
            return;
        }
        if (this.errors.passwordInvalid) {
            this.passwordInputType = 'text';
            const old_value = this.loginForm.value.password;
            this.loginForm.patchValue({
                password: message
            });
            this.loginForm.controls['password'].disable();
            this.passInput.nativeElement.blur();
            this.resetErrorState(old_value);
        }
    }

    public showAccountOnHoldDialog(): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'login-hold',
            },
        }).afterClosed().subscribe();
    }

    private resetErrorState(val: string): void {
        setTimeout(() => {
            if (this.errors.emailInvalid) {
                this.loginForm.patchValue({
                    email: val,
                });
                this.errors.emailInvalid = false;
                this.loginForm.controls['email'].enable();
            }
            if (this.errors.passwordInvalid) {
                this.passwordInputType = 'password';
                this.loginForm.patchValue({
                    password: val,
                });
                this.errors.passwordInvalid = false;
                this.loginForm.controls['password'].enable();
            }
        }, 2000);
    }


    public openForgotPassTokenDialog(message: string): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'custom',
                'title': this.textService.currentText.fail,
                'message': message
            },
        }).afterClosed().subscribe();
    }

    public openFBSuccesDialog(): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'custom',
                'title': this.textService.currentText.success,
                'message': this.textService.currentText.facebookConnected
            },
        }).afterClosed().subscribe();
    }

    public openFBFailDialog(): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'custom',
                'title': this.textService.currentText.fail,
                'message': this.textService.currentText.facebookConnectFailed
            },
        }).afterClosed().subscribe();
    }

    public openPaypalReregister() : void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'paypal-reregister'
            },
        }).afterClosed().subscribe((res) => {
            if (res) {
                var redUrl = this.userService.getChangePaymentUrl().subscribe(res =>
                {
                    window.location.href =res.url;
                });
                
            }else
            {
                this.checkDestination();
            }
        });
    }

    public checkDestination(): any {
        this.mixpanelService.track('q login page - checkDestination');
        if (!this.userService.currentUser.hasActivePlan && !this.userService.currentUser.isTrial && !this.userService.currentUser.isVa) {
            return this.router.navigate(['dashboard/upgrade']);
        }
        if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isOrderDashboard) {
            return this.router.navigate(['dashboard/orders-dashboard']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isDashboard) {
            return this.router.navigate(['dashboard/dashboard-schedules']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAutoTemplates) {
            return this.router.navigate(['dashboard/automated-messaging/fba']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAlerts) {
            return this.router.navigate(['dashboard/facebook-notifications']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isMoneyBack) {
            return this.router.navigate(['dashboard/money-back']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isHijacked) {
            return this.router.navigate(['dashboard/hijacked']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isOrders) {
            return this.router.navigate(['dashboard/orders'], { queryParams: { page: 'fba' } });
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isKeyWordOptimzer) {
            return this.router.navigate(['dashboard/keywords-optimizer']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isConversation) {
            return this.router.navigate(['dashboard/facebook-bot-manager/grant-access']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAsin) {
            return this.router.navigate(['dashboard/asin-tracker']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAffiliate) {
            return this.router.navigate(['dashboard/affiliate']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isCoupons) {
            return this.router.navigate(['dashboard/coupons']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isUpgrade) {
            return this.router.navigate(['dashboard/upgrade']);
        } else {
            if (this.userService.currentUser.isVa) {
                return this.router.navigate(['dashboard/user/settings/product-settings']);
            } else {
                return this.router.navigate(['dashboard/user/settings/account-settings']);
            }
        }
    }

    public openTAC(): void {
        this.matDialog.open(TermsDialogComponent, {
          data: {
            'dialogFor': 'login-to-posting',
          },
        }).afterClosed().subscribe(result => {
          //this.checkboxTAC = result;
          //this.ref.detectChanges();
        });
      }
    
      public openPP(): void {
        this.matDialog.open(TermsDialogComponent, {
          data: {
            'dialogFor': 'privacy-policy',
          },
        }).afterClosed().subscribe();
      }

      public SetIdentity(userData: any)
      {
        var userId='';
        if (userData.userId) {
            this.mixpanelService.identify(userData.userId);
            userId=userData.userId;
        }else if (userData.emails) {
            const user = userData.emails.find(data => (data.submarket === userData.marketplace));
            this.mixpanelService.identify(user.subid);
            userId=user.subid;
        }
        this.mixpanelService.people(userData.email,userData.trialStarted,userId);
        this.mixpanelService.track('q login page - SetIdentity');
      }
}
