<div class="main-dialog-container main-dialog-container--bulk default-popup">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container inner-dialog-container--bulk custom-selects"
       [formGroup]="accountForm" name="form" novalidate>
    <div class="header header--bulk">
      <h2>{{textService.currentText.addManagedAccount}}</h2>
    </div>
    <!--[class.has-danger]="errors.email"-->
    <div class="dialog-row" [class.has-danger]="isError">
      <div class="label-name">
        <span>{{textService.currentText.email}}</span>
      </div>
      <input matInput
             (keyup)="isError = false"
             [placeholder]="textService.currentText.addAccountEmail"
             [type]="emailInputType"
             formControlName="email"
             #email>
    </div>
    <!--[class.has-danger]="errors.password"-->
    <div class="dialog-row no-margin" [class.has-danger]="isError">
      <div class="label-name">
        <span>{{textService.currentText.password}}</span>
      </div>
      <input matInput
             (keyup)="isError = false"
             [placeholder]="textService.currentText.addPassword"
             [type]="passwordInputType"
             formControlName="password"
             #password>
    </div>
    <span class="error-msg" *ngIf="isError">{{textService.currentText.linkAccNotExistInDb}}</span>
  </div>
  <div class="dialog-footer space-between bulk-footer">
    <button mat-button class="primary-btn white" (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button
            (click)="saveNewAccount()"
            [disabled]="userService.currentUser.email === accountForm.value.email"
            class="primary-btn">{{textService.currentText.login}}</button>
  </div>
</div>
