<div class="preview">
  <div class="menu">
    <div class="body-row">
      <span *ngIf="showBackButton" (click)="goBack()"><</span>
      <p>{{currentMenu.title || textService.currentText.menu}}</p>
    </div>
    <div class="body-row" *ngFor="let menuItem of currentMenu; let i = index;">
          <input [(ngModel)]="menuItem.name"
                 disabled
                  [placeholder]="textService.currentText.botMenuTypeTitle">
          <span (click)="checkLevel(currentMenu, i)"
                *ngIf="menuItem.menu2level || menuItem.menu3level">
            >
          </span>
    </div>
  </div>
</div>
