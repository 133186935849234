
<div class="login-container" *ngIf="loginIsHide">
  <div class="right-container">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel">

      <div class="carousel-inner">
        <div class="carousel-item active">
          <p>
            It is tremendous help in allowing us to automate the follow-up
            process with
            our Amazon's customers and helping us with inventory management and
            accounting.<br>
            In Our consulting firm, QN is the first software we recommend to all
            our customers who sell on Amazon!
          </p>
          <div class="mt-5 user">
            <div class="user-image"><img
                src="../../../../assets/images/review.jpeg" /></div>
            <div class="user-detail ml-4">
              <h6>Jerome & Jeremie</h6>
              <span class="text-color">Consultants</span>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <p>
            Quntify Ninja is the best all-in-one tool for Amazon sellers out
            there. You get about a dozen tool covering practically all your
            needs for the price of one.
          </p>
          <div class="mt-5 user">
            <div class="user-image"><img
                src="../../../../assets/images/review2.jpg" /></div>
            <div class="user-detail ml-4">
              <h6>Jessica Ricks</h6>
              <span class="text-color">Seller</span>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <p>
            I really like Facebook bot, it’s amazing! It helps me manage a lot
            of things in an easy automated way. It’s also very clear and easy to
            use.
          </p>
          <div class="mt-5 user">
            <div class="user-image"><img
                src="../../../../assets/images/review3.jpg" /></div>
            <div class="user-detail ml-4">
              <h6>Michael Sivan</h6>
              <span class="text-color">Seller</span>
            </div>
          </div>
        </div>
      </div>
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
    </div>
  </div>


  <ng-container *abTestVersion="'buttons-up'">
  <div class="left-container left-container--login">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <img width="64" src="../../../../assets/images/logo2.svg" />
        </div>
      </div>
      <h2 class="mt-3">Welcome to Quantify Ninja</h2>
      <p class="text-center">Everything you need to grow your Amazon business</p>
      <!--<p class="text-center">{{textService.currentText.welcomeBack}}</p>-->
      <div class="form-group-button d-flex justify-content-between">
        <button mat-button class="fb-round-button" (click)="signInWithFB()">
          <img src="../../../../assets/images/facebook-round-logo.svg" />
          Continue With Facebook
        </button>
      </div>
      <div class="form-group-button d-flex justify-content-between">
        <button mat-button class="gog-round-button" (click)="signInWithGoogle()">
          <img src="../../../../assets/images/vector-smart-object.png" />
          Continue With Google
        </button>
    </div>
    <p class="text-center or-row"><b>OR</b></p>
      <form class="login-form" name="form" [formGroup]="loginForm" novalidate>
        <div class="form-group input-border" [class.has-danger]="errors?.emailInvalid">
          <!-- <label for="email">
            <i class="icon email"
              [ngStyle]="{'background-image': 'url(' + '../../../../assets/icons/mail.svg' + ')'}"></i>
          </label> -->
          <input type="email" id="email" #email class="form-control-x"
            formControlName="email"
            [placeholder]="textService.currentText.email"
            [(ngModel)]="loginUser" (focus)="emailInput= true"
            (focusout)="emailInput= false" />
          <div class="icon-container">
            <div class="capslock-icon help-icon inside-input" title="CAPSLOCK
              enabled" *ngIf="capsOn && emailInput">
            </div>
          </div>
        </div>
        <div class="form-group two-help-icons input-border"
          [class.has-danger]="errors?.passwordInvalid">
          <!-- <label for="password"
            (click)="passwordInputType === 'password' ? passwordInputType = 'text' : passwordInputType = 'password'">
            <i class="icon password" [class.visible]="passwordInputType === 'text'"></i>
          </label> -->
          <input [attr.type]="passwordInputType" #pass id="password"
            class="form-control-x" formControlName="password"
            [placeholder]="textService.currentText.password"
            [(ngModel)]="password" (focus)="passwordInput= true"
            (focusout)="passwordInput= false" />
          <div class="icon-container">
            <div class="capslock-icon help-icon inside-input" title="CAPSLOCK
              enabled" *ngIf="capsOn && passwordInput">
            </div>
          </div>
          <div class="icon-container inside-input">
          </div>
        </div>
        <div class="input-hint input-row justify-content-between">
          <a (click)="googleAnalyticsEventsService.emitEvent('Button', 'Forgot
            Password', 'Click', 1); goToResetPassword()"
            class="pointer forgot-pass" href="#">{{textService.currentText.forgotPass1}}</a>
            <!--<mat-checkbox  [checked]="rememberMe" (change)="rememberMe = !rememberMe">{{textService.currentText.rememberMe}}</mat-checkbox> -->
            <input type="checkbox" class="cb-rememberme" id="rememberMe" [checked]="rememberMe" (change)="rememberMe = !rememberMe">
            <label for="rememberMe"><span></span>{{textService.currentText.rememberMe}}</label>
        </div>
        <div class="form-group-button d-flex justify-content-between">
          <button mat-button class="secondary-btn btn-fill"
            (click)="googleAnalyticsEventsService.emitEvent('Button', 'Login',
            'Click', 1);
            mixpanelService.track('q login page - login clicked',{'page':
            'Login','page type':'quantifyninja'}); login()">{{textService.currentText.login}}</button>
        </div>
        <div class="input-hint justify-content-center termsofuse">
          <span class="terms-text">
            By continuing, you agree our <a (click)="openTAC()"><u>Terms of Use</u></a>
            <br>and
            <a (click)="openPP()"><u>{{textService.currentText.privacyPolicy}}</u></a></span>
        </div>
        <div class="input-hint termsofuse login-here">
          <span>Don't have an account? <a
            (click)="googleAnalyticsEventsService.emitEvent('Button',
            'Navigate to SignUp', 'Click',
            1);mixpanelService.track('q login page - sign up clicked',{'page':
            'Login', 'page type':'quantifyninja'}); goToSignUp()"
              class="pointer"> {{textService.currentText.signUp}}</a></span>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<ng-container *abTestVersion="'buttons-down'">
  <div class="left-container left-container--login">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <img src="../../../../assets/icons/logo.svg" />
        </div>
      </div>
      <h2 class="mt-3">Welcome to Quantify Ninja</h2>
      <p class="text-center">Everything you need to grow your Amazon business</p>
      <!--<p class="text-center">{{textService.currentText.welcomeBack}}</p>-->
      
      <form class="login-form" name="form" [formGroup]="loginForm" novalidate>
        <div class="form-group input-border" [class.has-danger]="errors?.emailInvalid">
          <!-- <label for="email">
            <i class="icon email"
              [ngStyle]="{'background-image': 'url(' + '../../../../assets/icons/mail.svg' + ')'}"></i>
          </label> -->
          <input type="email" id="email" #email class="form-control-x"
            formControlName="email"
            [placeholder]="textService.currentText.email"
            [(ngModel)]="loginUser" (focus)="emailInput= true"
            (focusout)="emailInput= false" />
          <div class="icon-container">
            <div class="capslock-icon help-icon inside-input" title="CAPSLOCK
              enabled" *ngIf="capsOn && emailInput">
            </div>
          </div>
        </div>
        <div class="form-group two-help-icons input-border"
          [class.has-danger]="errors?.passwordInvalid">
          <!-- <label for="password"
            (click)="passwordInputType === 'password' ? passwordInputType = 'text' : passwordInputType = 'password'">
            <i class="icon password" [class.visible]="passwordInputType === 'text'"></i>
          </label> -->
          <input [attr.type]="passwordInputType" #pass id="password"
            class="form-control-x" formControlName="password"
            [placeholder]="textService.currentText.password"
            [(ngModel)]="password" (focus)="passwordInput= true"
            (focusout)="passwordInput= false" />
          <div class="icon-container">
            <div class="capslock-icon help-icon inside-input" title="CAPSLOCK
              enabled" *ngIf="capsOn && passwordInput">
            </div>
          </div>
          <div class="icon-container inside-input">
          </div>
        </div>
        <div class="input-hint input-row justify-content-between">
          <a (click)="googleAnalyticsEventsService.emitEvent('Button', 'Forgot
            Password', 'Click', 1); goToResetPassword()"
            class="pointer forgot-pass" href="reset-password">{{textService.currentText.forgotPass1}}</a>
            <!--<mat-checkbox  [checked]="rememberMe" (change)="rememberMe = !rememberMe" >{{textService.currentText.rememberMe}}</mat-checkbox> -->
            <input type="checkbox" class="cb-rememberme" id="rememberMe" [checked]="rememberMe" (change)="rememberMe = !rememberMe">
            <label for="rememberMe"><span></span>{{textService.currentText.rememberMe}}</label>
            
        </div>
        <div class="form-group-button d-flex justify-content-between">
          <button mat-button class="secondary-btn btn-fill"
            (click)="googleAnalyticsEventsService.emitEvent('Button', 'Login',
            'Click', 1);
            mixpanelService.track('q login page - login clicked',{'page':
            'Login','page type':'quantifyninja'}); login()">{{textService.currentText.login}}</button>
        </div>
        <p class="text-center or-row"><b>OR</b></p>
        <div class="form-group-button d-flex justify-content-between">
          <button mat-button class="fb-round-button" (click)="signInWithFB()">
            <img src="../../../../assets/images/facebook-round-logo.svg" />
            Continue With Facebook
          </button>
        </div>
        <div class="form-group-button d-flex justify-content-between">
          <button mat-button class="gog-round-button" (click)="signInWithGoogle()">
            <img src="../../../../assets/images/vector-smart-object.png" />
            Continue With Google
          </button>
      </div>
        <div class="input-hint justify-content-center termsofuse">
          <span class="terms-text">
            By continuing, you agree our <a (click)="openTAC()"><u>Terms of Use</u></a>
            <br>and
            <a (click)="openPP()"><u>{{textService.currentText.privacyPolicy}}</u></a></span>
        </div>
        <div class="input-hint termsofuse login-here">
          <span>Don't have an account? <a
            (click)="googleAnalyticsEventsService.emitEvent('Button',
            'Navigate to SignUp', 'Click',
            1);mixpanelService.track('q login page - sign up clicked',{'page':
            'Login', 'page type':'quantifyninja'}); goToSignUp()"
              class="pointer"> {{textService.currentText.signUp}}</a></span>
        </div>
      </form>
    </div>
  </div>
</ng-container>
</div>