import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';

@Injectable()

export class SequencesService extends UserService {

  public getSequencesList(publicId: string): Observable<any> {
    return this.post('sequences/sequences_list', {
      publicId: publicId
    });
  }

  public changeSequenceStatus(publicId: string, funnelId: any, funnelStatus: any): Observable<any> {
    return this.post('sequences/change_sequence_status', {
      publicId: publicId,
      funnelId: funnelId,
      funnelStatus: funnelStatus
    });
  }

  public getFollowupRules(publicId: string, funnelId: any): Observable<any> {
    return this.post('sequences/rules', {
      publicId: publicId,
      funnelId: funnelId
    });
  }

  public createSequence(data: any): Observable<any> {
    return this.post('sequences/create_sequence', data);
  }

  public deleteSequence(publicId: string, funnelId: any): Observable<any> {
    return this.post('sequences/delete_sequence', {
      publicId: publicId,
      funnelId: funnelId
    });
  }

  public deleteRule(publicId: string, ruleId: any, funnelId: any): Observable<any> {
    return this.post('sequences/delete_rule', {
      publicId: publicId,
      ruleId: ruleId,
      funnelId: funnelId
    });
  }

  public saveRule(data: any): Observable<any> {
    return this.post('sequences/save_rule', data);
  }

}
