import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Functions} from '../../../services/functions';
import {UserService} from '../../../services/user.service';
import {ConstService} from '../../../services/const-service';
import {FormBuilder, Validators} from '@angular/forms';
import {TextService} from '../../../services/text.service';
import {FbService} from '../../../services/fb.service';
import {SequencesService} from '../../../services/sequences.service';

@Component({
  templateUrl: './sequence-dialog.component.html',
  styleUrls: ['./sequence-dialog.component.scss']
})

export class SequenceDialogComponent implements OnInit {
  public pages: any;
  public errors: any = {};
  public submitForm = this.fb.group({
    name: ['', [Validators.required]],
    status: ['', [Validators.required]],
    trigger: ['', [Validators.required]],
    page: ['', [Validators.required]],
    tag: [''],
  });
  public sequenceData = {
    publicId: this.userService.currentUser.id,
    funnelName: this.submitForm.controls['name'].value,
    pageId: this.submitForm.controls['page'].value.pageId,
    trigger: this.submitForm.controls['trigger'].value.tagid,
    tagId: this.submitForm.controls['tag'].value.tagid,
    funnelStatus: this.submitForm.controls['status'].value
  };

constructor(public userService: UserService,
              public fb: FormBuilder,
              public constService: ConstService,
              public textService: TextService,
              public sequencesService: SequencesService,
              public fbService: FbService,
              public ref: ChangeDetectorRef,
              public functions: Functions,
              public matDialog: MatDialog,
              public dialogRef: MatDialogRef<SequenceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit(): void {
    this.userService.dialogLoader = true;
    this.submitForm.controls['status'].setValue(true);
    this.fbService.getPagesFb(this.userService.currentUser.id).subscribe((data) => {
      this.pages = data;
      this.fbService.facebookGetTags(this.userService.currentUser.id).subscribe((tags) => {
        this.userService.tags = tags;
        this.userService.dialogLoader = false;
      });
    });
  }


  public saveSequence(): void {
    if (this.checkForm()) {
      this.getDataToSave();
      this.sequencesService.createSequence(this.sequenceData).subscribe(res => {
        if (res.isSuccess) {
          this.userService.showNotification = true;
          this.userService.notificationMessage = this.textService.currentText.saveSequenceSuccessText;
          this.userService.notificationTitle = this.textService.currentText.success;
          this.dialogRef.close('getNewData');
        }
      });
    }
  }

  public getDataToSave(): void {
    this.sequenceData.funnelStatus = this.submitForm.controls['status'].value;
    this.sequenceData.funnelName = this.submitForm.controls['name'].value;
    this.sequenceData.trigger = this.submitForm.controls['trigger'].value;
    this.sequenceData.pageId = this.submitForm.controls['page'].value.pageId;
    this.sequenceData.tagId = this.submitForm.controls['tag'].value.tagId;

  }

  public checkForm(): boolean {
      if (!this.submitForm.controls.name.valid) {
        this.errors.name = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.trigger.valid) {
        this.errors.trigger = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.tag.value.tagId && this.submitForm.controls.trigger.value === 'Tagged') {
        this.errors.tag = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.page.valid) {
        this.errors.page = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      return true;
  }

  private displayError(message?: string): void {
    if (!this.submitForm.controls.name.valid) {
      this.errors.name = true;
      const old_value = this.submitForm.value.name;
      this.submitForm.patchValue({
        name: message
      });
      this.submitForm.controls['name'].disable();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.trigger.valid) {
      this.errors.trigger = true;
      const old_value = this.submitForm.value.trigger;
      this.submitForm.patchValue({
        trigger: message
      });
      this.submitForm.controls['trigger'].disable();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.tag.value.tagid && this.submitForm.controls.trigger.value === 'Tagged') {
      this.errors.tag = true;
      const old_value = this.submitForm.value.tag;
      this.submitForm.patchValue({
        tag: message
      });
      this.submitForm.controls['tag'].disable();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.page.valid) {
      this.errors.page = true;
      const old_value = this.submitForm.value.page;
      this.submitForm.patchValue({
        page: message
      });
      this.submitForm.controls['page'].disable();
      this.resetErrorState(old_value);
      return;
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.name) {
        this.submitForm.patchValue({
          name: val,
        });
        this.errors.name = false;
        this.submitForm.controls['name'].enable();
        return;
      }
      if (this.errors.trigger) {
        this.submitForm.patchValue({
          trigger: val,
        });
        this.errors.trigger = false;
        this.submitForm.controls['trigger'].enable();
      }
      if (this.errors.tag) {
        this.submitForm.patchValue({
          tag: val,
        });
        this.errors.tag = false;
        this.submitForm.controls['tag'].enable();
      }
      if (this.errors.page) {
        this.submitForm.patchValue({
          page: val,
        });
        this.errors.page = false;
        this.submitForm.controls['page'].enable();
      }
    }, 2000);
  }
}
