import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TextService} from '../../services/text.service';

@Component({
  selector: 'ninja-404-page',
  templateUrl: './404-page.component.html',
  styleUrls: ['./404-page.component.scss']
})

export class Page404Component implements OnInit {
  constructor(public router: Router,
              public textService: TextService
  ) {
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate(['']);
    }, 3000);
  }
}
