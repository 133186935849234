<div class="menu" [class.main-menu]="showImageMenu" *ngIf="showImageMenu">
  <div class="close-btn" (click)="showImageOption(); $event.stopPropagation(); $event.preventDefault()"></div>
  <div class="main">
    <button class="action-button"
            *ngIf="!showImageMenu"
            (click)="showIconInsideMenu();
             $event.stopPropagation();
             $event.preventDefault()">
      <img src="assets/icons/bot-msg-btn.svg">
      + {{textService.currentText.botNewButton}}
    </button>
    <div class="menu-footer">
      <button class="action-button delete"
              (click)="deleteAction.emit(true); $event.stopPropagation(); $event.preventDefault()">
        <img src="assets/icons/trash.svg" alt="">
        <span>{{textService.currentText.delete}}</span>
      </button>
      <button class="action-button">
        <img src="../../../assets/icons/copy-clipboard.svg" alt="">
        <span>{{textService.currentText.copy}}</span>
      </button>
    </div>
  </div>
</div>
<div class="menu menu-close-checker-template"
     [class.main-menu]="showTemplateMenu"
     *ngIf="showTemplateMenu"
     [ngStyle]="{'bottom' : '88%'}">
  <div class="close-btn "
       (click)="showTemplateMenu = !showTemplateMenu; $event.stopPropagation(); $event.preventDefault()"></div>
  <div class="main menu-close-checker-template">
    <button class="action-button menu-close-checker-template"
            [ngStyle]="{'color': data?.childs?.length > 2 ? '#d5ddee' : null}"
            [disabled]="data?.childs?.length > 2"
            (click)="addButton.emit({el: 'test'});
             showIconInsideMenu();
              showTemplateMenu = !showTemplateMenu;
               $event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-msg-btn.svg">
      + {{textService.currentText.botNewButton}}
    </button>
    <div class="menu-footer menu-close-checker-template">
      <button class="action-button delete menu-close-checker-template"
              (click)="actionDelete(); closeActionOptions(); $event.stopPropagation(); $event.preventDefault()">
        <img src="assets/icons/trash.svg" alt="">
        <span class="menu-close-checker-template">{{textService.currentText.delete}}</span>
      </button>
      <button class="action-button menu-close-checker-template">
        <img src="../../../assets/icons/copy-clipboard.svg" alt="">
        <span class="menu-close-checker-template">{{textService.currentText.copy}}</span>
      </button>
    </div>
  </div>
</div>
<div class="menu action-menu" *ngIf="showActionMenu">
  <div class="close-btn" (click)="closeActionOptions()"></div>
  <div class="main" *ngIf="showActionMainMenu">
    <button class="action-button" (click)="showActionTriggerOptions(); $event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-msg-trigger.svg">
      {{textService.currentText.botTriggerConversation}}
    </button>
    <button class="action-button"
            (click)="saveUnsubscribe(); closeActionOptions(); $event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-msg-unsubscribe.svg">
      {{textService.currentText.unsubscribe}}
    </button>
    <button class="action-button"
            (click)="saveSubscribe(); closeActionOptions(); $event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-msg-subscribe.svg">
      {{textService.currentText.subscribe}}
    </button>
    <button class="action-button"
            (click)="showActionDelayOptions(); $event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-msg-delay.svg">
      {{textService.currentText.delay}}
    </button>
    <button class="action-button"
            (click)="showActionTypingOptions(); changeActionText.emit('Typing status'); $event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-msg-typing.svg">
      {{textService.currentText.botTypingStatus}}
    </button>
    <div class="menu-footer">
      <button class="action-button delete"
              (click)="deleteAction.emit(true); $event.stopPropagation(); $event.preventDefault()">
        <img src="assets/icons/trash.svg" alt="">
        <span>{{textService.currentText.delete}}</span>
      </button>
      <button class="action-button">
        <img src="../../../assets/icons/copy-clipboard.svg" alt="">
        <span>{{textService.currentText.copy}}</span>
      </button>
    </div>
  </div>
  <div class="icon-options" *ngIf="showActionTriggerMenu">
    <div class="menu-header">
      <img src="assets/icons/bot-msg-trigger.svg">
      {{textService.currentText.botTriggerConversation}}
    </div>
    <div class="select-holder custom-selects">
      <div
          (click)="functions.setDropDownPosition(allSalesChannelsSelect, 'allSalesChannels'); $event.stopPropagation(); $event.preventDefault()"
          #allSalesChannelsSelect [class.active]="allSalesChannels.panelOpen"
          class="select-wrapper">
        <mat-form-field>
          <mat-select [placeholder]="textService.currentText.botSelectConversation"
                      shouldPlaceholderFloat="false"
                      panelClass="default-dropdown allSalesChannels"
                      #allSalesChannels
                      [(ngModel)]="triggerConversation"
                      disableRipple
                      name="allSalesChannels">
            <mat-option *ngFor="let select of userService.conversationList" [value]="select.conversationId">
              {{ select.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="menu-footer">
      <button class="action-button secondary"
              (click)="showActionTriggerMenu = false; showActionMainMenu = true; $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.cancel}}</span>
      </button>
      <button class="action-button primary"
              (click)="saveTriggerConversation();
               closeActionOptions(); $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.save}}</span>
      </button>
    </div>
  </div>
  <div class="icon-options" *ngIf="showActionDelayMenu">
    <div class="menu-header">
      <img src="assets/icons/bot-msg-delay.svg">
      {{textService.currentText.delay}}
    </div>
    <p class="head-descr">{{textService.currentText.botSetDelay}}</p>
    <div class="counter menu-close-checker">
      <div class="controls left menu-close-checker" (click)="delayCounter = delayCounter - 1">-</div>
      <input type="number" class="menu-close-checker" placeholder="0" [(ngModel)]="delayCounter">
      <div class="controls right menu-close-checker" (click)="delayCounter = delayCounter + 1">+</div>
      <span class="sec">{{textService.currentText.sec}}</span>
    </div>
    <div class="menu-footer">
      <button class="action-button secondary"
              (click)="showActionDelayMenu = false; showActionMainMenu = true; $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.cancel}}</span>
      </button>
      <button class="action-button primary"
              (click)="saveDelay(); closeActionOptions(); $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.save}}</span>
      </button>
    </div>
  </div>
  <div class="icon-options" *ngIf="showActionTypingMenu">
    <div class="menu-header">
      <img src="assets/icons/bot-msg-typing.svg">
      {{textService.currentText.typing}}
    </div>
    <p class="head-descr">{{textService.currentText.botSetSecons}}</p>
    <div class="counter menu-close-checker">
      <div class="controls left menu-close-checker" (click)="typingCounter = typingCounter - 1">-</div>
      <input type="number" class="menu-close-checker" placeholder="0" [(ngModel)]="typingCounter">
      <div class="controls right menu-close-checker" (click)="typingCounter = typingCounter + 1">+</div>
      <span class="sec">{{textService.currentText.sec}}</span>
    </div>
    <div class="menu-footer">
      <button class="action-button secondary"
              (click)="showActionTypingMenu = false; showActionMainMenu = true; $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.cancel}}</span>
      </button>
      <button class="action-button primary"
              (click)="saveTypingStatus(); closeActionOptions(); $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.save}}</span>
      </button>
    </div>
  </div>
</div>
<div class="menu" [class.main-menu]="showMessageMainMenu" *ngIf="showMessageMenu">
  <div class="close-btn" (click)="closeMessageOption(); $event.stopPropagation(); $event.preventDefault()"></div>
  <div class="main" *ngIf="showMessageMainMenu">
    <button class="action-button"
            (click)="showVariableOptions(); $event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-variable.svg">
      + {{textService.currentText.variable}}
    </button>
    <button class="action-button" (click)="showCouponsOptions(); $event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-coupon.svg">
      + {{textService.currentText.coupon}}
    </button>
    <div class="menu-footer">
      <button class="action-button delete"
              (click)="deleteAction.emit(true); closeMessageOption(); $event.stopPropagation(); $event.preventDefault()">
        <img src="assets/icons/trash.svg" alt="">
        <span>{{textService.currentText.delete}}</span>
      </button>
      <button class="action-button">
        <img src="../../../assets/icons/copy-clipboard.svg" alt="">
        <span>{{textService.currentText.copy}}</span>
      </button>
    </div>
  </div>
  <div class="variable-options" *ngIf="showMessageVariableMenu">
    <div class="menu-header">
      <img src="assets/icons/bot-variable.svg">
      + {{textService.currentText.variable}}
    </div>
    <div class="select-holder custom-selects">
      <div (click)="functions.setDropDownPosition(PageName, 'days'); $event.stopPropagation(); $event.preventDefault()"
           #PageName [class.active]="days.panelOpen"
           class="select-wrapper">
        <mat-form-field>
          <mat-select [placeholder]="textService.currentText.variables"
                      shouldPlaceholderFloat="false"
                      panelClass="default-dropdown days"
                      [(ngModel)]="variable"
                      disableRipple
                      #days
                      name="page">
            <mat-option *ngFor="let select of variables" [value]="select.text">
              {{ select.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="menu-footer">
      <button class="action-button secondary"
              (click)="showMessageOptions(); $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.cancel}}</span>
      </button>
      <button class="action-button primary"
              [disabled]="!variable"
              (click)="emitTextVariable.emit(variable); closeMessageOption();
               $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.save}}</span>
      </button>
    </div>
  </div>
  <div class="coupons-options" *ngIf="showMessageCouponsMenu">
    <div class="menu-header">
      <img src="assets/icons/bot-variable.svg">
      + {{textService.currentText.coupons}}
    </div>
    <div class="select-holder custom-selects">
      <div (click)="functions.setDropDownPosition(PageName, 'days'); $event.stopPropagation(); $event.preventDefault()"
           #PageName [class.active]="days.panelOpen"
           class="select-wrapper">
        <mat-form-field>
          <mat-select [placeholder]="textService.currentText.coupons"
                      shouldPlaceholderFloat="false"
                      panelClass="default-dropdown days"
                      disableRipple
                      [(ngModel)]="coupon"
                      #days
                      name="page">
            <mat-option *ngFor="let select of userService.coupons" [value]="select">
              {{ select.couponName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="menu-footer">
      <button class="action-button secondary"
              (click)="showMessageOptions(); $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.cancel}}</span>
      </button>
      <button class="action-button primary"
              [disabled]="!coupon"
              (click)="emitTextCoupon.emit(coupon); closeMessageOption(); $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.save}}</span>
      </button>
    </div>
  </div>
</div>
<div class="menu" *ngIf="showButtonMenu"
     [ngStyle]="{'bottom' : bottom}"
     [class.main-menu]="showButtonMenu"
     [class.custom-bottom]="fbService.isTemplateButton">
  <div class="close-btn" (click)="closeButtonOption(); $event.stopPropagation(); $event.preventDefault()"></div>
  <div class="main" *ngIf="showButtonMainMenu">
    <button class="action-button menu-close-checker-template"
            *ngIf="data?.type === 'template'"
            [ngStyle]="{'color': data?.childs?.length > 2 ? '#d5ddee' : null}"
            [disabled]="data?.childs?.length > 2"
            (click)="addButton.emit({el: 'test'}); showButtonMenu = false; $event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-msg-btn.svg">
      + {{textService.currentText.botNewButton}}
    </button>
    <button class="action-button" (click)="showTagMenu(); $event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-tag.svg">
      + {{textService.currentText.tag}} <span *ngIf="isTag" class="variable-notification">1</span>
    </button>
    <button class="action-button" (click)="showIconInsideMenu(); $event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-icon-inside.svg">
      + {{buttonName || textService.currentText.botIconInside}} <span *ngIf="isUrl" class="variable-notification">1</span>
    </button>
    <div class="menu-footer">
      <button class="action-button delete"
              [disabled]="disabledTemplateDeleteLastButton"
              (click)="deleteAction.emit(true); $event.stopPropagation(); $event.preventDefault()">
        <img src="assets/icons/trash.svg" alt="">
        <span>{{textService.currentText.delete}}</span>
      </button>
      <button class="action-button">
        <img src="../../../assets/icons/copy-clipboard.svg" alt="">
        <span>{{textService.currentText.copy}}</span>
      </button>
    </div>
  </div>
  <div class="tag-options" *ngIf="showButtonTagMenu">
    <div class="menu-header">
      <img src="assets/icons/bot-tag.svg">
      + {{textService.currentText.tag}}
    </div>
    <div class="select-holder custom-selects">
      <div #PageName [class.active]="tags.panelOpen"
           class="select-wrapper">
        <!--<div (click)="functions.setDropDownPosition(PageName, 'tags');-->
      <!--$event.stopPropagation(); $event.preventDefault()" #PageName [class.active]="tags.panelOpen"-->
           <!--class="select-wrapper">-->
        <mat-form-field>
          <mat-select [placeholder]="tag?.tagName || textService.currentText.tag"
                      shouldPlaceholderFloat="false"
                      panelClass="default-dropdown variables"
                      disableRipple
                      #tags
                      [(ngModel)]="tag">
            <mat-option class="menu-close-checker" *ngFor="let select of userService.tags" [value]="select">
              <span [class.highlighted-select]="tag?.tagId === select?.tagId">{{ select.name }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <p class="new-tag" *ngIf="!showButtonNewTag"
       (click)="showButtonNewTag = !showButtonNewTag; $event.stopPropagation(); $event.preventDefault()">
      + {{textService.currentText.botNewTag}}</p>
    <div class="new-tag-block" *ngIf="showButtonNewTag">
      <input [placeholder]="showError ? 'Enter new tag' : 'My new tag'" #tagInput type="text" [class.tag-error]="showError"
             (click)="$event.stopPropagation(); $event.preventDefault()">
      <button (click)="createTag(tagInput.value);
                      $event.stopPropagation(); $event.preventDefault()">
        {{textService.currentText.add}}
      </button>
      <!--userService.tags.push({name: tagInput.value});-->
    </div>
    <div class="menu-footer">
      <button class="action-button secondary"
              (click)="showButtonOptions(); $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.cancel}}</span>
      </button>
      <button class="action-button primary"
              [disabled]="!tag"
              (click)="saveTag(tag); showButtonOptions();
              $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.save}}</span>
      </button>
    </div>
  </div>
  <div class="icon-options" *ngIf="showButtonIconMenu">
    <div class="menu-header" (click)="$event.stopPropagation(); $event.preventDefault()">
      <img src="assets/icons/bot-icon-inside.svg">
      + {{buttonName || textService.currentText.botIconInside}}
    </div>
    <input type="text" class="icon-input" #urlInput [placeholder]="buttonName === 'URL Link' ? 'Website URL' : 'Image URL'"
           [(ngModel)]="isUrl"
           (keyup)="buttonName ? emitUrlButton.emit(urlInput.value) : emitUrlButton.emit(urlInput.value);"
           (click)="$event.stopPropagation(); $event.preventDefault()">
    <div class="menu-footer">
      <button class="action-button secondary"
              (click)="showButtonOptions(); $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.cancel}}</span>
      </button>
      <button class="action-button primary"
              (click)="showButtonOptions(); $event.stopPropagation(); $event.preventDefault()">
        <span>{{textService.currentText.save}}</span>
      </button>
    </div>
  </div>
</div>
