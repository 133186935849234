import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { ShareService } from 'src/app/services/share.service';
import { StorageBrowser } from 'src/app/services/storage.browser';
import { Sort } from '@angular/material/sort';
import { ErrorsComponent } from '../dashboard-schedules/popups/errors/errors.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { EditShortNameDialogComponent } from '../../dialogs/edit-short-name/edit-short-name-dialog.component';
import { EditProductCostDialogComponent } from '../../dialogs/edit-product-cost/edit-product-cost-dialog.component';
import { TextService } from '../../../services/text.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';
@Component({
  selector: 'ninja-orders-popup',
  templateUrl: './orders-popup.component.html',
  styleUrls: ['./orders-popup.component.scss']
})

export class OrdersPopupComponent implements OnInit {
  expandedElement: any;
  currentIndexElement: any;
  filtersName = '';
  sku = '';
  asin = '';
  quantity = '';
  id = '';
  cost = '';
  totalProfit = '';
  promotionValue = '';
  promotionid = '';
  sortedData: any;
  isFilters: boolean;
  route: any;
  originalData: any;
  currencySymbol: any;
  constructor(public router: Router,
    public textService: TextService,
    public ref: ChangeDetectorRef,
    public mixpanelService: MixpanelService,
    public location: Location,
    public dialogRef: MatDialogRef<OrdersPopupComponent>, public userService: UserService, @Inject(MAT_DIALOG_DATA) public data: any, private shareService: ShareService, public storageBrowser: StorageBrowser, private dialog: MatDialog) {
    router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
      }
    });
    this.originalData = this.data.dataSource;
    this.mixpanelService.init(null);
  }

  public ngOnInit() {
    this.currencySymbol = this.storageBrowser.get('$Currency');
    // this.data.dataSource.map(item => {
    //   item.rowData.map(val => {
    //     val.cost = this.getCurrencySymbol(val.cost);
    //     if (this.data.title == 'Orders') {
    //       val.totalProfit = this.getCurrencySymbol(val.totalProfit);
    //     }
    //     if (this.data.title == 'Promotions') {
    //       val.promotionValue = this.getCurrencySymbol(val.promotionValue);
    //     }
    //     val.feesList.map(fees => {
    //       fees.Event = this.getCurrencySymbol(fees.Event);
    //     });
    //   })
    // });
  }

  sortData(sort: Sort) {
    const data = this.data.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    if (this.data.title == 'Orders') {
      this.sortedData = this.sortOrdersData(data, sort);
    }
    if (this.data.title == 'Promotions') {
      this.sortedData = this.sortPromotionData(data, sort);
    }
    if (this.data.title == 'Promotions Summary') {
      this.sortedData = this.sortPromotionSummayData(data, sort);
    }
    this.data.dataSource = this.sortedData;
  }

  sortPromotionSummayData(data, sort) {
    console.log("sortPromotionSummayData", data, sort);
    return data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'promotionName': return compare(a.promotionName, b.promotionName, isAsc);
        case 'uses': return compare(a.uses, b.uses, isAsc);
        default: return 0;
      }
    });
  }

  sortPromotionData(data, sort) {
    return data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.rowData[0].name.toLowerCase(), b.rowData[0].name.toLowerCase(), isAsc);
        case 'sku': return compare(a.rowData[0].sku.toLowerCase(), b.rowData[0].sku.toLowerCase(), isAsc);
        case 'promotionid': return compare(a.rowData[0].promotionid.toLowerCase(), b.rowData[0].promotionid.toLowerCase(), isAsc);
        case 'promotionValue': return compare(a.rowData[0].promotionValue, b.rowData[0].promotionValue, isAsc);
        case 'cost': return compare(a.rowData[0].cost, b.rowData[0].cost, isAsc);
        case 'quantity': return compare(a.rowData[0].quantity, b.rowData[0].quantity, isAsc);
        case 'id': return compare(a.id, b.id, isAsc);
        default: return 0;
      }
    });
  }

  sortOrdersData(data, sort) {
    console.log("sortOrdersData", data, sort);
    return data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.rowData[0].name.toLowerCase(), b.rowData[0].name.toLowerCase(), isAsc);
        case 'SKU': return compare(a.rowData[0].SKU.toLowerCase(), b.rowData[0].SKU.toLowerCase(), isAsc);
        case 'asin': return compare(a.rowData[0].asin.toLowerCase(), b.rowData[0].asin.toLowerCase(), isAsc);
        case 'quantity': return compare(a.rowData[0].quantity, b.rowData[0].quantity, isAsc);
        case 'cost': return compare(a.rowData[0].cost, b.rowData[0].cost, isAsc);
        case 'totalProfit': return compare(a.rowData[0].totalProfit, b.rowData[0].totalProfit, isAsc);
        case 'id': return compare(a.id, b.id, isAsc);
        default: return 0;
      }
    });
  }

  closePopup() {
    this.dialogRef.close();
  }

  openPromotionSummary() {
    this.dialogRef.close();
    this.userService.loaderObesrver.next(true);
    if (this.data.orderType == 'currentorders' || this.data.orderType == 'profitpie') {
      this.userService.currentpromotionSummary(this.data.formData).subscribe((res: any) => {
        if (res) {
          this.dialog.open(OrdersPopupComponent, {
            panelClass: 'orderpopup',
            width: '1300px',
            height: '500px',
            data: { dataSource: res, title: 'Promotions Summary' }
          });
          this.userService.loaderObesrver.next(false);
        }
      }, (err) => {
        this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
      });
    } else if (this.data.orderType == 'previousorders') {
      this.userService.previouspromotionSummary(this.data.formData).subscribe((res: any) => {
        if (res) {
          this.dialog.open(OrdersPopupComponent, {
            panelClass: 'orderpopup',
            width: '1300px',
            height: '500px',
            data: { dataSource: res, title: 'Promotions Summary' }
          });
          this.userService.loaderObesrver.next(false);
        }
      }, (err) => {
        this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
      });
    }
    // else if (this.data.orderType == 'profitpie') {
    //   this.userService.promotionSummary(this.data.formData).subscribe((res: any) => {
    //     if (res) {
    //       this.dialog.open(OrdersPopupComponent, {
    //         panelClass: 'orderpopup',
    //         width: '700px',
    //         height: '500px',
    //         data: { dataSource: res, title: 'Promotions Summary' }
    //       });
    //       this.userService.loaderObesrver.next(false);
    //     }
    //   }, (err) => {
    //     this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    //   });
    // }

  }

  // getCurrencySymbol(value) {
  //   var currency_symbols = {
  //     'USD': '$', // US Dollar
  //     'AUD': '$',
  //     'CAD': '$',
  //     'MXN': '$',
  //     'GBP': '£', // British Pound Sterling
  //     'INR': '₹', // Indian Rupee
  //     'JPY': '¥', // Japanese Yen
  //   };
  //   let currency_name = this.storageBrowser.get('$Currency');
  //   if (currency_symbols[currency_name] !== undefined) {
  //     return currency_symbols[currency_name] + value;
  //   } else {
  //     return value;
  //   }
  // }

  expandedRow(currentIndex) {

    if (this.expandedElement == currentIndex) {
      this.expandedElement = this.currentIndexElement = null;
    } else {
      this.expandedElement = currentIndex;
      this.currentIndexElement = currentIndex;
    }
  }

  editShortName(main): void {
    this.dialog.open(EditShortNameDialogComponent, {
      data: {
        'dialogFor': 'edit-short-name-dialog',
        'product': main,
      },
      width: '800px'
    }).afterClosed().subscribe((result) => {
      if (result) {
        for (let i = 0; i < this.data.dataSource.length; i++) {
          if (this.data.dataSource[i].rowData[0].SKU === result.sku || this.data.dataSource[i].rowData[0].sku === result.sku) {
            this.data.dataSource[i].rowData[0].name = result.defaultShortName;
          }
        }
      }
    });
  }

  editProductCost(main): void {
    this.dialog.open(EditProductCostDialogComponent, {
      data: {
        'dialogFor': 'edit-product-dialog',
        'product': main,
      },
      panelClass: 'edit-product-dialog',
      width: '1350px'
    }).afterClosed().subscribe((result) => {
      if (result) {
        for (let i = 0; i < this.data.dataSource.length; i++) {
          if (this.data.dataSource[i].rowData[0].SKU === result.SKU || this.data.dataSource[i].rowData[0].sku === result.SKU) {
            this.data.dataSource[i].rowData[0].name = result.shortName;
            this.data.dataSource[i].rowData[0].cost = result.cost;
          }
        }
      }
    });
  }

  liveSearchName() {
    this.data.dataSource = this.data.dataSource.filter(this.liveSearchCallback.bind(this));
    this.ref.detectChanges();
  }

  liveSearchCallback(value): boolean {
    return value.rowData[0].name.toLowerCase().indexOf(this.filtersName.toLowerCase()) !== -1;
  }

  liveSearchSku(): void {
    this.data.dataSource = this.data.dataSource.filter(this.liveSearchCallbackSku.bind(this));
    this.ref.detectChanges();
  }

  liveSearchCallbackSku(value): boolean {
    return value.rowData[0].sku ? value.rowData[0].sku.toLowerCase().indexOf(this.sku.toLowerCase()) !== -1 : value.rowData[0].SKU.toLowerCase().indexOf(this.sku.toLowerCase()) !== -1;
  }

  liveSearchAsin(): void {
    this.data.dataSource = this.data.dataSource.filter(this.liveSearchCallbackAsin.bind(this));
    this.ref.detectChanges();
  }

  liveSearchCallbackAsin(value: any): boolean {
    return value.rowData[0].asin.toLowerCase().indexOf(this.asin.toLowerCase()) !== -1;
  }

  liveSearchQuantity(): void {
    this.data.dataSource = this.data.dataSource.filter(this.liveSearchCallbackQuantity.bind(this));
    this.ref.detectChanges();
  }

  liveSearchCallbackQuantity(value: any): boolean {
    return value.rowData[0].quantity == this.quantity;
  }

  liveSearchCost(): void {
    this.data.dataSource = this.data.dataSource.filter(this.liveSearchCallbackCost.bind(this));
    this.ref.detectChanges();
  }

  liveSearchCallbackCost(value: any): boolean {
    return value.rowData[0].cost == this.cost;
  }

  liveSearchTotalProfit(): void {
    this.data.dataSource = this.data.dataSource.filter(this.liveSearchCallbackTotalProfit.bind(this));
    this.ref.detectChanges();
  }

  liveSearchCallbackTotalProfit(value: any): boolean {
    return value.rowData[0].totalProfit == this.totalProfit;
  }

  liveSearchPromotionValue(): void {
    this.data.dataSource = this.data.dataSource.filter(this.liveSearchCallbackPromotionValue.bind(this));
    this.ref.detectChanges();
  }

  liveSearchCallbackPromotionValue(value: any): boolean {
    return value.rowData[0].promotionValue == this.promotionValue;
  }

  liveSearchId(): void {
    this.data.dataSource = this.data.dataSource.filter(this.liveSearchCallbackId.bind(this));
    this.ref.detectChanges();
  }

  liveSearchCallbackId(value: any): boolean {
    return value.id.indexOf(this.id) !== -1;
  }

  liveSearchPromotionId() {
    this.data.dataSource = this.data.dataSource.filter(this.liveSearchCallbackPromotionId.bind(this));
    this.ref.detectChanges();
  }

  liveSearchCallbackPromotionId(value): boolean {
    return value.rowData[0].promotionid ? value.rowData[0].promotionid.toLowerCase().indexOf(this.promotionid.toLowerCase()) !== -1 : false;
  }

  clearFilter() {
    this.filtersName = '';
    this.asin = '';
    this.sku = '';
    this.quantity = '';
    this.cost = '';
    this.id = '';
    this.promotionValue = '';
    this.totalProfit = '';
    this.promotionid = '';
    this.data.dataSource = JSON.parse(JSON.stringify(this.originalData));
    this.ref.detectChanges();
  }

  checkFilters() {
    this.data.dataSource = JSON.parse(JSON.stringify(this.originalData));
    if (this.filtersName.length > 0) {
      this.liveSearchName();
    }
    if (this.asin.length > 0) {
      this.liveSearchAsin();
    }
    if (this.sku.length > 0) {
      this.liveSearchSku();
    }
    if (this.quantity.length > 0) {
      this.liveSearchQuantity();
    }
    if (this.cost.length > 0) {
      this.liveSearchCost();
    }
    if (this.id.length > 0) {
      this.liveSearchId();
    }
    if (this.totalProfit.length > 0) {
      this.liveSearchTotalProfit();
    }
    if (this.promotionValue.length > 0) {
      this.liveSearchPromotionValue();
    }
    if (this.promotionid.length > 0) {
      this.liveSearchPromotionId();
    }
  }

}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}