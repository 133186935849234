import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ConstService} from '../../../../services/const-service';
import {Functions} from '../../../../services/functions';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {FbService} from '../../../../services/fb.service';
import {TextService} from '../../../../services/text.service';
import {ConfirmationDialogComponent} from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'ninja-conversation-manager',
  templateUrl: './conversation-manager.component.html',
  styleUrls: ['./conversation-manager.component.scss']
})

export class ConversationManagerComponent implements OnInit {
  public isFilters = true;
  public conversationList: any;
  public currentConversationList: any;
  public pages: any;
  public filters = {
    name: null,
    triggerBy: null,
    page: null
  };
  public firstTime = false;

  constructor(public matDialog: MatDialog,
              public constService: ConstService,
              public userService: UserService,
              public fbService: FbService,
              public textService: TextService,
              public ref: ChangeDetectorRef,
              public router: Router,
              public activatedRoute: ActivatedRoute,
              public functions: Functions) {
  }

  public ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams.success) {
      this.openFBSuccesDialog();
    }
    this.userService.loaderObesrver.next(true);
    this.fbService.getPagesFb(this.userService.currentUser.id).subscribe((data) => {
      this.pages = data;
    });
    this.fbService.getConversation(this.userService.currentUser.id).subscribe((data) => {
      if (data.length === 0) {
        this.firstTime = true;
      }
      this.userService.loaderObesrver.next(false);
      this.conversationList = data;
      this.currentConversationList = JSON.parse(JSON.stringify(this.conversationList));
    });

  }

  public deleteConversation(id: number, index: number): void {
    this.fbService.deleteConversation(this.userService.currentUser.id, id).subscribe(() => {
      this.currentConversationList.splice(index, 1);
    });
  }

  public clearFilter(): void {
    this.filters = {
      name: null,
      triggerBy: null,
      page: null
    };
    this.currentConversationList = JSON.parse(JSON.stringify(this.conversationList));
    this.ref.detectChanges();
  }

  public checkFilters(): void {
    this.currentConversationList = JSON.parse(JSON.stringify(this.conversationList));
    if (this.filters.name) {
      this.liveSearch();
    }
    if (this.filters.triggerBy) {
      this.filterByTriggerBy();
    }
    if (this.filters.page) {
      this.filterByPage();
    }
  }

  public liveSearch(): void {
    this.currentConversationList = this.currentConversationList.filter(this.liveSearchCallback.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchCallback(value: any): boolean {
    if (value.name) {
      return value.name.toLowerCase().indexOf(this.filters.name.toLowerCase()) !== -1;
    } else {
      return false;
    }
  }

  public filterByTriggerBy(): void {
    this.currentConversationList = this.currentConversationList.filter(this.isTriggerBy.bind(this));
    this.ref.detectChanges();
  }

  public isTriggerBy(value: any): boolean {
    if (value.triggered) {
      return value.triggered.toLowerCase() === this.filters.triggerBy.toLowerCase();
    } else {
      return false;
    }
  }

  public filterByPage(): void {
    this.currentConversationList = this.currentConversationList.filter(this.isCorrectPage.bind(this));
    this.ref.detectChanges();
  }

  public isCorrectPage(value: any): boolean {
    if (value.pageName) {
      return value.pageName.toLowerCase() === this.filters.page.toLowerCase();
    } else {
      return false
    }
  }

  public openFBSuccesDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.success,
        'message': this.textService.currentText.facebookConnected
      },
    }).afterClosed().subscribe();
  }

  public showLinkDialog(link): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'conversation-link',
        link: link
      },
    }).afterClosed().subscribe();
  }

  public editCoversation(id) {
    this.router.navigate(['dashboard/facebook-bot-manager/conversation-manager/new-conversation'], {queryParams: {id: id}});
  }
}
