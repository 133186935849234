import {Directive, HostListener, HostBinding, EventEmitter, Output} from '@angular/core';

@Directive({
  selector: '[appDnd]',
})
export class DndDirective {

  @HostBinding('style.background') private background: any;

  @Output() filesChangeEmiter: EventEmitter<FileList> = new EventEmitter();

  constructor() {}

  @HostListener('dragover', ['$event']) public onDragOver(event){
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event){
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event']) public onDrop(event){
    event.preventDefault();
    event.stopPropagation();
    let files = event.dataTransfer.files;
    if(files.length > 0){
      this.filesChangeEmiter.emit(files);
    }
  }

}
