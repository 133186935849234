
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TextService } from '../../../services/text.service';
import { StorageBrowser } from 'src/app/services/storage.browser';

@Component({
  selector: 'ninja-spa',
  templateUrl: './spa.component.html',
  styleUrls: ['./spa.component.scss']
})

export class SpaComponent implements OnInit {
  isSuccess: boolean = true;
  localData: any;
  constructor(
    public userService: UserService,
    public activatedRoute: ActivatedRoute,
    public textService: TextService,
    public storageBrowser: StorageBrowser,
    public router: Router) {

  }

  public ngOnInit(): void {
    this.userService.loader = true;

    if (Object.keys(this.activatedRoute.snapshot.queryParams).length !== 0 &&
      this.activatedRoute.snapshot.queryParams.state !== 'null' &&
      this.activatedRoute.snapshot.queryParams.selling_partner_id &&
      this.activatedRoute.snapshot.queryParams.mws_auth_token !== 'null' &&
      this.activatedRoute.snapshot.queryParams.spapi_oauth_code !== 'null') {
      const dataToVerify = {
        state: this.activatedRoute.snapshot.queryParams.state,
        selling_partner_id: this.activatedRoute.snapshot.queryParams.selling_partner_id,
        mws_auth_token: this.activatedRoute.snapshot.queryParams.mws_auth_token,
        spapi_oauth_code: this.activatedRoute.snapshot.queryParams.spapi_oauth_code,
        referer: document.referrer
      }
      this.userService.verifyHybridUser(dataToVerify).subscribe((data) => {
        this.localData = this.storageBrowser.get('$UserToken$user');
        console.log(this.localData)
        if (!this.localData.isVerified) {
          this.localData.sellerId = this.activatedRoute.snapshot.queryParams.selling_partner_id;
          this.localData.spToken = data.spaToken;
          this.localData.publicId = data.publicId;
        } else {
          this.localData.spToken = data.spaToken;
          this.localData.publicId = data.publicId;
        }
        this.storageBrowser.set('$UserToken$user', this.localData);
        if (data.isSuccess) {
          this.router.navigate(['grant-access'], { queryParams: { promoteFBbot: "promoteFBbot" } });
        } else {
          this.isSuccess = data.isSuccess;
        }
        this.userService.loader = false;
      });
    }
  }

  public navigateToSignUp(): void {
    this.router.navigate(['choose-region']);
  }

}