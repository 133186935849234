<div class="dashboard dashboard-schedules profit-page" [class.wide]="userService.smallSidebar">
  <!-- userService.smallSidebar -->
  <div class="dashboard-header pb-0">
    <div class="head-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 d-flex align-items-center mb-2"> 
            <div class="d-flex mb-1 col-6">
              <h2 class="mb-0">{{textService.currentText.profit}}</h2>
              <span class="headertitle-subtext mt-1"
                *ngIf="profitData && profitData.lastUpdated">
                {{textService.currentText.tableHeaders.lastUpdated}}:
                {{profitData.lastUpdated | date : 'd/M/yy H:mm'}}</span>
            </div>
            <div class="compareTo col-6 d-flex justify-content-end new-line">
              <mat-radio-group class="d-flex align-items-center" [(ngModel)]="yearlyCompare" (change)="compareToChange($event)" aria-label="Select an option">
                <label>Compare To:</label>
                <mat-radio-button class="example-radio-button" [value]="false">Previous Period</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="true">Previous Year</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center mb-2" *ngIf="profitData && profitData.costConfigured == false">
            <div class="sub-head-container d-md-flex d-none">
              <img height="15" class="mr-2" src="../../../../assets/icons/alerts_hover.svg">
              <p>
                {{textService.currentText.profitSubTitle}}
                <a [routerLink]="'/dashboard/user/settings/product-settings'" class="edit-btn action-el">
                  {{textService.currentText.profitSubTitle1}}
                </a>
              </p>
            </div>
          </div>
          <div class="col-12 removeFilter" *ngIf="formDataDashBoard">
            <button *ngIf="formDataDashBoard.brands && formDataDashBoard.brands.length > 0" type="button" mat-button class="default-btn my-2 mr-3 filterBtn" (click)="resetFilter('brand')">
              Brand Filter
              <mat-icon>close</mat-icon>
            </button>
            <button *ngIf="formDataDashBoard.sc &&  formDataDashBoard.sc.length > 0" type="button" mat-button class="default-btn my-2 mr-3 filterBtn" (click)="resetFilter('marketplace')">
              Marketplace Filter
              <mat-icon>close</mat-icon>
            </button>
            <button *ngIf="formDataDashBoard.skus &&  formDataDashBoard.skus.length > 0" type="button" mat-button class="default-btn my-2 mr-3 filterBtn" (click)="resetFilter('asin')">
              Asin Filter
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <!-- <div class="col-md-5 d-flex align-items-center justify-content-center justify-content-md-end mt-4 mt-md-0">
            <ul class="filter-choices mb-1" *ngIf="formDataDashBoard">
              <li *ngIf="formDataDashBoard.startDate"><span class="mr-1">Dates: </span> {{formDataDashBoard.startDate | date:'dd/MM/yy'}} -
                {{formDataDashBoard.endDate | date:'dd/MM/yy'}}</li>
              <li class="align-items-center" *ngIf="formDataDashBoard.sc">
                <span>Marketplaces: </span>
                <span class="data-show d-flex">
                  <span *ngFor="let marketPlace of formDataDashBoard.sc">
                    <img class="mr-2" width="12" src="{{get_flag(marketPlace)}}">
                  </span>
                  <span *ngIf="formDataDashBoard.sc.length > 4">
                    {{formDataDashBoard.sc.length - 3}} more...
                  </span>
                </span>
                <div *ngIf="formDataDashBoard.sc.length > 4" class="detail bg-white">
                  <span *ngFor="let marketPlace of formDataDashBoard.sc">
                    <img class="my-2" width="20" src="{{get_flag(marketPlace)}}">
                  </span>
                </div>
              </li>
              <li *ngIf="formDataDashBoard.brands">
                <span>Brands: </span>
                <span class="data-show">
                  {{formDataDashBoard.brands}}
                </span>
                <div *ngIf="formDataDashBoard.brands?.length > 2" class="detail bg-white">
                  <ul>
                    <li *ngFor="let brandsList of formDataDashBoard.brands">{{brandsList}}</li>
                  </ul>
                </div>
              </li>
              <li *ngIf="formDataDashBoard.skus">
                <span>Asins: </span>
                <span class="data-show">
                  {{formDataDashBoard.skus}}
                </span>
                <div *ngIf="formDataDashBoard.skus.length > 2" class="detail bg-white">
                  <ul>
                    <li *ngFor="let skusList of formDataDashBoard.skus">{{skusList}}</li>
                  </ul>
                </div>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-container shadow-none">
    <div class="container-fluid">
      <!-- <div class="row justify-content-end pt-2 pr-2">
        <span class="text-gray-light" *ngIf="profitData.timeframeData">{{profitData.timeframeData.startAsString}} to
          {{profitData.timeframeData.endAsString}}</span>
      </div> -->
      <!-- <div class="row">
        <div class="max-width-36" *ngIf="profitData.realTimeData">
          <app-todaysection [realTimeData]="profitData.realTimeData" [formData]="formDataDashBoard">
          </app-todaysection>
        </div>
        <div class="max-width-28 h-100" *ngIf="profitData.timeframeData">
          <app-bestandworstsection [bestAndWorstData]="profitData.bestAndWorstData"
            [timeframeData]="profitData.timeframeData" [formData]="formDataDashBoard">
          </app-bestandworstsection>
        </div>
        <div class="max-width-36" *ngIf="profitData.timeframeData">
          <app-profitsection [timeframeData]="profitData.timeframeData" [formData]="formDataDashBoard">
          </app-profitsection>
        </div>
      </div> -->
      <div class="row" *ngIf="profitData">
        <div class="max-width-33" *ngIf="profitData.timeframeDataFirst">
          <app-profitsection (updatedNewData)="updatedNewData($event)" [boxNumber]="1" [timeframeData]="profitData.timeframeDataFirst" [formData]="formDataDashBoard"
            [defaultData]="defaultDataFirst">
          </app-profitsection>
        </div>
        <div class="max-width-33 h-100" *ngIf="profitData.timeframeDataSecond">
          <app-profitsection (updatedNewData)="updatedNewData($event)" [boxNumber]="2" [timeframeData]="profitData.timeframeDataSecond" [formData]="formDataDashBoard"
            [defaultData]="defaultDataSecond">
          </app-profitsection>
        </div>
        <div class="max-width-33" *ngIf="profitData.timeframeDataThird">
          <app-profitsection (updatedNewData)="updatedNewData($event)" [boxNumber]="3" [timeframeData]="profitData.timeframeDataThird" [formData]="formDataDashBoard"
            [defaultData]="defaultDataThird">
          </app-profitsection>
        </div>
      </div>
      <div class="row mt-4 dashboard-schedules-filter">
        <ninja-custom-filter-menu #componentChild (EmitformDataDashBoard)="passBall($event)" [profitData]="profitData" [yearlyCompareValue]="yearlyCompare" [dashboardSchedulesFilter]="dashboardSchedulesFilterFlag">
        </ninja-custom-filter-menu>
      </div>
      <div class="row mt-4" *ngIf="profitData">
        <div class="max-width-100" *ngIf="profitData.barChartData">
          <app-barchart [barChartData]="profitData.barChartData" [formData]="formDataDashBoard"></app-barchart>
        </div>
      </div>
      <div class="row mt-4" *ngIf="profitData">
        <div class="max-width-33 pieChart" *ngIf="profitData.pieChart">
          <app-doughnutchart [pieChart]="profitData.pieChart" [pieChartName]="'profitpie'"
            [formData]="formDataDashBoard"></app-doughnutchart>
        </div>
        <div class="max-width-33 pieChart" *ngIf="profitData.pieChart">
          <app-doughnutchart [pieChart]="profitData.refundRateData" [pieChartName]="'revenuepie'"
            [formData]="formDataDashBoard"></app-doughnutchart>
        </div>
        <div class="max-width-33">
          <div *ngIf="profitData.pieChart">
            <app-product-barchart [productBarChartData]="profitData.pieChart"></app-product-barchart>
          </div>
          <div class="mt-4" *ngIf="profitData.bestAndWorstData">
            <app-bestandworstsection [bestAndWorstData]="profitData.bestAndWorstData"
              [timeframeData]="profitData.timeframeData" [formData]="formDataDashBoard">
            </app-bestandworstsection>
          </div>
        </div>
      </div>
      <div class="row mt-4" *ngIf="profitData">
        <div class="max-width-100" *ngIf="profitData.scopeHierarchyData">
          <mat-card class="todaysection">
            <mat-card-content>
              <mat-tab-group>
                <mat-tab label="Profit Breakdown">
                  <app-profitbreakdown [scopeHierarchyData]="profitData.scopeHierarchyData" [formData]="formDataDashBoard">
                  </app-profitbreakdown>
                </mat-tab>
                <mat-tab label="Variation view"> 
                  <app-profitbreakdown [variationView]="true" [scopeHierarchyData]="profitData.scopeHierarchyData" [formData]="formDataDashBoard">
                  </app-profitbreakdown>
                </mat-tab>
              </mat-tab-group>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>