import { Component, OnInit, Input } from '@angular/core';
import { ShareService } from 'src/app/services/share.service';
import { EChartOption } from 'echarts';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { ErrorsComponent } from '../popups/errors/errors.component';
import { OrdersPopupComponent } from '../../orders-popup/orders-popup.component';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'app-doughnutchart',
  templateUrl: './doughnutchart.component.html',
  styleUrls: ['./doughnutchart.component.scss']
})
export class DoughnutchartComponent implements OnInit {
  @Input() pieChart: any;
  @Input() pieChartName: any;
  @Input() formData: any;
  service: any;
  route: any;
  totalUnits: any;
  totalOrders: any;
  totalPromotions: any;
  dynamicClass: any;
  // Doughnut 

  doughnutChartOptions: EChartOption = {
    color: [
      '#00ffff', '#6bf7aa', '#f05a23', '#d9df20', '#b65acc',
      '#ff7aac', '#29abe1', '#f7f76b', '#8bc53e', '#5792cc',
      '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
      '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
      '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
      '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
      '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
      '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
      '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
      '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
      '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
      '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
    ],
    tooltip: {},
    legend: {},
    series: [
      {
        width: "370px",

        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        top: '-120px',
        left: 'center',
        label: {
          show: false
        },
        data: []
      }
    ],

  };

  constructor(public router: Router,
    public location: Location, private dialog: MatDialog, private shareService: ShareService, public userService: UserService, public mixpanelService: MixpanelService) {
    this.mixpanelService.init(null);
    router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
      }
    });
  }
  ngOnInit() {
    // let left = 0;
    // let right = 0;
    // if (this.pieChartName == 'profitpie') {
    //   this.dynamicClass = 'pr-5 mr-5';
    //   this.doughnutChartOptions.series[0].left = '-55px';
    //   right = 2;
    //   left = 15;
    // } else {
    //   right = 2;
    //   left = 80;
    // }
    if (this.pieChart) {
      if (this.pieChartName == 'revenuepie') {
        this.totalUnits = this.pieChart.percentage ? this.pieChart.percentage.toLocaleString() + '% Refunds' : '0% Refunds';
        this.totalOrders = this.pieChart.totalRefunds.toLocaleString() + ' Refunds';
        this.totalPromotions = 'of ' + this.pieChart.total + ' Units';
      } else {
        this.totalUnits = this.pieChart.totalUnits.toLocaleString() + ' Units';
        this.totalOrders = this.pieChart.totalOrders.toLocaleString() + ' Orders';
        this.totalPromotions = this.pieChart.totalPromotions.toLocaleString() + ' Promotions';
      }
      let pieChartData = this.pieChart;
      // if (this.pieChartName == 'orders') {
      if (this.pieChartName == 'revenuepie') {
        if (pieChartData.total != 0) {
          this.doughnutChartOptions.color = ["#f48229", "#243853"];
          // this.doughnutChartOptions.series[0].top = '-120px',
          this.doughnutChartOptions.series[0].data = [
            pieChartData.totalRefunds, pieChartData.total - pieChartData.totalRefunds
          ]
        }
      } else {
        // this.doughnutChartOptions.series[0].top = '-120px',
        this.doughnutChartOptions.legend = {
          show: true,
          selectedMode: true,
          selectorLabel: {
            show: true,
          },
          tooltip: true,
          type: 'scroll',
          orient: 'vertical',
          height: 110,
          // width: 50,
          top: 280,
          // left: left,
          // right: right,
          // bottom: 20,
          left: 'left',
          data: pieChartData.scopeValues.map((item) => {
            let namePie;
            if (item.scopeName.length > 40) {
              namePie = item.scopeName.substring(0, 40) + '...';
            } else {
              namePie = item.scopeName.substring(0, 40);
            }
            return namePie + ' : ' + item.value;
          }),
          formatter: function (name) {
            return name;
          }
        };
        // } else {
        //   this.doughnutChartOptions.legend = { show: false, }
        // }
        this.doughnutChartOptions.series[0].data = this.pieChart.scopeValues.map((item) => {
          let namePie;
          if (item.scopeName.length > 40) {
            namePie = item.scopeName.substring(0, 40) + '...';
          } else {
            namePie = item.scopeName;
          }
          return { 'value': item.value, 'name': namePie + ' : ' + item.value, 'img': item.image };
        });
        this.doughnutChartOptions.tooltip = {
          trigger: 'item',
          //position: [10, 10],
          extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
          textStyle: { color: '#4d4f5c', fontSize: 9 },
          backgroundColor: '#fff',
          formatter: function (params) {
            const imgPath = params.data.img ? params.data.img : 'assets/images/noImage.png';
            return '<div class="d-flex p-2 align-items-center"><img src="' + imgPath + '" width="30" style="height:30px">' + ' <h4 class="pl-3 mb-0" style="font-size:16px"> ' + params.data.name + '</h4></div>';
          },
        }
      }
      // this.doughnutChartOptions.legend.data = this.pieChart.scopeValues.map((item) => {
      //   return { 'value': item.value, 'name': item.scopeName };
      // });
      // console.log('this.doughnutChartOptions.legend.data', this.doughnutChartOptions.legend.data);
      // let totalUnits = pieChartData.totalUnits.toLocaleString() + ' Units';
      // let totalOrders = pieChartData.totalOrders.toLocaleString() + ' Orders';
      // let totalPromotions = pieChartData.totalPromotions.toLocaleString() + ' Promotions';
      // this.doughnutChartOptions.series[0].label = {
      //   normal: {
      //     show: true,
      //     triggerEvent: true,
      //     // position: 'center',
      //     position: 'center',
      //     formatter: '{a|' + totalUnits + '}\n\n{b|' + totalOrders + ' }\n\n{c|' + totalPromotions + ' }',
      //     rich: {
      //       a: {
      //         color: '#243853',
      //         fontSize: 20,
      //         fontWeight: 'bold'
      //       },
      //       b: {
      //         color: '#a2a2a2',
      //         fontSize: 15,
      //         fontWeight: '400',
      //         formatter: function (val) {
      //           console.log('val', val);
      //         }
      //       },
      //       c: {
      //         color: '#a2a2a2',
      //         fontSize: 15,
      //         fontWeight: '400',
      //       },
      //     },
      //     callbacks: function () {
      //       console.log('click');
      //     }
      //   }
      // }
    }
  }

  openPopupClick(type) {
    this.userService.loaderObesrver.next(true);
    if (this.pieChartName == 'currentorders' || this.pieChartName == 'profitpie') {
      if (type === "Orders") {
        this.mixpanelService.track('q edit product popup - opened', {
          'source':
            'orders dashboard current', 'page': 'orders-dashboard'
        });
      } else {
        this.mixpanelService.track('q edit product popup - opened', {
          'source':
            'promotions popup', 'page': 'orders-dashboard'
        });
      }
      this.userService.get_currentorders_and_promotion(type, this.formData).subscribe((res: any) => {
        if (res) {
          this.dialog.open(OrdersPopupComponent, {
            panelClass: 'orderpopup',
            width: '1300px',
            height: '500px',
            data: { dataSource: res, title: type, formData: this.formData, orderType: this.pieChartName }
          });
          this.userService.loaderObesrver.next(false);
        }
      }, (err) => {
        this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
      });
    } else if (this.pieChartName == 'previousorders') {
      if (type === "Orders") {
        this.mixpanelService.track('q edit product popup - opened', {
          'source': 'orders dashboard previuos', 'page': 'orders-dashboard'
        });
      } else {
        this.mixpanelService.track('q edit product popup - opened', {
          'source': 'promotions popup previuos', 'page': 'orders-dashboard'
        });
      }
      this.userService.get_previousorders_and_promotion(type, this.formData).subscribe((res: any) => {
        if (res) {
          this.dialog.open(OrdersPopupComponent, {
            panelClass: 'orderpopup',
            width: '1300px',
            height: '500px',
            data: { dataSource: res, title: type, formData: this.formData, orderType: this.pieChartName }
          });
          this.userService.loaderObesrver.next(false);
        }
      }, (err) => {
        this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
      });
    }
    // else if (this.pieChartName == 'profitpie') {
    //   this.userService.get_currentorders_and_promotion(type, this.formData).subscribe((res: any) => {
    //     if (res) {
    //       this.dialog.open(OrdersPopupComponent, {
    //         panelClass: 'orderpopup',
    //         width: '850px',
    //         height: '500px',
    //         data: { dataSource: res, title: type, formData: this.formData, orderType: this.pieChartName }
    //       });
    //       this.userService.loaderObesrver.next(false);
    //     }
    //   }, (err) => {
    //     this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    //   });
    // }

  }
}
