import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { TextService } from '../../../services/text.service';
import { OrdersService } from '../../../services/orders.service';
import { InvoicingService } from '../../../services/invoicing.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MixpanelService } from 'src/app/services/mixpanel.service';

declare let jQuery: any;
declare let $: any;

@Component({
  templateUrl: './send-invoice-dialog.component.html',
  styleUrls: ['./send-invoice-dialog.component.scss']
})
export class SendInvoiceDialogComponent implements OnInit {
  public editor: any;
  public editorConfig: any;
  public currentOrder: any;
  public savedOrderNumber: any;
  public errors = {
    orderNumber: false,
    subject: false
  };

  public sendForm = this.fb.group({
    orderNumber: [''],
    buyerName: ['', [Validators.required]],
    salesChannel: ['', [Validators.required]],
    subject: ['', [Validators.required]],
    // password: ['',
    //   [Validators.required]],
  });

  constructor(public fb: FormBuilder,
    public matDialog: MatDialog,
    public userService: UserService,
    public invoicingService: InvoicingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public textService: TextService,
    public dialogRef: MatDialogRef<SendInvoiceDialogComponent>,
    public mixpanelService: MixpanelService,
  ) {
    this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    this.editorConfig = {
      height: 200,
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Times New Roman', 'Georgia', 'Verdana'],
      fontNamesIgnoreCheck: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Times New Roman', 'Georgia', 'Verdana'],
      toolbar: [
        ['style', ['style']],
        ['fontsize', ['fontsize']],
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['fontname', ['fontname']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture', 'video']],
        ['table', ['table']],
        ['misc', ['codeview', 'undo', 'redo']]
      ],
      popover: {
        image: [
          ['custom', ['imageAttributes']],
          ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
          ['float', ['floatLeft', 'floatRight', 'floatNone']],
          ['remove', ['removeMedia']]
        ],
      },
      fontSizes: ['6', '8', '10', '12', '14', '18', '24', '36', '48', '64', '72'],
      callbacks: {
        onImageUpload: (files) => {
          // this.imageFileLoader(files);
        }
      },
      lang: 'en-US', // Change to your chosen language
      imageAttributes: {
        icon: '<i class="note-icon-pencil"/>',
        removeEmpty: false, // true = remove attributes | false = leave empty if present
        disableUpload: true // true = don't display Upload Options | Display Upload Options
      }
    };
    this.editor = jQuery('#summernote');
    this.editor.summernote(this.editorConfig);
    this.editor.summernote('disable');
    this.sendForm.controls.subject.disable();
    this.sendForm.controls.buyerName.disable();
    this.sendForm.controls.salesChannel.disable();
  }


  public searchOrder(): void {
    this.userService.dialogLoader = true;
    this.invoicingService.getOrderInfo(
      this.userService.currentUser.id,
      this.sendForm.value.orderNumber.trim(),
      this.data.invoiceTemplateId)
      .subscribe(res => {
        if (res.isSuccess) {
          this.currentOrder = res;
          this.savedOrderNumber = JSON.parse(JSON.stringify(this.sendForm.value.orderNumber));
          this.enableForm();
        } else {
          // this.showSaveErrorDialog(res.message);
          this.showErrorDialog(res.errorCode, res.salesChannel);
          this.savedOrderNumber = '';
          // this.showOrderNotFound();
        }
        this.userService.dialogLoader = false;
      });
  }

  public enableForm(): void {
    this.sendForm.controls.subject.enable();
    this.editor.summernote('enable');
    this.fillForm();
  }

  public fillForm(): void {
    this.sendForm.patchValue({
      buyerName: this.currentOrder.buyerName,
      salesChannel: this.currentOrder.salesChannel,
      subject: this.textService.currentText.sendInvoiceDefaultSubject
    });
    this.editor.summernote('code', this.currentOrder.invoiceForContent);
  }

  public sendEmail(): void {
    if (this.checkForm()) {
      const dataToSend = {
        publicId: this.userService.currentUser.id,
        orderId: this.savedOrderNumber,
        invoiceTemplateId: this.data.invoiceTemplateId,
        subject: this.sendForm.value.subject,
        content: this.editor.summernote('code')
      };
      this.invoicingService.sendEmail(dataToSend).subscribe(res => {
        if (res.isSuccess) {
          this.userService.showNotification = true;
          this.userService.notificationMessage = this.textService.currentText.messageSentSuccess;
          this.userService.notificationTitle = this.textService.currentText.success;
          this.dialogRef.close();
        }
      });
    }

  }

  public resetForm(): void {
    if (this.sendForm.value.orderNumber !== this.savedOrderNumber) {
      this.sendForm.controls.buyerName.reset();
      this.sendForm.controls.buyerName.disable();
      this.sendForm.controls.salesChannel.reset();
      this.sendForm.controls.salesChannel.disable();
      this.sendForm.controls.subject.reset();
      this.sendForm.controls.subject.disable();
      this.editor.summernote('code', '');
      this.editor.summernote('disable');
    }
  }


  public showErrorDialog(code, salesChannel?): void {
    if (code === 3) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'orderNotMatch',
          'salesChannel': salesChannel
        },
      }).afterClosed().subscribe();
    } else if (code === 2) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'orderNotFound',
          'text': this.textService.currentText.invoiceOrderNotFoundText3
        },
      }).afterClosed().subscribe();
    } else if (code === 1) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'orderNotFound',
          'text': this.textService.currentText.invoiceOrderNotFoundText2
        },
      }).afterClosed().subscribe();
    }
  }


  public isOrderValid(): void {
    // if (!this.sendForm.controls.orderNumber.valid) {
    //   this.errors.orderNumber = true;
    //   this.displayError(this.textService.currentText.orderNumberValidation);
    //   return;
    // } else {
    this.searchOrder();
    // }
  }

  public checkForm(): boolean {
    if (!this.sendForm.controls.subject.valid) {
      this.displayError(this.textService.currentText.errorRequired);
      return;
    } else {
      return true;
    }
  }

  public showInvoice() {
    window.open(this.currentOrder.invoiceURL, '_blank')
  }

  private displayError(message?: string): void {
    if (!this.sendForm.controls.subject.valid) {
      this.errors.subject = true;
      const old_value = this.sendForm.value.subject;
      this.sendForm.patchValue({
        subject: message
      });
      this.sendForm.controls['subject'].disable();
      this.resetErrorState(old_value);
      return;
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.subject) {
        this.sendForm.patchValue({
          subject: val,
        });
        this.errors.subject = false;
        this.sendForm.controls['subject'].enable();
        return;
      }
    }, 2000);
  }

}
