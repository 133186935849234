
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TextService } from '../../../services/text.service';

@Component({
  selector: 'ninja-appstore',
  templateUrl: './appstore.component.html',
  styleUrls: ['./appstore.component.scss']
})

export class AppStoreComponent implements OnInit {
  isSuccess: boolean = true;
  constructor(public userService: UserService, public activatedRoute: ActivatedRoute, public textService: TextService, public router: Router) {
  }

  public ngOnInit(): void {
    this.userService.loader = true;
    if (Object.keys(this.activatedRoute.snapshot.queryParams).length !== 0 &&
      this.activatedRoute.snapshot.queryParams.amazon_state !== 'null' &&
      this.activatedRoute.snapshot.queryParams.selling_partner_id &&
      this.activatedRoute.snapshot.queryParams.amazon_callback_uri !== 'null') {
      this.navigateToSignUpWithParameter();
      // const dataToVerify = {
      //   amazon_state: this.activatedRoute.snapshot.queryParams.amazon_state,
      //   selling_partner_id: this.activatedRoute.snapshot.queryParams.selling_partner_id,
      //   amazon_callback_uri: this.activatedRoute.snapshot.queryParams.amazon_callback_uri,
      //   referer: this.activatedRoute.snapshot.queryParams.referer
      // }
      // this.userService.verifyAppstoreUser(dataToVerify).subscribe((data) => {
      //   if (data.isSuccess) {
      //     this.navigateToSignUpWithParameter();
      //   } else {
      //     this.isSuccess = data.isSuccess;
      //   }
      //   this.userService.loader = false;
      // });
    }
  }

  public navigateToSignUp(): void {
    this.router.navigate(['registration']);
  }

  navigateToSignUpWithParameter() {
    this.router.navigate(['registration'], {
      queryParams: {
        amazon_state: this.activatedRoute.snapshot.queryParams.amazon_state,
        selling_partner_id: this.activatedRoute.snapshot.queryParams.selling_partner_id,
        amazon_callback_uri: this.activatedRoute.snapshot.queryParams.amazon_callback_uri,
        referer: document.referrer
      }
    });
  }

}