import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';

@Injectable()

export class CouponsService extends UserService {

  public getCoupons(publicId: string): Observable<any> {
    return this.post('coupons/get_coupons', {
      publicId: publicId
    });
  }

  public deleteCoupons(publicId: string, couponId: any): Observable<any> {
    return this.post('coupons/delete_coupons', {
      publicId: publicId,
      couponId: couponId
    });
  }

  public editCoupon(data: any): Observable<any> {
    return this.post('coupons/edit_coupon', data);
  }


  public getCouponCode(publicId: string, couponId: any): Observable<any> {
    return this.post('coupons/get_coupon_code', {
      publicId: publicId,
      couponId: couponId
    });
  }

  public createCoupon(data: any): Observable<any> {
    return this.post('coupons/create_coupon', data);
  }
}
