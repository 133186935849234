import {Component, Input} from '@angular/core';
import {TextService} from '../../../../services/text.service';

@Component({
  selector: 'ninja-refund-tooltip',
  templateUrl: './refund-tooltip.component.html',
  styleUrls: ['./refund-tooltip.component.scss']
})

export class RefundTooltipComponent {
  @Input() refundDate: any;

  constructor (public textService: TextService) {}
}
