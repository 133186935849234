<div class="errorPopup">
    <div matDialogTitle>
        <div class="d-flex w-100 align-items-center justify-content-between">
            <h2 class="mb-0">Error</h2>
            <div class="d-flex align-items-center justify-content-end">
                <mat-icon class="close cursor-pointer" (click)="closePopup()">close</mat-icon>
            </div>
        </div>
    </div>
    <mat-dialog-content>
        <p class="align-items-center">This is the error message!<br />We need to address specific errors and provide guidance to users</p>
        <button mat-button (click)="closePopup()">Ok</button>
    </mat-dialog-content>
</div>