import {ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Functions} from '../../../services/functions';
import {UserService} from '../../../services/user.service';
import {TextService} from '../../../services/text.service';
import {FbService} from '../../../services/fb.service';

@Component({
  templateUrl: './manage-tags-dialog.component.html',
  styleUrls: ['./manage-tags-dialog.component.scss'],
})
export class ManageTagsDialogComponent implements OnInit, OnDestroy {
  public tags: any;
  public pageId: string;
  public tempList: any;
  public tagList: any;
  public currentTag = [];
  public isTags: any;
  public isDublicateTag = true;

  constructor(public ref: ChangeDetectorRef,
              public userService: UserService,
              public fbService: FbService,
              public textService: TextService,
              public dialogRef: MatDialogRef<ManageTagsDialogComponent>,
              public functions: Functions, public el: ElementRef,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit(): void {
    this.fbService.facebookGetTags(this.userService.currentUser.id).subscribe((data) => {
      this.tags = data;
      this.currentTag = this.data.data.tags;
      this.tagList = this.currentTag;
      this.isTags = JSON.parse(JSON.stringify(this.currentTag));
      this.setCurrentList();
    });
    for (let i = 0; this.data.pages.length > i; i++) {
      if (this.data.pages[i].name === this.data.data.pageName) {
        this.pageId = this.data.pages[i].pageId;
      }
    }
  }

  public ngOnDestroy(): void {
    this.dialogRef.close(this.tagList)
  }

  public setNewTags() {
    this.isTags = JSON.parse(JSON.stringify(this.currentTag));
    this.tagList = JSON.parse(JSON.stringify(this.currentTag));
  }

  public compareFn(tag, tag2) {
    return tag && tag2 ? tag.name === tag2.name : tag === tag2;
  }

  public removeTag(tag) {
    this.tagList.splice((this.tagList[this.tagList.indexOf(tag)]), 1);
    this.currentTag = JSON.parse(JSON.stringify(this.tagList));
    this.fbService.facebookRemoveTag(this.data.data.fbId, this.pageId, [Number(tag.tagId)]).subscribe(() => {})
  }

  public setCurrentList() {
    this.tempList = JSON.parse(JSON.stringify(this.currentTag));
  }

  public tagListGenerator(tagList) {
    const tags = [];
    for (let i = 0; tagList.length > i; i++) {
      tags.push(tagList[i].tagId);
    }
    return tags;
  }

  public detectTagDuplicate () {
    for (let i = 0; this.tagList.length > i; i++) {
      if (this.tagList[i]['tagId'] == this.currentTag['tagId']) {
        this.isDublicateTag = true;
        return
      }
    }
    this.isDublicateTag = false;
  }

  public saveTags() {
    let newTagList = JSON.parse(JSON.stringify(this.tempList));
    newTagList.push(this.currentTag);
    this.tagList.push(this.currentTag);
    this.detectTagDuplicate();
    if (JSON.stringify(this.tempList) === JSON.stringify(this.currentTag)) {
      this.dialogRef.close(false);
    } else {
      this.fbService.facebookRemoveTag(this.data.data.fbId, this.pageId, this.tagListGenerator(this.tempList)).subscribe(() => {
        this.fbService.facebookAddTag(this.data.data.fbId, this.pageId, this.tagListGenerator(newTagList)).subscribe();
      });
    }
  }
}
