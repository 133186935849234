<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-header">
    <div class="head-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-7">
            <h2>{{textService.currentText.userPermissions}}</h2>
          </div>
          <div class="col-md-5 d-flex align-items-center justify-content-center justify-content-md-end mt-4 mt-md-0">
            <button mat-button mat-raised-button (click)="showCreateUserDialog()" class="secondary-btn">
              {{textService.currentText.createNewUser}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-container">
    <h3 class="font-size-18 mr-3">{{textService.currentText.userPermissions}}</h3>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header" [class.full-width]="true">
            <div class="td"></div>
            <div class="td">{{textService.currentText.userName}}</div>
            <div class="td">{{textService.currentText.userEmail}}</div>
            <div class="td">{{textService.currentText.permissions}}</div>
            <div class="td">{{textService.currentText.actions}}</div>
          </div>
        </div>
        <div class="table-holder table">
          <div class="tr" *ngFor="let va of vaList">
            <div class="td">
              <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"></button>
              <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                <button mat-menu-item (click)="showCreateUserDialog(va)">
                  <div class="list-inner-container">
                    <div class="menu-icon icon-edit"></div>
                    {{textService.currentText.edit}}
                  </div>
                </button>
                <button mat-menu-item (click)="deleteVaUser(va)">
                  <div class="list-inner-container">
                    <div class="menu-icon icon-delete"></div>
                    {{textService.currentText.delete}}
                  </div>
                </button>
              </mat-menu>
            </div>
            <div class="td">{{va?.name}}</div>
            <div class="td">{{va?.email}}</div>
            <div class="td">
              <span *ngFor="let permission of va?.featureList">
                {{permission.charAt(0).toUpperCase() + permission.split('_').join(' ').slice(1)}}. </span>
            </div>
            <div class="td">
              <span class="action-el" (click)="showCreateUserDialog(va)">Edit Permissions</span>
            </div>
          </div>
          <div class="tr not-found" *ngIf="!vaList?.length">
            <div class="td not-found">
              {{textService.currentText.noDataFound}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ninja-notification [active]="userService.showNotification" [message]="notificationMessage" [title]="notificationTitle">
</ninja-notification>