<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container">
    <div class="dashboard-header">
      <div class="head-container">
        <h2>{{textService.currentText.autoResponseTitle}}</h2>
        <button mat-button
                mat-raised-button
                class="primary-btn"
                (click)="showAutoResponseDialog(null, false)">
          {{textService.currentText.createNewResponse}}
        </button>
      </div>
      <!--<div class="sub-head-container">-->
        <!--<p>-->
          <!--{{textService.currentText.autoResponseSubTitle1}}.-->
        <!--</p>-->
        <!--<p>-->
          <!--{{textService.currentText.autoResponseSubTitle2}}.-->
        <!--</p>-->
      <!--</div>-->
      <div class="filters-container">
        <div class="a-side">
          <div class="btn-container" (click)="isFilters = !isFilters">
            <button
                mat-button
                mat-raised-button
                class="default-btn filter-btn"
                type="button"
                [class.active]="isFilters">
              {{textService.currentText.filters}}
            </button>
          </div>
          <form class="filters custom-selects" [class.active]="isFilters">
            <div class="select-holder">
              <div (click)="functions.setDropDownPosition(Status, 'status')" #Status
                   [class.active]="status.panelOpen"
                   class="select-wrapper">
                <mat-form-field class="status-filter">
                  <mat-select [placeholder]="textService.currentText.status"
                              shouldPlaceholderFloat="false"
                              panelClass="default-dropdown status"
                              [(ngModel)]="filters.status"
                              (selectionChange)="checkFilters()"
                              #status
                              disableRipple
                              name="status">
                    <mat-option *ngFor="let select of constService.selectsStatus"
                                [value]="select.value">
                      {{ select.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="select-holder">
              <div (click)="functions.setDropDownPosition(PageName, 'days')" #PageName
                   [class.active]="days.panelOpen"
                   class="select-wrapper">
                <mat-form-field>
                  <mat-select [placeholder]="textService.currentText.pageName"
                              shouldPlaceholderFloat="false"
                              panelClass="default-dropdown days"
                              disableRipple
                              [(ngModel)]="filters.page"
                              (selectionChange)="checkFilters()"
                              #days
                              name="page">
                    <mat-option *ngFor="let select of pages" [value]="select.name">
                      {{ select.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="input-wrapper">
              <input [placeholder]="textService.currentText.responseName"
                     name="responseName"
                     [(ngModel)]="filters.name"
                     (keyup)="checkFilters()"
                     type="text">
              <div class="clear-input"
                   *ngIf="filters.name?.length > 0"
                   (click)="filters.name = null; checkFilters()"></div>
            </div>
            <div class="input-wrapper">
              <input [placeholder]="textService.currentText.keyword"
                     name="keyWord"
                     [(ngModel)]="filters.keyword"
                     (keyup)="checkFilters()"
                     type="text">
              <div class="clear-input"
                   *ngIf="filters.keyword?.length > 0"
                   (click)="filters.keyword = null; checkFilters()"></div>
            </div>
            <button mat-button
                    mat-raised-button
                    (click)="clearFilter()"
                    class="default-btn clear-btn"
                    type="button">
              {{textService.currentText.clearAll}}
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header auto-response-header"
               [class.full-width]="currentAutoReplyList?.length <= 5">
            <div class="td"></div>
            <div class="td" (click)="functions.sortColumns(currentAutoReplyList, 'status')">{{textService.currentText.status}}</div>
            <div class="td" (click)="functions.sortColumns(currentAutoReplyList, 'pageName')">{{textService.currentText.pageName}}</div>
            <div class="td" (click)="functions.sortColumns(currentAutoReplyList, 'ruleName', true)">{{textService.currentText.responseName}}</div>
            <div class="td" (click)="functions.sortColumns(currentAutoReplyList, 'keyword', true)">{{textService.currentText.keyword}}</div>
            <div class="td" (click)="functions.sortColumns(currentAutoReplyList, 'message')">{{textService.currentText.postPreview}}</div>
          </div>
        </div>
        <div class="table-holder auto-response-holder">
          <div class="talbe auto-response-table">
            <div class="tr" *ngFor="let list of currentAutoReplyList">
              <div class="td">
                <button mat-button [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"></button>
                <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                  <button mat-menu-item (click)="showAutoResponseDialog(list, true)">
                    <div class="list-inner-container">
                      <div class="menu-icon icon-edit"></div>
                      {{textService.currentText.edit}}
                    </div>
                  </button>
                  <button mat-menu-item >
                    <div class="list-inner-container" (click)="deleteAutoReply(list.ruleId)">
                      <div class="menu-icon icon-delete"></div>
                      {{textService.currentText.delete}}
                    </div>
                  </button>
                </mat-menu>
              </div>
              <div class="td">
                <mat-slide-toggle
                    disableRipple
                    [checked]="list.status"
                    (click)="list.status = !list.status;
                     fbService.changeStatusAutoReply(userService.currentUser.id, list.ruleId, list.status).subscribe();
                     $event.stopPropagation(); $event.preventDefault()"
                    [class.uncheckedStatus]="!list.status"
                >{{valueToggle(list.status)}}
                </mat-slide-toggle>
              </div>
              <div class="td">
                {{list.pageName}}
              </div>
              <div class="td">
                {{list.ruleName}}
              </div>
              <div class="td">
                {{list.keyword}}
              </div>
              <div class="td">
                {{list.message}}
              </div>
            </div>
          </div>
        </div>
        <div class="table-holder" *ngIf="currentAutoReplyList?.length == 0 && firstTime === false">
          <div class="table">
            <div class="tr">
              <div class="td not-found">
                {{textService.currentText.noDataFound}}.
              </div>
            </div>
          </div>
        </div>
        <div class="table-holder" *ngIf="firstTime">
          <div class="table">
            <div class="tr">
              <div class="td not-found">
                <img src="../../../../../assets/icons/attention-grey.svg" alt="">
                {{textService.currentText.autoResponseText}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ninja-notification [active]="userService.showNotification"
                    [message]="userService.notificationMessage"
                    [title]="userService.notificationTitle"
></ninja-notification>

