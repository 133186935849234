<div class="main-dialog-container small" *ngIf="data.dialogFor === 'export-csv'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.exportToCSV}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.exportCSVText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.ok}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'delete-confirm'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogDeleteTemplate}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogDeleteTemplateText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn" (click)="dialogRef.close(true)">{{textService.currentText.yesDelete}}
    </button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'reset-confirm'">
  <div class="close-btn" (click)="dialogRef.close(true)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.resetPass}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.resetPassText1}}
          <br>
          <br>
          {{textService.currentText.resetPassText2}}
          <a target="_black"
            href="https://quantifyninja.com/contact-us.html">{{textService.currentText.supportEmail}}</a>
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.dialogGoToLogin}}
    </button>
  </div>
</div>


<div class="main-dialog-container small" *ngIf="data.dialogFor === 'signUp-confirm'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.signUp}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.signUpSuccess}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.dialogGoToLogin}}
    </button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'leave-confirm'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogUnsaved}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogUnsavedText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(true)">{{textService.currentText.yes}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(false)">{{textService.currentText.no}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'status-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog">
      <div class="header">
        <h2>{{textService.currentText.dialogStatus}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogStatusText1}}
        </p>
        <p>
          {{textService.currentText.dialogStatusText2}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'feedback-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog">
      <div class="header">
        <h2>{{textService.currentText.dialogNegativeFeedback}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogNegativeFeedbackText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'order-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog">
      <div class="header">
        <h2>{{textService.currentText.dialogRefundOrder}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogRefundOrderText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'repBuyers-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog">
      <div class="header">
        <h2>{{textService.currentText.dialogRepBuyers}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogRepBuyersText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'onlyRepBuyers-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog">
      <div class="header">
        <h2>Send only to repeated buyers</h2>
      </div>
      <div class="dialog-message">
        <p>
          Setting this option will limit the emails <b>only</b> to returning buyers
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'promotionGiven-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog">
      <div class="header">
        <h2>{{textService.currentText.dialogPromotionGiven}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogPromotionGivenText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'promotionValue-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog">
      <div class="header">
        <h2>{{textService.currentText.dialogPromotionValue}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogPromotionValueText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'specSalesChannels-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog">
      <div class="header">
        <h2>{{textService.currentText.dialogSpecSalesChannel}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogSpecSalesChannelText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>
<div class="main-dialog-container small" *ngIf="data.dialogFor === 'pass-changed'">
  <div class="close-btn" (click)="dialogRef.close()"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog">
      <div class="header">
        <h2>{{textService.currentText.dialogPassChanged}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogPassChangedText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn" (click)="dialogRef.close()">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'addCoupon-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog text-left text-margin">
      <div class="header">
        <h2>{{textService.currentText.dialogAddCoupon}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogAddCouponText1}}
        </p>
        <p>
          {{textService.currentText.dialogAddCouponText2}}
        </p>
        <p>
          {{textService.currentText.dialogAddCouponText3}}
        </p>
        <p>
          <span class="alert">{{textService.currentText.warning}}:</span>
          {{textService.currentText.dialogAddCouponText4}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'bulk-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog text-left">
      <div class="header">
        <h2>{{textService.currentText.dialogBulk}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dilogBulkText1}}
        </p>
        <br>
        <p>
          {{textService.currentText.dilogBulkText2}}
        </p>
        <br>
        <p>
          {{textService.currentText.dilogBulkText3}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'variables-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog ovh variables-dialog">
    <div class="dialog-content tooltip-dialog text-left">
      <div class="header">
        <h2>{{textService.currentText.dialogVaiable}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogVaiableText1}}
          <br>
          <br>
          {{textService.currentText.dialogVaiableText2}}<br>
          <span>{{textService.currentText.dialogVaiableText3}}</span>
          {{textService.currentText.dialogVaiableText4}}
          <br>
          <br>
          {{textService.currentText.dialogVaiableText5}}
          <span>{{textService.currentText.dialogVaiableText6}}</span>
          {{textService.currentText.dialogVaiableText7}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'confirm-template'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog confirm-template">
    <div class="dialog-content tooltip-dialog text-left">
      <div class="header">
        <h2>{{textService.currentText.dialogConfirmTemplate}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          <span class="bold"> {{textService.currentText.dialogConfirmTemplateText1}}</span>
          {{textService.currentText.active}}
        </p>
        <p>{{textService.currentText.dialogConfirmTemplateText2}}
          <ng-container *ngIf="data.data.timeframe === 0">
            <span class="bold"> {{textService.currentText.dialogConfirmTemplateText3}}</span>
          </ng-container>
          <ng-container *ngIf="data.data.timeframe !== 0">
            <span class="bold"> {{data.data.timeframe}}
              {{textService.currentText.dialogConfirmTemplateText4}}
              {{transform(data.data.event)}} </span>
          </ng-container>
          <span>{{textService.currentText.dialogConfirmTemplateText5}} </span>
          <span>{{textService.currentText.dialogConfirmTemplateText6}}</span>
        </p>
        <ul>
          <li *ngIf="data.data.productsskus.length === 0">
            {{textService.currentText.dialogConfirmTemplateText8}}
            <span>{{data.page === 'fba' ? textService.currentText.FBA : textService.currentText.FBM}}</span>
            {{textService.currentText.dialogConfirmTemplateText9}}
          </li>
          <li *ngFor="let product of data.data.productsskus">
            {{product.shortName}}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.confirm}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'addfile-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog attach-format-dialog">
    <div class="dialog-content tooltip-dialog text-left">
      <div class="header">
        <h2>{{textService.currentText.dialogAddFile}}</h2>
      </div>
      <div class="dialog-message">
        <ul>
          <li>{{textService.currentText.dialogAddFileText1}}</li>
          <li>{{textService.currentText.dialogAddFileText2}}</li>
          <li>{{textService.currentText.dialogAddFileText3}}</li>
          <li>{{textService.currentText.dialogAddFileText4}}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container bulk-warning" *ngIf="data.dialogFor === 'bulkEditReject-tooltip'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content tooltip-dialog">
      <div class="header">
        <h2>{{textService.currentText.dialogBulkNoedit}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogBulkNoeditText}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'edit-email'">
  <div class="close-btn" (click)="mixpanelService.track('q sender email popup - close');dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog edit-email ">
    <div class="dialog-content tooltip-dialog">
      <div class="header">
        <h2>{{textService.currentText.dialogEditEmail}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogEditEmailText}}</p>
        <input matInput #emailInput type="text" [(ngModel)]="email" [formControl]="emailForm"
          [class.has-danger]="showError" name="email">
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="mixpanelService.track('q sender email popup - save');editEmail()">{{textService.currentText.setSender}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'login-hold'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.login}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dilogOnHold}} <a
            href="mailto:support@quantifyninja.com">{{textService.currentText.supportEmail}}</a>
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>


<div class="main-dialog-container small" *ngIf="data.dialogFor === 'delete-invoice-warning'">
  <div class="close-btn" (click)="dialogRef.close()"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.warning}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogInvoiceWarning1}} "{{data.templateName}}"
          .{{textService.currentText.dialogInvoiceWarning2}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn" (click)="dialogRef.close()">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container add-region" [class.grantAccHeight]="data.grantAccess"
  *ngIf="data.dialogFor === 'add-region'">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog" [class.grantAccContainer]="data.grantAccess">
    <div class="header">
      <h2>{{data.grantAccess ? textService.currentText.grantAccessBtn2 : textService.currentText.addRegion}}</h2>
      <p *ngIf="data.grantAccess" class="grantAccTitle">{{textService.currentText.grantAccessText8}}</p>
    </div>
    <div class="dialog-content">
      <div class="dialog-message">
        <!-- <div class="region-block">
          <div class="region-item" [class.active]='currentRegion === "US"' (click)="isUS ? null : currentRegion = 'US'">
            <div [class.region-added]="isUS">
              <img src="../../../../assets/images/united-states.svg" alt="">
              <h3>{{textService.currentText.dialogRegion1}}</h3>
              <p>{{textService.currentText.dialogRegion1Text}}</p>
            </div>
            <ng-container *ngIf="!data.grantAccess">
              <input type="text" [disabled]="USDisabled" [(ngModel)]="UsSubmarket">
              <span *ngIf="isUS">{{textService.currentText.addRegionQuestion}}</span>
            </ng-container>
          </div>
          <div class="region-item" [class.active]='currentRegion === "UK"' (click)="isUK ? null : currentRegion = 'UK'">
            <div [class.region-added]="isUK">
              <img src="../../../../assets/images/european-union.svg" alt="">
              <h3>{{textService.currentText.europianUnionAccount}}</h3>
              <p>{{textService.currentText.dialogRegion2Text}}</p>
            </div>
            <ng-container *ngIf="!data.grantAccess">
              <input type="text" [disabled]="UKDisabled" [(ngModel)]="UkSubmarket">
              <span *ngIf="isUK">{{textService.currentText.addRegionQuestion}}</span>
            </ng-container>
          </div>
          <div class="region-item" [class.active]='currentRegion === "FE"' (click)="isFE ? null : currentRegion = 'FE'">
            <div [class.region-added]="isFE">
              <img src="../../../../assets/images/japan.svg" alt="">
              <h3>{{textService.currentText.dialogRegion3}}</h3>
              <p>{{textService.currentText.dialogRegion3Text}}</p>
            </div>
            <ng-container *ngIf="!data.grantAccess">
              <input type="text" [disabled]="FEDisabled" [(ngModel)]="FeSubmarket">
              <span *ngIf="isFE">{{textService.currentText.addRegionQuestion}}</span>
            </ng-container>
          </div>
          <div class="region-item" [class.active]='currentRegion === "JP"' (click)="isJP ? null : currentRegion = 'JP'">
            <div [class.region-added]="isJP">
              <img src="../../../../assets/images/japan.svg" alt="">
              <h3>{{textService.currentText.dialogRegion3}}</h3>
              <p>{{textService.currentText.dialogRegion3Text}}</p>
            </div>
            <ng-container *ngIf="!data.grantAccess">
              <input type="text" [disabled]="JPDisabled" [(ngModel)]="JpSubmarket">
              <span *ngIf="isJP">{{textService.currentText.addRegionQuestion}}</span>
            </ng-container>
          </div>
          <div class="region-item" [class.active]='currentRegion === "AU"' (click)="isAU ? null : currentRegion = 'AU'">
            <div [class.region-added]="isAU">
              <img src="../../../../assets/images/australia.svg" alt="">
              <h3>{{textService.currentText.dialogRegion4}}</h3>
              <p>{{textService.currentText.dialogRegion4Text}}</p>
            </div>
            <ng-container *ngIf="!data.grantAccess">
              <input type="text" [disabled]="AUDisabled" [(ngModel)]="AuSubmarket">
              <span *ngIf="isAU">{{textService.currentText.addRegionQuestion}}</span>
            </ng-container>
          </div>
        </div> -->
        <div class="choose-re">
          <div class="regions">
            <div class="region-item" [class.active]='currentRegion === "US"'
              (click)="isUS ? null : currentRegion = 'US'">
              <div class="country-container" [class.region-added]="isUS">
                <img class="icon email" src="../../../../assets/images/northamerica.png"
                  (click)="mixpanelService.track('q add region - clicked', { 'region': 'US', 'page': 'menu' });addRegion(usInput, 'US')">
                <h3>{{textService.currentText.northAmericaAccount1}}</h3>
                <div class="marketplace">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">{{textService.currentText.northAmericaMarketplaces}}</h5>
                      <ul>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'BR', 'page': 'menu' });addRegion(usInput, 'BR')">
                          <img src="../../../../assets/images/br1.png">
                          <span>com.br</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'CA', 'page': 'menu' });addRegion(usInput, 'CA')">
                          <img src="../../../../assets/images/ca.png">
                          <span>.ca</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'MX', 'page': 'menu' });addRegion(usInput, 'MX')">
                          <img src="../../../../assets/images/mx.png">
                          <span>.com.mx</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'US', 'page': 'menu' });addRegion(usInput, 'US')">
                          <img src="../../../../assets/images/com.png">
                          <span>.com</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <input type="text" #usInput [disabled]="USDisabled" [(ngModel)]="UsSubmarket"
                [class.has-danger]="emailInvalid" placeholder="Your Amazon Email">
            </div>
            <div class="region-item" [class.active]='currentRegion === "UK"'
              (click)="isUK ? null : currentRegion = 'UK'">
              <div class="country-container" [class.region-added]="isUK">
                <img class="icon email" src="../../../../assets/images/europe.png"
                  (click)="mixpanelService.track('q add region - clicked', { 'region': 'UK', 'page': 'menu' });addRegion(euInput, 'UK')">
                <h3>{{textService.currentText.europianUnionAccount1}}</h3>
                <div class="marketplace">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">{{textService.currentText.europianUnionMarketplaces}}</h5>
                      <ul>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'UAE', 'page': 'menu' });addRegion(euInput, 'UAE')">
                          <img src="../../../../assets/images/ae.png">
                          <span>.ae</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'DE', 'page': 'menu' });addRegion(euInput, 'DE')">
                          <img src="../../../../assets/images/de.png">
                          <span>.de</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'ES', 'page': 'menu' });addRegion(euInput, 'ES')">
                          <img src="../../../../assets/images/es.png">
                          <span>.es</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'FR', 'page': 'menu' });addRegion(euInput, 'FR')">
                          <img src="../../../../assets/images/fr.png">
                          <span>.fr</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'UK', 'page': 'menu' });addRegion(euInput, 'UK')">
                          <img src="../../../../assets/images/uk.png">
                          <span>.co.uk</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'IN', 'page': 'menu' });addRegion(euInput, 'IN')">
                          <img src="../../../../assets/images/in.png">
                          <span>.in</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'IT', 'page': 'menu' });addRegion(euInput, 'IT')">
                          <img src="../../../../assets/images/it.png">
                          <span>.it</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'NL', 'page': 'menu' });addRegion(euInput, 'NL')">
                          <img src="../../../../assets/images/nl1.png">
                          <span>.nl</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'SA', 'page': 'menu' });addRegion(euInput, 'SA')">
                          <img src="../../../../assets/images/sa1.png">
                          <span>.sd</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'TR', 'page': 'menu' });addRegion(euInput, 'TR')">
                          <img src="../../../../assets/images/tr1.png">
                          <span>.tr</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <input type="text" #euInput [disabled]="UKDisabled" [(ngModel)]="UkSubmarket"
                [class.has-danger]="emailInvalid" placeholder="Your Amazon Email">
            </div>
            <div class="region-item" [class.active]='currentRegion === "FE"'
              (click)="isFE ? null : currentRegion = 'FE'">
              <div class="country-container" [class.region-added]="isFE">
                <img class="icon email" src="../../../../assets/images/fareast.png"
                  (click)="mixpanelService.track('q add region - clicked', { 'region': 'AU', 'page': 'menu' });addRegion(feInput, 'AU')">
                <h3>{{textService.currentText.farEastAccount}}</h3>
                <div class="marketplace">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">{{textService.currentText.farEastMarketplaces}}</h5>
                      <ul>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'SG', 'page': 'menu' });addRegion(feInput, 'SG')">
                          <img src="../../../../assets/images/sg1.png">
                          <span>.sg</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'JP', 'page': 'menu' });addRegion(feInput, 'JP')">
                          <img src="../../../../assets/images/jp.png">
                          <span>.co.jp</span>
                        </li>
                        <li
                          (click)="mixpanelService.track('q add region - clicked', { 'region': 'AU', 'page': 'menu' });addRegion(feInput, 'AU')">
                          <img src="../../../../assets/images/au.png">
                          <span>.com.au</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <input type="text" #feInput [disabled]="FEDisabled" [(ngModel)]="FeSubmarket"
                [class.has-danger]="emailInvalid" placeholder="Your Amazon Email">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="dialog-footer" [class.dialogFooter]="data.grantAccess">
    <button mat-button class="default-btn white" (click)="dialogRef.close()">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn" (click)="addRegion()"
      [disabled]="(data.hasOwnProperty(data.grantAccess) && !data.grantAccess) || currentRegion.length === 0">
      {{!data.grantAccess ? textService.currentText.addRegion : textService.currentText.grantAccessBtn}}
    </button>
  </div> -->
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'permissionDenied'">
  <div class="close-btn" (click)="dialogRef.close(true)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogPermossionDenied}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogPermossionDeniedText}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.upgrade}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'maintenance'" style="width: 700px;">
  <div class="close-btn" (click)="dialogRef.close(true)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2 style="font-size: 50px;">We&rsquo;ll be back soon!</h2>
      </div>
      <div class="dialog-message">
        <p style="font-size: 20px;">Sorry for the inconvenience but we&rsquo;re performing some maintenance at the
          moment. If you need to you can always <a href="mailto:support@quantifyninja.com">contact us</a>, otherwise
          we&rsquo;ll be back online shortly!</p>
        <p style="font-size: 20px;">&mdash; The Team</p>
      </div>
    </div>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'amazon-connect'" style="width: 700px;">
  <div class="close-btn" (click)="dialogRef.close(true)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.welcomeToNinja}}!</h2>
      </div>
      <div class="dialog-message text-center">
        <p style="font-size: 16px;margin-bottom: 20px;">{{textService.currentText.grantedAccess}}</p>
        <p style="font-size: 16px;margin-bottom: 20px;">{{textService.currentText.ninjaBusy}}</p>
        <p style="font-size: 16px;">{{textService.currentText.everythingRunning}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container instructions" *ngIf="data.dialogFor === 'messenger-instruction'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.getInstructions}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogInstructionsText1}}
          <a [href]='data.link || "https://www.messenger.com/t/248153372223046"' target="_blank" class="action-el">
            {{data.page || textService.currentText.dialogInstructionsText2}} </a>
          {{textService.currentText.dialogInstructionsText3}}
        </p>
        <div class="example-container custom-selects borderless-input">
          <mat-form-field>
            <textarea matInput rows="2" id="token-text" readonly [value]="data.token">
                    </textarea>
            <button mat-button (click)="copyToClipboard()" disableRipple class="copy-button">
              <img src="../../../assets/icons/copy-clipboard.svg" alt="">
            </button>
            <div class="copy-tooltip" *ngIf="showCopyTooltip">
              {{textService.currentText.copiedToClipboard}}
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container instructions" *ngIf="data.dialogFor === 'messenger-fbnotification'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.getInstructions}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.notificationText2}}
          <a [href]='"https://www.facebook.com/messages/t/248153372223046?ref=" + data.ref' target="_blank"
            class="action-el">
            {{data.page || textService.currentText.dialogInstructionsText2}} </a>
          {{textService.currentText.notificationText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container instructions" *ngIf="data.dialogFor === 'messenger-tgnotification'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.getInstructions}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.notificationText2}}
          <a [href]='"https://t.me/quantifyninjabot?start=" + data.ref' target="_blank" class="action-el">
            {{data.page || textService.currentText.dialogInstructionsText2}} </a>
          {{textService.currentText.notificationText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container instructions" *ngIf="data.dialogFor === 'messenger-alerts'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.getInstructions}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.notificationText2}}
          <a [href]=' data.userFbCredLink' target="_blank" class="action-el">
            {{data.page || textService.currentText.dialogInstructionsText2}} </a>
          {{textService.currentText.notificationText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container instructions" *ngIf="data.dialogFor === 'bot-messenger-instruction'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.getInstructions}}</h2>
      </div>
      <div class="dialog-message">
        <ng-container *ngIf="data.showLink">
          <p>
            <!--{{textService.currentText.dialogBotInstructions1}}-->
            <!--<a [href]='data.link || "https://www.messenger.com/t/248153372223046"'-->
            <!--target="_blank"-->
            <!--class="action-el">-->
            {{textService.currentText.dialogBotInstructions1}}
            <a [href]='data.link' target="_blank" class="action-el">{{data.page}}</a>
            <!--{{textService.currentText.dialogBotInstructions2}} {{data.page}} -->
          </p>
        </ng-container>
        <div class="example-container custom-selects borderless-input" *ngIf="!data.showLink">
          <p>{{textService.currentText.testInstructions}}
            <img class="display-block-unset" src="../../../assets/icons/messenger.svg" alt="">
            {{textService.currentText.testInstructions2}}
          </p>
          <mat-form-field>
            <textarea matInput rows="2" id="token-text" readonly [value]="data.token">
                    </textarea>
            <button mat-button (click)="copyToClipboard()" disableRipple class="copy-button">
              <img src="../../../assets/icons/copy-clipboard.svg" alt="">
            </button>
            <div class="copy-tooltip" *ngIf="showCopyTooltip">
              {{textService.currentText.copiedToClipboard}}
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container instructions small" *ngIf="data.dialogFor === 'bot-json'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.getJson}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogGetJsonText}}</p>
        <div class="example-container custom-selects borderless-input">
          <mat-form-field>
            <textarea matInput rows="5" class="scroll-textarea" id="token-text" readonly [value]="data.json">
                    </textarea>
            <button mat-button (click)="copyToClipboard()" disableRipple class="copy-button">
              <img src="../../../assets/icons/copy-clipboard.svg" alt="">
            </button>
            <div class="copy-tooltip" *ngIf="showCopyTooltip">
              {{textService.currentText.copiedToClipboard}}
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container instructions conversation-link" *ngIf="data.dialogFor === 'conversation-link'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.getLink}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.conversationManagerDirectLink}}</p>
        <div class="example-container custom-selects borderless-input">
          <mat-form-field>
            <textarea matInput rows="1" id="token-text" readonly [value]="data.link">
                    </textarea>
            <button mat-button (click)="copyToClipboard()" disableRipple class="copy-button">
              <img src="../../../assets/icons/copy-clipboard.svg" alt="">
            </button>
            <div class="copy-tooltip" *ngIf="showCopyTooltip">
              {{textService.currentText.copiedToClipboard}}
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'order-blacklist'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogOrderBL}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogOrderBLText}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'buyers-blacklist'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogBuyerBL}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogBuyerBLText}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container " *ngIf="data.dialogFor === 'delete-commit-menu'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog commit-menu">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogCommitMenu}}</h2>
      </div>
      <div class="dialog-message">
        <p><span class="blue">{{textService.currentText.dialogCommitMenuText1}}</span>
          {{textService.currentText.dialogCommitMenuText2}}</p>
        <br>
        <br>
        <p><span class="blue">{{textService.currentText.dialogCommitMenuText3}} </span>
          {{textService.currentText.dialogCommitMenuText4}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'session-expired'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogSessionExp}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogSessionExpText}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.dialogGoToLogin}}
    </button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'download-report-failure'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogDownload}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogDownloadFailText}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'download-report-noresults'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogDownload}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogDownloadText}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'asin-limit'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogAsinLimit}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.dialogAsinLimitText}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'asin-message'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.warning}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{data.message}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'auto-response-edit-unsave'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogAutoResponseUnsave}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogAutoResponseUnsaveText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white" (click)="dialogRef.close()">
      {{textService.currentText.dialogAutoResponseUnsaveButton1}}
    </button>
    <button mat-button class="primary-btn gotBtn" (click)="dialogRef.close(true)">
      {{textService.currentText.dialogAutoResponseUnsaveButton2}}
    </button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'ony-for-master-ninja'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogNeedUpgrade}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogNeedUpgradeText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.upgrade}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'asin-search-added'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogAsinAdded}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogAsinAddedText}} {{data.marketplace}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'email-change-warning'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogEmailChange}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogEmailChangeText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'custom-warning'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.warning}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{data.text}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'open-rate-warning'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogOpenRate}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogOpenRateText1}}
        </p>
        <p>{{textService.currentText.dialogOpenRateText2}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'downgrade-confirm'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogDowngrade}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogDowngradeText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white" (click)="dialogRef.close(true)">{{textService.currentText.yes}},
      {{textService.currentText.downgrade}}
    </button>
    <button mat-button class="primary-btn gotBtn" (click)="dialogRef.close(false)">{{textService.currentText.no}},
      {{textService.currentText.cancel}}
    </button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'twoFa'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2 class="mb-3">{{textService.currentText.twostepa}}</h2>
      </div>
      <div class="dialog-message twoFa">
        <p>
          {{textService.currentText.twoFaText}}
        </p>
        <p>
          {{textService.currentText.twoFaText1}}
        </p>
        <div class="row mt-4">
          <div class="col-md-6">
            <input matInput type="email" [value]="data.email" [placeholder]="textService.currentText.email"
              [disabled]="true" />
          </div>
          <div class="col-md-6">
            <input matInput [formControl]="password" type="password" [placeholder]="textService.currentText.password" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white" (click)="twoFaSubmit()">{{textService.currentText.submit}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'twoFaCode'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2 class="mb-3">{{textService.currentText.twostepa}}</h2>
      </div>
      <div class="dialog-message mfaCode">
        <p>
          {{textService.currentText.enter6DigitCode}}
        </p>
        <div class="mt-4">
          <form [formGroup]="form">
            <input #mfaInputFields type="number" *ngFor="let item of mfaInput; let i = index" [formControlName]="item"
              maxlength="1" (keyup)="onKeyUp($event, i)" (paste)="onPaste($event, i)" aria-required="true" required>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(form.value)">{{textService.currentText.submit}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'downgrade-success'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogDowngradeSuccess}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogDowngradeSuccessText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(false)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'reset-tester'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogResetTester}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{data.link}}
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(false)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'short-name-awareness'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogShortNameWarning}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogShortNameWarningText1}} <br>
          {{textService.currentText.dialogShortNameWarningText2}}<br>
          {{textService.currentText.dialogShortNameWarningText3}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white" (click)="dialogRef.close(true)">{{textService.currentText.sendAnyway}}
    </button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'bulk-products-alert'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogBulkAlert}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogBulkAlertText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'cancel-plan'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogCancelPlan}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogCancelPlanText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.no}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.yes}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'load-failure'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogWentWrong}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogWentWrongText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn" (click)="sendReport()">{{textService.currentText.sendReport}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'cancel-plan-success'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.success}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogCancelPlanSuccessText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'custom'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{data.title}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{data.message}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'grant-access-first'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.automatedReviewRequestservice}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.automatedReviewRequestservice1}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="navigateToAmazon()">{{textService.currentText.grantAccess}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'selling-grant-access'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <img src="assets/images/ninja-logo2.svg" alt="">
        <h2 class="mt-4">{{textService.currentText.grantAccessTitle5}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.grantAccessText2}}
        </p>
        <p class="small-text">
          {{textService.currentText.grantAccessSellerID}} {{data.sellerId}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="navigateToSignUp()">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'review-request-automation'"
  style="max-width: 660px; width: auto;">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.reviewRequestAutomation}}</h2>
      </div>
      <div class="dialog-message">
        <p class="mb-2">
          {{textService.currentText.reviewRequestAutomation1}}
        </p>
        <p class="mb-2">
          {{textService.currentText.reviewRequestAutomation2}}
        </p>
        <p class="mb-2">
          {{textService.currentText.reviewRequestAutomation3}}
        </p>
        <p>
          {{textService.currentText.reviewRequestAutomation4}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button *ngIf="!userService.currentUser.spToken" mat-button class="primary-btn gotBtn"
      (click)="navigateToAmazon()">{{textService.currentText.grantAccess}}</button>
    <button *ngIf="userService.currentUser.spToken" mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'custom-confirm'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{data.title}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{data.message}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.no}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.yes}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'custom-confirm-ok'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{data.title}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{data.message}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.ok}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'link-delete-confirm'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.removeAccount}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.unlinkPart1}} <span class="bold-roboto">{{data.email}}</span>
          {{textService.currentText.unlinkPart2}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.unlink}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'api-error'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogOops}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogOopsText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'unsubscribe-email'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.unsubscribe}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogUnsubscribeText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.no}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.yes}}</button>
  </div>
</div>


<div class="main-dialog-container small" *ngIf="data.dialogFor === 'trial-mounth-history'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogNeedUpgrade}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogTrialHistoryText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'dashboard-trial-mounth-history'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogNeedUpgrade}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dailogTrialHistory2}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white" [routerLink]="'dashboard/upgrade'" (click)="dialogRef.close(true)">
      {{textService.currentText.upgrade}}
    </button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.upgrade}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'fbm-missing-product'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogOops}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogFbmMissProducts}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="primary-btn " [routerLink]="'dashboard/automated-messaging/fba'"
      (click)="dialogRef.close('navigate')">{{textService.currentText.dialogFbmMissProductsButton1}}
    </button>
    <button mat-button class="default-btn white" (click)="dialogRef.close('report')">
      {{textService.currentText.dialogFbmMissProductsButton2}}
    </button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'delete-tester-confirm'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.confirm}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.deleteTesterText1}} {{data.tester.firstName}} {{data.tester.lastName}}
          {{textService.currentText.fromLowerCase}} {{data.page}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white" (click)="dialogRef.close()">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.delete}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'invoice-agreement'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.invoiceDialogTitle}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.invoiceDialogText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer space-between">
    <button mat-button class="default-btn white" (click)="dialogRef.close()">
      {{textService.currentText.dialogInoviceAgreementCancel}}
    </button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.iAgree}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'subscribers-att'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.warning}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.selectPageAndConversation}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer space-between">
    <button mat-button class="primary-btn gotBtn" (click)="dialogRef.close()">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'start-broadcast-message'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.warning}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogSubsNotSelected}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer space-between">
    <button mat-button class="default-btn white" (click)="dialogRef.close()">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.yes}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'delete-menu'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogDowngrade}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.areYouSure}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer space-between">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn" (click)="dialogRef.close(true)">{{textService.currentText.deleteMenu}}
    </button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'delete-menu-without-pageId'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.warning}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.selectPageConversation}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer space-between">
    <button mat-button class="primary-btn gotBtn" (click)="dialogRef.close(true)">Ok</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'delete-confirm-invoice'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogDeleteInvoice}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogDeleteInvoiceText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn" (click)="dialogRef.close(true)">{{textService.currentText.yesDelete}}
    </button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'delete-confirm-subscriber'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.unsubscribeTheUser}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.deleteSubscriber}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.yes}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'delete-confirm-sequence'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.deleteConfirmSequenceTitle}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.deleteConfirmSequenceText}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn" (click)="dialogRef.close(true)">{{textService.currentText.yesDelete}}
    </button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'auto-reply-overwrite'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.dialogOverwhriteRule}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.dialogPostAlreadyUsed}} <span class="highlighted">{{data.ruleName}}</span>
          {{textService.currentText.dialogPostAlreadyUsed2}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn" (click)="dialogRef.close(true)">{{textService.currentText.overwrite}}
    </button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'tag-webUrl-confiramation'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{data.title}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{data.subTitle2}}
        </p>
        <p>
          {{data.subTitle}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.continue}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'getLinkConversation'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{data.title}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{data.message}}<a [href]="data.link" target="_blank">
            {{data.link}}
          </a>
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.ok}}</button>
  </div>
</div>

<!--<div class="main-dialog-container" *ngIf="data.dialogFor === 'invoiceOrderNotFound'">-->
<!--<div class="close-btn" (click)="dialogRef.close(false)"></div>-->
<!--<div class="inner-dialog-container confirmation-dialog">-->
<!--<div class="dialog-content">-->
<!--<div class="header">-->
<!--<h2>{{textService.currnetText.invoiceOrderNotFoundTitle}}</h2>-->
<!--</div>-->
<!--<div class="dialog-message">-->
<!--<p>-->
<!--{{textService.currnetText.invoiceOrderNotFoundText1}}-->
<!--<br>-->
<!--{{textService.currnetText.invoiceOrderNotFoundText2}}-->
<!--<br>-->
<!--{{textService.currnetText.invoiceOrderNotFoundText3}}-->
<!--</p>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="dialog-footer single-btn">-->
<!--<button mat-button class="primary-btn" (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>-->
<!--</div>-->
<!--</div>-->


<div class="main-dialog-container small" *ngIf="data.dialogFor === 'reset-openRate'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.resetOpenRate}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{textService.currentText.resetOpenRateDialogText1}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="mixpanelService.track('q automated messaging - reset open rate cancel');dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="mixpanelService.track('q automated messaging - reset open rate save');dialogRef.close(true)">{{textService.currentText.reset}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'orderNotMatch'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.invoiceOrderDoesntMatch}}</h2>
      </div>
      <div class="dialog-message text-left">
        <p> {{textService.currentText.searchOrderErrorCode3text1}}</p>
        <p>
          <br>
          {{textService.currentText.searchOrderErrorCode3text2}}
          <span class="bold ">{{data.salesChannel}}</span>
          {{textService.currentText.searchOrderErrorCode3text3}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container" *ngIf="data.dialogFor === 'orderNotFound'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.invoiceOrderNotFoundTitle}}</h2>
      </div>
      <div class="dialog-message text-left">
        <p>{{textService.currentText.invoiceOrderNotFoundText1}}</p>
        <p> {{data.text}}</p>
        <p
          *ngIf="data.text === textService.currentText.invoiceOrderNotFoundText2 && !userService.currentUser.hasActivePlan">
          {{textService.currentText.invoiceOrderNotFoundText3}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.gotIt}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'custom-ok'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{data.title}}</h2>
      </div>
      <div class="dialog-message">
        <p>
          {{data.message1}}
        </p>
        <p>
          {{data.message2}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.ok}}</button>
  </div>
</div>

<div class="main-dialog-container small" *ngIf="data.dialogFor === 'order-fb-acceess'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.grantAccess}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.orderFbAccessDialogText}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.grantAccessToFb}}</button>
  </div>
</div>
<div class="main-dialog-container small" *ngIf="data.dialogFor === 'paypal-reregister'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.paypalReregister}}</h2>
      </div>
      <div class="dialog-message" style="white-space: pre-wrap">
        <p>{{textService.currentText.paypalReregisterText}}</p>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn white"
      (click)="dialogRef.close(false)">{{textService.currentText.paypalReregisterLater}}</button>
    <button mat-button class="primary-btn gotBtn"
      (click)="dialogRef.close(true)">{{textService.currentText.paypalReregisterNow}}</button>
  </div>
</div>