<div class="main-dialog-container ">
    <div class="close-btn" (click)="dialogRef.close(false)"></div>
    <div class="inner-dialog-container confirmation-dialog">
      <div class="dialog-content">
        <div class="header">
          <h2>{{textService.currentText.businessDetails}}</h2>
        </div>
        <ng-container name="form" [formGroup]="bussinesDetailsForm" novalidate>
            <div class="dialog-message">
                <div class="dialog-row" [class.has-danger]="errors.name">
                    <div class="label-name">
                      <span>{{textService.currentText.businessName}}</span>
                    </div>
                    <input matInput
                        #name
                        type="text"
                        formControlName="name">
                    <div class="opt-tooltip"
                    (mouseenter)="showBusinessNameTooltip = true"
                    (mouseleave)="showBusinessNameTooltip = false">
                      <span>?</span>
                      <div class="info-tooltip" *ngIf="showBusinessNameTooltip">
                        <p>{{textService.currentText.businessDetailsText}}</p>
                      </div>
                    </div>
                  </div>
              <div class="dialog-row" [class.has-danger]="errors.number">
                <div class="label-name">
                  <span>{{textService.currentText.businessRegNum}}</span>
                </div>
                <input matInput
                  #number
                  type="text"
                  formControlName="number">
                <div class="opt-tooltip"
                    (mouseenter)="showBusinessRegNumberTooltip = true"
                    (mouseleave)="showBusinessRegNumberTooltip = false">
                  <span>?</span>
                  <div class="info-tooltip" *ngIf="showBusinessRegNumberTooltip">
                    <p>{{textService.currentText.businessDetailsText}}</p>
                  </div>
                </div>
              </div>
            </div>
        </ng-container>
      </div>
    </div>
    <div class="dialog-footer">
      <button mat-button class="primary-btn" (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
      <button mat-button class="primary-btn" (click)="checkForm()">{{textService.currentText.save}}</button>
    </div>
  </div>
