import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Functions} from '../../../services/functions';
import {AccountSettings} from '../../../services/account-settings';
import {UserService} from '../../../services/user.service';
import {FormBuilder, Validators} from '@angular/forms';
import {TextService} from '../../../services/text.service';

@Component({
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss']
})
export class ContactUsDialogComponent implements OnInit {
  @ViewChild('email', { static: false }) email;
  @ViewChild('messageText', { static: false }) messageText;
  public notificationMessage: any;
  public notificationTitle: any;
  public errorEmailMessage = this.textService.currentText.errorIncorectEmail;
  public errors: any = {};
  public name = '';

  public contactUsForm = this.fb.group({
    email: ['',
      [Validators.email]],
    messageText: ['',
      [Validators.required]],
  });

  constructor(public dialogRef: MatDialogRef<ContactUsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public functions: Functions,
              public userService: UserService,
              public textService: TextService,
              public accountSettings: AccountSettings,
              public fb: FormBuilder) {
  }

  public ngOnInit(): void {
    this.email = this.userService.currentUser.email;
  }

  public checkForm(): void {
    if (!this.contactUsForm.valid) {
      this.displayError();
    } else {
      this.userService.sendContactUsMessage(this.contactUsForm.controls.email.value,
                                            this.name,
                                            this.contactUsForm.controls.messageText.value).subscribe(() => {
        this.dialogRef.close(true);
      });
    }
  }

  private displayError(): void {
    if (!this.contactUsForm.controls.email.valid) {
      this.errors.email = true;
      this.resetErrorState();
      return;
    }
    if (!this.contactUsForm.controls.messageText.valid) {
      this.errors.messageText = true;
      this.resetErrorState();
      return;
    }
  }

  private resetErrorState(): void {
    setTimeout(() => {
      if (!this.contactUsForm.controls.email.valid) {
        this.errors.email = false;
      }
      if (!this.contactUsForm.controls.messageText.valid) {
        this.errors.messageText = false;
      }
    }, 2000);
  }
}
