<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-header">
    <div class="head-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-7">
            <h2>{{textService.currentText.invoicingTitle}}</h2>
            <div class="sub-head-container">
              <p><span class="medium uppercase">{{textService.currentText.warning}}</span> :
                {{textService.currentText.invoicingSubTitle}}</p>
            </div>
          </div>
          <div class="col-md-5 d-flex align-items-center justify-content-center justify-content-md-end mt-4 mt-md-0">
            <button mat-button mat-raised-button class="primary-btn" (click)="mixpanelService.track('q invoicing main create clicked');createNewInvoice()" class="secondary-btn">
              {{textService.currentText.createNewInvoice}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-container">
    <h3 class="font-size-18 mb-0 mr-3">{{textService.currentText.invoices}}</h3>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header invoice-table" [class.full-width]="invoiceList?.length <= 7">
            <div class="td"></div>
            <div class="td">
              {{textService.currentText.autoUpload}}
            </div>
            <div class="td" (click)="functions.sortColumns(invoiceList, 'invoiceTemplateName', true)">
              {{textService.currentText.invoiceName}}</div>
            <div class="td" (click)="functions.sortColumns(invoiceList, 'invoiceTemplateCompanyName', true)">
              {{textService.currentText.companyName}}</div>
            <div class="td"> {{textService.currentText.salesChannel}}</div>
            <div class="td" (click)="functions.sortColumns(invoiceList, 'invoiceTemplateVat')">
              {{textService.currentText.VAT}} %</div>
            <div class="td"></div>
          </div>
        </div>
        <div class="table-holder invoice-table">
          <div class="table">
            <ng-container *ngFor="let invoice of invoiceList">
              <div class="tr" (click)="navigateToEdit(invoice.invoiceTemplateId)">
                <div class="td">
                  <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"></button>
                  <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                    <button mat-menu-item (click)="mixpanelService.track('q invoicing main edit clicked');navigateToEdit(invoice.invoiceTemplateId)">
                      <div class="list-inner-container">
                        <div class="menu-icon icon-edit"></div>
                        {{textService.currentText.edit}}
                      </div>
                    </button>
                    <button mat-menu-item (click)="mixpanelService.track('q invoicing main duplicate clicked');navigateToDuplicate(invoice.invoiceTemplateId)">
                      <div class="list-inner-container">
                        <div class="menu-icon icon-copy"></div>
                        {{textService.currentText.duplicate}}
                      </div>
                    </button>
                    <button mat-menu-item (click)="mixpanelService.track('q invoicing main delete clicked');showDeleteConfirm(invoice)">
                      <div class="list-inner-container">
                        <div class="menu-icon icon-delete"></div>
                        {{textService.currentText.delete}}
                      </div>
                    </button>
                  </mat-menu>
                </div>
                <div class="td">
                  <span *ngIf="checkMarketplace(invoice.invoiceTemplateSalesChannels)">
                    <mat-checkbox class="square-checkbox"
                      (click)="updateStatus(invoice, invoice.status); $event.stopPropagation()"
                      [checked]="invoice.status" [class.uncheckedStatus]="!invoice.status"></mat-checkbox>
                  </span>
                  <span *ngIf="!checkMarketplace(invoice.invoiceTemplateSalesChannels)">
                    <img class="m-auto" matTooltip="Available only in EU" src="assets/icons/excludefromtemplates.png">
                  </span>
                </div>
                <div class="td">
                  {{invoice.invoiceTemplateName}}
                </div>
                <div class="td">
                  {{invoice.invoiceTemplateCompanyName}}
                </div>
                <div class="td">
                  <ng-container *ngFor="let markeplace of invoice.invoiceTemplateSalesChannels">
                    {{markeplace}}
                    <!-- <ng-container *ngIf="setFlag(invoice.invoiceTemplateSalesChannels)">
                    </ng-container> -->
                    <!-- {{markeplace}} -->
                    <!-- <ng-container *ngIf="setFlag(invoice.invoiceTemplateSalesChannels)">
                      {{invoice.invoiceTemplateSalesChannels}}
                      <ng-container *ngIf="setFlag(markeplace)">
                        <ng-container *ngFor="let index of markeplace" >
                          {{index}}
                        </ng-container>
                      </ng-container>
                      <ng-container *ngFor="let index of markeplace" >
                        <img class="m-auto" src="{{index.image}}">
                      </ng-container>
                    </ng-container> -->
                  </ng-container>
                </div>
                <div class="td">
                  {{invoice.invoiceTemplateVat}} %
                </div>
                <div class="td">
                  <button class="mat-button default-btn secondary-outline-btn"
                    (click)="mixpanelService.track('q invoicing main send manual clicked');showSendInvoiceDialog(invoice.invoiceTemplateId); $event.stopPropagation()">
                    {{textService.currentText.sendManual}}
                  </button>
                </div>
              </div>
            </ng-container>
            <div class="tr" *ngIf="invoiceList?.length === 0">
              <div class="td not-found">
                {{textService.currentText.noDataFound}}.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>