<div class="main-dialog-container auto-response">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content" name="form" [formGroup]="submitForm" novalidate>
      <div class="header">
        <h2>{{popupTitle}}</h2>
      </div>
      <div class="dialog-row status">
        <div class="left">
          <mat-radio-group formControlName="status" (change)="dataToSave.status = !dataToSave.status">
            <mat-radio-button disableRipple [value]="true">Active</mat-radio-button>
            <mat-radio-button disableRipple [value]="false">Inactive</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="right">
          <mat-checkbox
              disableRipple formControlName="direct">
            {{textService.currentText.autoRespApplyOnly}}
          </mat-checkbox>
          <div class="opt-tooltip" (click)="showDirectResponseHelpDialog()">
            <span>?</span>
          </div>
        </div>
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.ruleName">
        <div class="label-name">
          <span>{{textService.currentText.ruleName}}</span>
        </div>
        <input
            matInput
            #ruleNameId
            formControlName="ruleName"
            name="ruleName"
            type="text">
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.keyword">
        <div class="label-name">
          <span>{{textService.currentText.keyword}} </span>
        </div>
        <input matInput
               formControlName="keyword"
               #keywordId
               name="ruleName"
               type="text">
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.message">
        <div class="label-name">
          <span>{{textService.currentText.message}} </span>
        </div>
        <input matInput
               formControlName="message"
               #messageId
               name="ruleName"
               type="text">
      </div>
      <div class="dialog-row custom-selects">
        <div class="label-name">
          <span>{{textService.currentText.selectPage}} </span>
        </div>
        <div class="select-holder" [class.has-danger]="errors?.currentFbPage">
          <div (click)="functions.setDropDownPosition(allProductsSelect, 'allProducts')"
               #allProductsSelect
               *ngIf="!errors?.currentFbPage"
               [class.active]="allProductsId.panelOpen"
               class="select-wrapper">
            <mat-form-field>
              <mat-select
                  shouldPlaceholderFloat="false"
                  panelClass="default-dropdown allProducts"
                  #allProductsId
                  formControlName="currentFbPage"
                  [placeholder]="pageNamePlaceholder"
                  (selectionChange)="setPageDetail()"
                  disableRipple
                  name="allProducts">
                <mat-option *ngFor="let select of pages" [value]="select">
                  {{ select.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <input matInput
                 *ngIf="errors?.currentFbPage"
                 type="text"
                 name="subject"
                 [value]="textService.currentText.errorPageNotSelected"
          >
        </div>
        <button mat-button
                [disabled]="!submitForm.controls['currentFbPage'].value || submitForm.controls['currentFbPage'].value.length"
                mat-raised-button
                class="default-btn"
                (click)="showSelectPostDialog()">
          {{textService.currentText.selectPost}}
        </button>
      </div>
      <div class="text-row">
        <div class="txt">
          <span>{{textService.currentText.postPreview}}</span>
        </div>
        <textarea [class.has-danger-textarea]="errors?.previewMsg"
                  disabled="true"
                  #previewMsgId
                  formControlName="previewMsg"
                  name="previewMsg">
        </textarea>
      </div>
    </div>
  </div>
  <div class="dialog-footer space-between">
    <button mat-button
            class="primary-btn white"
            (click)="dialogRef.close(false)">{{textService.currentText.cancel}}
    </button>
    <button mat-button class="primary-btn" (click)="saveNewResponse()">{{textService.currentText.save}}</button>
  </div>
</div>
