import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';
import { StorageBrowser } from 'src/app/services/storage.browser';
import { ProfitbreakdownpopupComponent } from '../../popups/profitbreakdownpopup/profitbreakdownpopup.component';
import { ErrorsComponent } from '../../popups/errors/errors.component';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  displayedColumns: string[] = ['img','shortName','skus','units'];
  dataSource: MatTableDataSource<any>;
  title: string;
  totalRes: any = [];
  totalVal: any = 0;
  totalPrevVal: any = 0;
  currencySymbol: any;
  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<ListComponent>, public userService: UserService, @Inject(MAT_DIALOG_DATA) public data: any, public storageBrowser: StorageBrowser) {
    this.currencySymbol = this.storageBrowser.get('$Currency');
  }

  ngOnInit() {

    if (this.data && this.data.dataSource && this.data.dataSource.valuePerSku.length > 0) {
      // this.data.dataSource.forEach(objVal => {
      //   this.totalVal += objVal.value;
      //   this.totalPrevVal += objVal.valuePrev;
      // });
      // if (this.data.title == 'Margin') {
      //   this.totalVal = Number((this.totalVal / (this.data.dataSource.length + 100) * 100).toFixed(2));
      //   this.totalPrevVal = Number((this.totalPrevVal / (this.data.dataSource.length + 100) * 100).toFixed(2));
      // } else if (this.data.title == 'Units Sold') {
      //   this.totalVal = (this.totalVal);
      //   this.totalPrevVal = (this.totalPrevVal);
      // } else {
      //   this.totalVal = Number(this.totalVal).toFixed(2);
      //   this.totalPrevVal = Number(this.totalPrevVal).toFixed(2);
      // }
      // if (this.data.title != 'Units Sold' && this.data.title != 'Margin') {
      //   this.data.dataSource.valuePerSku.forEach(element => {
      //     element.value = this.userService.getCurrencySymbol() + element.value;
      //   });
      // }
      this.data.dataSource.valuePerSku.push({ 'img': '', 'shortName': 'Total', 'value': this.data.dataSource.total, 'valuePrev': this.data.dataSource.totalPrev, 'change': '' });
      if (this.data.dataSource.profitReal) {
        this.data.dataSource.valuePerSku.push({ 'img': '', 'shortName': 'PPC', 'value': this.data.dataSource.totalRequestedPPCForAllSkus == 0 ? 'N/A' : this.data.dataSource.totalRequestedPPCForAllSkus, 'valuePrev': this.data.dataSource.totalPreviousePPCForAllSkus == 0 ? 'N/A' : this.data.dataSource.totalPreviousePPCForAllSkus, 'change': '' });
      }

    }


  }

  closePopup() {
    this.dialogRef.close();
  }

  openProfitBrakedownDialog(sku) {
    this.userService.loaderObesrver.next(true);
    if (sku == "total") {
      for(let i = 0; i < this.data.dataSource.valuePerSku.length; i++) {
        this.data.formData.skus.push(this.data.dataSource.valuePerSku[i].sku);
      }
    } else {
      this.data.formData.skus = [sku];
    }
    this.userService.getProfitBreakdownList("time_frame/", this.data.formData).subscribe((res: any) => {
      if (res) {
        res.formData = JSON.parse(JSON.stringify(this.data.formData));
        this.dialog.open(ProfitbreakdownpopupComponent, {
          width: '470px',
          panelClass: 'profitbreakdownpopup',
          height: 'auto',
          data: res
        }).afterClosed().subscribe((result) => {
          if(result) {
            if (result == 'closeProfitDialog') {
              this.dialogRef.close();
            } else {
              for (let i = 0; i < this.data.dataSource.valuePerSku.length; i++) {
                if (this.data.dataSource.valuePerSku[i].sku === result.SKU) {
                  this.data.dataSource.valuePerSku.shortName = result.shortName;
                }
              }
            }
          }
        });
        this.data.formData.skus = [];
        this.data.formData.brands = [];
        this.userService.loaderObesrver.next(false);
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

}
