import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageBrowser } from 'src/app/services/storage.browser';
import { UserService } from 'src/app/services/user.service';
import { EditProductCostDialogComponent } from '../../../../dialogs/edit-product-cost/edit-product-cost-dialog.component';
import { Router } from "@angular/router";
import { ListComponent } from "../list/list.component";
@Component({
  selector: 'app-profitbreakdownpopup',
  templateUrl: './profitbreakdownpopup.component.html',
  styleUrls: ['./profitbreakdownpopup.component.scss']
})
export class ProfitbreakdownpopupComponent implements OnInit {
  resultData: any;
  constructor(private router: Router, private dialog: MatDialog, public userService: UserService, public dialogRef: MatDialogRef<ProfitbreakdownpopupComponent>, public profitdialogRef: MatDialogRef<ListComponent>, @Inject(MAT_DIALOG_DATA) public profitBreakdown: any, public storageBrowser: StorageBrowser) {
    this.currencySymbol = this.storageBrowser.get('$Currency');
  }
  currencySymbol: any;
  public isOpen: boolean = false;
  public isOpen1: boolean = false;
  public isOpen2: boolean = false;
  public isOpen3: boolean = false;
  ngOnInit() {
    this.dialogRef.beforeClosed().subscribe(() => this.dialogRef.close(this.resultData ? this.resultData : (this.profitBreakdown.formData.skus.length > 1 || this.profitBreakdown.formData.skus.length == 0) ? 'closeProfitDialog' : ' '));
  }
  expandedData() {
    this.isOpen = !this.isOpen;
  }
  expandedData1() {
    this.isOpen1 = !this.isOpen1;
  }
  expandedData2() {
    this.isOpen2 = !this.isOpen2;
  }
  expandedData3() {
    this.isOpen3 = !this.isOpen3;
  }

  downloadPNL() {
    this.userService.getDownloadPNL(this.profitBreakdown.formData).subscribe((res: any) => {
      if (res) {
        this.downloadFile(res.link);
      }
    });
  }

  public downloadFile(path) {
    const file_path = path;
    const a: HTMLElement = document.createElement('A') as HTMLElement;
    a['href'] = file_path;
    a['download'] = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  editProductCost(): void {
    let formData = {
      sku: this.profitBreakdown.formData.skus[0]
    }
    if (this.profitBreakdown.formData.skus.length > 1 || this.profitBreakdown.formData.skus.length == 0) {
      this.dialogRef.close();
      this.router.navigate(['/dashboard/user/settings/product-settings']);
    } else {
      this.dialog.open(EditProductCostDialogComponent, {
        data: {
          'dialogFor': 'edit-product-dialog',
          'product': formData,
        },
        panelClass: 'edit-product-dialog',
        width: '1350px'
      }).afterClosed().subscribe((result) => {
        if (result) {
          this.resultData = result;
        }
      });
    }
  }

}
