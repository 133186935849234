import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../services/token.service';
import { UserService } from '../../services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { ConstService } from '../../services/const-service';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SystemMessagesService } from '../../services/system-messages.service';
import { environment } from '../../../environments/environment.prod';
import { Observable } from 'rxjs';
import { StorageBrowser } from '../../services/storage.browser';

@Component({
  selector: 'ninja-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  public user: any;
  public fbaActived: boolean;
  public notificationMessage = 'Your message has been sent!';
  public notificationTitle = 'Message sent';
  public timer: any;
  public grantAccessLinks: any;
  public loaderImage: any;
  public fullScreenMode: boolean;

  constructor(public tokenService: TokenService,
    public userService: UserService,
    public matDialog: MatDialog,
    public constService: ConstService,
    public storageBrowser: StorageBrowser,
    private systemMessagesService: SystemMessagesService,
    public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.tokenService.getToken().id) {
          this.userService.tokenCheck().subscribe((data) => {
            if (data.isLogged) {
              this.userService.currentUser = this.tokenService.getToken().user;
              this.systemMessagesService.getSystemMessages(this.userService.currentUser.id)
                .subscribe((messages) => {
                  this.systemMessagesService.setMessages(messages);
                });
            } else {
              this.matDialog.open(ConfirmationDialogComponent, {
                data: {
                  'dialogFor': 'session-expired',
                },
              }).afterClosed().subscribe(() => {
                this.tokenService.clear();
                window.location.reload(true);
              });
              setTimeout(() => {
                this.tokenService.clear();
                window.location.reload(true);
              }, 3000);
            }
          });
        }
      }
    });
  }

  grantAccessLinksEvent(event) {
    this.grantAccessLinks = event;
  }

  public ngOnInit(): void {
    this.userService.getGrantAccessUrls(this.userService.currentUser.id).subscribe((links) => {
      this.grantAccessLinks = links;
    });
    if (!this.tokenService.getToken().user) {
      this.router.navigate(['/login']);
    } else {
      if (this.router.url === '/dashboard/automated-messaging/fba' ||
        this.router.url === '/dashboard/automated-messaging/fbm' ||
        this.router.url === '/dashboard/automated-messaging/bulk') {
      }

      if (this.tokenService.getToken().id) {
        this.userService.currentUser = this.tokenService.getToken().user;
        this.constService.setRegions(this.tokenService.getToken().user);
        this.userService.tokenCheck().subscribe((data) => {
          if (data.isLogged) {
            this.user = this.tokenService.getToken().user;
            this.userService.currentUser = this.tokenService.getToken().user;
          } else {
            this.matDialog.open(ConfirmationDialogComponent, {
              data: {
                'dialogFor': 'session-expired',
              },
            }).afterClosed().subscribe(() => {
              this.tokenService.clear();
              window.location.reload(true);
            });
          }
        });
      } else {
        this.router.navigateByUrl('/dashboard/dashboard-schedules');
      }
    }
    this.userService.changeLoaderObserver().subscribe((value) => {
      setTimeout(() => {
        this.loaderImage = '../../../assets/images/preloader.svg'
        this.userService.dashboardLoader = value;
      });
      clearTimeout(this.timer);
      if (value) {
        this.timer = setTimeout(() => {
          this.matDialog.open(ConfirmationDialogComponent, {
            data: {
              'dialogFor': 'load-failure',
            },
          }).afterClosed().subscribe(() => {
            this.loaderImage = '../../../assets/images/preloader-stuck.svg';
          });
        }, 60000);
      }
    });
  }

}
