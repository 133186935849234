<div matDialogTitle>
    <div class="d-flex w-100 align-items-center justify-content-between headingTitle mb-2">
        <h2 class="mb-0" [textContent]="data.title"></h2>
        <mat-icon class="close cursor-pointer" (click)="closePopup()">close</mat-icon>
    </div>
    <div class="d-flex w-100 align-items-center">
        <h4 class="mr-2">Current</h4>
        <h4 [textContent]="data.timeFrame"></h4>
    </div>
    <div class="d-flex w-100 align-items-center">
        <h4 class="text-muted mr-2">Previous</h4>
        <h4 class="text-muted" [textContent]="data.previousDate"></h4>
    </div>
    <!--  -->
</div>

<mat-dialog-content>
    <table mat-table [dataSource]="data.dataSource.valuePerSku" class="w-100">
        <!-- img Column -->
        <ng-container matColumnDef="img">
            <th mat-header-cell *matHeaderCellDef> img </th>
            <td class="p-0" mat-cell *matCellDef="let element">
                <img class="pr-2 imageStyling"  width="35" *ngIf="element.img" [src]="element.img" />
                <img class="pr-2 imageStyling"  width="35" *ngIf="!element.img && element.img != ''" src="assets/images/noImage.png" /> </td>
        </ng-container>

        <!-- shortName Column -->
        <ng-container matColumnDef="shortName">
            <th mat-header-cell *matHeaderCellDef> shortName </th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.shortName"
                [ngClass]="(element.shortName == 'Total' || element.shortName == 'PPC')? 'pr-3 shortNameCls font-weight-bold' :'pr-3 shortNameCls'">
                {{element.shortName}} </td>
        </ng-container>

         <!-- skus Column -->
         <ng-container matColumnDef="skus">
            <th mat-header-cell *matHeaderCellDef> skus </th>
            <td mat-cell *matCellDef="let element" class="content-mobile">
                {{element.sku ? element.sku : ''}} </td>
        </ng-container>

        <!-- units Column -->
        <ng-container matColumnDef="units"]>
            <th mat-header-cell *matHeaderCellDef> units </th>
            <td mat-cell *matCellDef="let element" class="text-right pr-2" [ngClass]="data.title == 'Profit' ? 'profitunit' : ''">
                <div class="d-flex align-items-center justify-content-end">
                    <table>
                        <tr>
                            <td>
                                <h3 class="m-0 text-muted">
                                    {{data.title == 'Margin' ? (element.valuePrev?element.valuePrev:0)+'%' : data.title != 'Units Sold' ? (element.valuePrev?element.valuePrev == 'N/A'?element.valuePrev:(element.valuePrev | currency : currencySymbol : 'symbol'):0) : (element.valuePrev?element.valuePrev:0) }}
                                </h3>
                            </td>
                            <td>
                                <div class="mx-1 text-center">
                                    <mat-icon class="text-green" *ngIf="element.value>element.valuePrev">
                                        trending_up</mat-icon>
                                    <mat-icon class="text-red" *ngIf="element.value<element.valuePrev">
                                        trending_down</mat-icon>
                                    <mat-icon class="text-muted" *ngIf="element.value==element.valuePrev">
                                        remove</mat-icon>
                                </div>
                            </td>
                            <td>
                                <h3 class="m-0 text-left">
                                    {{data.title == 'Margin' ? (element.value?element.value:0)+'%' : data.title != 'Units Sold' ? (element.value?element.value == 'N/A'?element.value:(element.value | currency : currencySymbol : 'symbol'):0) : (element.value?element.value:0) }}
                                </h3>
                            </td>
                        </tr>
                    </table>
                    <div *ngIf="data.title == 'Profit'" (click)="openProfitBrakedownDialog(element.shortName == 'Total' ? 'total' : element.sku)" class="cursor-pointer ml-3">
                        <img matTooltip="Profit & Loss" height="18" width="18" src="assets/images/profit_breakdown.png" />
                    </div>
                    <!-- <small class="text-muted" [textContent]="element.valuePrev"></small> -->


                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
    </table>
    <!-- <div class="d-flex w-100 align-items-center justify-content-between pt-2">

        <h3><strong>Total</strong></h3>
        <div class="d-flex pr-2 totalValS text-right pr-2">
            <table>
                <tr>
                    <td>
                        <h3 class="m-0 text-muted">{{data.title == 'Margin' ? totalPrevVal+'%' : totalPrevVal }}</h3>
                    </td>
                    <td>
                        <div class="mx-1 d-flex align-items-center">
                            <mat-icon class="text-green" *ngIf="totalVal>totalPrevVal">
                                trending_up</mat-icon>
                            <mat-icon class="text-red" *ngIf="totalVal<totalPrevVal">
                                trending_down</mat-icon>
                            <mat-icon class="text-muted" *ngIf="totalVal==totalPrevVal">
                                remove</mat-icon>
                        </div>
                    </td>
                    <td>
                        <h3 class="m-0 text-left">{{data.title == 'Margin' ? totalVal+'%' : totalVal }}</h3>
                    </td>
                </tr>
            </table>



        </div>
    </div> -->
</mat-dialog-content>