<div class="filterMenu">
     <form class="menu-form custom-selects filters open-filters flex-column">
          <div *ngIf="rowType=='number'" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">

               <h6>Select [field]s that:</h6>
               <mat-form-field class="mt-3">
                    <mat-label>Select</mat-label>
                    <mat-select [(ngModel)]="type" name="type">
                         <mat-option value="equal">Equal</mat-option>
                         <mat-option value="not-equal">Not Equal</mat-option>
                         <mat-option value="less-than">Less Than</mat-option>
                         <mat-option value="greater-than">Greater Than</mat-option>
                         <mat-option value="null">Null</mat-option>
                         <mat-option value="not-null">Not Null</mat-option>
                    </mat-select>
               </mat-form-field>
               <mat-form-field class="mt-3">
                    <input matInput placeholder="Search Term" [(ngModel)]="serachType" name="searchType">
               </mat-form-field>
               <div class="mt-3">
                    <mat-radio-group [(ngModel)]="conditionData" name="conditionData">
                         <mat-radio-button value="1">And</mat-radio-button>
                         <mat-radio-button class="ml-3" value="2">Or</mat-radio-button>
                    </mat-radio-group>
               </div>
               <mat-form-field class="mt-3">
                    <mat-label>Select</mat-label>
                    <mat-select [(ngModel)]="type1" name="type1">
                         <mat-option value="equal">Equal</mat-option>
                         <mat-option value="not-equal">Not Equal</mat-option>
                         <mat-option value="less-than">Less Than</mat-option>
                         <mat-option value="greater-than">Greater Than</mat-option>
                         <mat-option value="null">Null</mat-option>
                         <mat-option value="not-null">Not Null</mat-option>
                    </mat-select>
               </mat-form-field>
               <mat-form-field class="mt-3">
                    <input matInput placeholder="Search Term" [(ngModel)]="serachType1" name="serachType1">
               </mat-form-field>
               <span class="mt-3">
                    <button class="mt-3" mat-raised-button type="button" (click)="clearFilterData()">Clear</button>
                    &nbsp;
                    <button class="mt-3" mat-raised-button type="button" (click)="saveFilterData()">Filter</button>
               </span>
          </div>
          <div *ngIf="rowType=='textual'" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">

               <h6>Select [field]s that:</h6>
               <mat-form-field class="mt-3">
                    <mat-label>Select</mat-label>
                    <mat-select [(ngModel)]="textualtype" name="textualtype">
                         <mat-option value="equal">Equal</mat-option>
                         <mat-option value="start-with">Start With</mat-option>
                         <mat-option value="contains">Contains</mat-option>
                         <mat-option value="not-equal">Not Equal</mat-option>
                         <mat-option value="end-with">End With</mat-option>
                         <mat-option value="does-not-contain">Does Not Contain</mat-option>
                    </mat-select>
               </mat-form-field>
               <mat-form-field class="mt-3">
                    <input matInput placeholder="Search Term" [(ngModel)]="textualserachType" name="textualsearchType">
               </mat-form-field>
               <div class="mt-3">
                    <mat-radio-group [(ngModel)]="textualconditionData" name="textualconditionData" class="mt-3">
                         <mat-radio-button value="1">And</mat-radio-button>
                         <mat-radio-button class="ml-3" value="2">Or</mat-radio-button>
                    </mat-radio-group>
               </div>
               <mat-form-field class="mt-3">
                    <mat-label>Select</mat-label>
                    <mat-select [(ngModel)]="textualtype1" name="textualtype1">
                         <mat-option value="equal">Equal</mat-option>
                         <mat-option value="start-with">Start With</mat-option>
                         <mat-option value="contains">Contains</mat-option>
                         <mat-option value="not-equal">Not Equal</mat-option>
                         <mat-option value="end-with">End With</mat-option>
                         <mat-option value="does-not-contain">Does Not Contain</mat-option>
                    </mat-select>
               </mat-form-field>
               <mat-form-field class="mt-3">
                    <input matInput placeholder="Search Term" [(ngModel)]="textualserachType1" name="textualserachType1">
               </mat-form-field>
               <span class="mt-3">
                    <button class="mt-3" mat-raised-button type="button" (click)="clearFilterData()">Clear</button>
                    &nbsp;
                    <button class="mt-3" mat-raised-button type="button" (click)="saveFilterWithTitle()">Filter</button>
               </span>

          </div>
     </form>
</div>