<div class="dashboard" [class.wide]="userService.smallSidebar">
  <div class="dashboard-header user-settings-header" [class.bot-indent]="isLastRoute">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h2>{{accountSettings.settingsTitle}}</h2>
          <!-- <p>{{accountSettings.settingsInfo}}</p> -->
          <!-- <div class="b-side">
            <button mat-button
                    *ngIf="!userService.currentUser.isVa"
                    class="default-btn"
                    [class.active]="accountSettings.currentStep == 1"
                    [routerLink]="'account-settings'">
              {{textService.currentText.settingsAccTitle}}
            </button>
            <button mat-button
                    class="default-btn"
                    [class.active]="accountSettings.currentStep == 2"
                    [routerLink]="'product-settings'">
              {{textService.currentText.productsSettings}}
            </button>
            <button mat-button
                    class="default-btn"
                    [class.active]="accountSettings.currentStep == 3"
                    [routerLink]="'expenses'">
              {{textService.currentText.expenseTitle}}
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-container mb-5">
    <router-outlet></router-outlet>
  </div>
</div>