import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Functions } from '../../../../services/functions';
import { UserService } from '../../../../services/user.service';
import { AccountSettings } from '../../../../services/account-settings';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TextService } from '../../../../services/text.service';
import { Router } from "@angular/router";
import { ConstService } from '../../../../services/const-service';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'ninja-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})

export class AccountSettingsComponent implements OnInit {
  @ViewChild('email', { static: true }) email: ElementRef;
  @ViewChild('email2', { static: true }) email2: ElementRef;
  @ViewChild('passwordInput', { static: true }) passwordInput: ElementRef;
  @ViewChild('confirmPassInput', { static: false }) confirmPassInput: ElementRef;
  public userAccData: any;
  public links: any;
  public errors: any = {};
  public defaultUserData;
  public addresses = [];
  public emailForm: FormControl;
  public emailFormSent: FormControl;
  public confirPassword: FormControl;
  public password: FormControl;
  public mfa: FormControl;
  public passwordInputType = 'password';
  public isPasswordTooltip = false;
  public disableSaveButton: boolean;
  public isEditMode = false;
  public capsOn = false;
  public dataChangedAfterSave = false;
  public dataSaved = true;
  public showMFAError = false;
  public currencies = [
    { value: 'USD', viewValue: 'USD' },
    { value: 'EUR', viewValue: 'EUR' },
    { value: 'GBP', viewValue: 'GBP' },
    { value: 'JPY', viewValue: 'JPY' },
  ];
  public tooltipPassword = 'Password must be at least 6 characters long and contain digits(0-9), lowercase letters (a-z) and nonalphanumeric characters: ~!@#$%^&amp;*_-+=`|(){}[]:;"\'<>,.?/"';

  constructor(public accountSettings: AccountSettings,
    public textService: TextService,
    public constService: ConstService,
    public functions: Functions,
    public router: Router,
    public matDialog: MatDialog,
    public mixpanelService: MixpanelService,
    public fb: FormBuilder,
    public userService: UserService) {
      this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.userService.currentPage = '';
      this.userService.loaderObesrver.next(true);
      this.accountSettings.currentStep = 1;
      this.accountSettings.settingsTitle = this.textService.currentText.settingsAccTitle;
      this.accountSettings.settingsInfo = this.textService.currentText.settingsAccSubTitle;
      this.textService.changeLoaderObserver().subscribe(() => {
        this.accountSettings.settingsTitle = this.textService.currentText.settingsAccTitle;
        this.accountSettings.settingsInfo = this.textService.currentText.settingsAccSubTitle;
      });
    });
    this.emailForm = new FormControl('', [
      Validators.required,
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    ]);
    this.emailFormSent = new FormControl('', [
      Validators.required,
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    ]);
    this.password = new FormControl('', [
      Validators.required,
      Validators.pattern(/(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[a-zA-Z]).*$/)
    ]);
    this.confirPassword = new FormControl('', [
      Validators.required]);
    this.mfa = new  FormControl('');
    this.disableForm();
    this.userAccData = {
      businessName: '',
      businessRegistrationNumber: '',
      defaultCurrency: '',
      email: '',
      emailFrom: '',
      subAccounts: '',
      expirationDate: this.textService.currentText.settingsAccExpoDate,
      pass: '',
      confirmPass: '',
      subscriptionPlan: '',
      language: 'EN',
      isMfa: false
    };
    this.refreshAcc();
  }

  public refreshAcc() {

    this.accountSettings.getAccSettings(this.userService.currentUser.id).subscribe((data) => {
      this.userAccData = data;
      this.userAccData.pass = '';
      this.userAccData.confirmPass = '';
      if (this.userAccData.businessAddress) {
        this.addresses = this.userAccData.businessAddress;
      }
      if (!this.userAccData.language) {
        this.userAccData.language = 'EN';
      }
      this.userService.loaderObesrver.next(false);
      if (this.userAccData.expirationDate.length === 0) {
        this.userAccData.expirationDate = this.textService.currentText.settingsAccExpoDate;
      }
      this.defaultUserData = JSON.parse(JSON.stringify(this.userAccData));
    });
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    this.capsOn = event.getModifierState && event.getModifierState('CapsLock');
  }

  public grantAccess() {
    this.userService.getGrantAccessUrls(this.userService.currentUser.id).subscribe((links) => {
      this.links = links;
      let linkList = Object.keys(links)
      for (let i = 0; linkList.length > i; i++) {
        if (linkList[i].substring(0, 2).toLowerCase() === this.userService.currentUser.marketplace.toLowerCase()) {
          window.location.href = links[linkList[i]];
        }
      }
    });
  }

  public disableForm(): void {
    if (!this.isEditMode) {
      this.emailForm.disable();
      this.emailFormSent.disable();
      this.password.disable();
    } else {
      this.emailForm.enable();
      this.emailFormSent.enable();
      this.password.enable();
    }
  }

  public save(): void {
    let isMangeAcc = true;
    if (!this.emailForm.valid || !this.emailFormSent.valid || this.userAccData.pass.length > 0) {
      this.displayError();
    }
    if (this.userAccData.pass === this.userAccData.confirmPass && this.emailForm.valid && this.emailFormSent.valid) {
      const accSettingData = this.userAccData;
      if (this.addresses.length > 0) {
        this.userAccData.businessAddress = this.addresses;
      }
      accSettingData.publicId = this.userService.currentUser.id;
      accSettingData.sellerId = this.userService.currentUser.sellerId;

      for (let i = 0; this.userService.currentUser.mangedAccounts.length > i; i++) {
        if (this.userService.currentUser.mangedAccounts[i].email === accSettingData.email) {
          isMangeAcc = false
        }
      }
      this.accountSettings.saveAccSettings(accSettingData).subscribe((data) => {
        if (data) {
          if (!data.isSuccess) {
            this.errors.emailOnBack = true;
            const old_value = this.emailForm.value;
            this.emailForm.patchValue(data.message);
            this.emailForm.disable();
            this.email.nativeElement.blur();
            this.resetErrorState(old_value);
            this.emailForm.disable();
            this.isEditMode = true;
          } else {
            if (this.userService.currentUser.emailFrom !== this.userAccData.emailFrom) {
              this.showEmailChangeWarning();
            }
            this.isEditMode = false;
            this.disableForm();
            this.dataChangedAfterSave = data.isSuccess;
            this.userService.currentUser.emailFrom = this.userAccData.emailFrom;
            if (this.userService.currentUser.mainEmail) {
              this.userService.currentUser.email = this.emailForm.value
            } else {
              for (let i = 0; this.userService.currentUser.mangedAccounts.length > i; i++) {
                if (this.userService.currentUser.mangedAccounts[i].sellerId === accSettingData.sellerId) {
                  this.userService.currentUser.mangedAccounts[i].email = this.emailForm.value;
                }
              }
            }
          }
          this.refreshAcc();
        }
      });
    }
  }

  public checkChanges(): void {
    if (JSON.stringify(this.defaultUserData) !== JSON.stringify(this.userAccData)) {
      this.dataSaved = false;
    }
  }

  public showEmailChangeWarning(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'email-change-warning',
      },
    }).afterClosed().subscribe();
  }

  public showTooltip(e): void {
    this.isPasswordTooltip = e;
  }

  public canDeactivate(): any {
    if (this.dataChangedAfterSave) {
      return true;
    }
    this.checkChanges();
    if (this.dataSaved) {
      return true;
    } else {
      return this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'leave-confirm',
        },
      }).afterClosed();
    }
  }

  private displayError(): void {
    this.disableSaveButton = true;
    if (!this.emailForm.valid) {
      this.errors.email = true;
      const old_value = this.emailForm.value;
      if (!this.emailForm.value || this.emailForm.value.length === 0) {
        this.emailForm.patchValue(this.textService.currentText.errorRequired);
      } else {
        this.emailForm.patchValue(this.textService.currentText.errorIncorectEmail);
      }
      this.emailForm.disable();
      this.email.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.emailFormSent.valid) {
      this.errors.email2 = true;
      const old_value = this.emailFormSent.value;
      if (!this.emailFormSent.value || this.emailFormSent.value.length === 0) {
        this.emailFormSent.patchValue(this.textService.currentText.errorRequired);
      } else {
        this.emailFormSent.patchValue(this.textService.currentText.errorIncorectEmail);
      }
      this.emailFormSent.disable();
      this.email2.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.password.valid) {
      this.passwordInputType = 'text';
      this.errors.passwordInput = true;
      const old_value = this.password.value;
      this.password.patchValue(this.textService.currentText.errorIncorectPassFormat);
      this.password.disable();
      this.passwordInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (this.password.value !== this.confirPassword.value) {
      this.passwordInputType = 'text';
      this.errors.passwordInput = true;
      const old_value = this.password.value;
      this.password.patchValue(this.textService.currentText.errorPassNotMath);
      this.password.disable();
      this.passwordInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      this.disableSaveButton = false;
      if (!this.emailForm.valid || this.errors.emailOnBack) {
        this.errors.email = false;
        this.errors.emailOnBack = false;
        this.emailForm.patchValue(val);
        this.emailForm.enable();
        return;
      }
      if (!this.emailFormSent.valid) {
        this.errors.email2 = false;
        this.emailFormSent.patchValue(val);
        this.emailFormSent.enable();
        return;
      }
      if (!this.password.valid) {
        this.passwordInputType = 'password';
        this.errors.passwordInput = false;
        this.password.patchValue(val);
        this.password.enable();
        return;
      }
    }, 2000);
  }

  public grantAcc(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'add-region',
        'grantAccess': false
      },
    }).afterClosed().subscribe();
  }

  public twoFa(value) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'twoFa',
        'email': this.userService.currentUser.email
      },
    }).afterClosed().subscribe(res => {
      if (res) {
        this.userService.loader = true;
        this.userService.login({
          'login': this.userService.currentUser.email,
          'pass': res
        }).subscribe(
          (userData) => {
            this.userService.loader = false;
            if (userData.id === 0) {
              this.mfa.patchValue(this.defaultUserData.isMfa);
              return;
            }
            this.matDialog.open(ConfirmationDialogComponent, {
              data: {
                'dialogFor': 'twoFaCode',
              },
            }).afterClosed().subscribe((data) => {
              if(data) {
                this.userService.updateMfa({
                  publicId: this.userService.currentUser.id,
                  isMfa: value, 
                  mfaCode : data.input0 + '' + data.input1 + '' + data.input2 + '' + data.input3 + '' + data.input4 + '' + data.input5
                }).subscribe((data) => {
                  if(data.message === 'MFA login not valid') {
                    this.showMFAError = true;
                    this.mfa.patchValue(this.defaultUserData.isMfa);

                    setTimeout(() => {
                      this.showMFAError = false;
                    }, 5000);
                  }
                })
              } else {
                this.mfa.patchValue(this.defaultUserData.isMfa);
              }
            });
          },
          error => {
            this.userService.loader = false;
          });
      } else {
        this.mfa.patchValue(this.defaultUserData.isMfa);
      }
    });
  }
}
