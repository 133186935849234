<div class="payment-holder">
    <img class="header-image" src="../../../../assets/images/payment-ninja.png">
    <h1 class="thanks-text mt-3">Thanks For Choosing Ninja</h1>
    <div *ngIf="isPaymentSuccess">
        <p class="normal-font-16 width-68">We’re processing your transaction, this may take a few seconds</p>
        <mat-progress-bar mode="indeterminate" class="mt-4 progress-bar" color="#dcdde1" value="60"></mat-progress-bar>
    </div>
    <div *ngIf="!isPaymentSuccess" class="text-center">
        <p class="normal-font-16 width-110">Your payment has been successfully processed</p>
        <button class="btn-start mt-5 normal-font-16">Let’s Start</button>
    </div>
</div>