import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';

@Injectable()

export class TemplateService extends UserService {
  public currentFbaTemplate;
  public currentFbmTemplate;

  public changeTemplateStatus(templateId: any, sellerId: any): Observable<any> {
    return this.post('templates/save', {
      sellerId: sellerId,
      templateId: templateId
    });
  }

  public changeFbmTemplateStatus(templateId: any, sellerId: any): Observable<any> {
    return this.post('templates/save', {
      sellerId: sellerId,
      templateId: templateId
    });

  }

  public deleteTemplate(templateId: any, publicId: string): Observable<any> {
    return this.post('templates/delete', {
      publicId: publicId,
      templateId: templateId
    });
  }

  public copyTemplate(templateId: any, publicId: string): Observable<any> {
    return this.post('templates/copy_template', {
      publicId: publicId,
      templateId: templateId
    });
  }

  public saveTemplate(data: any): Observable<any> {
    return this.post('templates/save_info', data);
  }

  public getTemplateInfo(templateId: any, publicId: string): Observable<any> {
    return this.post('templates/template_info', {
      publicId: publicId,
      templateId: templateId
    });
  }

  public getTemplates(id: string): Observable<any> {
    return this.post('templates/get_templates', {
      publicId: id
    });
  }

  public checkProductsShortNames(publicId: string): Observable<any> {
    return this.post('templates/check_short_name', {
      publicId: publicId
    });
  }

  public getTemplatesWithRates(id: string): Observable<any> {
    return this.post('templates/get_templates_with_rates', {
      publicId: id
    });
  }

  public testTemplate(testData: any): Observable<any> {
    return this.post('templates/send_test', testData);
  }

  public getBulkTemplate(publicId: string): Observable<any> {
    return this.post('templates/get_bulks', {
      publicId: publicId
    });
  }

  public getBulkLimit(publicId: string): Observable<any> {
    return this.post('templates/emails_limit', {
      publicId: publicId
    });
  }

  public getFbmTemplates(id: string): Observable<any> {
    return this.post('templates/get_fbm_templates', {
      publicId: id
    });
  }
  public getFbmTemplatesWithRates(id: string): Observable<any> {
    return this.post('templates/get_fbm_templates_with_rates', {
      publicId: id
    });
  }

  public copyFBMTemplate(templateId: any, publicId: string): Observable<any> {
    return this.post('templates/copy_fbm_template', {
      publicId: publicId,
      templateId: templateId
    });
  }

  public deleteFBMTemplate(templateId: any, publicId: string): Observable<any> {
    return this.post('templates/delete_fbm', {
      publicId: publicId,
      templateId: templateId
    });
  }

  public saveFBMTemplate(data: any): Observable<any> {
    return this.post('templates/save_fbm_info', data);
  }

  public saveBulkTemplate(data: any): Observable<any> {
    return this.post('templates/save_bulks', data);
  }

  public deleteBulkTemplate(publicId: string, bulkId: any): Observable<any> {
    return this.post('templates/delete_bulks', {
      publicId: publicId,
      bulkId: bulkId,
      isDelete: true
    });
  }

  public getBulkInfo(publicId: string, bulkId: any): Observable<any> {
    return this.post('templates/get_bulk_info', {
      publicId: publicId,
      bulkId: bulkId,
    });
  }

  public getTemplateProduct(publicId: string, templateId: any): Observable<any> {
    return this.post('templates/get_template_product', {
      publicId: publicId,
      templateId: templateId,
    });
  }

  public getBulkTemplates(publicId: string): Observable<any> {
    return this.post('templates/get_bulk_templates', {
      publicId: publicId
    });
  }

  public getTemplatesName(publicId: string): Observable<any> {
    return this.post('templates/get_templates_name', {
      publicId: publicId
    });
  }

  public getAllTemplatesName(publicid: string): Observable<any> {
    return this.post('templates/get_all_templates_name', {
      publicId: publicid
    });
  }

  public getEstimatedEmails(data: any): Observable<any> {
    return this.post('templates/get_estimated_emails', data);
  }

  public exportTemplateFBM(publicId: string, isSuperUser?: any): Observable<any> {
    return this.post('templates/export_fbm_template', {
      publicId: publicId,
      isSuperUser: isSuperUser
    });
  }

  public exportTemplate(publicId: string, isSuperUser?: any): Observable<any> {
    return this.post('templates/export_template', {
      publicId: publicId,
      isSuperUser: isSuperUser
    });
  }

  public updateFbmStatus(publicId: string): Observable<any> {
    return this.post('templates/update_selling_fbm_status', {
      publicId: publicId
    });
  }

  public getLastEmails(data: any): Observable<any> {
    return this.post('templates/last_emails', data);
  }

  public resetOpenRate(publicId: string, templateId): Observable<any> {
    return this.post('templates/reset_open_rate', {
      publicId: publicId,
      templateId: templateId,
    });
  }

  public resetMainOpenRate(publicId: string, templateId): Observable<any> {
    return this.post('templates/reset_main_open_rate', {
      publicId: publicId,
      templateId: templateId,
    });
  }

//   this.userService.post('templates/reset_open_rate', {
//   publicId: "50",
//   templateId: 5,
// }).subscribe((data) => {
//   console.log(data)
// });


}
