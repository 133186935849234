<div class="main-dialog-container">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog sequence">
    <div class="dialog-content"  name="form" [formGroup]="submitForm" novalidate>
      <div class="header">
        <h2>{{textService.currentText.createNewSequence}}</h2>
      </div>
      <div class="dialog-row status">
        <span class="status-text">{{textService.currentText.status}}:</span>
        <mat-radio-group  formControlName="status" (change)="sequenceData.funnelStatus = !sequenceData.funnelStatus">
          <mat-radio-button disableRipple [value]="true">Active</mat-radio-button>
          <mat-radio-button disableRipple [value]="false">Inactive</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.name">
        <div class="label-name">
          <span>{{textService.currentText.sequenceName}}</span>
        </div>
        <input matInput
               #sequenceName1
               formControlName="name"
               name="name"
               type="text">
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.trigger">
        <div class="label-name">
          <span>{{textService.currentText.sequenceTrigger}}</span>
        </div>
        <div class="select-holder custom-selects">
          <div (click)="functions.setDropDownPosition(allProductsSelect, 'trigger')"
               #allProductsSelect
               *ngIf="!errors?.trigger"
               [class.active]="triggerBy.panelOpen"
               class="select-wrapper">
            <mat-form-field>
              <mat-select
                shouldPlaceholderFloat="false"
                panelClass="default-dropdown trigger"
                formControlName="trigger"
                #triggerBy
                disableRipple
                name="sequencTrigger">
                <mat-option *ngFor="let select of constService.sequenceTrigger" [value]="select.value">
                  {{ select.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <input matInput
                 *ngIf="errors?.trigger"
                 type="text"
                 name="subject"
                 [value]="textService.currentText.errorRequired"
          >
        </div>
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.tag" *ngIf="submitForm.controls['trigger'].value === 'Tagged'">
        <div class="label-name">
          <span>{{textService.currentText.tag}}</span>
        </div>
        <div class="select-holder custom-selects">
          <div (click)="functions.setDropDownPosition(seqTag, 'tag')"
               #seqTag
               *ngIf="!errors?.tag"
               [class.active]="tagged.panelOpen"
               class="select-wrapper">
            <mat-form-field>
              <mat-select
                shouldPlaceholderFloat="false"
                panelClass="default-dropdown tag"
                formControlName="tag"
                #tagged
                disableRipple
                name="tagged">
                <mat-option *ngFor="let select of userService.tags" [value]="select">
                  {{ select.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <input matInput
                 *ngIf="errors?.tag"
                 type="text"
                 name="subject"
                 [value]="textService.currentText.errorRequired">
        </div>
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.page">
        <div class="label-name">
          <span>{{textService.currentText.page}}</span>
        </div>
        <div class="select-holder custom-selects">
          <div (click)="functions.setDropDownPosition(pageFrame, 'pages')" #pageFrame
               [class.active]="pageId.panelOpen"
               *ngIf="!errors?.page"
               class="select-wrapper">
            <mat-form-field>
              <mat-select shouldPlaceholderFloat="false"
                          panelClass="default-dropdown pages"
                          formControlName="page"
                          disableRipple
                          #pageId
                          name="pageName">
                <mat-option *ngFor="let select of pages" [value]="select">
                  {{ select.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <input matInput
                 *ngIf="errors?.page"
                 type="text"
                 name="subject"
                 [value]="textService.currentText.errorRequired">
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer space-between">
    <button mat-button class="primary-btn white" (click)="dialogRef.close(false)">{{textService.currentText.cancel}}
    </button>
    <button mat-button class="primary-btn" (click)="saveSequence()">{{textService.currentText.create}}</button>
  </div>
</div>

