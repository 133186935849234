import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss']
})
export class AddressDialogComponent implements OnInit {
  displayedColumns: string[] = ['Shipping Address 1', 'Shipping Address 2', 'City', 'Shipping State', 'Shipping Country', 'Zip Code'];
  dataSource: MatTableDataSource<any>;
  title: string;
  totalRes: any = [];
  totalVal: any = 0;
  totalPrevVal: any = 0;
  constructor(public dialogRef: MatDialogRef<AddressDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.data.order;

  }

  closePopup() {
    this.dialogRef.close();
  }

}
