import {Component, Input, Output, OnInit} from '@angular/core';
import {UserService} from '../../../../../services/user.service';
import {TextService} from '../../../../../services/text.service';

@Component({
  selector: 'ninja-bot-menu-preview',
  templateUrl: './bot-menu-preview.component.html',
  styleUrls: ['./bot-menu-preview.component.scss']
})

export class BotMenuPreviewComponent implements OnInit {
  @Input() menu: any;
  public currentMenu: any;
  public showBackButton: any;

  constructor(public textService: TextService) {
  }

  public ngOnInit() {
    this.currentMenu = this.menu;
  }

  public checkLevel(menu, i) {
    if (menu[i].menu2level) {
      this.currentMenu = menu[i].menu2level;
      this.currentMenu.title = menu[i].name;
      this.showBackButton = true;
    }
    if (menu[i].menu3level) {
      this.currentMenu = menu[i].menu3level;
      this.currentMenu.title = menu[i].name;
      this.showBackButton = true;
    } else {
      return false;
    }
  }

  public goBack() {
    for (let i = 0; this.menu.length > i; i++) {
      if (JSON.stringify(this.menu[i]) === JSON.stringify(this.currentMenu)) {
        this.currentMenu = this.menu;
        this.showBackButton = false;
      } else {
        if (this.menu[i].menu2level) {
          for (let j = 0; this.menu[i].menu2level.length > j; j++) {
            if (JSON.stringify(this.menu[i].menu2level) === JSON.stringify(this.currentMenu)) {
              this.currentMenu = this.menu;
              this.showBackButton = false;
            } else {
              for (let k = 0; this.menu[i].menu2level.length > k; k++) {
                if (JSON.stringify(this.menu[i].menu2level[j].menu3level) === JSON.stringify(this.currentMenu)) {
                  this.currentMenu = this.menu[i].menu2level;
                }
              }
            }
          }
        }
      }
    }
  }
}
