<div class="main-dialog-container" *ngIf="data.dialogFor === 'auto-money-back'">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.auto}}</h2>
      </div>
      <div class="dialog-row status">
        <mat-radio-group [(ngModel)]="status">
          <mat-radio-button disableRipple [value]="true">{{textService.currentText.active}}</mat-radio-button>
          <mat-radio-button disableRipple [value]="false">{{textService.currentText.inactive}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <ng-container name="form" [formGroup]="submitForm" novalidate>
        <div class="dialog-row" [class.has-danger]="errors.email">
          <div class="label-name">
            <span>{{textService.currentText.emailAddress}}</span>
          </div>
          <input matInput
                 #email
                 type="text"
                 formControlName="email">
        </div>
        <div class="dialog-row" [class.has-danger]="errors.days">
          <div class="label-name">
            <span>{{textService.currentText.mbDaysBtwRequests}}</span>
          </div>
          <input matInput
                 #days
                 [type]="dayInputType"
                 formControlName="days">
        </div>
        <div class="dialog-row no-margin" [class.has-danger]="errors.requests">
          <div class="label-name">
            <span>{{textService.currentText.mbNumberOfRequests}}</span>
          </div>
          <input matInput
                 #requests
                 [type]="requestInputType"
                 formControlName="requests">
        </div>
        <div class="txt">
          <img src="../../../assets/icons/info-icon.svg">
          <span>{{textService.currentText.mbOnSingleEmial}}</span>
        </div>
        <ng-container *ngIf="!data.adjustments">
          <div class="dialog-row no-margin" [class.has-danger]="errors.moneyback">
            <div class="label-name">
              <span>{{textService.currentText.mbMoneyBackGuarantee}}</span>
            </div>
            <input matInput
                   #moneyback
                   [type]="moneybackInputType"
                   formControlName="moneyback">
          </div>
          <div class="txt">
            <img src="../../../assets/icons/info-icon.svg">
            <span>
              {{textService.currentText.mbGuaranteeText}}
            </span>
          </div>
        </ng-container>
        <div class="advanced">
          <div class="txt">
            <span>{{textService.currentText.advOptions}}</span>
          </div>
        </div>
      </ng-container>
      <div class="dialog-row datepicker custom-selects borderless-input">
        <mat-checkbox [(ngModel)]="before"
                      (click)="clearBeforeDate()"
                      disableRipple>
          {{textService.currentText.mbOnlyAdjBefore}}
        </mat-checkbox>
        <div class="small-date">
          <div class="label-name">
            <span>Date</span>
          </div>
          <mat-form-field>
            <input
                [disabled]="!before"
                matInput
                [placeholder]="setPlaceholder(before)"
                [matDatepicker]="picker"
                name="dateFrom"
                [(ngModel)]="beforeDate"
                (click)="picker.open()"
                (keydown)="$event.preventDefault()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="dialog-row datepicker custom-selects borderless-input no-margin">
        <mat-checkbox [(ngModel)]="after"
                      (change)="clearAfterDate()"
                      disableRipple>
          {{textService.currentText.mbOnlyAdjBefore}}
        </mat-checkbox>
        <div class="small-date">
          <div class="label-name">
            <span>{{textService.currentText.date}}</span>
          </div>
          <mat-form-field>
            <input matInput
                   [(ngModel)]="afterDate"
                   [disabled]="!after"
                   [placeholder]="setPlaceholder(after)"
                   [matDatepicker]="picker2"
                   (click)="picker2.open()"
                   (keydown)="$event.preventDefault()">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer space-between">
    <button mat-button class="primary-btn white" (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn" (click)="check()">{{textService.currentText.save}}</button>
  </div>
</div>
