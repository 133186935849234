import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { TokenService } from './services/token.service';
import { GoogleAnalyticsEventsService } from './services/google-analytics.service';
import { ConstService } from './services/const-service';
import { MatDialog } from '@angular/material/dialog';
import { TextService } from './services/text.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public userService: UserService,
    public tokenService: TokenService,
    public textService: TextService,
    public matDialog: MatDialog,
    public router: Router,
    public constService: ConstService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsEventsService.emitEvent('navigateTo', event.urlAfterRedirects, event.urlAfterRedirects);
        this.googleAnalyticsEventsService.emitEvent('navigateTo', event.urlAfterRedirects);
        if (event.urlAfterRedirects == "/registration")
          this.googleAnalyticsEventsService.emitPageView(null, "https://www.quantifyninja.com/registration");
        else
          this.googleAnalyticsEventsService.emitPageView(event.urlAfterRedirects);
      }
    });
  }

  public title = 'app';

  public ngOnInit(): void {
    this.userService.currentUser = this.tokenService.getToken();
    this.textService.currentText = this.textService.textEN;
    this.textService.setLangBadge('EN');
  }
}
