import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currency' })
export class CustomCurrencyPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'USD':
                return '$';
            case 'AUD':
                return '$';
            case 'CAD':
                return '$';
            case 'MXN':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            case 'JPY':
                return '¥';
            case 'INR':
                return '₹';
            default:
                return '';
        }
    }
}