import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ConstService } from '../../../services/const-service';
import { Functions } from '../../../services/functions';
import { SelectSpecificProductsDialogComponent } from '../../dialogs/select-specific-products-dialog/select-specific-products.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { NotificationsData } from '../../../models/BaseModels';
import { StorageBrowser } from '../../../services/storage.browser';
import { FbService } from '../../../services/fb.service';
import { NotificationService } from '../../../services/notification.service';
import { TextService } from '../../../services/text.service';
import { WhyDialogComponent } from '../../dialogs/why-dialog/why-dialog.component';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'ninja-facebook-notifications',
  templateUrl: './facebook-notifications.component.html',
  styleUrls: ['./facebook-notifications.component.scss']
})

export class FacebookNotificationsComponent implements OnInit {
  @ViewChild('allProducts', { static: false }) allProductsSelect;

  public userNotificationsData: NotificationsData;
  public isFBNotifyForSales: NotificationsData;
  public isTgNotifyForSales: NotificationsData;
  public filtersFb: any = {
    allProducts: '',
    time: 'Default',
    productspec: []
  };
  public filtersTg: any = {
    allProducts: '',
    tgtime: 'Default',
    productspec: []
  };
  public isAvaliable = false;
  public productsFb = [];
  public productsTg = [];
  public showProductTooltip: any;
  public showProductTooltip1: any;

  public ref: any;
  public tgRef: any;
  public isFacebookConnected: boolean;
  public isTelegramConnected: boolean;
  panelOpenState: boolean = false;

  constructor(public userService: UserService,
    public textService: TextService,
    public fbService: FbService,
    public notificationService: NotificationService,
    public constService: ConstService,
    public storageBrowser: StorageBrowser,
    public functions: Functions,
    public matDialog: MatDialog,
    public mixpanelService: MixpanelService,
  ) {
    this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    this.userService.loaderObesrver.next(true);
    this.notificationService.getNotificationsInfo(this.userService.currentUser.id).subscribe((data) => {
      this.ref = data.ref
      this.tgRef = data.tgRef
      this.userNotificationsData = data;
      this.filtersFb.time = data.numberOfSalesFbNotifications;
      this.filtersTg.tgtime = data.tgNumberOfSalesNotifications;
      if (this.userNotificationsData.notificationProducts) {
        for (let i = 0; i < this.userNotificationsData.notificationProducts.length; i++) {
          this.filtersFb.productspec.push({ sku: this.userNotificationsData.notificationProducts[i] });
        }
      }
      if (this.userNotificationsData.tgNotificationProducts) {
        for (let i = 0; i < this.userNotificationsData.tgNotificationProducts.length; i++) {
          this.filtersTg.productspec.push({ sku: this.userNotificationsData.tgNotificationProducts[i] });
        }
      }
      if (data.isAll) {
        this.filtersFb.allProducts = data.isAll;
      } else {
        this.filtersFb.placeholder = 'Specific' + ' (' + data.notificationProducts.length + ')';
      }

      if (data.tgIsAll) {
        this.filtersTg.allProducts = data.tgIsAll;
      } else {
        this.filtersTg.placeholder = 'Specific' + ' (' + data.tgNotificationProducts.length + ')';
      }

      if (data.hasFbAccess) {
        this.isFacebookConnected = data.hasFbAccess;
      }
      if (data.hasTgAccess) {
        this.isTelegramConnected = data.hasTgAccess;
      }
      this.userService.loaderObesrver.next(false);
    });
    this.isAvaliable = this.userService.currentUser.permissions.isReview;
  }

  public valueToggle(checker: boolean): string {
    return checker ? 'On' : 'Off';
  }

  public changeValueFb(): void {
    if (this.filtersFb.allProducts) {
      this.notificationService.changeNotificationProducts(this.userService.currentUser.id, this.filtersFb.allProducts, this.productsFb).subscribe();
      this.filtersFb.productspec = [];
    } else {
      this.matDialog.open(SelectSpecificProductsDialogComponent, {
        data: {
          'products': this.filtersFb.productspec
        }
      }).afterClosed().subscribe(result => {
        console.log("result1", result)

        if (result) {
          for (let i = 0; i < result.length; i++) {
            this.productsFb.push(result[i]['sku']);
          }
          this.notificationService.changeNotificationProducts(this.userService.currentUser.id,
            this.filtersFb.allProducts,
            this.productsFb).subscribe();
          this.filtersFb.productsFb = result;
          this.filtersFb.productspec = result;
          if (result.length > 0) {
            this.mixpanelService.track('q notifications - facebook products selected', { 'type': 'Specific'});
            this.filtersFb.allProducts = '';
            this.filtersFb.placeholder = 'Specific' + ' (' + this.filtersFb.productspec.length + ')';
          } else {
            this.filtersFb.allProducts = true;
            this.mixpanelService.track('q notifications - facebook products selected', { 'type': 'allProducts'});

          }
        } else {
          if (this.filtersFb.productspec.length > 0) {
            this.mixpanelService.track('q notifications - facebook products selected', { 'type': 'Specific'});
            this.filtersFb.allProducts = '';
            this.filtersFb.placeholder = 'Specific' + ' (' + this.filtersFb.productspec.length + ')';
          } else {
            this.filtersFb.allProducts = true;
            this.mixpanelService.track('q notifications - facebook products selected', { 'type': 'allProducts'});
          }
        }
      });
    }
  }

  public changeValueTg(): void {
    if (this.filtersTg.allProducts) {
      console.log("filtersTg", this.filtersTg.allProducts)
      this.notificationService.changeNotificationProductsTg(this.userService.currentUser.id, this.filtersTg.allProducts, this.productsTg).subscribe();
      this.filtersTg.productspec = [];
    } else {
      this.matDialog.open(SelectSpecificProductsDialogComponent, {
        data: {
          'products': this.filtersTg.productspec
        }
      }).afterClosed().subscribe(result => {
        console.log("result", result)

        if (result) {
          for (let i = 0; i < result.length; i++) {
            this.productsTg.push(result[i]['sku']);
          }
          this.notificationService.changeNotificationProductsTg(this.userService.currentUser.id,
            this.filtersTg.allProducts,
            this.productsTg).subscribe();
          this.filtersTg.productsTg = result;
          this.filtersTg.productspec = result;
          if (result.length > 0) {
            this.mixpanelService.track('q notifications - tg products selected', { 'type': 'Specific'});
            this.filtersTg.allProducts = '';
            this.filtersTg.placeholder = 'Specific' + ' (' + this.filtersTg.productspec.length + ')';
          } else {
            this.filtersTg.allProducts = true;
          }
        } else {
          if (this.filtersTg.productspec.length > 0) {
            this.mixpanelService.track('q notifications - tg products selected', { 'type': 'Specific'});
            this.filtersTg.allProducts = '';
            this.filtersTg.placeholder = 'Specific' + ' (' + this.filtersTg.productspec.length + ')';
          } else {
            this.filtersTg.allProducts = true;
          }
        }
      });
    }
  }

  public showWhyDialog() {
    this.matDialog.open(WhyDialogComponent).afterClosed().subscribe(() => { });
  }

  public showFbInstruction(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'messenger-fbnotification',
        token: this.userNotificationsData.token,
        ref: this.ref
      },
    }).afterClosed().subscribe();
  }

  public showTgInstruction(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'messenger-tgnotification',
        token: this.userNotificationsData.token,
        ref: this.tgRef
      },
    }).afterClosed().subscribe();
  }


  public changeStatus(attribute: string, value: boolean): void {
    this.notificationService.changeNotificationStatus(this.userService.currentUser.id, attribute, value).subscribe();
  }

  public changeTgStatus(attribute: string, value: boolean): void {
    if (!this.isTelegramConnected && !value) {
      this.showTgInstruction();
    }
    this.userNotificationsData[attribute] = !this.userNotificationsData[attribute];
    this.notificationService.changeNotificationStatus(this.userService.currentUser.id, attribute, this.userNotificationsData[attribute]).subscribe();
  }

  public changeFbStatus(attribute: string, value: boolean): void {
    if (!this.isFacebookConnected && !value) {
      this.showFbInstruction();
    }
    this.userNotificationsData[attribute] = !this.userNotificationsData[attribute];
    this.notificationService.changeNotificationStatus(this.userService.currentUser.id, attribute, this.userNotificationsData[attribute]).subscribe();
  }

  public changeSalesNumberGap(value: boolean) {
    this.notificationService.changeSalesNumber(this.userService.currentUser.id, value).subscribe();
  }

  public changeSalesNumberGapTg(value: boolean) {
    this.notificationService.changeSalesNumberTg(this.userService.currentUser.id, value).subscribe();
  }

}
