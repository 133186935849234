import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConstService } from '../../../services/const-service';
import { Functions } from '../../../services/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { TokenService } from '../../../services/token.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { UpgradeBusinessDetailsComponent } from '../../dialogs/upgrade-business-details-dialog/upgrade-business-details-dialog.component';
import { GoogleAnalyticsEventsService } from '../../../services/google-analytics.service';
import { UpgradeService } from '../../../services/upgrade.service';
import { TextService } from '../../../services/text.service';
import { StorageBrowser } from '../../../services/storage.browser';
import { UpgradeDialogComponent } from '../../dialogs/upgrade-dialog/upgrade-dialog.component';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'ninja-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})

export class UpgradeComponent implements OnInit {
  public currentPlan: any;
  public isCustomPaymentAvaliable: boolean;
  public customDataInfo: any;
  public isCustomPaymentPending: boolean;
  public isChangeAccRequestPending: boolean
  public closeDialog: boolean;
  public costs: any;
  public isPopup: boolean;
  public isNotPopup: boolean;


  constructor(
    public constService: ConstService,
    public textService: TextService,
    public matDialog: MatDialog,
    public storageBrowser: StorageBrowser,
    public tokenService: TokenService,
    public userService: UserService,
    public upgradeService: UpgradeService,
    public activatedRoute: ActivatedRoute,
    public mixpanelService: MixpanelService,
    public router: Router,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public functions: Functions,
  ) {
    this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    this.upgradeService.getCustomPaymentData(this.userService.currentUser.id)
      .subscribe(res => {
        if (res.isSuccess === false) {
          this.isCustomPaymentAvaliable = false;
        } else {
          this.isCustomPaymentAvaliable = true;
          this.customDataInfo = res;
        }
      });

    this.upgradeService.getPlanCosts(this.userService.currentUser.id).subscribe((data) => {
      this.userService.loaderObesrver.next(false);
      this.currentPlan = data;
      if (this.currentPlan.popup === null) {
        this.isNotPopup = true;
      } else {
        this.isPopup = true;
      }

    });
    this.activatedRoute.queryParams.subscribe((data) => {
      if (this.router.url.split('/')[2].includes('upgrade')) {
        this.userService.loaderObesrver.next(true);
        this.currentPlan = undefined;
        this.upgradeService.getPlanCosts(this.userService.currentUser.id).subscribe((data) => {
          this.currentPlan = data;
          if (this.currentPlan.popup === null) {
            this.isNotPopup = true;
          } else {
            this.isPopup = true;
          }
          this.userService.loaderObesrver.next(false);
        });
      }
    });
  }

  public subscribePlan(plan): void {
    this.userService.loaderObesrver.next(true);
    this.userService.amazonTokenStatus().subscribe((res) => {
      this.userService.loaderObesrver.next(false);
      if (this.currentPlan.locale === "IL") {
        this.openBusinessDetailsDialog(plan);
      } else {
        this.router.navigate(['grant-access'], { queryParams: { paymentRedirect: true, plan: plan } });
      }
    });
  }

  public openBusinessDetailsDialog(plan) {
    this.matDialog.open(UpgradeBusinessDetailsComponent, {
      data: {
        'plan': plan
      },
    }).afterClosed().subscribe(data => {
      if (data.status) {
        this.router.navigate(['grant-access'], { queryParams: { paymentRedirect: true, plan: plan, name: data.name, number: data.number } });
      }
    });
  }

  openDialog() {
    const dialogRef = this.matDialog.open(UpgradeDialogComponent, {
      data: { extra: this.currentPlan.popup.extra, type: this.currentPlan?.popup.type }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("result", result)
      if (result === "MONTHLY_MASTER_NINJA") {
        this.subscribePlan(this.currentPlan.popup.option);
      } else {
        this.subscribePlan(this.currentPlan.onScreen.option);
      }
    });
  }



  public openCancelPlanDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'cancel-plan',
      },
    }).afterClosed().subscribe(response => {
      if (response) {
        this.userService.loaderObesrver.next(true);
        this.upgradeService.cancelPlan(this.userService.currentUser.id).subscribe(cancelInfo => {
          if (cancelInfo.isSuccess) {
            this.openCancelPlanSuccessDialog();
            this.userService.loaderObesrver.next(false);
          } else {
            this.openCancelPlanFailureDialog(cancelInfo.message);
            this.userService.loaderObesrver.next(false);
          }
        });
      }
    });
  }

  public openDowngradeDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'downgrade-confirm',
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.upgradeService.downgradePlan(this.userService.currentUser.id).subscribe(() => {
          this.openDowngradeSuccessDialog();
        });
      }
    });
  }

  public openDowngradeSuccessDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'downgrade-success',
      },
    }).afterClosed().subscribe();
  }

  public openCancelPlanSuccessDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'cancel-plan-success'
      },
    }).afterClosed().subscribe();
  }

  public openCancelPlanFailureDialog(message): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.fail,
        'message': message
      },
    }).afterClosed().subscribe();
  }

  public getChangeAccountPayPalUrl(): void {
    this.isChangeAccRequestPending = true;
    this.upgradeService.getChangeAccountUrl(this.userService.currentUser.id)
      .subscribe(r => {
        this.isChangeAccRequestPending = false;
        if (r.url) {
          this.storageBrowser.set('isAccountChange', 'true');
          window.location.replace(r.url);
        } else {
          this.matDialog.open(ConfirmationDialogComponent, {
            data: {
              'dialogFor': 'custom',
              message: this.textService.currentText.cannotChangePayPalAcc,
              title: this.textService.currentText.dialogWentWrong
            },
          }).afterClosed().subscribe();
        }
      }, error => {
        this.isChangeAccRequestPending = false;
      });
  }

  public getCustomPaymentUrl(): void {
    this.isCustomPaymentPending = true;
    this.upgradeService.getCustomPaymentUrl(this.userService.currentUser.id)
      .subscribe(r => {
        this.isCustomPaymentPending = false;
        if (r.url) {
          this.storageBrowser.set('isCustomPayment', 'true');
          window.location.replace(r.url);
        } else {
          this.matDialog.open(ConfirmationDialogComponent, {
            data: {
              'dialogFor': 'load-failure',
            },
          }).afterClosed().subscribe();
        }
      }, error => {
        this.isCustomPaymentPending = false;
      });
  }
}

