import {Component, ElementRef, Input, Output, EventEmitter, NgZone, OnChanges, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {DatePipe} from '@angular/common';
import {ConstService} from "../../../../services/const-service";

declare let Chart: any;

@Component({
    selector: 'ninja-asin-chart',
    templateUrl: './asin-chart.component.html',
    styleUrls: ['./asin-chart.component.scss']
})

export class AsinChartComponent implements OnInit, OnChanges {
    @Input() asin: any;
    @Input() chartName: any;
    @Output() isAvaliable: EventEmitter<any> = new EventEmitter();
    @Output() marketplace: EventEmitter<any> = new EventEmitter();
    public chartData: any;
    public canvas: any;
    public chart; any;


    constructor(public userService: UserService,
                private elRef: ElementRef,
                private ngZone: NgZone,
                private datePipe: DatePipe,
                public constService: ConstService) {
    }

    public ngOnInit() {
        this.getAsinChartData();
    }

    public ngOnChanges() {
        if (this.chartData) {
            // this.buildChart(this.chartData);
        }

    }

    public getAsinChartData() {
        this.userService.get(`asin/chart_data?id=${this.userService.currentUser.id}&asin=${this.asin.asin}&keyword=${this.asin.keyword}&marketplace=${this.asin.marketPlace}`)
            .subscribe(asinChartData => {
                this.chartData = asinChartData;
                // this.setMarketplaceImage();
                if (this.chartData.length <= 3) {
                    this.isAvaliable.emit('No Data');
                } else {
                    this.buildChart();
                }
            });
    }

    private buildChart() {
        this.ngZone.runOutsideAngular(() => {
            this.canvas = document.createElement('canvas');
            this.elRef.nativeElement.appendChild(this.canvas);
            const spotData = this.chartData.map(c => c.spot);
            const pageData = this.chartData.map(c => c.page);
            const labels = this.chartData.map(c => {
                return this.datePipe.transform(c.lastChecked);
            });
            this.chart = new Chart(this.canvas, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [{
                        pointBorderWidth: 0,
                        backgroundColor: 'transparent',
                        borderColor: '#a762ff',
                        pointRadius: 2,
                        data: spotData,
                        pointHoverRadius: 5,
                        borderWidth: 2
                    }, {
                        pointBorderWidth: 0,
                        backgroundColor: '#transparent',
                        borderColor: '#ff9a17',
                        pointRadius: 2,
                        data: pageData,
                        pointHoverRadius: 5,
                        borderWidth: 2
                    }],
                },
                options: {
                    elements: {point: {radius: 1}},
                    responsive: true,
                    legend: false,
                    scales: {
                        xAxes: [{
                            scalelabel: {
                                display: true
                            },
                            gridLines: {
                                display: true,
                                drawBorder: true,
                            }
                        }
                        ],
                        yAxes: [{
                            scalelabel: {
                                display: true
                            },
                            gridLines: {
                                display: true,
                                drawBorder: true,
                            }
                        }
                        ]
                    },
                    hover: {
                        mode: 'nearest',
                        intersect: true,
                    }
                }
            });
        });
    }

    private setMarketplaceImage() {
        this.marketplace.emit(this.constService.countries.filter(item => item.value === this.asin.marketPlace)[0].image);
    }

}
