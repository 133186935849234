import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ExpenceDialogComponent } from "../../../dialogs/expence-dialog/expence-dialog.component";
import { AccountSettings } from "../../../../services/account-settings";
import { UserService } from "../../../../services/user.service";
import { TextService } from "../../../../services/text.service";
import { Functions } from "../../../../services/functions";
import * as moment from "moment";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { DaterangepickerComponent } from "ngx-daterangepicker-material";
import { FormControl } from "@angular/forms";
import _ from "lodash";

@Component({
  selector: "ninja-expenses",
  templateUrl: "./expenses.component.html",
  styleUrls: ["./expenses.component.scss"],
})
export class ExpensesComponent implements OnInit, OnDestroy {
  public totalAmount: any = 0;
  public defaultStartDate: any;
  public defaultEndDate: any;
  public expences: any;
  public oldData: any;
  public showNotification = false;
  public notificationMessage: string;
  public notificationTitle: string;
  public selectedVal: string;
  @ViewChild("customDate", { static: true }) customDate: MatMenuTrigger;
  @Input() dashboardSchedulesFilter: any;
  public customDateType = [
    "TODAY",
    "YESTERDAY",
    "LAST_7_DAYS",
    "LAST_30_DAYS",
    "MONTH_TO_DATE",
    "LAST_MONTH",
    "YEAR_TO_DATE",
    "LAST_YEAR",
    "CUSTOM",
  ];
  public customDateSelection = "LAST_7_DAYS";
  public dateSelection: any;
  public startDate: any = moment();
  public ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "Month To Date": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Year To Date": [moment().startOf("year"), moment()],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };
  @ViewChild(DaterangepickerComponent, { static: true })
  pickerInline: DaterangepickerComponent;
  alwaysShowCalendars: boolean;
  showCustomRangeLabel: boolean;
  groupby = new FormControl();

  constructor(
    public el: ElementRef,
    public functions: Functions,
    public accountSettings: AccountSettings,
    public textService: TextService,
    public userService: UserService,
    public matDialog: MatDialog,
    public mixpanelService: MixpanelService
  ) {
    this.mixpanelService.init(null);
    this.alwaysShowCalendars = true;
    this.showCustomRangeLabel = true;
  }

  public ngOnInit(): void {
    this.selectedVal = "Last 7 Days";
    this.customDateSelection = "Last 7 Days";
    this.pickerInline.chosenRange = "Last 7 Days";
    this.pickerInline.setStartDate(moment().subtract(7, "days"));
    this.pickerInline.setEndDate(moment());
    // this.defaultStartDate = moment(Date.now() - 7 * 24 * 60 * 60 * 1000).format("YYYY-MM-DD");
    // this.defaultEndDate = moment(Date.now()).format("YYYY-MM-DD");
    this.defaultStartDate = moment(Date.now() - 7 * 24 * 60 * 60 * 1000).format(
      "DD/MM/YYYY"
    );
    this.defaultEndDate = moment().format("DD/MM/YYYY");
    let fromDate, toDate;
    toDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
    fromDate =
      moment(Date.now() - 7 * 24 * 60 * 60 * 1000).format("YYYY-MM-DD") +
      "T00:00:00.000Z";
    setTimeout(() => {
      this.userService.loaderObesrver.next(true);
      this.accountSettings.currentStep = 3;
      this.accountSettings.settingsTitle = this.textService.currentText.expenseTitle;
      this.accountSettings.settingsInfo = this.textService.currentText.expenseSubTitle;
      this.textService.changeLoaderObserver().subscribe(() => {
        this.accountSettings.settingsTitle = this.textService.currentText.expenseTitle;
        this.accountSettings.settingsInfo = this.textService.currentText.expenseSubTitle;
      });

      this.accountSettings
        .getExpensesSettings(this.userService.currentUser.id, fromDate, toDate)
        .subscribe((data) => {
          this.expences = data.list;
          this.oldData = data.list;
          const nullImageData = this.expences.filter((data) => data.img == "");
          this.expences = this.expences.filter((data) => data.img != "");
          this.expences = this.expences.concat(nullImageData);
          this.expences.map((item) => {
            if (item.amount !== undefined) {
              this.totalAmount += item.amount ? item.amount : 0;
            }
          });
          this.userService.loaderObesrver.next(false);
        });
    });
  }

  choosedDate(event) {
    let evenetDate = "";
    if (event.label) {
      evenetDate = event.label.toUpperCase().replace(/-|\s/g, "_");
    } else {
      evenetDate = event.chosenLabel.toUpperCase().replace(/-|\s/g, "_");
    }
    if (this.customDateType.indexOf(evenetDate) !== -1) {
      this.selectedVal = this.customDateSelection = evenetDate;
    } else {
      this.selectedVal = this.customDateSelection = "CUSTOM";
    }
    this.dateSelection = event;
    //this.customDate.closeMenu();
  }

  public ngOnDestroy(): void {
    this.userService.showNotification = false;
  }

  public addExpence(): void {
    this.matDialog
      .open(ExpenceDialogComponent, {
        data: {
          data: "expOpen",
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.accountSettings.createExpenses(result).subscribe((data) => {
            this.expences = data.list;
            this.notificationMessage = this.textService.currentText.expenseAdded;
            this.notificationTitle = this.textService.currentText.addExpense;
            this.userService.showNotification = true;
          });
        }
      });
  }

  public edit(id: number): void {
    this.expences[id].edit = true;
  }

  changeGroupbySelection(value) {
    this.expences = this.oldData;
    const nullImageData = this.expences.filter((data) => data.img == "");
    this.expences = this.expences.filter((data) => data.img != "");
    this.expences = this.expences.concat(nullImageData);
    if (value.includes("asin")) {
      let filterData = _.chain(this.oldData)
        .groupBy((p) => p.asin)
        .map((value: any, key) => ({
          img: value[0].img,
          asin: key,
          name: "Mixed",
          from: value.reduce(function (r: any, a: any) {
            return moment(r.from ? r.from : r).format("YYYY-MM-DD") <
              moment(a.from ? a.from : a).format("YYYY-MM-DD")
              ? r.from
                ? r.from
                : r
              : a.from
              ? a.from
              : a;
          }),
          to: value.reduce(function (t: any, d: any) {
            return moment(t.to ? t.to : t).format("YYYY-MM-DD") >
              moment(d.to ? d.to : d).format("YYYY-MM-DD")
              ? t.to
                ? t.to
                : t
              : d.to
              ? d.to
              : d;
          }),
          amount: value
            .map((item) => item.amount)
            .reduce((prev, curr) => prev + curr, 0),
        }))
        .value();
      this.expences = JSON.parse(JSON.stringify(filterData));
      const nullImageData = this.expences.filter((data) => data.img == "");
      this.expences = this.expences.filter((data) => data.img != "");
      this.expences = this.expences.concat(nullImageData);
    }
    if (value.includes("type")) {
      let filterData = _.chain(this.oldData)
        .groupBy((p: any) => p.name)
        .map((value: any, key: any) => ({
          asin: "Mixed",
          name: key,
          from: value.reduce(function (prev: any, curr: any) {
            return moment(prev.from ? prev.from : prev).format("YYYY-MM-DD") <
              moment(curr.from ? curr.from : curr).format("YYYY-MM-DD")
              ? prev.from
                ? prev.from
                : prev
              : curr.from
              ? curr.from
              : curr;
          }),
          to: value.reduce(function (prev: any, curr: any) {
            return moment(prev.to ? prev.to : prev).format("YYYY-MM-DD") >
              moment(curr.to ? curr.to : curr).format("YYYY-MM-DD")
              ? prev.to
                ? prev.to
                : prev
              : curr.to
              ? curr.to
              : curr;
          }),
          amount: value
            .map((item: any) => item.amount)
            .reduce((prev: any, curr: any) => prev + curr, 0),
        }))
        .value();
      this.expences = JSON.parse(JSON.stringify(filterData));
      const nullImageData = this.expences.filter((data) => data.img == "");
      this.expences = this.expences.filter((data) => data.img != "");
      this.expences = this.expences.concat(nullImageData);
    }
    if (value.includes("asin") && value.includes("type")) {
      let filterData = _.chain(this.oldData)
        .groupBy((p: any) => p.asin)
        .map((value: any) =>
          _.chain(value)
            .groupBy((t: any) => t.name)
            .map((fvalue: any, key: any) => ({
              asin: fvalue[0].asin,
              img: fvalue[0].img,
              name: key,
              from: fvalue.reduce(function (prev: any, curr: any) {
                return moment(prev.from ? prev.from : prev).format(
                  "YYYY-MM-DD"
                ) < moment(curr.from ? curr.from : curr).format("YYYY-MM-DD")
                  ? prev.from
                    ? prev.from
                    : prev
                  : curr.from
                  ? curr.from
                  : curr;
              }),
              to: fvalue.reduce(function (t: any, d: any) {
                return moment(t.to ? t.to : t).format("YYYY-MM-DD") >
                  moment(d.to ? d.to : d).format("YYYY-MM-DD")
                  ? t.to
                    ? t.to
                    : t
                  : d.to
                  ? d.to
                  : d;
              }),
              amount: fvalue
                .map((item: any) => item.amount)
                .reduce((prev: any, curr: any) => prev + curr, 0),
            }))
            .value()
        )
        .value();
      let expenses = [];
      filterData.forEach((item) => {
        expenses = expenses.concat(item);
      });
      this.expences = JSON.parse(JSON.stringify(expenses));
      const nullImageData = this.expences.filter((data) => data.img == "");
      this.expences = this.expences.filter((data) => data.img != "");
      this.expences = this.expences.concat(nullImageData);
    }
  }

  public save(id: number): void {
    const expenseData = {
      publicId: this.userService.currentUser.id,
      expensesId: this.expences[id].expensesid,
      from:
        this.expences[id].from.length > 0
          ? this.expences[id].from
          : `${this.expences[id].from.getFullYear()}-${
              this.expences[id].from.getMonth() + 1
            }-${this.expences[id].from.getDate()}`,
      to:
        this.expences[id].to.length > 0
          ? this.expences[id].to
          : `${this.expences[id].to.getFullYear()}-${
              this.expences[id].to.getMonth() + 1
            }-${this.expences[id].to.getDate()}`,
    };
    this.accountSettings.saveExpenses(expenseData).subscribe();
    this.expences[id].edit = false;
    this.notificationMessage = this.textService.currentText.expenseEdited;
    this.notificationTitle = this.textService.currentText.editExpense;
    this.userService.showNotification = true;
  }

  public cancel(id: number): void {
    this.expences[id].edit = false;
  }

  public deleteExpences(id: number): void {
    const expenseData = {
      publicId: this.userService.currentUser.id,
      expensesId: this.expences[id].expensesid,
    };
    this.accountSettings.deleteExpenses(expenseData).subscribe(() => {
      this.expences.splice(id, 1);

      this.notificationMessage = this.textService.currentText.expenseDeleted;
      this.notificationTitle = this.textService.currentText.deleteExpense;
      this.userService.showNotification = true;
    });
  }

  cancelPicker() {
    this.customDate.closeMenu();
  }

  onSubmit() {
    this.customDate.closeMenu();
    this.userService.loaderObesrver.next(true);
    let startDate, endDate;
    if (this.dateSelection) {
      if (this.dateSelection.label) {
        startDate =
          moment(this.dateSelection.dates[0]).format("YYYY-MM-DD") +
          "T00:00:00.000Z";
        endDate =
          moment(this.dateSelection.dates[1]).format("YYYY-MM-DD") +
          "T00:00:00.000Z";
        this.defaultStartDate = moment(startDate).format("DD/MM/YYYY");
        this.defaultEndDate = moment(endDate).format("DD/MM/YYYY");
      } else {
        startDate =
          moment(this.dateSelection.startDate).format("YYYY-MM-DD") +
          "T00:00:00.000Z";
        endDate =
          moment(this.dateSelection.endDate).format("YYYY-MM-DD") +
          "T00:00:00.000Z";
        this.defaultStartDate = moment(startDate).format("DD/MM/YYYY");
        this.defaultEndDate = moment(endDate).format("DD/MM/YYYY");
      }
      this.accountSettings
        .getExpensesSettings(
          this.userService.currentUser.id,
          startDate,
          endDate
        )
        .subscribe((data) => {
          this.totalAmount = 0;
          this.expences = data.list;
          this.oldData = data.list;
          const nullImageData = this.expences.filter((data) => data.img == "");
          this.expences = this.expences.filter((data) => data.img != "");
          this.expences = this.expences.concat(nullImageData);
          this.expences.map((item) => {
            if (item.amount !== undefined) {
              this.totalAmount += item.amount ? item.amount : 0;
            }
          });
          this.changeGroupbySelection(
            this.groupby.value ? this.groupby.value : ""
          );
          this.userService.loaderObesrver.next(false);
        });
    } else {
      startDate =
        moment(this.defaultStartDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate =
        moment(this.defaultEndDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      this.accountSettings
        .getExpensesSettings(
          this.userService.currentUser.id,
          startDate,
          endDate
        )
        .subscribe((data) => {
          this.totalAmount = 0;
          this.expences = data.list;
          this.oldData = data.list;
          const nullImageData = this.expences.filter((data) => data.img == "");
          this.expences = this.expences.filter((data) => data.img != "");
          this.expences = this.expences.concat(nullImageData);
          this.expences.map((item) => {
            if (item.amount !== undefined) {
              this.totalAmount += item.amount ? item.amount : 0;
            }
          });
          this.changeGroupbySelection(
            this.groupby.value ? this.groupby.value : ""
          );
          this.userService.loaderObesrver.next(false);
        });
    }
  }
}
