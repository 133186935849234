import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {TextService} from '../../../services/text.service';
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";

@Component({
  templateUrl: './managed-account-dialog.component.html',
  styleUrls: ['./managed-account-dialog.component.scss']
})
export class ManagedAccountDialogComponent {
  public emailInputType = 'text';
  public passwordInputType = 'password';
  public canCheck = false;
  public isError = false;
  public errors = {
    email: false,
    password: false
  };

  public accountForm = this.fb.group({
    email: ['',
      [Validators.required,
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]],
    password: ['',
      [Validators.required]],
  });

  constructor(public fb: FormBuilder,
              public matDialog: MatDialog,
              public userService: UserService,
              public textService: TextService,
              public dialogRef: MatDialogRef<ManagedAccountDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public checkValidator() {
    if (!this.canCheck) {
      return
    }
    this.accountForm.controls.email.valid ? this.errors.email = false : this.errors.email = true;
    this.accountForm.controls.password.valid ? this.errors.password = false : this.errors.password = true;
  }

  public showCustomPopup (msg) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.grantAccessTitle5,
        'message': msg
      }
    });
  }

  public saveNewAccount () {
    for (let i = 0; this.data.list.length > i; i++) {
      if (this.data.list[i].email === this.accountForm.value.email) {
        this.showCustomPopup(this.textService.currentText.allreadyLinked);
        return
      }
    }
    // this.canCheck = true;
    this.checkValidator();
    if (!this.accountForm.valid) {
      return
    }
    this.userService.addManagedAccount(this.accountForm.value.email, this.accountForm.value.password,
      this.userService.isSuperUser || false).subscribe(res => {
      if (res.errorCode === 3) {
        this.isError = true;
        return
      }
      if (res.errorCode === 2) {
        this.showCustomPopup(this.textService.currentText.cannotLinAcc);
        return
      }
      if (res.errorCode === 1) {
        this.showCustomPopup(this.textService.currentText.onlyPrimaryAcc);
        return
      }
      if (res.isSuccess) {
        this.dialogRef.close({email: this.accountForm.controls.email.value, sellerId: res.sellerId})
        return
      }
    })
  }
}
