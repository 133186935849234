import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TextService} from "../../../services/text.service";
import {ConstService} from "../../../services/const-service";

@Component({
  selector: 'ninja-add-region',
  templateUrl: './add-region.component.html',
  styleUrls: ['./add-region.component.scss']
})

export class AddRegionComponent implements OnInit {
  public links: any;
  public emailInvalid = false;
  public emailInputDisabled = false;
  public notificationMessage: any;
  public notificationTitle: any;
  public currentEmail: string;

  constructor(public userService: UserService,
              public textService: TextService,
              public constService: ConstService,
              public router: Router,
              public activatedRoute: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.userService.getGrantAccessUrls(this.userService.currentUser.id).subscribe((links) => {
      this.links = links;
      this.userService.verifyAcc = false;
    });
  }

  public ngAfterViewInit (): void {
    for (let i = 0; this.userService.currentUser.emails.length > i; i++) {
      if (this.userService.currentUser.emails) {
        this.userService.getTemporarySendEmail(this.userService.currentUser.emails[i].subid).subscribe(
          (e) => {
            if (this.constService.usRegion.indexOf(this.activatedRoute.snapshot.queryParams.id) !== -1) {
              (<HTMLInputElement>document.querySelector('.add-region-input')).value = e.sendEmail || '';
            } else
            if (this.constService.feRegion.indexOf(this.activatedRoute.snapshot.queryParams.id) !== -1) {
              (<HTMLInputElement>document.querySelector('.add-region-input')).value = e.sendEmail || '';
            } else
            // if (this.constService.jpRegion.indexOf(this.activatedRoute.snapshot.queryParams.id) !== -1) {
            //   (<HTMLInputElement>document.querySelector('.add-region-input')).value = e.sendEmail || '';
            // } else
            // if (this.constService.auRegion.indexOf(this.activatedRoute.snapshot.queryParams.id) !== -1) {
            //   (<HTMLInputElement>document.querySelector('.add-region-input')).value = e.sendEmail || '';
            // } else
            if (this.constService.euRegion.indexOf(this.activatedRoute.snapshot.queryParams.id) !== -1) {
              (<HTMLInputElement>document.querySelector('.add-region-input')).value = e.sendEmail || '';
            }
          }
        )
      }
    }
    // for (let i = 0; this.userService.currentUser.emails.length > i; i++) {
    //   if (this.userService.currentUser.emails[i].submarket === this.activatedRoute.snapshot.queryParams.id) {
    //     (<HTMLInputElement>document.querySelector('.add-region-input')).value = this.userService.currentUser.emails[i].subemail;
    //     this.emailInputDisabled = true;
    //   }
    // }
  }

  public canDeactivate(): boolean {
    return this.userService.verifyAcc;
  }

  public navigateToAmazon(link): void {
    window.location.href = link;
  }

  public checkInput(input, link) {
    let id = 0;
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!pattern.test(input.value)) {
      this.displayError(input)
    } else {
      for (let i = 0; this.userService.currentUser.emails.length > i; i++) {
        if (this.userService.currentUser.emails[i].submarket === this.activatedRoute.snapshot.queryParams.id) {
          id = this.userService.currentUser.emails[i].subid
        }
      }
      this.userService.temporarySendEmail(id, input.value).subscribe((res) => {
        if (res.isSuccess) {
          this.userService.showNotification = true;
          this.notificationTitle =  this.textService.currentText.success;
          this.notificationMessage = this.textService.currentText.temporarySendEmail
          this.navigateToAmazon(link)
        }
      })
    }
  }

  private displayError(input): void {
      this.emailInvalid = true;
      const old_value = input.value;
      input.value = 'Please Enter email ID';
      input.disable = true;
      input.blur();
      this.resetErrorState(old_value, input);
      return;
  }

  private resetErrorState(val: string, input: any): void {
    setTimeout(() => {
        input.value = val;
        this.emailInvalid = false;
        input.disable = false;
    }, 2000);
  }

}
