<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container super-user">
    <ng-container *ngIf="showUsers">
      <div class="custom-selects customize">
        <div class="head-container">
          <h2>{{textService.currentText.superLogInAS}}…</h2>
          <div class="right-side">
            <button mat-button class="default-btn active">
              {{textService.currentText.superLogInAS}}…
            </button>
            <button mat-button class="default-btn">{{textService.currentText.superCreateInvoice}}</button>
            <button mat-button class="default-btn">{{textService.currentText.superTab1}}</button>
            <button mat-button class="default-btn">{{textService.currentText.tab}}
            </button>
          </div>
        </div>
        <div class="users-row">
          <div class="super-row">
            <form class="super-user" *ngIf="showUsers" [disabled]="userList?.length === 0">
              <mat-form-field class="example-full-width">
                <input matInput
                       [placeholder]="textService.currentText.all"
                       [(ngModel)]="currentUser"
                       aria-label="State"
                       [matAutocomplete]="auto"
                       [formControl]="stateCtrl">
                <mat-autocomplete #auto="matAutocomplete"
                                  class="super-user"
                                  panelClass="default-dropdown"
                                  disableRipple>
                  <mat-option class="with-padding"
                              disableRipple
                              *ngFor="let name of filteredStates | async"
                              [value]="name.id">
                    <span>{{ name.login }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
            <button mat-button
                    mat-raised-button
                    class="default-btn go-btn"
                    [disabled]="noData"
                    (click)="loginAsUser()">GO
            </button>
          </div>
          <div class="bottom-btn">
            <button mat-button mat-raised-button class="primary-btn" (click)="getSuperUserList()">
              {{textService.currentText.superFillUsers}}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="dashboard-header customize" *ngIf="!showUsers">
      <h2>{{textService.currentText.superUser}}</h2>
      <div class="select-line">
        <div class="select-holder">
          <form name="form" [formGroup]="superUserForm" novalidate>
            <mat-form-field>
              <input matInput
                     formControlName="password"
                     [class.has-danger]="errors?.passwordInvalid"
                     [placeholder]="textService.currentText.password"
                     name="Password"
                     #pass
                     [type]="passwordInputType">
            </mat-form-field>
          </form>
        </div>
        <button mat-button mat-raised-button class="default-btn" (click)="loginSuperUser()">
          {{textService.currentText.go}}</button>
      </div>
    </div>
  </div>
</div>
