import { Component, OnInit, Inject } from '@angular/core';
import { TextService } from '../../../services/text.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { UpgradeBusinessDetailsComponent } from '../upgrade-business-details-dialog/upgrade-business-details-dialog.component';
import { MixpanelService } from 'src/app/services/mixpanel.service';
export interface DialogData {
  extra: string;
}
@Component({
  selector: 'app-upgrade-dialog',
  templateUrl: './upgrade-dialog.component.html',
  styleUrls: ['./upgrade-dialog.component.scss']
})
export class UpgradeDialogComponent implements OnInit {

  constructor(public textService: TextService,
    public userService: UserService,
    public router: Router,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<UpgradeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public mixpanelService: MixpanelService,
  ) {
    this.mixpanelService.init(null);
  }

  ngOnInit(): void {
  }

}
