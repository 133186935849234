<ul *ngFor="let menuOption of data" class="navigation-main">
  <li *ngIf="menuOption.type === 'VARIABLE_TEXT'"
      (click)="fbService.facebookNavigation.next(true); menuOption.active = true;
    fbService.facebookNavigation.next(false); $event.preventDefault(); $event.stopPropagation()"
      [class.active]="menuOption?.activatePreview">{{menuOption.value || 'Enter text'}}
  </li>
  <li *ngIf="menuOption.type === 'TXT'"
      (click)="fbService.facebookNavigation.next(true); menuOption.active = true;
    fbService.facebookNavigation.next(false); $event.preventDefault(); $event.stopPropagation()"
      [class.active]="menuOption?.activatePreview">{{menuOption.value || 'Enter text'}}
  </li>
  <li *ngIf="menuOption.type === 'template'"
      (click)="fbService.facebookNavigation.next(true); menuOption.active = true;
    fbService.facebookNavigation.next(false); $event.preventDefault(); $event.stopPropagation()"
      [class.active]="menuOption?.activatePreview">Template
  </li>
  <li *ngIf="menuOption.type === 'IMAGE'"
      (click)="fbService.facebookNavigation.next(true); menuOption.active = true;
    fbService.facebookNavigation.next(false); $event.preventDefault(); $event.stopPropagation()"
      [class.active]="menuOption?.activatePreview">Image
  </li>
  <li *ngIf="menuOption.type === 'QuickReply'" [class.active]="menuOption?.activatePreview">{{menuOption.value}}</li>
  <li *ngIf="menuOption.type === 'TEMPLATE_BUTTON'"
      (click)="menuOption.active = true;
    fbService.facebookNavigation.next(menuOption); $event.preventDefault(); $event.stopPropagation()"
      [class.active]="menuOption?.activatePreview">{{menuOption.value || 'Enter text'}}
  </li>
  <li *ngIf="menuOption.type === 'ACTIONABLE'"
      (click)="menuOption.active = true;
    fbService.facebookNavigation.next(menuOption); $event.preventDefault(); $event.stopPropagation()"
      [class.active]="menuOption?.activatePreview">{{menuOption.text || 'Enter text'}}
  </li>
  <ng-container *ngIf="menuOption.type === 'TXT' || 'IMAGE' || 'ACTIONABLE'">
    <ng-container *ngFor="let menu of menuOption.childs">
      <li [class.active]="menu?.activatePreview" (click)="
             fbService.facebookNavigation.next(true); menu.active = true;
    fbService.facebookNavigation.next(false); $event.preventDefault(); $event.stopPropagation()"
          [class.button-child]="menu.type === 'QuickReply' || 'TEMPLATE_BUTTON'">
        {{menu.type === 'QuickReply' ? menu.value : menu.name}}
        <ng-container *ngIf="menu">
          <ninja-conversation-bot-menu-navigation
              [data]="menu.childs"></ninja-conversation-bot-menu-navigation>
        </ng-container>
      </li>
    </ng-container>
  </ng-container>
</ul>
