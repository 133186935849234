<div class="dashboard one-screen keywords" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container">
    <div class="dashboard-header">
      <div class="head-container">
        <h2>{{textService.currentText.keywordTitle}}</h2>
      </div>
      <div class="sub-head-container">
        <p>
          {{textService.currentText.keywordSubTitle}}
        </p>
      </div>
    </div>
    <div class="keywords-block">
      <div class="column">
        <h4>{{textService.currentText.keywordDesired}}</h4>
        <p>{{textService.currentText.keywordNumber}}: <span>{{first.length}}</span></p>
        <mat-form-field>
          <textarea maxlength="5000"
                    matInput
                    rows="10"
                    [(ngModel)]="firstTextArea"
                    (keyup)="textOptimizer()">
          </textarea>
        </mat-form-field>
      </div>
      <div class="column">
        <h4>{{textService.currentText.keywordListing}}</h4>
        <p>{{textService.currentText.keywordNumber}}:
          <span>{{secondTextArea.length === 0 ? '0' : secondTextArea.split(' ').length}}</span></p>
        <mat-form-field>
          <textarea matInput rows="10"
                    [(ngModel)]="secondTextArea"
                    (keyup)="textOptimizer()">
          </textarea>
        </mat-form-field>
      </div>
      <div class="column">
        <h4>{{textService.currentText.result}}</h4>
        <p>{{textService.currentText.keywordNumber}}: <span>{{resultField.length}}</span></p>
        <mat-form-field>
          <textarea matInput rows="10" [value]="result"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
