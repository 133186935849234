import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';
import {ConfirmationDialogComponent} from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';

@Injectable()

export class UpgradeService extends UserService {

  public getPlansInfo(userId: any): Observable<any> {
    return this.post('update/get_plans_info', {
      userId: userId
    });
  }

  public getUpdateInfo(publicId: string): Observable<any> {
    return this.post('update/get_update_info', {
      publicId: publicId
    });
  }

  public downgradePlan(publicId: string): Observable<any> {
    return this.post('update/downgrade', {
      publicId: publicId
    });
  }

  public cancelPlan(publicId: string): Observable<any> {
    return this.post('update/unsubscribe', {
      publicId: publicId
    });
  }

  public getPlanCosts(publicId: string): Observable<any> {
    return this.post('update/get_plan_costs_new', {
      publicId: publicId
    });
  }

  public checkPayment(publicId: string, token: any, agreementToCancel?: any): Observable<any> {
    return this.post('update/check_payment', {
      publicId: publicId,
      token: token,
      agreementToCancel: agreementToCancel
    });
  }


  public updatePlan(publicId, plan: any, newRegion?: any, companyName?: any, registrationNumber?: any): Observable<any> {
    return this.post('update/update_plan', {
      publicId: publicId,
      Plan: plan,
      newRegion: newRegion,
      companyName: companyName,
      registrationNumber: registrationNumber
    });
  }

  public getChangeAccountUrl(publicId: string): Observable<any> {
    return this.get(`update/change_payment_url/${publicId}`);
  }

  public getCustomPaymentData(publicId: string): Observable<any> {
    return this.get(`update/custom_payment_data/${publicId}`);
  }

  public getCustomPaymentUrl(publicId: string): Observable<any> {
    return this.get(`update/custom_payment/${publicId}`);
  }

  public updateCustomPayment(publicId: string, token: string): Observable<any> {
    return this.post('update/custom_payment', {
      publicId: publicId,
      token: token,
    });
  }

  public changePaymentMethod(publicId: string, token: string): Observable<any> {
    return this.post('update/change_payment_method', {
      publicId: publicId,
      token: token,
    });
  }
}
