<mat-card class="todaysection best-and-worst">
  <!-- <mat-card-title class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <h4 class="text-gray-light mr-4 mb-0">BEST AND WORST</h4>
    </div>
  </mat-card-title> -->
  <mat-card-content>
    <div class="d-flex align-items-center px-4">
      <div class="max-width-5">
        <mat-icon class="text-green">thumb_up</mat-icon>
      </div>
      <div class="text-center max-width-30">
        <!-- <h5 class="text-green">Best Product</h5> -->
        <span><img class="align-self-center height-32 d-inline-block" *ngIf="bestAndWorstData" src={{bestAndWorstData.bestImage}}
            [matTooltip]="bestAndWorstData.bestName" /></span>
      </div>
      <div class="text-center max-width-30">
        <h6 class="line-height-24 profit-color-text mb-0">
          Units
          <span class="font-size-11 line-height-15"
            [ngClass]="bestAndWorstData && bestAndWorstData.bestUnits && bestAndWorstData.bestUnits.change > 0 ? 'text-green' : 'profit-red-color'"
            *ngIf="bestAndWorstData && bestAndWorstData.bestUnits && bestAndWorstData.bestUnits.change > 0">
            <mat-icon class="mat-icon-size mb-0 align-middle">
              {{bestAndWorstData && bestAndWorstData.bestUnits && bestAndWorstData.bestUnits.change > 0 ? 'trending_up' : 'trending_down'}}
            </mat-icon>
            {{bestAndWorstData.bestUnits.change | number}}%
          </span>
        </h6>
        <h3 class="header-user-color font-weight-bold mb-0"
          *ngIf="bestAndWorstData && bestAndWorstData.bestUnits && bestAndWorstData.bestUnits.value > 0">
          {{bestAndWorstData.bestUnits.value}}</h3>
      </div>
      <div class="text-center max-width-35">
        <h6 class="line-height-24 profit-color-text mb-0">
          Profit
          <span class="font-size-11 line-height-15"
            [ngClass]="bestAndWorstData && bestAndWorstData.bestRevenue && bestAndWorstData.bestRevenue.change > 0 ? 'text-green' : 'profit-red-color'"
            *ngIf="bestAndWorstData && bestAndWorstData.bestRevenue && bestAndWorstData.bestRevenue.change > 0">
            <mat-icon class="mat-icon-size mb-0 align-middle">
              {{bestAndWorstData && bestAndWorstData.bestRevenue && bestAndWorstData.bestRevenue.change > 0 ? 'trending_up' : 'trending_down'}}
            </mat-icon>
            {{bestAndWorstData.bestRevenue.change | number}}%
          </span>
        </h6>
        <h3 class="header-user-color font-weight-bold mb-0"
          *ngIf="bestAndWorstData && bestAndWorstData.bestRevenue && bestAndWorstData.bestRevenue.value > 0">
          {{bestAndWorstData.bestRevenue.value | currency: currencySymbol : 'symbol' : '1.0-0'}}</h3>
      </div>
    </div>
    <div class="d-flex mt-3 bestAndWorst align-items-center px-4">
      <div class="max-width-5">
        <mat-icon class="profit-red-color">thumb_down</mat-icon>
      </div>
      <div class="text-center max-width-30">
        <!-- <h5 class="profit-red-color">Worst Product</h5> -->
        <span><img class="align-self-center height-32 d-inline-block" *ngIf="bestAndWorstData" src={{bestAndWorstData.worstImage}}
            [matTooltip]="bestAndWorstData.worstName" /></span>
      </div>
      <div class="text-center max-width-30">
        <h6 class="line-height-24 profit-color-text mb-0">
          Units
          <span class="font-size-11 line-height-15"
            [ngClass]="bestAndWorstData && bestAndWorstData.worstUnits && bestAndWorstData.worstUnits.change > 0 ? 'text-green' : 'profit-red-color'"
            *ngIf="bestAndWorstData && bestAndWorstData.worstUnits && bestAndWorstData.worstUnits.change > 0">
            <mat-icon class="mat-icon-size mb-0 align-middle">
              {{bestAndWorstData && bestAndWorstData.worstUnits && bestAndWorstData.worstUnits.change > 0 ? 'trending_up' : 'trending_down'}}
            </mat-icon>
            {{bestAndWorstData.worstUnits.change | number}}%
          </span>
        </h6>
        <h3 class="header-user-color font-weight-bold mb-0"
          *ngIf="bestAndWorstData && bestAndWorstData.worstUnits && bestAndWorstData.worstUnits.value > 0">
          {{bestAndWorstData.worstUnits.value}}</h3>
      </div>
      <div class="text-center max-width-35">
        <h6 class="line-height-24 profit-color-text mb-0">
          Profit
          <span class="font-size-11 line-height-15"
            [ngClass]="bestAndWorstData && bestAndWorstData.worstRevenue && bestAndWorstData.worstRevenue.change > 0 ? 'text-green' : 'profit-red-color'"
            *ngIf="bestAndWorstData && bestAndWorstData.worstRevenue && bestAndWorstData.worstRevenue.change > 0">
            <mat-icon class="mat-icon-size mb-0 align-middle">
              {{bestAndWorstData && bestAndWorstData.worstRevenue && bestAndWorstData.worstRevenue.change > 0 ? 'trending_up' : 'trending_down'}}
            </mat-icon>
            {{bestAndWorstData.worstRevenue.change | number}}%
          </span>
        </h6>
        <h3 class="header-user-color font-weight-bold mb-0"
          *ngIf="bestAndWorstData && bestAndWorstData.worstRevenue && bestAndWorstData.worstRevenue.value > 0">
          {{bestAndWorstData.worstRevenue.value | currency: currencySymbol : 'symbol' : '1.0-0'}}</h3>
      </div>
    </div>
  </mat-card-content>
</mat-card>