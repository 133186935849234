<div class="d-flex justify-content-end mb-4">
  <div class="d-flex">
    <div class="d-flex align-items-end mr-5 cursor-pointer" [matMenuTriggerFor]="filter" style="color: rgba(0, 0, 0, 0.54)">
        Columns
      <mat-icon class="mat-icon-size mb-0 align-middle header-icon-color cursor-pointer">
        filter_alt</mat-icon>
    </div>
    <mat-menu #filter="matMenu">
      <div class="px-25 pt-10 pb-0 columnFilter" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <mat-checkbox [checked]="filters.selectAll" (change)="columnFilters($event.checked, 'selectAll')">Select All</mat-checkbox>
        <mat-checkbox [checked]="filters.title" (change)="columnFilters($event.checked, 'title')">Title</mat-checkbox>
        <mat-checkbox [checked]="filters.asin" (change)="columnFilters($event.checked, 'asin')">Asin</mat-checkbox>
        <mat-checkbox [checked]="filters.sku" (change)="columnFilters($event.checked, 'sku')">Sku</mat-checkbox>
        <mat-checkbox [checked]="filters.sales" (change)="columnFilters($event.checked, 'sales')">Sales</mat-checkbox>
        <mat-checkbox [checked]="filters.revenue" (change)="columnFilters($event.checked, 'revenue')">Revenue</mat-checkbox>
        <mat-checkbox [checked]="filters.costs" (change)="columnFilters($event.checked, 'costs')">Cost</mat-checkbox>
        <mat-checkbox [checked]="filters.profit" (change)="columnFilters($event.checked, 'profit')">Profit</mat-checkbox>
        <mat-checkbox [checked]="filters.margin" (change)="columnFilters($event.checked, 'margin')">Margin</mat-checkbox>
        <mat-checkbox [checked]="filters.tACoS" (change)="columnFilters($event.checked, 'tACoS')">TACOS</mat-checkbox>
        <mat-checkbox [checked]="filters.promos" (change)="columnFilters($event.checked, 'promos')">Promos</mat-checkbox>
        <mat-checkbox [checked]="filters.ppcUnits" (change)="columnFilters($event.checked, 'ppcUnits')">PPC Units</mat-checkbox>
        <mat-checkbox [checked]="filters.ppcExpenses" (change)="columnFilters($event.checked, 'ppcExpenses')">PPC Expenses</mat-checkbox>
        <mat-checkbox [checked]="filters.ppcPerUnit" (change)="columnFilters($event.checked, 'ppcPerUnit')">PPC Per Unit</mat-checkbox>
        <mat-checkbox [checked]="filters.roi" (change)="columnFilters($event.checked, 'roi')">Roi</mat-checkbox>
        <mat-checkbox [checked]="filters.stock" (change)="columnFilters($event.checked, 'stock')">Stock</mat-checkbox>
        <mat-checkbox [checked]="filters.detail" (change)="columnFilters($event.checked, 'detail')">Detail</mat-checkbox>
      </div>
    </mat-menu>
    <mat-form-field *ngIf="!variationView">
      <mat-label>Group By </mat-label>
      <mat-select [formControl]="groupby" multiple [disableOptionCentering]="true">
        <mat-option value="brand">Brand</mat-option>
        <mat-option value="mp">Market Place</mat-option>
      </mat-select>
    </mat-form-field>
    <button class="ml-4 pt-3" (click)="downloadPB()">
      <img title="Download" src="assets/icons/download.svg" />
  </button>
  </div>
</div>
<app-profitbreakdownwithoutgroup [columnFilters]="filters" [colspanCount]="tableColspanCount" [formData]="formData" *ngIf="selectedVal != 'brand' && selectedVal != 'mp' && selectedVal != 'brand,mp' && selectedVal != 'parentAsin'"
  [profitbreakdowndataSource]="profitbreakdowndataSource">
</app-profitbreakdownwithoutgroup>
<app-profitbreakdownonegroup [columnFilters]="filters" [colspanCount]="tableColspanCount" [formData]="formData" *ngIf="selectedVal == 'brand' || selectedVal == 'mp' || selectedVal == 'parentAsin' "
  [profitbreakdowndataSource]="profitbreakdowndataSource">
</app-profitbreakdownonegroup>
<app-profitbreakdowntwogroup [columnFilters]="filters" [colspanCount]="tableColspanCount" [formData]="formData" *ngIf="selectedVal == 'brand,mp'" [profitbreakdowndataSource]="profitbreakdowndataSource">
</app-profitbreakdowntwogroup>