<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-header">
    <div class="head-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-7">
            <h2>{{textService.currentText.linkAccounts}}</h2>
          </div>
          <div class="col-md-5 d-flex align-items-center justify-content-center justify-content-md-end mt-4 mt-md-0">
            <button mat-button mat-raised-button (click)="showAddAccountDialog()" class="secondary-btn">
              {{textService.currentText.addManagedAccount}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-container">
    <h3 class="font-size-18 mr-3">{{textService.currentText.linkAccounts}}</h3>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header" [class.full-width]="true">
            <div class="td">{{textService.currentText.accountEmail}}</div>
            <div class="td">{{textService.currentText.actions}}</div>
          </div>
        </div>
        <div class="table-holder table">
          <div class="tr" *ngFor="let va of vaList">
            <div class="td">
              {{va.email}}
            </div>
            <div class="td">
              <span class="action-el" (click)="deleteLinkConfirmation(va)">
                {{textService.currentText.removeAccount}}
              </span>
            </div>
          </div>
          <div class="tr not-found" *ngIf="!vaList?.length">
            <div class="td not-found">
              {{textService.currentText.noDataFound}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>