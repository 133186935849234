<div class="conversation-row" [class.findMe]="data?.active" *ngIf="data.type === 'TXT' || data.type === 'VARIABLE_TEXT'">
  <div class="conversation-item closeMenuDet" [class.active]="isActive" (click)="disableEl(fbService.conversation);
        data.active = true; showButtonMenu2 = false;
        enableBranch(fbService.conversation);
        $event.stopPropagation(); $event.preventDefault()">
    <img src="../../../../../assets/icons/bot-msg-text.svg" class="closeMenuDet">
    <input type="text" (focus)="data.value === textService.currentText.text ? data.value = '' : null"
      (focusout)="data.value === '' ? data.value = textService.currentText.text : null" (keyup)="checkDataType()" [(ngModel)]="data.value"
      [placeholder]="textService.currentText.enterText" maxlength="2000" [class.inputActive]="isActive" class="closeMenuDet" name="text">
    <div class="action closeMenuDet" (click)="showMessageMenu = !showMessageMenu">
    </div>
    <ninja-conversation-bot-menu-option [showMessageMenu]="showMessageMenu" [showMessageMainMenu]="true" (changeActionText)="actionText = $event"
      (emitTextCoupon)="saveCouponText($event);" (emitTextVariable)="saveVariableText($event)"
      (deleteAction)="deleteAction(data); data['delete'] = $event;">
    </ninja-conversation-bot-menu-option>
  </div>
  <div class="sub-row closeMenuDet" *ngIf="data.childs?.length > 0 && data.active">
    <ng-container *ngFor="let item of data.childs; let i = index">
      <ninja-conversation-bot-option-constructor (emitTextData)="emitTextDataRec(item, $event, i)" (setImgUrl)="setImgUrlRec(item, $event, i)"
        (setActionData)="setActionDataRec(item, $event, i)" (setActiveEmitter)="setActiveRec(item, $event, i)"
        (setTemplateData)="setTemplateDataRec(item, $event, i)" [isActive]="true" [data]="item"></ninja-conversation-bot-option-constructor>
    </ng-container>
  </div>
</div>
<div class="conversation-row" [class.findMe]="data?.active" *ngIf="data.type === 'QuickReply'" [class.active-button-text]="isActive">
  <div class="button-holder">
    <div class="tag-indicator relative" *ngIf="data.tagId" (mouseenter)="showTooltipIndicator = true" (mouseleave)="showTooltipIndicator = false">
      <img src="assets/icons/bot-tag-white.svg" alt="">
      <div class="help-tooltip" *ngIf="showTooltipIndicator">
        <p>Tag Name: {{data.tagName}}</p>
      </div>
    </div>
    <div class="conversation-item button" (click)="disableEl(fbService.conversation);
          data.active = true;
          enableBranch(fbService.conversation);
          setActiveButton(data);
          $event.stopPropagation(); $event.preventDefault()" [class.active-button-text]="data.active">
      <img class="button-img" [src]="data?.image?.length > 0 ? data?.image : 'assets/icons/bot-msg-btn.svg'">
      <input type="text" [class.inputActive]="isActive" name="button2" class="QuickReply" maxlength="20"
        (focus)="data.value === textService.currentText.button ? data.value = '' : null"
        (focusout)="data.value === '' ? data.value = textService.currentText.button : null" [(ngModel)]="data.value">
      <div class="action" [class.with-action]="data.tagName" (click)="fbService.isTemplateButton = false; showButtonMenu2 = !showButtonMenu2;"></div>
      <ninja-conversation-bot-menu-option [isTag]="data.tagId" [showButtonMenu]="showButtonMenu2" [showButtonMainMenu]="data.active"
        [savedTag]="{tagId: data.tagId, tagName: data.tagName}" (emitTagButton)="data.tagId = $event.tagId; data.tagName = $event.name;"
        (deleteAction)="data['delete'] = $event; deleteAction(data);" (emitUrlButton)="data.image = $event">
      </ninja-conversation-bot-menu-option>
    </div>
  </div>
  <div class="sub-col" *ngIf="data?.childs?.length > 0 && data.active">
    <ng-container *ngFor="let item of data.childs; let i = index">
      <ninja-conversation-bot-option-constructor (emitTextData)="emitTextDataRec(item, $event, i)" (setImgUrl)="setImgUrlRec(item, $event, i)"
        (setActionData)="setActionDataRec(item, $event, i)" (setActiveEmitter)="setActiveRec(item, $event, i)"
        (setTemplateData)="setTemplateDataRec(item, $event, i)" [isActive]="item.active" [data]="item"></ninja-conversation-bot-option-constructor>
    </ng-container>
  </div>
</div>
<div class="conversation-row closeMenuDet" [class.findMe]="data?.active" *ngIf="data.type === 'IMAGE'">
  <div class="conversation-item image closeMenuDet" [class.active]="isActive" (click)="disableEl(fbService.conversation);
       data.active = true;
        enableBranch(fbService.conversation)">
    <div class="action closeMenuDet" (click)="showImageMenu = !showImageMenu">
    </div>
    <ninja-conversation-bot-menu-option [showImageMenu]="showImageMenu" [class.inputActive]="isActive" [type]="type"
      (deleteAction)="deleteAction(data); data['delete'] = $event;"></ninja-conversation-bot-menu-option>
    <div class="image-holder closeMenuDet">
      <label class="file-label closeMenuDet" for="upload-image" *ngIf="!data?.value?.length" (click)="data.id = true; fileInput.click()">
        <img src="assets/icons/bot-image-white.svg" alt="" class="closeMenuDet">
      </label>
      <label class="file-label closeMenuDet uploadName" for="upload-image" *ngIf="!data?.value?.length" (click)="data.id = true; fileInput.click()">
        {{textService.currentText.uploadImage}}
      </label>
      <img [src]="data.value.length ? data.value : ''" alt="" class="closeMenuDet">
    </div>
    <div class="input-row closeMenuDet">
      <input class="upload-Input" #fileInput id="fileInput" type="file" name="file2" [attr.multiple]="null" (change)="imageFileLoader($event)"
        [accept]="constService.fileImageFormats">
      <img src="assets/icons/bot-image.svg" class="closeMenuDet" alt="">
      <input type="text closeMenuDet" class="closeMenuDet" [(ngModel)]="data.value" placeholder="http://">
    </div>
  </div>
  <div class="sub-row closeMenuDet" *ngIf="data.childs?.length > 0 && data.active">
    <ng-container *ngFor="let item of data.childs; let i = index">
      <ninja-conversation-bot-option-constructor (emitTextData)="emitTextDataRec(item, $event, i)" (setImgUrl)="setImgUrlRec(item, $event, i)"
        (setActionData)="setActionDataRec(item, $event, i)" (setActiveEmitter)="setActiveRec(item, $event, i)"
        (setTemplateData)="setTemplateDataRec(item, $event, i)" [isActive]="true" [data]="item"></ninja-conversation-bot-option-constructor>
    </ng-container>
  </div>
</div>
<div class="conversation-row closeMenuDet" [class.findMe]="data?.active" *ngIf="data.type === 'template'">
  <div class="conversation-item image closeMenuDet" [class.active]="isActive" (click)="disableEl(fbService.conversation);
       data.active = true;
        enableBranch(fbService.conversation)">
    <div class="action closeMenuDet" (click)="showTemplateMenu = !showTemplateMenu; closeTemplateTooltip(data.childs)">
    </div>

    <ninja-conversation-bot-menu-option [showTemplateMenu]="showTemplateMenu" [showButtonMenu]="false" [type]="type" [data]="data"
      [class.inputActive]="isActive" (addButton)="addButton($event)" (deleteAction)="deleteAction(data); data['delete'] = $event;">
    </ninja-conversation-bot-menu-option>
    <div class="image-holder closeMenuDet">
      <label class="file-label closeMenuDet" *ngIf="!data?.image?.length" (click)="data.id = true; fileInput3.click()">
        <img src="assets/icons/bot-image-white.svg" alt="" class="closeMenuDet">
        <!--<input type="file" class="upload-image" id="upload-image" name="file3">-->
      </label>
      <label class="file-label closeMenuDet uploadName" *ngIf="!data?.image?.length" (click)="data.id = true; fileInput3.click()">
        {{textService.currentText.uploadImage}}
        <!--<input type="file" class="upload-image" id="upload-image" name="file3">-->
      </label>
      <input class="upload-Input" #fileInput3 type="file" name="file" [attr.multiple]="null" (change)="imageFileLoader($event)"
        [accept]="constService.fileImageFormats">
      <img [src]="data.image && data.image.length > 0 ? data.image : ''" alt="" class="closeMenuDet">
    </div>
    <div class="sub-holder closeMenuDet">
      <div class="input-row closeMenuDet">
        <img src="assets/icons/bot-image.svg" alt="" class="closeMenuDet">
        <input type="text" class="closeMenuDet" [(ngModel)]="data.image" placeholder="http://">
      </div>
      <div class="input-row closeMenuDet">
        <img src="../../../../../assets/icons/bot-msg-text.svg" class="closeMenuDet">
        <input type="text" class="closeMenuDet" [(ngModel)]="data.templateTitle"
          (focus)="data.templateTitle === textService.currentText.title ? data.templateTitle = '' : null"
          (focusout)="data.templateTitle === '' ? data.templateTitle = textService.currentText.title : null">
      </div>
      <div class="input-row closeMenuDet">
        <img src="../../../../../assets/icons/bot-msg-text.svg" class="closeMenuDet">
        <input type="text" class="closeMenuDet" [(ngModel)]="data.templateSubTitle"
          (focus)="data.templateSubTitle === textService.currentText.subTitle ? data.templateSubTitle = '' : null"
          (focusout)="data.templateSubTitle === '' ? data.templateSubTitle = textService.currentText.subTitle : null">
      </div>
      <ng-container *ngFor="let el of data.childs; let i = index">
        <div class="input-row closeMenuDet" (click)="disableEl(fbService.conversation); activateTemplateButton(data.childs, i); enableBranch(fbService.conversation);
          $event.stopPropagation(); $event.preventDefault()" [class.activeTemplate]="el.active">
          <img src="/assets/icons/bot-image.svg" alt="" class="closeMenuDet">
          <input type="text" class="closeMenuDet" (focus)="el.name === textService.currentText.button ? el.name = '' : null"
            (focusout)="el.name === '' ? el.name = textService.currentText.button : null" [(ngModel)]="el.name">
          <div (click)="fbService.isTemplateButton = true; showTemplateMenu = false;
                          closeTemplateTooltip(data.childs, el); el.show = true" class="action template-button-action closeMenuDet"
            [class.with-action]="data?.childs[i]?.tagId || data?.childs[i]?.weburl"></div>
          <ninja-conversation-bot-menu-option [isTag]="data.childs[i].tagId" [isUrl]="data.childs[i].weburl" [showButtonMenu]="el.show"
            [isTemplateButton]="true" [disabledTemplateDeleteLastButton]="fbService.isTemplateButton && data?.childs.length === 1"
            [showButtonMainMenu]="true" [showTemplateMenu]="false" [data]="data" [buttonName]="'URL Link'" [elPosition]="i"
            (addButton)="addButton($event); el.active = false" [numOfELenents]="data.childs.length"
            [savedTag]="{tagId: data.childs[i].tagId, tagName: data.childs[i].tagName}"
            (emitTagButton)="data.childs[i].tagId = $event.tagId; data.childs[i].tagName = $event.name" (emitUrlButton)="el.weburl = $event"
            (deleteAction)="deleteAction(el); data['delete'] = $event;">
          </ninja-conversation-bot-menu-option>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="data.childs?.length > 0 && data.active">
    <ng-container *ngFor="let item of data.childs">
      <ng-container *ngIf="item.active">
        <ng-container *ngFor="let el of item.childs; let y = index">
          <ninja-conversation-bot-option-constructor (emitTextData)="emitTextDataRec(item, $event, y)" (setImgUrl)="setImgUrlRec(item, $event, y)"
            (setActionData)="setActionDataRec(item, $event, y);" (setActiveEmitter)="setActiveRec(item, $event, y)"
            (setTemplateData)="setTemplateDataRec(item, $event, y)" [isActive]="el.active" [data]="el"></ninja-conversation-bot-option-constructor>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="conversation-row closeMenuDet" [class.findMe]="data?.active" *ngIf="data.type === 'ACTIONABLE'">
  <div class="conversation-item action closeMenuDet" [class.active]="isActive" (click)="disableEl(fbService.conversation);
       setActiveEl(data);
       enableBranch(fbService.conversation)">
    <img src="assets/icons/bot-menu-sublevel.svg" class="closeMenuDet">
    <input class="action-text closeMenuDet" [class.inputActive]="isActive" [(ngModel)]="data.text" disabled>
    <div class="action closeMenuDet" (click)="showActionMenu = !showActionMenu">
    </div>
    <ninja-conversation-bot-menu-option [showActionMenu]="showActionMenu" [showActionMainMenu]="true" (changeActionText)="data.text = $event"
      (deleteAction)="deleteAction(data); data['delete'] = $event;" (emitTriggerAction)="emitAction($event)"></ninja-conversation-bot-menu-option>
  </div>
  <div class="sub-row closeMenuDet" *ngIf="data.childs?.length > 0 && data.active">
    <ng-container *ngFor="let item of data.childs; let i = index">
      <ninja-conversation-bot-option-constructor (emitTextData)="emitTextDataRec(item, $event, i)" (setImgUrl)="setImgUrlRec(item, $event, i)"
        (setActionData)="setActionDataRec(item, $event, i)" (setActiveEmitter)="setActiveRec(item, $event, i)"
        (setTemplateData)="setTemplateDataRec(item, $event, i)" [isActive]="true" [data]="item"></ninja-conversation-bot-option-constructor>
    </ng-container>
  </div>
</div>