import {Component} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {TermsDialogComponent} from '../dialogs/terms-condition-dialog/terms-dialog.component';

@Component({
  selector: 'ninja-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})

export class MainPageComponent {

  constructor(public matDialog: MatDialog) {}

  public login(): void {
    this.matDialog.open(TermsDialogComponent, {});
  }
}
