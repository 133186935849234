import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../../services/user.service';
import {CouponsService} from '../../../../../services/coupons.service';
import {Functions} from '../../../../../services/functions';
import {ConstService} from '../../../../../services/const-service';
import {FbService} from '../../../../../services/fb.service';
import {TextService} from '../../../../../services/text.service';
import {ConfirmationDialogComponent} from "../../../../dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'ninja-conversation-bot-menu-option',
  templateUrl: './conversation-bot-menu-option.component.html',
  styleUrls: ['./conversation-bot-menu-option.component.scss']
})

export class ConversationBotMenuOptionComponent implements OnInit, OnChanges {
  @Output() changeActionText: EventEmitter<any> = new EventEmitter();
  @Output() deleteAction: EventEmitter<any> = new EventEmitter();
  @Output() addButton: EventEmitter<any> = new EventEmitter();
  @Output() emitTriggerAction: EventEmitter<any> = new EventEmitter();
  @Output() emitTextVariable: EventEmitter<any> = new EventEmitter();
  @Output() emitTagButton: EventEmitter<any> = new EventEmitter();
  @Output() emitTextCoupon: EventEmitter<any> = new EventEmitter();
  @Output() emitUrlButton: EventEmitter<any> = new EventEmitter();
  @Input() showTemplateMenu: boolean;
  @Input() data: any;
  @Input() isTag: any;
  @Input() isUrl: any;
  @Input() buttonName: any;
  @Input() elPosition: any;
  @Input() numOfELenents: any;
  @Input() showImageMenu: boolean;
  @Input() showMessageMenu: boolean;
  @Input() showMessageMainMenu: boolean;
  @Input() showButtonMenu: boolean;
  @Input() showActionMainMenu = true;
  @Input() showActionMenu: boolean;
  @Input() showButtonMainMenu: boolean;
  @Input() isTemplateButton: boolean;
  @Input() disabledTemplateDeleteLastButton: boolean;
  @Input() savedTag: any;
  public showMessageVariableMenu: boolean;
  public showMessageCouponsMenu: boolean;
  public showButtonTagMenu: boolean;
  public showButtonNewTag: boolean;
  public showActionTriggerMenu: boolean;
  public showError: boolean;
  public showButtonIconMenu: boolean;
  public showActionDelayMenu: boolean;
  public showActionTypingMenu: boolean;
  public delayCounter = 0;
  public typingCounter = 0;
  public coupons: any;
  public coupon: any;
  public tag: any;
  public bottom: any;
  public variable: any;
  public triggerConversation: any;
  public conversationList: any;
  public tags: any;
  public width = 150;
  public y = 100;
  public variables = [{
    text: 'Buyer name'
  }, {text: 'Buyer first name'}];

  constructor(public matDialog: MatDialog,
              public constService: ConstService,
              public userService: UserService,
              public textService: TextService,
              public couponsService: CouponsService,
              public fbService: FbService,
              public router: Router,
              public activatedRoute: ActivatedRoute,
              public functions: Functions) {
  }

  @HostListener('window:click', ['$event'])
  public checkFullScreen(event: MouseEvent): void {
    for (let i = 0; this.constService.dropdownCls.length > i; i++) {
      if (event.target['classList'].contains(this.constService.dropdownCls[i]) || event.target['classList'].value === '') {
        return;
      }
    }
    if (this.search('menu-close-checker', event['path']) === -1 &&
        this.search('action', event['path']) === -1) {
      this.closeAll();
    }
  }

  public ngOnInit () {
    this.fbService.facebookCls().subscribe((data) => {
      this.closeAll();
      this.showButtonMenu = false;
    });
    this.showButtonMenu = false;
  }

  public ngOnChanges () {
    this.bottom = this.elPosition === 2 ? '20%' : '10%';
    this.bottom = this.elPosition === 0  ? '10%' : '10%';
    if (this.numOfELenents === this.elPosition + 1) {
      this.bottom = '0%'
    }
    if (this.numOfELenents === this.elPosition) {
      this.bottom = '10%'
    }
    if (this.elPosition === 0 && this.numOfELenents === 3) {
      this.bottom = '20%'
    }
  }

  public search(nameKey, myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].className.indexOf(nameKey) !== -1) {
        return myArray[i].className.indexOf(nameKey);
      }
      if (i === myArray.length - 5) {
        return myArray[i].className.indexOf(nameKey);
      }
    }
  }

  public actionDelete() {
    this.deleteAction.emit(true);
  }

  public closeAll() {
    this.showImageMenu = false;
    this.showTemplateMenu = false;
    // this.showActionMenu = false;
    // this.showMessageMenu = false;
    // this.showButtonMenu = false;
    this.closeActionOptions()
    this.closeMessageOption()
    this.closeButtonOption();
  }


  public showMessageOptions() {
    this.showMessageMenu = true;
    this.showMessageMainMenu = true;
    this.showMessageVariableMenu = false;
    this.showMessageCouponsMenu = false;
  }

  public saveTypingStatus() {
    this.emitTriggerAction.emit(
      {
        action: '',
        params: this.typingCounter * 1000,
        type: 'ACTIONABLE',
        childs: [],
        value: 'typingStatusActionTask',
        text: `Typing status (${this.typingCounter} sec)`,
        active: true
      });
  }

  public saveDelay() {
    this.emitTriggerAction.emit(
      {
        action: '',
        params: this.delayCounter * 1000,
        type: 'ACTIONABLE',
        childs: [],
        value: 'delayActionTask',
        text: `Delay (${this.delayCounter} sec)`,
        active: true
      });
  }

  public createTag(tagName): void {
    if (!tagName || tagName.length === 0) {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 1000)
      return
    }
    this.userService.loaderObesrver.next(true);
    this.fbService.createTag(this.userService.currentUser.id, tagName).subscribe(data => {
      this.tag = {tagId: data.tagId, name: tagName};
      this.userService.tags.push(this.tag);
      this.userService.loaderObesrver.next(false);
      this.showButtonNewTag = false;
      this.saveTag(this.tag);
      this.isTag = true;
    });
  }

  public deleteTag () {
    let tag = {tagid: undefined, name: undefined}
    this.emitTagButton.emit(tag);
  }

  public warningDialog (title, subTitle, subTitle2?) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'tag-webUrl-confiramation',
        title: title,
        subTitle: subTitle,
        subTitle2: subTitle2,
      },
    }).afterClosed().subscribe(res => {
      if (res) {
        if (this.isTag) {
          // this.showButtonTagMenu = true;
          this.deleteTag();
          this.isTag = false;
        }
        if (this.isUrl) {
          // this.showButtonTagMenu = true;
          this.emitUrlButton.emit(undefined)
        }
      } else {
        this.showButtonIconMenu = false;
        this.showButtonTagMenu = false;
        this.showButtonMainMenu = true;
      }
    });
  }

  public saveSubscribe() {
    this.emitTriggerAction.emit(
      {
        action: '',
        params: null,
        type: 'ACTIONABLE',
        childs: [],
        value: 'facebookSubscribeTask',
        text: `Subscribe`,
        active: true
      });
  }

  public saveUnsubscribe() {
    this.emitTriggerAction.emit(
      {
        action: '',
        params: null,
        type: 'ACTIONABLE',
        childs: [],
        value: 'facebookUnsubscribeTask',
        text: `Unsubscribe`,
        active: true
      });
  }


  public saveTriggerConversation() {
    this.emitTriggerAction.emit(
      {
        action: '',
        params: this.triggerConversation,
        type: 'ACTIONABLE',
        childs: [],
        value: 'triggerConversationActionTask',
        text: `Trigger conversation`,
        active: true
      });
  }

  public showCouponsOptions() {
    this.showMessageMenu = true;
    this.showMessageMainMenu = false;
    this.showMessageCouponsMenu = true;
  }

  public closeMessageOption() {
    this.showMessageMenu = false;
    this.showMessageMainMenu = true;
    this.showMessageVariableMenu = false;
    this.showMessageCouponsMenu = false;
  }

  public showVariableOptions() {
    this.couponsService.getCoupons(this.userService.currentUser.id).subscribe((data) => {
      this.coupons = data;
    });
    this.showMessageMainMenu = false;
    this.showMessageVariableMenu = true;
  }

  public showButtonOptions() {
    this.showButtonMenu = true;
    this.showButtonMainMenu = true;
    this.showButtonTagMenu = false;
    this.showButtonIconMenu = false;
  }

  public saveTag (tag) {
    this.emitTagButton.emit(tag)
  }

  public showTagMenu() {
    if (this.savedTag && this.savedTag.tagId && this.savedTag.tagName) {
      this.tag = this.savedTag;
    }
    if (this.isUrl) {
      this.warningDialog(this.textService.currentText.alreadyHaveAUrl, this.textService.currentText.warningRemoveUrl,
        this.textService.currentText.warningRemoveTag2)
    }
    this.showButtonTagMenu = true;
    this.showButtonMainMenu = false;
  }

  public showIconInsideMenu() {
    // if (this.isTag) {
    //   this.warningDialog(this.textService.currentText.alreadyHaveATag, this.textService.currentText.warningRemoveTag,
    //     this.textService.currentText.warningRemoveTag2)
    // }
    this.showButtonIconMenu = true;
    this.showButtonMainMenu = false;
  }

  public closeButtonOption() {
    this.tag = '';
    this.showButtonMenu = false;
    this.showButtonMainMenu = true;
    this.showButtonTagMenu = false;
    this.showButtonIconMenu = false;
  }

  public showImageOption() {
    this.showImageMenu = !this.showImageMenu;
  }

  public showActionTriggerOptions() {
    this.showActionTriggerMenu = true;
    this.showActionMainMenu = false;
  }

  public showActionDelayOptions() {
    this.showActionMainMenu = false;
    this.showActionTriggerMenu = false;
    this.showActionDelayMenu = true;
    this.showActionTypingMenu = false;
  }

  public showActionTypingOptions() {
    this.showActionMainMenu = false;
    this.showActionTriggerMenu = false;
    this.showActionDelayMenu = false;
    this.showActionTypingMenu = true;
  }

  public closeActionOptions() {
    this.showActionMenu = false;
    this.showActionMainMenu = true;
    this.showActionTriggerMenu = false;
    this.showActionDelayMenu = false;
    this.showActionTypingMenu = false;
  }
}
