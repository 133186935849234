<div class="access-wrapper">
  <div class="access-main-block">
    <div class="finish-checking" *ngIf="!isSuccess">
      <img src="assets/images/ninja-logo2.svg" alt="">
      <h2 class="mt-4">{{textService.currentText.grantAccessTitle5}}</h2>
      <div class="text">
        <p>
          {{textService.currentText.grantAccessText2}}
        </p>
        <p class="small-text">
          {{textService.currentText.grantAccessSellerID}} {{this.activatedRoute.snapshot.queryParams.selling_partner_id }}
        </p>
      </div>
      <button class="mat-button primary-btn" (click)="navigateToSignUp()">
        {{textService.currentText.gotIt}}</button>
    </div>
  </div>
</div>