import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {TextService} from '../../../services/text.service';
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";

@Component({
  templateUrl: './account-permissions-dialog.component.html',
  styleUrls: ['./account-permissions-dialog.component.scss']
})
export class AccountPermissionsDialogComponent implements OnInit, AfterViewInit {
  public grantedPermissions = [];
  public permissions = [];
  public errors: any = {};
  public userForm = this.fb.group({
    firstName: ['',
      [Validators.required]],
    lastName: ['',
      [Validators.required]],
    email: ['',
      [Validators.required,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]],
    password: ['',
      [Validators.required,
        Validators.minLength(6),
        Validators.pattern(/(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[a-zA-Z]).*$/)]],
  });
  public passwordInputType = 'password';

  constructor(public fb: FormBuilder,
              public matDialog: MatDialog,
              public userService: UserService,
              public textService: TextService,
              public dialogRef: MatDialogRef<AccountPermissionsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit(): void {
    this.userService.dialogLoader = true;
    this.userService.getFeatureList().subscribe(permissionList => {
      for (let prop in permissionList) {
        if (permissionList[prop]) {
          this.permissions.push(prop)
        }
      }
      if (this.data.va) {
        this.userForm.patchValue({
          firstName: this.data.va.name.split(' ')[0],
          lastName: this.data.va.name.split(' ')[1],
          email: this.data.va.email,
        });
        this.userForm.controls['firstName'].disable();
        this.userForm.controls['lastName'].disable();
        this.userForm.controls['email'].disable();
        this.userForm.controls['password'].disable();
        this.grantedPermissions = this.data.va.featureList;
        for (let i = 0; this.grantedPermissions.length > i; i++) {
          if (this.permissions.indexOf(this.grantedPermissions[i]) !== -1) {
            this.permissions.splice(this.permissions.indexOf(this.grantedPermissions[i]), 1)
          }
        }
      }
      this.userService.dialogLoader = false;
    });
  }

  public ngAfterViewInit () {
    setTimeout(() => {
      if (this.data.va) {
        this.userForm.patchValue({
          firstName: this.data.va.name.split(' ')[0],
          lastName: this.data.va.name.split(' ')[1],
          email: this.data.va.email,
          password: ''
        });
      }
    })
  }

  public showCustomPopup (msg) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.grantAccessTitle5,
        'message': msg
      }
    }).afterClosed().subscribe(() => {
      this.userService.dialogLoader = false;
    });
  }

  public addPermission(el) {
    this.permissions.splice(this.permissions.indexOf(el), 1);
    this.grantedPermissions.push(el)
  }

  public removePermission(el) {
    this.grantedPermissions.splice(this.grantedPermissions.indexOf(el), 1);
    this.permissions.push(el)
  }

  public closeDialog() {
    this.userService.dialogLoader = false;
    this.dialogRef.close(true)
  }

  public createNewVaUser() {
    let vaUserData = {
      publicId: this.userService.currentUser.id,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      email: this.userForm.value.email,
      pass: this.userForm.value.password,
      featureList: this.grantedPermissions,
    };
    this.userService.dialogLoader = true;
    if (this.data.va) {
      this.userService.editFeatureList(this.data.va.id, this.grantedPermissions).subscribe(() => {
        this.closeDialog();
      })
    } else {
      this.userService.createVaUser(vaUserData).subscribe((data) => {
        if (!data.isSuccess) {
          if (data.errorCode === 2) {
            this.showCustomPopup(this.textService.currentText.emailAlreadyIsUse);
          }
          if (data.errorCode === 1) {
            this.showCustomPopup(this.textService.currentText.selectMainEmailError);
          }
        } else {
          this.closeDialog();
        }
      })
    }
  }

  public checkForm(): boolean {
    if (!this.userForm.valid) {
      if (!this.userForm.controls.firstName.valid) {
        this.errors.firstName = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.userForm.controls.lastName.valid) {
        this.errors.lastName = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.userForm.controls.email.valid) {
        this.errors.email = true;
        if (this.userForm.controls.email.value.length === 0) {
          this.displayError(this.textService.currentText.errorRequired);
        } else {
          this.displayError(this.textService.currentText.errorIncorectEmail);
        }
        return;
      }
      if (!this.userForm.controls.password.valid) {
        this.errors.password = true;
        this.displayError();
        return;
      }
      return false;
    }
    this.createNewVaUser();
    // return this.userForm.valid;
  }

  private displayError(message?: string): void {
    if (!this.userForm.controls.firstName.valid) {
      this.errors.firstName = true;
      const old_value = this.userForm.value.firstName;
      this.userForm.patchValue({
        firstName: message
      });
      this.userForm.controls['firstName'].disable();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.userForm.controls.lastName.valid) {
      this.errors.lastName = true;
      const old_value = this.userForm.value.lastName;
      this.userForm.patchValue({
        lastName: message
      });
      this.userForm.controls['lastName'].disable();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.userForm.controls.email.valid) {
      this.errors.email = true;
      const old_value = this.userForm.value.email;
      this.userForm.patchValue({
        email: message
      });
      this.userForm.controls['email'].disable();
      this.resetErrorState(old_value);
      return;
    }
    if (this.errors.password) {
      const old_value = this.userForm.value.password;
      if (this.userForm.controls.password.errors && this.userForm.controls.password.errors['minLength']) {
        this.passwordInputType = 'text';
        this.userForm.patchValue({
          password: this.textService.currentText.errorMoreThan6
        });
      }
      if (this.userForm.controls.password.errors && this.userForm.controls.password.errors['pattern']) {
        this.passwordInputType = 'text';
        this.userForm.patchValue({
          password: this.textService.currentText.errorIncorectPassFormat
        });
      }
      if (this.userForm.controls.password.value.length === 0) {
        this.passwordInputType = 'text';
        this.userForm.patchValue({
          password: this.textService.currentText.errorRequired
        });
      }
      this.userForm.controls['password'].disable();
      this.resetErrorState(old_value);
      return;
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.firstName) {
        this.userForm.patchValue({
          firstName: val,
        });
        this.errors.firstName = false;
        this.userForm.controls['firstName'].enable();
        return;
      }
      // if (this.errors.date) {
      //   this.errors.date = false;
      // }
      if (this.errors.lastName) {
        this.userForm.patchValue({
          lastName: val,
        });
        this.errors.lastName = false;
        this.userForm.controls['lastName'].enable();
      }
      if (this.errors.email) {
        this.userForm.patchValue({
          email: val,
        });
        this.errors.email = false;
        this.userForm.controls['email'].enable();
      }
      if (this.errors.password) {
        this.userForm.patchValue({
          password: val,
        });
        this.errors.password = false;
        this.passwordInputType = 'password';
        this.userForm.controls['password'].enable();
      }
    }, 2000);
  }

}
