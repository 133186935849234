import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TextService} from '../../../services/text.service';

@Component({
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.scss']
})
export class TermsDialogComponent {

  constructor(public dialogRef: MatDialogRef<TermsDialogComponent>,
              public textService: TextService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}
