import { Component, OnInit, Input } from '@angular/core';
import { StorageBrowser } from 'src/app/services/storage.browser';

@Component({
  selector: 'app-bestandworstsection',
  templateUrl: './bestandworstsection.component.html',
  styleUrls: ['./bestandworstsection.component.scss']
})
export class BestandworstsectionComponent implements OnInit {
  @Input() bestAndWorstData: any;
  @Input() timeframeData: any;
  currencySymbol: any;
  constructor(public storageBrowser: StorageBrowser) { }

  ngOnInit() {
    this.currencySymbol = this.storageBrowser.get('$Currency');
  }

}
