<div class="sidenav" [class.mobile]="userService?.smallSidebar">
  <div class="logo-container">
    <div class="logo">
      <div class="toggle-btn" (click)="mixpanelService.track('q main hide menu clicked',{'state': 'off'});userService.smallSidebar = !userService.smallSidebar;">
      </div>
    </div>
    <div class="sidebar-menu" (click)="mixpanelService.track('q main hide menu clicked',{'state': 'on'});userService.smallSidebar = !userService.smallSidebar;">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
  <div class="sidenav-wrapper">
    <div class="sidenav-content__holder" #scrollMe>
      <div class="sidenav-content">
        <div class="head-box">
          <div class="country-box">
            <div class="country-img">
              <img [src]="getImage()" alt="united-states">
            </div>
            <!-- <div class="country-box__thumbnail">
              <span>Change</span>
            </div> -->
          </div>
          <div class="select-wrapper" (click)="functions.setDropDownPosition(countrySelect, 'countrySelect')" #countrySelect>
            <mat-form-field>
              <mat-select disableOptionCentering [placeholder]="textService.currentText.selectRegion" panelClass="default-dropdown countrySelect" disableRipple
                [disabled]="this.userService.currentUser?.isVa" [(ngModel)]="userService.currentCountry" (selectionChange)="changeRegion();"
                shouldPlaceholderFloat="false">
                <mat-option *ngFor="let country of constService.regions" [value]="country.value">
                  <img src="{{country.image}}" class="dropdown-country" alt="united-states">
                  {{ country.viewValue }}
                </mat-option>
                <div class="inner-add-region p-1">
                  <button (click)="addRegion()">{{textService.currentText.addRegion}}</button>
                </div>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <ul class="nav-menu">
          <li
            *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isOrderDashboard">
            <div class="menu-item dashboard-icon" (click)="mixpanelService.track('q orders dashboard page - view',{'source':'menu'});" [class.active]="router.url === '/dashboard/orders-dashboard'" [routerLink]="'orders-dashboard'">
              <p>Orders Dashboard</p>
            </div>
          </li>
          <li
            *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isDashboard">
            <div class="menu-item profit-icon" (click)="mixpanelService.track('q profit page - view',{'source':'menu'});" [class.active]="router.url === '/dashboard/dashboard-schedules'"
              [routerLink]="'dashboard-schedules'">
              <p>Profit</p>
            </div>
          </li>
          <li
            *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isAutoTemplates">
            <div class="menu-item message-icon" (click)="mixpanelService.track('q automated messaging page - view',{'source':'menu'});userService.currentPage = null" [routerLink]="'automated-messaging/fba'"
              [class.active]="router.url.split('/')[2] === 'automated-messaging'">
              <p>{{textService.currentText.automatedMessaging}}</p>
            </div>
            <mat-radio-group [class.active]="router.url.split('/')[2] === 'automated-messaging'">
              <mat-radio-button value="FBA" (click)="mixpanelService.track('q fba page - view',{'source':'menu'})" class="select-hover" disableRipple [routerLink]="'automated-messaging/fba'" [checked]="router.url === '/dashboard/automated-messaging/fba' ||
                                            router.url === '/dashboard/automated-messaging/fba?created=false' ||
                                            userService.currentPage === 'fba'">
                {{textService.currentText.FBA}}
              </mat-radio-button>
              <mat-radio-button value="FBM" (click)="mixpanelService.track('q fbm page - view',{'source':'menu'})" class="select-hover" disableRipple [routerLink]="'automated-messaging/fbm'" [checked]="router.url === '/dashboard/automated-messaging/fbm' ||
                                          router.url === '/dashboard/automated-messaging/fbm?created=false' ||
                                          userService.currentPage === 'fbm'">
                {{textService.currentText.FBM}}
              </mat-radio-button>
              <mat-radio-button value="Bulk" (click)="mixpanelService.track('q bulk page - view',{'source':'menu'})" class="select-hover" disableRipple [routerLink]="'automated-messaging/bulk'"
                [checked]="router.url === '/dashboard/automated-messaging/bulk'">
                {{textService.currentText.bulk}}
              </mat-radio-button>
            </mat-radio-group>
          </li>
          <li
            *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isCompetetorAlerts">
            <div class="menu-item alerts-icon" (click)="mixpanelService.track('q alerts page - view',{'source':'menu'})" [routerLink]="'asin-alerts'" [class.active]="router.url.split('/')[2] === 'asin-alerts'">
              <p>{{textService.currentText.asinAlertsViewer}}<span class="new-icon"></span></p>
            </div>
          </li>
          <li
            *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isInvoicing">
            <div class="menu-item invoice-icon" (click)="mixpanelService.track('q invoicing page - view',{'source':'menu'})" [routerLink]="'invoicing'" [class.active]="router.url.split('/')[2] === 'invoicing'">
              <p>{{textService.currentText.invoicing}}</p>
            </div>
          </li>
          <li
            *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isAlerts">
            <div class="menu-item notification-icon" (click)="mixpanelService.track('q notifications page - view',{'source':'menu'})" [routerLink]="'facebook-notifications'"
              [class.active]="router.url === '/dashboard/facebook-notifications'">
              <p>{{textService.currentText.notifications}}</p>
            </div>
          </li>
          <!--<li *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isMoneyBack">
            <div class="menu-item money-icon"
                 [routerLink]="'money-back'"
                 [class.active]="router.url === '/dashboard/money-back'">
              <p>{{textService.currentText.moneyBack}}</p>
            </div>
          </li>-->
          <li
            *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isHijacked">
            <div class="menu-item view-icon" (click)="mixpanelService.track('q hijacked page - view',{'source':'menu'})" [routerLink]="'hijacked'" [class.active]="router.url === '/dashboard/hijacked'">
              <p>{{textService.currentText.hijackedListingViewer}}</p>
            </div>
          </li>
          <li
            *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isOrders">
            <div class="menu-item orders-icon" (click)="mixpanelService.track('q orders and blacklist page - view',{'source':'menu'});navigateToFbaOrders()" [class.active]="router.url === '/dashboard/orders?page=fba' ||
                                router.url === '/dashboard/orders?page=fbm' ||
                                router.url === '/dashboard/orders/download-report' ||
                                router.url === '/dashboard/orders/send-message' ||
                                userService.currentPage === 'fba-orders' ||
                                userService.currentPage === 'fbm-orders'">
              <p>{{textService.currentText.ordersAndBL}}</p>
            </div>
            <mat-radio-group [class.active]="router.url === '/dashboard/orders?page=fba' ||
                                             router.url === '/dashboard/orders?page=fbm' ||
                                             router.url === '/dashboard/orders/download-report' ||
                                             router.url === '/dashboard/orders/send-message' ||
                                             userService.currentPage === 'fba-orders' ||
                                             userService.currentPage === 'fbm-orders'">
              <mat-radio-button value="FBA" class="select-hover" (click)="navigateToFbaOrders()" disableRipple [checked]="router.url === '/dashboard/orders?page=fba' ||
                                           userService.currentPage === 'fba-orders'">
                {{textService.currentText.FBA}}
              </mat-radio-button>
              <mat-radio-button value="FBM" class="select-hover" (click)="navigateToFbmOrders()" [checked]="router.url === '/dashboard/orders?page=fbm' ||
                                          userService.currentPage === 'fbm-orders'" disableRipple>
                {{textService.currentText.FBM}}
              </mat-radio-button>
              <mat-radio-button value="download" class="select-hover" [routerLink]="'orders/download-report'"
                [checked]="router.url === '/dashboard/orders/download-report'" disableRipple>
                {{textService.currentText.download}}
              </mat-radio-button>
            </mat-radio-group>
          </li>
          <li
            *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isKeyWordOptimzer">
            <div class="menu-item key-icon" (click)="mixpanelService.track('q keyword optimizer page - view',{'source':'menu'});" [routerLink]="'keywords-optimizer'" [class.active]="router.url === '/dashboard/keywords-optimizer'">
              <p>{{textService.currentText.keywordOptimizer}}</p>
            </div>
          </li>
          <li *ngIf="(!this.userService.currentUser?.isVa && this.userService.currentUser.isMainAccount) ||
                       this.userService.currentUser?.permissions?.isConversation && this.userService.currentUser.isMainAccount">
            <div class="menu-item chat-icon" (click)="mixpanelService.track('q facebook bot manager page - view',{'source':'menu'});" [routerLink]="'facebook-bot-manager/grant-access'"
              [class.active]="router.url.split('/')[2] === 'facebook-bot-manager'">
              <p>{{textService.currentText.facebookBotManager}}</p>
            </div>
            <mat-radio-group class="facebook-icon"
              [class.active]="router.url.split('/')[2] === 'facebook-bot-manager' && router.url.split('/')[3] !== 'grant-access'">
              <mat-radio-button value="conversation-manager" class="select-hover" disableRipple
                [routerLink]="'facebook-bot-manager/conversation-manager'" [checked]="router.url.split('/')[3] === 'conversation-manager'">
                {{textService.currentText.conversationManager}}
              </mat-radio-button>
              <mat-radio-button value="auto-response" class="select-hover" disableRipple [routerLink]="'facebook-bot-manager/auto-response'"
                [checked]="router.url === '/dashboard/facebook-bot-manager/auto-response'">
                {{textService.currentText.autoResponse}}
              </mat-radio-button>
              <mat-radio-button value="subscribers" class="select-hover" disableRipple [routerLink]="'facebook-bot-manager/subscribers'"
                [checked]="router.url === '/dashboard/facebook-bot-manager/subscribers'">
                {{textService.currentText.subscribers}}
              </mat-radio-button>
              <mat-radio-button value="menu" class="select-hover" disableRipple [routerLink]="'facebook-bot-manager/menu'"
                [checked]="router.url === '/dashboard/facebook-bot-manager/menu'">
                {{textService.currentText.menu}}
              </mat-radio-button>
              <mat-radio-button value="sequences" class="select-hover" disableRipple [routerLink]="'facebook-bot-manager/sequences'"
                [checked]="router.url === '/dashboard/facebook-bot-manager/sequences'">
                {{textService.currentText.sequences}}
              </mat-radio-button>
            </mat-radio-group>

          </li>
          <li *ngIf="this.userService.currentUser?.permissions?.isAsin || this.userService.currentUser.isMainAccount">
            <div class="menu-item money-icon" (click)="mixpanelService.track('q asian tracker page - view',{'source':'menu'});" [routerLink]="'asin-tracker'" [class.active]="router.url === '/dashboard/asin-tracker'">
              <p>{{textService.currentText.asinTracker}}</p>
            </div>
          </li>
          <li *ngIf="this.userService.currentUser?.permissions?.isAffiliate">
            <div class="menu-item people-icon" (click)="mixpanelService.track('q affiliates title page - view',{'source':'menu'});" [routerLink]="'affiliate'" [class.active]="router.url === '/dashboard/affiliate'">
              <p>{{textService.currentText.affiliatesTitle}}</p>
            </div>
          </li>
          <li *ngIf="userService.currentUser?.permissions?.isSuperUser">
            <div class="menu-item star-icon" (click)="mixpanelService.track('q super user page - view',{'source':'menu'});" [routerLink]="'super-user'" [class.active]="router.url === '/dashboard/super-user'">
              <p>{{textService.currentText.superUser}}</p>
            </div>
          </li>
          <li
            *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isCoupons">
            <div class="menu-item coupon-icon" (click)="mixpanelService.track('q coupon page - view',{'source':'menu'});" [routerLink]="'coupons'" [class.active]="router.url === '/dashboard/coupons'">
              <p>{{textService.currentText.coupons}}</p>
            </div>
          </li>
          <!--<li *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isInvitations">-->
          <!--<div class="menu-item invite-icon">-->
          <!--<p>{{textService.currentText.inviteFriends}}</p>-->
          <!--</div>-->
          <!--</li>-->
          <li
            *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && this.userService.currentUser?.permissions?.isUpgrade">
            <div class="menu-item arrow-up-icon" (click)="mixpanelService.track('q upgrade page - view',{'source':'menu'});" [routerLink]="'upgrade'" [class.active]="this.router.url.split('/')[2].includes('upgrade')">
              <p>{{textService.currentText.upgrade}}</p>
            </div>
          </li>
          <li *ngIf="!this.userService.currentUser?.isVa || this.userService.currentUser?.isVa && userService.currentUser?.permissions?.isSettings" (click)="scrollToBottom()">
            <div class="menu-item account-icon" (click)="mixpanelService.track('q account page - view',{'source':'menu'});"
              [routerLink]="this.userService.currentUser?.isVa ? 'user/settings/product-settings' : 'user/settings/account-settings'"
              [class.active]="router.url.split('/')[2] === 'account' || router.url.split('/')[2] === 'user'">
              <p>{{textService.currentText.accountSidenav}}</p>
            </div>
            <mat-radio-group [class.active]="router.url.split('/')[2] === 'account' || router.url.split('/')[2] === 'user'">
              <mat-radio-button (click)="mixpanelService.track('q account settings page - view',{'source':'menu'});" value="account-settings" *ngIf="!this.userService.currentUser?.isVa ||
                                        this.userService.currentUser?.isVa &&
                                        this.userService.currentUser?.permissions?.isUpgrade" class="select-hover" disableRipple
                [routerLink]="'user/settings/account-settings'" [checked]="router.url.split('/')[4] === 'account-settings'">
                {{textService.currentText.accountSettings}}
              </mat-radio-button>
              <mat-radio-button (click)="mixpanelService.track('q product settings page - view',{'source':'menu'});" value="product-settings" class="select-hover" disableRipple [routerLink]="'user/settings/product-settings'"
                [checked]="router.url.split('/')[4] === 'product-settings'">
                {{textService.currentText.productSettings}}
              </mat-radio-button>
              <mat-radio-button (click)="mixpanelService.track('q expenses page - view',{'source':'menu'});" value="expenses" class="select-hover" disableRipple [routerLink]="'user/settings/expenses'"
                [checked]="router.url.split('/')[4] === 'expenses'">
                {{textService.currentText.expenses}}
              </mat-radio-button>
              <mat-radio-button (click)="mixpanelService.track('q user permissions page - view',{'source':'menu'});" value="permissions" *ngIf="!this.userService.currentUser?.isVa" class="select-hover" disableRipple
                [routerLink]="'account/user-permissions'" [checked]="router.url.split('/')[3] === 'user-permissions'">
                {{textService.currentText.userPermissions}}
              </mat-radio-button>
              <mat-radio-button (click)="mixpanelService.track('q billing page - view',{'source':'menu'});" value="billing" class="select-hover" disableRipple [routerLink]="'account/billing'"
                [checked]="router.url.split('/')[3] === 'billing'">
                {{textService.currentText.billing}}
              </mat-radio-button>
              <mat-radio-button (click)="mixpanelService.track('q link-accounts page - view',{'source':'menu'});" value="linked" *ngIf="!this.userService.currentUser?.isVa" class="select-hover" disableRipple
                [routerLink]="'account/link-accounts'" [checked]="router.url.split('/')[3] === 'link-accounts'">
                {{textService.currentText.linkAccounts}}
              </mat-radio-button>
              <mat-radio-button value="linked" (click)="mixpanelService.track('q contact page - view',{'source':'menu'});showContactUs(); $event.preventDefault()" class="select-hover" disableRipple>
                {{textService.currentText.contactUs}}
              </mat-radio-button>
            </mat-radio-group>
          </li>
        </ul>
      </div>
      <!-- <div class="tutorial-block">
        <div class="row">
          <div class="media align-items-center">
            <img src="assets/images/tutorial.svg" alt="" (click)="navigateToFAQ()">
            <div class="media-body text">
              {{textService.currentText.FAQQuestions}}?
            </div>
          </div>
        </div>
        <button mat-button mat-raised-button (click)="navigateToFAQ()" class="primary-btn">
          {{textService.currentText.watchTutorials}}
        </button>
      </div> -->
    </div>
  </div>
</div>