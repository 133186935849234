import {Component, Input} from '@angular/core';
import {TextService} from '../../../../../services/text.service';

@Component({
  selector: 'ninja-conversation-preview',
  templateUrl: './conversation-preview.component.html',
  styleUrls: ['./conversation-preview.component.scss']
})

export class ConversationPreviewComponent {
  @Input() data: any;
  constructor (public textService: TextService) {}
}
