
<div class="login-container">
  <div class="right-container">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel">

      <div class="carousel-inner">
        <div class="carousel-item active">
          <p>
            It is tremendous help in allowing us to automate the follow-up
            process with
            our Amazon's customers and helping us with inventory management and
            accounting.<br>
            In Our consulting firm, QN is the first software we recommend to all
            our customers who sell on Amazon!
          </p>
          <div class="mt-5 user">
            <div class="user-image"><img
                src="../../../../assets/images/review.jpeg" /></div>
            <div class="user-detail ml-4">
              <h6>Jerome & Jeremie</h6>
              <span class="text-color">Consultants</span>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <p>
            Quntify Ninja is the best all-in-one tool for Amazon sellers out
            there. You get about a dozen tool covering practically all your
            needs for the price of one.
          </p>
          <div class="mt-5 user">
            <div class="user-image"><img
                src="../../../../assets/images/review2.jpg" /></div>
            <div class="user-detail ml-4">
              <h6>Jessica Ricks</h6>
              <span class="text-color">Seller</span>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <p>
            I really like Facebook bot, it’s amazing! It helps me manage a lot
            of things in an easy automated way. It’s also very clear and easy to
            use.
          </p>
          <div class="mt-5 user">
            <div class="user-image"><img
                src="../../../../assets/images/review3.jpg" /></div>
            <div class="user-detail ml-4">
              <h6>Michael Sivan</h6>
              <span class="text-color">Seller</span>
            </div>
          </div>
        </div>
      </div>
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
    </div>
  </div>

  <div class="left-container left-container--login">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <img src="../../../../assets/icons/logo.svg" />
        </div>
      </div>
      <h2 class="mt-3">Welcome to Quantify Ninja</h2>
      <p class="text-center mfa-text">{{textService.currentText.mfaText}}</p>
      
      <form class="login-form" name="form" [formGroup]="mfaForm" novalidate>
        <div class="form-group input-border" [class.has-danger]="errors?.mfaCodeInvalid">
          <input type="mfaCode" id="mfaCode" #mfaCode class="form-control-x"
            formControlName="mfaCode"
            [placeholder]="textService.currentText.passcode"
            [(ngModel)]="userMfaCode" (focus)="mfaCodeInput= true"
            (focusout)="mfaCodeInput= false" />
        </div>
        <div class="form-group-button d-flex justify-content-between">
          <button mat-button class="secondary-btn btn-fill"
            (click)="googleAnalyticsEventsService.emitEvent('Button', 'Login',
            'Click', 1);
            mixpanelService.track('q login page - login clicked',{'page':
            'Login','page type':'quantifyninja'}); login()">{{textService.currentText.continue}}</button>
        </div>
        <div class="input-hint input-row justify-content-between">
          <a (click)="resendCode()"
            class="pointer forgot-pass">{{textService.currentText.resendCode}}</a>
          <a (click)="goToLoginPage()"
            class="pointer forgot-pass">{{textService.currentText.canNotLogin}}</a>
        </div>
      </form>
    </div>
  </div>
</div>