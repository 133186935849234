import { Component, EventEmitter, Input, OnInit, Output, ElementRef, } from '@angular/core';

@Component({
    selector: 'app-inline-edit',
    templateUrl: './inline-edit.component.html',
    styleUrls: ['./inline-edit.component.scss'],
})

export class InlineEditComponent implements OnInit {




    @Input() data: string;
    @Output() update: EventEmitter<any> = new EventEmitter();
    @Output() close: EventEmitter<any> = new EventEmitter();
    private _editMode = false;
    private prevValue: string;
    private saveData = false;

    constructor(private eRef: ElementRef) { }



    ngOnInit() {
        this.prevValue = this.data
    }

    public onOK(): void {
        this.saveData = true;
        this.editMode = false;
    }

    @Input() set EditMode(value: boolean) {
        this.editMode = value;
    }

    set editMode(mode: boolean) {
        if (this._editMode == mode)
            return;
        this._editMode = mode;
        if (this._editMode) {
            this.saveData = false;
            this.prevValue = this.data
            setTimeout(() => {
                document.addEventListener('mousedown', this.offclickevent);
            }, 200);
            this.eRef.nativeElement.focus();
        } else {
            if (this.saveData) {
                this.update.emit({ editor: this, prevValue: this.prevValue, value: this.data });
            } else {
                this.data = this.prevValue;
                this.close.emit({ prevValue: this.prevValue, value: this.data });
            }
            document.removeEventListener('mousedown', this.offclickevent);
        }

    }

    offclickevent: any = ((evt: any) => {
        if ((this.eRef.nativeElement != evt.target) && (!this.eRef.nativeElement.contains(evt.target)))
            this.editMode = false;
    }).bind(this);



    get editMode(): boolean {
        return this._editMode;
    }

}