<div class="main-dialog-container edit-dialog">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content" name="form" [formGroup]="submitForm" novalidate>
      <div class="header">
        <h2>{{textService.currentText.editProductShortName}}</h2>
      </div>
      <div class="dialog-row sub-row">
        <div class="label-name">
          <span>{{textService.currentText.shortName}}</span>
        </div>
        <input matInput formControlName="shortName" type="text" placeholder="Enter Short Name">
      </div>
    </div>
    <div class="dialog-content">
      <h3>{{textService.currentText.setdifferentnamesformarketplaces}}</h3>
      <div class="dialog-row sub-row mb-4" *ngFor="let salesChannels of salesChannelsList">
        <div class="label-name">
          <img class="mr-3" width="32px" src="{{salesChannels.image}}">
          <span>{{salesChannels.viewValue}}</span>
        </div>
        <input matInput [(ngModel)]="salesChannels.value" type="text" placeholder="Enter Short Name">
      </div>
    </div>
  </div>
  <div class="dialog-footer justify-content-center">
    <button mat-button class="secondary-btn btn-fill mr-5" (click)="mixpanelService.track('q edit product popup - cancel', {
      'source': 'orders dashboard current',
      'page':'orders-dashboard'
    });submit()">{{textService.currentText.save}}</button>
    <button mat-button class="secondary-btn btn-fill cancel"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
  </div>
</div>

<div class="preloader-wrapper-dashboard" style="position: absolute; top: 0; left: 0;" *ngIf="loader">
  <div class="preloader">
    <img src="assets/images/preloader.svg" alt="preloader">
  </div>
</div>