<mat-card class="todaysection">
    <mat-card-title class="d-flex justify-content-between">
        <div class="d-flex">
            <mat-icon class="text-orng-style font-size-19">access_alarm</mat-icon>
            <span class="header-user-color font-size-13 font-weight-bold pl-2 pr-2">REAL TIME</span>
            <span class="text-gray-light font-size-13" *ngIf="realTimeData">{{realTimeData.dateAsString}}</span>
        </div>
        <button class="cursor-pointer btnChange" type="button" matTooltip="Profit Breakdown" [disabled]="disableProfitBreakdownDialog"
            (click)="openProfitBrakedownDialog()">
            <img height="18" width="18" src="assets/images/profit_breakdown.png" />
        </button>
    </mat-card-title>
    <mat-card-content>
        <div class="row">
            <div class="text-center max-width-33">
                <h4 class="text-gray-light">Units Sold</h4>
                <div class="cursor-pointer" (click)="openUnitsSoldDialog()">
                    <h3 class="font-size-25 mb-0 font-weight-bold" *ngIf="realTimeData">
                        {{realTimeData.unitsData.units}}
                    </h3>
                    <span class="font-size-11 line-height-15 font-weight-bold"
                        [ngClass]="realTimeData.unitsData && realTimeData.unitsData.change > 0 ? 'text-green' : 'profit-red-color'"
                        *ngIf="realTimeData">
                        <mat-icon class="mat-icon-size align-middle">
                            {{realTimeData.unitsData.change > 0 ? 'arrow_upward' : 'arrow_downward'}} </mat-icon>
                        {{realTimeData.unitsData.change | number}}%
                    </span>
                </div>
                <div echarts [options]="unitsSoldChartOptions"></div>
            </div>

            <div class="text-center max-width-33">
                <div class="cursor-pointer" (click)="openCostDialog()">
                    <h6 class="line-height-16 profit-color-text mb-0">
                        Est. Costs
                        <!-- <span class="font-size-11 line-height-15" [ngClass]="realTimeData.costs.change > 0 ? 'text-green' : 'profit-red-color'"
                                *ngIf="realTimeData">
                                <mat-icon class="mat-icon-size mb-0 align-middle">
                                    {{realTimeData.costs.change > 0 ? 'trending_up' : 'trending_down'}}
                                </mat-icon>{{realTimeData.costs.change | number}}%
                            </span> -->
                    </h6>
                    <h3 class="mb-19 font-weight-bold" *ngIf="realTimeData">
                        {{realTimeData.costs.value | currency: currencySymbol : 'symbol' : '1.0-0'}}</h3>
                </div>

                <div class="cursor-pointer" (click)="openProfitDialog()">
                    <h6 class="line-height-16 profit-color-text mb-0">
                        Est. Profit
                        <span class="font-size-11 line-height-15" [ngClass]="realTimeData.profit.change > 0 ? 'text-green' : 'profit-red-color'"
                            *ngIf="realTimeData">
                            <mat-icon class="mat-icon-size mb-0 align-middle">
                                {{realTimeData.profit.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                            {{realTimeData.profit.change | number}}%
                        </span>
                    </h6>
                    <h3 class="mb-19 font-weight-bold" *ngIf="realTimeData">
                        {{realTimeData.profit.value | currency: currencySymbol : 'symbol' : '1.0-0'}}</h3>
                </div>
                <div class="cursor-pointer" (click)="openMarginDialog()">
                    <h6 class="line-height-16 profit-color-text mb-0">
                        Est. Margin
                        <span class="font-size-11 line-height-15" [ngClass]="realTimeData.margin.change > 0 ? 'text-green' : 'profit-red-color'"
                            *ngIf="realTimeData">
                            <mat-icon class="mat-icon-size mb-0 align-middle">
                                {{realTimeData.margin.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                            {{realTimeData.margin.change | number}}%
                        </span>
                    </h6>
                    <h3 class="font-weight-bold mb-0" *ngIf="realTimeData">{{realTimeData.margin.value | number}}%</h3>
                </div>
            </div>

            <div class="text-center max-width-33">
                <h4 class="text-gray-light">Revenue</h4>
                <div class="cursor-pointer" (click)="openRevenueDialog()">
                    <h3 class="font-size-25 mb-0 font-weight-bold mt-22" *ngIf="realTimeData">
                        {{realTimeData.revenueData.value | currency: currencySymbol : 'symbol' : '1.0-0'}}
                    </h3>
                    <span class="font-size-11 line-height-15 font-weight-bold"
                        [ngClass]="realTimeData.revenueData.change > 0 ? 'text-green' : 'profit-red-color'" *ngIf="realTimeData">
                        <mat-icon class="mat-icon-size align-middle">
                            {{realTimeData.revenueData.change > 0 ? 'arrow_upward' : 'arrow_downward'}}</mat-icon>
                        {{realTimeData.revenueData.change | number}}%
                    </span>
                </div>
                <div echarts [options]="revenueChartOptions"></div>
            </div>
        </div>
    </mat-card-content>
</mat-card>