import {Component, OnInit} from '@angular/core';
import {TermsDialogComponent} from '../../dialogs/terms-condition-dialog/terms-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {UserService} from '../../../services/user.service';
import {GoogleAnalyticsEventsService} from '../../../services/google-analytics.service';
import {TextService} from '../../../services/text.service';

@Component({
  selector: 'ninja-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.scss']
})

export class AffiliateComponent implements OnInit {
  public currentAffiliates: any;

  constructor(public matDialog: MatDialog,
              public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
              public textService: TextService,
              public userService: UserService) {
  }

  public ngOnInit(): void {
    this.userService.loaderObesrver.next(true);
    this.userService.getAffiliate().subscribe((data) => {
      this.currentAffiliates = data;
      this.userService.loaderObesrver.next(false);
    });
  }

  public getLink(): void {
    this.userService.getAffiliateLink().subscribe((data) => {
      this.currentAffiliates.push({affiliates: [], link: data.link});
    });
  }

  public openTAC(): void {
    this.matDialog.open(TermsDialogComponent, {
      data: {
        'dialogFor': 'login-to-posting',
      },
    }).afterClosed();
  }
}
