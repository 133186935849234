import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ninja-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})

export class TooltipComponent {
  @Input() text: string;
  @Input() isForProducts: string;
  @Output() toggledChange = new EventEmitter<boolean>();
}
