import { Component, OnInit, EventEmitter, Input, Output, Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgModel } from '@angular/forms';
import { ShareService } from 'src/app/services/share.service';
import * as _moment from 'moment';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { TextService } from 'src/app/services/text.service';
import { UserService } from 'src/app/services/user.service';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { MatDialog } from '@angular/material/dialog';
import { ErrorsComponent } from '../dashboard-schedules/popups/errors/errors.component';
import { StorageBrowser } from 'src/app/services/storage.browser';
import { Location } from "@angular/common";
import { Sort } from '@angular/material/sort';
import { MixpanelService } from 'src/app/services/mixpanel.service';
@Component({
  selector: 'ninja-custom-filter-menu',
  templateUrl: './custom-filter-menu.component.html',
  styleUrls: ['./custom-filter-menu.component.scss']
})

export class CustomFilterMenuComponent implements OnInit {
  dateForm: FormGroup;
  productForm: FormGroup;
  submitted = false;
  lastUpdated: string;
  isFilters: any;
  asinarr: any = [];
  defaultList: any = [];
  sortedData: any;
  searchFilters: any;
  @ViewChild('customDate', { static: true }) customDate: MatMenuTrigger;
  @Input() notificPanel;
  selected: any;
  closeOnAutoApply: boolean;
  autoApply: boolean;
  alwaysShowCalendars: boolean;
  showCustomRangeLabel: boolean;
  lockStartDate: boolean;
  brand: any = [];
  activeChannels: any = [];
  niches: any = [];
  restoreFocus: boolean;
  startDate: any = moment();
  customDateSelection = 'LAST_30_DAYS';
  dateSelection: any;
  @Input() profitData: any;
  @Input() dashboardSchedulesFilter: any;
  @Input() yearlyCompareValue: boolean;
  presetData: any;
  service: any;
  selectedVal: string;
  route: string;
  @Output() EmitformDataDashBoard = new EventEmitter<string>();
  formDataDashBoard: any;
  customDateType = ["TODAY", "YESTERDAY", "LAST_7_DAYS", "LAST_30_DAYS", "MONTH_TO_DATE", "LAST_MONTH", "YEAR_TO_DATE", "LAST_YEAR", "CUSTOM"];
  products: any = [];
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'Month To Date': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Year To Date': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  }
  allSelected = false;
  public filters: any = {
    allSalesChannels: this.textService.currentText.allChannels,
    allProducts: true,
    time: 'Default',
    productsskus: []
  };
  public filtersAmazonSales: any = {
    allProducts: true,
    productsskus: [],
    allSalesChannels: this.textService.currentText.allChannels,
  };
  @ViewChild('asinSel', { static: true }) asinSel: MatSelect;
  @ViewChild('activeChannelsSel', { static: true }) activeChannelsSel: MatSelect;
  @ViewChild('nichesSel', { static: true }) nichesSel: MatSelect;
  @ViewChild('brandsSel', { static: true }) brandsSel: MatSelect;
  @ViewChild(DaterangepickerComponent, { static: true }) pickerInline: DaterangepickerComponent;
  constructor(
    private router: Router,
    private location: Location,
    public storageBrowser: StorageBrowser,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    public textService: TextService,
    public userService: UserService,
    public ref: ChangeDetectorRef,
    private dialog: MatDialog,
    public mixpanelService: MixpanelService,
    private shareService: ShareService
  ) {
    this.mixpanelService.init(null);
    this.alwaysShowCalendars = true;
    this.showCustomRangeLabel = true;
  }
  ngOnInit() {
    console.log(this.activeChannels)

    this.searchFilters = {
      defaultName: '',
      asin: '',
      sku: ''
    };
    this.productForm = this.formBuilder.group({
      brands: ['', ''],
      niches: ['', ''],
      marketplace: ['', ''],
      asin: ['', ''],
      checkAll: ['', '']
    });
    if (this.userService.currentUser && this.userService.currentUser.preset) {
      this.presetData = JSON.parse(this.userService.currentUser.preset);
      if (this.presetData.startDate) {
        this.selectedVal = this.presetData.timeFrame;
        this.customDateSelection = this.selectedVal;
        if (this.presetData.startDate !== undefined) {
          this.pickerInline.setStartDate(moment(this.presetData.startDate));
          this.presetData.startDate = moment(this.presetData.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
        }
        if (this.presetData.endDate !== undefined) {
          if (this.presetData.startDate === undefined) {
            this.pickerInline.setStartDate(moment(this.presetData.endDate));
          }
          this.pickerInline.setEndDate(moment(this.presetData.endDate));
          this.presetData.endDate = moment(this.presetData.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
        }
        this.productForm.patchValue({
          marketplace: this.presetData.sc,
          asin: this.presetData.skus,
          brands: this.presetData.brands
        })
        if (this.selectedVal == 'LAST_7_DAYS') {
          this.pickerInline.chosenRange = 'Last 7 Days';
        } else if (this.selectedVal == 'LAST_30_DAYS') {
          this.pickerInline.chosenRange = 'Last 30 Days';
        } else if (this.selectedVal == 'TODAY') {
          this.pickerInline.chosenRange = 'Today';
        } else if (this.selectedVal == 'YESTERDAY') {
          this.pickerInline.chosenRange = 'Yesterday';
        } else if (this.selectedVal == 'MONTH_TO_DATE') {
          this.pickerInline.chosenRange = 'Month To Date';
        } else if (this.selectedVal == 'LAST_MONTH') {
          this.pickerInline.chosenRange = 'Last Month';
        } else if (this.selectedVal == 'YEAR_TO_DATE') {
          this.pickerInline.chosenRange = 'Year To Date';
        } else if (this.selectedVal == 'LAST_YEAR') {
          this.pickerInline.chosenRange = 'Last Year';
        }
        if (this.selectedVal == 'CUSTOM') {
          this.dateSelection = {
            "startDate": '',
            "endDate": ''
          }
          this.dateSelection.startDate = moment(this.presetData.startDate);
          this.dateSelection.endDate = moment(this.presetData.endDate);
        } else {
          this.dateSelection = {
            "dates": []
          }
          this.dateSelection.dates[0] = moment(this.presetData.startDate);
          this.dateSelection.dates[1] = moment(this.presetData.endDate);
        }
        this.presetData["firstTimeFrameBox"] = {
          "startDate": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.startDate ? moment(this.presetData.firstTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
          "endDate": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.endDate ? moment(this.presetData.firstTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
          "timeFrame": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.timeFrame ? this.presetData.firstTimeFrameBox.timeFrame : "TODAY"
        };
        this.presetData["secondTimeFrameBox"] = {
          "startDate": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.startDate ? moment(this.presetData.secondTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
          "endDate": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.endDate ? moment(this.presetData.secondTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
          "timeFrame": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.timeFrame ? this.presetData.secondTimeFrameBox.timeFrame : "LAST_7_DAYS"
        };
        this.presetData["thirdTimeFrameBox"] = {
          "startDate": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.startDate ? moment(this.presetData.thirdTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
          "endDate": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.endDate ? moment(this.presetData.thirdTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
          "timeFrame": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.timeFrame ? this.presetData.thirdTimeFrameBox.timeFrame : "LAST_30_DAYS"
        };
        this.formDataDashBoard = this.presetData;
        this.EmitformDataDashBoard.emit(this.presetData);
        if (this.router.url == '/dashboard/orders-dashboard') {
          this.getOrderData(this.presetData);
        } else {
          this.getprofitData(this.presetData);
        }
        this.getProductData(this.presetData);
      } else {
        this.setDefaultData();
      }
    } else {
      this.setDefaultData();
    }
  }

  onGoToPage2(asin) {
    let link: string;
    if ((this.activeChannels && this.activeChannels[0] === "AMAZON_COM") ||
      (this.activeChannels && this.activeChannels[0] === "AMAZON_SAUDI_ARABIA") ||
      (this.activeChannels && this.activeChannels[0] === "AMAZON_EGYPT")) {
      link = `https://www.amazon.com/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_CANADA") {
      link = `https://www.amazon.ca/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_MEXICO") {
      link = `https://www.amazon.com.mx/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_BRAZIL") {
      link = `https://www.amazon.com.br/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_UK") {
      link = `https://www.amazon.co.uk/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_FRANCE") {
      link = `https://www.amazon.fr/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_GERMANY") {
      link = `https://www.amazon.de/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_ITALY") {
      link = `https://www.amazon.it/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_SPAIN") {
      link = `https://www.amazon.es/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_NETHERLANDS") {
      link = `https://www.amazon.nl/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_TURKEY") {
      link = `https://www.amazon.com.tr/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_JAPAN") {
      link = `https://www.amazon.co.jp/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === 'AMAZON_SWEDEN') {
      link = `https://www.amazon.se/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_AUSTRALIA") {
      link = `https://www.amazon.com.au/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_INDIA") {
      link = `https://www.amazon.in/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_UAE") {
      link = `https://www.amazon.ae/dp/${asin}`
    } else if (this.activeChannels && this.activeChannels[0] === "AMAZON_SINGAPOR") {
      link = `https://www.amazon.sg/dp/${asin}`
    }
    window.open(link, '_blank');
  }
  setDefaultData() {
    if (this.router.url == '/dashboard/orders-dashboard') {
      this.selectedVal = 'TODAY';
      setTimeout(() => { this.oneDay(); }, 1000);
    } else {
      this.selectedVal = 'LAST_30_DAYS';
      this.pickerInline.setStartDate(moment().subtract(29, 'days'));
      this.pickerInline.setEndDate(moment());
      this.pickerInline.chosenRange = 'Last 30 Days';
      setTimeout(() => {
        this.onMonth();
      }, 1000);
    }
  }
  getProductData(formData) {
    if (this.storageBrowser.get('$UserToken$user')) {
      this.userService.getProductData(formData).subscribe((res: any) => {
        if (res) {
          this.brand = res.brnads.filter(item => {
            if (item) {
              return item;
            }
          });
          this.activeChannels = res.activeChannels.filter(item2 => {
            if (item2) {
              return item2;
            }
          });
          this.products = res.products;
          this.defaultList = res.products;
          this.products.map(product => {
            product['checked'] = false;
            if (this.presetData && this.presetData.skus) {
              this.asinarr = this.presetData.skus;
              this.presetData.skus.map(sku => {
                if (sku == product.sku) {
                  product.checked = true;
                }
              });
            }
          });
        }
      });
    }
  }
  toggleAllSelection(type, event?) {
    this.allSelected = !this.allSelected;  // to control select-unselect
    if (type == 'asin' && event) {
      if (type == 'asin')
        this.asinSel.options.forEach((item: MatOption) => item.select());
      if (this.data.asin.value[0] == 0) {
        this.data.asin.value.shift();
      }
      if (event) {
        this.asinarr = [];
        this.products.map(item => {
          item.checked = true;
          this.asinarr.push(item.sku);
        });
      }
    } else if (type == 'asin' && !event) {
      if (type == 'asin')
        this.asinSel.options.forEach((item: MatOption) => { item.deselect() });
      if (!event) {
        this.asinarr = [];
        this.products.map(item => {
          item.checked = false;
        });
      }
    }
    if (this.allSelected) {
      // if (type == 'asin')
      //   this.asinSel.options.forEach((item: MatOption) => item.select());
      //   if(this.data.asin.value[0] == 0) {
      //     this.data.asin.value.shift();
      //   }
      //   if(event) {
      //     this.asinarr = [];
      //     this.products.map(item => {
      //       item.checked = true;
      //       this.asinarr.push(item.sku);
      //     });   
      //   }
      if (type == 'activeChannels')
        this.activeChannelsSel.options.forEach((item: MatOption) => item.select());
      console.log("this.activeChannelsSel", this.activeChannelsSel)
      if (this.data.marketplace.value[0] == 0) {
        this.data.marketplace.value.shift();
      }
      if (type == 'brands')
        this.brandsSel.options.forEach((item: MatOption) => item.select());
      if (this.data.brands.value[0] == 0) {
        this.data.brands.value.shift();
      }
    } else {
      // if (type == 'asin')
      //   this.asinSel.options.forEach((item: MatOption) => { item.deselect() });
      //   if(!event) {
      //     this.asinarr = [];
      //     this.products.map(item => {
      //       item.checked = false;
      //     }); 
      //   }
      if (type == 'activeChannels')
        this.activeChannelsSel.options.forEach((item: MatOption) => { item.deselect() });
      if (type == 'brands')
        this.brandsSel.options.forEach((item: MatOption) => { item.deselect() });
    }
  }
  choosedDate(event) {
    let evenetDate = '';
    if (event.label) {
      evenetDate = event.label.toUpperCase().replace(/-|\s/g, "_");
    } else {
      evenetDate = event.chosenLabel.toUpperCase().replace(/-|\s/g, "_");
    }
    if (this.customDateType.indexOf(evenetDate) !== -1) {
      this.selectedVal = this.customDateSelection = evenetDate;
    } else {
      this.selectedVal = this.customDateSelection = 'CUSTOM';
    }
    this.dateSelection = event;
    //this.customDate.closeMenu();
  }
  get data() { return this.productForm.controls; }

  changeDay() {
    this.userService.loaderObesrver.next(true);
    this.pickerInline.ranges = this.ranges;
    let startDate = '';
    let endDate = '';
    if (this.selectedVal == 'LAST_7_DAYS') {
      startDate = moment().subtract(7, "days").format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
      this.pickerInline.clickRange('', 'Last 7 Days');
    } else if (this.selectedVal == 'LAST_30_DAYS') {
      endDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
      startDate = moment().subtract(29, "days").format("YYYY-MM-DD") + "T00:00:00.000Z";
      this.pickerInline.clickRange('', 'Last 30 Days');
    } else if (this.selectedVal == 'TODAY') {
      endDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
      startDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
      this.pickerInline.clickRange('', 'Today');
    } else if (this.selectedVal == 'YESTERDAY') {
      startDate = moment().subtract(1, "day").format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment().subtract(1, "day").format("YYYY-MM-DD") + "T00:00:00.000Z";
      this.pickerInline.clickRange('', 'Yesterday');
    } else if (this.selectedVal == 'MONTH_TO_DATE') {
      startDate = moment().startOf('month').format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
      this.pickerInline.clickRange('', 'Month To Date');
    } else if (this.selectedVal == 'LAST_MONTH') {
      startDate = moment().subtract(1, "month").startOf('month').format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment().subtract(1, "month").endOf('month').format("YYYY-MM-DD") + "T00:00:00.000Z";
      this.pickerInline.clickRange('', 'Last Month');
    } else if (this.selectedVal == 'YEAR_TO_DATE') {
      startDate = moment().startOf('year').format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
      this.pickerInline.clickRange('', 'Year To Date');
    } else if (this.selectedVal == 'LAST_YEAR') {
      startDate = moment().subtract(1, "year").startOf('year').format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment().subtract(1, "year").endOf('year').format("YYYY-MM-DD") + "T00:00:00.000Z";
      this.pickerInline.clickRange('', 'Last Year');
    } else if (this.selectedVal == 'CUSTOM') {
      startDate = moment(this.dateSelection.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment(this.dateSelection.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
    }

    let formData: any = {
      "startDate": startDate,
      "endDate": endDate,
      "timeFrame": this.selectedVal,
      "skus": this.data.asin.value ? this.data.asin.value : [],
      "sc": this.data.marketplace.value ? this.data.marketplace.value : [],
      "brands": this.data.brands.value ? this.data.brands.value : [],
      "niches": this.data.niches.value ? this.data.niches.value : [],
      "firstTimeFrameBox": {
        "startDate": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.startDate ? moment(this.presetData.firstTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.endDate ? moment(this.presetData.firstTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.timeFrame ? this.presetData.firstTimeFrameBox.timeFrame : "TODAY"
      },
      "secondTimeFrameBox": {
        "startDate": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.startDate ? moment(this.presetData.secondTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.endDate ? moment(this.presetData.secondTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.timeFrame ? this.presetData.secondTimeFrameBox.timeFrame : "LAST_7_DAYS"
      },
      "thirdTimeFrameBox": {
        "startDate": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.startDate ? moment(this.presetData.thirdTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.endDate ? moment(this.presetData.thirdTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.timeFrame ? this.presetData.thirdTimeFrameBox.timeFrame : "LAST_30_DAYS"
      }
    };

    this.customDateSelection = this.selectedVal;
    // this.shareService.changeDate(formData);
    this.formDataDashBoard = formData;
    this.EmitformDataDashBoard.emit(formData);
    if (this.router.url == '/dashboard/orders-dashboard') {
      this.getOrderData(formData);
    } else {
      this.getprofitData(formData);
    }
  };

  onSubmit() {
    this.userService.loaderObesrver.next(true);
    let startDate, endDate;
    if (this.presetData && this.presetData.startDate) {
      startDate = moment(this.presetData.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment(this.presetData.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
    } else {
      startDate = moment().subtract(29, "days").format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
    }
    if (this.dateSelection) {
      if (this.dateSelection.label) {
        startDate = moment(this.dateSelection.dates[0]).format("YYYY-MM-DD") + "T00:00:00.000Z";
        endDate = moment(this.dateSelection.dates[1]).format("YYYY-MM-DD") + "T00:00:00.000Z";
      } else {
        startDate = moment(this.dateSelection.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
        endDate = moment(this.dateSelection.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      }

    }
    let formData: any = {
      "startDate": startDate,
      "endDate": endDate,
      "timeFrame": this.customDateSelection,//this.customDateSelection,
      "skus": this.data.asin.value ? this.data.asin.value : [],
      "sc": this.data.marketplace.value ? this.data.marketplace.value : [],
      "brands": this.data.brands.value ? this.data.brands.value : [],
      "niches": this.data.niches.value ? this.data.niches.value : [],
      "firstTimeFrameBox": {
        "startDate": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.startDate ? moment(this.presetData.firstTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.endDate ? moment(this.presetData.firstTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.timeFrame ? this.presetData.firstTimeFrameBox.timeFrame : "TODAY"
      },
      "secondTimeFrameBox": {
        "startDate": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.startDate ? moment(this.presetData.secondTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.endDate ? moment(this.presetData.secondTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.timeFrame ? this.presetData.secondTimeFrameBox.timeFrame : "LAST_7_DAYS"
      },
      "thirdTimeFrameBox": {
        "startDate": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.startDate ? moment(this.presetData.thirdTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.endDate ? moment(this.presetData.thirdTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.timeFrame ? this.presetData.thirdTimeFrameBox.timeFrame : "LAST_30_DAYS"
      }
    };
    // this.shareService.changeDate(formData);
    this.formDataDashBoard = formData;
    this.EmitformDataDashBoard.emit(formData);
    if (this.router.url == '/dashboard/orders-dashboard') {
      this.getOrderData(formData);
    } else {
      this.getprofitData(formData);
    }
    //  this.saveFilter();
    this.customDate.closeMenu();
  }
  cancel() {
    this.customDate.closeMenu();
  }
  clearAll() {
    // this.userService.loaderObesrver.next(false);
    // this.customDateSelection = 'LAST_30_DAYS';
    // this.dateSelection = null;
    this.productForm.reset();
    this.asinarr = [];
    this.products.map(item => {
      item.checked = false;
    });
  }

  sortData(sort: Sort) {
    const data = this.products.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'defaultName': return compare(a.defaultName, b.defaultName, isAsc);
        case 'asin': return compare(a.asin, b.asin, isAsc);
        case 'sku': return compare(a.sku, b.sku, isAsc);
        default: return 0;
      }
    });
    this.products = this.sortedData;
  }

  public clearFilters(): void {
    this.searchFilters = {
      defaultName: '',
      asin: '',
      sku: ''
    };
    this.checkFilters();
  }

  public checkFilters(): void {
    this.products = JSON.parse(JSON.stringify(this.defaultList));
    if (this.searchFilters.defaultName.length > 0) {
      this.liveSearchName();
    }
    if (this.searchFilters.asin.length > 0) {
      this.liveSearchAsin();
    }
    if (this.searchFilters.sku.length > 0) {
      this.liveSearchSku();
    }
  }

  public liveSearchName() {
    this.products = this.products.filter((val) => val['defaultName'].toLowerCase().match(this.searchFilters.defaultName.toLowerCase()))
  }

  public liveSearchAsin() {
    this.products = this.products.filter((val) => val['asin'].toLowerCase().match(this.searchFilters.asin.toLowerCase()))
  }

  public liveSearchSku() {
    this.products = this.products.filter((val) => val['sku'].toLowerCase().match(this.searchFilters.sku.toLowerCase()))
  }

  saveCurrentCheckbox(product) {
    product.checked = !product.checked;
    this.products.map(item => {
      if (item.sku == product.sku) {
        if (item.checked) {
          this.asinarr.push(product.sku);
          if (this.products.length == this.asinarr.length) {
            this.productForm.patchValue({
              checkAll: true,
            });
          }
        } else {
          this.asinarr.forEach((item, index) => {
            if (item === product.sku) {
              this.asinarr.splice(index, 1);
            }
          });
          if (this.products.length != this.asinarr.length) {
            this.productForm.patchValue({
              checkAll: false,
            });
          }
        }
        this.productForm.patchValue({
          asin: this.asinarr,
        });
      }
    });
  }

  saveFilter() {
    this.customDate.closeMenu();
    this.userService.loaderObesrver.next(true);
    let startDate = '';
    let endDate = '';
    if (this.selectedVal == 'LAST_7_DAYS') {
      startDate = moment().subtract(7, "days").format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
    } else if (this.selectedVal == 'LAST_30_DAYS') {
      startDate = moment().subtract(29, "days").format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
    } else if (this.selectedVal == 'TODAY') {
      startDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
    } else if (this.selectedVal == 'YESTERDAY' || this.selectedVal == 'MONTH_TO_DATE' || this.selectedVal == 'LAST_MONTH' || this.selectedVal == 'YEAR_TO_DATE' || this.selectedVal == 'LAST_YEAR') {
      startDate = moment(this.dateSelection.dates[0]).format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment(this.dateSelection.dates[1]).format("YYYY-MM-DD") + "T00:00:00.000Z";
    } else if (this.selectedVal == 'CUSTOM') {
      startDate = moment(this.dateSelection.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      endDate = moment(this.dateSelection.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
    }
    this.customDateSelection = this.selectedVal;
    let formData1: any = {
      "startDate": startDate,
      "endDate": endDate,
      "timeFrame": this.customDateSelection,
      "skus": this.data.asin.value ? this.data.asin.value : [],
      "sc": this.data.marketplace.value ? this.data.marketplace.value : [],
      "brands": this.data.brands.value ? this.data.brands.value : [],
      "niches": this.data.niches.value ? this.data.niches.value : [],
    };
    this.formDataDashBoard = formData1;
    this.EmitformDataDashBoard.emit(formData1);
    this.updatePreset(formData1);
    this.userService.saveFilterData(formData1).subscribe((res: any) => {
      if (!res.isSuccess) {
        this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
      }
      this.userService.loaderObesrver.next(false);
    });
  }

  updatePreset(formData) {
    let userData = JSON.parse(JSON.stringify(this.storageBrowser.get('$UserToken$user')));
    if (userData.preset) {
      let presetData = JSON.parse(userData.preset);
      presetData.startDate = moment(formData.startDate).utc().format("MMMM D, YYYY h:mm:ss A");
      presetData.endDate = moment(formData.endDate).utc().format("MMMM D, YYYY h:mm:ss A");
      presetData.timeFrame = formData.timeFrame;
      presetData.skus = formData.skus;
      presetData.sc = formData.sc;
      presetData.brands = formData.brands;
      presetData.niches = formData.niches;
      this.userService.currentUser.preset = JSON.stringify(presetData);
      userData.preset = JSON.stringify(presetData);
      this.storageBrowser.set('$UserToken$user', userData);
    } else {
      this.userService.currentUser.preset = JSON.stringify(formData);
      userData.preset = JSON.stringify(formData);
      this.storageBrowser.set('$UserToken$user', userData);
    }
  }

  oneDay() {
    this.userService.loaderObesrver.next(true);
    let formData1: any = {
      "startDate": moment().format("YYYY-MM-DD") + "T00:00:00.000Z",
      "endDate": moment().format("YYYY-MM-DD") + "T00:00:00.000Z",
      "timeFrame": 'TODAY',
      "skus": this.data.asin.value ? this.data.asin.value : [],
      "sc": this.data.marketplace.value ? this.data.marketplace.value : [],
      "brands": this.data.brands.value ? this.data.brands.value : [],
      "niches": this.data.niches.value ? this.data.niches.value : [],
      "firstTimeFrameBox": {
        "startDate": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.startDate ? moment(this.presetData.firstTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.endDate ? moment(this.presetData.firstTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.timeFrame ? this.presetData.firstTimeFrameBox.timeFrame : "TODAY"
      },
      "secondTimeFrameBox": {
        "startDate": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.startDate ? moment(this.presetData.secondTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.endDate ? moment(this.presetData.secondTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.timeFrame ? this.presetData.secondTimeFrameBox.timeFrame : "LAST_7_DAYS"
      },
      "thirdTimeFrameBox": {
        "startDate": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.startDate ? moment(this.presetData.thirdTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.endDate ? moment(this.presetData.thirdTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.timeFrame ? this.presetData.thirdTimeFrameBox.timeFrame : "LAST_30_DAYS"
      }
    };
    this.formDataDashBoard = formData1;

    this.EmitformDataDashBoard.emit(formData1);
    this.getProductData(formData1);
    if (this.router.url == '/dashboard/orders-dashboard') {
      this.getOrderData(formData1);
    } else {
      this.getprofitData(formData1);
    }
  }
  onMonth() {
    this.userService.loaderObesrver.next(true);
    let formData1: any = {
      "startDate": moment().subtract(29, "days").format("YYYY-MM-DD") + "T00:00:00.000Z",
      "endDate": moment().format("YYYY-MM-DD") + "T00:00:00.000Z",
      "timeFrame": this.customDateSelection,
      "skus": this.data.asin.value ? this.data.asin.value : [],
      "sc": this.data.marketplace.value ? this.data.marketplace.value : [],
      "brands": this.data.brands.value ? this.data.brands.value : [],
      "niches": this.data.niches.value ? this.data.niches.value : [],
      "firstTimeFrameBox": {
        "startDate": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.startDate ? moment(this.presetData.firstTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.endDate ? moment(this.presetData.firstTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.firstTimeFrameBox && this.presetData.firstTimeFrameBox.timeFrame ? this.presetData.firstTimeFrameBox.timeFrame : "TODAY"
      },
      "secondTimeFrameBox": {
        "startDate": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.startDate ? moment(this.presetData.secondTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.endDate ? moment(this.presetData.secondTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.secondTimeFrameBox && this.presetData.secondTimeFrameBox.timeFrame ? this.presetData.secondTimeFrameBox.timeFrame : "LAST_7_DAYS"
      },
      "thirdTimeFrameBox": {
        "startDate": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.startDate ? moment(this.presetData.thirdTimeFrameBox.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "endDate": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.endDate ? moment(this.presetData.thirdTimeFrameBox.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
        "timeFrame": this.presetData && this.presetData.thirdTimeFrameBox && this.presetData.thirdTimeFrameBox.timeFrame ? this.presetData.thirdTimeFrameBox.timeFrame : "LAST_30_DAYS"
      }
    };
    // this.shareService.changeDate(formData);
    this.formDataDashBoard = formData1;

    this.EmitformDataDashBoard.emit(formData1);
    this.getProductData(formData1);
    if (this.router.url == '/dashboard/orders-dashboard') {
      this.getOrderData(formData1);
    } else {
      this.getprofitData(formData1);
    }
  }
  getprofitData(formData) {
    formData.yearlyCompare = this.yearlyCompareValue;
    if (this.storageBrowser.get('$UserToken$user')) {
      this.userService.getProfitDashboardData(formData).subscribe((res: any) => {
        if (res) {
          this.userService.loaderObesrver.next(false);
          this.shareService.changeData('');
          setTimeout(() => {
            this.shareService.changeData(res);
            if (res.realTimeData) {
              // this.lastUpdated = res.realTimeData.lastUpdated;
            }

          });
        } else {
        }
      }, (err) => {
      });
    }
  }
  getOrderData(formData) {
    if (this.storageBrowser.get('$UserToken$user')) {
      this.userService.orderDashboard(formData).subscribe(res => {
        if (res) {
          this.userService.loaderObesrver.next(false);
          this.shareService.changeOrderData('');
          setTimeout(() => {
            this.shareService.changeOrderData(res);
          });
        } else {
        }
      });
    }
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
} 
