import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class ShareService {
  private dataSource = new BehaviorSubject("");
  private orderDataSource = new BehaviorSubject("");
  private dateSource = new BehaviorSubject("");
  currentData = this.dataSource.asObservable();
  currentOrderData = this.orderDataSource.asObservable();
  currentDate = this.dateSource.asObservable();
  constructor() { }

  changeData(newData: any) {
    this.dataSource.next(newData);
  }

  changeOrderData(newData: any) {
    this.orderDataSource.next(newData);
  }

  changeDate(newDate: any) {
    this.dateSource.next(newDate);
  }


}