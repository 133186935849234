import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../services/user.service';
import { ConstService } from '../../../../services/const-service';
import { Functions } from '../../../../services/functions';
import { FormBuilder, Validators } from '@angular/forms';
import { SelectSpecificProductsDialogComponent } from '../../../dialogs/select-specific-products-dialog/select-specific-products.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { TextService } from '../../../../services/text.service';
import { OrdersService } from '../../../../services/orders.service';
import { adAccount } from '../../../../models/BaseModels';
import { MoneyBackDialogComponent } from '../../../dialogs/money-back-dialog/money-back-dialog.component';
import { CreateAudienceDialogComponent } from '../../../dialogs/create-audience-dialog/create-audience-dialog.component';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
    selector: 'ninja-download-report',
    templateUrl: './download-report.component.html',
    styleUrls: ['./download-report.component.scss']
})

export class DownloadReportComponent implements OnInit {
    public refundStatusOptions = [
        { value: 'IGNORE_REFUNDS', viewValue: 'Ignore refunds status' },
        { value: 'EXCLUDE_REFUNDS', viewValue: 'Exclude refunds' },
        { value: 'ONLY_REFUNDS', viewValue: 'Only refunds' },
    ];
    public repeatBuyersOptions = [
        { value: 'IGNORE_REPEATED_BUYERS', viewValue: 'Ignore status' },
        { value: 'EXCLUDE_REPEATED_BUYERS', viewValue: 'Exclude repeated' },
        { value: 'ONLY_REPEATED_BUYERS', viewValue: 'Only repeated' },
    ];
    public negativeFeedbackOptions = [
        { value: 'IGNORE_NEGATIVE_FEEDBACK', viewValue: 'Ignore status' },
        { value: 'EXCLUDE_NEGATIVE_FEEDBACK', viewValue: 'Exclude negative feedback' },
    ];
    public promotionStatusOptions = [
        { value: true, viewValue: 'Ignore promotion status' },
        { value: false, viewValue: 'Exclude promotions' },
    ];
    public fulfillmentChannel = [
        { viewValue: 'Merchant', value: true },
        { viewValue: 'FBA', value: false },
    ];
    public filters: any;
    @ViewChild('dateFrom', { static: false }) dateFrom;
    @ViewChild('dateTo', { static: false }) dateTo;
    public startDate: any;
    public endDate: any;
    public minDate: any;
    public page: any;
    public loader = false;
    public errors: any = {};
    public dateError = 'Required';
    public showProductTooltip: any;
    public adAccount: any
    public selectedCustomAudience: any;
    public showUploadAudience: boolean;
    public adAccounts: Array<adAccount>;
    public audienceList: any;
    public isAdUploading: boolean;
    public isAudienceLoading: boolean;
    public grantAccessUrl: any;
    //
    public downloadForm = this.fb.group({
        dateFrom: ['',
            [Validators.required]],
        dateTo: ['',
            [Validators.required]],
    });
    public audienceForm = this.fb.group({
        adAccount: ['', [Validators.required]],
        audience: ['', [Validators.required]],
    });

    constructor(public router: Router,
        public userService: UserService,
        public ordesService: OrdersService,
        public constService: ConstService,
        public functions: Functions,
        public textService: TextService,
        public matDialog: MatDialog,
        public ref: ChangeDetectorRef,
        public activatedRoute: ActivatedRoute,
        public fb: FormBuilder, public mixpanelService: MixpanelService) {
        this.mixpanelService.init(null);
        this.setDefaultFilters();
        this.activatedRoute.queryParams.subscribe(params => {
            this.page = params.page;
        });
        this.minDate = new Date();
        this.minDate.setMonth(this.minDate.getMonth() - 1);
    }


    public ngOnInit(): void {
        // this.checkAccess();
        //this.userService.loaderObesrver.next(true);
    }

    public setDefaultFilters(): void {
        this.filters = {
            refundStatus: 'IGNORE_REFUNDS',
            repeatedBuyers: 'IGNORE_REPEATED_BUYERS',
            negativeFeedback: 'IGNORE_NEGATIVE_FEEDBACK',
            salesChannel: '',
            withFBM: false,
            promotionStatus: true,
            allProducts: true,
            currency: this.userService.currentUser.currency || 'USD',
            isFull: true,
        };
    }

    public showSpecProducts(): void {
        if (!this.filters.allProducts) {
            this.matDialog.open(SelectSpecificProductsDialogComponent, {
                data: {
                    'products': this.filters.products
                }
            }).afterClosed().subscribe(result => {
                this.filters.products = result || [];
                if (result.length > 0) {
                    this.filters.allProducts = '';
                    this.filters.placeholder = 'Specific' + ' (' + result.length + ')';
                } else {
                    this.filters.allProducts = true;
                }
            });
        }
    }

    public checkForm(): void {
        if (!this.downloadForm.valid) {
            this.displayError();
        } else {
            this.download();
        }
    }

    private displayError(): void {
        if (!this.downloadForm.controls.dateFrom.valid) {
            this.errors.dateFrom = true;
            this.resetErrorState();
            return;
        }
        if (!this.downloadForm.controls.dateTo.valid) {
            this.errors.dateTo = true;
            this.resetErrorState();
            return;
        }
    }

    private resetErrorState(): void {
        setTimeout(() => {
            if (!this.downloadForm.controls.dateFrom.valid) {
                this.errors.dateFrom = false;
            }
            if (!this.downloadForm.controls.dateTo.valid) {
                this.errors.dateTo = false;
            }
        }, 2000);
    }

    private displayAudienceError(): void {
        if (!this.audienceForm.controls.adAccount.valid) {
            this.errors.adAccount = true;
            this.resetAudeienceErrorState();
            return;
        }
        if (!this.audienceForm.controls.audience.valid) {
            this.errors.audience = true;
            this.resetAudeienceErrorState();
            return;
        }
    }

    private resetAudeienceErrorState(): void {
        setTimeout(() => {
            if (!this.audienceForm.controls.adAccount.valid) {
                this.errors.adAccount = false;
            }
            if (!this.audienceForm.controls.audience.valid) {
                this.errors.audience = false;
            }
        }, 2000);
    }


    public showFailDownloadDialog(): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'download-report-noresults',
            },
        }).afterClosed().subscribe();
    }

    public showGrantAccessDialog(url): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'order-fb-acceess',
            },
        }).afterClosed().subscribe(result => {
            if (result) {
                window.location.href = url;
            }
        });
    }

    public download() {
        if (!this.downloadForm.valid) {
            this.displayError();
            return;
        }
        this.loader = true;
        const dataToSend = {
            publicId: this.userService.currentUser.id,
            products: this.filters.products,
            from: `${this.startDate.getFullYear()}-${this.startDate.getMonth() + 1}-${this.startDate.getDate()}`,
            to: `${this.endDate.getFullYear()}-${this.endDate.getMonth() + 1}-${this.endDate.getDate()}`,
            refundStatus: this.filters.refundStatus,
            repeatedBuyers: this.filters.repeatedBuyers,
            withFBM: this.filters.withFBM,
            negativeFeedback: this.filters.negativeFeedback,
            salesChannel: this.filters.salesChannel,
            withPromotions: this.filters.promotionStatus,
            spentMoney: this.filters.spentMoney,
            currency: this.filters.currency,
            isFull: this.filters.isFull
        };
        if (this.filters.withFBM == false) {
            this.ordesService.createReport(dataToSend).subscribe((data) => {
                if (data.isSuccess === false) {
                    this.showFailDownloadDialog();
                }
                if (data.isSuccess === true) {
                    this.downloadFile('orders/download_report');
                }
                this.loader = false;
            });
        } else {
            this.ordesService.createFBMReport(dataToSend).subscribe((data) => {
                if (data.isSuccess === false) {
                    this.showFailDownloadDialog();
                }
                if (data.isSuccess === true) {
                    this.downloadFile('orders/download_fbm_report');
                }
                this.loader = false;
            });
        }
        this.filters.products = [];
    }


    public getCustomAudience(): void {
        this.isAudienceLoading = true;
        this.ordesService.getCustomAudience(
            this.userService.currentUser.id,
            this.adAccount.userToken,
            this.adAccount.adAccountId)
            .subscribe(audienceData => {
                this.isAudienceLoading = false;
                this.audienceList = audienceData;
                if (this.audienceList.length > 0) {
                    this.selectedCustomAudience = this.audienceList[0].customAudienceId;
                }
            });
    }

    public downloadFile(path) {
        const file_path = `${this.userService._apiEndpoint}` + path;
        const a: HTMLElement = document.createElement('A') as HTMLElement;
        a['href'] = file_path;
        a['download'] = file_path.substr(file_path.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // window.open(this.userService._apiEndpoint + 'orders/download_report', '_blank');
        // this.loader = false;
    }

    public setAudienceSelectStyling() {
        const select = document.getElementsByClassName('audience') as HTMLCollectionOf<HTMLElement>;
        select[0].style.minHeight = this.audienceList.length >= 9 ? '200px' : this.audienceList.length * 22 + 'px';
    }


    public checkAccess(): void {
        this.ordesService.checkAccess(this.userService.currentUser.id)
            .subscribe(accessInfo => {
                if (!accessInfo.isSuccess) {
                    this.grantAccessUrl = accessInfo.response;
                    this.showGrantAccessDialog(accessInfo.response);
                }
                if (accessInfo.isSuccess) {
                    this.showUploadAudience = true;
                    this.adAccounts = accessInfo.adAccounts;
                }
            });
    }

    public showOnFbReviewDialog(): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'custom',
                'title': this.textService.currentText.warning,
                'message': this.textService.currentText.onFbReview
            },
        }).afterClosed().subscribe();
    }

    public showCreateAudienceDialog(): void {
        this.matDialog.open(CreateAudienceDialogComponent).afterClosed().subscribe((result) => {
            if (result) {
                this.ordesService
                    .createCustomAudience(this.userService.currentUser.id,
                        this.adAccount.userToken,
                        this.adAccount.adAccountId,
                        result.name,
                        result.description)
                    .subscribe(res => {
                        if (!res.isSuccess) {
                            this.showCustomDialog(res.messageTitle, res.message);
                        } else if (res.customAudienceId) {
                            this.audienceList.unshift({
                                customAudienceId: res.customAudienceId,
                                customAudienceName: result.name
                            });
                            this.selectedCustomAudience = res.customAudienceId;
                        }
                    });
            }
        });
    }

    public showCustomDialog(title, message): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'custom',
                'title': title,
                'message': message
            },
        }).afterClosed().subscribe();
    }

    public uploadToFb(): void {
        if (!this.downloadForm.valid) {
            this.displayError();
            return;
        }
        if (!this.audienceForm.valid) {
            this.displayAudienceError();
            return;
        }
        const dataToUpload = {
            publicId: this.userService.currentUser.id,
            products: this.filters.products,
            from: `${this.startDate.getFullYear()}-${this.startDate.getMonth() + 1}-${this.startDate.getDate()}`,
            to: `${this.endDate.getFullYear()}-${this.endDate.getMonth() + 1}-${this.endDate.getDate()}`,
            refundStatus: this.filters.refundStatus,
            repeatedBuyers: this.filters.repeatedBuyers,
            negativeFeedback: this.filters.negativeFeedback,
            withFBM: this.filters.withFBM,
            salesChannel: this.filters.salesChannel,
            withPromotions: this.filters.promotionStatus,
            spentMoney: this.filters.spentMoney,
            currency: this.filters.currency,
            isFull: this.filters.isFull,
            audienceId: this.selectedCustomAudience,
            userToken: this.adAccount.userToken
        };
        this.isAdUploading = true;
        this.ordesService.uploadCustomAudience(dataToUpload).subscribe(() => {
            this.isAdUploading = false;
            this.userService.showNotification = true;
            this.userService.notificationMessage = this.textService.currentText.uploadToFbSuccess;
            this.userService.notificationTitle = this.textService.currentText.success;
        });
    }

    test(audienceList) {
        console.log(audienceList);
    }
}
