import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'ninja-faq',
  templateUrl: './FAQ.component.html',
  styleUrls: ['./FAQ.component.scss']
})

export class FAQComponent implements OnInit {

  constructor( public userService: UserService,
                public activatedRoute: ActivatedRoute,
                public router: Router) {}

  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((data) => {
      this.scrollTo(data.hash);
    });
  }

  public scrollTo(hash) {
    location.hash = '#' + hash;
  }
}
