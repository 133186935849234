<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-header" [class.fullWidth]="currentTemplates?.list?.length <= 5">
    <div class="head-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-7">
            <div class="head-container--inner">
              <h2>{{textService.currentText.bulkTitle}}</h2>
              <div class="opt-tooltip" (click)="bulkWarning()">
                <span>?</span>
              </div>
            </div>
            <div class="sub-head-container">
              <p>
                {{textService.currentText.bulkSubTitle}}
              </p>
              <p>
                {{textService.currentText.bulkSubLimit1}} {{bulkEmailLimit || '500' }}
                {{textService.currentText.bulkSubLimit2}}
                <span class="email" (click)="showContactUs()">{{textService.currentText.contactUs}}</span>
              </p>
            </div>
          </div>
          <div class="col-md-5 d-flex align-items-center justify-content-center justify-content-md-end mt-4 mt-md-0">
            <button mat-button mat-raised-button class="secondary-btn"
              (click)="mixpanelService.track('q automated messaging - create bulk clicked');googleAnalyticsEventsService.emitEvent('Button', 'Create New Bulk', 'Click', 1);createNewBulk()">
              {{textService.currentText.createNewBulk}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-container">
    <div class="filters-container">
      <div class="a-side">
        <div class="btn-container" (click)="isFilters = !isFilters">
          <button mat-button mat-raised-button class="default-btn filter-btn" type="button" [class.active]="isFilters">
            {{textService.currentText.filters}}
          </button>
        </div>
        <form class="filters bulk-filters custom-selects" [class.active]="isFilters">
          <div class="select-holder">
            <div (click)="functions.setDropDownPosition(timeFrame, 'days')" #timeFrame class="select-wrapper"
              [class.active]="timeFrameSelect.panelOpen">
              <mat-form-field>
                <mat-select disableOptionCentering [placeholder]="textService.currentText.status" shouldPlaceholderFloat="false"
                  panelClass="default-dropdown days" [(ngModel)]="filters.status" #timeFrameSelect disableRipple name="days"
                  (selectionChange)="checkFilters()">
                  <mat-option *ngFor="let select of constService.selectsBulkStatus" [value]="select.value">
                    {{ select.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="select-holder">
            <div (click)="functions.setDropDownPosition(event, 'event')" #event>
              <mat-form-field>
                <mat-select disableOptionCentering [placeholder]="textService.currentText.template" shouldPlaceholderFloat="false"
                  panelClass="default-dropdown event" [(ngModel)]="filters.templateSelected" disableRipple name="events"
                  (selectionChange)="checkFilters()">
                  <mat-option *ngFor="let select of bulkTemplates" [value]="select.templatename">
                    {{ select.templatename }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="select-holder datepicker">
            <div (click)="functions.setDropDownPosition(Status, 'status')" #Status>
              <mat-form-field>
                <input matInput [matDatepicker]="picker" [(ngModel)]="dateFrom" name="dateFrom" [max]="dateTo" (click)="picker.open()"
                  (keydown)="$event.preventDefault()" (dateChange)="checkFilters();" [placeholder]="textService.currentText.from">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="select-holder datepicker">
            <div (click)="functions.setDropDownPosition(SalesChannel, 'salesChannel')" #SalesChannel>
              <mat-form-field>
                <input matInput [matDatepicker]="picker2" [min]="dateFrom" [(ngModel)]="dateTo" (click)="picker2.open()"
                  (keydown)="$event.preventDefault()" (dateChange)="checkFilters()" [placeholder]="textService.currentText.to" name="dateTo">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker2></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="input-wrapper">
            <mat-form-field>
              <input matInput [placeholder]="textService.currentText.bulkName" [(ngModel)]="filters.name" name="name" (keyup)="checkFilters()">
            </mat-form-field>
            <div class="clear-input" *ngIf="filters.name" (click)="filters.name = ''; checkFilters()"></div>
          </div>
          <button mat-button mat-raised-button class="default-btn clear-btn new-primary-btn" type="button" (click)="clearFilter()">
            {{textService.currentText.clearAll}}
          </button>
        </form>
      </div>
      <div class="b-side">
      </div>
    </div>
    <div class="table-container">
      <div class="table-wrapper table-wrapper--not-found">
        <div class="table-header-wrapper">
          <div class="table-header bulk-header" [class.full-width]="currentTemplates?.list?.length < 8">
            <div class="td"></div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'status')">
              {{textService.currentText.status}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'name', true)">
              {{textService.currentText.bulkName}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'template')">
              {{textService.currentText.template}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'from')">
              {{textService.currentText.from}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'to')">{{textService.currentText.to}}
            </div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'emailssent')">
              {{textService.currentText.email}} {{textService.currentText.sent}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'estimated')">
              {{textService.currentText.email}} {{textService.currentText.estimated}}</div>
          </div>
        </div>
        <div class="table-holder table-holder--bulk">
          <div class="table bulk-table">
            <div class="tr" *ngFor="let bulk of currentTemplates?.list" (click)="editBulk(bulk); $event.stopPropagation()">
              <div class="td">
                <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"></button>
                <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                  <button mat-menu-item (click)="editBulk(bulk);">
                    <div class="list-inner-container">
                      <div class="menu-icon icon-edit"></div>
                      {{textService.currentText.edit}}
                    </div>
                  </button>
                  <button mat-menu-item [disabled]="bulk.status === 'PROCESSING'" (click)="deleteConfirm(bulk.bulkid)">
                    <div class="list-inner-container">
                      <div class="menu-icon icon-delete"></div>
                      {{textService.currentText.delete}}
                    </div>
                  </button>
                </mat-menu>
              </div>
              <div class="td">{{bulk.status}}</div>
              <div class="td">{{bulk.name}}</div>
              <div class="td">{{bulk.messages}}</div>
              <div class="td">{{bulk.from}}</div>
              <div class="td">{{bulk.to}}</div>
              <div class="td">{{bulk.emailssent}}</div>
              <div class="td">{{bulk.estimated || 0}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-container" *ngIf="currentTemplates?.list?.length <= 0">
      <div class="table-wrapper">
        <div class="table-holder">
          <div class="table">
            <div class="tr">
              <div class="td not-found">
                {{textService.currentText.noDataFound}}.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ninja-notification [active]="userService.showNotification" [message]="notificationMessage" [title]="notificationTitle">
</ninja-notification>