<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
    <div class="dashboard-container">
        <div class="dashboard-header">
            <div class="head-container">
                <h2>{{textService.currentText.searchAsinsByKeywords}}</h2>
                <button *ngIf="currentAsins?.length < 20" mat-button
                        mat-raised-button
                        (click)="openAddAsinDialog()"
                        class="primary-btn">
                    {{textService.currentText.add}}
                </button>
            </div>
        </div>
        <div class="table-container">
            <div class="table-wrapper">
                <div class="table-header-wrapper">
                    <div class="table-header" [class.fullWidth]="currentAsins?.length < 5">
                        <div class="td"></div>
                        <div class="td"></div>
                        <div class="td" (click)="functions.sortColumns(currentAsins, 'spot')">
                            {{textService.currentText.tableHeaders.spot}}
                        </div>
                        <div class="td" (click)="functions.sortColumns(currentAsins, 'keyword', true)">
                            {{textService.currentText.keyword}}
                        </div>
                        <div class="td" (click)="functions.sortColumns(currentAsins, 'page')">
                            {{textService.currentText.tableHeaders.page}}
                        </div>
                        <div class="td" (click)="functions.sortColumns(currentAsins, 'asin')">
                            {{textService.currentText.asin}}
                        </div>
                        <div class="td" (click)="functions.sortColumns(currentAsins, 'marketPlace')">
                            {{textService.currentText.tableHeaders.marketPlace}}
                        </div>
                        <div class="td" (click)="functions.sortColumns(currentAsins, 'lastChecked')">
                            {{textService.currentText.date}}
                        </div>
                    </div>
                </div>
                <div class="table-holder" *ngIf="currentAsins?.length > 0">
                    <div class="table">
                        <ng-container *ngFor="let asin of currentAsins">
                            <div class="tr" [class.with-alt]="asin.showDetails">
                                <div class="td">
                                    <button mat-button [matMenuTriggerFor]="menu"
                                            (click)="$event.stopPropagation()"></button>
                                    <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                                        <button mat-menu-item>
                                            <div class="list-inner-container" (click)="checkAsin(asin)">
                                                <div class="menu-icon icon-copy"></div>
                                                {{textService.currentText.check}}
                                            </div>
                                        </button>
                                        <button mat-menu-item>
                                            <div class="list-inner-container" (click)="deleteAsin(asin)">
                                                <div class="menu-icon icon-delete"></div>
                                                {{textService.currentText.delete}}
                                            </div>
                                        </button>
                                    </mat-menu>
                                </div>
                                <div class="td">
                                    <img [src]="asin.image"
                                         *ngIf="asin?.image">
                                </div>
                                <div class="td">
                                    <ng-container *ngIf="asin.spot === -10">
                                        {{textService.currentText.notFound}}
                                    </ng-container>
                                    <ng-container *ngIf="asin.spot === -1">
                                        {{textService.currentText.pending}}
                                    </ng-container>
                                    <ng-container *ngIf="asin.spot !== -1 && asin.spot !== -10">
                                        {{asin.spot}}
                                    </ng-container>
                                </div>
                                <div class="td">
                                    <span [matTooltip]="asin.keyword">{{asin.keyword}}</span>
                                </div>
                                <div class="td">
                                    {{asin.page === -10 ? "Not found" : asin.page}}
                                </div>
                                <div class="td">
                                    {{asin.asin}}
                                </div>
                                <div class="td">
                                    {{asin.marketPlace}}
                                </div>
                                <div class="td relative">
                                    {{asin.lastChecked | date:'medium'}}
                                    <div class="arrow"
                                         (click)="asin.showDetails = !asin.showDetails;"
                                         [class.active]="asin.showDetails">
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="asin.showDetails">
                                <div class="tr alt">
                                    <div class="col">
                                        <div class="full-name">
                                            {{asin.productFullName}}
                                        </div>
                                        <div class="market">
                                            <img [src]="asin.marketplaceImg" alt="">
                                            <div class="market-name">
                                                <ng-container *ngIf="asin.marketPlace === 'US'">
                                                    North America
                                                </ng-container>
                                                <ng-container *ngIf="asin.marketPlace === 'EU'">
                                                    Europe
                                                </ng-container>
                                                <ng-container *ngIf="asin.marketPlace === 'JP'">
                                                    Japan
                                                </ng-container>
                                                <ng-container *ngIf="asin.marketPlace === 'AU'">
                                                    Australia
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="no-chart-data"
                                         *ngIf="asin.chartAvaliable === 'No Data'">
                                        No data
                                    </div>
                                        <ninja-asin-chart
                                            *ngIf="asin.chartAvaliable !== 'No Data'"
                                            (marketplace)="asin.marketplaceImg = $event"
                                            (isAvaliable)="asin.chartAvaliable = $event"
                                            [asin]="asin">
                                        </ninja-asin-chart>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="table-holder" *ngIf="currentAsins?.length == 0">
                    <div class="table">
                        <div class="tr">
                            <div class="td not-found">
                                {{textService.currentText.noDataFound}}.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

