import { Injectable } from '@angular/core';

@Injectable()

export class ConstService {
    constructor() {
    }

    public selectsDays = [
        { value: 0, viewValue: 'ASAP' },
        { value: 1, viewValue: 'One Day' },
        { value: 2, viewValue: 'Two Days' },
        { value: 3, viewValue: 'Three Days' },
        { value: 4, viewValue: 'Four Days' },
        { value: 5, viewValue: 'Five Days' },
        { value: 6, viewValue: 'Six Days' },
        { value: 7, viewValue: 'Seven Days' },
        { value: 8, viewValue: 'Eight Days' },
        { value: 9, viewValue: 'Nine Days' },
        { value: 10, viewValue: 'Ten Days' },
        { value: 14, viewValue: '2 Weeks' },
        { value: 21, viewValue: '3 Weeks' },
        { value: 30, viewValue: '1 Months' },
        { value: 60, viewValue: '2 Months' },
        { value: 90, viewValue: '3 Months' },
        { value: 180, viewValue: '6 Months' }
    ];

    public sequenceTrigger = [
        { value: 'Subscription' },
        { value: 'Tagged' }
    ];

    public usRegion = ['US', 'CA', 'MX'];
    public euRegion = ['UK', 'ES', 'FR', 'IT', 'DE', 'EU', 'UAE', 'SA'];
    public feRegion = ['JP', 'AU', 'SG'];
    // public jpRegion = ['JP'];
    // public auRegion = ['AU'];

    public pieChartsColors = [
        '#c5cdfb',
        '#b57ff1',
        '#f9c959',
        '#31b9b8',
        '#d8f3b2',
        '#81b3f5',
        '#5f5bf1',
        '#5dd0cc',
        '#fbc1df',
        '#f58981',
        '#7adefb',
        '#a6be8c',
        '#3d9cf2',
        '#f0effd',
        '#7a7bc2',
        '#b4deff',
        '#e6c1e3',
        '#c4aea0',
        '#a5fcfc',
        '#89cbc1',
        '#ffac8a',
        '#5d7fd7',
        '#f9c754',
        '#8ebaf6',
    ];

    public countries = [
        { value: 'US', viewValue: 'North America', image: '../../../../assets/images/united-states.svg' },
        { value: 'CA', viewValue: 'North America', image: '../../../../assets/images/united-states.svg' },
        { value: 'MX', viewValue: 'North America', image: '../../../../assets/images/united-states.svg' },
        { value: 'UAE', viewValue: 'Europe', image: '../../../../assets/images/european-union.svg' },
        { value: 'UK', viewValue: 'Europe', image: '../../../../assets/images/european-union.svg' },
        { value: 'SA', viewValue: 'Europe', image: '../../../../assets/images/european-union.svg' },
        { value: 'AU', viewValue: 'Far East', image: '../../../../assets/images/australia.svg' },
        { value: 'JP', viewValue: 'Far East', image: '../../../../assets/images/japan.svg' },
        { value: 'FE', viewValue: 'Far East', image: '../../../../assets/images/japan.svg' },
        { value: 'SG', viewValue: 'Far East', image: '../../../../assets/images/Singapore_sg.jpeg' },
        // { value: 'AU', viewValue: 'Australia', image: '../../../../assets/images/australia.svg' },
        // { value: 'JP', viewValue: 'Japan', image: '../../../../assets/images/japan.svg' },
        { value: 'IN', viewValue: 'Japan', image: '../../../../assets/images/india.svg' }
    ];

    public countriesFlags = [
        { value: 'US', region: 'North America', image: '../../../../assets/images/united-states.svg' },
        { value: 'CA', region: 'North America', image: '../../../../assets/images/flag_canada.svg' },
        { value: 'MX', region: 'North America', image: '../../../../assets/images/flag_mexico.svg' },
        { value: 'UK', region: 'Europe', image: '../../../../assets/images/flag_uk.svg' },
        { value: 'ES', region: 'Europe', image: '../../../../assets/images/flag_spain.svg' },
        { value: 'DE', region: 'Europe', image: '../../../../assets/images/flag_germany.svg' },
        { value: 'FR', region: 'Europe', image: '../../../../assets/images/flag_france.svg' },
        { value: 'IT', region: 'Europe', image: '../../../../assets/images/flag_italy.svg' },
        { value: 'AU', region: 'Far East', image: '../../../../assets/images/australia.svg' },
        { value: 'JP', region: 'Far East', image: '../../../../assets/images/japan.svg' },
        { value: 'SG', region: 'Far East', image: '../../../../assets/images/Singapore_sg.jpeg' },
        // { value: 'AU', region: 'Australia', image: '../../../../assets/images/australia.svg' },
        // { value: 'JP', region: 'Japan', image: '../../../../assets/images/japan.svg' },
        { value: 'IN', region: 'india', image: '../../../../assets/images/india.svg' }
    ];

    public arrayRegions = [
        'ES',
        'FR',
        'IT',
        'DE',
        'EU',
        'UK'
    ];

    public triggersBy = [
        { value: 'Get Started', viewValue: 'POST_BACK' },
        { value: 'Specific text', viewValue: 'TEXT' },
        { value: 'Default Text', viewValue: 'DEFAULT_TEXT' },
        { value: 'Payload', viewValue: 'PAYLOAD' }
    ];

    public regions = [];

    public coupons = [
        { value: 'iphoneCase', viewValue: 'iphone Case' },
        { value: 'speakers', viewValue: 'speakers' },
        { value: 'testLast', viewValue: 'testLast' },
        { value: 'baba', viewValue: 'baba' },
        { value: 'bluetooth_speaker_coupons', viewValue: 'My bluetooth speaker coupons' },
        { value: 'last_coupon', viewValue: 'last coupon' }
    ];

    public promotionValues = [
        { value: 'GREATER', viewValue: '>' },
        { value: 'LESS', viewValue: '<' },
        { value: 'EQUALS', viewValue: '=' }
    ];

    public salesChannels = [{
        value: '',
        viewValue: 'All Channels'
    },
    {
        value: 'Amazon.com',
        viewValue: 'Amazon.com',
        dbShortName: 'shortNameCOM',
        image: '../../../../assets/images/united-states.svg'
    },
    {
        value: 'Amazon.ca',
        viewValue: 'Amazon.ca',
        dbShortName: 'shortNameCA',
        image: '../../../../assets/images/flag_canada.svg'
    },
    {
        value: 'Amazon.com.mx',
        viewValue: 'Amazon.com.mx',
        dbShortName: 'shortNameMX',
        image: '../../../../assets/images/flag_mexico.svg'
    },
    {
        value: 'Amazon.com.tr',
        viewValue: 'Amazon.com.tr',
        dbShortName: '',
        image: '../../../../assets/images/tr.png'
    },
    {
        value: 'Amazon.nl',
        viewValue: 'Amazon.nl',
        dbShortName: '',
        image: '../../../../assets/images/nl.png'
    },
    {
        value: 'Amazon.sg',
        viewValue: 'Amazon.sg',
        dbShortName: '',
        image: '../../../../assets/images/Singapore_sg.jpeg'
    },
    {
        value: 'Amazon.com.br',
        viewValue: 'Amazon.com.br',
        dbShortName: '',
        image: '../../../../assets/images/Brazil_br.jpeg'
    },
    {
        value: 'Amazon.com/Saudi-Arabia',
        viewValue: 'Amazon.com/Saudi-Arabia',
        dbShortName: '',
        image: '../../../../assets/images/sa.png'
    },
    {
        value: 'Amazon.com.au',
        viewValue: 'Amazon.com.au',
        dbShortName: 'shortNameAU',
        image: '../../../../assets/images/australia.svg'
    },
    {
        value: 'Amazon.co.uk',
        viewValue: 'Amazon.co.uk',
        dbShortName: 'shortNameUK',
        image: '../../../../assets/images/flag_uk.svg'
    },
    {
        value: 'Amazon.fr',
        viewValue: 'Amazon.fr',
        dbShortName: 'shortNameFR',
        image: '../../../../assets/images/flag_france.svg'
    },
    {
        value: 'Amazon.it',
        viewValue: 'Amazon.it',
        dbShortName: 'shortNameIT',
        image: '../../../../assets/images/flag_italy.svg'
    },
    {
        value: 'Amazon.es',
        viewValue: 'Amazon.es',
        dbShortName: 'shortNameES',
        image: '../../../../assets/images/flag_spain.svg'
    },
    {
        value: 'Amazon.de',
        viewValue: 'Amazon.de',
        dbShortName: '',
        image: '../../../../assets/images/flag_germany.svg'
    },
    {
        value: 'Amazon.co.jp',
        viewValue: 'Amazon.co.jp',
        dbShortName: 'shortNameJP',
        image: '../../../../assets/images/japan.svg'
    }
    ];

    public marketplace = [
        { value: '', viewValue: 'Marketplace' },
        { value: 'US', viewValue: 'Amazon.com' },
        { value: 'CA', viewValue: 'Amazon.ca' },
        { value: 'MX', viewValue: 'Amazon.com.mx' },
        { value: 'EU', viewValue: 'Amazon.com.tr' },
        { value: 'EU', viewValue: 'Amazon.nl' },
        { value: 'FR', viewValue: 'Amazon.sg' },
        { value: 'US', viewValue: 'Amazon.com.br' },
        { value: 'CA', viewValue: 'Amazon.com.br' },
        { value: 'MX', viewValue: 'Amazon.com.br' },
        { value: 'UK', viewValue: 'Amazon.com/Saudi-Arabia' }
    ];

    public menuActions = [
        { value: 'Subscribe' },
        { value: 'Unsubscribe' },
        { value: 'Trigger Conversation' },
    ];

    public timing = [
        { viewValue: 'Morning', value: 'MORNING' },
        { viewValue: 'Noon', value: 'NOON' },
        { viewValue: 'Evening', value: 'EVENING' },
    ];

    public products = [
        { value: true, viewValue: 'All' },
        { value: false, viewValue: 'Specific' }
    ];

    public excludeProducts = [
        { value: true, viewValue: 'None' },
        { value: false, viewValue: 'Specific' }
    ];

    public productsDashboard = [
        { value: true, viewValue: 'All Products' },
        { value: false, viewValue: 'Specific' }
    ];

    public after = [
        { value: 'ITEM_SHIPPED', viewValue: 'Item shipped' },
        { value: 'ITEM_DELIVERED', viewValue: 'Item delivered' },
        { value: 'ITEM_REFUNDED', viewValue: 'Item refunded' },
        { value: 'NEGATIVE_FEEDBACK', viewValue: 'Negative feedback' },
    ];

    public afterFBM = { value: 'ITEM_PURCHASED', viewValue: 'Item purchased' };

    public productTags = ['<product-short-name>', '<product-name>', '<product-review-link>',
        '<product-link>', '<sku>', '<asin>'];

    public ordersTags = ['<order-link>', '<order-id>', '<quantity>',
        '<price-item>', '<purchase-date>', '<tracking-number>'];

    public ordersTagsFBM = ['<order-link>', '<order-id>', '<quantity>',
        '<price-item>', '<purchase-date>'];

    public customerTags = ['<customer-first-name>', '<customer-name>',
        '<feedback-link>', '<contact-link>', '<buyer-email>'];

    public shippingTags = ['<price-shipping>', '<address1>', '<address2>',
        '<ship-city>', '<ship-state>', '<ship-zip>', '<ship-country>', '<carrier>', '<recipient>'];

    public shippingTagsFBM = ['<price-shipping>', '<address1>', '<address2>',
        '<ship-city>', '<ship-state>', '<ship-zip>', '<ship-country>', '<recipient>'];

    public variablesFBA = ['<product-short-name>', '<product-name>', '<product-review-link>',
        '<product-link>', '<sku>', '<asin>', '<product-image>', '<product-image-reviewlink>', '<order-link>', '<order-id>', '<quantity>',
        '<price-item>', '<purchase-date>', '<tracking-number>', '<customer-first-name>',
        '<customer-name>', '<feedback-link>', '<contact-link>', '<buyer-email>', '<price-shipping>',
        '<address1>', '<address2>', '<ship-city>', '<ship-state>', '<ship-zip>', '<ship-country>',
        '<carrier>', '<recipient>', '<estimated-arrival>'];

    public variablesFBM = ['<product-short-name>', '<product-name>', '<product-review-link>',
        '<product-link>', '<sku>', '<asin>', '<product-image>', '<product-image-reviewlink>', '<order-link>', '<order-id>', '<quantity>',
        '<price-item>', '<purchase-date>', '<customer-first-name>', '<customer-name>',
        '<feedback-link>', '<contact-link>', '<buyer-email>', '<price-shipping>',
        '<address1>', '<address2>', '<ship-city>', '<ship-state>', '<ship-zip>', '<ship-country>',
        '<recipient>', '<estimated-arrival>'];

    public currencies = [
        { value: 'USD', viewValue: 'USD' },
        { value: 'EUR', viewValue: 'EUR' },
        { value: 'GBP', viewValue: 'GBP' },
        { value: 'JPY', viewValue: 'JPY' },
    ];




    public allSalesChannels = {
        usaChannels: [
            { value: '', viewValue: 'All Channels' },
            { value: 'Amazon.com', viewValue: 'Amazon.com', dbShortName: 'shortNameCOM', image: '../../../../assets/images/united-states.svg' },
            { value: 'Amazon.ca', viewValue: 'Amazon.ca', dbShortName: 'shortNameCA', image: '../../../../assets/images/flag_canada.svg' },
            { value: 'Amazon.com.mx', viewValue: 'Amazon.com.mx', dbShortName: 'shortNameMX', image: '../../../../assets/images/flag_mexico.svg' },
            { value: 'Amazon.com.br', viewValue: 'Amazon.com.br', dbShortName: '', image: '../../../../assets/images/Brazil_br.jpeg' },
        ],
        euChannels: [
            { value: '', viewValue: 'All Channels' },
            { value: 'Amazon.co.uk', viewValue: 'Amazon.co.uk', dbShortName: 'shortNameUK', image: '../../../../assets/images/flag_uk.svg' },
            { value: 'Amazon.de', viewValue: 'Amazon.de', dbShortName: '', image: '../../../../assets/images/flag_germany.svg' },
            { value: 'Amazon.es', viewValue: 'Amazon.es', dbShortName: 'shortNameES', image: '../../../../assets/images/flag_spain.svg' },
            { value: 'Amazon.it', viewValue: 'Amazon.it', dbShortName: 'shortNameIT', image: '../../../../assets/images/flag_italy.svg' },
            { value: 'Amazon.fr', viewValue: 'Amazon.fr', dbShortName: 'shortNameFR', image: '../../../../assets/images/flag_france.svg' },
            { value: 'Amazon.com.tr', viewValue: 'Amazon.com.tr', dbShortName: '', image: '../../../../assets/images/tr.png' },
            { value: 'Amazon.nl', viewValue: 'Amazon.nl', dbShortName: '', image: '../../../../assets/images/nl.png' },
            { value: 'Amazon.com/Saudi-Arabia', viewValue: 'Amazon.com/Saudi-Arabia', dbShortName: '', image: '../../../../assets/images/sa.png' },
        ],
        feChannels: [
            { value: '', viewValue: 'All Channels' },
            { value: 'Amazon.co.jp', viewValue: 'Amazon.co.jp', dbShortName: 'shortNameJP', image: '../../../../assets/images/japan.svg' },
            { value: 'Amazon.sg', viewValue: 'Amazon.sg', dbShortName: '', image: '../../../../assets/images/Singapore_sg.jpeg' },
            { value: 'Amazon.com.au', viewValue: 'Amazon.com.au', dbShortName: 'shortNameAU', image: '../../../../assets/images/australia.svg' }
        ],
        // jpChannels: [
        //     { value: 'Amazon.co.jp', viewValue: 'Amazon.co.jp' },
        //     { value: 'Amazon.sg', viewValue: 'Amazon.sg' },
        // ],
        // auChannels: [
        //     { value: 'Amazon.com.au', viewValue: 'Amazon.com.au' }
        // ],
        inChannels: [
            { value: '', viewValue: 'All Channels' },
            { value: 'Amazon.in', viewValue: 'Amazon.in' }
        ],
    };

    public selectsEvents = [
        { value: 0, viewValue: 'All events' },
        { value: 'Item Shipped', viewValue: 'Item Shipped' },
        { value: 'Item Delivered', viewValue: 'Item Delivered' },
        { value: 'Item Refunded', viewValue: 'Item Refunded' },
        { value: 'Negative Feedback', viewValue: 'Negative Feedback' }
    ];

    public invoiceLanguage = [
        { value: 'HEB', viewValue: 'Hebrew' },
        { value: 'EN', viewValue: 'English' }
    ];

    public selectsStatus = [
        { value: true, viewValue: 'On' },
        { value: false, viewValue: 'Off' }
    ];

    public dashboardTimeSelect = [
        { value: 'Last 30 days', viewValue: 'Last 30 days' },
        { value: 'Default', viewValue: 'Default time frame' },
        { value: 'Today', viewValue: 'Today' },
        { value: 'Yesterday', viewValue: 'Yesterday' },
        { value: 'Week to date', viewValue: 'Week to date' },
        { value: 'Month to date', viewValue: 'Month to date' },
        { value: 'Custom', viewValue: 'Custom' },
    ];

    public selectsBulkStatus = [
        { value: 'PENDING', viewValue: 'Pending' },
        { value: 'EXECUTED', viewValue: 'Executed' },
        { value: 'NA', viewValue: 'N/A' }
    ];

    public fileImageFormats = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

    public fileFormats = ['image/jpeg', 'image/bmp', 'image/png', 'image/gif', 'image/tiff', 'image/x-icon', 'video/3gpp',
        'video/3gpp2', 'video/x-msvideo', 'video/x-flv', 'video/jpeg', 'video/x-ms-asf', 'video/mpeg', 'video/mp4', 'video/x-ms-wmv',
        'audio/x-ms-wma', 'audio/mp4', 'text/csv', 'text/html', 'text/tab-separated-values', 'text/plain', 'application/x-shockwave-flash',
        'application/pdf', 'application/vnd.ms-excel', 'application/vnd.ms-excel.addin.macroenabled.12',
        'application/vnd.ms-excel.sheet.binary.macroenabled.12', 'application/vnd.ms-excel.template.macroenabled.12',
        'application/vnd.ms-excel.sheet.macroenabled.12', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.slide',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow,',
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/vnd.ms-powerpoint', 'application/vnd.ms-powerpoint.addin.macroenabled.12',
        'application/vnd.ms-powerpoint.presentation.macroenabled.12', 'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
        'application/msword', 'application/mp21', 'application/mp4'
    ];

    public datePickerFormat = {
        parse: {
            dateInput: 'LL',
        },
        display: {
            dateInput: 'LL',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM YYYY',
        },
    };

    public regionOriginValue = {
        ATVPDKIKX0DER: 'US',
        A1F83G8C2ARO7P: 'UK',
        A2EUQ1WTGCTBG2: 'CA',
        A1PA6795UKMFR9: 'DE',
        A1RKKUPIHCS9HS: 'ES',
        APJ6JRA9NG5V4: 'IT',
        A1VC38T7YXB528: 'JP',
        A13V1IB3VIYZZH: 'FR',
        A1AM78C64UM0Y8: 'MX'
    };

    public variables = [
        { value: 'image/png' },
        { value: 'video/3gpp' },
        { value: 'video/3gpp2' },
        { value: 'application/x-shockwave-flash' },
        { value: 'application/pdf' },
        { value: 'video/x-msvideo' },
        { value: 'text/csv' },
        { value: 'video/x-flv' },
        { value: 'video/x-f4v' },
        { value: 'image/gif' },
        { value: 'video/jpeg' },
        { value: 'image/bmp' },
        { value: 'image/x-icon' },
        { value: 'video/x-ms-asf' },
        { value: 'application/vnd.ms-excel' },
        { value: 'application/vnd.ms-excel.addin.macroenabled.12' },
        { value: 'application/vnd.ms-excel.sheet.binary.macroenabled.12' },
        { value: 'application/vnd.ms-excel.template.macroenabled.12' },
        { value: 'application/vnd.ms-excel.sheet.macroenabled.12' },
        { value: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
        { value: 'application/vnd.openxmlformats-officedocument.presentationml.slide' },
        { value: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
        { value: 'application/vnd.openxmlformats-officedocument.presentationml.template' },
        { value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
        { value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template' },
        { value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
        { value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' },
        { value: 'application/vnd.ms-powerpoint' },
        { value: 'application/vnd.ms-powerpoint.addin.macroenabled.12' },
        { value: 'application/vnd.ms-powerpoint.presentation.macroenabled.12' },
        { value: 'application/vnd.ms-powerpoint.slideshow.macroenabled.12' },
        { value: 'audio/x-ms-wma' },
        { value: 'video/x-ms-wmv' },
        { value: 'application/msword' },
        { value: 'video/mpeg' },
        { value: 'application/mp21' },
        { value: 'audio/mp4' },
        { value: 'video/mp4' },
        { value: 'application/mp4' },
        { value: 'image/tiff' },
        { value: 'text/tab-separated-values' },
        { value: 'text/plain' },
        { value: 'text/html' },
        { value: 'image/jpeg' }
    ];

    public facebookNotificationsAlertFilter = [
        { value: 'On every sale', viewValue: 'On every sale' },
        { value: 'Every 5 Sales', viewValue: 'Every 5 sales' },
        { value: 'Every 10 Sales', viewValue: 'Every 10 sales' },
        { value: 'Every 20 Sales', viewValue: 'Every 20 sales' },
        { value: 'Every 50 Sales', viewValue: 'Every 50 sales' },
        { value: 'Every 100 Sales', viewValue: 'Every 100 sales' },
    ];

    public orderTypeFilter = [
        { value: '', viewValue: 'All types' },
        { value: 'refundOrders', viewValue: 'Refund orders' },
        { value: 'promotions', viewValue: 'Promotions' },
        { value: 'reviewRequest', viewValue: 'Review Request' }
    ];

    public dropdownCls = [
        'mat-select-value',
        'mat-option-text',
        'mat-input-infix',
        'mat-input-flex',
        'default-btn',
        'primary-btn',
        'dialog-footer',
        'mat-select-placeholder',
        'mat-select-arrow',
        'mat-select-arrow-wrapper',
        'mat-button-wrapper',
        'dialog-content',
        'cdk-overlay-backdrop',
        'mat-form-field-autofill-control',
        ''
    ];

    public textConversationAction = {
        active: false,
        type: 'text',
        data: {
            text: 'Welcome to my page. How are you?',
            coupon: null,
            variable: null
        }
    };

    public templateConversationAction = {
        type: 'template',
        data: [{ title: '' }],
        image: 'http://fitline-sport.ru/media/editor/2016/10/11/172544_1000.jpg'
    };

    public imageConversationAction = {
        active: false,
        type: 'image',
        data: 'http://fitline-sport.ru/media/editor/2016/10/11/172544_1000.jpg'
    };

    public actionConversationAction = {
        type: 'action',
        active: false,
        name: 'Subscribe',
        data: {
            action: ''
        }
    };

    public buttonConversationAction = {
        type: 'button',
        active: false,
        data: [
            {
                type: 'buttonEl',
                name: 'New button',
                tag: null,
                image: null,
                active: false,
                data: []
            }]
    };

    public buttonConversationSingleButton = {
        type: 'buttonEl',
        name: 'New button',
        tag: null,
        image: null,
        active: false,
        data: []
    };

    public languages = ['EN', 'ES', 'RO', 'CN'];

    public setRegions(data) {
        this.regions = [];
        const USAregion = {
            value: 'US',
            viewValue: 'North America',
            image: '../../../../assets/images/united-states.svg'
        };
        const UKregion = {
            value: 'UK',
            viewValue: 'Europe',
            image: '../../../../assets/images/european-union.svg'
        };
        const FAREregion = {
            value: 'FE',
            viewValue: 'Far East',
            image: '../../../../assets/images/japan.svg'
        };
        // const JPregion = {
        //     value: 'JP',
        //     viewValue: 'Japan',
        //     image: '../../../../assets/images/japan.svg'
        // };
        // const AUregion = {
        //     value: 'AU',
        //     viewValue: 'Australia',
        //     image: '../../../../assets/images/australia.svg'
        // };
        const INregion = {
            value: 'IN',
            viewValue: 'India',
            image: '../../../../assets/images/india.svg'
        };
        const NLregion = {
            value: 'NL',
            viewValue: 'NL Turkey Saudi - Arabia',
            image: '../../../../assets/images/Netherlands_nl.jpeg'
        };

        if (data.emails) {
            for (let i = 0; data.emails.length > i; i++) {
                switch (data.emails[i].submarket) {
                    case 'US':
                    case 'CA':
                    case 'MX':
                        if (!this.regions.includes(USAregion)) {
                            this.regions.push(USAregion);
                        }
                        break;
                    case 'UK':
                    case 'ES':
                    case 'FR':
                    case 'UAE':
                    case 'IT':
                    case 'DE':
                    case 'SA':
                    case 'EU':
                        if (!this.regions.includes(UKregion)) {
                            this.regions.push(UKregion);
                        }
                        break;
                    case 'JP':
                    case 'AU':
                    case 'SG':
                        if (!this.regions.includes(FAREregion)) {
                            this.regions.push(FAREregion);
                        }
                        break;
                    // case 'JP':
                    //     if (!this.regions.includes(JPregion)) {
                    //         this.regions.push(JPregion);
                    //     }
                    //     break;
                    // case 'AU':
                    //     if (!this.regions.includes(AUregion)) {
                    //         this.regions.push(AUregion);
                    //     }
                    //     break;
                    case 'IN':
                        if (!this.regions.includes(INregion)) {
                            this.regions.push(INregion);
                        }
                        break;
                }
            }
        }
        switch (data.marketplace) {
            case 'US':
            case 'CA':
            case 'MX':
                if (!this.regions.includes(USAregion)) {
                    this.regions.push(USAregion);
                }
                this.salesChannels = this.allSalesChannels.usaChannels;
                break;
            case 'UK':
            case 'ES':
            case 'FR':
            case 'IT':
            case 'UAE':
            case 'SA':
            case 'DE':
            case 'EU':
                if (!this.regions.includes(UKregion)) {
                    this.regions.push(UKregion);
                }
                this.salesChannels = this.allSalesChannels.euChannels;
                break;
            case 'JP':
            case 'AU':
            case 'SG':
                if (!this.regions.includes(FAREregion)) {
                    this.regions.push(FAREregion);
                }
                this.salesChannels = this.allSalesChannels.feChannels;
                break;
            // case 'JP':
            //     if (!this.regions.includes(JPregion)) {
            //         this.regions.push(JPregion);
            //     }
            //     this.salesChannels = this.allSalesChannels.jpChannels;
            //     break;
            // case 'AU':
            //     if (!this.regions.includes(AUregion)) {
            //         this.regions.push(AUregion);
            //     }
            //     this.salesChannels = this.allSalesChannels.auChannels;
            //     break;
            case 'IN':
                if (!this.regions.includes(INregion)) {
                    this.regions.push(INregion);
                }
                this.salesChannels = this.allSalesChannels.inChannels;
                break;
        }
    }
}
