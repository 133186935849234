import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { Router } from '@angular/router';
import { ConstService } from '../../../../services/const-service';
import { BulkDialogComponent } from '../../../dialogs/bulk-dialog/bulk-dialog.component';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Functions } from '../../../../services/functions';
import { TemplateService } from '../../../../services/template.service';
import { ContactUsDialogComponent } from '../../../dialogs/contact-us-dialog/contact-us-dialog.component';
import { GoogleAnalyticsEventsService } from '../../../../services/google-analytics.service';
import { TextService } from '../../../../services/text.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'ninja-fba',
  templateUrl: './bulk.component.html',
  styleUrls: ['./bulk.component.scss']
})

export class BulkComponent implements OnInit, OnDestroy {
  public user: any;
  public currentTemplates: any;
  public bulkTemplates: any;
  public filters: any;
  public notificationMessage: string;
  public notificationTitle: string;
  public dateTo = null;
  public isFilters: boolean;
  public dateFrom = null;
  public bulkEmailLimit: any;

  constructor(public userService: UserService,
    public textService: TextService,
    public router: Router,
    public matDialog: MatDialog,
    public templateService: TemplateService,
    public functions: Functions,
    public constService: ConstService,
    public el: ElementRef,
    public ref: ChangeDetectorRef,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public mixpanelService: MixpanelService) {
    this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    this.filters = {
      daySelected: '',
      eventSelected: '',
      status: '',
      name: '',
      channel: ''
    };
    this.userService.loaderObesrver.next(true);
    if (this.userService.currentUser) {
      this.templateService.getBulkLimit(this.userService.currentUser.id).subscribe((data) => {
        if (data) {
          this.bulkEmailLimit = data.emailsLimit;
        }
      });
      this.templateService.getBulkTemplates(this.userService.currentUser.id).subscribe((data) => {
        this.bulkTemplates = data;
      });
      this.user = this.userService.currentUser;
      this.user.list = [];
      this.templateService.getBulkTemplate(this.userService.currentUser.id).subscribe(
        (data) => {
          this.currentTemplates = {
            list: []
          };
          this.user.list = data.list;
          this.currentTemplates.list = data.list;
          this.currentTemplates = JSON.parse(JSON.stringify(this.user));
          this.clearFilter();
          this.userService.loaderObesrver.next(false);
        }

      );
    }
  }


  public ngOnDestroy(): void {
    this.userService.currentPage = null;
    this.userService.showNotification = false;
  }

  public createNewBulk(): void {
    this.matDialog.open(BulkDialogComponent, {
      data: {
        'dialogFor': 'new',
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.notificationMessage = 'Bulk rule successfully created';
        this.notificationTitle = 'Bulk create';
        this.userService.showNotification = true;
        this.currentTemplates.list = result;
        this.user.list = result;
      }
    });
  }

  public showContactUs(): void {
    this.matDialog.open(ContactUsDialogComponent, {
      data: {},
    }).afterClosed().subscribe((res) => {
      if (res === true) {
        this.notificationMessage = 'Your message has been sent!';
        this.notificationTitle = 'Message sent';
        this.userService.showNotification = true;
      }
    });
  }

  public editBulk(bulk: any): void {
    if (bulk.status === 'PENDING') {
      this.matDialog.open(BulkDialogComponent, {
        data: {
          'dialogFor': 'edit',
          'bulkid': bulk.bulkid
        },
      }).afterClosed().subscribe(result => {
        if (result) {
          this.notificationMessage = 'Bulk rule successfully edited';
          this.notificationTitle = 'Edit bulk';
          this.userService.showNotification = true;
          this.currentTemplates.list = result;
          this.user.list = result;
        }
      });
    } else {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'bulkEditReject-tooltip',
        },
      }).afterClosed().subscribe();
    }
  }

  public filterByDateFrom(): void {
    this.currentTemplates.list = this.currentTemplates.list.filter((data) => {
      const date = +new Date(data.from);
      return +new Date(this.dateFrom) <= date;
    });
    this.ref.detectChanges();
  }

  public filterByDateTo(): void {
    this.currentTemplates.list = this.currentTemplates.list.filter((data) => {
      const date = +new Date(data.to) - (3 * 60 * 60 * 1000);
      return +new Date(this.dateTo) >= date;
    });
    this.ref.detectChanges();
  }

  public filterByTemplate(): void {
    this.currentTemplates.list = this.currentTemplates.list.filter(this.isTemplateEnough.bind(this));
    this.ref.detectChanges();
  }

  public isTemplateEnough(value: any): boolean {
    return value.template === this.filters.templateSelected;
  }

  public filterByStatus(): void {
    this.currentTemplates.list = this.currentTemplates.list.filter(this.isStatus.bind(this));
    this.ref.detectChanges();
  }

  public isStatus(value: any): boolean {
    return value.status === this.filters.status;
  }

  public liveSearch(): void {
    this.currentTemplates.list = this.currentTemplates.list.filter(this.liveSearchCallback.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchCallback(value: any): boolean {
    return value.name.toLowerCase().indexOf(this.filters.name.toLowerCase()) !== -1;
  }

  public clearFilter(): void {
    this.filters = {
      daySelected: '',
      templateSelected: '',
      status: '',
      name: ''
    };
    this.dateTo = null;
    this.dateFrom = null;
    this.currentTemplates = JSON.parse(JSON.stringify(this.user));
  }

  public deleteTemplate(templateId: number): void {
    this.findTemplate(templateId, false, true);
    this.templateService.deleteBulkTemplate(this.user.id, templateId).subscribe((data) => {
      if (data) {
        this.notificationMessage = 'Bulk rule successfully deleted';
        this.notificationTitle = 'Delete bulk';
        this.userService.showNotification = true;
        this.currentTemplates.list = data.list;
      }
    });
  }

  public findTemplate(id: number, changeStatus: boolean, deleteTemplate: boolean): void {
    for (let i = 0; i < this.user.list.length; i++) {
      if (this.user.list[i].id === id) {
        if (changeStatus) {
          this.checkFilters();
        }
        if (deleteTemplate) {
          this.user.list.splice(i, 1);
        }
      }
    }
  }

  public checkFilters(): void {
    this.currentTemplates = JSON.parse(JSON.stringify(this.user));
    if (this.filters.status !== '') {
      this.filterByStatus();
    }
    if (this.filters.templateSelected !== '') {
      this.filterByTemplate();
    }
    if (this.dateFrom > 0) {
      this.filterByDateFrom();
    }
    if (this.dateTo > 0) {
      this.filterByDateTo();
    }
    if (this.filters.name.length > 0) {
      this.liveSearch();
    }
  }

  public deleteConfirm(id: number): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'delete-confirm'
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.deleteTemplate(id);
      } else {
        return;
      }
    });
  }

  public bulkWarning(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'bulk-tooltip',
      },
    }).afterClosed().subscribe();
  }
}
