import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Functions } from '../../../services/functions';
import { FormBuilder, Validators } from '@angular/forms';
import { TemplateService } from '../../../services/template.service';
import { UserService } from '../../../services/user.service';
import { newBulkInterface } from '../../../models/BaseModels';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TextService } from '../../../services/text.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  templateUrl: './bulk-dialog.component.html',
  styleUrls: ['./bulk-dialog.component.scss']
})
export class BulkDialogComponent implements OnInit {
  @ViewChild('name', { static: true }) name: ElementRef;
  @ViewChild('tempSelect', { static: false }) tempSelect: ElementRef;
  @ViewChild('dateTo', { static: false }) dateTo;
  @ViewChild('dateFrom', { static: false }) dateFrom;
  public emailEstimated = '';
  public templateInfo: any;
  public labelBulk: any;
  public currentTemplate: any;
  public currentProducts: any;
  public dateToError = this.textService.currentText.required;
  public templateError = this.textService.currentText.errorTemplateNotSelected;
  public templateData = [];
  public isTemplateSelected = false;
  public errors: any = {};

  public fbaForm = this.fb.group({
    name: ['',
      [Validators.required]],
    template: ['',
      [Validators.required]],
    dateFrom: ['',
      [Validators.required]],
    dateTo: ['',
      [Validators.required]],
    emailEstimated: ['']
  });

  constructor(public dialogRef: MatDialogRef<BulkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public textService: TextService,
    public functions: Functions,
    public matDialog: MatDialog,
    public templateService: TemplateService,
    public userService: UserService,
    public fb: FormBuilder,
    public mixpanelService: MixpanelService) {
    this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    this.userService.dialogLoader = true;
    if (this.data.dialogFor === 'edit') {
      this.labelBulk = this.textService.currentText.editBulk;
    } else {
      this.labelBulk = this.textService.currentText.createNewBulk;
    }
    this.templateService.getTemplatesName(this.userService.currentUser.id).subscribe((data) => {
      this.templateData = data.list;
      if (this.data.dialogFor === 'edit') {
        this.templateService.getBulkInfo(this.userService.currentUser.id, this.data.bulkid).subscribe((info) => {
          this.templateInfo = info.list;
          this.fbaForm.patchValue({
            name: info.list.name
          });
          this.fbaForm.patchValue({
            template: info.list.templateid
          });
          this.isTemplateSelected = true;
          this.currentProducts = info.list.productsskus;
          this.dateFrom = info.list.from;
          for (let i = 0; i < this.templateData.length; i++) {
            if (this.templateData[i].id === info.list.templateid) {
              this.currentTemplate = this.templateData[i];
              break;
            }
          }
          this.dateTo = info.list.to;
          this.userService.dialogLoader = false;
          if (this.templateInfo.productsskus === 'NULL' ||
            this.templateInfo.productsskus === null ||
            this.templateInfo.productsskus === '\'NULL\'' ||
            this.templateInfo.productsskus === '') {
            this.currentProducts = this.textService.currentText.anyOfYourProducts;
          } else {
            this.currentProducts = this.templateInfo.productsskus;
          }
        });
      } else {
        this.userService.dialogLoader = false;
      }
    });
  }

  private displayError(): void {
    if (!this.fbaForm.controls.name.valid) {
      this.errors.name = true;
      const old_value = this.fbaForm.value.name;
      this.fbaForm.patchValue({
        name: this.textService.currentText.errorRequired
      });
      this.fbaForm.controls['name'].disable();
      this.name.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.fbaForm.controls.template.valid && this.data.dialogFor === 'new') {
      this.errors.template = true;
      this.resetErrorState('');
      return;
    }
    if (!this.fbaForm.controls.dateFrom.valid) {
      this.errors.dateFrom = true;
      this.resetErrorState('');
      return;
    }
    if (!this.fbaForm.controls.dateTo.valid) {
      this.errors.dateTo = true;
      this.resetErrorState('');
      return;
    }
  }

  public checkForm(): void {
    if (!this.fbaForm.valid) {
      this.displayError();
    } else {
      let bulk: newBulkInterface;
      if (this.data.dialogFor === 'edit') {
        bulk = {
          publicId: this.userService.currentUser.id,
          templateId: this.currentTemplate.id,
          from: this.dateFrom,
          to: this.dateTo,
          bulkName: this.fbaForm.controls.name.value,
          isNew: false,
          bulkId: this.templateInfo.bulkid
        };
      } else {
        bulk = {
          publicId: this.userService.currentUser.id,
          templateId: this.currentTemplate.id,
          from: `${this.dateFrom.getFullYear()}-${this.dateFrom.getMonth() + 1}-${this.dateFrom.getDate()}`,
          to: `${this.dateTo.getFullYear()}-${this.dateTo.getMonth() + 1}-${this.dateTo.getDate()}`,
          bulkName: this.fbaForm.controls.name.value,
          isNew: true,
        };
      }
      this.userService.dialogLoader = true;
      this.templateService.saveBulkTemplate(bulk).subscribe((data) => {
        this.dialogRef.close(data.list);
        this.userService.dialogLoader = false;
      });
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (!this.fbaForm.controls.name.valid) {
        this.errors.name = false;
        this.fbaForm.patchValue({
          name: val,
        });
        this.fbaForm.controls['name'].enable();
      }
      if (!this.fbaForm.controls.template.valid) {
        this.errors.template = false;
      }
      if (!this.fbaForm.controls.dateFrom.valid) {
        this.errors.dateFrom = false;
      }
      if (!this.fbaForm.controls.dateTo.valid) {
        this.errors.dateTo = false;
      }
    }, 2000);
  }

  public getTemplateProducts(): void {
    if (this.currentTemplate.invoiceTemplateId) {
      this.currentTemplate = '';
      this.fbaForm.controls.template.setValue('');
      this.showInvoiceWarning();
      return;
    } else {
      if (this.currentTemplate.hasAlternateContent) {
        this.showAltContentWarning();
      }
      this.templateService.getTemplateProduct(this.userService.currentUser.id, this.currentTemplate.id).subscribe((products) => {
        this.isTemplateSelected = true;
        if (products.productsskus === 'NULL') {
          this.currentProducts = this.textService.currentText.anyOfYourProducts;
        } else {
          this.currentProducts = products.productsskus;
        }
        this.emailEstimated = '';
      });
    }

  }

  public getCount(): void {
    const bulk = {
      publicId: this.userService.currentUser.id,
      templateId: this.currentTemplate.id,
      from: this.dateFrom,
      to: this.dateTo,
    };
    this.templateService.getEstimatedEmails(bulk).subscribe((data) => {
      this.emailEstimated = data.estimatedemails;
    });
  }

  public showInvoiceWarning(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'message': this.textService.currentText.bulkInvoiceNotSupported,
        'title': this.textService.currentText.warning,
      },
    }).afterClosed().subscribe();
  }

  public showAltContentWarning(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'message': this.textService.currentText.bulkAltContentWarning,
        'title': this.textService.currentText.warning,
      },
    }).afterClosed().subscribe();
  }

  public showProductsPopup(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'bulk-products-alert',
      },
    }).afterClosed().subscribe();
  }
}
