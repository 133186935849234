<mat-card>
  <mat-card-content>
    <!-- <div class="d-flex justify-content-end">
      <mat-form-field>
        <mat-label>Select Metrix </mat-label>
        <mat-select #matrixsSel [formControl]="selectMatrix" multiple>
          <mat-option (click)="toggleAllSelection()">All</mat-option>
          <mat-option value="sales">Sales</mat-option>
          <mat-option value="ppc">PPC</mat-option>
          <mat-option value="promotion">Promotion</mat-option>
          <mat-option value="profit">Profit</mat-option>
          <mat-option value="revenue">Revenue</mat-option>
          <mat-option value="cost">Cost</mat-option>
          <mat-option value="margin">Margin</mat-option>
          <mat-option value="roi">Roi</mat-option>
          <mat-option value="refunds">Refunds</mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
    <div class="card barchart-card">
      <div class="card-body">
        <p class="mb-4" *ngIf="this.barChartValue.time">{{this.barChartValue.time | date : 'mediumDate'}}</p>
        <p class="mb-4" *ngIf="!this.barChartValue.time">Date</p>
        <table class="w-100">
          <tbody>
            <tr>
              <td>
                <span style="background:rgba(77,153,255,0.49)"></span>
                Units Sold
              </td>
              <td>
                {{this.barChartValue.units}}
              </td>
            </tr>
            <tr>
              <td>
                <span style="background:rgba(77,255,178,0.49)"></span>
                PPC Units
              </td>
              <td>
                {{this.barChartValue.ppc}}
              </td>
            </tr>
            <tr>
              <td>
                <span style="background:rgba(77,107,255,0.49)"></span>
                Promotions Units
              </td>
              <td>
                {{this.barChartValue.promotions}}
              </td>
            </tr>
            <tr>
              <td>
                <span style="background:#f48229"></span>
                Profit
              </td>
              <td>
                {{this.barChartValue.profit | currency : currencySymbol : 'symbol'}}
              </td>
            </tr>
            <tr>
              <td>
                <span style="background:#ff7aac"></span>
                Roi
              </td>
              <td>
                {{this.barChartValue.roi}}%
              </td>
            </tr>
            <tr>
              <td>
                <span style="background:#d9df20"></span>
                Refunds
              </td>
              <td>
                {{this.barChartValue.refunds}}
              </td>
            </tr>
            <tr>
              <td>
                <span style="background:#5792cc"></span>
                Cost
              </td>
              <td>
                {{this.barChartValue.cost | currency : currencySymbol : 'symbol'}}
              </td>
            </tr>
            <tr>
              <td>
                <span style="background:#8bc53e"></span>
                Revenue
              </td>
              <td>
                {{this.barChartValue.revenue | currency : currencySymbol : 'symbol'}}
              </td>
            </tr>
            <tr>
              <td>
                <span style="background:#6bf7aa"></span>
                Margin
              </td>
              <td>
                {{this.barChartValue.margin}}%
              </td>
            </tr>
            <tr>
              <td>
                <span style="background:#343a40"></span>
                TACOS
              </td>
              <td>
                {{this.barChartValue.tACoS}}%
              </td>
            </tr>
            <tr>
              <td>
                <span style="background:#dc3545"></span>
                PPC Expenses
              </td>
              <td>
                {{this.barChartValue.ppcExpenses}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div echarts [options]="echartPPUBarOptions" (chartMouseOver)="onChartMouseOver($event)" (chartLegendSelectChanged)="onChangeLegend($event)" style="height: 330px"></div>
  </mat-card-content>
</mat-card>