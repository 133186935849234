<div class="main-dialog-container main-dialog-container--spec-prod">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="mixpanelService.track('q alerts - add  asin select product cancel clicked');dialogRef.close(false)"></div>
  <div class="inner-dialog-container">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.selectSpecProducts}}</h2>
        <h3>{{textService.currentText.selectSpecProductsSub}}</h3>
      </div>
      <div class="dialog-holder">
        <div class="filters-container">
          <div class="a-side">
            <div class="btn-container" (click)="isFilters = !isFilters">
              <button mat-button mat-raised-button class="default-btn filter-btn" type="button" [class.active]="isFilters">
                {{textService.currentText.filters}}
              </button>
            </div>
            <form class="filters custom-selects filters-popup" [class.active]="isFilters">
              <div class="input-wrapper">
                <mat-form-field>
                  <input matInput type="text" [placeholder]="textService.currentText.productName" (keyup)="checkFilters()" name="name"
                    [(ngModel)]="filters.name">
                </mat-form-field>
                <div class="clear-input" *ngIf="filters.name" (click)="filters.name = ''; checkFilters()"></div>
              </div>
              <div class="input-wrapper">
                <mat-form-field>
                  <input matInput type="text" [placeholder]="textService.currentText.asin" (keyup)="checkFilters()" name="asin"
                    [(ngModel)]="filters.asin">
                </mat-form-field>
                <div class="clear-input" *ngIf="filters.asin" (click)="filters.asin = ''; checkFilters()"></div>
              </div>
              <div class="input-wrapper">
                <mat-form-field>
                  <input matInput type="text" [placeholder]="textService.currentText.SKU" (keyup)="checkFilters()" name="sku"
                    [(ngModel)]="filters.sku">
                </mat-form-field>
                <div class="clear-input" *ngIf="filters.sku" (click)="filters.sku = ''; checkFilters()"></div>
              </div>
              <button mat-button mat-raised-button (click)="clearFilters()" class="default-btn clear-btn" [disabled]="isSingle" type="button">
                {{textService.currentText.clearAll}}
              </button>
            </form>
          </div>

        </div>
        <div class="table-container">
          <div class="table-wrapper spec-prod-wrapper">
            <div class="table-header-wrapper">
              <div class="table-header spec-prod-header">
                <div class="td">
                  <mat-checkbox disableRipple (change)="checkAll()" class="square-checkbox" [disabled]="isSingle" [(ngModel)]="checkAllProducts">
                  </mat-checkbox>
                </div>
                <div class="td">{{textService.currentText.product}}<br>{{textService.currentText.image}}</div>
                <div class="td" (click)="functions.sortColumns(productsList, 'shortName'); getUpdatePaginator(0)">
                  {{textService.currentText.productName}}</div>
                <div class="td" (click)="functions.sortColumns(productsList, 'asin'); getUpdatePaginator(0)">{{textService.currentText.asin}}</div>
                <div class="td" (click)="functions.sortColumns(productsList, 'SKU'); getUpdatePaginator(0)">{{textService.currentText.SKU}}</div>
                <div class="td" *ngIf="showSaleChannel==true">Sale Channel</div>
              </div>
            </div>
            <div class="table-holder spec-prod-holder" *ngIf="productsList?.length > 0">
              <div class="table" *ngFor="let product of productsListPaginator">
                <div class="tr" *ngIf="!product.isHiden" [class.checked]="product.checked">
                  <div class="td">
                    <mat-checkbox disableRipple class="square-checkbox" (change)="saveCurrentCheckbox(product)" [(ngModel)]="product.checked">
                    </mat-checkbox>
                  </div>
                  <div class="td">
                    <div class="product-image-holder">
                      <img [src]="product?.image" alt="">
                    </div>
                  </div>
                  <div class="td">{{product.shortName}}</div>
                  <div class="td">{{product.asin}}</div>
                  <div class="td">{{product.SKU}}</div>
                  <div class="td" *ngIf="showSaleChannel==true">
                    <mat-form-field>
                      <mat-select class="select-sale-channel" [(ngModel)]="product.saleChannel" [(value)]="product.saleChannel">
                        <mat-select-trigger>
                          <span class="flag-icon"><img src='{{getFlagFromSaleChannel(product.saleChannel)}}' /></span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let flag of regionFlags " [value]="flag.value">
                          <span class="flag-icon"><img src='{{flag.image}}' /></span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-holder" *ngIf="productsListPaginator?.length === 0">
              <div class="table">
                <div class="tr not-found">
                  <div class="td not-found">
                    {{textService.currentText.noDataFound}}.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn" (click)="mixpanelService.track('q alerts - add  asin select product cancel clicked');dialogRef.close(false)">cancel</button>
    <mat-paginator [length]="productsList?.length" [pageSize]="pageSize" (page)="getUpdatePaginator($event.pageIndex)">
    </mat-paginator>
    <button mat-button class="primary-btn" (click)="mixpanelService.track('q alerts - add  asin select product save clicked');save()">{{textService.currentText.save}}</button>
  </div>
</div>