<div class="main-dialog-container contact-us">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.contactUsTitle}}</h2>
      </div>
      <div class="dialog-message">
        <p>{{textService.currentText.contactUsSubTitle1}}</p>
        <p>{{textService.currentText.contactUsSubTitle2}} <a class="action-el"
            href="mailto:support@quantifyninja.com">{{textService.currentText.supportEmail}}</a>
        </p>
      </div>
      <div class="dialog-row">
        <div class="label-name">
          <span>{{textService.currentText.yourName}}</span>
        </div>
        <input matInput [(ngModel)]="name" type="text">
      </div>
      <ng-container [formGroup]="contactUsForm" name="form" novalidate>
        <div class="dialog-row">
          <div class="label-name" [class.has-danger]="errors?.email">
            <span>{{textService.currentText.yourEmail}}</span>
          </div>
          <input matInput *ngIf="!errors?.email" formControlName="email" name="email" [(ngModel)]="email">
          <input matInput [class.has-danger]="errors?.email" *ngIf="errors?.email" type="text" [value]="errorEmailMessage">
        </div>
        <div class="dialog-row last">
          <textarea matInput *ngIf="!errors?.messageText" formControlName="messageText" [(ngModel)]="messageText" name="messageText"
            [placeholder]="textService.currentText.yourMessage">
        </textarea>
          <textarea matInput *ngIf="errors?.messageText" [class.has-danger]="errors?.messageText" formControlName="messageText" name="messageText"
            [value]="textService.currentText.errorContantEmpty">
        </textarea>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="dialog-footer space-between">
    <button mat-button class="primary-btn white" (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn send" (click)="checkForm()">{{textService.currentText.send}}</button>
  </div>
</div>

<ninja-notification [active]="userService.showNotification" [message]="notificationMessage" [title]="notificationTitle"></ninja-notification>