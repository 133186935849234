import { UpgradeNewComponent } from './components/upgrade-new/upgrade-new.component';
import { MailUnsubscribeComponent } from "./components/main-page/mail-unsubscribe/mail-unsubscribe.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SignUpPageComponent } from "./components/main-page/sign-up-page/sign-up-page.component";
import { ResetPasswordPageComponent } from "./components/main-page/reset-password/reset-password.component";
import { ChooseRegionPageComponent } from "./components/main-page/choose-region/choose-region.component";
import { LoginPageComponent } from "./components/main-page/login-page/login-page.component";

// import { CanActivateTeam, CanDeactivateGuard, RouteGuardIsLoggedIn } from './components/dashboard/diactivate.guadr';
import { ConfirmNewPasswordPageComponent } from "./components/main-page/confirm-new-password/confirm-new-password.component";
import { Page404Component } from "./components/404-page/404-page.component";
import { GrantAccessComponet } from "./components/main-page/grant-access/grant-access.component";
import { AppStoreComponent } from "./components/main-page/appstore/appstore.component";
import { SpaComponent } from "./components/main-page/spa/spa.component";
import { CanDeactivateGuard, RouteGuardIsLoggedIn } from "./components/dashboard/diactivate.guadr";
import { MfaComponent } from './components/main-page/mfa/mfa.component';

const routes: Routes = [
  { path: "login", component: LoginPageComponent },
  { path: "mfa", component: MfaComponent },
  { path: "registration", component: SignUpPageComponent },
  { path: "reset-password", component: ResetPasswordPageComponent },
  { path: "confirm-password", component: ConfirmNewPasswordPageComponent },
  { path: "choose-region", component: ChooseRegionPageComponent },
  { path: "grant-access", component: GrantAccessComponet },
  { path: "payment_approved", component: GrantAccessComponet },
  { path: "payment_canceled", component: GrantAccessComponet },
  { path: "appstore/spa", component: AppStoreComponent },
  { path: "spa", component: SpaComponent },
  { path: "token", component: GrantAccessComponet },
  // { path: 'orders/download-report', component: DownloadReportComponent },
  { path: "adaccounts", component: GrantAccessComponet },
  { path: "fbcode", component: LoginPageComponent },
  { path: "munsub", component: MailUnsubscribeComponent },
  {
    path: "dashboard",
    // canActivateChild: [CanActivateTeam],
    loadChildren: () =>
      import("./components/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path : "upgrade-new",
    component : UpgradeNewComponent,
  },
  { path: "", pathMatch: "full", redirectTo: "login" },
  { path: "**", component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRouteModule {}
