import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../../services/user.service';
import {Functions} from '../../../../../services/functions';
import {ConstService} from '../../../../../services/const-service';
import {FbService} from '../../../../../services/fb.service';
import {TextService} from '../../../../../services/text.service';
import {ConfirmationDialogComponent} from "../../../../dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'ninja-conversation-bot-option-constructor',
  templateUrl: './conversation-bot-option-constructor.component.html',
  styleUrls: ['./conversation-bot-option-constructor.component.scss']
})

export class ConversationBotOptionConstructorComponent implements OnInit {
  @Input() type: string;
  @Input() isActive: boolean;
  @Input() checkNewEl: boolean;
  @Input() data: any;
  @Input() index: any;
  @Output() actionDel: EventEmitter<any> = new EventEmitter();
  @Output() setActiveEmitter: EventEmitter<any> = new EventEmitter();
  @Output() setTemplateData: EventEmitter<any> = new EventEmitter();
  @Output() setActionData: EventEmitter<any> = new EventEmitter();
  @Output() setImgUrl: EventEmitter<any> = new EventEmitter();
  @Output() emitTextData: EventEmitter<any> = new EventEmitter();
  @Output() emitConversationData: EventEmitter<any> = new EventEmitter();
  @Output() emitTextParams: EventEmitter<any> = new EventEmitter();
  @Output() changeTextValue: EventEmitter<any> = new EventEmitter();
  public actionText = 'Action';
  public showMessageMenu: boolean;
  public fileSizeLeft = 7340032;
  public showTemplateMenu: boolean;
  public showImageMenu: boolean;
  public showActionMenu: boolean;
  public showButtonMenu2: boolean;
  public dataTemplateButton: any;
  public rowsArray: any;
  public activate = false;
  public findImgBlock = false;
  public activatePreview = false;
  public imgUrl = '';
  public actionName = '';
  public tagName = '';
  public lnk = '';
  public coupons: any;
  public tags: any;
  public width = 150;
  public y = 100;
  public activeEl = 0;
  public showTooltipIndicator: any;


  constructor(public matDialog: MatDialog,
              public constService: ConstService,
              public ref: ChangeDetectorRef,
              public fbService: FbService,
              public userService: UserService,
              public textService: TextService,
              public router: Router,
              public activatedRoute: ActivatedRoute,
              public functions: Functions) {
  }

  public ngOnInit() {
    this.setActionName();
    this.fbService.facebookNav().subscribe((data) => {
      data ? this.disableEl(this.data) : this.enableBranch(this.data);
      if (this.data && this.data.type === 'IMAGE') {
        this.imgUrl = JSON.parse(JSON.stringify(this.data.value));
      }
      this.setActionName();
      if (this.data && this.data.type === 'template') {
        this.dataTemplateButton = JSON.parse(JSON.stringify(this.data));
      }
    });
  }

  public ngAfterViewInit() {
    this.rowsArray = document.getElementsByClassName('conversation-row');
  }

  public setActionName() {
    if (this.data && this.data.type === 'ACTIONABLE') {
      if (this.data.value === 'facebookUnsubscribeTask') {
        this.data.text = 'Unsubscribe'
      }
      if (this.data.value === 'facebookSubscribeTask') {
        this.data.text = 'Subscribe'
      }
      if (this.data.value === 'triggerConversationActionTask') {
        this.data.text = 'Trigger Conversation ' + this.data.params
      }
      if (this.data.value === 'delayActionTask' || this.data.value === 'typingStatusActionTask') {
        this.data.text = this.data.value === 'delayActionTask' ?
          `Delay (${this.data.params / 1000} sec)` : `Typing status (${this.data.params / 1000} sec)`
      }
    }
  }

  public disableEl(data) {
    this.activeEl = 0;
    for (let i = 0; data.length > i; i++) {
      data[i].active = false;
      data[i].activatePreview = false;
      if (data[i].childs && data[i].childs.length > 0) {
        data[i].active = false;
        data[i].activatePreview = false;
        this.disableEl(data[i].childs);
      }
    }
    this.activate = false;
    this.activatePreview = true;
  }

  public enableBranch(data) {
    const currentData = data;
    for (let i = 0; currentData.length > i; i++) {
      if (currentData[i].active) {
        if (this.activatePreview) {
          currentData[i].activatePreview = true;
          this.activatePreview = false;
        }
        this.activate = true;
        return;
      }
      if (currentData[i].childs) {
        this.enableBranch(currentData[i].childs);
      }
      if (this.activate) {
        currentData[i].active = true;
        return;
      }
      continue;
    }
  }

  public setActiveButton(button) {
    // setTimeout(() => {
    //   button.active = true;
    // });
  }

  public setActiveRec(data, e, index) {
    data.active = true;
    this.disableEl(null);
  }

  public setActive(data) {
    setTimeout(() => {
      this.setActiveEmitter.emit(true);
    });
  }

  public saveCouponText(e) {
    const variable = `<Coupon:${e.couponid}>`;
    this.data.value += ' ';
    this.data.value += variable;
    this.data.type = 'VARIABLE_TEXT'
  }

  public saveVariableText(e) {
    let variable;
    e === 'Buyer name' ? variable = `<customer-name>` : variable = `<customer-first-name>`;
    this.data.value += ' ';
    this.data.value += variable;
    this.data.type = 'VARIABLE_TEXT';
  }

  public emitAction(e) {
    this.setActionData.emit(e);
  }

  public setActiveEl(data) {
    data.active = true;
  }

  public setTemplate(data, e, index) {
    data.childs[index].tag = e.tagid;
    data.childs[index].name += e.name;
  }

  public setTemplateDataRec(data, e, index) {
    data.buttons = e.data;
    this.ref.detectChanges();
  }

  public setUrlTemplateButton(data, e, index) {
    data.buttons[index].url = e;
    data.buttons[index].name = e;
    this.setTemplateData.emit(data);
  }

  public emitTextDataRec(item, e, index) {
    item.value = e.text;
    this.ref.detectChanges();
  }

  public setActionDataRec(item, e, index) {

    if (e.type === 'ACTIONABLE' && item.childs && item.childs.length > 0) {
      item.childs[index].params = e.params ? e.params : undefined;
      item.childs[index].type = e.type;
      item.childs[index].value = e.value;
      item.childs[index].text = e.text;
      console.log(item)
      console.log(e)
    } else {
      item.params = e.params;
      item.type = e.type;
      item.value = e.value;
      item.text = e.text;
    }
  }

  public setImgUrlRec(item, e, index) {
    item.data = e.data;
  }

  public setPositions() {
    for (let i = 0; i < this.rowsArray.length; i++) {
      this.rowsArray[i].style.paddingLeft = (i * 20).toString() + 'px';
    }
  }

  public deleteAction(data) {
    this.fbService.actionDel(data, this.fbService.conversation);
  }

  public addButton(e) {
    if (this.data.childs.length !== 3) {
      this.data.childs.push(JSON.parse(JSON.stringify(this.fbService.templateBUtton)));
    }
  }

  public activateTemplateButton(data, index) {
    data[index].active = true;
  }

  public closeTemplateTooltip(data, el?) {
    for (let i = 0; data.length > i; i++) {
      data[i].show = false;
    }
    this.ref.detectChanges();
  }

  public imageFileLoader(files) {
    this.userService.loaderObesrver.next(true);
    const sizeLimit = 2097152;
    const formats = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    if (files.target.files[0].size >= sizeLimit || formats.indexOf(files.target.files[0].type) === -1) {
      this.userService.loaderObesrver.next(false);
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'custom-confirm-ok',
          title: this.textService.currentText.warning,
          message: this.textService.currentText.sizeLimitImage
        },
      }).afterClosed().subscribe(() => {
      });
      return;
    }
    this.userService.uploadImage(files.target.files, 'templates/upload_image', this.fbService.currentPayload);
    this.userService.fileLoaded().subscribe((data) => {
      const imageLink = JSON.parse(data.currentTarget.response).url.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
      this.userService.loaderObesrver.next(false);
      this.lnk = imageLink;
      this.findLastestActiveImage(this.fbService.conversation)
    });
  }

  public findLastestActiveImage(data) {
    const currentData = data;
    for (let i = 0; currentData.length > i; i++) {
      if (currentData[i].id) {
        currentData[i].value = this.lnk;
        currentData[i].image = this.lnk;
        currentData[i].id = false
      }
      if (currentData[i].childs && currentData[i].childs.length) {
        this.findLastestActiveImage(currentData[i].childs);
      }
    }
  }

  public checkDataType() {
    if (this.data.value.indexOf('<') === -1 || this.data.value.indexOf('>') === -1) {
      this.data.type = 'TXT';
    }
  }
}
