import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Functions} from '../../../services/functions';
import {UserService} from '../../../services/user.service';
import {ConstService} from '../../../services/const-service';
import {SelectPostDialogComponent} from '../select-post-dialog/select-post-dialog.component';
import {FormBuilder, Validators} from '@angular/forms';
import {TextService} from '../../../services/text.service';
import {FbService} from '../../../services/fb.service';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  templateUrl: './auto-response-dialog.component.html',
  styleUrls: ['./auto-response-dialog.component.scss']
})

export class AutoResponseDialogComponent implements OnInit {
  @ViewChild('ruleNameId', { static: true }) ruleNameInput: ElementRef;
  @ViewChild('keywordId', { static: true }) keywordInput: ElementRef;
  @ViewChild('messageId', { static: true }) messageInput: ElementRef;
  @ViewChild('previewMsgId', { static: true }) previewMsgInput: ElementRef;
  public pages: any;
  public prevData: any;
  public currentFbPage: any;
  public previewMsg = '';
  public errors: any = {};
  public popupTitle: string;
  public pageNamePlaceholder = null;
  public submitForm = this.fb.group({
    ruleName: ['', [Validators.required]],
    status: ['', [Validators.required]],
    direct: ['', [Validators.required]],
    keyword: ['', [Validators.required]],
    message: ['', [Validators.required]],
    currentFbPage: ['', [Validators.required]],
    previewMsg: ['', [Validators.required]]
  });
  public dataToSave = {
    publicId: this.userService.currentUser.id,
    message: this.submitForm.controls['message'].value,
    keyword: this.submitForm.controls['keyword'].value,
    status: this.submitForm.controls['status'].value,
    pageId: null,
    ruleName: this.submitForm.controls['ruleName'].value,
    postMessage: this.submitForm.controls['previewMsg'].value,
    postId: null,
    pageName: null,
    isDirect: this.submitForm.controls['direct'].value,
    isEditMode: true,
    ruleId: null
  };

  constructor(public userService: UserService,
              public fb: FormBuilder,
              public constService: ConstService,
              public textService: TextService,
              public fbService: FbService,
              public ref: ChangeDetectorRef,
              public functions: Functions,
              public matDialog: MatDialog,
              public dialogConfirmRef: MatDialogRef<ConfirmationDialogComponent>,
              public dialogRef: MatDialogRef<AutoResponseDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit(): void {
    this.userService.dialogLoader = true;
    this.fbService.getPagesFb(this.userService.currentUser.id).subscribe((data) => {
      this.pages = data;
      this.submitForm.controls['direct'].setValue(true);
      this.submitForm.controls['status'].setValue(true);
      this.dataToSave.isEditMode = this.data.editMode;
      if (this.data.editMode) {
        this.dataToSave = JSON.parse(JSON.stringify(this.data.data));
        this.setData();
        this.dataToSave.isEditMode = this.data.editMode;
        this.dataToSave.publicId = this.userService.currentUser.id;
        for (let i = 0; this.pages.length > i; i++) {
          if (this.pages[i].name === this.dataToSave.pageName) {
            this.submitForm.controls['currentFbPage'].setValue(this.pages[i]);
          }
        }
        this.popupTitle = this.textService.currentText.editResponse;
      } else {
        this.popupTitle = this.textService.currentText.createNewResponse;
        this.pageNamePlaceholder = this.textService.currentText.selectPage;
      }
      this.prevData = JSON.parse(JSON.stringify(this.dataToSave));
      this.userService.dialogLoader = false;
    });
  }

  public showOverwriteDialog(dataToPass) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'auto-reply-overwrite',
        'ruleName': dataToPass.ruleName
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.dataToSave.ruleId = dataToPass.ruleId;
        this.dataToSave.isEditMode = true;
        this.userService.dialogLoader = true;
        this.fbService.saveRule(this.dataToSave).subscribe( list => {

          this.dialogRef.close(list);
          this.userService.dialogLoader = false;
        });
      }
    });
  }

  public showSelectPostDialog(): void {
    this.matDialog.open(SelectPostDialogComponent, {
      data: {
        data: this.submitForm.controls['currentFbPage'].value
      },
    }).afterClosed().subscribe((response) => {
      if (response) {
        this.dataToSave.postId = response.postId;
        this.submitForm.controls['previewMsg'].setValue(response.message);
      }
    });
  }

  public setPageDetail(): void {
    this.dataToSave.pageId = this.submitForm.controls['currentFbPage'].value.pageId;
    this.dataToSave.pageName = this.submitForm.controls['currentFbPage'].value.name;
  }

  public setData(): void {
    this.submitForm.controls['direct'].setValue(this.dataToSave.isDirect);
    this.submitForm.controls['status'].setValue(this.dataToSave.status);
    this.submitForm.controls['keyword'].setValue(this.dataToSave.keyword);
    this.submitForm.controls['message'].setValue(this.dataToSave.message);
    this.submitForm.controls['ruleName'].setValue(this.dataToSave.ruleName);
    this.submitForm.controls['currentFbPage'].setValue(this.dataToSave.pageName);
    this.submitForm.controls['previewMsg'].setValue(this.dataToSave.postMessage);
  }

  public getDataToSave(): void {
    this.dataToSave.isDirect = this.submitForm.controls['direct'].value;
    this.dataToSave.status = this.submitForm.controls['status'].value;
    this.dataToSave.keyword = this.submitForm.controls['keyword'].value;
    this.dataToSave.message = this.submitForm.controls['message'].value;
    this.dataToSave.ruleName = this.submitForm.controls['ruleName'].value;
    this.dataToSave.pageName = this.submitForm.controls['currentFbPage'].value.name;
    this.dataToSave.postMessage = this.submitForm.controls['previewMsg'].value;
    this.dataToSave.pageId = this.submitForm.controls['currentFbPage'].value.pageId;
  }

  public saveNewResponse(): void {
    if (this.checkForm()) {
        this.getDataToSave();
      this.userService.dialogLoader = true;
      this.setPageDetail();
      this.fbService.saveRule(this.dataToSave).subscribe((resp) => {
        if (resp.isSuccess === false) {
          this.userService.dialogLoader = false;
          const dataToPass = resp;
          dataToPass.data = this.dataToSave;
          this.showOverwriteDialog(dataToPass);
        } else {
          this.dialogRef.close(resp);
        }
      });
    }
  }

  public showDirectResponseHelpDialog() {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.autoRespApplyOnly,
        'message': this.textService.currentText.dialogAutoReplyComment
      },
    });
  }

  public checkForm(): boolean {
    if (!this.submitForm.valid) {
      if (!this.submitForm.controls.ruleName.valid) {
        this.errors.ruleName = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.keyword.valid) {
        this.errors.keyword = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.message.valid) {
        this.errors.message = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.currentFbPage.valid) {
        this.errors.currentFbPage = true;
        this.displayError();
        return;
      }
      if (!this.submitForm.controls.previewMsg.valid) {
        this.errors.previewMsg = true;
        this.displayError(this.textService.currentText.errorPostNotSelected);
        return;
      }
      return false;
    }
    return this.submitForm.valid;
  }

  private displayError(message?: string): void {
    if (!this.submitForm.controls.ruleName.valid) {
      this.errors.ruleName = true;
      const old_value = this.submitForm.value.email;
      this.submitForm.patchValue({
        ruleName: message
      });
      this.submitForm.controls['ruleName'].disable();
      this.ruleNameInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.keyword.valid) {
      this.errors.keyword = true;
      const old_value = this.submitForm.value.email;
      this.submitForm.patchValue({
        keyword: message
      });
      this.submitForm.controls['keyword'].disable();
      this.keywordInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.message.valid) {
      this.errors.message = true;
      const old_value = this.submitForm.value.email;
      this.submitForm.patchValue({
        message: message
      });
      this.submitForm.controls['message'].disable();
      this.messageInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.currentFbPage.valid) {
      this.errors.currentFbPage = true;
      this.resetErrorState('test');
      return;
    }
    if (!this.submitForm.controls.previewMsg.valid) {
      this.errors.previewMsg = true;
      const old_value = this.submitForm.value.email;
      this.submitForm.patchValue({
        previewMsg: message
      });
      this.submitForm.controls['previewMsg'].disable();
      this.previewMsgInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.ruleName) {
        this.submitForm.patchValue({
          ruleName: val,
        });
        this.errors.ruleName = false;
        this.submitForm.controls['ruleName'].enable();
        return;
      }
      if (this.errors.keyword) {
        this.submitForm.patchValue({
          keyword: val,
        });
        this.errors.keyword = false;
        this.submitForm.controls['keyword'].enable();
      }
      if (this.errors.message) {
        this.submitForm.patchValue({
          message: val,
        });
        this.errors.message = false;
        this.submitForm.controls['message'].enable();
      }
      if (this.errors.currentFbPage) {
        this.errors.currentFbPage = false;
      }
      if (this.errors.previewMsg) {
        this.submitForm.patchValue({
          previewMsg: val,
        });
        this.errors.previewMsg = false;
      }
    }, 2000);
  }
}
