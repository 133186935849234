<mat-card class="todaysection">
  <mat-card-title class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <h4 class="text-gray-light mr-4 mb-0" *ngIf="title && subTitle">{{title}} <span>vs {{subTitle}}</span></h4>
      <!-- <span class="text-gray-light">{{timeframeData.startAsString}} -
        {{timeframeData.endAsString}}</span> -->
    </div>
    <button mat-icon-button class="mt-n3 mr-n2" [matMenuTriggerFor]="menu" #customDate="matMenuTrigger">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <!-- <button class="cursor-pointer btnChange" type="button" [disabled]="disableProfitBreakdownDialog" (click)="openProfitBrakedownDialog()">
      <img height="18" width="18" src="assets/images/profit_breakdown.png" />
    </button> -->
  </mat-card-title>
  <mat-menu class="three-option" #menu="matMenu">
    <button mat-menu-item (click)="mixpanelService.track('q box - change timeframe',{'box number': boxNumber});$event.preventDefault();" [matMenuTriggerFor]="custom">
      <mat-icon class="mr-2">calendar_today</mat-icon> Change Date Range
    </button>
    <button mat-menu-item class="cursor-pointer btnChange d-flex align-items-center" type="button"
      [disabled]="disableProfitBreakdownDialog" (click)="mixpanelService.track('q box - view p&l',{'box number': boxNumber});openProfitBrakedownDialog()">
      <img class="mr-3" height="15" width="15" src="assets/images/profit_breakdown.png" /> Show P&L Breakdown
    </button>
    <!-- <button mat-menu-item>
      <mat-icon class="mr-2">help_outline</mat-icon> Help
    </button> -->
  </mat-menu>
  <mat-menu #custom="matMenu" class="profit-date" [xPosition]="defaultData.timeFrame == 'firstTimeFrame' ? 'after' : 'before'">
    <ngx-daterangepicker-material [showCustomRangeLabel]="true" [autoApply]="true"
      [alwaysShowCalendars]="false" [ranges]="ranges" [linkedCalendars]="true" [keepCalendarOpeningWithRange]="false"
      (rangeClicked)="choosedDate($event)" (choosedDate)="customRange($event)" [locale]="{applyLabel: 'Done'}">
    </ngx-daterangepicker-material>
    <div class="right-box ml-4" *ngIf="customrangeshow">
      <div class="d-flex mt-4 justify-content-between">
        <button mat-button class="default-btn orange cancelBtn mr-4" (click)="cancel()">
          Cancel
        </button>
        <button mat-button class="default-btn applyBtn mr-4" (click)="submit()">
          Apply
        </button>
      </div>
    </div>
  </mat-menu>
  <mat-card-content>
    <div class="row">
      <div class="text-center max-width-33" *ngIf="timeframeData.unitsData">
        <h4 class="text-gray-light">Units Sold</h4>
        <div class="cursor-pointer" (click)="mixpanelService.track('q box - units sold - open',{'box number': boxNumber});openUnitsSoldDialog()">
          <h3 class="main-title font-size-25 mb-0 mt-22">
            {{timeframeData.unitsData.units}}
          </h3>
          <span class="font-size-11 line-height-15 font-weight-bold"
            [ngClass]="timeframeData.unitsData.change > 0 ? 'text-green' : 'profit-red-color'">
            <mat-icon class="mat-icon-size align-middle">
              {{timeframeData.unitsData.change > 0 ? 'arrow_upward' : 'arrow_downward'}} </mat-icon>
            {{timeframeData.unitsData.change | number}}%
          </span>
        </div>
        <div echarts [options]="unitsSoldChartOptions" [merge]="updateUnitsSoldChartOptions"></div>
      </div>

      <div class="text-center max-width-33" *ngIf="timeframeData.costs">
        <div class="cursor-pointer" (click)="mixpanelService.track('q box - Est. Costs - open',{'box number': boxNumber});openCostDialog()">
          <h6 class="line-height-24 profit-color-text mb-0">
            Est. Costs
            <!-- <span class="font-size-11 line-height-15" [ngClass]="timeframeData.costs.change > 0 ? 'text-green' : 'profit-red-color'">
                <mat-icon class="mat-icon-size mb-0 align-middle">{{timeframeData.costs.change > 0 ? 'trending_up' : 'trending_down'}}
                </mat-icon>{{timeframeData.costs.change | number}}%
              </span> -->
          </h6>
          <h3>
            {{timeframeData.costs.value | currency: currencySymbol : 'symbol' : '1.0-0'}}</h3>
        </div>

        <div class="cursor-pointer" (click)="mixpanelService.track('q box - Est. Profit - open',{'box number': boxNumber});openProfitDialog()" *ngIf="timeframeData.profit">
          <h6 class="line-height-24 profit-color-text mb-0">
            Est. Profit
            <span class="font-size-11 line-height-15"
              [ngClass]="timeframeData.profit.change > 0 ? 'text-green' : 'profit-red-color'">
              <mat-icon class="mat-icon-size align-middle">
                {{timeframeData.profit.change > 0 ? 'trending_up' : 'trending_down'}}
              </mat-icon>
              {{timeframeData.profit.change | number}}%
            </span>
          </h6>
          <h3>
            {{timeframeData.profit.value | currency: currencySymbol : 'symbol' : '1.0-0'}}</h3>
        </div>

        <div class="cursor-pointer" (click)="mixpanelService.track('q box - Est. Margin - open',{'box number': boxNumber});openMarginDialog()" *ngIf="timeframeData.margin">
          <h6 class="line-height-24 profit-color-text mb-0">
            Est. Margin
            <span class="font-size-11 line-height-15"
              [ngClass]="timeframeData.margin.change > 0 ? 'text-green' : 'profit-red-color'">
              <mat-icon class="mat-icon-size mb-0 align-middle">
                {{timeframeData.margin.change > 0 ? 'trending_up' : 'trending_down'}}
              </mat-icon>
              {{timeframeData.margin.change | number}}%
            </span>
          </h6>
          <h3 class="mb-0">{{timeframeData.margin.value}}%</h3>
        </div>
      </div>
      <div class="text-center max-width-33" *ngIf="timeframeData.revenueData">
        <h4 class="text-gray-light">Revenue</h4>
        <div class="cursor-pointer" (click)="mixpanelService.track('q box - Revenue - open',{'box number': boxNumber});openRevenueDialog()">
          <h3 class="font-size-25 mb-0">
            {{timeframeData.revenueData.value | currency: currencySymbol : 'symbol' : '1.0-0'}}
          </h3>
          <span class="font-size-11 line-height-15 font-weight-bold"
            [ngClass]="timeframeData.revenueData.change > 0 ? 'text-green' : 'profit-red-color'">
            <mat-icon class="mat-icon-size align-middle">
              {{timeframeData.revenueData.change > 0 ? 'arrow_upward' : 'arrow_downward'}}</mat-icon>
            {{timeframeData.revenueData.change | number}}%
          </span>
        </div>
        <div echarts [options]="revenueChartOptions" [merge]="updateRevenueChartOptions"></div>
      </div>
    </div>
  </mat-card-content>
</mat-card>