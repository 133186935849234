import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationDialogComponent} from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {TextService} from '../../../services/text.service';

@Component({
  selector: 'ninja-confirm-new-password',
  templateUrl: './confirm-new-password.component.html',
  styleUrls: ['./confirm-new-password.component.scss']
})

export class ConfirmNewPasswordPageComponent implements OnInit {
  @ViewChild('passwordInput', { static: true }) passwordInput: ElementRef;
  @ViewChild('confirmPassInput', { static: true }) confirmPassInput: ElementRef;
  public passwordInputType = 'password';
  public isPasswordTooltip = false;
  public confirmPassword: any;
  public capsOn: any;
  public userPass: any;
  public passwordInputCaps: any;
  public rePasswordInputType = 'password';
  public userPassConfirm: any;
  public confirmPassInputCaps: any;
  public errors: any = {};
  public confirmPass = false;
  public tooltipPassword = this.textService.currentText.passwordTooltip + '~!@#$%^&amp;*_-+=`|(){}[]:;"\'<>,.?/"';
  public submitForm = this.fb.group({
    password: ['',
      [Validators.required,
        Validators.minLength(6),
        Validators.pattern(/(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[a-z]).*$/)]],
    confirmPassword: ['',
      [Validators.required]]
  });

  constructor(public fb: FormBuilder,
              public userService: UserService,
              public matDialog: MatDialog,
              public textService: TextService,
              public router: Router,
              public activatedRoute: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.userService.loader = false;
    if (!this.activatedRoute.snapshot.queryParams.user) {
      this.router.navigate(['login']);
    }
    if (this.activatedRoute.snapshot.queryParams.user) {
      this.userService.checkTokenForgotPass(this.activatedRoute.snapshot.queryParams.user).subscribe((data) => {
        if (data.status === 'INVALID_USED') {
          const message = this.textService.currentText.tokenUsedMessage
          this.openForgotPassTokenDialog(message);
        } else if (data.status === 'INVALID_TIMEOUT') {
          const message = this.textService.currentText.tokenExpiredMessage
          this.openForgotPassTokenDialog(message);
        } else if (data.status === 'INVALID_NOT_EXISTS') {
          const message = this.textService.currentText.tokenNotExistMessage
          this.openForgotPassTokenDialog(message);
        } else if (data.status === 'UNKNOWN') {
          const message = this.textService.currentText.tokenInvalidMessage
          this.openForgotPassTokenDialog(message);
        }
      });
      return;
    }
  }

  public openForgotPassTokenDialog(message: string): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.fail,
        'message': message
      },
    }).afterClosed().subscribe(data => {
      this.router.navigate(['login']);
    });
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    this.capsOn = event.getModifierState && event.getModifierState('CapsLock');
  }

  public showTooltip(e: any): void {
    this.isPasswordTooltip = e;
  }

  public checkForm(): boolean {
    if (!this.submitForm.valid) {
      if (!this.submitForm.controls.password.valid) {
        this.errors.password = true;
        this.displayError();
        return;
      }
      if (!this.submitForm.controls.confirmPassword.valid) {
        this.errors.confirmPassword = true;
        this.displayError();
        return;
      }
      return false;
    }
    return this.submitForm.valid;
  }

  private displayError(): void {
    if (this.errors.password) {
      const old_value = this.submitForm.value.password;
      if (this.submitForm.controls.password.errors && this.submitForm.controls.password.errors['minLength']) {
        this.passwordInputType = 'text';
        this.submitForm.patchValue({
          password: this.textService.currentText.errorMoreThan6
        });
      } else if (this.submitForm.controls.password.errors && this.submitForm.controls.password.errors['pattern']) {
        this.isPasswordTooltip = true;
        this.passwordInputType = 'text';
        this.submitForm.patchValue({
          password: this.textService.currentText.errorIncorectEmail
        });
      } else {
        this.passwordInputType = 'text';
        this.submitForm.patchValue({
          password: this.textService.currentText.errorRequired
        });
      }
      this.submitForm.controls['password'].disable();
      this.passwordInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (this.errors.confirmPassword) {
      const old_value = this.submitForm.value.confirmPassword;
      this.rePasswordInputType = 'text';
      this.submitForm.patchValue({
        confirmPassword: this.textService.currentText.errorRequired
      });
      this.submitForm.controls['confirmPassword'].disable();
      this.confirmPassInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.confirmPassword) {
        this.submitForm.patchValue({
          confirmPassword: val,
        });
        this.errors.confirmPassword = false;
        this.rePasswordInputType = 'password';
        this.submitForm.controls['confirmPassword'].enable();
        this.confirmPass = false;
        return;
      }
      if (this.userPass !== this.userPassConfirm) {
        this.submitForm.patchValue({
          password: val,
        });
        this.errors.password = false;
        this.passwordInputType = 'password';
        this.submitForm.controls['password'].enable();
        this.confirmPass = false;
      }
      if (this.errors.password) {
        this.submitForm.patchValue({
          password: val,
        });
        this.errors.password = false;
        this.passwordInputType = 'password';
        this.submitForm.controls['password'].enable();
      }
    }, 2000);
  }

  public savePassword(): void {
    if (this.checkForm()) {
      if (this.userPass !== this.userPassConfirm) {
        if (this.userPassConfirm.length > 0) {
          this.passwordInputType = 'text';
          this.errors.password = true;
          this.confirmPass = true;
          const old_value = this.submitForm.value.password;
          this.passwordInputType = 'text';
          this.submitForm.patchValue({
            password: 'Passwords do not match'
          });
          this.submitForm.controls['password'].disable();
          this.passwordInput.nativeElement.blur();
          this.resetErrorState(old_value);
          return;
        }
      }
      this.userService.loader = true;
      this.userService.changePass(this.activatedRoute.snapshot.queryParams.user, this.userPass).subscribe((data) => {
        if (data.isUpdate) {
          this.userService.loader = false;
          this.matDialog.open(ConfirmationDialogComponent, {
            data: {
              'dialogFor': 'pass-changed'
            },
          }).afterClosed().subscribe(() => {
            this.router.navigate(['login']);
          });
        } else {
          this.userService.loader = false;
            this.matDialog.open(ConfirmationDialogComponent, {
            data: {
              'dialogFor': 'custom',
              'title': this.textService.currentText.fail,
              'message': this.textService.currentText.tokenInvalidMessage
            },
          }).afterClosed().subscribe();
        }
      });
    }
  }
}
