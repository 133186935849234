<div class="dashboard one-screen" [class.wide]="userService.smallSidebar" *ngIf="dataLoaded==true">
    <div class="dashboard-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="head-container">
                        <h2>{{textService.currentText.alertsNotifications}}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="noti-settings-container">
        <mat-accordion multi="true">
            <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'85px'" [expandedHeight]="'85px'">
                    <mat-panel-title>
                        <mat-checkbox class="round-checkbox" [checked]="data.compEmailAlert || data.compFbAlert" [disabled]="true"></mat-checkbox>
                        <h3 class="head-container">{{textService.currentText.chooseHowAlerts}}</h3>
                    </mat-panel-title>
                    <mat-panel-description>
                        <div>
                            {{textService.currentText.emailAlerts}}:
                            <span class="ml-2"
                                [ngClass]="data.compEmailAlert ? 'text-success' : 'text-danger'">{{data.compEmailAlert ? textService.currentText.active : textService.currentText.inactive}}</span>
                        </div>
                        <div>{{textService.currentText.facebookAlerts}}:
                            <span class="ml-2"
                                [ngClass]="data.compFbAlert ? 'text-success' : 'text-danger'">{{data.compFbAlert ? textService.currentText.active : textService.currentText.inactive}}</span>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ul class="ml-md-4 pl-md-5 mb-0">
                    <li class="mb-3 d-flex flex-wrap flex-md-nowrap">
                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.compEmailAlert" [checked]="data.compEmailAlert" (change)="OnEmConfigurationChange();
                  $event.stopPropagation(); $event.preventDefault()" [class.uncheckedStatus]="!data.compEmailAlert">
                            {{textService.currentText.sendAlertsEmail}}</mat-checkbox>
                    </li>
                    <li>
                        <mat-checkbox class="square-checkbox" [checked]="data.compFbAlert" [(ngModel)]="data.compFbAlert" (change)="OnFbEmConfigurationChange();
                  $event.stopPropagation();
                  $event.preventDefault()" [class.uncheckedStatus]="!data.compFbAlert">
                            {{textService.currentText.sendAlertsFacebook}}</mat-checkbox>
                    </li>
                </ul>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'85px'" [expandedHeight]="'85px'">
                    <mat-panel-title>
                        <img src="../../../../assets/icons/warning.png" alt="Warning">
                        <h3 class="head-container">{{textService.currentText.chooseWhichAlerts}}</h3>
                    </mat-panel-title>
                    <mat-panel-description>
                        <div>
                            {{textService.currentText.myProductAlerts}}:
                            <span class="ml-2">{{textService.currentText.alertsSelected}}</span>
                        </div>
                        <div>{{textService.currentText.competitorsAlerts}}:
                            <span class="ml-2">{{textService.currentText.alertsSelected}}</span>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="alert-content">
                    <div class="alerts-asin-type">
                        <mat-button-toggle-group name="alertAsinType" [(ngModel)]="MyConf">
                            <mat-button-toggle (change)="onMyAlerts()" class="alert-asin-toggle" [value]="true">
                                <img class="mr-2" src="../../../../assets/icons/myalerts.png" alt="Alerts">
                                {{textService.currentText.myAlerts}}
                            </mat-button-toggle>
                            <mat-button-toggle (change)="onCompetitorsAlerts()" class="alert-asin-toggle" [value]="false">
                                <img class="mr-2" src="../../../../assets/icons/com-alert.png" alt="Alerts">
                                {{textService.currentText.competitorsAlerts1}}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <!-- <div class="asins-head-note text-left">
                        </div> -->
                    </div>
                    <div class="alert-asin-switches ml-md-4 pl-md-5 mb-0">
                        <div class="table-responsive">
                            <table *ngIf="!MyConf">

                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">


                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.title"
                                            [checked]="data.userConfiguration.title" (change)="mixpanelService.track('q alerts - type title checked',{'state': state,'type':'My Alerts'});OnConfigurationChange('TITLE',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.title">
                                            Product Title Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.title"
                                        [checked]="data.userConfiguration.title" (change)="OnConfigurationChange('TITLE',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.title">
                                        {{valueToggle(data.userConfiguration.title)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.img"
                                            [checked]="data.userConfiguration.img" (change)="mixpanelService.track('q alerts - type image alert checked',{'state': state,'type':'My Alerts'});OnConfigurationChange('IMG',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.img">
                                            Product Image Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.img" [checked]="data.userConfiguration.img"
                                        (change)="OnConfigurationChange('IMG',$event)" [class.uncheckedStatus]="!data.userConfiguration.img">
                                        {{valueToggle(data.userConfiguration.img)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.category"
                                            [checked]="data.userConfiguration.category" (change)="mixpanelService.track('q alerts - type category alert checked',{'state': state,'type':'My Alerts'});OnConfigurationChange('CATEGORY',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.category">
                                            Product Category Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.category"
                                        [checked]="data.userConfiguration.category" (change)="OnConfigurationChange('CATEGORY',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.category">
                                        {{valueToggle(data.userConfiguration.category)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.price"
                                            [checked]="data.userConfiguration.price" (change)="mixpanelService.track('q alerts - type price alert checked',{'state': state,'type':'My Alerts'});OnConfigurationChange('PRICE',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.price">
                                            Product Price Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.price"
                                        [checked]="data.userConfiguration.price" (change)="OnConfigurationChange('PRICE',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.price">
                                        {{valueToggle(data.userConfiguration.price)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">


                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.reviews"
                                            [checked]="data.userConfiguration.reviews" (change)="mixpanelService.track('q alerts - type reviews count alert checked',{'state': state,'type':'My Alerts'});OnConfigurationChange('REVIEWS',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.reviews">
                                            Product Reviews Count Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.reviews"
                                        [checked]="data.userConfiguration.reviews" (change)="OnConfigurationChange('REVIEWS',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.reviews">
                                        {{valueToggle(data.userConfiguration.reviews)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.rating"
                                            [checked]="data.userConfiguration.rating" (change)="mixpanelService.track('q alerts - type reviews rating checked',{'state': state,'type':'My Alerts'});OnConfigurationChange('RATING',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.rating">
                                            Product Reviews Rating Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.rating"
                                        [checked]="data.userConfiguration.rating" (change)="OnConfigurationChange('RATING',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.rating">
                                        {{valueToggle(data.userConfiguration.rating)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">


                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.offers"
                                            [checked]="data.userConfiguration.offers" (change)="mixpanelService.track('q alerts - type sellers count alert checked',{'state': state,'type':'My Alerts'});OnConfigurationChange('OFFERS',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.offers">
                                            Number of Sellers on List Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.offers"
                                        [checked]="data.userConfiguration.offers" (change)="OnConfigurationChange('OFFERS',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.offers">
                                        {{valueToggle(data.userConfiguration.offers)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.brand"
                                            [checked]="data.userConfiguration.brand" (change)="mixpanelService.track('q alerts - type buybox alert checked',{'state': state,'type':'My Alerts'});OnConfigurationChange('BRAND',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.brand">
                                            Buybox Seller Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.brand"
                                        [checked]="data.userConfiguration.brand" (change)="OnConfigurationChange('BRAND',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.brand">
                                        {{valueToggle(data.userConfiguration.brand)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <table *ngIf="MyConf">

                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.myTitle"
                                            [checked]="data.userConfiguration.myTitle" (change)="mixpanelService.track('q alerts - type title checked',{'state': state,'type':'Competitors Alerts'});OnConfigurationChange('TITLE',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.myTitle">
                                            Product Title Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.myTitle"
                                        [checked]="data.userConfiguration.myTitle" (change)="OnConfigurationChange('TITLE',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.myTitle">
                                        {{valueToggle(data.userConfiguration.myTitle)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.myImg"
                                            [checked]="data.userConfiguration.myImg" (change)="mixpanelService.track('q alerts - type image alert checked',{'state': state,'type':'Competitors Alerts'});OnConfigurationChange('IMG',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.myImg">
                                            Product Image Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.myImg"
                                        [checked]="data.userConfiguration.myImg" (change)="OnConfigurationChange('IMG',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.myImg">
                                        {{valueToggle(data.userConfiguration.myImg)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.myCategory"
                                            [checked]="data.userConfiguration.myCategory" (change)="mixpanelService.track('q alerts - type category alert checked',{'state': state,'type':'Competitors Alerts'});OnConfigurationChange('CATEGORY',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.myCategory">
                                            Product Category Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.myCategory"
                                        [checked]="data.userConfiguration.myCategory" (change)="OnConfigurationChange('CATEGORY',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.myCategory">
                                        {{valueToggle(data.userConfiguration.myCategory)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.myPrice"
                                            [checked]="data.userConfiguration.myPrice" (change)="mixpanelService.track('q alerts - type price alert checked',{'state': state,'type':'Competitors Alerts'});OnConfigurationChange('PRICE',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.myPrice">
                                            Product Price Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.myPrice"
                                        [checked]="data.userConfiguration.myPrice" (change)="OnConfigurationChange('PRICE',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.myPrice">
                                        {{valueToggle(data.userConfiguration.myPrice)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.myReviews"
                                            [checked]="data.userConfiguration.myReviews" (change)="mixpanelService.track('q alerts - type reviews count alert checked',{'state': state,'type':'Competitors Alerts'});OnConfigurationChange('REVIEWS',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.myReviews">
                                            Product Reviews Count Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.myReviews"
                                        [checked]="data.userConfiguration.myReviews" (change)="OnConfigurationChange('REVIEWS',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.myReviews">
                                        {{valueToggle(data.userConfiguration.myReviews)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.myRating"
                                            [checked]="data.userConfiguration.myRating" (change)="mixpanelService.track('q alerts - type reviews rating checked',{'state': state,'type':'Competitors Alerts'});OnConfigurationChange('RATING',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.myRating">
                                            Product Reviews Rating Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.myRating"
                                        [checked]="data.userConfiguration.myRating" (change)="OnConfigurationChange('RATING',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.myRating">
                                        {{valueToggle(data.userConfiguration.myRating)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox class="square-checkbox" [(ngModel)]="data.userConfiguration.myOffers"
                                            [checked]="data.userConfiguration.myOffers" (change)="mixpanelService.track('q alerts - type sellers count alert checked',{'state': state,'type':'Competitors Alerts'});OnConfigurationChange('OFFERS',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.myOffers">
                                            Number of Sellers on List Changed </mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.myOffers"
                                        [checked]="data.userConfiguration.myOffers" (change)="OnConfigurationChange('OFFERS',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.myOffers">
                                        {{valueToggle(data.userConfiguration.myOffers)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                                <tr class="mb-3 d-flex flex-wrap flex-md-nowrap">

                                    <td>
                                        <mat-checkbox disableRipple class="square-checkbox" [(ngModel)]="data.userConfiguration.myBrand"
                                            [checked]="data.userConfiguration.myBrand" (change)="mixpanelService.track('q alerts - type buybox alert checked',{'state': state,'type':'Competitors Alerts'});OnConfigurationChange('BRAND',$event)"
                                            [class.uncheckedStatus]="!data.userConfiguration.myBrand">
                                            Buybox Seller Changed</mat-checkbox>
                                        <!-- <mat-slide-toggle disableRipple [(ngModel)]="data.userConfiguration.myBrand"
                                        [checked]="data.userConfiguration.myBrand" (change)="OnConfigurationChange('BRAND',$event)"
                                        [class.uncheckedStatus]="!data.userConfiguration.myBrand">
                                        {{valueToggle(data.userConfiguration.myBrand)}}
                                    </mat-slide-toggle> -->
                                    </td>
                                    <td class="alert-asin-info"><img class="mt-2 ml-2" src="../../../../assets/icons/Info.svg" alt="Menu" /></td>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
                <!-- <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <img class="mr-2" src="../../../../assets/icons/myalerts.png" alt="Alerts">
                            {{textService.currentText.myAlerts}}
                        </ng-template>
                        <ul class="ml-md-4 pl-md-5 mb-0">
                            <li class="mb-3 d-flex flex-wrap flex-md-nowrap">
                                <mat-checkbox class="square-checkbox" [(ngModel)]="data.compEmailAlert" [checked]="data.compEmailAlert" (change)="OnEmConfigurationChange();
                  $event.stopPropagation(); $event.preventDefault()" [class.uncheckedStatus]="!data.compEmailAlert">
                                    {{textService.currentText.sendAlertsEmail}}</mat-checkbox>
                            </li>
                            <li>
                                <mat-checkbox class="square-checkbox" [checked]="data.compFbAlert" [(ngModel)]="data.compFbAlert" (change)="OnFbEmConfigurationChange();
                  $event.stopPropagation();
                  $event.preventDefault()" [class.uncheckedStatus]="!data.compFbAlert">
                                    {{textService.currentText.sendAlertsFacebook}}</mat-checkbox>
                            </li>
                        </ul>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <img class="mr-2" src="../../../../assets/icons/com-alert.png" alt="Alerts">
                            {{textService.currentText.competitorsAlerts1}}
                        </ng-template>
                        Content 2
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <img class="mr-2" src="../../../../assets/icons/notification.png" alt="Notification">
                            {{textService.currentText.notifications}}
                        </ng-template>
                        Content 3
                    </mat-tab>
                </mat-tab-group> -->
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header [collapsedHeight]="'85px'" [expandedHeight]="'85px'">
                    <mat-panel-title>
                        <img src="../../../../assets/icons/warning.png" alt="Warning">
                        <h3 class="head-container">{{textService.currentText.chooseProductsCompetitors}}</h3>
                    </mat-panel-title>
                    <mat-panel-description>
                        <div>
                            {{textService.currentText.myProduct}}:
                            <span class="ml-2">{{textService.currentText.productsMonitored}}</span>
                        </div>
                        <div>{{textService.currentText.competitorsProducts}}:
                            <span class="ml-2">{{textService.currentText.competitorsSelected}}</span>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="asins-content">
                    <div class="asins-head">
                        <div class="asins-title">
                            {{textService.currentText.chooseYourASINs}}
                        </div>
                        <button mat-button mat-raised-button [disabled]="disableAddParents()" (click)="mixpanelService.track('q alerts - add  asin enable clicked');AddNewAsin();"
                            class="primary-btn product-asin-btn">
                            {{textService.currentText.addProductAsin}}
                        </button>
                    </div>
                    <div class="table-responsive">
                        <table class="asins-table">
                            <ng-container *ngFor="let product of data.competitorsFullData">
                                <tr class="main-asin">
                                    <td class="asin-img">
                                        <img class="ml-2" src="{{product.img}}" onError="this.src='.../../../../assets/images/loading.gif';" />
                                    </td>
                                    <td class="asin-col">
                                        <div class="asin-asin">{{product.source}}</div>
                                        <div *ngIf="product.competitors"> Status:
                                            <!-- {{product.competitors.length}} {{textService.currentText.competitors}}--><span
                                                class="green">{{textService.currentText.tracking}}... &#x25CF;</span>
                                        </div>
                                    </td>
                                    <td class="asin-title" title="{{product.productName }}">
                                        {{product.productName }}
                                    </td>
                                    <td class="asin-flag">
                                        <img _ngcontent-c2="" alt="united-states" src="{{getFlagFromSaleChannel(product.mp)}}">
                                    </td>
                                    <td class="text-center">
                                        <button mat-button mat-raised-button (click)="mixpanelService.track('q alerts - add  competitor clicked');onAddCompetetor(product.source)"
                                            [disabled]="disableAddComp(product.source)" class="primary-btn add-asin-btn">
                                            Add Competitor
                                        </button>
                                    </td>
                                    <td class="asin-menu">

                                        <button mat-icon-button [matMenuTriggerFor]="appMenu"
                                            [matMenuTriggerData]="{itmAsin: null ,itmParent:product.source}">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <!--<button mat-icon-button (click)="onMenuClick();">
                                                        <mat-icon>more_vert</mat-icon>
                                                    </button>-->
                                    </td>
                                </tr>
                                <!-- <tr class="row-spacing"></tr> -->
                                <!-- <tr class="add-asin">
                                <td></td>
                                <td colspan="2">
                                    Add competitor ASIN
                                </td>
                                <td colspan="3" class="add-asin-col">

                                </td>

                            </tr>
                            <tr class="row-spacing"></tr> -->
                                <!--<ng-container *ngIf="product.competitors!=null">-->
                                <ng-container *ngFor="let competitor of product.competitors">
                                    <tr class="comp-asin">
                                        <td colspan="6">
                                            <table class="asins-table mt-0 insideTable">
                                                <tr>
                                                    <td class="asin-img pl-5">
                                                        <img class="ml-2" src="{{competitor.img}}"
                                                            onError="this.src='.../../../../assets/images/loading.gif';" />
                                                    </td>
                                                    <td colspan="3" class="comp-title" title="{{competitor.productName}}">
                                                        {{competitor.productName | truncateText:50 }}
                                                    </td>

                                                    <td class="comp-asin">
                                                        <app-inline-edit #inlineEdit (update)="onCompEditUpdate($event);"
                                                            (close)="onCompEditClose($event);" [data]='competitor.asin' [EditMode]='!competitor.asin'>
                                                        </app-inline-edit>
                                                        <!--{{ competitor.asin }}-->
                                                    </td>
                                                    <td class="asin-menu">
                                                        <!--<img  alt="Menu" src="../../../../assets/icons/menu.svg">-->
                                                        <button mat-icon-button [matMenuTriggerFor]="appMenu"
                                                            [matMenuTriggerData]="{ inlineEdit: inlineEdit,itmAsin: competitor.asin,itmParent:product.source}">
                                                            <mat-icon>more_vert</mat-icon>
                                                        </button>
                                                        <!--<button mat-icon-button (click)="onMenuClick();">
                                                                <mat-icon>more_vert</mat-icon>
                                                            </button>-->
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>

                                    </tr>
                                    <!-- <tr class="row-spacing"></tr> -->
                                    <!-- </ng-container> -->
                                </ng-container>
                            </ng-container>
                        </table>
                    </div>
                    <mat-menu #appMenu="matMenu" yPosition="below" class="item-Menu">
                        <ng-template matMenuContent let-itmAsin="itmAsin" let-itmParent="itmParent" let-inlineEdit=inlineEdit>
                            <button mat-menu-item (click)="mixpanelService.track('q alerts - edit asin',{'type':''});OnMenuDelete(itmAsin,itmParent);">Delete</button>
                            <button mat-menu-item (click)="mixpanelService.track('q alerts - delete asin');OnMenuEdit(inlineEdit,itmAsin,itmParent);">Edit</button>
                        </ng-template>
                    </mat-menu>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<!--   <div>

        <div class="content-row">
            <div class="content-column side-container">
                <div class="asins-head">
                    <div class="asins-title">
                        {{textService.currentText.chooseYourASINs}}
                    </div>
                    <button mat-button mat-raised-button [disabled]="disableAddParents()" (click)="AddNewAsin();"
                        class="primary-btn product-asin-btn">
                        {{textService.currentText.addProductAsin}}
                    </button>
                </div>
                <div class="asins-head-note">
                    Manage your tracked ASIN’s, you can add up to 3 of your products, and 4 competitors for each one of
                    them.
                </div>
                
            </div>
        </div>
    </div>-->