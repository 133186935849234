import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Functions} from '../../../services/functions';
import {AccountSettings} from '../../../services/account-settings';
import {UserService} from '../../../services/user.service';
import {MoneyBackService} from '../../../services/money-back.service';
import {ConstService} from '../../../services/const-service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TextService} from '../../../services/text.service';

@Component({
  templateUrl: './create-audience-dialog.component.html',
  styleUrls: ['./create-audience-dialog.component.scss']
})

export class CreateAudienceDialogComponent implements OnInit {
  public submitForm: any;
  public errors = {
    name: false,
    description: false
  };

  constructor(public userService: UserService,
              public fb: FormBuilder,
              public constService: ConstService,
              public textService: TextService,
              public functions: Functions,
              public matDialog: MatDialog,
              public dialogRef: MatDialogRef<CreateAudienceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit(): void {
    this.submitForm = this.fb.group({
        name: ['', [Validators.required]],
        description: ['', [Validators.required]]
    });
  }

  public save(): void {
    if (this.checkForm()) {
      this.dialogRef.close(this.submitForm.value);
    }
  }

  public checkForm(): any {
    if (!this.submitForm.valid) {
      if (!this.submitForm.controls.name.valid) {
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.description.valid) {
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
    } else {
      return true;
    }
  }

  public displayError(message?: string): void {
    if (!this.submitForm.controls.name.valid) {
      this.errors.name = true;
      const old_value = this.submitForm.value.email;
      this.submitForm.patchValue({
        name: message
      });
      this.submitForm.controls['name'].disable();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.description.valid) {
      this.errors.description = true;
      const old_value = this.submitForm.value.description;
      this.submitForm.patchValue({
        description: message
      });
      this.submitForm.controls['description'].disable();
      this.resetErrorState(old_value);
      return;
    }
  }

  public resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.name) {
        this.submitForm.patchValue({
          name: val,
        });
        this.errors.name = false;
        this.submitForm.controls['name'].enable();
        return;
      }
      if (this.errors.description) {
        this.submitForm.patchValue({
          description: val,
        });
        this.errors.description = false;
        this.submitForm.controls['description'].enable();
        return;
      }
    }, 2000);
  }
}


