<h4 matDialogTitle class="font-size-17 header-user-color text-center">
    Profit Breakdown
    <button *ngIf="profitBreakdown.formData" (click)="downloadPNL()">
        <img title="Download" src="assets/icons/download.svg" />
    </button>
</h4>
<mat-dialog-content>
    <mat-accordion multi="false">
        <mat-expansion-panel (closed)="isOpen = false" hideToggle (opened)="expandedData">
            <mat-expansion-panel-header expandedHeight="30px" collapsedHeight="30px">
                <mat-panel-title>
                    {{profitBreakdown.grossRevenue?profitBreakdown.grossRevenue.name:''}}
                </mat-panel-title>
                <mat-panel-description class="text-right text-default" *ngIf="profitBreakdown.grossRevenue"
                    [ngClass]="profitBreakdown.grossRevenue.value<0?'red-color':''">
                    {{profitBreakdown.grossRevenue.value | currency: currencySymbol : 'symbol' : '1.2-2'}}
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-list role="list" [ngClass]="{'d-none' : isOpen}"
                *ngIf="profitBreakdown.grossRevenue && profitBreakdown.grossRevenue.nameValueList && profitBreakdown.grossRevenue.nameValueList.length>0">
                <mat-list-item role="listitem" *ngFor="let nameValue of profitBreakdown.grossRevenue.nameValueList">
                    <div class="max-width-50">{{nameValue.name}}</div>
                    <div class="max-width-50 text-right text-default" *ngIf="nameValue.value"
                        [ngClass]="nameValue.value<0?'red-color':''">
                        {{nameValue.value | currency: currencySymbol : 'symbol' : '1.2-2'}}</div>
                </mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-list role="list">
        <mat-list-item role="listitem" class="border-bottom-dotted-list-item">
            <div class="max-width-50">Reimbursements</div>
            <div class="max-width-50 text-right text-default" *ngIf="profitBreakdown.reimbursement"
                [ngClass]="profitBreakdown.reimbursement<0?'red-color':''">
                {{profitBreakdown.reimbursement | currency: currencySymbol : 'symbol' : '1.2-2'}}</div>
        </mat-list-item>
    </mat-list>
    <mat-expansion-panel hideToggle (closed)="isOpen1 = false" (opened)="expandedData1()">
        <mat-expansion-panel-header expandedHeight="30px" collapsedHeight="30px">
            <mat-panel-title>
                {{profitBreakdown.amazonFees ? profitBreakdown.amazonFees.name : ''}}
            </mat-panel-title>
            <mat-panel-description class="text-right text-default" *ngIf="profitBreakdown.amazonFees"
                [ngClass]="profitBreakdown.amazonFees.value<0?'red-color':''">
                {{profitBreakdown.amazonFees.value | currency: currencySymbol : 'symbol' : '1.2-2'}}
                <mat-icon>keyboard_arrow_down</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-list role="list" [ngClass]="{'d-none' : !isOpen1}"
            *ngIf="profitBreakdown.amazonFees && profitBreakdown.amazonFees.nameValueList && profitBreakdown.amazonFees.nameValueList.length>0">
            <mat-list-item role="listitem" *ngFor="let nameValue of profitBreakdown.amazonFees.nameValueList">
                <div class="max-width-50">{{nameValue.name}}</div>
                <div class="max-width-50 text-right text-default" *ngIf="nameValue.value"
                    [ngClass]="nameValue.value<0?'red-color':''">
                    {{nameValue.value | currency: currencySymbol : 'symbol' : '1.2-2'}}
                </div>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle (closed)="isOpen2 = false" (opened)="expandedData2()">
        <mat-expansion-panel-header expandedHeight="30px" collapsedHeight="30px">
            <mat-panel-title>
                {{profitBreakdown.estimatedPayout ? profitBreakdown.estimatedPayout.name : ''}}
            </mat-panel-title>
            <mat-panel-description class="text-right text-default" *ngIf="profitBreakdown.estimatedPayout"
                [ngClass]="profitBreakdown.estimatedPayout.value<0?'red-color':''">
                {{profitBreakdown.estimatedPayout.value | currency: currencySymbol : 'symbol' : '1.2-2'}}
                <mat-icon>keyboard_arrow_down</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-list role="list" [ngClass]="{'d-none' : !isOpen2}"
            *ngIf="profitBreakdown.estimatedPayout && profitBreakdown.estimatedPayout.nameValueList && profitBreakdown.estimatedPayout.nameValueList.length>0">
            <mat-list-item role="listitem" *ngFor="let nameValue of profitBreakdown.estimatedPayout.nameValueList">
                <div class="max-width-50">{{nameValue.name}}</div>
                <div class="max-width-50 text-right text-default" *ngIf="nameValue.value"
                    [ngClass]="nameValue.value<0?'red-color':''">
                    {{nameValue.value | currency: currencySymbol : 'symbol' : '1.2-2'}}
                </div>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>
    <mat-list role="list">
        <mat-list-item role="listitem" class="border-bottom-dotted-list-item">
            <div class="max-width-50">Refunds</div>
            <div class="max-width-50 text-right text-default" *ngIf="profitBreakdown.refunds"
                [ngClass]="profitBreakdown.refunds<0?'red-color':''">
                {{profitBreakdown.refunds | currency: currencySymbol : 'symbol' : '1.2-2'}}</div>
        </mat-list-item>
        <mat-list-item role="listitem" class="border-bottom-dotted-list-item">
            <div class="max-width-50">Promo Value</div>
            <div class="max-width-50 text-right text-default" *ngIf="profitBreakdown.promoValue"
                [ngClass]="profitBreakdown.promoValue<0?'red-color':''">
                {{profitBreakdown.promoValue | currency: currencySymbol : 'symbol' : '1.2-2'}}</div>
        </mat-list-item>
    </mat-list>
    <mat-expansion-panel hideToggle (closed)="isOpen3 = false" (opened)="expandedData3()">
        <mat-expansion-panel-header expandedHeight="30px" collapsedHeight="30px">
            <mat-panel-title>
                <span (click)="editProductCost(); $event.stopPropagation();">
                    {{profitBreakdown.cogs ? profitBreakdown.cogs.name : ''}}
                    <mat-icon class="mat-edit-icon">edit</mat-icon>
                </span>
            </mat-panel-title>
            <mat-panel-description class="text-right text-default"
                *ngIf="profitBreakdown.cogs && profitBreakdown.cogs.value"
                [ngClass]="profitBreakdown.cogs.value<0?'red-color':''">
                <span (click)="editProductCost(); $event.stopPropagation();">
                    {{profitBreakdown.cogs.value | currency: currencySymbol : 'symbol' : '1.2-2'}}
                    <mat-icon class="mat-edit-icon">edit</mat-icon>
                </span>
                <mat-icon>keyboard_arrow_down</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-list role="list" [ngClass]="{'d-none' : !isOpen3}"
            *ngIf="profitBreakdown.cogs && profitBreakdown.cogs.nameValueList && profitBreakdown.cogs.nameValueList.length>0">
            <mat-list-item role="listitem" *ngFor="let nameValue of profitBreakdown.cogs.nameValueList">
                <div *ngIf="nameValue.name != 'Product Cost'" class="max-width-50">{{nameValue.name}}</div>
                <div (click)="editProductCost()" *ngIf="nameValue.name == 'Product Cost'" class="max-width-50 cursor-pointer">
                    {{nameValue.name}}
                    <mat-icon class="mat-edit-icon">edit</mat-icon>
                </div>
                <div class="max-width-50 text-right text-default" *ngIf="nameValue.name != 'Product Cost' && nameValue.value"
                    [ngClass]="nameValue.value<0?'red-color':''">
                    {{nameValue.value | currency: currencySymbol : 'symbol' : '1.2-2'}}
                </div>
                <div class="max-width-50 text-right text-default cursor-pointer" (click)="editProductCost()" *ngIf="nameValue.name == 'Product Cost' && nameValue.value"
                    [ngClass]="nameValue.value<0?'red-color':''">
                    {{nameValue.value | currency: currencySymbol : 'symbol' : '1.2-2'}}
                    <mat-icon class="mat-edit-icon">edit</mat-icon>
                </div>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>
    <mat-list role="list">
        <mat-list-item role="listitem" class="border-bottom-dotted-list-item">
            <div class="max-width-50">PPC Cost</div>
            <div class="max-width-50 text-right text-default" *ngIf="profitBreakdown.ppcCosts"
                [ngClass]="profitBreakdown.ppcCosts<0?'red-color':''">
                {{profitBreakdown.ppcCosts?(profitBreakdown.ppcCosts | currency: currencySymbol : 'symbol' : '1.0-0'):0.00}}
            </div>
        </mat-list-item>
        <mat-list-item role="listitem">
            <div class="max-width-50">Tax</div>
            <div class="max-width-50 text-right text-default" *ngIf="profitBreakdown.tax"
                [ngClass]="profitBreakdown.tax<0?'red-color':''">
                {{profitBreakdown.tax | currency: currencySymbol : 'symbol' : '1.2-2'}}</div>
        </mat-list-item>
        <mat-list-item role="listitem" class="border-bottom-dotted-list-item">
            <div class="max-width-50 h6 mb-0 font-weight-600">Net Profit</div>
            <div class="max-width-50 text-right text-green h6 mb-0 font-weight-600" *ngIf="profitBreakdown.netProift">
                {{profitBreakdown.netProift | currency: currencySymbol : 'symbol' : '1.2-2'}}</div>
        </mat-list-item>
        <mat-list-item role="listitem">
            <div class="max-width-50 font-size-11 font-weight-600">Margin</div>
            <div class="max-width-50 text-right font-size-11 font-weight-600 text-default"
                *ngIf="profitBreakdown.margin">
                {{profitBreakdown.margin | number: '1.2-2'}}%</div>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>