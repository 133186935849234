import {Component, Inject,ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './messenger-video-dialog.component.html',
    styleUrls: ['./messenger-video-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class MessangerVideoDialogComponent {
    public youtubeUrl="";
    


    constructor(public dialogRef: MatDialogRef<MessangerVideoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialog: MatDialog) {
    }

    public ngOnInit(): void {
    }
    
  }