<div class="main-dialog-container main-dialog-container--expense">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container inner-dialog-container--terms custom-selects">
    <div class="header">
      <h2>{{textService.currentText.addExpense}}</h2>
    </div>
    <form [formGroup]="expencesForm" name="form" novalidate>
      <div class="form-row has-danger" [class.has-danger]="errors?.name">
        <span class="label-name">{{textService.currentText.name}}</span>
        <input matInput type="text" #name formControlName="name" name="nameInput">
      </div>
      <div class="form-row form-row--datepicker">
        <div class="inner-row" [class.has-danger]="errors?.from">
          <div class="label-name">
            <span>{{textService.currentText.from}}</span>
          </div>
          <mat-form-field *ngIf="!errors?.from; else elseBlockFrom">
            <input matInput #from [matDatepicker]="expenceFrom" [(ngModel)]="expences.from" (click)="expenceFrom.open()"
              (keydown)="$event.preventDefault()" formControlName="from" name="fromInput">
            <mat-datepicker-toggle matSuffix [for]="expenceFrom"></mat-datepicker-toggle>
            <mat-datepicker #expenceFrom touchUi="true" panelClass="calendar-popup"></mat-datepicker>
          </mat-form-field>
          <ng-template #elseBlockFrom>
            <mat-form-field>
              <input matInput type="text" [value]="'Requried'" disabled>
              <mat-datepicker panelClass="calendar-popup"></mat-datepicker>
            </mat-form-field>
          </ng-template>
        </div>
        <div class="inner-row" [class.has-danger]="errors?.to">
          <div class="label-name">
            <span>{{textService.currentText.to}}</span>
          </div>
          <mat-form-field *ngIf="!errors?.to; else elseBlockTo">
            <input matInput #to [matDatepicker]="expenceTo" formControlName="to" (click)="expenceTo.open()" (keydown)="$event.preventDefault()"
              [min]="expencesForm.value.from" name="toInput">
            <mat-datepicker-toggle matSuffix [for]="expenceTo"></mat-datepicker-toggle>
            <mat-datepicker #expenceTo touchUi="true" panelClass="calendar-popup"></mat-datepicker>
          </mat-form-field>
          <ng-template #elseBlockTo>
            <mat-form-field>
              <input matInput type="text" [value]="textService.currentText.required" disabled>
              <mat-datepicker panelClass="calendar-popup"></mat-datepicker>
            </mat-form-field>
          </ng-template>
        </div>
      </div>
      <div class="form-row">
        <div class="inner-row mr-4" [class.has-danger]="errors?.amount">
          <span class="label-name">{{textService.currentText.amount}}</span>
          <input matInput type="text" #amount formControlName="amount" name="amountInput">
        </div>
        <div class="inner-row" [class.has-danger]="errors?.currency">
          <span class="label-name">{{textService.currentText.currency}}</span>
          <input matInput type="text" *ngIf="errors?.currency" [value]="currencyError" name="currencyInput">
          <div class="select-wrapper" (click)="functions.setDropDownPosition(currency, 'currencySelect')" #currency *ngIf="!errors?.currency"
            [class.active]="currencySelect.panelOpen">
            <mat-form-field>
              <mat-select shouldPlaceholderFloat="false" formControlName="currency" panelClass="default-dropdown currencySelect" #currencySelect
                disableRipple name="currencyInput">
                <mat-option *ngFor="let select of selects" [value]="select.value">
                  {{ select.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-row form-row--footer justify-content-around">
        <button mat-button class="secondary-btn cancel" (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
        <button mat-button class="secondary-btn" (click)="checkForm()">{{textService.currentText.addExpense}}</button>
      </div>
    </form>
  </div>
</div>