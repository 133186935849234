import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {UserService} from './user.service';

@Injectable()

export class FbService extends UserService {
  public activate: boolean;
  public isTemplateButton: boolean;
  public currentPayload: boolean;
  public conversation: any;
  public facebookNavigation = new Subject<any>();
  public fbClose = new Subject<any>();
  public fbChatLogged = false;

  public textConversationAction = {
    active: true,
    type: 'TXT',
    value: 'Text',
    childs: []
  };

  public templateConversationAction = {
    active: true,
    type: 'template',
    value: '',
    title: '',
    templateTitle: 'Title',
    templateSubTitle: 'Sub Title',
    image: '',
    childs: [{
      type: 'TEMPLATE_BUTTON',
      active: true,
      name: 'Button',
      tag: null,
      weburl: undefined,
      childs: []
    }]
  };

  public imageConversationAction = {
    active: true,
    type: 'IMAGE',
    value: '',
    image: '',
    childs: []
  };

  public templateBUtton = {
    type: 'TEMPLATE_BUTTON',
    active: true,
    value: 'Button',
    name: 'Button',
    tag: null,
    weburl: undefined,
    childs: []
  };

  public actionConversationAction = {
    type: 'ACTIONABLE',
    active: true,
    text: 'Unsubscribe',
    value: 'facebookUnsubscribeTask',
    action: '',
    childs: []
  };

  public buttonConversationAction = {
    type: 'QuickReply',
    active: true,
    value: 'Button',
    tag: null,
    image: undefined,
    childs: []
  };

  public facebookNav(): Observable<any> {
    return this.facebookNavigation.asObservable();
  }

  public facebookCls(): Observable<any> {
    return this.fbClose.asObservable();
  }

  public actionDel(el: any, data: any): void {
    if (el.type === 'QuickReply' && el.delete) {
      if (data.indexOf(el) > -1) {
        data.splice(data.indexOf(el), 1);
        return;
      }
      for (let i = 0; data.length > i; i++) {
        if (data[i].childs && data[i].childs.indexOf(el) > -1) {
          data[i].childs.splice(data[i].childs.indexOf(el), 1);
          return;
        }
      }
    }
    if (Array.isArray(data) && data.indexOf(el) > -1) {
      data.splice(data.indexOf(el), 1);
      return;
    }
    if (data.childs && data.childs.indexOf(el) > -1) {
      data.childs.splice(data.childs.indexOf(el), 1);
      return;
    }
    for (let i = 0; data.length > i; i++) {
      if (data[i].active && data[i].childs.length > 0) {
        this.actionDel(el, data[i].childs);
      }
    }
  }

  public getConversationsNames(publicId: string, pageId: any): Observable<any> {
    return this.post('facebook/get_conversations_names', {
      publicId: publicId,
      pageId: pageId
    });
  }

  public getTesterName(publicId: string, pageId: any): Observable<any> {
    return this.post('facebook/get_tester_name', {
      publicId: publicId,
      pageId: pageId
    });
  }

  public getConversationJson(publicId: string, conversationId: any, pageId: any, pageToken: string): Observable<any> {
    return this.post('facebook/get_conversation_json', {
      publicId: publicId,
      conversationId: conversationId,
      pageId: pageId,
      pageToken: pageToken
    });
  }

  public createEmptyСonversation(publicId: string): Observable<any> {
    return this.post('facebook/create_empty_conversation', {
      publicId: publicId
    });
  }

  public resetTester(publicId: string, pageId: any, pageName: any): Observable<any> {
    return this.post('facebook/reset_tester', {
      publicId: publicId,
      pageId: pageId,
      pageName: pageName
    });
  }

  public resetDemoTester(publicId: string, pageId: any, pageName: any): Observable<any> {
    return this.post('facebook/reset_demo_tester', {
      publicId: publicId,
      pageId: pageId,
      pageName: pageName
    });
  }

  public deleteConversation(publicId: string, conversationId: any): Observable<any> {
    return this.post('facebook/delete_conversation', {
      publicId: publicId,
      conversationId: conversationId
    });
  }

  public startConversation(publicId: string, conversationId: any, pageId: any, fbId: any): Observable<any> {
    return this.post('facebook/start_conversation', {
      publicId: publicId,
      conversationId: conversationId,
      pageId: pageId,
      fbId: fbId,
    });
  }

  public startDemoConversation(publicId: string, conversationId: any, pageId: any, fbId: any): Observable<any> {
    return this.post('facebook/start_demo_conversation', {
      publicId: publicId,
      conversationId: conversationId,
      pageId: pageId,
      fbId: fbId,
    });
  }

  public editConversation(publicId: string, conversationId: any): Observable<any> {
    return this.post('facebook/edit_conversation', {
      publicId: publicId,
      conversationId: conversationId,
    });
  }

  public fbSaveConversation(data: any): Observable<any> {
    return this.post('facebook/save_conversation', data);
  }

  public fbBroadcast(data: any): Observable<any> {
    return this.post('facebook/broadcast', data);
  }

  public fbInterrupt(publicId: string): Observable<any> {
    return this.post('facebook/interrupt', {
      publicId: publicId
    });
  }

  public checkInterrupt(publicId: string): Observable<any> {
    return this.post('facebook/check_interrupt', {
      publicId: publicId
    });
  }

  public createMenu(publicId: string, pageToken: any, menuArrays: any): Observable<any> {
    return this.post('facebook/menu_data', {
      publicId: publicId,
      pageToken: pageToken,
      menuArrays: menuArrays
    });
  }

  public deleteMenu(publicId: string, pageToken: any): Observable<any> {
    return this.post('facebook/delete_menu', {
      publicId: publicId,
      pageToken: pageToken
    });
  }

  public facebookGetTags(publicId: string): Observable<any> {
    return this.post('facebook/get_tags', {
      publicId: publicId
    });
  }

  public saveRule(data: any): Observable<any> {
    return this.post('facebook/save_rule', data);
  }

  public getPosts(publicId: string, data: any): Observable<any> {
    data.publicId = publicId;
    return this.post('facebook/get_posts', data);
  }

  public getPagesFb(publicId: string): Observable<any> {
    return this.post('facebook/get_pages', {
      publicId: publicId
    });
  }

  public changeStatusAutoReply(publicId: string, ruleId: any, value: any): Observable<any> {
    return this.post('facebook/change_status_auto_reply', {
      publicId: publicId,
      ruleId: ruleId,
      value: value
    });
  }

  public getAutoReply(publicId: string): Observable<any> {
    return this.post('facebook/get_auto_reply', {
      publicId: publicId
    });
  }

  public getSubscribers(publicId: string): Observable<any> {
    return this.post('facebook/get_subscribers', {
      publicId: publicId
    });
  }

  public getSubscribersWithImage(publicId: string): Observable<any> {
    return this.post('facebook/get_subscribers_with_image', {
      publicId: publicId
    });
  }

  public getConversation(publicId: string): Observable<any> {
    return this.post('facebook/get_conversation', {
      publicId: publicId
    });
  }

  public getFbToken(publicId: string, key: any): Observable<any> {
    return this.post('facebook/get_token', {
      publicId: publicId,
      key: key
    });
  }

  public deleteTester(publicId: string, pageId: any, fbId: any): Observable<any> {
    return this.post('facebook/delete_tester', {
      publicId: publicId,
      pageId: pageId,
      fbId: fbId
    });
  }

  public createTag(publicId: string, tagName: any): Observable<any> {
    return this.post('facebook/create_tag', {
      publicId: publicId,
      tagName: tagName,
    });
  }

  public checkAccess(publicId): Observable<any> {
    return this.post('facebook/check_access', {
      publicId: publicId,
    });
  }

  public deleteSubscriber(publicId: string, fbId: any, pageId: any): Observable<any> {
    return this.post('facebook/delete_subscriber', {
      publicId: publicId,
      fbId: fbId,
      pageId: pageId
    });
  }

  public deleteAutoReply(publicId: string, ruleId: any): Observable<any> {
    return this.post('facebook/delete_auto_reply', {
      publicId: publicId,
      ruleId: ruleId
    });
  }

  public facebookAddTag(fbId: any, pageId: any, tags: any): Observable<any> {
    return this.post('facebook/add_tag', {
      fbId: fbId,
      pageId: pageId,
      tags: tags
    });
  }

  public facebookRemoveTag(fbId: any, pageId: any, tags: any): Observable<any> {
    return this.post('facebook/remove_tag', {
      fbId: fbId,
      pageId: pageId,
      tags: tags
    });
  }

  public sendFbMsg(id: any, msg: string, accToken: string): Observable<any> {
    return this.http.post('https://graph.facebook.com/v2.6/me/messages?access_token=' + accToken,
      {"messaging_type": "RESPONSE",
      "recipient":{
      "id": id
    },
    "message":{
      "text": msg
    }});
  }

  public deleteDemoTesters(id: any, fbId): Observable<any> {
    return this.deleteRequest('facebook/demo_tester/' + id + '/' + fbId);
  }
}
