<div class="dashboard" [class.wide]="userService.smallSidebar">

  <div class="dashboard-header">
    <div class="head-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4">
            <h2>{{textService.currentText.createNewInvoice}}</h2>
            <div class="sub-head-container">
              <p>{{textService.currentText.createNewInvoiceSubTitle}}</p>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row invoice-name flex-row">
              <div class="col-md-4 text-right"> <label>{{textService.currentText.invoiceName}}</label></div>
              <div class="col-md-8 mb-3"> <input class="w-100" matInput type="text" #invName [class.has-danger]="invoiceTemplateNameError"
                  [(ngModel)]="invoiceData.invoiceTemplateName"
                  [placeholder]="textService.currentText.invoiceNamePlaceholder" name="invoiceName">
              </div>
              <div class="col-md-4 d-flex align-items-center justify-content-end">
                <label class="flex mb-0">
                  {{textService.currentText.invoiceMarkeplaceTooltipTitle}}
                  <!-- <span class="opt-tooltip " (mouseenter)="showMarketplaceTooltip = true"
                    (mouseleave)="showMarketplaceTooltip = false">
                    <span>?</span>
                    <span class="help-tooltip" *ngIf="showMarketplaceTooltip">
                      <p>{{textService.currentText.invoiceMarkeplaceTooltip}}</p>
                      <p>{{textService.currentText.invoiceMarkeplaceTooltip2}}</p>
                      <p>{{textService.currentText.invoiceMarkeplaceTooltip3}}</p>
                    </span>
                  </span> -->
                </label>
              </div>
              <div class="col-md-5">
                <div class="select-wrapper custom-selects mr-0"
                  (click)="functions.setDropDownPosition(salesChannels, 'salesChannels')" #salesChannels
                  [class.active]="channelListSelect?.panelOpen">
                  <mat-form-field class="w-100">
                    <mat-select [placeholder]="textService.currentText.marketplace" shouldPlaceholderFloat="false"
                      panelClass="default-dropdown salesChannels" disableRipple #channelListSelect
                      [(value)]="invoiceData.invoiceTemplateSalesChannels" [formControl]="selectMarketplace" required>
                      <mat-option *ngFor="let select of localChannels" [value]="select.value" [disabled]="select.disabled">
                        <img src="{{select.image}}" class="dropdown-country">
                        {{ select.viewValue }}
                      </mat-option>
                      <!--<div class="inner-select-box">-->
                      <!--<button (click)="defaultFbaTemplate.channellist = []">Clear All</button>-->
                      <!--<button class="primary" (click)="selectAll()">Select All</button>-->
                      <!--</div>-->
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-3">
                <mat-checkbox *ngIf="checkMarketplace(invoiceData.invoiceTemplateSalesChannels)" class="square-checkbox" [(ngModel)]="invoiceData.status" [checked]="invoiceData.status" [class.uncheckedStatus]="!invoiceData.status">
                  {{textService.currentText.sendAutomatically}}</mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="steps-wrapper mt-4">
          <div class="step-item" [class.active]="currentStep >= 1" (click)="currentStep = 1;">
            <div class="bottom line"></div>
            <div class="step-number">
              1

            </div>
            <div class="step-name">
              {{textService.currentText.logo}}
            </div>
          </div>
          <div class="step-item" [class.active]="currentStep >= 2" (click)="currentStep = 2;">
            <div class="bottom line"></div>
            <div class="step-number">
              2
              <!-- <div class="top line"></div> -->

            </div>
            <div class="step-name">
              {{textService.currentText.name}}
            </div>
          </div>
          <div class="step-item" [class.active]="currentStep >= 3" (click)="currentStep = 3; ">
            <div class="bottom line"></div>
            <div class="step-number">
              3
              <!-- <div class="top line"></div> -->

            </div>
            <div class="step-name">
              {{textService.currentText.address}}
            </div>
          </div>
          <div class="step-item" [class.active]="currentStep >= 4" (click)="currentStep = 4; scrollToBottom();">
            <div class="bottom line"></div>
            <div class="step-number">
              4
              <!-- <div class="top line"></div> -->

            </div>
            <div class="step-name">
              {{textService.currentText.VAT}}
            </div>
          </div>
          <div class="step-item" [class.active]="currentStep >= 5" (click)="currentStep = 5;">
            <div class="step-number">
              5
              <!-- <div class="top line"></div> -->
            </div>
            <div class="step-name">
              {{textService.currentText.notes}}
            </div>
          </div>
        </div>
      </div>


    </div>

  </div>
  <div class="dashboard-container">
    <div class="invoice-wrapper">

      <div class="file-wrapper">
        <div class="dnd-overlay" *ngIf="currentStep === 1" appDnd (filesChangeEmiter)="onFilesChange($event)">

        </div>
        <div class="overlay" *ngIf="currentStep != 0">
        </div>
        <div class="invoice-file" (click)="currentStep = 1">
          <div class="top-row row">
            <div class="col col-lg-8 col-md-6">
              <div class="logo">
                <img
                  [src]="invoiceData.invoiceTemplateLogoURL ? invoiceData.invoiceTemplateLogoURL : 'assets/images/product-placeholder.png'"
                  alt="">
              </div>
              <div class="title-descr">
                <h3>{{invoiceData.invoiceTemplateCompanyName || 'Example Inc.'}}</h3>
                <p>
                  {{ invoiceData.invoiceTemplateAddress1 || 'address1'}} <br>
                  {{ invoiceData.invoiceTemplateAddress2 || 'address2'}} <br>
                  {{ invoiceData.invoiceTemplateAddress3 || 'address3'}} <br>
                </p>
              </div>
            </div>
            <div class="col col-lg-4 col-md-6">
              <h3>{{textService.currentText.invoice}}</h3>
              <div class="date-number-row">
                <div class="small-row">
                  <div class="col">
                    {{textService.currentText.invoiceDate}}:
                  </div>
                  <div class="col">
                    2018-07-16
                  </div>
                </div>
                <div class="small-row">
                  <div class="col">
                    {{textService.currentText.invoiceNumber}}:
                  </div>
                  <div class="col">
                    123-4
                  </div>
                </div>
              </div>
              <div class="bill-row">
                <div class="col">
                  <h4>{{textService.currentText.billTo}}: </h4>
                  <p>
                    {{ textService.currentText.billToText4}} <br>
                    {{ textService.currentText.billToText1}} <br>
                    {{ textService.currentText.billToText2}} <br>
                    {{ textService.currentText.billToText3}} <br>
                  </p>

                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <div class="invoice-table">
              <div class="table-header">
                <div class="tr">
                  <div class="td">{{textService.currentText.productId}}</div>
                  <div class="td">{{textService.currentText.productName}}</div>
                  <div class="td">{{textService.currentText.quantity}}</div>
                  <div class="td">{{textService.currentText.unitPrice}}</div>
                  <div class="td">{{textService.currentText.total}}</div>
                </div>
              </div>
              <div class="table-body">
                <div class="tr">
                  <div class="td">sku</div>
                  <div class="td">Product name</div>
                  <div class="td">4.0</div>
                  <div class="td">14.1532</div>
                  <div class="td">53.80</div>
                </div>
                <div class="tr">
                  <div class="td"></div>
                  <div class="td"></div>
                  <div class="td"></div>
                  <div class="td"></div>
                  <div class="td"></div>
                </div>
                <div class="tr">
                  <div class="td"></div>
                  <div class="td"></div>
                  <div class="td"></div>
                  <div class="td"></div>
                  <div class="td"></div>
                </div>
                <div class="tr">
                  <div class="td"></div>
                  <div class="td"></div>
                  <div class="td"></div>
                  <div class="td"></div>
                  <div class="td"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-row">
            <div class="notes-col">
              <h3>{{textService.currentText.notes}}</h3>
              <div class="notes-area">
                {{invoiceData.invoiceTemplateNotes || textService.currentText.invoiceNotePlacehoder}}
              </div>
            </div>
            <div class="total-col">
              <div class="total-row d-flex justify-content-between">
                <h3>{{textService.currentText.subTotal}}</h3>
                <div class="value">
                  45 USD
                </div>
              </div>
              <div class="total-row d-flex justify-content-between mt-2">
                <h3>{{textService.currentText.vat}}
                  <ng-container *ngIf="invoiceData?.invoiceTemplateVat > 0"> ({{invoiceData?.invoiceTemplateVat}} %)
                  </ng-container>
                </h3>
                <div class="value">
                  45 USD
                </div>
              </div>
              <div class="total-row d-flex justify-content-between mt-2">
                <h3>{{textService.currentText.totalPrice}}</h3>
                <div class="value">
                  45 USD
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="upload-file-window window" *ngIf="currentStep === 1" appDnd
          (filesChangeEmiter)="onFilesChange($event)">
          <div class="window-content">
            <div class="dropzone">
              <label class="file-label closeMenuDet" for="logo-upload"
                *ngIf="!invoiceData?.invoiceTemplateLogoURL?.length">
                {{textService.currentText.uploadImage}}
              </label>
              <img
                [src]="invoiceData.invoiceTemplateLogoURL ? invoiceData.invoiceTemplateLogoURL : 'assets/images/product-placeholder.png'"
                alt="">
            </div>
            <div class="text">
              <h5>{{textService.currentText.invoiceStep1Title1}} <label class="lowercase"
                  for="logo-upload">{{textService.currentText.upload}}</label>
                {{textService.currentText.invoiceStep1Title2}} </h5>

              <input type="file" (change)="onFilesChange($event.srcElement?.files)" id="logo-upload"
                accept="image/jpeg,image/png">
              <p>{{textService.currentText.invoiceStep1Text}} </p>
            </div>
          </div>
          <input matInput type="text" #logURL [(ngModel)]="invoiceData.invoiceTemplateLogoURL" name="logUrl">
          <div class="window-footer">
            <div class="required">

            </div>
            <div class="buttons">
              <!--<button mat-button-->
              <!--class="btn-default"-->
              <!--(click)="currentStep = 2">{{textService.currentText.skip}}</button>-->
              <button mat-button class="btn-primary" (click)="currentStep = 2">{{textService.currentText.next}}</button>
            </div>
          </div>

        </div>
        <div class="invoice-name-window window" *ngIf="currentStep === 2">
          <div class="window-content">
            <h5>{{textService.currentText.invoiceStep2Title1}} <span>{{textService.currentText.companyName}} </span>
            </h5>
            <input matInput type="text" #email2 [(ngModel)]="invoiceData.invoiceTemplateCompanyName" name="invName">
            <!--<input type="text" matInput [ngModel]="invoiceName">-->
          </div>
          <div class="window-footer">
            <div class="required">
              <img src="assets/icons/invoice-required.svg" alt="">
              <p>This step is required</p>
            </div>
            <div class="buttons">
              <button mat-button class="btn-default" (click)="currentStep = 1">{{textService.currentText.back}}</button>
              <button mat-button mat-raised-button [disabled]="invoiceData.invoiceTemplateCompanyName?.length === 0"
                class="btn-primary" (click)="currentStep = 3">{{textService.currentText.next}}</button>
            </div>

          </div>
        </div>
        <div class="invoice-name-window window" *ngIf="currentStep === 3">
          <div class="window-content">
            <h5>{{textService.currentText.invoiceStep3Title1}} <span>{{textService.currentText.contactInfo}} </span>
              {{textService.currentText.invoiceStep3Title2}}</h5>
            <input matInput placeholder="Info 1" type="text" #adress1 [(ngModel)]="invoiceData.invoiceTemplateAddress1"
              name="adr1">
            <input matInput placeholder="Info 2" type="text" #adress2 [(ngModel)]="invoiceData.invoiceTemplateAddress2"
              name="adr2">
            <input matInput placeholder="Info 3" type="text" #adress3 [(ngModel)]="invoiceData.invoiceTemplateAddress3"
              name="adr3">
          </div>
          <div class="window-footer">
            <div class="required">
            </div>
            <div class="buttons">
              <button mat-button class="btn-default" (click)="currentStep = 2">{{textService.currentText.back}}</button>
              <button mat-button mat-raised-button class="btn-primary"
                (click)="currentStep = 4; scrollToBottom()">{{textService.currentText.next}}</button>
            </div>

          </div>
        </div>
        <div class="invoice-name-window window step4" *ngIf="currentStep === 4">
          <div class="window-content">
            <h5>{{textService.currentText.invoiceStep4Title1}} <span>{{textService.currentText.invoiceStep4Title2}}
              </span> </h5>
            <input matInput type="number" [placeholder]="textService.currentText.imageURL" #vatNumber
              [(ngModel)]="invoiceData.invoiceTemplateVat" name="vatNumber">
          </div>
          <div class="window-footer">
            <div class="required">
              <img src="assets/icons/invoice-required.svg" alt="">
              <p>This step is required</p>
            </div>
            <div class="buttons">
              <button mat-button class="btn-default"
                (click)="currentStep = 3; scrollToTop()">{{textService.currentText.back}}</button>
              <button mat-button mat-raised-button
                [disabled]="invoiceData.invoiceTemplateVat === 0 || invoiceData.invoiceTemplateVat?.length === 0"
                class="btn-primary" (click)="currentStep = 5;">{{textService.currentText.next}}</button>
            </div>

          </div>
        </div>
        <div class="invoice-name-window window step5" *ngIf="currentStep === 5">
          <div class="window-content">
            <h5>{{textService.currentText.invoiceStep5Title1}} <span class="lowercase">{{textService.currentText.notes}}
              </span>
              {{textService.currentText.invoiceStep5Title2}}</h5>
            <textarea #notesText [(ngModel)]="invoiceData.invoiceTemplateNotes" name="notes" type="text" rows="6">
            </textarea>
          </div>
          <div class="window-footer">
            <div class="required">
            </div>
            <div class="buttons">
              <button mat-button class="btn-default"
                (click)="currentStep = 4;">{{textService.currentText.back}}</button>
              <button mat-button mat-raised-button class="btn-primary"
                (click)="currentStep = 0;">{{textService.currentText.finish}}</button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="page-footer">
      <button mat-button mat-raised-button (click)="clearAll()" class="primary-btn white">
        {{textService.currentText.clearAll}}
      </button>
      <div class="buttons d-flex justify-content-between">
        <button mat-button mat-raised-button (click)="navigateToInvoicing()" class="primary-btn white">
          {{textService.currentText.cancel}}
        </button>
        <button mat-button mat-raised-button (click)="saveInvoice()" class="primary-btn">
          {{textService.currentText.save}}
        </button>
      </div>
    </div>
  </div>
</div>