import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Functions} from '../../../services/functions';
import {AccountSettings} from '../../../services/account-settings';
import {UserService} from '../../../services/user.service';
import {TextService} from '../../../services/text.service';
import {ConstService} from '../../../services/const-service';

@Component({
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {
  public currentProduct: any;
  public defaultProduct: any;
  public selects: any;
  public showAllProducts = false;
  public localCurrencies: any;

  constructor(public dialogRef: MatDialogRef<EditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public functions: Functions,
              public textService: TextService,
              public userService: UserService,
              public accountSettings: AccountSettings,
              public constService: ConstService) {
  }

  public ngOnInit(): void {
    this.defaultProduct = JSON.parse(JSON.stringify(this.data.products));
    this.currentProduct = JSON.parse(JSON.stringify(this.data.products));
    this.localCurrencies = JSON.parse(JSON.stringify(this.constService.currencies));
    if (this.currentProduct.currency) {
        this.localCurrencies.push({
            value: this.currentProduct.currency,
            viewValue: this.currentProduct.currency
    });
    } else {
        this.currentProduct.currency = 'USD';
    }
    if (this.userService.currentCountry === 'US') {
      if (this.currentProduct.shortNameCOM || this.currentProduct.shortNameCA || this.currentProduct.shortNameMX ) {
        this.showAllProducts = true;
      }
    } else if (this.userService.currentCountry === 'UK') {
      if (this.currentProduct.shortNameUK || this.currentProduct.shortNameFR ||
         this.currentProduct.shortNameDE || this.currentProduct.shortNameIT) {
        this.showAllProducts = true;
      }
    } else if (this.userService.currentCountry === 'AU') {
      if (this.currentProduct.shortNameAU) {
        this.showAllProducts = true;
      }
    } else if (this.userService.currentCountry === 'JP') {
      if (this.currentProduct.shortNameJP) {
        this.showAllProducts = true;
      }
    }
    // if (this.currentProduct.shortnameCOM || this.currentProduct.shortnamesJP || this.currentProduct.shortnamesUK) {
    //   this.showAllProducts = true;
    // }
  }

  public saveData(): void {
    this.currentProduct.publicId = this.userService.currentUser.id;
    this.currentProduct.index = this.data.index;
    if (!this.currentProduct.cost) {
      this.currentProduct.cost = 0;
    }
    this.accountSettings.saveProductSettings(this.currentProduct).subscribe((data) => {
      if (data) {
        this.dialogRef.close(this.currentProduct);
      }
    });
  }

  public resetAll(): void {
    this.currentProduct.shortName = this.currentProduct.fullName;
    // this.currentProduct.shortName = '';
    if (this.userService.currentCountry === 'AU') {
      this.currentProduct.shortNameAU = '';
    }
    if (this.userService.currentCountry === 'US') {
      this.currentProduct.shortNameCOM = '';
      this.currentProduct.shortNameCA = '';
      this.currentProduct.shortNameMX = '';
    }
    if (this.userService.currentCountry === 'UK') {
      this.currentProduct.shortNameUK = '';
      this.currentProduct.shortNameFR = '';
      this.currentProduct.shortNameIT = '';
      this.currentProduct.shortNameDE = '';
      this.currentProduct.shortNameES = '';
    }
    if (this.userService.currentCountry === 'JP') {
      this.currentProduct.shortNameJP = '';
    }
  }
}
