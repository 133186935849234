import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ErrorsComponent>) {
  }

  ngOnInit() {
  }

  closePopup() {
    this.dialogRef.close();
  }

}
