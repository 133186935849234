import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { TextService } from '../../../../services/text.service';
import { ConstService } from '../../../../services/const-service';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoicingService } from '../../../../services/invoicing.service';
import { Functions } from '../../../../services/functions';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
    selector: 'create-new-invoice',
    templateUrl: './create-new-invoice.component.html',
    styleUrls: ['./create-new-invoice.component.scss']
})

export class CreateNewInvoiceComponent implements OnInit, AfterViewInit {
    @ViewChild('invName', { static: true }) inputEl: ElementRef;
    public currentStep = 0;
    public dropArea: any;
    public invoiceName = '';
    public vat: number;
    public invoiceData: any;
    public invoiceTemplateNameError: boolean;
    public canDranAndDrop: false;
    public localChannels: any;
    public showMarketplaceTooltip: any;
    public autoSend = false;
    public usedMp: any;
    selectMarketplace = new FormControl('', [Validators.required]);

    constructor(public userService: UserService,
        public router: Router,
        public invoiceService: InvoicingService,
        public functions: Functions,
        public matDialog: MatDialog,
        public activatedRoute: ActivatedRoute,
        public textService: TextService,
        public constService: ConstService,
        public mixpanelService: MixpanelService,
    ) {
        this.mixpanelService.init(null);
    }

    public ngOnInit() {
        this.invoiceData = {
            invoiceTemplateId: undefined,
            invoiceTemplateAddress1: '',
            invoiceTemplateAddress2: '',
            invoiceTemplateAddress3: '',
            invoiceTemplateLogoURL: '',
            invoiceTemplateCompanyName: '',
            invoiceTemplateNotes: '',
            invoiceTemplateName: '',
            invoiceTemplateVat: 0,
            invoiceTemplateSalesChannels: '',
            status: false,
        };
        this.localChannels = JSON.parse(JSON.stringify(this.constService.salesChannels));
        this.localChannels.splice(0, 1);
        this.activatedRoute.queryParams.subscribe((data) => {
            if (data.newInvoice) {
                this.marketplaceSelectBox(data.newInvoice);
            } else if (data.id && data.duplicate) {
                this.invoiceService.getInvoice(this.userService.currentUser.id, data.id).subscribe(invoice => {
                    this.invoiceData = invoice;
                    if (!this.invoiceData.invoiceTemplateLogoURL) {
                        this.invoiceData.invoiceTemplateLogoURL = '';
                    }
                    this.invoiceData.invoiceTemplateName = 'Copy of ' + this.invoiceData.invoiceTemplateName;
                    delete this.invoiceData["invoiceTemplateId"];
                    this.invoiceData.invoiceTemplateSalesChannels = '';
                    this.marketplaceSelectBox(data.usedMp);
                });
            } else if (data.id) {
                this.invoiceService.getInvoice(this.userService.currentUser.id, data.id).subscribe(invoice => {
                    this.invoiceData = invoice;
                    this.invoiceData.invoiceTemplateSalesChannels = this.invoiceData.invoiceTemplateSalesChannels[0];
                    this.marketplaceSelectBox(data.usedMp);
                    if (!this.invoiceData.invoiceTemplateLogoURL) {
                        this.invoiceData.invoiceTemplateLogoURL = '';
                    }
                });
            }

        });
    }

    public marketplaceSelectBox(usedMp) {
        for (let i = 0; i < usedMp.length; i++) {
            for (let j = 0; j < this.localChannels.length; j++) {
                if (usedMp[i] == this.localChannels[j].value) {
                    this.localChannels[j].disabled = true;
                    break;
                }
            }
        }
    }

    public checkMarketplace(marketplace) {
        // for (let i = 0; i < marketplace.length; i++) {
        if (marketplace == 'Amazon.co.uk' || marketplace == 'Amazon.fr' || marketplace == 'Amazon.it' || marketplace == 'Amazon.de' || marketplace == 'Amazon.es') {
            return true;
        } else {
            return false;
        }
        // }
    }

    public ngAfterViewInit() {
        this.inputEl.nativeElement.focus();
    }

    public scrollToBottom(): void {
        window.scrollTo(0, document.body.scrollHeight);
    }

    public scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    public onFilesChange(fileList: FileList) {
        this.userService.loaderObesrver.next(true);
        this.userService.uploadImage(fileList, 'invoicing/upload_image');
        this.userService.fileLoaded().subscribe((fileData) => {
            const imageLink = JSON.parse(fileData.currentTarget.response).url.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
            this.invoiceData.invoiceTemplateLogoURL = imageLink
            this.userService.loaderObesrver.next(false);
        });
    }


    public clearAll(): void {
        this.invoiceData = {
            invoiceTemplateId: undefined,
            invoiceTemplateAddress1: '',
            invoiceTemplateAddress2: '',
            invoiceTemplateAddress3: '',
            invoiceTemplateLogoURL: '',
            invoiceTemplateCompanyName: '',
            invoiceTemplateNotes: '',
            invoiceTemplateName: '',
            invoiceTemplateVat: 0
        }
        this.currentStep = 0;
    }

    public navigateToInvoicing(): void {
        this.router.navigate(['/dashboard/invoicing']);
    }

    public saveInvoice() {
        if (!this.invoiceData.invoiceTemplateName) {
            this.currentStep = 0;
            window.scrollTo(0, 0);
            this.invoiceData.invoiceTemplateName = this.textService.currentText.errorRequired;
            this.invoiceTemplateNameError = true;
            setTimeout(() => {
                this.invoiceData.invoiceTemplateName = '';
                this.invoiceTemplateNameError = false;
            }, 2000);
            return;
        }
        if (this.invoiceData.invoiceTemplateSalesChannels == '') {
            window.scrollTo(0, 0);
            this.selectMarketplace.markAsTouched();
            return;
        }
        if (!this.invoiceData.invoiceTemplateCompanyName) {
            this.currentStep = 2;
            window.scrollTo(0, 0);
            return;
        }
        if (!this.invoiceData.invoiceTemplateVat) {
            this.currentStep = 4;
            window.scrollTo(0, document.body.scrollHeight);
            return;
        }
        this.invoiceData.publicId = this.userService.currentUser.id;
        // if (this.invoiceData.invoiceTemplateSalesChannels.length === this.localChannels.length) {
        //     this.showSalesChannelError();
        //     return;
        // }
        this.invoiceService.saveIncoice(this.invoiceData).subscribe(res => {
            if (res.isSuccess) {
                this.userService.showNotification = true;
                this.userService.notificationMessage = this.textService.currentText.saveInoviceSuccessText;
                this.userService.notificationTitle = this.textService.currentText.success;
                this.router.navigate(['/dashboard/invoicing']);
            } else {
                this.showSaveErrorDialog();
            }
        });
    }

    public showSaveErrorDialog(): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'custom',
                'title': this.textService.currentText.warning,
                'message': this.textService.currentText.saveInvoiceError
            },
        }).afterClosed().subscribe();
    }

    public showSalesChannelError(): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'custom',
                'title': this.textService.currentText.invoiceChannelsTitleError,
                'message': this.textService.currentText.invoiceChannelsError
            },
        }).afterClosed().subscribe();
    }

}
