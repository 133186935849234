<div class="dashboard" [class.wide]="this.userService.smallSidebar">
  <div class="dashboard-header">
    <div class="head-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-7">
            <h2>{{pageTitle}}</h2>
            <span class="action-el edit-btn" (click)="backToFbaList()">{{backButton}}</span>
          </div>
          <div class="col-md-5 d-flex align-items-center justify-content-center justify-content-md-end mt-4 mt-md-0">
            <button *ngIf="!reviewRequest" mat-button mat-stroked-button class="secondary-outline-btn btn-large mr-4 font-size-18"
              (click)="googleAnalyticsEventsService.emitEvent('Button', 'Test FBA/FBM', 'Click', 1);mixpanelService.track('q automated messaging - template test',{'page':'edit fba\edit fbm'}); testTemplate()">
              {{textService.currentText.test}}
            </button>
            <button mat-button mat-raised-button color="secondary" class="btn-large font-size-18"
              (click)="googleAnalyticsEventsService.emitEvent('Button', 'Save FBA/FBM', 'Click', 1); mixpanelService.track('q automated messaging - template save',{'page':'edit fba\edit fbm'});checkForm()">
              {{textService.currentText.save}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-new-fba">
    <div class="flex">
      <div class="dashboard-editor dashboard-container--main">
        <div class="dashboard-container new-template">
          <div class="d-flex align-items-start justify-content-between templatename">
            <h3>{{defaultFbaTemplate.name ? defaultFbaTemplate.name : textService.currentText.templateName}}</h3>
            <div class="text-center">
              <span [ngClass]="defaultFbaTemplate.status ? 'text-success' : 'text-danger'">{{defaultFbaTemplate.status ? textService.currentText.active : textService.currentText.inactive}}</span>
              <!-- <p *ngIf="reviewRequest" class="reset-open-rate d-flex align-items-center"> <img class="mr-2" src="assets/icons/icon_refresh.png" alt="">Reset Open Rate</p> -->
            </div>
          </div>
          <div class="grid-section head-grid custom-selects" [formGroup]="fbaForm" name="form" novalidate>
            <div class="row">
              <div class="col-md-6 mb-2" [class.has-danger]="errors?.name">
                <div class="row align-items-center">
                  <div class="col-4">
                    <div class="label-name">
                      {{textService.currentText.name}}
                    </div>
                  </div>
                  <div class="col-8">
                    <input matInput formControlName="name" #name [placeholder]="textService.currentText.templateName" required minlength="1" type="text" name="templateName"
                      [(ngModel)]="defaultFbaTemplate.name">
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-2 m09">
                <div class="row align-items-center">
                  <div class="col-4">
                    <div class="label-name">
                      {{textService.currentText.products}}
                    </div>
                  </div>
                  <div class="select-wrapper select-holder col-8" (mouseenter)="showProductTooltip = true"
                    (mouseleave)="showProductTooltip = false"
                    (click)="functions.setDropDownPosition(products, 'products')" #products
                    [class.active]="defaultFbaSelect?.panelOpen">
                    <mat-form-field class="w-100">
                      <mat-select shouldPlaceholderFloat="false" formControlName="products"
                        panelClass="default-dropdown products" (selectionChange)="showSpecProducts()" disableRipple
                        [(ngModel)]="defaultFbaTemplate.products" [placeholder]="defaultFbaTemplate.placeholder"
                        #defaultFbaSelect>
                        <mat-option *ngFor="let select of constService.products" [value]="select.value">
                          {{ select.viewValue }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="spec-product-tooltip"
                      *ngIf="defaultFbaTemplate?.productsskus?.length > 0 && showProductTooltip == true"
                      (mouseenter)="showProductTooltip = true" (mouseleave)="showProductTooltip = false">
                      <h4>SELECTED PRODUCTS:</h4>
                      <ol>
                        <li *ngFor="let product of defaultFbaTemplate?.productsskus">
                          <span>{{product.shortName}}</span>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-2" [class.has-danger]="errors?.when">
                <div class="row align-items-center">
                  <div class="col-4">
                    <div class="label-name">
                      <span>{{textService.currentText.when}}</span>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="select-wrapper" *ngIf="!errors?.when"
                      (click)="functions.setDropDownPosition(when, 'when')" #when
                      [class.active]="whenSelect?.panelOpen">
                      <mat-form-field class="w-100">
                        <mat-select placeholder="" formControlName="when" shouldPlaceholderFloat="false"
                          panelClass="default-dropdown when"
                          (selectionChange)="defaultFbaTemplate.timeframe === 0 ? defaultFbaTemplate.timing = '' : defaultFbaTemplate.timing = 'MORNING';"
                          #whenSelect disableRipple [(ngModel)]="defaultFbaTemplate.timeframe">
                          <mat-option *ngFor="let select of constService.selectsDays" [value]="select.value">
                            {{ select.viewValue }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <input matInput *ngIf="errors?.when" type="text" name="subject" [value]="whenError">
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-2 mb-2" [class.has-danger]="errors?.after">
                <div class="row align-items-center">
                  <div class="col-4">
                    <div class="label-name">
                      <span>{{textService.currentText.after}}</span>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="select-wrapper" (click)="functions.setDropDownPosition(after, 'after')"
                      *ngIf="!errors?.after" #after>
                      <mat-form-field class="w-100" *ngIf="userService.currentPage === 'fba'">
                        <mat-select placeholder="" [disabled]="afterDisabled" shouldPlaceholderFloat="false"
                          panelClass="default-dropdown after" formControlName="after" #afterSelect disableRipple
                          [(ngModel)]="defaultFbaTemplate.event">
                          <mat-option *ngFor="let select of constService.after" [value]="select.value">
                            {{ select.viewValue }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="w-100" *ngIf="userService.currentPage === 'fbm'">
                        <mat-select placeholder="" [disabled]="afterDisabled" shouldPlaceholderFloat="false"
                          panelClass="default-dropdown after" formControlName="after" #afterSelect disableRipple
                          [(ngModel)]="constService.afterFBM.value">
                          <mat-option [value]="constService.afterFBM.value">
                            {{ constService.afterFBM.viewValue }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <input matInput *ngIf="errors?.after" type="text" name="subject" [value]="afterError">
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-4 col-md-2">
                <div class="label-name">
                  <span>{{textService.currentText.subject}}</span>
                </div>
              </div>
              <div class="col-8 col-md-10">
                <input matInput (click)="pasteToSubject= true" placeholder="{{textService.currentText.mailSubject}}" #subject formControlName="subject" type="text"
                  name="subject" [(ngModel)]="defaultFbaTemplate.subject">
              </div>
            </div>
          </div>
          <div class="row" *ngIf="reviewRequest">
            <div class="col-12">
              <div class="card shadow-sm">
                <div class="card-body" [innerHtml]="reviewRequestContent">
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!reviewRequest">
            <div class="col-12">
              <div id="summernote" class="editor "></div>
              <div class="close-btn-editor" (click)="disableFullScreen()" *ngIf="fullScreen"></div>
            </div>
          </div>
          <div class="center-grid mt-4" *ngIf="!reviewRequest">
            <div>
              <h3>{{textService.currentText.editTemplateAddVariableTitle}}</h3>
              <p>{{textService.currentText.editTemplateAddVariableSubTitle}}
                <span class="action-el" (click)="openVariablesTooltip()">click here</span>
              </p>
            </div>
          </div>
          <div class="row custom-selects align-items-center" *ngIf="!reviewRequest">
            <div class="col-md-6">
              <div class="row align-items-center">
                <div class="col-4 col-lg-3">
                  <div class="label-name">
                    <span>{{textService.currentText.variables}}</span>
                  </div>
                </div>
                <div class="select-wrapper col-8 col-lg-9"
                  (click)="functions.setDropDownPosition(variables, 'variables')" #variables
                  [class.active]="variablesSelect?.panelOpen">
                  <mat-form-field class="w-100">
                    <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown variables" #variablesSelect
                      disableRipple [(ngModel)]="selectedVariable">
                      <ng-container *ngIf="this.userService.currentPage === 'fbm'">
                        <mat-option *ngFor="let select of constService.variablesFBM" [value]="select">
                          {{ select }}
                        </mat-option>
                      </ng-container>
                      <ng-container *ngIf="this.userService.currentPage === 'fba'">
                        <mat-option *ngFor="let select of constService.variablesFBA" [value]="select">
                          {{ select }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-3 mt-md-0">
              <button mat-button mat-raised-button class="font-size-13" color="secondary"
                (click)="pushToEditor(selectedVariable, 'editor')">
                {{textService.currentText.editTemplateAddVariableTitle}}
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="dashboard-container new-template" *ngIf="!reviewRequest">
          <div class="center-grid grid-section alt-editor" [class.with-margin]="fbaForm?.controls?.subject2">
            <div class="grid-col add-alternatives">
              <h3>{{textService.currentText.addAlternatives}}</h3>
              <p>{{textService.currentText.addAlternativesText}}</p>
            </div>
            <div class="grid-col flex space-around">
              <div class="col my-3 my-md-0">
                <h4>{{textService.currentText.selectAltContentText}}</h4>
                <mat-checkbox [(ngModel)]="showAltSubject" (change)="checkAltSubject($event)" disableRipple
                  color="primary">
                  {{textService.currentText.addAltSubject }}</mat-checkbox>
                <mat-checkbox [(ngModel)]="showAltContent" (change)="checkAltContent($event)" disableRipple
                  color="primary">
                  {{textService.currentText.addAltContent}}</mat-checkbox>
              </div> -->

              <!--<button mat-button class="default-btn" (click)="fbaForm?.controls?.subject2 ? removeAltSubject() : addAltSubject();">-->
              <!--{{!fbaForm?.controls?.subject2 ? textService.currentText.addAlternatives : textService.currentText.removeAlternatives}}-->
              <!--</button>-->
            <!-- </div>
            <div class="grid-col">
              <div class="col">
                <h4>{{textService.currentText.resetTheOpenRate}}</h4> -->
                <!--<p>{{textService.currentText.resetOpenRateLearnMore}} <span class="action-el">{{textService.currentText.clickHere}}</span> </p>-->
                <!-- <button mat-button [disabled]="!defaultFbaTemplate.aId" class="default-btn"
                  (click)="openResetOpenRateConfirm()">
                  {{textService.currentText.reset}}
                </button>
              </div>
            </div>
          </div>
          <div class="subject" *ngIf="fbaForm?.controls?.subject2" [class.has-danger]="errors?.subject2"
            [formGroup]="fbaForm">
            <div class="row">
              <div class="col-4 col-md-2">
                <div class="label-name mr-3" for="subject2">
                  <span>{{textService.currentText.subject}}2</span>
                </div>
              </div>
              <div class="col-8 col-md-10">
                <input matInput (click)="pasteToSubject2= true" *ngIf="fbaForm?.controls?.subject2" #subject2
                  formControlName="subject2" type="text" name="subject2">
              </div>
            </div>
          </div>
          <div id="summernote2" class="editor"></div>
          <div class="center-grid  grid-section mt-4" *ngIf="showAltContent">
            <div>
              <h3>{{textService.currentText.editTemplateAddVariableTitle}}</h3>
              <p>{{textService.currentText.editTemplateAddVariableSubTitle}}
                <span class="action-el" (click)="mixpanelService.track('q automated messaging - add Variable help');openVariablesTooltip()">click here</span>
              </p>
            </div>
            <div class="row custom-selects align-items-center">
              <div class="col-md-6">
                <div class="row align-items-center">
                  <div class="col-4 col-lg-3">
                    <div class="label-name">
                      <span>{{textService.currentText.variables}}</span>
                    </div>
                  </div>
                  <div class="select-wrapper col-8 col-lg-9"
                    (click)="mixpanelService.track('q automated messaging - add Variable',{'type': variablesSelect2});functions.setDropDownPosition(variables2, 'variables2')" #variables2
                    [class.active]="variablesSelect2?.panelOpen">
                    <mat-form-field class="w-100">
                      <mat-select placeholder="" shouldPlaceholderFloat="false" panelClass="default-dropdown variables"
                        #variablesSelect2 disableRipple [(ngModel)]="selectedVariable2">
                        <ng-container *ngIf="this.userService.currentPage === 'fbm'">
                          <mat-option *ngFor="let select of constService.variablesFBM" [value]="select">
                            {{ select }}
                          </mat-option>
                        </ng-container>
                        <ng-container *ngIf="this.userService.currentPage === 'fba'">
                          <mat-option *ngFor="let select of constService.variablesFBA" [value]="select">
                            {{ select }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-3 mt-md-0">
                <button mat-button mat-raised-button color="secondary" class="font-size-13"
                  (click)="pushToEditor(selectedVariable2, 'editor2')">
                  {{textService.currentText.editTemplateAddVariableTitle}}
                </button>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="dashboard-container dashboard-container--side custom-selects mr-0">
        <div class="options-box">
          <div class="d-flex align-items-start justify-content-between">
            <h3>{{textService.currentText.templateOptions}}</h3>
            <img *ngIf="defaultFbaTemplate.status" class="mt-n2" src="assets/images/active.png" alt="">
            <img *ngIf="!defaultFbaTemplate.status" class="mt-n2" src="assets/images/InActive.png" alt="">
          </div>
          <div class="opt-row opt-row--active justify-content-md-start">
            <div class="a-side">
              <div class="opt-tooltip" (click)="openStatusTooltip()">
                <span>?</span>
              </div>
              <div class="opt-label">
                {{textService.currentText.dialogConfirmTemplateText1}}
              </div>
            </div>
            <div class="b-side">
              <mat-radio-group [(ngModel)]="defaultFbaTemplate.status">
                <mat-radio-button disableRipple [value]="true">Active</mat-radio-button>
                <mat-radio-button disableRipple [value]="false">Inactive</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="opt-row">
            <mat-checkbox class="checkbox-width" color="primary" disableRipple [(ngModel)]="defaultFbaTemplate.negative_feedback">
              {{textService.currentText.templateOption2}}
            </mat-checkbox>
          </div>
          <div class="opt-row">
            <mat-checkbox color="primary" disableRipple [(ngModel)]="defaultFbaTemplate.refund">
              {{textService.currentText.templateOption3}}
            </mat-checkbox>
          </div>
          <div class="opt-row">
            <mat-checkbox color="primary" disableRipple [(ngModel)]="defaultFbaTemplate.repeated_buyers">
              {{textService.currentText.templateOption4}}
            </mat-checkbox>
          </div>
          <div class="opt-row">
            <mat-checkbox color="primary" disableRipple [disabled]="!defaultFbaTemplate.repeated_buyers"
              [(ngModel)]="defaultFbaTemplate.only_for_repeated_buyers">
              {{textService.currentText.templateOption10}}
            </mat-checkbox>
          </div>
          <div class="opt-row">
            <mat-checkbox color="primary" disableRipple [(ngModel)]="defaultFbaTemplate.coupon_given">
              {{textService.currentText.templateOption5}}
            </mat-checkbox>
          </div>
          <div class="opt-row opt-row--column">
            <div class="a-side">
              <div class="opt-tooltip" (click)="openPromotionValueTooltip()">
                <span>?</span>
              </div>
              <div class="opt-label">
                {{textService.currentText.templateOption6}}
              </div>
            </div>
            <div class="b-side inner-row">
              <div class="select-wrapper" (click)="functions.setDropDownPosition(templateSend, 'promotionValueSelect')"
                #templateSend [class.active]="promotionValueSelect?.panelOpen">
                <ninja-tooltip *ngIf="isTooltip" (toggledChange)="isTooltip = $event" [text]="promotionNumber"
                  class="at-select left-align has-danger">
                </ninja-tooltip>
                <mat-form-field>
                  <mat-select placeholder="N/A (>, < , =)" shouldPlaceholderFloat="false"
                    panelClass="default-dropdown promotionValueSelect" #promotionValueSelect disableRipple
                    [(ngModel)]="defaultFbaTemplate.coupon_sign" [disabled]="!defaultFbaTemplate.coupon_given">
                    <mat-option *ngFor="let select of constService.promotionValues" [value]="select.value">
                      {{ select.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <input matInput (keydown)="$event.key === 'e' ? $event.preventDefault() : null" type="number"
                [(ngModel)]="defaultFbaTemplate.coupon_value">
            </div>
          </div>
          <div class="opt-row opt-row--column">
            <div class="a-side">
              <div class="opt-tooltip" (click)="openSpecSalesChannelsTooltip()">
                <span>?</span>
              </div>
              <div class="opt-label">
                {{textService.currentText.templateOption7}}
              </div>
            </div>
            <div class="b-side inner-row one-item">
              <div class="select-wrapper w-100" (click)="functions.setDropDownPosition(salesChannels, 'salesChannels')"
                #salesChannels [class.active]="channelListSelect?.panelOpen">
                <mat-form-field>
                  <mat-select [placeholder]="textService.currentText.allChannels" shouldPlaceholderFloat="false"
                    panelClass="default-dropdown salesChannels" multiple disableRipple #channelListSelect
                    [(value)]="defaultFbaTemplate.channellist">
                    <mat-option *ngFor="let select of localChannels" [value]="select.value">
                      {{ select.viewValue }}
                    </mat-option>
                    <div class="inner-select-box">
                      <button (click)="defaultFbaTemplate.channellist = []">Clear All</button>
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="opt-row opt-row--column" *ngIf="!reviewRequest">
            <div class="a-side">
              <div class="opt-tooltip" (click)="openCouponTooltip()">
                <span>?</span>
              </div>
              <div class="opt-label">
                {{textService.currentText.templateOption8}}
              </div>
            </div>
            <div class="b-side inner-row select-button">
              <div class="select-holder">
                <div (click)="functions.setDropDownPosition(PageName, 'days')" #PageName [class.active]="days.panelOpen"
                  class="select-wrapper coupon">
                  <mat-form-field>
                    <mat-select [placeholder]="textService.currentText.coupons" shouldPlaceholderFloat="false"
                      panelClass="default-dropdown days" disableRipple [(ngModel)]="selectedCoupon" #days name="page">
                      <mat-option *ngFor="let select of coupons" [value]="select">
                        {{ select.couponName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <button mat-button mat-raised-button color="secondary"
                (click)="mixpanelService.track('q automated messaging - add coupon',{'page':'edit fba\edit fbm'});pushToEditor('<Coupon:' + selectedCoupon.couponid + '>', 'editor')">
                {{textService.currentText.addCoupon}}
              </button>
            </div>
          </div>
          <div class="opt-row opt-row--column">
            <div class="a-side">
              <div class="opt-tooltip relative" *ngIf="defaultFbaTemplate.timeframe === 0"
                (mouseenter)="showTimingTooltip = true" (mouseleave)="showTimingTooltip = false">
                <span>!</span>
                <div class="timing-tooltip" [class.active]="showTimingTooltip">
                  {{textService.currentText.timingTooltipText2}}
                </div>
              </div>
              <div class="opt-tooltip" (click)="openTimingTooltip()">
                <span>?</span>
              </div>
              <div class="opt-label">
                {{textService.currentText.templateOption9}} ({{textService.currentText.PSTTime}})
              </div>
            </div>
            <div class="b-side inner-row one-item">
              <div class="select-wrapper w-100" (click)="functions.setDropDownPosition(timingSelect, 'timing')"
                #timingSelect [class.active]="timing?.panelOpen">
                <mat-form-field>
                  <mat-select [placeholder]="textService.currentText.templateOption9" shouldPlaceholderFloat="false"
                    [disabled]="defaultFbaTemplate.timeframe === 0" panelClass="default-dropdown timing" disableRipple
                    #timing [(value)]="defaultFbaTemplate.timing">
                    <mat-option *ngFor="let select of constService.timing" [value]="select.value">
                      {{ select.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="opt-row opt-row--column">
            <div class="a-side">
              <div class="opt-tooltip" (click)="openExludeProductTooltip()">
                <span>?</span>
              </div>
              <div class="opt-label">
                {{textService.currentText.excludeProducts}}
              </div>
            </div>
            <div class="b-side inner-row one-item">
              <div class="select-wrapper select-holder w-100" (mouseenter)="showExcludedProductTooltip = true"
                (mouseleave)="showExcludedProductTooltip = false"
                (click)="functions.setDropDownPosition(exProducts, 'exProducts')" #exProducts
                [class.active]="exProductsSelect?.panelOpen">
                <mat-form-field>
                  <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown exProducts"
                    [(ngModel)]="exclProducts" [ngModelOptions]="{standalone: true}"
                    (selectionChange)="showExcludeSpecProducts()" disableRipple
                    [placeholder]="defaultFbaTemplate.exludePlaceholder" #exProductsSelect>
                    <mat-option *ngFor="let select of constService.excludeProducts" [value]="select.value">
                      {{ select.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="spec-product-tooltip excluded"
                  *ngIf="defaultFbaTemplate?.excludedProducts?.length > 0 && showExcludedProductTooltip == true"
                  (mouseenter)="showExcludedProductTooltip = true" (mouseleave)="showExcludedProductTooltip = false">
                  <h4>EXCLUDED PRODUCTS:</h4>
                  <ol>
                    <li *ngFor="let product of defaultFbaTemplate?.excludedProducts">
                      <span>{{product.shortName}}</span>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="opt-row">-->
          <!--<div class="a-side">-->
          <!--<div class="opt-tooltip" (click)="openOrderTooltip()">-->
          <!--<span>?</span>-->
          <!--</div>-->
          <!--<div class="opt-label">-->
          <!--{{textService.currentText.resetOpenRate}}-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="b-side">-->
          <!--<button mat-button [disabled]="!defaultFbaTemplate.aId" class="default-btn" (click)="openResetOpenRateConfirm()">-->
          <!--{{textService.currentText.reset}}-->
          <!--</button>-->
          <!--</div>-->
          <!--</div>-->
        </div>
        <div class="upload-box" *ngIf="!reviewRequest">
          <h3>
            {{textService.currentText.editTemplateFileTitle}}
          </h3>
          <p>
            {{textService.currentText.editTemplateFileSubtitle}}
            <span class="action-el" (click)="openAddFileTooltip()"> {{textService.currentText.clickHere}}</span>
          </p>
          <div class="upload-row custom-selects justify-content-md-start">
            <div class="file-loading" [class.error-file-upload]="fileUploadError">
              <span class="percent-loading" *ngIf="currentUploadProgress > 0">{{currentUploadProgress}}%</span>
              <span class="percent-loading" *ngIf="fileUploadError">Incorrect file format or size</span>
              <div class="indicator" *ngIf="isFileLoadStart" [ngStyle]="{'width': currentUploadProgress + '%'}"></div>
            </div>
            <input class="upload-Input" #input type="file" name="file" [attr.multiple]="null"
              (change)="mixpanelService.track('q automated messaging - upload file', {'page':'edit fba\edit fbm'});fileUploader($event, input)" [accept]="constService.fileFormats">
            <button mat-button mat-raised-button color="secondary" (click)="input.click()" [disabled]="isFileLoadStart">
              {{textService.currentText.upload}}
            </button>
          </div>
          <div class="files-holder">
            <div class="file-item justify-content-md-start" *ngFor="let file of currentFiles; let i = index">
              <div class="file-item__row">
                <div class="file-icon"></div>
                <div class="file-name">{{file}}</div>
              </div>
              <div class="delete-btn ml-2" (click)="mixpanelService.track('q automated messaging - delete file', {'page':'edit fba\edit fbm'});deleteFile(i)"> {{textService.currentText.delete}}</div>
            </div>
          </div>
          <!-- <p *ngIf="userService.currentUser.permissions.isInvoicing">{{textService.currentText.selectInvoice}}</p>
          <div *ngIf="userService.currentUser.permissions.isInvoicing" class="select-wrapper"
            (click)="functions.setDropDownPosition(after, 'invoice')" #after [class.active]="invoiceSelect?.panelOpen">
            <mat-form-field>
              <mat-select [disabled]="afterDisabled" shouldPlaceholderFloat="false"
                panelClass="default-dropdown invoice" #invoiceSelect disableRipple
                [(ngModel)]="defaultFbaTemplate.invoiceTemplateId">

                <mat-option *ngFor="let invoice of invoiceList" [value]="invoice.invoiceTemplateId">
                  {{ invoice.invoiceTemplateName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<ninja-notification [active]="userService.showNotification" [message]="notificationMessage" [title]="notificationTitle">
</ninja-notification>