<h3 class="font-size-18 mr-3">{{textService.currentText.accountSettings}}</h3>
<div class="account-settings-wrapper">
  <form action="" class="settings-grid custom-selects" [class.disabled]="!isEditMode">
    <div class="container-fluid p-xl-5 m-xl-3 mt-4">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-4">
            <span>{{textService.currentText.accountSettings}}</span>
          </h4>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row" [class.has-danger]="errors.email || errors.emailOnBack">
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{textService.currentText.emailAddress}}</h3>
            </div>
            <div class="col-md-9">
              <input [(ngModel)]="userAccData.email" matInput type="text" [formControl]="emailForm" name="email" #email>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row bot-indent" [class.has-danger]="errors.passwordInput">
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{textService.currentText.password}}</h3>
            </div>
            <div class="col-md-9">
              <div class="inner-row">
                <input matInput [attr.type]="passwordInputType" [formControl]="password" #passwordInput
                  [placeholder]="textService.currentText.password" name="pass" [(ngModel)]="userAccData.pass"
                  [disabled]="!isEditMode">
                <div class="q-icon help-icon" (click)="isPasswordTooltip = !isPasswordTooltip"
                  [class.active]="isPasswordTooltip" *ngIf="isEditMode">
                  ?
                </div>
                <ninja-tooltip (toggledChange)="showTooltip($event)" [text]="tooltipPassword"
                  *ngIf="isPasswordTooltip && isEditMode"></ninja-tooltip>
                <div class="icon-container">
                  <div class="capslock-icon help-icon inside-input" title="CAPSLOCK enabled"
                    *ngIf="capsOn && isEditMode">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-md-3">
          <div class="row" [class.has-danger]="errors.email2">
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{textService.currentText.sendEmailFrom}}</h3>
            </div>
            <div class="col-md-6">
              <input matInput type="text" #email2 [formControl]="emailFormSent" [(ngModel)]="userAccData.emailFrom"
                name="emailFrom" [disabled]="!isEditMode">
            </div>
            <div class="col-md-3">
              <button mat-button class="default-btn mt-3 mt-md-0" [disabled]="userService.currentUser.isVerified"
                (click)="grantAccess()">{{textService.currentText.grantAccessBtn}}</button>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row" *ngIf="isEditMode">
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{textService.currentText.confirmPasswod}}</h3>
            </div>
            <div class="col-md-9">
              <div class="inner-row">
                <input matInput type="password" #confirmPassInput [formControl]="confirPassword"
                  [placeholder]="textService.currentText.confirmPasswod" name="confirmPass"
                  [(ngModel)]="userAccData.confirmPass" [disabled]="!isEditMode">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h4 class="my-4">
            <span>{{textService.currentText.accountSettings}}</span>
          </h4>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{textService.currentText.inoiceLanguage}}</h3>
            </div>
            <div class="col-md-9">
              <div class="select-holder custom-selects">
                <div (click)="functions.setDropDownPosition(event, 'events')" #event [class.active]="events.panelOpen"
                  class="select-wrapper">
                  <mat-form-field>
                    <mat-select [placeholder]="textService.currentText.selectInvoiceLanguage" #events
                      [disabled]="!isEditMode" [(ngModel)]="userAccData.language" shouldPlaceholderFloat="false"
                      panelClass="default-dropdown events" disableRipple name="events">
                      <mat-option *ngFor="let select of constService.invoiceLanguage" [value]="select.value">
                        {{ select.viewValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{textService.currentText.defaultCurrency}}</h3>
            </div>
            <div class="col-md-9">
              <div class="select-wrapper" (click)="functions.setDropDownPosition(currency, 'defaultCurrencySelect')"
                #currency [class.active]="defaultCurrencySelect.panelOpen">
                <mat-form-field>
                  <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown defaultCurrencySelect"
                    [(ngModel)]="userAccData.defaultCurrency" name="defaultCurrency" #defaultCurrencySelect
                    disableRipple [disabled]="!isEditMode">
                    <mat-option *ngFor="let select of constService.currencies" [value]="select.value">
                      {{ select.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{textService.currentText.linkedRegs}}</h3>
            </div>
            <div class="col-md-9">
              <input matInput type="text" [(ngModel)]="userAccData.subAccounts" name="subAccounts" disabled>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-md-5 pt-md-5 mt-4">
        <div class="col-12">
          <h4 class="mb-4">
            <span>{{textService.currentText.business}}</span>
          </h4>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{textService.currentText.businessName}}</h3>
            </div>
            <div class="col-md-9">
              <input matInput type="text" [(ngModel)]="userAccData.businessName" name="businessName"
                [disabled]="!isEditMode">
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col-md-5 d-flex align-items-center">
              <h3>{{textService.currentText.businessRegNum}}</h3>
            </div>
            <div class="col-md-7">
              <input matInput type="text" [(ngModel)]="userAccData.businessRegistrationNumber"
                name="businessRegistrationNumber" [disabled]="!isEditMode">
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{textService.currentText.businessAddress}} </h3>
            </div>
            <div class="col-md-9">
              <div class="inner-row inner-row--multi">
                <input class="mb-2" matInput type="text" [disabled]="!isEditMode" name="address1"
                  [(ngModel)]="addresses[0]">
                <input class="mb-2" matInput type="text" [disabled]="!isEditMode" name="address2"
                  [(ngModel)]="addresses[1]">
                <input matInput type="text" [disabled]="!isEditMode" name="address3" [(ngModel)]="addresses[2]">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h4 class="my-4">
            <span>{{textService.currentText.subscription}}</span>
          </h4>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{textService.currentText.subscrPlan}}</h3>
            </div>
            <div class="col-md-9">
              <div class="inner-row">
                <input matInput type="text" class="sub-plan" [(ngModel)]="userAccData.subscriptionPlan"
                  name="subscriptionPlan" disabled>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{textService.currentText.expiration}}</h3>
            </div>
            <div class="col-md-9">
              <input matInput type="text" [value]="userAccData.expirationDate" disabled>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-9">
              <button mat-button class="default-btn gray-btn w-100"
                [routerLink]="'../../../upgrade'">{{textService.currentText.upgradesubscriptionplan}}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h4 class="my-4">
            <span>{{textService.currentText.twostepa}}</span>
          </h4>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col-md-3 d-flex align-items-center">
            </div>
            <div class="col-md-9">
              <mat-checkbox class="round-checkbox mfa" [formControl]="mfa" [checked]="userAccData.isMfa" (change)="twoFa($event.checked)" [disabled]="!isEditMode">{{textService.currentText.activate2sa}}</mat-checkbox>
              <p *ngIf="showMFAError" class="error">Two Step Authentication Process Failed</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="control-buttons-container justify-content-center mt-5 pt-1 mb-5 mb-md-0">
            <div class="control-buttons">
              <button mat-button class="secondary-btn btn-fill" *ngIf="!isEditMode" (click)="mixpanelService.track('q settings  - edit clicked');isEditMode = !isEditMode; dataChangedAfterSave = false;
                      disableForm();">
                {{textService.currentText.edit}}
              </button>
              <div class="btn-container" *ngIf="isEditMode">
                <button mat-button class="secondary-btn btn-fill" [disabled]="disableSaveButton"
                  (click)="mixpanelService.track('q settings  - save clicked');save();">{{textService.currentText.save}}</button>
                <button mat-button class="default-btn secondary-outline-btn cancel-btn"
                  (click)="isEditMode = !isEditMode; disableForm()">
                  {{textService.currentText.cancel}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>