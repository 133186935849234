<div class="login-container">
  <div class="right-container">
    <div class="logo">
      <img class="d-block d-lg-none" src="assets/images/ninja-logo2.svg" alt="">
      <img class="d-none d-lg-block" width="200" src="assets/images/logo-white.svg" alt="">
    </div>
    <!-- <div class="content">
      <div class="img-container">
        <img src="../../../../assets/images/login-img.png" alt="login-img">
      </div>
      <h3>{{textService.currentText.loginTitle}}</h3>
      <p>
        {{textService.currentText.loginTitleText}}
        <br/>
        {{textService.currentText.loginTitleText2}}
      </p>
    </div> -->
  </div>
  <div class="left-container">
    <div class="container">
      <h2>{{textService.currentText.resetPass}}</h2>
      <form class="login-form" name="form" [formGroup]="loginForm" novalidate>
        <div class="form-group" [class.has-danger]="errors?.emailInvalid">
          <!-- <label for="email">
            <i class="icon email"
               [ngStyle]="{'background-image': 'url(' + '../../../../assets/icons/mail.svg' + ')'}"></i>
          </label> -->
          <input type="email"
                 id="email"
                 #email
                 class="form-control-x"
                 formControlName="email"
                 [placeholder]="textService.currentText.email"/>
        </div>
        <div class="form-group-button text-center">
          <button mat-button class="secondary-btn btn-fill" (click)="resetPassword()">{{textService.currentText.reset}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
