/* tslint:disable */

declare var Object: any;

export interface AccessTokenInterface {
  'id'?: string;
  // 'ttl'?: number;
  'created'?: Date;
  // 'userId'?: string;
  'user'?: any;
}

export interface User {
  email?: string,
  emailFrom?: string,
  emails?: Array<UserSubEmail>,
  endTrial?: string,
  hasActivePlan?: boolean,
  id?: string,
  isMainAccount?: boolean,
  isTrial?: boolean,
  isVa?: boolean,
  isVerified?: boolean,
  language?: string,
  mangedAccounts?: Array<UserMangedAccount>,
  marketplace?: string,
  permissions?: {
    isAbTesting?: boolean,
    isAffiliate?: boolean,
    isAlerts?: boolean,
    isAsin?: boolean,
    isAutoTemplates?: boolean,
    isConversation?: boolean,
    isCoupons?: boolean,
    isDashboard?: boolean,
    isHijacked?: boolean,
    isInvitations?: boolean,
    isInvoicing?: boolean,
    isKeyWordOptimzer?: boolean,
    isMoneyBack?: boolean,
    isOrders?: boolean,
    isReports?: boolean,
    isReview?: boolean,
    isSettings?: boolean,
    isSuperUser?: boolean,
    isUpgrade?: boolean,
    isCompetetorAlerts?: boolean
  },
  sellerId?: string,
  version?: string,
  created?: any,
  user?: any,
  isShowAuthNotification?: any;
  currency?: any;
  mainEmail?: any;
  message?: any;
  fbtoken?: string;
  fbids?: any;
  jwtToken?: string;
  advertisingAccess?: boolean;
}

export interface UserSubEmail {
  subemail: string,
  subid: number,
  submarket: string,
}

export interface UserMangedAccount {
  email: string,
  id: number,
  sellerId: string,
}

export interface adAccount {
  adAccountId: string,
  adAccountName: string,
  userToken: string,
}

export interface SystemMessage {
  messageContent: string;
  messageExpirationDate: string;
  messageIsHasSeen: boolean;
  messageTime: boolean;
  messageTypeColor: string;
  messageTypeText: string;
}

export interface RequestChartData {
  'id'?: string;
  'start'?: string;
  'end'?: string;
  'isall'?: boolean;
  'channel'?: any;
  'skusList'?: any;
  'time'?: any;
}

export interface CreateAsinInterface {
  asin: string,
  keyword: string,
  marketplace: string
}

export interface newBulkInterface {
  'publicId'?: string;
  'templateId'?: number;
  'bulkId'?: number;
  'from'?: any;
  'to'?: any;
  'bulkName'?: string;
  'isNew'?: boolean;
}

export interface Expences {
  'publicid': any,
  'from': any,
  'to': any,
  'name': string,
  'amount': number,
  'currency': string
}

export class AccessToken implements AccessTokenInterface {
  'id': string;
  'ttl': number;
  'created': Date;
  'userId': string;
  'user': any;

  constructor(data?: AccessTokenInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `AccessToken`.
   */
  public static getModelName() {
    return 'AccessToken';
  }

  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of AccessToken for dynamic purposes.
   **/
  public static factory(data: AccessTokenInterface): AccessToken {
    return new AccessToken(data);
  }

  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'AccessToken',
      plural: 'AccessTokens',
      properties: {
        'id': {
          name: 'id',
          type: 'string'
        },
        'ttl': {
          name: 'ttl',
          type: 'number',
          default: 1209600
        },
        'created': {
          name: 'created',
          type: 'Date'
        },
        'userId': {
          name: 'userId',
          type: 'string'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'User',
          model: 'User'
        },
      }
    };
  }
}

export class Token implements AccessTokenInterface {
  id: any = null;
  // ttl: number = null;
  created: any = null;
  // userId: any = null;
  user: any = null;
  rememberMe: boolean = null;

  constructor(data?: AccessTokenInterface) {
    Object.assign(this, data);
  }
}

/**
 * This GeoPoint represents both, LoopBack and MongoDB GeoPoint
 **/
export interface GeoPoint {
  lat?: number;
  lng?: number;
  type?: string;
  coordinates?: number[];
}

export interface StatFilter {
  range: string,
  custom?: {
    start: string,
    end: string
  },
  where?: {},
  groupBy?: string
}

export interface NotificationsData {
  isAlertOnHijacked: boolean,
  isAlertOnNegativeFeedback: boolean,
  isAll: boolean,
  isEmailNotifyForReviews: boolean,
  isFBNotifyForReviews: boolean,
  isFBNotifyForSales: boolean,
  isTgNotifyForSales: boolean,
  isNegativeFeedbackOnFB: boolean,
  isReportEmailDaily: boolean,
  token: string,
  numberOfSalesFbNotifications: string,
  notificationProducts?: Array<string>,
  tgNotificationProducts?: Array<string>
}
