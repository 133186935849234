<div class="main-dialog-container main-dialog-container--bulk default-popup">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container inner-dialog-container--bulk custom-selects" [formGroup]="userForm" name="form" novalidate>
    <div class="header header--bulk">
      <h2>{{data.va ? textService.currentText.editPermissions : textService.currentText.createNewUser}}</h2>
    </div>
    <div class="dialog-body">
      <div class="dialog-row flex space-between">
        <div class="col" [class.has-danger]="errors?.firstName">
          <div class="label-name">
            <span>{{textService.currentText.firstName}}</span>
          </div>
          <input matInput [disabled]="data.va" [placeholder]="textService.currentText.usersFirstName" formControlName="firstName" #firstName>
        </div>
        <div class="col" [class.has-danger]="errors?.lastName">
          <div class="label-name">
            <span>{{textService.currentText.lastName}}</span>
          </div>
          <input matInput [disabled]="data.va" [placeholder]="textService.currentText.usersLastName" formControlName="lastName" #lastName>
        </div>
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.email">
        <div class="label-name">
          <span>{{textService.currentText.email}}</span>
        </div>
        <input matInput [disabled]="data.va" [placeholder]="textService.currentText.usersEmail" formControlName="email" #email>
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.password">
        <div class="label-name">
          <span>{{textService.currentText.password}}</span>
        </div>
        <input matInput [disabled]="data.va" [autocomplete]="data.va ? 'new-password' : null" [type]="passwordInputType"
          [placeholder]="textService.currentText.usersPassword" formControlName="password" #password>
        <label class="icon" (click)="passwordInputType === 'password' && !data.va ? passwordInputType = 'text' : passwordInputType = 'password'">
          <i class="icon password" [class.visible]="passwordInputType === 'text'"></i>
        </label>
      </div>
      <h5>{{textService.currentText.permissions}}</h5>
      <div class="permissions-table">
        <div class="header">
          <div class="col">
            {{textService.currentText.allPermissions}}
          </div>
          <div class="col">
            {{textService.currentText.grantedPermissions}} ({{this.grantedPermissions.length}})
          </div>
        </div>
        <div class="body">
          <div class="col">
            <div class="item" *ngFor="let permission of permissions">
              <span>{{permission.charAt(0).toUpperCase() + permission.split('_').join(' ').slice(1)}}</span>
              <img (click)="addPermission(permission)" width="30" height="30" src="assets/icons/permission.png" alt="">
            </div>
          </div>
          <div class="col">
            <div class="item" *ngFor="let permission of grantedPermissions">
              <span>{{permission.charAt(0).toUpperCase() + permission.split('_').join(' ').slice(1)}}</span>
              <img (click)="removePermission(permission)" src="assets/icons/delete-permission.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer space-between bulk-footer">
    <button mat-button class="primary-btn white" (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button *ngIf="!data.va" mat-button class="primary-btn send" (click)="checkForm()">
      {{data.va ? textService.currentText.save : textService.currentText.create}}</button>
    <button *ngIf="data.va" mat-button class="primary-btn send" (click)="createNewVaUser()">
      {{data.va ? textService.currentText.save : textService.currentText.create}}</button>
  </div>
</div>