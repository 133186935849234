import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';


@Injectable()

export class PricingService extends UserService {

    public getValuePricingInfo(publicId: string): Observable<any> {
        return this.post('update/valuePricingInfo', {
            publicId: publicId
        });
      }

      public getValuePricingPlan(publicId: string, addons: any, annual: boolean): Observable<any> {
        return this.post('update/valuePricingPlan', {
            publicId: publicId,
            addons: addons,
            annual: annual
        });
      }
}