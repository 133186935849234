<div class="dashboard " [class.wide]="userService.smallSidebar">
  <div class="download-holder">
    <div class="dashboard-container">
      <div class="col custom-selects">
        <div class="title">
          {{textService.currentText.ordersDownloadTittle}}
        </div>
        <div class="main-sub-title">
          {{textService.currentText.createFilters}}
        </div>
        <div class="sub-title">
          {{textService.currentText.dates}}:
        </div>
        <ng-container [formGroup]="downloadForm" name="form" novalidate>
          <div class="filter-row" [class.has-danger]="errors?.dateFrom">
            <div class="select-label">
              <span>{{textService.currentText.from}}</span>
            </div>
            <div class="select-holder datepicker">
              <mat-form-field *ngIf="!errors?.dateFrom">
                <input formControlName="dateFrom" matInput [matDatepicker]="picker" name="dateFrom" [(ngModel)]="startDate" [max]="endDate"
                  [min]="minDate" (click)="picker.open()" (keydown)="$event.preventDefault()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker></mat-datepicker>
              </mat-form-field>
              <input *ngIf="errors?.dateFrom" type="text" [value]="dateError">
            </div>
          </div>
          <div class="filter-row" [class.has-danger]="errors?.dateTo">
            <div class="select-label">
              <span>{{textService.currentText.to}}</span>
            </div>
            <div class="select-holder datepicker">
              <mat-form-field *ngIf="!errors?.dateTo">
                <input matInput formControlName="dateTo" [matDatepicker]="picker2" [min]="startDate" [(ngModel)]="endDate" (click)="picker2.open()"
                  name="dateTo">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker2></mat-datepicker>
              </mat-form-field>
              <input *ngIf="errors?.dateTo" type="text" [value]="dateError">
            </div>
          </div>
        </ng-container>
        <div class="sub-title">
          {{textService.currentText.parameters}}:
        </div>
        <div class="filter-row">
          <div class="select-label">
            <span>{{textService.currentText.refundStatus}}</span>
          </div>
          <div (click)="functions.setDropDownPosition(refundStatusSelect, 'refundStatus')" #refundStatusSelect [class.active]="refundStatus.panelOpen"
            class="select-wrapper">
            <mat-form-field>
              <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown refundStatus" #refundStatus disableRipple
                [(ngModel)]="filters.refundStatus" name="refundStatus">
                <mat-option *ngFor="let select of refundStatusOptions" [value]="select.value">
                  {{ select.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="filter-row">
          <div class="select-label">
            <span>{{textService.currentText.repeatBuyers}}</span>
          </div>
          <div (click)="functions.setDropDownPosition(repeatBuyersSelect, 'repeatedBuyers')" #repeatBuyersSelect
            [class.active]="repeatedBuyers.panelOpen" class="select-wrapper">
            <mat-form-field>
              <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown repeatedBuyers" #repeatedBuyers disableRipple
                [(ngModel)]="filters.repeatedBuyers" name="repeatedBuyers">
                <mat-option *ngFor="let select of repeatBuyersOptions" [value]="select.value">
                  {{ select.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="filter-row">
          <div class="select-label">
            <span>{{textService.currentText.negativeFeedback}}</span>
          </div>
          <div (click)="functions.setDropDownPosition(negativeFeedbackSelect, 'negativeFeedback')" #negativeFeedbackSelect
            [class.active]="negativeFeedback.panelOpen" class="select-wrapper">
            <mat-form-field>
              <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown negativeFeedback" #negativeFeedback disableRipple
                [(ngModel)]="filters.negativeFeedback" name="negativeFeedback">
                <mat-option *ngFor="let select of negativeFeedbackOptions" [value]="select.value">
                  {{ select.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="filter-row">
          <div class="select-label">
            <span>{{textService.currentText.salesChannel}}</span>
          </div>
          <div (click)="functions.setDropDownPosition(saleChannelSelect, 'saleChannel')" #saleChannelSelect [class.active]="saleChannel.panelOpen"
            class="select-wrapper">
            <mat-form-field>
              <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown saleChannel" #saleChannel disableRipple
                [(ngModel)]="filters.salesChannel" name="saleChannel">
                <mat-option *ngFor="let select of constService.salesChannels" [value]="select.value">
                  {{ select.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="filter-row">
          <div class="select-label">
            <span>{{textService.currentText.promotionStatus}}</span>
          </div>
          <div (click)="functions.setDropDownPosition(promotionStatusSelect, 'promotionStatus')" #promotionStatusSelect
            [class.active]="promotionStatus.panelOpen" class="select-wrapper">
            <mat-form-field>
              <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown promotionStatus" #promotionStatus disableRipple
                [(ngModel)]="filters.promotionStatus" name="promotionStatus">
                <mat-option *ngFor="let select of promotionStatusOptions" [value]="select.value">
                  {{ select.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="filter-row">
          <div class="select-label">
            <span>{{textService.currentText.products}}</span>
          </div>
          <div class="select-wrapper" (mouseenter)="showProductTooltip = true" (mouseleave)="showProductTooltip = false">
            <div (click)="functions.setDropDownPosition(allProductsSelect, 'allProducts')" #allProductsSelect [class.active]="allProducts.panelOpen"
              class="select-wrapper">
              <mat-form-field>
                <mat-select [placeholder]="filters.placeholder" shouldPlaceholderFloat="false" panelClass="default-dropdown allProducts" #allProducts
                  disableRipple [(ngModel)]="filters.allProducts" (selectionChange)="showSpecProducts()" name="allProducts">
                  <mat-option *ngFor="let select of constService.productsDashboard" [value]="select.value">
                    {{ select.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="spec-product-tooltip" *ngIf=" showProductTooltip == true && !filters.allProducts" (mouseenter)="showProductTooltip = true"
                (mouseleave)="showProductTooltip = false">
                <h4>SELECTED PRODUCTS:</h4>
                <ol>
                  <li *ngFor="let product of filters?.productsskus">
                    <span>{{product.shortName}}</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="filter-row">
          <div class="select-label">
            <span>{{textService.currentText.fulfillmentCahnnels}}</span>
          </div>
          <div (click)="functions.setDropDownPosition(fulfillmentSelect, 'fulfillment')" #fulfillmentSelect [class.active]="fulfillment.panelOpen"
            class="select-wrapper">
            <mat-form-field>
              <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown fulfillment" #fulfillment disableRipple
                [(ngModel)]="filters.withFBM" name="promotionStatus">
                <mat-option *ngFor="let select of fulfillmentChannel" [value]="select.value">
                  {{ select.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div> -->
        <div class="sub-title">
          {{textService.currentText.expences}}:
        </div>
        <div class="filter-row">
          <div class="select-label">
            <span>
              {{textService.currentText.spentMoreThan}}
            </span>
          </div>
          <input type="number" [placeholder]="textService.currentText.anyAmount" [(ngModel)]="filters.spentMoney">
        </div>
        <div class="filter-row">
          <div class="select-label">
            <span>
              {{textService.currentText.currency}}
            </span>
          </div>
          <div (click)="functions.setDropDownPosition(currencySelect, 'currency')" #currencySelect [class.active]="currency.panelOpen"
            class="select-wrapper">
            <mat-form-field>
              <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown currency" #currency disableRipple [(ngModel)]="filters.currency"
                name="currency">
                <mat-option *ngFor="let select of constService.currencies" [value]="select.value">
                  {{ select.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <button mat-button class="primary-btn white" (click)="mixpanelService.track('q download orders - reset clicked');setDefaultFilters()">
          {{textService.currentText.reset}}
        </button>
      </div>
      <div class="col custom-selects">
        <div class="main-sub-title">
          {{textService.currentText.downloadReport}}
        </div>
        <div class="sub-title">
          {{textService.currentText.reportType}}:
        </div>
        <div class="ratio">
          <mat-radio-group [(ngModel)]="filters.withFBM">
            <mat-radio-button disableRipple [value]="false">
              {{textService.currentText.FBA}}
            </mat-radio-button>
            <mat-radio-button disableRipple [value]="true">
              {{textService.currentText.FBM}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="sub-title">
          {{textService.currentText.data}}:
        </div>
        <div class="ratio">
          <mat-radio-group  [(ngModel)]="filters.isFull">
            <mat-radio-button  disableRipple [value]="true">
              {{textService.currentText.full}}
            </mat-radio-button>
            <mat-radio-button disableRipple [value]="false">
              {{textService.currentText.onlyFbData}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <button mat-button class="primary-btn download" (click)="mixpanelService.track('q download orders - download clicked');checkForm()">
          {{textService.currentText.downloadReport}}
        </button>
        <!--<ng-container >-->
        <!--<div class="main-sub-title" >-->
        <!--{{textService.currentText.uploadAudienceToFbTitle}}-->
        <!--</div>-->
        <!--<ng-container *ngIf="showUploadAudience"  [formGroup]="audienceForm" name="audForm" novalidate>-->
        <!--<div class="sub-title flex space-between">-->
        <!--{{textService.currentText.selectAdAccount}}:-->
        <!--<span class="purple" *ngIf="isAdUploading">{{textService.currentText.uploading}}</span>-->
        <!--</div>-->
        <!--<div class="filter-row " [class.has-danger]="errors?.adAccount">-->
        <!--<div class="select-label">-->
        <!--<span>{{textService.currentText.adAccount}}</span>-->
        <!--</div>-->
        <!--<div (click)="functions.setDropDownPosition(accountSelect, 'account')" #accountSelect-->
        <!--[class.active]="account.panelOpen"-->
        <!--*ngIf="!errors?.adAccount"-->
        <!--class="select-wrapper">-->
        <!--<mat-form-field>-->
        <!--<mat-select shouldPlaceholderFloat="false"-->
        <!--formControlName="adAccount"-->
        <!--panelClass="default-dropdown account"-->
        <!--#account-->
        <!--disableRipple-->
        <!--(change)="getCustomAudience()"-->
        <!--[(ngModel)]="adAccount"-->
        <!--name="account">-->
        <!--<mat-option *ngFor="let select of adAccounts" [value]="select">-->
        <!--{{ select.adAccountName }}-->
        <!--</mat-option>-->
        <!--</mat-select>-->
        <!--</mat-form-field>-->
        <!--</div>-->
        <!--<input *ngIf="errors?.adAccount"-->
        <!--type="text"-->
        <!--[value]="textService.currentText.required">-->
        <!--</div>-->
        <!--<div class="sub-title flex space-between">-->
        <!--<span> {{textService.currentText.selectAudience}}:</span>-->
        <!--<span class="purple align-center"-->
        <!--[class.disabled]="!adAccount"-->
        <!--(click)="adAccount ? showCreateAudienceDialog(): adAccount ">-->
        <!--{{textService.currentText.addNew}} +-->
        <!--</span>-->
        <!--<span class="flex purple align-center">-->
        <!--<span [style.marginRight.px]="5"-->
        <!--(click)="getCustomAudience()">-->
        <!--{{textService.currentText.refresh}}-->
        <!--</span>-->
        <!--<img [class.loading]="isAudienceLoading" src="assets/icons/refresh-report.svg" alt="">-->
        <!--</span>-->
        <!--</div>-->
        <!--<div class="filter-row" [class.has-danger]="errors?.audience">-->
        <!--<div class="select-label">-->
        <!--<span>{{textService.currentText.customAudience}}</span>-->
        <!--</div>-->
        <!--<div (click)="functions.setDropDownPosition(audienceSelect, 'audience')" #audienceSelect-->
        <!--[class.active]="audience.panelOpen"-->
        <!--*ngIf="!errors?.audience"-->
        <!--class="select-wrapper">-->
        <!--<mat-form-field>-->
        <!--<mat-select shouldPlaceholderFloat="false"-->
        <!--formControlName="audience"-->
        <!--panelClass="default-dropdown audience"-->
        <!--#audience-->
        <!--disableRipple-->
        <!--(onOpen)="setAudienceSelectStyling()"-->
        <!--[(ngModel)]="selectedCustomAudience"-->
        <!--name="audience">-->
        <!--<mat-option *ngFor="let select of audienceList" [value]="select.customAudienceId">-->
        <!--{{ select.customAudienceName }}-->
        <!--</mat-option>-->
        <!--</mat-select>-->
        <!--</mat-form-field>-->
        <!--</div>-->
        <!--<input *ngIf="errors?.audience"-->
        <!--type="text"-->
        <!--[value]="textService.currentText.required">-->
        <!--</div>-->
        <!--</ng-container>-->

        <!--<button mat-button class="primary-btn purple"-->
        <!--(click)="!showUploadAudience ? checkAccess() : uploadToFb()">-->
        <!--{{textService.currentText.uploadAudienceToFb}}-->
        <!--</button>-->
        <!--&lt;!&ndash;<button mat-button class="primary-btn purple"&ndash;&gt;-->
        <!--&lt;!&ndash;(click)="showCreateAudienceDialog()"&ndash;&gt;-->
        <!--&lt;!&ndash;[disabled]="!adAccount">&ndash;&gt;-->
        <!--&lt;!&ndash;{{textService.currentText.addNewAudience}}&ndash;&gt;-->
        <!--&lt;!&ndash;</button>&ndash;&gt;-->
        <!--</ng-container>-->

      </div>
    </div>
    <div class="dashboard-container">
      <div class="col">
        <div class="title">
          {{textService.currentText.ordersAndAudience}}
        </div>
        <div class="text">
          {{textService.currentText.ordersDownloadText1}}
        </div>
        <div class="sub-title">
          {{textService.currentText.ordersDownloadTitle1}}
        </div>
        <div class="text">
          {{textService.currentText.ordersDownloadText1}}
        </div>
        <div class="sub-title">
          {{textService.currentText.ordersDownloadTitle2}}
        </div>
        <div class="text">
          <p>{{textService.currentText.ordersDownloadText3}}</p>
          <p>{{textService.currentText.ordersDownloadText4}}</p>
          <p>{{textService.currentText.ordersDownloadText5}}</p>
        </div>
        <div class="sub-title">
          {{textService.currentText.ordersDownloadTitle3}}
        </div>
        <div class="text">
          <p>{{textService.currentText.ordersDownloadText6}}</p>
          <p>{{textService.currentText.ordersDownloadText7}}</p>
        </div>
        <div class="sub-title">
          {{textService.currentText.ordersDownloadTitle4}}
        </div>
        <div class="text">
          {{textService.currentText.ordersDownloadText8}}
        </div>
      </div>
    </div>
  </div>
</div>