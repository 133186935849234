<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container">
    <div class="dashboard-header">
      <div class="head-container">
        <h2>{{textService.currentText.lastMessagesSent}}</h2>
        <span *ngIf="userService.currentPage === 'fba'" (click)="goBack()" class="action-el">{{textService.currentText.backFbaTemplate}}</span>
        <span *ngIf="userService.currentPage === 'fbm'" (click)="goBack()" class="action-el">{{textService.currentText.backFbmTemplate}}</span>
      </div>
      <div class="sub-head-container"></div>
      <div class="filters-container">
        <div class="a-side">
          <form class="filters active custom-selects">
            <div class="select-holder multy-check" (click)="functions.setDropDownPosition(includeTemplates, 'includeTemplates')" #includeTemplates
              [class.active]="includeTemplatesId?.panelOpen">
              <mat-form-field>
                <mat-select [placeholder]="textService.currentText.includeTemplates" shouldPlaceholderFloat="false"
                  panelClass="default-dropdown includeTemplates" multiple disableRipple #includeTemplatesId [(value)]="filters.includeTemplatesId">
                  <mat-option *ngFor="let select of templateList" [value]="select.id">
                    {{ select.name }}
                  </mat-option>
                  <!--<div class="inner-select-box">-->
                  <!--<button (click)="filters.includeTemplatesId = []">Clear All</button>-->
                  <!--</div>-->
                </mat-select>
              </mat-form-field>
            </div>
            <div class="select-holder multy-check" (click)="functions.setDropDownPosition(salesChannels, 'salesChannels')" #salesChannels
              [class.active]="excludeTemplatesId?.panelOpen">
              <mat-form-field>
                <mat-select [placeholder]="textService.currentText.excludeTemplates" shouldPlaceholderFloat="false"
                  panelClass="default-dropdown salesChannels" multiple disableRipple #excludeTemplatesId [(value)]="filters.excludeTemplatesId">
                  <mat-option *ngFor="let select of templateList" [value]="select.id">
                    {{ select.name }}
                  </mat-option>
                  <div class="inner-select-box">
                    <button (click)="filters.excludeTemplatesId = []">Clear All</button>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="input-wrapper">
              <mat-form-field>
                <input matInput [placeholder]="textService.currentText.orderId" [(ngModel)]="filters.orderId" name="orderId">
              </mat-form-field>
              <div class="clear-input" *ngIf="filters.orderId" (click)="filters.orderId = ''"></div>
            </div>
            <div class="input-wrapper">
              <mat-form-field>
                <input matInput [placeholder]="textService.currentText.buyerEmail" [(ngModel)]="filters.buyerEmail" name="buyerEmail">
              </mat-form-field>
              <div class="clear-input" *ngIf="filters.buyerEmail" (click)="filters.buyerEmail = ''"></div>
            </div>
            <button mat-button mat-raised-button class="default-btn search-btn send" type="button" (click)="search()">
              {{textService.currentText.search}}
            </button>
          </form>
        </div>
      </div>
      <div class="filters-container">
        <div class="a-side">
          <form class="filters active custom-selects">
            <div class="select-holder datepicker">
              <mat-form-field>
                <input matInput [matDatepicker]="purchasedAfter" name="purchasedAfter" (click)="purchasedAfter.open()"
                  [(ngModel)]="filters.purchasedAfter" (keydown)="$event.preventDefault()" [placeholder]="textService.currentText.purchasedAfter">
                <mat-datepicker-toggle matSuffix [for]="purchasedAfter"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" panelClass="calendar-popup" #purchasedAfter></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="select-holder datepicker">
              <mat-form-field>
                <input matInput [matDatepicker]="purchasedBefore" name="purchasedBefore" (click)="purchasedBefore.open()"
                  [(ngModel)]="filters.purchasedBefore" (keydown)="$event.preventDefault()" [placeholder]="textService.currentText.purchasedBefore">
                <mat-datepicker-toggle matSuffix [for]="purchasedBefore"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" panelClass="calendar-popup" #purchasedBefore></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="select-holder datepicker">
              <mat-form-field>
                <input matInput [matDatepicker]="shippedAfter" name="shipperAfter" (click)="shippedAfter.open()" [(ngModel)]="filters.shippedAfter"
                  (keydown)="$event.preventDefault()" [placeholder]="textService.currentText.shippedAfter">
                <mat-datepicker-toggle matSuffix [for]="shippedAfter"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" panelClass="calendar-popup" #shippedAfter></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="select-holder datepicker">
              <mat-form-field>
                <input matInput [matDatepicker]="shippedBefore" name="shippedBefore" (click)="shippedBefore.open()"
                  [(ngModel)]="filters.shippedBefore" (keydown)="$event.preventDefault()" [placeholder]="textService.currentText.shippedBefore">
                <mat-datepicker-toggle matSuffix [for]="shippedBefore"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" panelClass="calendar-popup" #shippedBefore></mat-datepicker>
              </mat-form-field>
            </div>
            <button mat-button mat-raised-button class="default-btn clear-btn white" type="button" (click)="clear()">
              {{textService.currentText.clearAll}}
            </button>
          </form>
        </div>
      </div>

    </div>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header" [class.full-width]="emailList?.length <= 5">
            <div class="td"></div>
            <div class="td"></div>
            <div class="td">{{textService.currentText.buyerName}}</div>
            <div class="td">{{textService.currentText.lastSent}}</div>
            <div class="td">{{textService.currentText.templateName}}</div>
            <div class="td">{{textService.currentText.orderId}}</div>
            <div class="td">{{textService.currentText.purchaseDate}}</div>
            <div class="td">{{textService.currentText.shipmentDate}}</div>
            <div class="td">{{textService.currentText.estimatedDelivery}}</div>
            <div class="td">{{textService.currentText.salesChannel}}</div>
            <div class="td">{{textService.currentText.SKU}}</div>
          </div>
        </div>
        <div class="table-holder">
          <div class="table">
            <div class="tr" *ngFor="let email of emailList">
              <div class="td">
                <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"></button>
                <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                  <button mat-menu-item *ngIf="!email.isBuyerOnBlackList"
                    (click)="changeBuyerBlackList(!email.isBuyerOnBlackList, email.buyerEmail); email.isBuyerOnBlackList = !email.isBuyerOnBlackList">
                    <div class="list-inner-container red">
                      <div class="menu-icon icon-buyer-blacklist-red"></div>
                      {{textService.currentText.addBuyerToBlackList}}
                    </div>
                  </button>
                  <button mat-menu-item *ngIf="email.isBuyerOnBlackList"
                    (click)="changeBuyerBlackList(!email.isBuyerOnBlackList, email.buyerEmail); email.isBuyerOnBlackList = !email.isBuyerOnBlackList">
                    <div class="list-inner-container">
                      <div class="menu-icon icon-buyer-blacklist"></div>
                      {{textService.currentText.removeBuyerFromBlackList}}
                    </div>
                  </button>
                  <button mat-menu-item *ngIf="!email.isOnBlackList"
                    (click)="changeOrderlackList(!email.isOnBlackList, email.orderId); email.isOnBlackList = !email.isOnBlackList ">
                    <div class="list-inner-container red">
                      <div class="menu-icon icon-order-blacklist-red"></div>
                      {{textService.currentText.addOrderToBlackList}}
                    </div>
                  </button>
                  <button mat-menu-item *ngIf="email.isOnBlackList"
                    (click)="changeOrderlackList(!email.isOnBlackList, email.orderId); email.isOnBlackList = !email.isOnBlackList ">
                    <div class="list-inner-container">
                      <div class="menu-icon icon-order-blacklist"></div>
                      {{textService.currentText.removeOrderFromBlackList}}
                    </div>
                  </button>
                </mat-menu>
              </div>
              <div class="td">
                <div class="icon" *ngIf="email.isBuyerOnBlackList">
                  <img src="assets/icons/buyer-blacklist-red.svg" alt="">
                </div>
                <div class="icon" *ngIf="email.isOnBlackList">
                  <img src="assets/icons/order-blacklist-red.svg" alt="">
                </div>
              </div>
              <div class="td overflow-visible" (mouseenter)="email.showTooltop = true" (mouseleave)="email.showTooltop = false">
                <p>
                  {{email.buyerName}}
                </p>
                <div class="help-tooltip" *ngIf="email.showTooltop">
                  <h5>{{textService.currentText.email}}:</h5>
                  <p>{{email.buyerEmail}}</p>
                </div>
              </div>
              <div class="td">{{email.lastSent | date:'short'}}</div>
              <div class="td overflow-visible">
                <ng-container *ngFor="let template of email.sentTemplates; let i = index">
                  <span class="name" *ngIf="i <= 0"> {{template}},</span>
                </ng-container>
                <span class="more overflow-visble" *ngIf="email?.sentTemplates?.length > 1"
                  (click)="email.showMoreTemplates =  !email.showMoreTemplates">
                  + {{email.sentTemplates?.length - 1}} {{textService.currentText.moreLowercase}}
                  <div class="template-list" *ngIf="email.showMoreTemplates">
                    <ng-container *ngFor="let template of email.sentTemplates; let j = index">
                      <div class="tag-item" *ngIf="j > 0">
                        {{template}}
                      </div>
                    </ng-container>
                  </div>
                </span>
                <!--<ng-container *ngFor="let template of email.sentTemplates">-->
                <!--{{template}}-->
                <!--</ng-container>-->
              </div>
              <div class="td">{{email.orderId}}</div>
              <div class="td">{{email.purchasedDate}}</div>
              <div class="td">{{email.shipmentDate}}</div>
              <div class="td">{{email.estimatedDelivery}}</div>
              <div class="td">{{email.salesChannel}}</div>
              <div class="td">{{email.SKUList}}</div>
            </div>
            <div class="tr" *ngIf="!emailList || emailList?.length === 0">
              <div class="td not-found">
                {{textService.currentText.noDataFound}}.
              </div>
            </div>
          </div>
        </div>
        <!--<mat-paginator  [length]="userProducts?.length"-->
        <!--[pageSize]="pageSize"-->
        <!--(page)="getUpdatePaginator($event.pageIndex)">-->
        <!--</mat-paginator>-->
        <div class="mat-paginator-custom">
          <p>Items per page: 20</p>
          <button (click)="search(this.prevToken)" [disabled]="this.nextToken === 20 || this.nextToken === 0">
            <svg class="mat-paginator-icon " focusable="false" width="24px" height="24" viewBox="0 0 24 24">
              <path class="prev" fill="#757575" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            </svg>
          </button>
          <button (click)="search(this.nextToken)" [disabled]="this.nextToken === 0 || this.isNextDisabled">
            <svg class="mat-paginator-icon" focusable="false" width="24px" height="24" viewBox="0 0 24 24">
              <path class="next" fill="#757575" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
            </svg>
          </button>
        </div>

      </div>
    </div>
  </div>