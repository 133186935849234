import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  public static appConfig: any;
  public static maintenance: boolean;
  public static isDev: boolean;

  constructor(private httpClient: HttpClient) { }

  loadAppConfig() {
    return new Promise((resolve, reject) => {
      this.httpClient.get('assets/config.json').subscribe((res: any) => {
        if (res.isDev) {
          resolve(AppConfigService.appConfig = res.SERVER_BASE_URL_DEV);
          AppConfigService.maintenance = res.maintenance;
          AppConfigService.isDev = res.isDev;
        } else {
          resolve(AppConfigService.appConfig = res.SERVER_BASE_URL_PROD);
          AppConfigService.maintenance = res.maintenance;
          AppConfigService.isDev = res.isDev;
        }
      });
    });
  }

}