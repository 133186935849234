import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Functions} from '../../../services/functions';
import {UserService} from '../../../services/user.service';
import {CouponsService} from '../../../services/coupons.service';
import {ConstService} from '../../../services/const-service';
import {FormBuilder, Validators} from '@angular/forms';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {TextService} from '../../../services/text.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
    templateUrl: './coupon-dialog.component.html',
    styleUrls: ['./coupon-dialog.component.scss']
})

export class CouponDialogComponent implements OnInit {
    @ViewChild('couponNameId', { static: false }) couponNameInput: ElementRef;
    @ViewChild('date', { static: false }) date: ElementRef;
    @ViewChild('inputId', { static: false }) inputId: ElementRef;
    public textInputValue = '';
    public currentDate: any;
    public words = [];
    public errors: any = {};
    public submitForm = this.fb.group({
        couponName: ['', [Validators.required]],
        date: ['', [Validators.required]],
        file: ['', [Validators.required]]
    });

    constructor(public userService: UserService,
                public couponsService: CouponsService,
                public constService: ConstService,
                public fb: FormBuilder,
                public functions: Functions,
                public textService: TextService,
                public matDialog: MatDialog,
                public dialogRef: MatDialogRef<CouponDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public mixpanelService: MixpanelService,
                ) {
                  this.mixpanelService.init(null);
    }

    public ngOnInit(): void {
        const testDate = new Date();
        this.currentDate = testDate.getFullYear() + '-' +
                          (testDate.getMonth() + 1) + '-' +
                          testDate.getDate();
        if (this.data.coupon) {
            this.submitForm.controls.couponName.patchValue(this.data.coupon.couponName);
            this.submitForm.controls.date.patchValue(this.data.coupon.dateExpired);
        }
    }


    public checkState(): void {
        if (this.data.edit) {
            this.saveEditCoupon();
        } else {
            this.saveCoupon();
        }
    }

    public saveCoupon(): void {
        if (this.checkForm()) {
            this.userService.dialogLoader = true;
            const newCoupon = {
                publicId: this.userService.currentUser.id,
                couponSetName: this.submitForm.controls.couponName.value,
                coupons: this.words,
                dateExpired: this.submitForm.controls.date.value.getFullYear() + '-' +
                             (this.submitForm.controls.date.value.getMonth() + 1) + '-' +
                             this.submitForm.controls.date.value.getDate()
      };
      this.couponsService.createCoupon(newCoupon).subscribe(() => {
        this.userService.dialogLoader = false;
        this.dialogRef.close('new');
      });
    }
  }

  public isString(val) {
    return typeof val === 'string' || ((!!val && typeof val === 'object') && Object.prototype.toString.call(val) === '[object String]');
  }

  public saveEditCoupon(): void {
    const date = this.isString(this.submitForm.controls.date.value) ? this.submitForm.controls.date.value :
      `${this.submitForm.controls.date.value.getFullYear()} - ${this.submitForm.controls.date.value.getMonth() + 1} -${this.submitForm.controls.date.value.getDate()}`;
    if (this.checkForm()) {
      const editCoupon = {
        publicId: this.userService.currentUser.id,
        couponSetId: this.data.coupon.couponid,
        coupons: this.words,
        dateExpired: date
      };
      this.couponsService.editCoupon(editCoupon).subscribe((data) => {
      });
      this.dialogRef.close('edit');
    }
  }

  public fileUploader(e: any): void {
    let files = null;
    files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    if (files[0] && files[0].size > 100000) {
      this.openFileSizeDialog();
      return;
    } else if (files[0] && files[0].type !== 'text/plain') {
      this.openFileSizeDialog();
      return;
    }
    this.textInputValue = files[0].name;
    this.submitForm.controls['file'].setValue(this.textInputValue);
    const reader = new FileReader();
    reader.onload = (event) => {
      const ct: any = reader.result;
      const words = ct.split('\n');
      this.words = words;
    };
    reader.readAsText(files[0]);
  }

  public checkForm(): boolean {
    if (!this.submitForm.valid) {
      if (!this.submitForm.controls.couponName.valid) {
        this.errors.couponName = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.date.valid) {
        this.errors.date = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.file.valid) {
        this.errors.message = true;
        this.displayError(this.textService.currentText.errorValidFile);
        return;
      }
      return false;
    }
    return this.submitForm.valid;
  }

  private displayError(message?: string): void {
    if (!this.submitForm.controls.couponName.valid) {
      this.errors.couponName = true;
      const old_value = this.submitForm.value.email;
      this.submitForm.patchValue({
        couponName: message
      });
      this.submitForm.controls['couponName'].disable();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.date.valid) {
      this.errors.keyword = true;
      const old_value = this.submitForm.value.email;
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.file.valid) {
      this.errors.file = true;
      const old_value = this.submitForm.value.email;
      this.submitForm.patchValue({
        file: message
      });
      this.submitForm.controls['file'].disable();
      this.resetErrorState(old_value);
      return;
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.couponName) {
        this.submitForm.patchValue({
          couponName: val,
        });
        this.errors.couponName = false;
        this.submitForm.controls['couponName'].enable();
        return;
      }
      if (this.errors.date) {
        this.errors.date = false;
      }
      if (this.errors.file) {
        this.submitForm.patchValue({
          file: val,
        });
        this.errors.file = false;
        this.submitForm.controls['file'].enable();
      }
    }, 2000);
  }


  public openFileSizeDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.couponNotAdded,
        'message': this.textService.currentText.couponNotAddedText
      },
    }).afterClosed().subscribe();
  }
}
