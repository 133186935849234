import { Component, ElementRef, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConstService } from '../../../services/const-service';
import { UserService } from '../../../services/user.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TextService } from '../../../services/text.service';
import { Router } from '@angular/router';
import { StorageBrowser } from "../../../services/storage.browser";
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  @ViewChild('emailInput', { static: false }) emailInput: ElementRef;
  public email: string;
  public currentRegion = '';
  public showError: boolean;
  public isUS: boolean;
  public USDisabled: boolean;
  public UKDisabled: boolean;
  public FEDisabled: boolean;
  public password: FormControl;
  // public JPDisabled: boolean;
  // public AUDisabled: boolean;
  public isUK: boolean;
  public isFE: boolean;
  // public isJP: boolean;
  // public isAU: boolean;
  public emailForm: FormControl;
  public copiedText: any;
  public currentSubId: any;
  public UsSubmarket = '';
  public UkSubmarket = '';
  public FeSubmarket = ''
  // public JpSubmarket = '';
  // public AuSubmarket = '';
  public showCopyTooltip = false;
  public emailInvalid = false;
  public links;
  public mfaInput = ['input0', 'input1', 'input2', 'input3', 'input4', 'input5'];
  @ViewChildren('mfaInputFields') mfaInputFields: any;
  form = this.formBuilder.group({});

  constructor(public constService: ConstService,
    public matDialog: MatDialog,
    public router: Router,
    private formBuilder: FormBuilder,
    public storageBrowser: StorageBrowser,
    public textService: TextService, 
    public mixpanelService: MixpanelService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService) {
      this.mixpanelService.init(null);
      const mfaFormGroup = this.form as FormGroup;

      this.mfaInput.map((item) => {
        mfaFormGroup.addControl(item, new FormControl());
      });
  }

  onKeyUp(event, index) {
    let pos = index;

    if (((event.keyCode >= 48 && event.which <= 57) || (event.keyCode >= 96 && event.which <= 105)) && event.target.value) {
      pos = index + 1;

      this.mfaInputFields._results[pos].nativeElement.focus();
    }
  }

  onPaste(event, index) {
    const pastedValue = event.clipboardData.getData('Text');

    if (index === 0) {
      // remove white space and update each form input field
      pastedValue.replace(/ /g, '').split('').map((item, index) => {
        this.form.controls[`input${index}`].setValue(item);
      });
    }
  }

  public ngOnInit(): void {
    this.password = new  FormControl('', [
      Validators.required]);
    if (this.userService.currentUser.id) {
      this.userService.getGrantAccessUrls(this.userService.currentUser.id).subscribe((links) => {
        this.links = links;
      });
    }
    if (this.data.dialogFor === 'add-region') {
      for (let i = 0; this.userService.currentUser.emails.length > i; i++) {
        if (this.userService.currentUser.emails) {
          this.userService.getTemporarySendEmail(this.userService.currentUser.emails[i].subid).subscribe(
            (e) => {
              if (this.constService.usRegion.indexOf(this.userService.currentUser.emails[i].submarket) !== -1) {
                this.UsSubmarket = e.sendEmail || '';
                this.isUS = true;
              }
              if (this.constService.euRegion.indexOf(this.userService.currentUser.emails[i].submarket) !== -1) {
                this.UkSubmarket = e.sendEmail || '';
                this.isUK = true;
              }
              if (this.constService.feRegion.indexOf(this.userService.currentUser.emails[i].submarket) !== -1) {
                this.FeSubmarket = e.sendEmail || '';
                this.isFE = true;
              }
              // if (this.constService.jpRegion.indexOf(this.userService.currentUser.emails[i].submarket) !== -1) {
              //     this.JpSubmarket = e.sendEmail || '';
              //     this.isJP = true;
              // }
              // if (this.constService.auRegion.indexOf(this.userService.currentUser.emails[i].submarket) !== -1) {
              //     this.AuSubmarket = e.sendEmail || '';
              //     this.isAU = true;
              // }
            }
          )
        }
      }
    }

    this.emailForm = new FormControl('', [
      Validators.required,
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    ]);
    if (!this.userService.currentUser.emails) {
      this.userService.currentUser.emails = this.storageBrowser.get('$UserToken$user').emails;
    }

    if (this.data.dialogFor === 'add-region') {
      if (this.userService.currentUser.emails) {
        for (let i = 0; this.userService.currentUser.emails.length > i; i++) {
          if (this.userService.currentUser.emails[i].submarket === 'US' ||
            this.userService.currentUser.emails[i].submarket === 'MX' ||
            this.userService.currentUser.emails[i].submarket === 'CA') {
            this.USDisabled = true;
          }
          if (this.userService.currentUser.emails[i].submarket === 'UK' ||
            this.userService.currentUser.emails[i].submarket === 'ES' ||
            this.userService.currentUser.emails[i].submarket === 'FR' ||
            this.userService.currentUser.emails[i].submarket === 'IT' ||
            this.userService.currentUser.emails[i].submarket === 'DE' ||
            this.userService.currentUser.emails[i].submarket === 'EU') {
            this.UKDisabled = true;
          }
          if (this.userService.currentUser.emails[i].submarket === 'JP' ||
            this.userService.currentUser.emails[i].submarket === 'AU' ||
            this.userService.currentUser.emails[i].submarket === 'SG') {
            this.FEDisabled = true;
          }
          // if (this.userService.currentUser.emails[i].submarket === 'JP') {
          //   this.JPDisabled = true;
          // }
          // if (this.userService.currentUser.emails[i].submarket === 'AU') {
          //   this.AUDisabled = true;
          // }
        }
      }
      if (this.data.grantAccess) {
        this.userService.currentUser.marketplace = this.storageBrowser.get('$UserToken$user').marketplace
        if (this.constService.usRegion.indexOf(this.userService.currentUser.marketplace) !== -1) {
          this.isUS = false;
          this.currentRegion = 'US';
        }
        if (this.constService.euRegion.indexOf(this.userService.currentUser.marketplace) !== -1) {
          this.isUK = false;
          this.currentRegion = 'EU';
        }
        if (this.constService.feRegion.indexOf(this.userService.currentUser.marketplace) !== -1) {
          this.isFE = false;
          this.currentRegion = 'FE';
        }
        // if (this.constService.jpRegion.indexOf(this.userService.currentUser.marketplace) !== -1) {
        //   this.isJP = false;
        //   this.currentRegion = 'JP';
        // }
        // if (this.constService.auRegion.indexOf(this.userService.currentUser.marketplace) !== -1) {
        //   this.isAU = false;
        //   this.currentRegion = 'AU';
        // }
      }
    }
  }

  public copyToClipboard() {
    this.copiedText = document.getElementById('token-text');
    this.copiedText.select();
    document.execCommand('copy');
    this.showCopyTooltip = true;
    setTimeout(() => {
      this.showCopyTooltip = false;
    }, 2000);
  }

  public editEmail(): void {
    if (!this.emailForm.valid) {
      this.showError = true;
      const old_value = this.emailForm.value;
      if (!this.emailForm.value || this.emailForm.value.length === 0) {
        this.emailForm.patchValue(this.textService.currentText.errorRequired);
      } else {
        this.emailForm.patchValue(this.textService.currentText.errorIncorectEmail);
      }
      this.emailForm.disable();
      this.emailInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    this.userService.updateEmail(this.email).subscribe((data) => {
      if (data.isSuccess) {
        this.dialogRef.close(true);
        this.userService.currentUser.emailFrom = this.emailForm.value;
      } else {
        this.showError = true;
        const old_value = this.emailForm.value;
        this.emailForm.patchValue(this.textService.currentText.errorEmailInUse);
        this.emailForm.disable();
        this.emailInput.nativeElement.blur();
        this.resetErrorState(old_value);
      }
    });
  }

  public resetErrorState(val: string): void {
    setTimeout(() => {
      this.showError = false;
      this.emailForm.patchValue(val);
      this.emailForm.enable();
      return;
    }, 2000);
  }

  public addRegion(input, region): void {
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!pattern.test(input.value)) {
      this.displayError(input);
    } else {
      // this.userService.dialogLoader = true;
      // let email = '';
      // if (this.currentRegion === 'US') {
      //   email = this.UsSubmarket
      // }
      // if (this.currentRegion === 'UK') {
      //   email = this.UkSubmarket;
      //   console.log(email, this.UkSubmarket);
      // }
      // if (this.currentRegion === 'FE') {
      //   email = this.FeSubmarket
      // }
      // if (this.currentRegion === 'JP') {
      //   email = this.JpSubmarket
      // }
      // if (this.currentRegion === 'AU') {
      //   email = this.AuSubmarket
      // }
      this.userService.addRegion(region, input.value).subscribe((links) => {
        if (links.hasOwnProperty('isSuccess') && links.isSuccess === false) {
          this.router.navigate(['dashboard/dashboard-schedules'])
          this.userService.dialogLoader = false;
          this.dialogRef.close(false)
        } else {
          window.location.href = links.Link;
        }
      });
    }
  }

  public transform(data) {
    return data.toLowerCase().split('_').join(' ');
  }

  public sendReport(): void {
    if (window.location.hostname === 'webapp.quantifyninja.com') {
      this.userService.sendLoaderReport(this.router.url).subscribe();
    }
    this.dialogRef.close();
  }

  public twoFaSubmit() {
    if (this.password.valid) {
      this.dialogRef.close(this.password.value);
    }
  }

  private displayError(input): void {
    this.emailInvalid = true;
    const old_value = input.value;
    input.value = 'Please Enter email ID';
    input.disable = true;
    input.blur();
    this.resetInputErrorState(old_value, input);
    return;
  }

  private resetInputErrorState(val: string, input: any): void {
    setTimeout(() => {
      input.value = val;
      this.emailInvalid = false;
      input.disable = false;
    }, 2000);
  }
  public navigateToSignUp() {
    this.dialogRef.close(true);
    this.router.navigate(['registration']);
  }
  public navigateToAmazon(): void {
    let link: string;
    if (this.userService.currentUser.marketplace == 'BR') {
      link = this.links.AMAZON_BRAZIL;
    } else if (this.userService.currentUser.marketplace == 'CA') {
      link = this.links.AMAZON_CANADA;
    } else if (this.userService.currentUser.marketplace == 'MX') {
      link = this.links.AMAZON_MEXICO;
    } else if (this.userService.currentUser.marketplace == 'US') {
      link = this.links.AMAZON_COM;
    } else if (this.userService.currentUser.marketplace == 'UAE') {
      link = this.links.AMAZON_UAE;
    } else if (this.userService.currentUser.marketplace == 'DE') {
      link = this.links.AMAZON_GERMANY;
    } else if (this.userService.currentUser.marketplace == 'ES') {
      link = this.links.AMAZON_SPAIN;
    } else if (this.userService.currentUser.marketplace == 'FR') {
      link = this.links.AMAZON_FRANCE;
    } else if (this.userService.currentUser.marketplace == 'UK') {
      link = this.links.AMAZON_UK;
    } else if (this.userService.currentUser.marketplace == 'IN') {
      link = this.links.AMAZON_INDIA;
    } else if (this.userService.currentUser.marketplace == 'IT') {
      link = this.links.AMAZON_ITALY;
    } else if (this.userService.currentUser.marketplace == 'NL') {
      link = this.links.AMAZON_NETHERLANDS;
    } else if (this.userService.currentUser.marketplace == 'SA') {
      link = this.links.AMAZON_SAUDI_ARABIA;
    } else if (this.userService.currentUser.marketplace == 'TR') {
      link = this.links.AMAZON_TURKEY;
    } else if (this.userService.currentUser.marketplace == 'SG') {
      link = this.links.AMAZON_SINGAPOR;
    } else if (this.userService.currentUser.marketplace == 'JP') {
      link = this.links.AMAZON_JAPAN;
    } else if (this.userService.currentUser.marketplace == 'AU') {
      link = this.links.AMAZON_AUSTRALIA;
    }
    // const user = this.userService.currentUser.emails.find(data => (data.submarket === this.userService.currentUser.marketplace));
    // link = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.ca82764b-41ed-46b1-83c6-298a96554243&state=${user.subid}`
    window.location.href = link;
  }
}
