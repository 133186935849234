<div class="upgrade-holder">
    <div class="close-btn" (click)="dialogRef.close(false)"></div>
    <div class="row">
        <div class="col-md-12 col-sm-6">
            <h1 mat-dialog-title class="title">Why do we recommend Telegram over
                Facebook messenger?</h1>
            <div class="">
                <p class="text-content">The Facebook policy requires users
                    to re-approve their permissions every 24 hours,</p>
                <p class="text-content">This means you will need to respond
                    to at least one of our
                    messages at least once a day,</p>
                <p class="text-content">If you do not response a message
                    after 24 hours we will
                    not be able to send you notifications until you re-approve
                    our permissions.</p>
                <p class="text-content">Telegram does not impose such a
                    requirement, so you will
                    only need to approve our permissions once (you can always
                    opt-out by turning off notifications)</p>
            </div>
        </div>
        <div class="col-md-12 col-sm-6 text-center pt-3">
            <button class="close-button" (click)="dialogRef.close(false)">Close</button>
        </div>
    </div>
</div>