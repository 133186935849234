import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import * as moment from 'moment';
import { AppConfigService } from './app-config.service';
@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  public isDeveloping: boolean;
  public isInit=false;
  constructor() {
    this.isDeveloping = AppConfigService.isDev;
  }

  public initDev(userToken: string): void {
    mixpanel.init("3730cc01bd369ca1819cf32298a89e13", { batch_requests: true });
    //mixpanel.identify(userToken);
  }

  public initProd(userToken: string): void {
    mixpanel.init("8c0e04220f9e670f2f6558ab1ef64026", { batch_requests: true });
    //mixpanel.identify(userToken);
  }

  public init(userToken: string): void {
    console.log("this.isDeveloping", this.isDeveloping)
    if (this.isInit)
    {
      console.log("this.init already initialized", this.isDeveloping);
      return;
    }
    if (this.isDeveloping) {
      this.initDev(userToken);
    } else {
      this.initProd(userToken);
    }
    this.isInit=true;
  }

  public identify(userIdentify: any): void {
    console.log('identify', userIdentify)
    mixpanel.identify(userIdentify);
  }

  public alias(userAlias: any): void {
    console.log('alias', userAlias)
    mixpanel.alias(userAlias);
  }

  public people(email: string, signupDate: Date ,userId: string): void {
    mixpanel.people.set({
      "$email": email,
      "Sign up date": signupDate,//moment(new Date).format(),
      "USER_ID": userId,
    });
  }

  // send Events with properties
  public track(page: any, action: any = {}): void {
    mixpanel.track(page, action);
  }
}
