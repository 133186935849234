<div class="dashboard one-screen">
  <div class="dashboard-container">
    <div class="dashboard-header">
      <div class="head-container">
        <h2>{{textService.currentText.inviteFriendsTitle}}</h2>
      </div>
      <div class="sub-head-container">
        <p>
          {{textService.currentText.inviteFriendsSubTitle}}
        </p>
      </div>
    </div>
    <input type="text" matInput class="input-width">
    <br>
    <br>
    <button mat-button
            mat-raised-button
            class="primary-btn">
      {{textService.currentText.inviteFriendsSend}}
    </button>
  </div>
</div>
