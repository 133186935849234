<div class="main-dialog-container">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
    <div class="close-btn" (click)="dialogRef.close(false)"></div>
    <div class="inner-dialog-container confirmation-dialog">
          <div class="dialog-content">
            <div class="header">
              <h2>{{textService.currentText.createNewAudiecnce}}</h2>
            </div>
            <ng-container name="form" [formGroup]="submitForm" novalidate>
              <div class="dialog-row" [class.has-danger]="errors.name">
                <div class="label-name">
                  <span>{{textService.currentText.audienceName}}</span>
                </div>
                <input matInput
                       #name
                       type="text"
                       formControlName="name">
              </div>
              <div class="dialog-row" [class.has-danger]="errors.description">
                <div class="label-name">
                  <span>{{textService.currentText.description}}</span>
                </div>
                <input matInput
                       #name
                       type="text"
                       formControlName="description">
              </div>
            </ng-container>
          </div>
        </div>
    <div class="dialog-footer">
            <button mat-button class="primary-btn white" (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
            <button mat-button class="primary-btn" (click)="save()">{{textService.currentText.save}}</button>
          </div>
</div>
