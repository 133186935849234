<div class="login-container">
  <div class="left-container">
    <div class="container">
      <h2>{{textService.currentText.createNewPassword}}</h2>
      <form class="login-form" name="form" [formGroup]="submitForm" name="form">
        <div class="form-group" [class.has-danger]="errors?.password">
          <label for="password"
                 (click)="passwordInputType === 'password' ? passwordInputType = 'text' : passwordInputType = 'password'">
            <i class="icon password"
               [class.visible]="passwordInputType === 'text'"></i>
          </label>
          <input [attr.type]="passwordInputType"
                 id="password"
                 #passwordInput
                 class="form-control-x"
                 formControlName="password"
                 [placeholder]="textService.currentText.password"
                 [(ngModel)]="userPass"
                 (focus)="passwordInputCaps = true"
                 (focusout)="passwordInputCaps = false"
          />
          <div class="icon-container big-indent">
            <div class="capslock-icon help-icon inside-input" title="CAPSLOCK enabled"
                 *ngIf="capsOn && passwordInputCaps"></div>
          </div>
          <div class="icon-container inside-input">
            <div class="q-icon help-icon" (click)="isPasswordTooltip = !isPasswordTooltip"
                 [class.active]="isPasswordTooltip">
              ?
            </div>
            <ninja-tooltip (toggledChange)="showTooltip($event)"
                           [text]="tooltipPassword"
                           *ngIf="isPasswordTooltip"></ninja-tooltip>
          </div>
        </div>
        <div class="form-group" [class.has-danger]="errors?.confirmPassword">
          <label for="confirmPassword"
                 (click)="rePasswordInputType === 'password' ? rePasswordInputType = 'text' : rePasswordInputType = 'password'">
            <i class="icon password"
               [class.visible]="rePasswordInputType === 'text'"></i>
          </label>
          <input [attr.type]="rePasswordInputType"
                 id="confirmPassword"
                 #confirmPassInput
                 class="form-control-x"
                 formControlName="confirmPassword"
                 [placeholder]="textService.currentText.repeatPassword"
                 [(ngModel)]="userPassConfirm"
                 (focus)="confirmPassInputCaps = true"
                 (focusout)="confirmPassInputCaps = false"
          />
          <div class="icon-container">
            <div class="capslock-icon help-icon inside-input" title="CAPSLOCK enabled"
                 *ngIf="capsOn && confirmPassInputCaps"></div>
          </div>
        </div>
        <div class="form-group-button">
          <button mat-button (click)="savePassword()">{{textService.currentText.save}}</button>
        </div>
      </form>
    </div>
  </div>
  <div class="right-container">
    <div class="content">
      <div class="img-container">
        <img src="../../../../assets/images/login-img.png" alt="login-img">
      </div>
      <h3>{{textService.currentText.loginTitle}}</h3>
      <p>
        {{textService.currentText.loginTitleText}}
        <br/>
        {{textService.currentText.loginTitleText2}}
      </p>
    </div>
  </div>
</div>
