import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl } from "@angular/forms";
import { map } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';
import { from, of } from 'rxjs';
import { ShareService } from 'src/app/services/share.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-profitbreakdown',
  templateUrl: './profitbreakdown.component.html',
  styleUrls: ['./profitbreakdown.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProfitbreakdownComponent implements OnInit {
  @Input() scopeHierarchyData: any;
  @Input() formData: any;
  @Input() variationView = false;
  service: any;
  proFitFormData: any;
  tableColspanCount: any = [];
  profitbreakdowndataSource: any;
  @Output("search") search = new EventEmitter();
  searchCtrl = new FormControl();
  groupby = new FormControl();
  selectMatrix = new FormControl();
  expandedElement: any = 0;
  sublevel1expandedElement: any;
  sublevel2expandedElement: any;
  sublevel3expandedElement: any;
  chartExpandedElement: any;
  sublevel1chartExpandedElement: any;
  sublevel2chartExpandedElement: any;
  sublevel3chartExpandedElement: any;
  sublevel4chartExpandedElement: any;
  selectedVal: any;
  barMaxWidth = 10;
  columnFiltersList: any = [];
  filters: any = {
    selectAll: true,
    title: true,
    asin: false,
    sku: false,
    sales: true,
    revenue: true,
    costs: true,
    profit: true,
    margin: true,
    promos: true,
    ppcUnits: true,
    ppcExpenses: true,
    ppcPerUnit: true,
    roi: true,
    stock: true,
    detail: true,
    tACoS : false,
  };


  constructor(private dialog: MatDialog, private shareService: ShareService, public userService: UserService) {
  }
  ngOnInit() {

    this.proFitFormData = this.formData;
    let arr = [];
    const filterData = JSON.parse(JSON.stringify(this.scopeHierarchyData));
    if (filterData["filter"].title || filterData["filter"].asin || filterData["filter"].sku || filterData["filter"].sales || filterData["filter"].revenue ||
      filterData["filter"].costs || filterData["filter"].profit || filterData["filter"].margin || filterData["filter"].promos ||
      filterData["filter"].roi || filterData["filter"].stock || filterData["filter"].ppcPerUnit || filterData["filter"].ppcExpenses || filterData["filter"].ppcUnits ||
      filterData["filter"].detail || filterData["filter"].sales || filterData["filter"].tACoS) {
      filterData["filter"].selectAll = false;
    } else {
      filterData["filter"].selectAll = true;
    }
    console.log(filterData["filter"]);
    this.filters = filterData["filter"];
    console.log(JSON.parse(JSON.stringify(this.scopeHierarchyData)))
    Object.values(this.filters).map(function (value) {
      if (value) {
        arr.push({ value })
        return arr;
      }
    });
    this.tableColspanCount = arr.length + 1;
    if (this.scopeHierarchyData) {
      this.profitbreakdowndataSource = JSON.parse(JSON.stringify(this.scopeHierarchyData));
    }
    // const profitData = this.profitbreakdowndataSource.rowData.map((data) => {
    //   return { brand: data.brand, sku: data.sku }
    // })
    // console.log(profitData);
    // this.proFitFormData.brands = this.profitbreakdowndataSource.rowData.map(data => data.brand);
    // this.proFitFormData.skus = this.profitbreakdowndataSource.rowData.map(data => data.sku);
    this.groupby.valueChanges.subscribe(value => {
      this.selectedVal = value;
      this.profitbreakdowndataSource = JSON.parse(JSON.stringify(this.scopeHierarchyData));
      const data = this.profitbreakdowndataSource.rowData;
      const data1 = this.profitbreakdowndataSource.rowDataSplitByChannel;
      const groupBy = (data, keyFn) => data.reduce((agg, item) => {
        const group = keyFn(item);
        agg[group] = [...(agg[group] || []), item];
        return agg;
      }, {});

      if (value.includes('brand')) {
        of(data).pipe(
          map(data => groupBy(data, item => item.brand ? item.brand : '')
          ))
          .subscribe((res) => {
            this.profitbreakdowndataSource = Object.keys(res).map(key => {
              let resD = res[key];
              return [{ "title": key, "totalStockValue": 0, "totalSalesValue": 0, "totalSalesChange": 0, "totalPPCValue": 0, "totalPPCChange": 0, "totalPPCExpensesValue": 0, "totalPPCExpensesChange": 0, "totalPPCPerUnitValue": 0, "totalPPCPerUnitChange": 0, "totalPromosValue": 0, "totalPromosChange": 0, "totalRevenueValue": 0, "totalRevenueChange": 0, "totalCostValue": 0, "totalCostChange": 0, "totalProfitValue": 0, "totalRoiPerItem": 0, "totalProfitChange": 0, "totalMarginValue": 0, "totalMarginChange": 0, "totalRoiValue": 0, "totalRoiChange": 0, "sku": [], "type": 'brand', 'img': 'assets/images/brand.png', 'resD': resD }, res[key]];
            });
          });
      }
      if (value.includes('parentAsin')) {
        of(data).pipe(
          map(data => groupBy(data, item => item.parentAsin ? item.parentAsin : '')
          ))
          .subscribe((res) => {
            this.profitbreakdowndataSource = Object.keys(res).map(key => {
              let resD = res[key];
              let image = resD[0].image;
              return [{ "title": key, "totalStockValue": 0, "totalSalesValue": 0, "totalSalesChange": 0, "totalPPCValue": 0, "totalPPCChange": 0, "totalPPCExpensesValue": 0, "totalPPCExpensesChange": 0, "totalPPCPerUnitValue": 0, "totalPPCPerUnitChange": 0, "totalPromosValue": 0, "totalPromosChange": 0, "totalRevenueValue": 0, "totalRevenueChange": 0, "totalCostValue": 0, "totalCostChange": 0, "totalProfitValue": 0, "totalRoiPerItem": 0, "totalProfitChange": 0, "totalMarginValue": 0, "totalMarginChange": 0, "totalRoiValue": 0, "totalRoiChange": 0, "sku": [], "type": 'parentAsin', 'img': image, 'resD': resD }, res[key]];
            });
          });
      }
      if (value.includes('mp')) {
        of(data1).pipe(
          map(data1 => groupBy(data1, item => item.mp ? item.mp : '')
          ))
          .subscribe((res) => {
            this.profitbreakdowndataSource = Object.keys(res).map(key => {
              let resD = res[key];
              let mpImage = this.get_flag(key.split("."));
              return [{ "title": key, "totalStockValue": 0, "totalSalesValue": 0, "totalSalesChange": 0, "totalPPCValue": 0, "totalPPCChange": 0, "totalPPCExpensesValue": 0, "totalPPCExpensesChange": 0, "totalPPCPerUnitValue": 0, "totalPPCPerUnitChange": 0, "totalPromosValue": 0, "totalPromosChange": 0, "totalRevenueValue": 0, "totalRevenueChange": 0, "totalCostValue": 0, "totalCostChange": 0, "totalProfitValue": 0, "totalRoiPerItem": 0, "totalProfitChange": 0, "totalMarginValue": 0, "totalMarginChange": 0, "totalTacosValue" : 0, "totalTacosChange" : 0, "totalRoiValue": 0, "totalRoiChange": 0, "sku": [], "type": 'mp', 'img': mpImage, 'resD': resD }, res[key]];
            });
          });
      }
      if (value.includes('brand') && value.includes('mp')) {
        of(data1).pipe(
          map(data1 => groupBy(data1, item => item.brand ? item.brand : '')
          ))
          .subscribe((res) => {
            this.profitbreakdowndataSource = Object.keys(res).map(key => {
              let keyVal = res[key];
              let allDataList = [];
              of(keyVal).pipe(
                map(keyVal => groupBy(keyVal, item => item.mp ? item.mp : '')
                ))
                .subscribe((resData) => {
                  allDataList = Object.keys(resData).map(key1 => {
                    let resD = resData[key1];
                    let mpImage = this.get_flag(key1.split("."));
                    return [{ "title": key1, "totalStockValue": 0, "totalSalesValue": 0, "totalSalesChange": 0, "totalPPCValue": 0, "totalPPCChange": 0, "totalPPCExpensesValue": 0, "totalPPCExpensesChange": 0, "totalPPCPerUnitValue": 0, "totalPPCPerUnitChange": 0, "totalPromosValue": 0, "totalPromosChange": 0, "totalRevenueValue": 0, "totalRevenueChange": 0, "totalCostValue": 0, "totalCostChange": 0, "totalProfitValue": 0, "totalRoiPerItem": 0, "totalProfitChange": 0, "totalMarginValue": 0, "totalMarginChange": 0, "totalTacosValue" : 0, "totalTacosChange" : 0, "totalRoiValue": 0, "totalRoiChange": 0, "sku": [], "type": 'both', 'img': mpImage, 'resD': resD }, resData[key1]];
                  });
                });
              return [{ "title": key, "totalStockValue": 0, "totalSalesValue": 0, "totalSalesChange": 0, "totalPPCValue": 0, "totalPPCChange": 0, "totalPPCExpensesValue": 0, "totalPPCExpensesChange": 0, "totalPPCPerUnitValue": 0, "totalPPCPerUnitChange": 0, "totalPromosValue": 0, "totalPromosChange": 0, "totalRevenueValue": 0, "totalRevenueChange": 0, "totalCostValue": 0, "totalCostChange": 0, "totalProfitValue": 0, "totalRoiPerItem": 0, "totalProfitChange": 0, "totalMarginValue": 0, "totalMarginChange": 0, "totalTacosValue" : 0, "totalTacosChange" : 0, "totalRoiValue": 0, "totalRoiChange": 0, "sku": [], "type": 'both', 'img': 'assets/images/brand.png', 'resD': allDataList }, allDataList];
            });
          });
      }
    });
    if (this.variationView) {
      this.groupby.patchValue('parentAsin');
    }
  }

  downloadPB() {
    this.userService.getDownloadProfitBreakdown(this.proFitFormData).subscribe((res: any) => {
      console.log(res);
      if (res) {
        this.downloadFile(res.link);
      }
    });
  }

  public downloadFile(path) {
    const file_path = path;
    const a: HTMLElement = document.createElement('A') as HTMLElement;
    a['href'] = file_path;
    a['download'] = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }



  async columnFilters(value, filterName) {
    if (filterName == 'title') {
      this.filters.title = value;
    } else if (filterName == 'asin') {
      this.filters.asin = value;
    } else if (filterName == 'sku') {
      this.filters.sku = value;
    } else if (filterName == 'sales') {
      this.filters.sales = value;
    } else if (filterName == 'revenue') {
      this.filters.revenue = value;
    } else if (filterName == 'costs') {
      console.log("value", value)
      this.filters.costs = value;
    } else if (filterName == 'profit') {
      this.filters.profit = value;
    } else if (filterName == 'margin') {
      this.filters.margin = value;
    } else if(filterName == 'tACoS'){
      this.filters.tACoS = value;
    } else if (filterName == 'promos') {
      this.filters.promos = value;
    } else if (filterName == 'ppcUnits') {
      this.filters.ppcUnits = value;
    } else if (filterName == 'ppcExpenses') {
      this.filters.ppcExpenses = value;
    } else if (filterName == 'ppcPerUnit') {
      this.filters.ppcPerUnit = value;
    } else if (filterName == 'stock') {
      this.filters.stock = value;
    } else if (filterName == 'roi') {
      this.filters.roi = value;
    } else if (filterName == 'detail') {
      this.filters.detail = value;
    } else if (filterName == 'selectAll') {
      if (value) {
        this.filters.title = this.filters.asin = this.filters.sku = this.filters.sales = this.filters.revenue = this.filters.costs =
          this.filters.profit = this.filters.stock = this.filters.margin = this.filters.promos = this.filters.ppcUnits =
          this.filters.ppcExpenses = this.filters.ppcPerUnit = this.filters.roi = this.filters.detail = this.filters.tACoS = true;
      } else {
        this.filters.title = this.filters.asin = this.filters.sku = this.filters.sales = this.filters.revenue = this.filters.costs =
          this.filters.profit = this.filters.stock = this.filters.margin = this.filters.promos = this.filters.ppcUnits =
          this.filters.ppcExpenses = this.filters.ppcPerUnit = this.filters.roi = this.filters.detail = this.filters.tACoS = false;
      }
    }
    delete this.filters.selectAll;
    await this.userService.profitBreakDownFilter(this.filters).subscribe((res) => { })
    let arr = [];
    Object.values(this.filters).map((value) => {
      if (value) {
        arr.push({ value })
        return arr;
      }
    });
    this.tableColspanCount = arr.length + 1;
  }

  get_flag(marketPlaceChangeIcon) {
    let mpImage = '';
    if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "ca") {
      mpImage = 'assets/images/flag_canada.svg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "com" && marketPlaceChangeIcon[2] == "mx") {
      mpImage = 'assets/images/flag_mexico.svg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "co" && marketPlaceChangeIcon[2] == "uk") {
      mpImage = 'assets/images/flag_uk.svg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "fr") {
      mpImage = 'assets/images/flag_france.svg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "de") {
      mpImage = 'assets/images/flag_germany.svg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "it") {
      mpImage = 'assets/images/flag_italy.svg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "es") {
      mpImage = 'assets/images/flag_spain.svg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "co" && marketPlaceChangeIcon[2] == "jp") {
      mpImage = 'assets/images/japan.svg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "com" && marketPlaceChangeIcon[2] == "au") {
      mpImage = 'assets/images/australia.svg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "in") {
      mpImage = 'assets/images/india.svg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "ae") {
      mpImage = 'assets/images/UAE_Flag.svg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "com" && marketPlaceChangeIcon[2] == "br") {
      mpImage = 'assets/images/Brazil_br.jpeg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "com/Saudi-Arabia") {
      mpImage = 'assets/images/SoudiArabia.jpeg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "com" && marketPlaceChangeIcon[2] == "tr") {
      mpImage = 'assets/images/turkey_tr.jpeg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "com") {
      mpImage = 'assets/images/marketplace.png'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "sg") {
      mpImage = 'assets/images/Singapore_sg.jpeg'
    } else if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "nl") {
      mpImage = 'assets/images/Netherlands_nl.jpeg'
    } else {
      mpImage = 'assets/images/noImage.png';
    }
    return mpImage;
  }
}