import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'filter-dropdown-menu',
  templateUrl: './filterdropdownmenu.component.html',
  styleUrls: ['./filterdropdownmenu.component.scss']
})

export class FilterdropdownmenuComponent implements OnInit {
  @Input() oneGrouping: any;
  @Input() profitbreakdowndataSource: any;
  @Output() messageToEmit = new EventEmitter<string>();
  @Input() filterType: any;
  @Input() rowType: any;
  @Input() groupType: any;
  filterData: any;
  type: any;
  type1: any;
  serachType: any;
  serachType1: any;
  textualtype: any;
  textualtype1: any;
  textualserachType: any;
  textualserachType1: any;
  textualconditionData: any;
  conditionData: any;
  countProduct: any = 0;
  constructor() {
  }
  ngOnInit() {
    console.log("this.filterType", this.groupType)
    if (this.groupType == 'none') {
      this.filterData = this.profitbreakdowndataSource ? this.profitbreakdowndataSource.rowData : '';
    } else if (this.groupType == 'one') {
      this.filterData = this.oneGrouping;
    } else if (this.groupType == 'two') {
      this.filterData = this.oneGrouping;
    }
  }

  saveFilterWithTitle() {
    let conditionS = '';
    if (this.textualconditionData == '1') {
      conditionS = ' && ';
    } else if (this.textualconditionData == '2') {
      conditionS = ' || ';
    }
    if (this.groupType == 'none') {
      this.profitbreakdowndataSource.rowData = this.filterData.filter((main) => {
        let textualType = false;
        let textualType1 = false;
        if (this.filterType == 'title') {
          if (this.textualtype == 'equal') {
            textualType = ((main.title.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
          }
          if (this.textualtype == 'not-equal') {
            textualType = ((main.title.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
          }
          if (this.textualtype1 == 'equal') {
            textualType1 = ((main.title.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
          }
          if (this.textualtype1 == 'not-equal') {
            textualType1 = ((main.title.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
          }
          if (this.textualtype == 'contains') {
            textualType = ((main.title.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
          }
          if (this.textualtype == 'does-not-contain') {
            textualType = ((main.title.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
          }
          if (this.textualtype1 == 'contains') {
            textualType1 = ((main.title.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
          }
          if (this.textualtype1 == 'does-not-contain') {
            textualType1 = ((main.title.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
          }
          if (this.textualtype == 'start-with') {
            textualType = (main.title.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
          }
          if (this.textualtype == 'end-with') {
            textualType = (main.title.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
          }
          if (this.textualtype1 == 'start-with') {
            textualType1 = (main.title.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
          }
          if (this.textualtype1 == 'end-with') {
            textualType1 = (main.title.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
          }
        } else if (this.filterType == 'asin') {
          if (this.textualtype == 'equal') {
            textualType = ((main.asin.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
          }
          if (this.textualtype == 'not-equal') {
            textualType = ((main.asin.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
          }
          if (this.textualtype1 == 'equal') {
            textualType1 = ((main.asin.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
          }
          if (this.textualtype1 == 'not-equal') {
            textualType1 = ((main.asin.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
          }
          if (this.textualtype == 'contains') {
            textualType = ((main.asin.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
          }
          if (this.textualtype == 'does-not-contain') {
            textualType = ((main.asin.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
          }
          if (this.textualtype1 == 'contains') {
            textualType1 = ((main.asin.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
          }
          if (this.textualtype1 == 'does-not-contain') {
            textualType1 = ((main.asin.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
          }
          if (this.textualtype == 'start-with') {
            textualType = (main.asin.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
          }
          if (this.textualtype == 'end-with') {
            textualType = (main.asin.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
          }
          if (this.textualtype1 == 'start-with') {
            textualType1 = (main.asin.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
          }
          if (this.textualtype1 == 'end-with') {
            textualType1 = (main.asin.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
          }
        } else if (this.filterType == 'sku') {
          if (this.textualtype == 'equal') {
            textualType = ((main.sku.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
          }
          if (this.textualtype == 'not-equal') {
            textualType = ((main.sku.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
          }
          if (this.textualtype1 == 'equal') {
            textualType1 = ((main.sku.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
          }
          if (this.textualtype1 == 'not-equal') {
            textualType1 = ((main.sku.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
          }
          if (this.textualtype == 'contains') {
            textualType = ((main.sku.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
          }
          if (this.textualtype == 'does-not-contain') {
            textualType = ((main.sku.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
          }
          if (this.textualtype1 == 'contains') {
            textualType1 = ((main.sku.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
          }
          if (this.textualtype1 == 'does-not-contain') {
            textualType1 = ((main.sku.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
          }
          if (this.textualtype == 'start-with') {
            textualType = (main.sku.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
          }
          if (this.textualtype == 'end-with') {
            textualType = (main.sku.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
          }
          if (this.textualtype1 == 'start-with') {
            textualType1 = (main.sku.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
          }
          if (this.textualtype1 == 'end-with') {
            textualType1 = (main.sku.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
          }
        } else if (this.filterType == 'stock') {
          if (this.textualtype == 'equal') {
            textualType = ((main.inStock.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
          }
          if (this.textualtype == 'not-equal') {
            textualType = ((main.inStock.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
          }
          if (this.textualtype1 == 'equal') {
            textualType1 = ((main.inStock.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
          }
          if (this.textualtype1 == 'not-equal') {
            textualType1 = ((main.inStock.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
          }
          if (this.textualtype == 'contains') {
            textualType = ((main.inStock.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
          }
          if (this.textualtype == 'does-not-contain') {
            textualType = ((main.inStock.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
          }
          if (this.textualtype1 == 'contains') {
            textualType1 = ((main.inStock.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
          }
          if (this.textualtype1 == 'does-not-contain') {
            textualType1 = ((main.inStock.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
          }
          if (this.textualtype == 'start-with') {
            textualType = (main.inStock.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
          }
          if (this.textualtype == 'end-with') {
            textualType = (main.inStock.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
          }
          if (this.textualtype1 == 'start-with') {
            textualType1 = (main.inStock.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
          }
          if (this.textualtype1 == 'end-with') {
            textualType1 = (main.inStock.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
          }
        }
        if (conditionS != '') {
          return eval((textualType) + conditionS + (textualType1));
        } else if (textualType) {
          return textualType;
        } else if (textualType1) {
          return textualType1;
        }
      });
      this.messageToEmit.emit(this.profitbreakdowndataSource);
    } else if (this.groupType == 'one') {
      this.filterData.map((res, i) => {
        if (res[0].title) {
          this.oneGrouping[i][0].resD = res[0].resD.filter((main) => {
            let textualType = false;
            let textualType1 = false;
            if (this.filterType == 'title') {
              if (this.textualtype == 'equal') {
                textualType = ((main.title.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
              }
              if (this.textualtype == 'not-equal') {
                textualType = ((main.title.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
              }
              if (this.textualtype1 == 'equal') {
                textualType1 = ((main.title.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
              }
              if (this.textualtype1 == 'not-equal') {
                textualType1 = ((main.title.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
              }
              if (this.textualtype == 'contains') {
                textualType = ((main.title.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
              }
              if (this.textualtype == 'does-not-contain') {
                textualType = ((main.title.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
              }
              if (this.textualtype1 == 'contains') {
                textualType1 = ((main.title.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
              }
              if (this.textualtype1 == 'does-not-contain') {
                textualType1 = ((main.title.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
              }
              if (this.textualtype == 'start-with') {
                textualType = (main.title.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
              }
              if (this.textualtype == 'end-with') {
                textualType = (main.title.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
              }
              if (this.textualtype1 == 'start-with') {
                textualType1 = (main.title.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
              }
              if (this.textualtype1 == 'end-with') {
                textualType1 = (main.title.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
              }
            } else if (this.filterType == 'asin') {
              if (this.textualtype == 'equal') {
                textualType = ((main.asin.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
              }
              if (this.textualtype == 'not-equal') {
                textualType = ((main.asin.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
              }
              if (this.textualtype1 == 'equal') {
                textualType1 = ((main.asin.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
              }
              if (this.textualtype1 == 'not-equal') {
                textualType1 = ((main.asin.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
              }
              if (this.textualtype == 'contains') {
                textualType = ((main.asin.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
              }
              if (this.textualtype == 'does-not-contain') {
                textualType = ((main.asin.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
              }
              if (this.textualtype1 == 'contains') {
                textualType1 = ((main.asin.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
              }
              if (this.textualtype1 == 'does-not-contain') {
                textualType1 = ((main.asin.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
              }
              if (this.textualtype == 'start-with') {
                textualType = (main.asin.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
              }
              if (this.textualtype == 'end-with') {
                textualType = (main.asin.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
              }
              if (this.textualtype1 == 'start-with') {
                textualType1 = (main.asin.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
              }
              if (this.textualtype1 == 'end-with') {
                textualType1 = (main.asin.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
              }
            } else if (this.filterType == 'sku') {
              if (this.textualtype == 'equal') {
                textualType = ((main.sku.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
              }
              if (this.textualtype == 'not-equal') {
                textualType = ((main.sku.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
              }
              if (this.textualtype1 == 'equal') {
                textualType1 = ((main.sku.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
              }
              if (this.textualtype1 == 'not-equal') {
                textualType1 = ((main.sku.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
              }
              if (this.textualtype == 'contains') {
                textualType = ((main.sku.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
              }
              if (this.textualtype == 'does-not-contain') {
                textualType = ((main.sku.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
              }
              if (this.textualtype1 == 'contains') {
                textualType1 = ((main.sku.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
              }
              if (this.textualtype1 == 'does-not-contain') {
                textualType1 = ((main.sku.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
              }
              if (this.textualtype == 'start-with') {
                textualType = (main.sku.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
              }
              if (this.textualtype == 'end-with') {
                textualType = (main.sku.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
              }
              if (this.textualtype1 == 'start-with') {
                textualType1 = (main.sku.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
              }
              if (this.textualtype1 == 'end-with') {
                textualType1 = (main.sku.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
              }
            } else if (this.filterType == 'stock') {
              if (this.textualtype == 'equal') {
                textualType = ((main.inStock.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
              }
              if (this.textualtype == 'not-equal') {
                textualType = ((main.inStock.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
              }
              if (this.textualtype1 == 'equal') {
                textualType1 = ((main.inStock.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
              }
              if (this.textualtype1 == 'not-equal') {
                textualType1 = ((main.inStock.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
              }
              if (this.textualtype == 'contains') {
                textualType = ((main.inStock.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
              }
              if (this.textualtype == 'does-not-contain') {
                textualType = ((main.inStock.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
              }
              if (this.textualtype1 == 'contains') {
                textualType1 = ((main.inStock.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
              }
              if (this.textualtype1 == 'does-not-contain') {
                textualType1 = ((main.inStock.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
              }
              if (this.textualtype == 'start-with') {
                textualType = (main.inStock.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
              }
              if (this.textualtype == 'end-with') {
                textualType = (main.inStock.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
              }
              if (this.textualtype1 == 'start-with') {
                textualType1 = (main.inStock.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
              }
              if (this.textualtype1 == 'end-with') {
                textualType1 = (main.inStock.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
              }
            }
            if (conditionS != '') {
              return eval((textualType) + conditionS + (textualType1));
            } else if (textualType) {
              return textualType;
            } else if (textualType1) {
              return textualType1;
            }
          });
        }
      });
      this.messageToEmit.emit(this.filterData);
    } else if (this.groupType == 'two') {
      this.filterData.map((res, i) => {
        if (res[0].title) {
          res[0].resD.map((resData, j) => {
            if (resData[0].title) {
              this.oneGrouping[i][0].resD[j][0].resD = resData[0].resD.filter((main) => {
                let textualType = false;
                let textualType1 = false;
                if (this.filterType == 'title') {
                  if (this.textualtype == 'equal') {
                    textualType = ((main.title.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
                  }
                  if (this.textualtype == 'not-equal') {
                    textualType = ((main.title.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
                  }
                  if (this.textualtype1 == 'equal') {
                    textualType1 = ((main.title.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
                  }
                  if (this.textualtype1 == 'not-equal') {
                    textualType1 = ((main.title.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
                  }
                  if (this.textualtype == 'contains') {
                    textualType = ((main.title.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
                  }
                  if (this.textualtype == 'does-not-contain') {
                    textualType = ((main.title.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
                  }
                  if (this.textualtype1 == 'contains') {
                    textualType1 = ((main.title.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
                  }
                  if (this.textualtype1 == 'does-not-contain') {
                    textualType1 = ((main.title.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
                  }
                  if (this.textualtype == 'start-with') {
                    textualType = (main.title.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
                  }
                  if (this.textualtype == 'end-with') {
                    textualType = (main.title.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
                  }
                  if (this.textualtype1 == 'start-with') {
                    textualType1 = (main.title.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
                  }
                  if (this.textualtype1 == 'end-with') {
                    textualType1 = (main.title.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
                  }
                } else if (this.filterType == 'asin') {
                  if (this.textualtype == 'equal') {
                    textualType = ((main.asin.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
                  }
                  if (this.textualtype == 'not-equal') {
                    textualType = ((main.asin.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
                  }
                  if (this.textualtype1 == 'equal') {
                    textualType1 = ((main.asin.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
                  }
                  if (this.textualtype1 == 'not-equal') {
                    textualType1 = ((main.asin.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
                  }
                  if (this.textualtype == 'contains') {
                    textualType = ((main.asin.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
                  }
                  if (this.textualtype == 'does-not-contain') {
                    textualType = ((main.asin.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
                  }
                  if (this.textualtype1 == 'contains') {
                    textualType1 = ((main.asin.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
                  }
                  if (this.textualtype1 == 'does-not-contain') {
                    textualType1 = ((main.asin.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
                  }
                  if (this.textualtype == 'start-with') {
                    textualType = (main.asin.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
                  }
                  if (this.textualtype == 'end-with') {
                    textualType = (main.asin.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
                  }
                  if (this.textualtype1 == 'start-with') {
                    textualType1 = (main.asin.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
                  }
                  if (this.textualtype1 == 'end-with') {
                    textualType1 = (main.asin.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
                  }
                } else if (this.filterType == 'sku') {
                  if (this.textualtype == 'equal') {
                    textualType = ((main.sku.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
                  }
                  if (this.textualtype == 'not-equal') {
                    textualType = ((main.sku.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
                  }
                  if (this.textualtype1 == 'equal') {
                    textualType1 = ((main.sku.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
                  }
                  if (this.textualtype1 == 'not-equal') {
                    textualType1 = ((main.sku.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
                  }
                  if (this.textualtype == 'contains') {
                    textualType = ((main.sku.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
                  }
                  if (this.textualtype == 'does-not-contain') {
                    textualType = ((main.sku.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
                  }
                  if (this.textualtype1 == 'contains') {
                    textualType1 = ((main.sku.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
                  }
                  if (this.textualtype1 == 'does-not-contain') {
                    textualType1 = ((main.sku.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
                  }
                  if (this.textualtype == 'start-with') {
                    textualType = (main.sku.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
                  }
                  if (this.textualtype == 'end-with') {
                    textualType = (main.sku.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
                  }
                  if (this.textualtype1 == 'start-with') {
                    textualType1 = (main.sku.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
                  }
                  if (this.textualtype1 == 'end-with') {
                    textualType1 = (main.sku.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
                  }
                } else if (this.filterType == 'stock') {
                  if (this.textualtype == 'equal') {
                    textualType = ((main.inStock.toLowerCase().trim() == this.textualserachType.toLowerCase().trim()));
                  }
                  if (this.textualtype == 'not-equal') {
                    textualType = ((main.inStock.toLowerCase().trim() != this.textualserachType.toLowerCase().trim()));
                  }
                  if (this.textualtype1 == 'equal') {
                    textualType1 = ((main.inStock.toLowerCase().trim() == this.textualserachType1.toLowerCase().trim()));
                  }
                  if (this.textualtype1 == 'not-equal') {
                    textualType1 = ((main.inStock.toLowerCase().trim() != this.textualserachType1.toLowerCase().trim()));
                  }
                  if (this.textualtype == 'contains') {
                    textualType = ((main.inStock.toLowerCase().indexOf(this.textualserachType.toLowerCase())) > -1);
                  }
                  if (this.textualtype == 'does-not-contain') {
                    textualType = ((main.inStock.toLowerCase().indexOf(this.textualserachType.toLowerCase())) < 0);
                  }
                  if (this.textualtype1 == 'contains') {
                    textualType1 = ((main.inStock.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) > -1);
                  }
                  if (this.textualtype1 == 'does-not-contain') {
                    textualType1 = ((main.inStock.toLowerCase().indexOf(this.textualserachType1.toLowerCase())) < 0);
                  }
                  if (this.textualtype == 'start-with') {
                    textualType = (main.inStock.toLowerCase().startsWith(this.textualserachType.toLowerCase()));
                  }
                  if (this.textualtype == 'end-with') {
                    textualType = (main.inStock.toLowerCase().endsWith(this.textualserachType.toLowerCase()));
                  }
                  if (this.textualtype1 == 'start-with') {
                    textualType1 = (main.inStock.toLowerCase().startsWith(this.textualserachType1.toLowerCase()));
                  }
                  if (this.textualtype1 == 'end-with') {
                    textualType1 = (main.inStock.toLowerCase().endsWith(this.textualserachType1.toLowerCase()));
                  }
                }
                if (conditionS != '') {
                  return eval((textualType) + conditionS + (textualType1));
                } else if (textualType) {
                  return textualType;
                } else if (textualType1) {
                  return textualType1;
                }
              });
            }
          });
        }
      });
      this.messageToEmit.emit(this.filterData);
    }
  }

  saveFilterData() {
    let typeC = '';
    let typeC1 = '';
    let conditionS = '';
    if (this.serachType == '') {
      this.serachType = 0;
    } else if (this.serachType1 == '') {
      this.serachType1 = 0
    }

    if (this.type == 'null' || this.type == 'not-null' || this.type1 == 'null' || this.type1 == 'not-null') {
      this.serachType = this.serachType1 = 0;
    }

    if (this.type == 'equal' || this.type == 'null') {
      typeC = ' == ';
    } else if (this.type == 'not-equal' || this.type == 'not-null') {
      typeC = ' != ';
    } else if (this.type == 'less-than') {
      typeC = ' < ';
    } else if (this.type == 'greater-than') {
      typeC = ' > ';
    }

    if (this.type1 == 'equal' || this.type1 == 'null') {
      typeC1 = ' == ';
    } else if (this.type1 == 'not-equal' || this.type1 == 'not-null') {
      typeC1 = ' != ';
    } else if (this.type1 == 'less-than') {
      typeC1 = ' < ';
    } else if (this.type1 == 'greater-than') {
      typeC1 = ' > ';
    }

    if (this.conditionData == '1') {
      conditionS = ' && ';
    } else if (this.conditionData == '2') {
      conditionS = ' || ';
    }
    if (this.groupType == 'none') {
      this.profitbreakdowndataSource.rowData = this.filterData.filter((main) => {
        let sValue = '';
        if (this.filterType == 'sales') {
          sValue = main.sales.value;
        } else if (this.filterType == 'ppc') {
          sValue = main.ppc ? main.ppc.value : 0;
        } else if (this.filterType == 'ppcExpenses') {
          sValue = main.ppcExpenses ? main.ppcExpenses.value : 0;
        } else if (this.filterType == 'ppcPerUnit') {
          sValue = main.ppcPerUnit.value;
        } else if (this.filterType == 'promos') {
          sValue = main.promos.value;
        } else if (this.filterType == 'revenue') {
          sValue = main.revenue.value;
        } else if (this.filterType == 'cost') {
          sValue = main.cost.value;
        } else if (this.filterType == 'profit') {
          sValue = main.profit.value;
        } else if (this.filterType == 'margin') {
          sValue = main.margin.value;
        } else if (this.filterType == 'tACoS') {
          sValue = main.tAcoS.value;
        } else if (this.filterType == 'roi') {
          sValue = main.roi.value;
        }
        if (conditionS != '') {
          return eval(sValue + typeC + this.serachType + conditionS + sValue + typeC1 + this.serachType1);
        } else if (typeC != '') {
          return eval(sValue + typeC + this.serachType);
        } else if (typeC1 != '') {
          return eval(sValue + typeC1 + this.serachType1);
        }
      });
      this.messageToEmit.emit(this.profitbreakdowndataSource);
    } else if (this.groupType == 'one') {
      this.filterData.map((res, i) => {
        if (res[0].title) {
          this.oneGrouping[i][0].resD = res[0].resD.filter((main) => {
            let sValue = '';
            if (this.filterType == 'sales') {
              sValue = main.sales.value;
            } else if (this.filterType == 'ppc') {
              sValue = main.ppc.value;
            } else if (this.filterType == 'promos') {
              sValue = main.promos.value;
            } else if (this.filterType == 'revenue') {
              sValue = main.revenue.value;
            } else if (this.filterType == 'cost') {
              sValue = main.cost.value;
            } else if (this.filterType == 'profit') {
              sValue = main.profit.value;
            } else if (this.filterType == 'margin') {
              sValue = main.margin.value;
            } else if (this.filterType == 'tACoS') {
              sValue = main.tAcoS.value;
            } else if (this.filterType == 'roi') {
              sValue = main.roi.value;
            }
            if (conditionS != '') {
              return eval(sValue + typeC + this.serachType + conditionS + sValue + typeC1 + this.serachType1);
            } else if (typeC != '') {
              return eval(sValue + typeC + this.serachType);
            } else if (typeC1 != '') {
              return eval(sValue + typeC1 + this.serachType1);
            }
          });
        }
      });

      this.messageToEmit.emit(this.oneGrouping);
    } else if (this.groupType == 'two') {
      this.filterData.map((res, i) => {
        if (res[0].title) {
          res[0].resD.map((resData, j) => {
            if (resData[0].title) {
              this.oneGrouping[i][0].resD[j][0].resD = resData[0].resD.filter((main) => {
                let sValue = '';
                if (this.filterType == 'sales') {
                  sValue = main.sales.value;
                } else if (this.filterType == 'ppc') {
                  sValue = main.ppc.value;
                } else if (this.filterType == 'promos') {
                  sValue = main.promos.value;
                } else if (this.filterType == 'revenue') {
                  sValue = main.revenue.value;
                } else if (this.filterType == 'cost') {
                  sValue = main.cost.value;
                } else if (this.filterType == 'profit') {
                  sValue = main.profit.value;
                } else if (this.filterType == 'margin') {
                  sValue = main.margin.value;
                } else if (this.filterType == 'tACoS') {
                  sValue = main.tAcoS.value;
                } else if (this.filterType == 'roi') {
                  sValue = main.roi.value;
                } else if (this.filterType == 'stock') {
                  sValue = main.roi.value;
                }
                if (conditionS != '') {
                  return eval(sValue + typeC + this.serachType + conditionS + sValue + typeC1 + this.serachType1);
                } else if (typeC != '') {
                  return eval(sValue + typeC + this.serachType);
                } else if (typeC1 != '') {
                  return eval(sValue + typeC1 + this.serachType1);
                }
              });
            }
          });
        }
      });

      this.messageToEmit.emit(this.oneGrouping);
    }
  }

  clearFilterData() {
    if (this.groupType == 'none') {
      this.profitbreakdowndataSource.rowData = this.filterData;
      this.messageToEmit.emit(this.profitbreakdowndataSource);
    } else if (this.groupType == 'one') {
      this.filterData.map((res, i) => {
        this.oneGrouping[i][0].resD = res[1];
      });
      this.messageToEmit.emit(this.oneGrouping);
    } else if (this.groupType == 'two') {
      this.filterData.map((res, i) => {
        if (res[0].title) {
          res[0].resD.map((resData, j) => {
            if (resData[0].title) {
              this.oneGrouping[i][0].resD[j][0].resD = resData[1];
            }
          });
        }
      });
      this.messageToEmit.emit(this.oneGrouping);
    }

    this.textualconditionData = this.textualtype1 = this.textualtype = this.textualserachType = this.textualserachType1 = this.serachType = this.serachType1 = this.type = this.type1 = this.conditionData = '';
  }
}
