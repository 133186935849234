
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { StorageBrowser } from '../../../services/storage.browser';
import { UpgradeService } from '../../../services/upgrade.service';
import { TextService } from '../../../services/text.service';
import { ConstService } from '../../../services/const-service';
import { OrdersService } from '../../../services/orders.service';
import { ConfirmationDialogComponent } from "../../dialogs/confirmation-dialog/confirmation-dialog.component";
import { MessangerVideoDialogComponent } from './../../dialogs/messenger-video-dialog/messenger-video-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { WhyDialogComponent } from '../../dialogs/why-dialog/why-dialog.component';

@Component({
  selector: 'ninja-grant-access',
  templateUrl: './grant-access.component.html',
  styleUrls: ['./grant-access.component.scss']
})

export class GrantAccessComponet implements OnInit {
  public showProgressBar = true;
  public paymentRedirectStep2 = false;
  public title = 'Preparing…';
  public titleNumber = 1;
  public showError: boolean;
  public currentRegionsStr = ``;
  public isNewRegionPayment: boolean;
  public paymentRedirectError: boolean;
  public redirectPayPal: boolean;
  public changeRegionData: any[] = ['Basic'];
  public grantAccesPage: boolean;
  public paymentRedirectStep1: boolean;
  public currentUploadProgress = 0;
  public redirectCount = 5;
  public fbError: boolean;
  public fbSuccess: boolean;
  public isCustomPayment: boolean;
  public isWentWrongError: boolean;
  public promoteFBbot = false;
  public usaRegion = false;
  public euRegion = false;
  public feRegion = false;
  public configurePermission = false;
  public userLnks: any;

  constructor(public userService: UserService,
    public upgradeService: UpgradeService,
    public ordersService: OrdersService,
    public router: Router,
    public matDialog: MatDialog,
    public storageBrowser: StorageBrowser,
    public textService: TextService,
    public tokenService: TokenService,
    public constService: ConstService,
    public activatedRoute: ActivatedRoute) {
  }

  public ngOnInit(): void {
    if (this.userService.currentUser.id) {
      this.userService.getGrantAccessUrls(this.userService.currentUser.id).subscribe((links) => {
        this.userLnks = links;
      });
    }
    if (this.activatedRoute.routeConfig.path == 'token') {
      this.activatedRoute.queryParams.subscribe(params => {
        this.userService.advertitingAccessGranted(params.code).subscribe(res => {
          this.userService.currentUser.advertisingAccess = true;
        });
      });
      this.router.navigate(['dashboard/dashboard-schedules']);
    } else if (this.activatedRoute.snapshot.queryParams.code) {
      this.ordersService.checkToken(this.userService.currentUser.id, this.activatedRoute.snapshot.queryParams.code)
        .subscribe(tokenInfo => {
          if (!tokenInfo.isSuccess) {
            this.fbError = true;
            setTimeout(() => {
              this.router.navigate(['dashboard/orders/download-report']);
            }, 5000);
            return;
          }
          if (tokenInfo.isSuccess) {
            this.fbSuccess = true;
            setTimeout(() => {
              this.router.navigate(['dashboard/orders/download-report'], { queryParams: { fbAccess: true } });
            }, 5000);
            return;
          }
        });
    } else if (this.activatedRoute.snapshot.queryParams.promoteFBbot == 'promoteFBbot') {
      this.grantAccesPage = true;
      this.promoteFBbot = true;
      this.configurePermission = false;
    }
    if ((this.userService.currentUser.fbids === undefined) || (this.userService.currentUser.fbids.length == 0))
      this.configurePermission = true;
    if (this.router.url.includes('payment_canceled')) {
      this.router.navigate(['dashboard/upgrade']);
    }
    if (this.storageBrowser.get('isAccountChange')) {
      this.userService.currentUser = this.tokenService.getToken();
      this.storageBrowser.remove('isAccountChange');
      this.upgradeService.changePaymentMethod(
        this.userService.currentUser.id,
        this.activatedRoute.snapshot.queryParams.token).subscribe(data => {
          if (data.isSuccess) {
            this.successPaymentLoader();
          } else {
            this.grantAccesPage = true;
            this.showProgressBar = false;
            this.showError = true;
            this.isWentWrongError = true;
            this.enableFakeLoader();
          }
        }, error => {
          this.grantAccesPage = true;
          this.showProgressBar = false;
          this.showError = true;
          this.enableFakeLoader();
        });
      return;
    }
    if (this.storageBrowser.get('isCustomPayment')) {
      this.userService.currentUser = this.tokenService.getToken();
      this.storageBrowser.remove('isCustomPayment');
      this.upgradeService.updateCustomPayment(
        this.userService.currentUser.id,
        this.activatedRoute.snapshot.queryParams.token).subscribe(data => {
          if (data.isSuccess) {
            this.successPaymentLoader();
          } else {
            this.enableFakeLoader();
            this.grantAccesPage = true;
            this.showProgressBar = false;
            this.showError = true;
          }
        }, error => {
          this.enableFakeLoader();
          this.grantAccesPage = true;
          this.showProgressBar = false;
          this.showError = true;
        });
      return;
    }
    if (this.activatedRoute.snapshot.queryParams.isNewRegion === 'true') {
      this.userService.currentUser = this.tokenService.getToken();
      this.upgradeService.getPlansInfo(+this.activatedRoute.snapshot.queryParams.id).subscribe((data) => {
        this.changeRegionData = data.isSuccess;
        this.buildCurrentRegions(data.isSuccess);
      });
      this.isNewRegionPayment = true;
      return;
    }
    if (this.activatedRoute.snapshot.queryParams.token) {
      this.userService.currentUser = this.tokenService.getToken();
      this.paymentRedirectStep1 = true;
      this.enableLoader();
      return;
    }
    if (this.activatedRoute.snapshot.queryParams.paymentRedirect === 'true') {
      this.redirectPayPal = true;
      this.paymentProcess();
      return;
    }
    if (this.activatedRoute.snapshot.queryParams.status) {
      this.grantAccesPage = true;
      this.userService.switchRegion(this.activatedRoute.snapshot.queryParams.id).subscribe((user) => {
        this.userService.currentUser = user;
        this.tokenService.setToken(user);
      });
      this.showError = this.activatedRoute.snapshot.queryParams.status !== 'true';
      this.enableFakeLoader();
      return;
    }

    if (this.userService.currentUser.id && Object.keys(this.activatedRoute.snapshot.queryParams).length !== 0 &&
      (this.activatedRoute.snapshot.queryParams.MWSAuthToken && this.activatedRoute.snapshot.queryParams.MWSAuthToken !== 'null') &&
      this.activatedRoute.snapshot.queryParams.Marketplace !== 'null' &&
      this.activatedRoute.snapshot.queryParams.Merchant !== 'null' &&
      this.activatedRoute.snapshot.queryParams.webApp_irpregistration !== 'null' &&
      this.activatedRoute.snapshot.queryParams.Signature !== 'null') {
      const dataToVerify = {
        MWSAuthToken: this.activatedRoute.snapshot.queryParams.MWSAuthToken,
        Marketplace: this.activatedRoute.snapshot.queryParams.Marketplace,
        Merchant: this.activatedRoute.snapshot.queryParams.Merchant,
        webApp_irpregistration: this.activatedRoute.snapshot.queryParams.webApp_irpregistration,
        Signature: this.activatedRoute.snapshot.queryParams.Signature
      };
      this.userService.verifyUser(dataToVerify).subscribe((data) => {
        if (data.isSuccess) {
          this.grantAccesPage = true;
          this.userService.switchRegion(data.publicId).subscribe((user) => {
            this.storageBrowser.set('$UserToken$tokenId', user.id);
            this.userService.currentUser = user;
            this.tokenService.setToken(user);
            for (let i = 0; i < user.emails.length; i++) {
              if (user.emails[i].submarket === 'IT' || user.emails[i].submarket === 'UK' || user.emails[i].submarket === 'ES' || user.emails[i].submarket === 'FR' || user.emails[i].submarket === 'DE' || user.emails[i].submarket === 'EU') {
                this.euRegion = true;
              }
              if (user.emails[i].submarket === 'CA' || user.emails[i].submarket === 'MX' || user.emails[i].submarket === 'US') {
                this.usaRegion = true;
              }
              if (user.emails[i].submarket === 'JP' || user.emails[i].submarket === 'AU' || user.emails[i].submarket === 'SG') {
                this.feRegion = true;
              }
            }
          });
            this.grantAccesPage = true;
            this.showProgressBar = false;
            this.showError = true;
          this.enableFakeLoader();
        } else {
          this.grantAccesPage = true;
          this.showProgressBar = false;
          this.showError = true;
        }
      });
    }
  }

  showPromoteFBbot() {
    this.promoteFBbot = true;
    this.configurePermission = false;
  }

  public buildCurrentRegions(data: any): void {
    for (let i = 1; data.length > i; i++) {
      if (data.length - 1 === i) {
        this.currentRegionsStr += data[i].RegionName;
      } else {
        this.currentRegionsStr += data[i].RegionName + ', ';
      }
    }
  }

  public navigateToDashboard(): void {
    this.userService.currentUser = this.tokenService.getToken().user;
    this.router.navigate(['dashboard/orders-dashboard']);
    this.showAmazonConnectProgressDialog();
  }

  public showAmazonConnectProgressDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'amazon-connect',
      },
    }).afterClosed().subscribe();
  }

  public navigateToSignUp(): void {
    this.router.navigate(['registration']);
  }

  public openFBWindow(): void {
    var fbLink = "https://www.facebook.com/messages/t/248153372223046?ref=";

    if (this.userService.currentUser.fbtoken) {
      const user = this.userService.currentUser.emails.find(data => (data.submarket === this.userService.currentUser.marketplace))
      var fbRef = '{notifCode:"userid=' + user.subid + '&tokenType=single_sale' + '&token=' + this.userService.currentUser.fbtoken + '"}';
    }
    else {
      const user = this.userService.currentUser.user.emails.find(data => (data.submarket === this.userService.currentUser.user.marketplace))
      var fbRef = '{notifCode:"userid=' + user.subid + '&tokenType=single_sale' + '&token=' + this.userService.currentUser.user.fbtoken + '"}';
    }
    fbRef = btoa(fbRef);
    fbLink += fbRef;
    window.open(fbLink);
    this.navigateToDashboard();

  }

  public openTGWindow(): void {
    var tgLink = "https://t.me/quantifyninjabot?start=";
    if (this.userService.currentUser.fbtoken) {
      const user = this.userService.currentUser.emails.find(data => (data.submarket === this.userService.currentUser.marketplace));
      var tgRef = `${user.subid}_${this.userService.currentUser.fbtoken}_single_sale`;
    }
    else {
      const user = this.userService.currentUser.user.emails.find(data => (data.submarket === this.userService.currentUser.user.marketplace));
      var tgRef = `${user.subid}_${this.userService.currentUser.user.fbtoken}_single_sale`;
    }
    tgLink += tgRef;
    window.open(tgLink);
    this.navigateToDashboard();
  }

  public enableLoader(): void {
    this.userService.loaderObesrver.next(true);
    this.userService.agreementToCancel = this.storageBrowser.get('agreementToCancel') ? this.storageBrowser.get('agreementToCancel') : 'undefined';
    this.upgradeService.checkPayment(this.storageBrowser.get('$UserToken$id'), this.activatedRoute.snapshot.queryParams.token, this.userService.agreementToCancel).subscribe((data) => {
      this.userService.switchRegion(data.userId).subscribe((user) => {
        this.userService.currentUser = user;
        this.constService.setRegions(user);
        this.tokenService.setToken(user);
      });
      this.userService.loaderObesrver.next(false);
      this.userService.amazonTokenStatus().subscribe((res) => {
        if (!res.isSuccess) {
          this.grantAcc();
          return;
        }
      })
      clearInterval(interval);
      setTimeout(() => {
        this.showProgressBar = false;
        if (this.activatedRoute.snapshot.queryParams.token) {
          this.paymentRedirectStep1 = false;
          if (data.isSuccess) {
            this.userService.currentUser.permissions = data.permissions;
            this.successPaymentLoader();
          } else {
            this.paymentRedirectError = true;
          }
        }
      }, 1500);
      this.currentUploadProgress = 100;
    });
    const interval = setInterval(() => {
      if (this.currentUploadProgress === 100) {
        clearInterval(interval);
      }
      this.currentUploadProgress++;
    }, 100);

  }

  public enableFakeLoader(): void {
    this.showProgressBar = true;
    const interval = setInterval(() => {
      this.currentUploadProgress++;
      if (this.currentUploadProgress === 100) {
        clearInterval(interval);
        setTimeout(() => {
          this.showProgressBar = false;
        }, 1500);
      }
    }, 33);
  }

  public successPaymentLoader(): void {
    sessionStorage['PayedInThisSession'] = true;
    this.paymentRedirectStep2 = true;
    const redirectInterval = setInterval(() => {
      this.redirectCount--;
      if (this.redirectCount === 0) {
        clearInterval(redirectInterval);
        this.navigateToDashboard();
      }
    }, 1000);
  }

  public navigateToDownload(): void {
    setTimeout(() => {
      this.router.navigate(['dashboard/orders/download-report']);
    }, 5000);
  }

  public paymentProcess(): void {
    if (this.activatedRoute.snapshot.queryParams.isNewRegion === 'true') {
      this.upgradeService.updatePlan(this.storageBrowser.get('$UserToken$tokenId'), this.activatedRoute.snapshot.queryParams.plan, true
      ).subscribe((link) => {
        if (link.link) {
          this.userService.linkToRedirect = link.link;
        }
        window.location.href = this.userService.linkToRedirect;
      });
    } else {
      this.upgradeService.updatePlan(this.userService.currentUser.id,
        this.activatedRoute.snapshot.queryParams.plan,
        undefined,
        this.activatedRoute.snapshot.queryParams.name,
        this.activatedRoute.snapshot.queryParams.number).subscribe((link) => {
          if (link.link) {
            this.userService.linkToRedirect = link.link;
          }
          this.storageBrowser.set('agreementToCancel', link.agreementToCancel);
          window.location.href = this.userService.linkToRedirect;
        });
    }
    const interval = setInterval(() => {
      this.currentUploadProgress++;
      if (this.currentUploadProgress === 33) {
        this.title = this.textService.currentText.grantAccessTitle;
        this.titleNumber = 2;
      }
      if (this.currentUploadProgress === 66) {
        this.title = this.textService.currentText.grantAccessTitle2;
        this.titleNumber = 3;
      }
      if (this.currentUploadProgress === 100) {
        clearInterval(interval);
        setTimeout(() => {
          this.showProgressBar = false;
        }, 1500);
      }
    }, 66);
  }

  public showWhyDialog() {
    console.log("Dialog Open")
    this.matDialog.open(WhyDialogComponent).afterClosed().subscribe(() => { });
  }

  public grantAcc(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'add-region',
        'grantAccess': true
      },
    }).afterClosed().subscribe(() => {
      this.router.navigate(['dashboard/upgrade'])
    });
  }

  public showMessangerVideo(): void {
    this.matDialog.open(MessangerVideoDialogComponent, {
      panelClass: 'novscroll-dialog-container',
      height: '424px',
      width: '755px',
      data: {},
    }).afterClosed().subscribe();
  }

  public navigateToAmazon(link): void {
    window.location.href = link;
  }

}
