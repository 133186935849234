import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Functions} from '../../../services/functions';
import {UserService} from '../../../services/user.service';
import {TextService} from '../../../services/text.service';
import {FbService} from '../../../services/fb.service';

@Component({
  templateUrl: './select-post-dialog.component.html',
  styleUrls: ['./select-post-dialog.component.scss'],
})
export class SelectPostDialogComponent implements OnInit {
  public posts: any;
  public canSave = true;

  constructor(public ref: ChangeDetectorRef,
              public userService: UserService,
              public fbService: FbService,
              public textService: TextService,
              public dialogRef: MatDialogRef<SelectPostDialogComponent>,
              public functions: Functions, public el: ElementRef,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit(): void {
    this.userService.dialogLoader = true;
    this.fbService.getPosts(this.userService.currentUser.id, this.data.data).subscribe((data) => {
      this.posts = data;
      this.userService.dialogLoader = false;
      this.ref.detectChanges();
    });
  }

  public disableChecker(): void {
    for (let i = 0; this.posts.length > i; i++) {
      if (this.posts[i].checked) {
        this.posts[i].checked = false;
        this.ref.detectChanges();

      }
    }
  }

  public emitPost(): void {
    for (let i = 0; this.posts.length > i; i++) {
      if (this.posts[i].checked) {
        this.dialogRef.close(this.posts[i]);
        this.ref.detectChanges();
      }
    }
  }
}
