
<div class="dashboard dashboard-schedules profit-page" [class.wide]="userService.smallSidebar">
    <!-- userService.smallSidebar -->
    <div class="dashboard-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="head-container mb-0">
                        <h2 class="mb-0 bold">Orders</h2>
                    </div>
                </div>
                <div class="col-12 removeFilter mt-2 head-container justify-content-start" *ngIf="orderContext">
                    <button *ngIf="orderContext.brands && orderContext.brands.length > 0" type="button" mat-button class="default-btn my-2 mr-3 filterBtn"
                        (click)="mixpanelService.track('q filter - clicked',{'filter type':
                        'brand', 'page':'orders-dashboard'});resetFilter('brand')">
                        Brand Filter
                        <mat-icon>close</mat-icon>
                    </button>
                    <button *ngIf="orderContext.sc && orderContext.sc.length > 0" type="button" mat-button class="default-btn my-2 mr-3 filterBtn"
                        (click)="mixpanelService.track('q filter - clicked',{'filter type':
                        'marketplace','page':'orders-dashboard'});resetFilter('marketplace')">
                        Marketplace Filter
                        <mat-icon>close</mat-icon>
                    </button>
                    <button *ngIf="orderContext.skus && orderContext.skus.length > 0" type="button" mat-button class="default-btn my-2 mr-3 filterBtn"
                        (click)="mixpanelService.track('q filter - clicked',{'filter type':
                        'asin', 'page':'orders-dashboard'});resetFilter('asin')">
                        Asin Filter
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="col-12 dashboard-schedules-filter">
                    <ninja-custom-filter-menu #componentChild (EmitformDataDashBoard)="passBall($event)" [dashboardSchedulesFilter]="dashboardSchedulesFilterFlag"></ninja-custom-filter-menu>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-container shadow-none">
        <div class="row">
            <div class="col-md-4" *ngIf="orderData && orderData.requestedPie">
                <div class="head-container mb-0">
                    <h3 class="mb-0 text-center bold1">{{currentTitle}}</h3>
                    <div class="font-size-14 text-center pt-2" *ngIf="currentTitle == 'Current'">{{orderData.requestedPie.from | date: 'dd/MM/yy'}} -
                        {{orderData.requestedPie.to | date:'dd/MM/yy'}}</div>
                    <app-doughnutchart [pieChart]="orderData.requestedPie" [pieChartName]="'currentorders'" [formData]="orderContext">
                    </app-doughnutchart>
                </div>

            </div>
            <div class="col-md-4" *ngIf="orderData && orderData.previousePie">
                <div class="head-container mb-0">
                    <h3 class="mb-0 text-center bold1">{{previousTitle}}</h3>
                    <div class="font-size-14 text-center pt-2" *ngIf="previousTitle == 'Previous'">{{orderData.previousePie.from | date: 'dd/MM/yy'}} -
                        {{orderData.previousePie.to | date: 'dd/MM/yy'}}</div>
                    <app-doughnutchart [pieChart]="orderData.previousePie" [pieChartName]="'previousorders'" [formData]="orderContext">
                    </app-doughnutchart>
                </div>
            </div>
            <div class="col-md-4" *ngIf="orderData && orderData.revenuePie && topValues">
                <div class="head-container mb-0">
                    <h3 class="mb-0 text-center bold1">Revenue</h3>
                    <div class="font-size-14 text-center pt-2">{{orderData.revenuePie.from | date: 'dd/MM/yy'}} - {{orderData.revenuePie.to | date: 'dd/MM/yy'}}</div>
                    <app-orders-barchart [topValues]="topValues"></app-orders-barchart>
                </div>
            </div>
        </div>
        <div style="padding-top: 50px;">
            <mat-card>
                <mat-card-title class="d-flex justify-content-between">
                    <div class="d-flex">
                        <h4 class="mb-0 bold1">Orders</h4>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <div class="container-fluid">
                        <div class="profitTable">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Marketplace</th>
                                        <th>Product</th>
                                        <th>Order Id</th>
                                        <th>Date</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="orderData && orderData.orders; else noOrderData">
                                    <tr *ngFor="let main of orderData.orders; let mainIndex = index">
                                        <td>{{mainIndex+1}}</td>
                                        <td><img src="{{main.img}}" width="40" height="40" /></td>
                                        <td>
                                            <span class="cursor-pointer" (click)="mixpanelService.track('q edit product popup - opened',{'source':
                        'orders popup', 'page':'orders-dashboard'});editShortName(main)">
                                                <mat-icon class="mat-edit-icon">edit</mat-icon>
                                                {{main.shortName}}
                                            </span>
                                        </td>
                                        <td><a href="{{main.link}}" target="_blank">{{main.ordrId}}</a></td>
                                        <td>{{main.purchasedDate}}</td>
                                        <td>{{main.qty}}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <ng-template #noOrderData>
                                        <tr>
                                            <td colspan="6">No record found.</td>
                                        </tr>
                                    </ng-template>
                                </tbody>
                            </table>
                        </div>
                        <div class="mat-paginator-custom text-center" *ngIf="orderData && orderData.orders">
                            <p>Items per page: 50</p>
                            <button (click)="searchOrders(this.prevToken)" [disabled]="this.nextToken === 50 || this.nextToken === 0">
                                <svg class="mat-paginator-icon " focusable="false" width="24px" height="24" viewBox="0 0 24 24">
                                    <path class="prev" fill="#757575" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                </svg>
                            </button>
                            <button (click)="searchOrders(this.nextToken)" [disabled]="this.nextToken === 0 || isNextDisabled">
                                <svg class="mat-paginator-icon" focusable="false" width="24px" height="24" viewBox="0 0 24 24">
                                    <path class="next" fill="#757575" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>