<div class="constructor-option"
     [class.last]="lastBlock"
     [class.first]="firstBlock">
  <button (click)="showMenu = !showMenu; closeMenuFunc(); init()"
          class="plus-btn"
          [class.with-action]="isAction || isWebUrl">

  </button>
  <div class="menu menu-close-checker" *ngIf="showMenu">
    <div class="close-btn menu-close-checker" (click)="closeMenu()"></div>
    <div class="menu-header menu-close-checker">
      <h5 class="menu-close-checker">
        {{showUrlMenu ? textService.currentText.botMenuEditStep : textService.currentText.urlButton}}</h5>
    </div>
    <div class="menu-body menu-close-checker">
      <button class="default-menu menu-close-checker"
              *ngIf="!headerEdit && showUrlMenu"
              (click)="isWebUrl ? warningDialog(textService.currentText.alreadyHaveUrlError
                , textService.currentText.alreadyHaveUrlErrorText1,
                 textService.currentText.alreadyHaveUrlErrorText2) : showOption = !showOption">
        <img src="assets/icons/bot-menu-sublevel.svg">
        + {{textService.currentText.action}}
      </button>
      <ng-container *ngIf="!headerEdit && showUrlMenu">
        <button class="default-menu menu-close-checker"
                *ngIf="!lastLevel"
                [class.disableDelete]="canAddSubMenu"
                (click)="addSub2Level.emit(true); showMenu = !showMenu">
          <img src="assets/icons/bot-menu-sublevel.svg">
          + {{textService.currentText.botMenuAddSubLvl}}
        </button>
      </ng-container>
      <!--<ng-container *ngIf="!headerEdit && showUrlMenu">-->
        <!--<button class="default-menu menu-close-checker"-->
                <!--*ngIf="!lastLevel"-->
                <!--(click)="showUrlMenu = !showUrlMenu"-->
                <!--[class.disableDelete]="canAddSubMenu">-->
          <!--<img src="assets/icons/url.svg">-->
          <!--+ {{textService.currentText.url}}-->
        <!--</button>-->
      <!--</ng-container>-->
      <!--<input class="default-menu menu-close-checker"-->
             <!--[(ngModel)]="webUrl"-->
             <!--[placeholder]="textService.currentText.urlButton"-->
             <!--*ngIf="!showUrlMenu">-->
      <ng-container *ngIf="!headerEdit && showUrlMenu">
        <button class="default-menu menu-close-checker"
                *ngIf="!lastLevel"
                (click)="isAction || showOption ? warningDialog(textService.currentText.alreadyHaveActionError
                , textService.currentText.alreadyHaveActionErrorText1,
                 textService.currentText.alreadyHaveActionErrorText2, true) : showUrlMenu = !showUrlMenu"
                [class.disableDelete]="canAddSubMenu">
          <img src="assets/icons/url.svg">
          + {{textService.currentText.url}}
        </button>
      </ng-container>
      <input class="default-menu menu-close-checker"
             [(ngModel)]="webUrl"
             [placeholder]="textService.currentText.url"
             *ngIf="!showUrlMenu">
      <button class="default-menu menu-close-checker"
              *ngIf="showUrlMenu"
              [class.disableDelete]="!canAddMenu"
              (click)="addMenuToThisLevel.emit({name: ''}); showMenu = !showMenu">
        <img src="assets/icons/bot-menu-sublevel.svg">
        + {{textService.currentText.botMenuAddNewStep}}
      </button>
      <div class="menu-options custom-selects menu-close-checker"
           *ngIf="(showOption || isAction) && isAction?.web_url !== 'web_url'">
        <div class="sub-head menu-close-checker">
          <h5 class="menu-close-checker">Added actions</h5>
          <button class="delete-btn menu-close-checker"
                  (click)="showOption = !showOption; isAction = undefined; setAction.emit(undefined);
 selectedAction = undefined; selectedConversation = undefined; showOption = false">
            <img src="assets/icons/trash.svg" alt="">
            {{textService.currentText.delete}}
          </button>
        </div>
        <div (click)="functions.setDropDownPosition(chooseAction, 'actions')" #chooseAction
             [class.active]="actions.panelOpen"
             class="select-wrapper menu-close-checker">
          <mat-form-field class="menu-close-checker">
            <mat-select [placeholder]="textService.currentText.botMenuChooseAction"
                        class="menu-close-checker"
                        shouldPlaceholderFloat="false"
                        panelClass="default-dropdown actions"
                        disableRipple
                        [(ngModel)]="selectedAction"
                        (selectionChange)="setAction.emit(selectedAction)"
                        #actions
                        name="actions">
              <mat-option *ngFor="let select of constService.menuActions" [value]="select.value"
                          class="menu-close-checker">
                {{ select.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="selectedAction && selectedAction === 'Trigger Conversation'"
             (click)="functions.setDropDownPosition(timeSelect, 'time')" #timeSelect
             [class.active]="time.panelOpen"
             class="select-wrapper menu-close-checker">
          <mat-form-field class="menu-close-checker">
            <mat-select placeholder="Select Conversation" shouldPlaceholderFloat="false"
                        panelClass="default-dropdown time"
                        #time
                        class="menu-close-checker"
                        [(ngModel)]="selectedConversation"
                        (selectionChange)="setActionId.emit(selectedConversation)"
                        disableRipple
                        name="time">
              <mat-option *ngFor="let select of conversationList" [value]="select.conversationId"
                          class="menu-close-checker">
                {{ select.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="menu-footer menu-close-checker" *ngIf="showUrlMenu">
        <button class="default-menu menu-close-checker"
                *ngIf="!headerEdit"
                (click)="deleteMenu.emit(true); showMenu = !showMenu"
                [class.disableDelete]="isDelete">
          {{textService.currentText.botMenuDeleteStep}}
        </button>
        <button class="default-menu menu-close-checker"
                *ngIf="headerEdit && !firstMenu"
                (click)="deleteSection.emit(true); showMenu = !showMenu">
          {{textService.currentText.botMenuDeleteLevel}}
        </button>
        <button class="primary menu-close-checker" (click)="closeMenu()">
          {{textService.currentText.done}}
        </button>
      </div>
      <div class="menu-footer menu-close-checker" *ngIf="!showUrlMenu">
        <button class="default-menu menu-close-checker"
                (click)="showUrlMenu = !showUrlMenu">
          {{textService.currentText.cancel}}
        </button>
        <button class="default-menu menu-close-checker"
                (click)="showUrlMenu = !showUrlMenu; this.setWebUrl.emit({web_url: webUrl, action: undefined})">
          {{textService.currentText.save}}
        </button>
      </div>
    </div>
  </div>
  <input type="text"
         [disabled]="headerEdit"
         [class.header-menu]="headerEdit"
         [(ngModel)]="name"
         (keyup)="menuName.emit(name)"
         [placeholder]="textService.currentText.botMenuTypeTitle">
  <span *ngIf="!headerEdit">></span>
</div>
