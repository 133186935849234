<div class="outer_wrapper row">
  <div class="col-md-12 text-center">
    <h2 class="main_title">Easily unsubscribe from Ninja's notifications</h2>
    <h2 class="sub_title">We didn’t mean to flood your inbox.</h2>
  </div>
  <br>
  <div class="clearfix"></div>
  <div class="col-md-12 text-center">
    <img src="assets/images/robot-3256109_1280.png" alt="" srcset="" class="img_title">
  </div>
  <div class="col-md-12 ">
    <p>
        Here you can see all of your email and Facebook notification subscriptions and unsubscribe from them with one click.
    </p>
  </div>
  <br>
  <div class="clearfix"></div>
  <div class="col-md-12 ">
    <b>Your current preferences</b>
    <br>
    <br>
    <p>Any changes you made here will take effect in your Ninja account. And don't worry you can change your preferences at any time</p>
  </div>
  <br>
  <div class="clearfix"></div>
  <div class="col-md-12 ">
    <table class="table">
      <thead>
        <tr>
          <th width="80%"></th>
          <th>YES</th>
          <th>NO</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="blue-text">
            Facebook Sales Notification
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Facebook_Sales_Notification" id="Facebook_Sales_Notification1" value="true" [(ngModel)]="repJson.fbSales" [checked]="repJson.fbSales" >
              </div>
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Facebook_Sales_Notification" id="Facebook_Sales_Notification2" value="false" [(ngModel)]="repJson.fbSales" [checked]="repJson.fbSales" >
              </div>
          </td>
        </tr>
        <tr>
          <td class="blue-text">
              Facebook Competitors Alerts 
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Facebook_Competitors_Alerts" id="Facebook_Competitors_Alerts1" value=true [(ngModel)]="repJson.fbComp" checked="repJson.fbComp">
              </div>
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Facebook_Competitors_Alerts" id="Facebook_Competitors_Alerts2" value=false [(ngModel)]="repJson.fbComp" checked="repJson.fbComp">
              </div>
          </td>
        </tr>
        <tr>
          <td class="blue-text">
              Email notifications on negative feedback
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Email_notifications_on_negative_feedback" id="Email_notifications_on_negative_feedback1" value="true" [(ngModel)]="repJson.emailNegativeFeedback" checked="repJson.emailNegativeFeedback" >
              </div>
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Email_notifications_on_negative_feedback" id="Email_notifications_on_negative_feedback2" value="false" [(ngModel)]="repJson.emailNegativeFeedback" checked="repJson.emailNegativeFeedback">
              </div>
          </td>
        </tr>
        <tr>
          <td class="blue-text">
              Email daily updates on how many emails my customers received
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Email_daily_updates_on_how_many_emails_my_customers_received" id="Email_daily_updates_on_how_many_emails_my_customers_received1" value="true" [(ngModel)]="repJson.dailyEmailCounter" checked="repJson.dailyEmailCounter" >
              </div>
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Email_daily_updates_on_how_many_emails_my_customers_received" id="Email_daily_updates_on_how_many_emails_my_customers_received2" value="false" [(ngModel)]="repJson.dailyEmailCounter" checked="repJson.dailyEmailCounter">
              </div>
          </td>
        </tr>
        <tr>
          <td class="blue-text">
              Email notifications on hijacked
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Email_notifications_on_hijacked" id="Email_notifications_on_hijacked2" value="true"  [(ngModel)]="repJson.emailHijack" checked="repJson.emailHijack">
              </div>
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Email_notifications_on_hijacked" id="Email_notifications_on_hijacked1" value="false" [(ngModel)]="repJson.emailHijack" checked="repJson.emailHijack">
              </div>
          </td>
        </tr>
        <tr>
          <td class="blue-text">
              Email Competitors Alerts
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Email_Competitors_Alerts" id="Email_Competitors_Alerts1" value="true"  [(ngModel)]="repJson.emailComp" checked="repJson.emailComp">
              </div>
          </td>
          <td>
              <div class="text-center">
                <input class="form-check-input" type="radio" name="Email_Competitors_Alerts" id="Email_Competitors_Alerts2" value="false" [(ngModel)]="repJson.emailComp" checked="repJson.emailComp">
              </div>
          </td>
        </tr>
      </tbody>

    </table>

    <div class="col-md-12 text-center">
      <button class="btn btn-blue" (click)="onUpdate();">Update my preferences</button>
    </div>

  </div>

  
</div>