import { Component, OnInit, Input } from '@angular/core';
import { EChartOption } from 'echarts';
import { TextService } from 'src/app/services/text.service';
import { graphic } from 'echarts';

@Component({
  selector: 'app-product-barchart',
  templateUrl: './product-barchart.component.html'
})
export class ProductBarchartComponent implements OnInit {
  @Input() productBarChartData: any;
  barMaxWidth = 30;
  allSelected = false;
  selected: any;
  echartProductBarOptions: EChartOption = {
    color: ['#243853'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    calculable: true,
    grid: {
      top: '10px',
      left: '30px',
      right: '10px',
    },
    xAxis: [
      {
        type: 'category',
        data: [],
        splitLine: {
          show: true
        },
        axisLine: {
          lineStyle: {
            color: "rgba(36, 56, 83, 0.5)"
          },
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: "rgba(36, 56, 83, 0.5)"
          },
        },
        axisLabel: {
          formatter: function (value) {
            return value >= 1000 ? value / 1000 + 'k' : value;
          },
          textStyle: {
            color: function (value, index) {
              return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
            }
          }
        },
      },
    ],
    dataZoom: [
      {
          show: true,
          startValue: 0,
          endValue: 8
      },
      {
          type: 'inside',
          startValue: 0,
          endValue: 8
      },
  ],
    //   toolbox: {
    //     feature: {
    //         //dataView: {show: true, readOnly: false, title:"Data View"},
    //         magicType: {show: true, type: ['line', 'bar'], title:{"line":"Line","bar":"Bar"}},
    //         restore: {show: true, title:"Restore"},
    //         saveAsImage: {show: true, title:"Download"}
    //     }
    // },
    series: [
      {
        type: 'bar',
        data: [],
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: new graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#243853' },
              { offset: 1, color: 'rgba(36, 56, 83, 0.25)' }
            ]
          ),
          barBorderRadius: [4, 4, 1, 1]
        },
        barMaxWidth: this.barMaxWidth
      }
    ]
  };
  constructor(public textService: TextService) { }

  ngOnInit() {
    if (this.productBarChartData) {
      this.echartProductBarOptions.xAxis[0].data = this.productBarChartData.scopeValues.map((item) => {return item.value});
      // this.echartProductBarOptions.xAxis[0].data = this.productBarChartData.scopeValues.map((item) => {return '<img src="' + item.image + '" width="30" style="height:30px">'});
      // this.echartProductBarOptions.xAxis[0].data = this.productBarChartData.scopeValues.map((item) => {return item.image});
      // this.echartProductBarOptions.xAxis[0].axisLabel = {
      //   formatter: function (img) { 
      //     console.log("value, index", '<img src="' + img + '" width="30" style="height:30px">');
      //     return '<img src="' + img + '" width="30" style="height:30px">'
      //   }
      // }
      this.echartProductBarOptions.series[0].data = this.productBarChartData.scopeValues.map((item) => {
        let namePie;
        if (item.scopeName.length > 20) {
          namePie = item.scopeName.substring(0, 20) + '...';
        } else {
          namePie = item.scopeName;
        }
        return { 'value': item.value, 'name': namePie + ' : ' + item.value, 'img': item.image };
      });
      this.echartProductBarOptions.tooltip = {
        //position: [10, 10],
        extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
        textStyle: { color: '#4d4f5c', fontSize: 9 },
        backgroundColor: '#fff',
        formatter: function (params) {
          return '<div class="d-flex p-2"><h4 class="mb-0" style="font-size:16px"> ' + params.data.name + '</h4></div>';
        },
      }
    }
  }
};