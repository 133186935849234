
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentDialogComponent } from '../dialogs/payment-dialog/payment-dialog.component';
import { PricingService } from './../../services/pricing.service';
import { UserService } from './../../services/user.service';

interface Country {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-upgrade-new',
  templateUrl: './upgrade-new.component.html',
  styleUrls: ['./upgrade-new.component.scss']
})
export class UpgradeNewComponent implements OnInit {
  public isShow: Boolean;
  public selectedValue: string;
  public addonsData = [];
  public currPlan = { basePricing: 0,addOns:[]};
  public monthPrice=0;
  public paymentSum=0;
  public paymentPeriod = "annual";

  countries: Country[] = [
    { value: "India", viewValue: "India" },
    { value: "US", viewValue: "U.S.A" },
    { value: "Canada", viewValue: "Canada" },
  ];

  states: Country[] = [
    { value: "Texas", viewValue: "Texas" },
    { value: "California", viewValue: "California" },
    { value: "New York", viewValue: "New York" },
  ];

  constructor(public dialog: MatDialog,
              public userService: UserService, 
              public pricingService: PricingService)
   { }

  ngOnInit(): void {
    this.isShow = false;
    this.pricingService.getValuePricingInfo(this.userService.currentUser.id).subscribe((data)=>
    {
        this.currPlan = data.currPlan;
        if (data.currPlan.addOns==null)
          data.currPlan.addOns=[];
          
        this.addonsData = data.offers.reduce((result, addon) => {
          result[addon.addon] = addon;
          
          return result;
        }, {});
        this.calcPrice();
    });
    
  }
  public openPurchasePayment() {
    this.isShow = true;
  }

  public openDialog() {
    this.dialog.open(PaymentDialogComponent);
  }

  public onAddonClick(addon)
  {
    if (this.currPlan.addOns.indexOf(addon)>=0)
    {
      const index = this.currPlan.addOns.indexOf(addon);
      if (index > -1) {
        this.currPlan.addOns.splice(index, 1);
      }
    }else
    {
      this.currPlan.addOns.push(addon);
    }
    this.calcPrice();
  }

  public onPeriodChange()
  {
    this.calcPrice();
  }

  public calcPrice()
  {
    this.monthPrice=this.currPlan.basePricing;
    for (const addon of this.currPlan.addOns) 
    {
      this.monthPrice+=this.addonsData[addon].pricing;
    }
    if (this.paymentPeriod=="annual")
    {
      this.paymentSum=(this.monthPrice*12)*0.7;
    }else
      this.paymentSum=this.monthPrice;
  }

  public onSummeryClick()
  {
    this.pricingService.getValuePricingPlan(this.userService.currentUser.id,this.currPlan.addOns,(this.paymentPeriod=="annual")).subscribe((data)=>
    {
      window.location = data.link;
    });
    this.openDialog();
  }
}
