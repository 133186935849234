<div class="main-dialog-container main-dialog-container--bulk default-popup">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container inner-dialog-container--bulk custom-selects"
       [formGroup]="sendForm" name="form">
    <div class="header header--bulk">
      <h2>{{textService.currentText.sendManual}}</h2>
    </div>
    <div class="dialog-body">
      <div class="dialog-row" [class.has-danger]="errors?.orderNumber">
        <div class="label-name">
          <span>{{textService.currentText.orderNumb}}</span>
        </div>
        <input matInput
               [placeholder]="textService.currentText.addOrderNumber"
               type="text"
               (focusout)="resetForm()"
               formControlName="orderNumber"
               #orderNumber>
        <button class="primary" (click)="mixpanelService.track('q invoicing manual search clicked');isOrderValid()">
          {{textService.currentText.search}}
        </button>
      </div>
      <div class="dialog-row flex space-between" >
        <div class="col">
          <div class="label-name">
            <span>{{textService.currentText.buyerName}}</span>
          </div>
          <input matInput
                 type="text"
                 formControlName="buyerName"
                 #buyerName>
        </div>
        <div class="col">
          <div class="label-name">
            <span>{{textService.currentText.salesChannel}}</span>
          </div>
          <input matInput
                 type="text"
                 formControlName="salesChannel"
                 #salesChannel>
        </div>
          <div class="col" [class.has-danger]="errors?.subject">
              <div class="label-name">
                  <span>{{textService.currentText.subject}}</span>
              </div>
              <input matInput
                     [placeholder]="textService.currentText.addSubjectForEmail"
                     type="text"
                     formControlName="subject"
                     #subject>
          </div>
          <button class="primary" [disabled]="!sendForm.controls.subject.valid" (click)="mixpanelService.track('q invoicing manual preview clicked');showInvoice()">
              {{textService.currentText.invoicePreview}}
          </button>
      </div>

      <div id="summernote"></div>
    </div>
    <!--[class.has-danger]="errors.email"-->

  </div>
  <div class="dialog-footer space-between bulk-footer">
    <button mat-button class="primary-btn white" (click)="mixpanelService.track('q invoicing manual cancel clicked');dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn"
            [disabled]="!sendForm.controls.subject.valid"
            (click)="mixpanelService.track('q invoicing manual send clicked');sendEmail()">{{textService.currentText.send}}</button>
  </div>
</div>
