import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ConfirmationDialogComponent} from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import {UserService} from '../../../services/user.service';
import {TextService} from '../../../services/text.service';

@Component({
  selector: 'ninja-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordPageComponent implements OnInit {
  @ViewChild('email', { static: true }) loginInput: ElementRef;

  constructor(public fb: FormBuilder,
              public matDialog: MatDialog,
              public textService: TextService,
              public userService: UserService,
              private router: Router) {}

  public email = false;
  public errors: any = {};
  public loginForm = this.fb.group({
    email: ['',
      [Validators.required,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
  });

  public ngOnInit(): void {
    this.userService.loader = false;
  }

  public checkForm(): boolean {
    this.errors = {};
    if (!this.loginForm.valid) {
      if (!this.loginForm.controls.email.valid) {
        this.errors.emailInvalid = true;
        this.displayError(this.textService.currentText.errorIncorectEmail);
        return false;
      }
    }
    return this.loginForm.valid;
  }

  private displayError(message: string): void {
    if (this.errors.emailInvalid) {
      const old_value = this.loginForm.value.email;
      this.loginForm.patchValue({
        email: message
      });
      this.loginForm.controls['email'].disable();
      this.loginInput.nativeElement.blur();
      this.resetErrorState(old_value);
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.emailInvalid) {
        this.loginForm.patchValue({
          email: val,
        });
        this.errors.emailInvalid = false;
        this.loginForm.controls['email'].enable();
      }
    }, 2000);
  }

  public resetPassword(): void {
    if (this.checkForm()) {
      this.userService.loader = true;
      this.userService.forgotPassword(this.loginForm.value.email).subscribe((data) => {
        this.userService.loader = false;
        this.matDialog.open(ConfirmationDialogComponent, {
          data: {
            'dialogFor': 'reset-confirm',
          },
        }).afterClosed().subscribe(result => {
          this.router.navigate(['login']);
        });
      });
    }
  }
}
