<div class="main-dialog-container main-dialog-container--bulk default-popup">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="header header--bulk">
    <h2>{{labelBulk}}</h2>
    <div class="close-btn" (click)="dialogRef.close(false)"></div>
  </div>
  <div class="inner-dialog-container inner-dialog-container--bulk custom-selects"
       [formGroup]="fbaForm" name="form" novalidate>
    <div class="dialog-row sub-row" [class.has-danger]="errors?.name">
      <div class="label-name">
        <span>{{textService.currentText.ruleName}}</span>
      </div>
      <input matInput
             type="text"
             formControlName="name"
             #name>
    </div>
    <div class="dialog-row" [class.has-danger]="errors?.template">
      <div class="label-name">
        <span>{{textService.currentText.template}}</span>
      </div>
      <div class="select-wrapper" *ngIf="!errors?.template" (click)="functions.setDropDownPosition(template, 'tempSelect')" #template>
        <mat-form-field>
          <mat-select
              formControlName="template"
              shouldPlaceholderFloat="false"
              panelClass="default-dropdown tempSelect"
              (selectionChange)="getTemplateProducts()"
              [(ngModel)]="currentTemplate"
              name="currentTemplate"
              disableRipple
              #tempSelect>
            <mat-option *ngFor="let template of templateData" [value]="template">
              {{ template.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <input matInput
             *ngIf="errors?.template"
             type="text"
             name="subject"
             [value]="templateError"
      >
    </div>
    <div class="dialog-row dialog-column" *ngIf="isTemplateSelected">
      <p class="title" *ngIf="currentProducts !== textService.currentText.anyOfYourProducts">
        {{textService.currentText.bulkDialogText1}}
      </p>
      <p class="title" *ngIf="currentProducts === textService.currentText.anyOfYourProducts">
        {{textService.currentText.bulkDialogText2}}
      </p>
      <textarea matInput type="text" [value]="currentProducts || textService.currentText.anyOfYourProducts" readonly (click)="showProductsPopup()"></textarea>
    </div>
    <div class="dialog-row dialog-column">
      <p class="title">
        {{textService.currentText.bulkDialogTimeframe}}
      </p>
      <div class="datepicker-row">
        <div class="inner-row" [class.has-danger]="errors?.dateFrom">
          <div class="label-name">
            <span>{{textService.currentText.from}}</span>
          </div>
          <mat-form-field *ngIf="!errors?.dateFrom">
            <input
                formControlName="dateFrom"
                matInput
                class="datepicker-noborder"
                (click)="picker.open()"
                (keydown)="$event.preventDefault()"
                [matDatepicker]="picker"
                [(ngModel)]="dateFrom"
                name="dateFrom"
                [max]="dateTo"
            >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker></mat-datepicker>
          </mat-form-field>
          <input matInput
                 *ngIf="errors?.dateFrom"
                 type="text"
                 [value]="dateToError"
          >
        </div>
        <div class="inner-row" [class.has-danger]="errors?.dateTo">
          <div class="label-name">
            <span>{{textService.currentText.to}}</span>
          </div>
          <mat-form-field *ngIf="!errors?.dateTo">
            <input matInput
                   formControlName="dateTo"
                   [matDatepicker]="picker2"
                   (click)="picker2.open()"
                   (keydown)="$event.preventDefault()"
                   [min]="dateFrom"
                   [(ngModel)]="dateTo"
                   name="dateTo">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker2></mat-datepicker>
          </mat-form-field>
          <input matInput
                 *ngIf="errors?.dateTo"
                 type="text"
                 [value]="dateToError"
          >
        </div>
      </div>
    </div>
    <div class="dialog-row dialog-column last-row">
      <p class="title">
        {{textService.currentText.bulkDialogEmailEst}}:
      </p>
      <div class="inner-row btn-input-row">
        <button mat-button
                class="default-btn"
                [disabled]="!dateFrom || !dateTo || !currentTemplate"
                (click)="getCount()">{{textService.currentText.count}}
        </button>
        <input matInput type="text" disabled [(value)]="emailEstimated">
      </div>
    </div>
    <div class="dialog-footer space-between bulk-footer">
      <button mat-button class="default-btn" (click)="mixpanelService.track('q automated messaging - create bulk set canceled');dialogRef.close(false)">{{textService.currentText.cancel}}</button>
      <button mat-button class="primary-btn" (click)="mixpanelService.track('q automated messaging - create bulk set clicked');checkForm()">{{textService.currentText.set}}</button>
    </div>
  </div>
</div>
