import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';
import {SystemMessage} from '../models/BaseModels';

@Injectable()

export class SystemMessagesService extends UserService {
  public messages: Array<SystemMessage>;
  public unreadMessages: Array<SystemMessage>;

  public getSystemMessages(publicId: string): Observable<any> {
    return this.post('dashboard/system_messages', {
      publicId: publicId
    });
  }

  public clearSystemMessages(publicId: string): Observable<any> {
    return this.post('dashboard/clear_system_messages', {
      publicId: publicId
    });
  }

  public setMessages(messages): void {
    const first = messages.filter(this.isHasSeen.bind(this));
    const second = messages.filter(this.isHasNotSeen.bind(this));
    this.unreadMessages = second;
    this.messages = second.concat(first);
  }


  public isHasSeen(value: any): boolean {
    return value.messageIsHasSeen === true;
  }

  public isHasNotSeen(value: any): boolean {
    return value.messageIsHasSeen === false;
  }

}
