import {ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ConstService} from '../../../../services/const-service';
import {Functions} from '../../../../services/functions';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {CouponsService} from '../../../../services/coupons.service';
import {FbService} from '../../../../services/fb.service';
import {ConfirmationDialogComponent} from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import {TextService} from '../../../../services/text.service';
import {FacebookService, InitParams} from "ngx-facebook";

@Component({
  selector: 'ninja-create-new-conversation',
  templateUrl: './create-new-conversation.component.html',
  styleUrls: ['./create-new-conversation.component.scss']
})

export class CreateNewConversationComponent implements OnInit {
  @ViewChild('conversationHolder', { static: true }) conversationHolder: ElementRef;
  public checkNewEl: boolean;
  public coupons: any;
  public nameFromFB: any;
  public currentFbUser: string;
  public testOnThisPage = false;
  public saveFlag: boolean;
  public currentId: any;
  public currentTesterName: string;
  public currentTester: any;
  public testers = [];
  public embeddedTesters = [];
  public tags: any;
  public triggerKeyword: string;
  public conversationName = '';
  public linkConversation = null;
  public conversationList: any;
  public fbPageId = '';
  public fbToken = '';
  public triggerBy = 'PAYLOAD';
  public fbPages: any;
  public width = 150;
  public y = 100;
  public oldX = 0;
  public grabber = false;
  public autoHumanize = false;
  public fixedPhone = false;
  public conversation: any;
  public fullScreenMode: boolean;
  public activate: boolean;
  public activatePreview: boolean;
  public notificationMessage: string;
  public notificationTitle: string;
  public testerLoading: boolean;
  public conversationTag: any;
  public refLink = '2129::';

  constructor(public matDialog: MatDialog,
              public constService: ConstService,
              public textService: TextService,
              public userService: UserService,
              public couponsService: CouponsService,
              private fb: FacebookService,
              public fbService: FbService,
              public ref: ChangeDetectorRef,
              public router: Router,
              public activatedRoute: ActivatedRoute,
              public functions: Functions) {
  let initParams: InitParams = {
      appId: '142114196208163',
      xfbml: true,
      version: "v2.8"
    };
    if (!this.fbService.fbChatLogged) {
      fb.init(initParams);
      this.fbService.fbChatLogged = true;
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.grabber) {
      return;
    }
    this.resizer(event.clientX - this.oldX);
    this.oldX = event.clientX;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    if (event.target['classList'].contains('closeMenuDet')) {
      this.fbService.fbClose.next(true);
    }
    this.grabber = false;
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (event.target['classList'].contains('grabber')) {
      this.grabber = true;
      this.oldX = event.clientX;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScrollEvent($event) {
    $event.target.scrollingElement.scrollTop >= 140 ? this.fixedPhone = true : this.fixedPhone = false;
  }

  public ngOnInit() {
    document.getElementById('fb-root').style.display = 'block';
    this.userService.loaderObesrver.next(true);
    this.fbService.facebookNav().subscribe((data) => {
      data ? this.disableEl(this.fbService.conversation) : this.enableBranch(this.fbService.conversation)
    });
    this.fbService.getPagesFb(this.userService.currentUser.id).subscribe((data) => {
      if (this.fbPageId.length === 0 && data.length && data[0].pageId) {
        this.fbPageId = data[0].pageId;
      }
      this.fbPages = data;
      if (!this.activatedRoute.snapshot.queryParams.id) {
        this.fbService.createEmptyСonversation(this.userService.currentUser.id).subscribe((id) => {
          this.currentId = id.conversationId;
          this.fbService.currentPayload = id.conversationId;
          this.conversationName = 'default_[' + this.currentId + ']';
          const currid = this.currentId ? this.currentId : this.activatedRoute.snapshot.queryParams.id;
          this.linkConversation = "http://m.me/" + this.fbPageId + '?ref=' + currid + "::";
          this.getTesterName();
          this.saveConversation();
        });
      }
      this.fbService.getConversationsNames(this.userService.currentUser.id, this.fbPageId).subscribe((data) => {
        this.userService.conversationList = data;
      });
    });
    if (this.activatedRoute.snapshot.queryParams.id) {
      this.currentId = this.activatedRoute.snapshot.queryParams.id;
      this.fbService.currentPayload = this.activatedRoute.snapshot.queryParams.id
    }
    this.fbService.conversation ? this.fbService.conversation = [] : this.fbService.conversation = [];
    if (this.currentId) {
      this.fbService.editConversation(this.userService.currentUser.id,
                                      this.activatedRoute.snapshot.queryParams.id).subscribe((conversation) => {
        this.triggerBy = conversation.triggerType ? conversation.triggerType : 'PAYLOAD';
        if (conversation.triggerValue) {
          this.triggerKeyword = conversation.triggerValue;
        }
        this.fbService.conversation = conversation.data || [];
        if (this.fbService.conversation.length) {
          this.fbService.conversation[0].active = true;
        }
        this.conversationName = conversation.name;
        if (conversation.tagId && conversation.tagName) {
          this.conversationTag = {tagId: conversation.tagId, tagName: conversation.tagName}
        }
        this.fbPageId = conversation.pageId;
        const currid = this.currentId ? this.currentId : this.activatedRoute.snapshot.queryParams.id;
        this.linkConversation = "http://m.me/" + this.fbPageId + '?ref=' + currid + "::";
        if (this.fbPageId) {
          this.getTesterName();
        }
      });
      this.userService.loaderObesrver.next(false);
    }
    this.couponsService.getCoupons(this.userService.currentUser.id).subscribe((data) => {
      this.userService.coupons = data;
    });
    this.fbService.facebookGetTags(this.userService.currentUser.id).subscribe((data) => {
      this.userService.tags = data.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
    });
  }

  public ngOnDestroy() {
    document.getElementById('fb-root').style.display = 'none';
  }

  public getMyProfile() {
    this.fb.api('/me?fields=id,name,email')
      .then(response => {
        console.log('Got response', response);
      });
    // must login before using `api`
    this.fb.getLoginStatus()
      .then((res) => {
        this.currentFbUser = res.authResponse.userID
      })
      .catch(console.error.bind(console));
  }

  public disableEl(data) {
    for (let i = 0; data.length > i; i++) {
      data[i].active = false;
      data[i].activatePreview = false;
      if (data[i].childs && data[i].childs.length > 0) {
        data[i].active = false;
        data[i].activatePreview = false;
        this.disableEl(data[i].childs);
      }
    }
    this.activate = false;
    this.activatePreview = true;
  }

  public enableBranch(data) {
    const currentData = data;
    for (let i = 0; currentData.length > i; i++) {
      if (currentData[i].active) {
        if (this.activatePreview) {
          currentData[i].activatePreview = true;
          this.activatePreview = false;
        }
        this.activate = true;
        return;
      }
      if (currentData[i].childs) {
        this.enableBranch(currentData[i].childs);
      }
      if (this.activate) {
        currentData[i].active = true;
        return;
      }
      continue;
    }
  }

  public resizer(offsetY: number) {
    this.width += offsetY;
  }

  public setActive(e, index) {
    this.fbService.conversation[index].active = true;
  }

  public newTemplate(data) {
    return JSON.parse(JSON.stringify(data));
  }

  public botComponentErrorPopup(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        title: this.textService.currentText.warning,
        message: this.textService.currentText.botIncorrectELem
      },
    }).afterClosed().subscribe();
  }

  public addEvent(data, action) {
    this.checkNewEl = true;
    if (data && !data.length) {
      if (action === 'QuickReply') {
        this.botComponentErrorPopup();
        return;
      }
      this.fbService.facebookNavigation.next(true);
      data.push(action);
      this.fbService.facebookNavigation.next(false);
      return;
    }
    for (let i = 0; data.length > i; i++) {
      if (data[i].active && data[i].childs && data[i].childs.length > 0 && !this.isActiveChilds(data[i].childs)) {
        this.addEvent(data[i].childs, action);
        return;
      }
      if (data[i].type === 'QuickReply' && action === 'QuickReply' && data[i].active) {
        this.fbService.facebookNavigation.next(true);
        data.push(JSON.parse(JSON.stringify(this.fbService.buttonConversationAction)));
        this.fbService.facebookNavigation.next(false);
        return;
      }
      if (data[i].type === 'QuickReply' && this.isActiveChilds(data[i].childs) &&
        data[i].childs && data[i].childs.length && data[i].active) {
        if (data[i].childs.length) {
          this.fbService.facebookNavigation.next(true);
          data[i].childs.unshift(action);
          this.fbService.facebookNavigation.next(false);
          return;
        }
        this.fbService.facebookNavigation.next(true);
        data[i].childs.push(action);
        this.fbService.facebookNavigation.next(false);
        return;
      }
      if (data[i].type === 'template' && data[i].childs && this.isActiveChilds(data[i].childs) && action === 'QuickReply') {
        if (data[i].childs.length < 3) {
          this.fbService.facebookNavigation.next(true);
          data[i].childs.push(JSON.parse(JSON.stringify(this.fbService.templateBUtton)));
          this.fbService.facebookNavigation.next(false);
        } else {
          this.botComponentErrorPopup();
        }
        return;
      }
      if (data[i].active) {
        if (action === 'QuickReply') {
          if (data[i].type === 'QuickReply') {
            this.fbService.facebookNavigation.next(true);
            data.push(JSON.parse(JSON.stringify(this.fbService.buttonConversationAction)));
            this.fbService.facebookNavigation.next(false);
            return;
          }
          if (data.length < 3 && (data[i].type === 'TEMPLATE_BUTTON' || data[i].action === 'QuickReply')) {
            this.fbService.facebookNavigation.next(true);
            data.push(JSON.parse(JSON.stringify(this.fbService.templateBUtton)));
            this.fbService.facebookNavigation.next(false);
            return;
          }
          if (data[i].type === 'TEMPLATE_BUTTON' || data[i].type === 'QuickReply') {
            this.botComponentErrorPopup();
            return;
          }
        }
        if (action === 'QuickReply' && data[i].type !== 'QuickReply') {
          if (!data[data.length - 1].childs) {
            data[data.length - 1].childs = [];
          }
          if (data[data.length - 1].type !== 'template') {
            this.fbService.facebookNavigation.next(true);
            data[data.length - 1].childs.push(JSON.parse(JSON.stringify(this.fbService.buttonConversationAction)));
            this.fbService.facebookNavigation.next(false);
            return;
          } else {
            this.botComponentErrorPopup();
            return;
          }
        }
        if (action !== 'QuickReply' && data[i].type === 'QuickReply') {
          if (data[i].childs) {
            this.fbService.facebookNavigation.next(true);
            data[i].childs.push(action);
            this.fbService.facebookNavigation.next(false);
          } else {
            data[i].childs = [];
            this.fbService.facebookNavigation.next(true);
            data[i].childs.push(action);
            this.fbService.facebookNavigation.next(false);
          }
          return;
        }
        if (action !== 'QuickReply' && data[i].type === 'TEMPLATE_BUTTON') {
          if (data[i].weburl && data[i].weburl.length) {
            this.botComponentErrorPopup();
            return;
          }
          if (data[i].childs) {
            this.fbService.facebookNavigation.next(true);
            data[i].childs.push(action);
            this.fbService.facebookNavigation.next(false);
          } else {
            data[i].childs = [];
            this.fbService.facebookNavigation.next(true);
            data[i].childs.push(action);
            this.fbService.facebookNavigation.next(false);
          }
          return;
        }
        if (action.type === 'template' && data[i].type !== 'QuickReply') {
          if (!data[data.length - 1].childs) {
            data[data.length - 1].childs = [];
          }
          if (data[data.length - 1].childs && !data[data.length - 1].childs.length) {
            this.fbService.facebookNavigation.next(true);
            data.push(JSON.parse(JSON.stringify(this.fbService.templateConversationAction)));
            this.fbService.facebookNavigation.next(false);
            return;
          } else {
            // this.botComponentErrorPopup();
            return;
          }
        }
        if (action.type !== 'QuickReply' && data[i].type !== 'QuickReply') {
          if (i === data.length - 1 && data[i].childs && data[i].childs.length) {
            return
          }
          this.fbService.facebookNavigation.next(true);
          data.splice(i + 1, 0, action);
          this.fbService.facebookNavigation.next(false);
          return;
        }
      }
      if (i === data.length - 1 && this.isActiveChilds(data)) {
        if (action === 'QuickReply' && (!data[data.length - 1].type || data[data.length - 1].type === 'QuickReply')) {
          this.fbService.facebookNavigation.next(true);
          data.push(JSON.parse(JSON.stringify(this.fbService.buttonConversationAction)));
          this.fbService.facebookNavigation.next(false);
        }
        return;
      }
    }
  }

  public focusTextEl(cls) {
    setTimeout(() => {
      const el = document.querySelectorAll(cls);
      if (el.length && el[el.length - 1].children) {
        const el2: HTMLElement = el[el.length - 1].children[1] as HTMLElement;
        if (el2) {
          el2.focus();
        }
      }
    });
  }

  public isActiveChilds(data) {
    if (data) {
      for (let i = 0; data.length > i; i++) {
        if (data[i].active) {
          return false;
        }
      }
    }
    return true;
  }

  public emitTextData(option, e, index) {
    this.fbService.conversation[index].value = e.text;
  }

  public setImgUrl(e, index) {
    this.fbService.conversation[index] = e;
  }

  public setActionData(e, index) {
    this.fbService.conversation[index] = e;
  }

  public setTemplateData(e, index) {
    this.fbService.conversation[index] = e;
  }

  public emitConversationData(e, index) {
    this.fbService.conversation[index].data = e;
  }

  public saveConversation(withNotification?: boolean, openLinkPopup?: boolean) {
    if (this.triggerBy === 'TEXT' && (!this.triggerKeyword || this.triggerKeyword.length === 0)) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'custom-warning',
          text: this.textService.currentText.warningTextKeyword
        },
      }).afterClosed().subscribe();
      return
    }
    this.userService.loaderObesrver.next(true);
    const data = {
      publicId: this.userService.currentUser.id,
      data: this.fbService.conversation || [],
      pageId: this.fbPageId,
      tagId: this.conversationTag ? this.conversationTag.tagid : undefined,
      tagName: this.conversationTag ? this.conversationTag.name : undefined,
      conversationName: this.conversationName.length ? this.conversationName : 'default_[' + this.currentId + ']',
      conversationId: this.currentId,
      isAutoHumanized: this.autoHumanize,
      triggerType: this.triggerBy,
      triggerValue: this.triggerBy === 'TEXT' ? this.triggerKeyword ? this.triggerKeyword.toString() : '' : undefined
    };
    this.fbService.fbSaveConversation(data).subscribe((id) => {
      this.userService.loaderObesrver.next(false);
      this.currentId = id.conversationId;
      if (this.saveFlag) {
        this.testConversation();
        this.saveFlag = false;
        if (!this.currentTester) {
          withNotification = false;
        }
      }
      if (withNotification) {
        this.userService.showNotification = true;
        this.notificationMessage = this.textService.currentText.saveConvSuccessText;
        this.notificationTitle = this.textService.currentText.success;
      }
      if (openLinkPopup) {
        this.matDialog.open(ConfirmationDialogComponent, {
          data: {
            'dialogFor': 'getLinkConversation',
            title: this.textService.currentText.getLink,
            message: this.textService.currentText.linkToTheConversation,
            link: this.linkConversation
          },
        }).afterClosed().subscribe();
      }
    });
    this.userService.loaderObesrver.next(false);
  }

  public testConversation() {
    const id = this.currentId ? this.currentId : this.activatedRoute.snapshot.queryParams.id;
    if (!this.currentTester) {
      this.resetTester();
      return;
    } else {
      if (!this.testOnThisPage) {
        this.fbService.startDemoConversation(this.userService.currentUser.id, id, this.fbPageId,
          this.currentTester.fbId).subscribe();
      } else {
        this.fbService.startConversation(this.userService.currentUser.id, id, this.fbPageId,
          this.currentTester.fbId).subscribe();
      }
    }
  }

  public resetTester() {
    if (!this.testOnThisPage) {
      this.fbService.resetDemoTester(this.userService.currentUser.id, this.fbPageId, this.conversationName).subscribe((data) => {
        const link = 'https://www.facebook.com/messages/t/' + this.fbPageId;
        this.showInstructions(data.code, data.link, true, false);
        this.refLink = data.ref;
        // this.fb.logout().then(res => {
        //   console.log(res)
        // })
      });
    } else {
      this.fbService.resetTester(this.userService.currentUser.id, this.fbPageId, this.conversationName).subscribe((data) => {
        const link = 'https://www.facebook.com/messages/t/' + this.fbPageId;
        this.showInstructions(data.code, data.link, true, true);
        this.refLink = data.ref;
        // this.fb.logout().then(res => {
        //   console.log(res)
        // })
      });
    }
  }

  public setTester(tester, resetTester?) {
    if (this.testOnThisPage ? this.testers.length === 0 : this.embeddedTesters.length === 0) {
      this.currentTesterName = null;
      this.currentTester = {};
      return
    }
    if (tester && tester.hasOwnProperty('firstName')) {
      this.currentTesterName = tester.firstName + ' ' + tester.lastName;
      this.currentTester = tester;
    }
  }

  public navigateToManager() {
    this.router.navigate(['dashboard/facebook-bot-manager/conversation-manager']);
  }

  public deleteAll() {
    this.fbService.conversation = [];
  }

  public getTesterName() {
    this.testerLoading = true;
    this.nameFromFB = null;
    this.fbService.getTesterName(this.userService.currentUser.id, this.fbPageId).subscribe((testersInfo) => {
      this.testers = testersInfo.subscribersList;
      this.embeddedTesters = testersInfo.embeddedTesters;
      this.setTesterName(testersInfo);
      this.fb.getLoginStatus().then((res) => {
        if (res.authResponse && res.authResponse.userID) {
          this.fb.api(res.authResponse.userID)
            .then((res) => {
              console.log(res)
              let test = 'Vladimir  Piharev'
              this.nameFromFB = res.name.replace(/\s/g, '').toLowerCase();
              this.setTesterName(testersInfo);
            })
            .catch(e => this.setTesterName(testersInfo));
        }
      });
    })
  }

  public setTesterName(testersInfo) {
    this.currentTester = '';
    if (!this.nameFromFB && testersInfo.firstName) {
      this.currentTesterName = '';
      this.currentTesterName += testersInfo.firstName + ' ' + testersInfo.lastName;
    }
    if (testersInfo.embeddedTesters && !this.testOnThisPage) {
      let currentName;
      let setDefault = true;
      for (let i = 0; testersInfo.embeddedTesters.length > i; i++) {
        currentName = testersInfo.embeddedTesters[i].firstName.replace(/\s/g, '').toLowerCase();
        if (currentName === this.nameFromFB) {
          this.setTester(this.embeddedTesters[i]);
          setDefault = false;
        }
      }
      if (setDefault) {
        this.setTester(this.embeddedTesters[0]);
      }
      this.testerLoading = false;
      return
    }
    if (testersInfo.subscribersList && testersInfo.subscribersList.length > 0 && this.testOnThisPage) {
      let currentName;
      let setDefault = true;
      for (let i = 0; testersInfo.subscribersList.length > i; i++) {
        currentName = testersInfo.subscribersList[i].firstName.replace(/\s/g, '').toLowerCase();
        if (currentName === this.nameFromFB) {
          this.setTester(this.testers[i]);
          setDefault = false;
        }
      }
      if (setDefault) {
        this.setTester(this.testers[0]);
      }
      if (this.testers.length === 0) {
        this.currentTester = '';
      }
      this.testerLoading = false;
      return
    }
    this.testerLoading = false;
  }


  public showInstructions(token, link, emedded?, showLink?): void {
    let pageName = '';
    for (let i = 0; this.fbPages.length > i; i++) {
      if (this.fbPages[i].pageId === this.fbPageId) {
        pageName = this.fbPages[i].name;
      }
    }
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'bot-messenger-instruction',
        token: token,
        link: link,
        page: pageName,
        testOnThisPage: emedded ? !this.testOnThisPage : false,
        showLink: showLink
      },
    }).afterClosed().subscribe(res => {
      this.getTesterName();
    });
  }

  public showDeleteTesterConfirm(tester): void {
    let pageName = '';
    for (let i = 0; this.fbPages.length > i; i++) {
      if (this.fbPages[i].pageId === this.fbPageId) {
        pageName = this.fbPages[i].name;
      }
    }
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'delete-tester-confirm',
        'tester': tester,
        'page': pageName
      },
    }).afterClosed().subscribe(res => {
      if (res) {
        if (!this.testOnThisPage) {
          this.fbService.deleteDemoTesters(this.userService.currentUser.id, tester.fbId.toString()).subscribe(() => {
            for (let i = 0; this.embeddedTesters.length > i; i++) {
              if (this.embeddedTesters[i].fbId === tester.fbId) {
                this.embeddedTesters.splice(i, 1);
                if (this.currentTester.fbId === tester.fbId) {
                  this.currentTester = '';
                }
              }
            }
          });
        } else {
          this.fbService.deleteTester(this.userService.currentUser.id, this.fbPageId, tester.fbId.toString()).subscribe(() => {
            for (let i = 0; this.testers.length > i; i++) {
              if (this.testers[i].fbId === tester.fbId) {
                this.testers.splice(i, 1)
                if (this.currentTester.fbId === tester.fbId) {
                  this.currentTester = '';
                }
              }
            }
          });
        }
      }
    });
  }

  public showSelectTesterDialog() {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.warning,
        'message': this.textService.currentText.setTesterMessage
      }
    }).afterClosed().subscribe()
  }

  public JSONChecker() {
    for (let i = 0; this.fbService.conversation.length > i; i++) {
      if (this.fbService.conversation[i].type === 'VARIABLE_TEXT') {
        this.matDialog.open(ConfirmationDialogComponent, {
          data: {
            'dialogFor': 'custom-confirm',
            title: this.textService.currentText.dialogDowngrade,
            message: this.textService.currentText.jsonConfirmation
          },
        }).afterClosed().subscribe((isConfirmed) => {
          if (isConfirmed) {
            this.getJSON();
          }
        });
        return
      }
    }
    this.fbService.conversation.length ? this.getJSON() : null;
  }

  public getJSON() {
    const data = {
      publicId: this.userService.currentUser.id,
      data: this.fbService.conversation,
      pageId: this.fbPageId,
      conversationName: this.conversationName.length > 0 ? this.conversationName : 'default_[' + this.currentId + ']',
      conversationId: this.currentId,
      isAutoHumanized: this.autoHumanize,
      triggerType: this.triggerBy,
      triggerValue: this.triggerBy === 'TEXT' ? this.triggerKeyword.toString() : undefined
    };
    this.userService.loaderObesrver.next(true);
    this.fbService.fbSaveConversation(data).subscribe((id) => {
      this.currentId = id.conversationId;
      let pageToken = this.getPageTokenByPageId(this.fbPageId);
      this.userService.loaderObesrver.next(false);
      this.fbService.getConversationJson(this.userService.currentUser.id, this.currentId, this.fbPageId, pageToken).subscribe((convJSON) => {
        this.matDialog.open(ConfirmationDialogComponent, {
          data: {
            'dialogFor': 'bot-json',
            json: convJSON.json,
          },
        }).afterClosed().subscribe();
      });
    });
  }

  public getPageTokenByPageId(pageId): string {
    for (let i = 0; i < this.fbPages.length; i++) {
      if (this.fbPages[i].pageId === pageId) {
        return this.fbPages[i].pageToken
      }
    }
  }

  public switchTesterList() {
    !this.testOnThisPage ? document.getElementById('fb-root').style.display = 'block' :
      document.getElementById('fb-root').style.display = 'none';
    if (this.triggerBy === 'TEXT') {
      this.warninngForSpecText();
    }
    let isSameAcc = false;
    let currentList = this.testOnThisPage ? this.testers : this.embeddedTesters;
    for (let i = 0; currentList.length > i; i++) {
      if (this.currentTester && (this.currentTester['firstName'] === currentList[i].firstName)) {
        isSameAcc = true;
        this.currentTester['fbId'] = currentList[i].fbId;
        this.setTester(currentList[i], true);
      }
    }
    if (!isSameAcc) {
      if (this.testOnThisPage && this.testers.length) {
        this.setTester(this.testers[0]);
        return;
      }
      if (!this.testOnThisPage && this.embeddedTesters.length) {
        this.setTester(this.embeddedTesters[0]);
        return;
      }
      this.currentTesterName = null;
      this.currentTester = null;
    }
  }

  public warninngForSpecText() {
    if (this.triggerBy === 'TEXT' && !this.testOnThisPage) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'custom-ok',
          title: this.textService.currentText.warning,
          message1: this.textService.currentText.warningDemoBotTest1,
          message2: this.textService.currentText.warningDemoBotTest2
        },
      }).afterClosed().subscribe();
      return
    }
  }

}

