<div class="dashboard one-screen"
     *ngIf="!userService.loader"
     [class.wide]="userService.smallSidebar">
  <div class="dashboard-container" *ngIf="showPage">
    <div class="overlay" [class.wide]="userService.smallSidebar"
        *ngIf="userService.botIsUnderConstruction">
        <div class="main-dialog-container ">
                <div class="header">
                  <h2>{{textService.currentText.botUnderConstructionTitle}}</h2>
                </div>
                <div class="dialog-message">
                  <p>
                    {{textService.currentText.botUnderConstructionText1}}
                      <br>
                      <br>
                    {{textService.currentText.botUnderConstructionText2}}
                      <br>
                      <br>
                    {{textService.currentText.botUnderConstructionText3}}
                  </p>
                  <button mat-button
                          (click)="googleAnalyticsEventsService.emitEvent('Button', 'Switch', 'Click', 1)"
                          class="mat-button primary-btn"><a
                      href="https://app.quantifyninja.com/#!login">{{textService.currentText.switch}}</a></button>
                </div>
          </div>
    </div>
    <div class="dashboard-header">
      <div class="head-container">
        <h2>{{textService.currentText.facebookBotManager}}</h2>
      </div>
    </div>
    <div class="main-container">
      <img src="../../../../../assets/images/phone.svg" alt="">
      <div class="description-block">
        <p> {{textService.currentText.botGrantAccessSubTitle}} </p>
        <p> - {{textService.currentText.botGrantAccessSubTitle2}}</p>
        <p> - {{textService.currentText.botGrantAccessSubTitle3}}</p>
        <p> - {{textService.currentText.botGrantAccessSubTitle4}}</p>
        <h3>{{textService.currentText.botGrantAccessSubTitle1}}:</h3>
        <a href="http://m.me/309813392833359?ref=222" target="_blank">http://m.me/309813392833359?ref=222</a>
        <button mat-button mat-raised-button class="primary-btn" (click)="goToFb()">{{textService.currentText.grantAccess}}</button>
        <h3>{{textService.currentText.botGrantAccessPermissions}}:</h3>
        <div class="list-item first">
          <h3>1. {{textService.currentText.botGrantAccessListTitle1}}</h3>
          <p>{{textService.currentText.botGrantAccessListText1}}</p>
        </div>
        <div class="list-item">
          <h3>2. {{textService.currentText.botGrantAccessListTitle2}}</h3>
          <p>{{textService.currentText.botGrantAccessListText2}}</p>
        </div>
        <div class="list-item">
          <h3>3. {{textService.currentText.botGrantAccessListTitle3}}</h3>
          <p>{{textService.currentText.botGrantAccessListText3}}</p>
        </div>
        <div class="list-item">
          <h3>4. {{textService.currentText.botGrantAccessListTitle4}}</h3>
          <p>{{textService.currentText.botGrantAccessListText4}}</p>
        </div>
        <div class="list-item">
          <h3>5. {{textService.currentText.botGrantAccessListTitle5}}</h3>
          <p>{{textService.currentText.botGrantAccessListText5}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
