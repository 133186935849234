<div class="prod-set-table">
  <div class="table-row">
    <div class="details py-3">
      <div class="media align-items-center">
        <img class="mr-2" width="32" [src]="products?.image" alt="">
        <div class="media-body">
          <h5 class="mt-0 mb-0">Product CoGS for <span>{{products.asin}}</span></h5>
          {{products.shortName}} <mat-icon (click)="editShortName(products)">edit</mat-icon>
        </div>
      </div>
      <div class="table-container mt-2">
        <div class="table-wrapper prod-set-wrapper">
          <div class="table-header-wrapper">
            <div class="table-header prod-set-header">
              <div class="td"></div>
              <div class="td">
                {{textService.currentText.description}}
              </div>
              <div class="td">
                {{textService.currentText.unitCost}}
              </div>
              <div class="td">
                {{textService.currentText.currency}}
              </div>
              <div class="td">
                {{textService.currentText.quantity}}
              </div>
              <div class="td">
                {{textService.currentText.totalCost}}
              </div>
              <div class="td">
                {{textService.currentText.from}}
              </div>
              <div class="td">
                {{textService.currentText.to}}
              </div>
            </div>
          </div>
          <div class="table-holder table-holder--prod-set">
            <div class="table prod-set-table">
              <div class="table-row" *ngFor="let costData of products.costData; let i = index"
                (click)="costData.showDetailedCost = !costData.showDetailedCost">
                <div class="tr">
                  <div class="td">
                    <ng-container *ngIf="!costData.showDetailedCost">
                      <mat-icon>keyboard_arrow_right</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="costData.showDetailedCost">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </ng-container>
                  </div>
                  <div class="td">
                    {{costData.costTitle}}
                  </div>
                  <div class="td">
                    {{+costData.productCost.costPerUnit + +costData.shippingCost.costPerUnit}}
                  </div>
                  <div class="td">
                    USD
                  </div>
                  <div class="td">
                    {{costData.productCost.units}}
                  </div>
                  <div class="td">
                    {{ costData.totalPaid }}
                  </div>
                  <div class="td">
                    {{costData.from | date : 'd/M/yy'}}
                  </div>
                  <div class="td">
                    {{costData.to | date : 'd/M/yy'}}
                  </div>
                </div>
                <div class="container-fluid detailed-cost" *ngIf="costData.showDetailedCost"
                  (click)="$event.stopPropagation()">
                  <div class="row px-5 mx-5 pb-3 border-bottom border-left border-right rounded-bottom">
                    <div class="col-4">
                      <h3 class="mt-2">
                        <app-inline-edit #costTitleEdit (update)="onCompEditUpdate($event, costData, 'costTitleEdit')"
                          (close)="onCompEditClose($event);" [data]='costData.costTitle' [EditMode]='false'>
                        </app-inline-edit>
                        <mat-icon (click)="OnValueEdit(costTitleEdit)">edit</mat-icon>
                      </h3>
                    </div>
                    <div class="col-8 d-flex filters active custom-selects m-0 mt-2 justify-content-end"
                      *ngIf="i + 1 > 1 ? true : false">
                      <div class="mr-5 d-flex align-items-center mt-n1">
                        <mat-label>Effective Date</mat-label>
                        <mat-form-field>
                          <input matInput [matDatepicker]="picker" [(ngModel)]="costData.from" [max]="costData.to"
                            (click)="picker.open()" [placeholder]="textService.currentText.calander"
                            (dateChange)="effectiveDateChange(products, i, $event)">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="d-flex align-items-center mt-n1">
                        <mat-label>Expire Date</mat-label>
                        <mat-form-field>
                          <input matInput [matDatepicker]="picker1" [(ngModel)]="costData.to" [min]="costData.from"
                            (click)="picker1.open()" [placeholder]="textService.currentText.calander">
                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker1></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-12 mb-4 pb-2">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>Per Unit Cost</th>
                              <th>Units</th>
                              <th>Currency</th>
                              <th>Total Paid</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Product Cost</td>
                              <td width="240">
                                <app-inline-edit #productCostPerUnitEdit
                                  (update)="onCompEditUpdate($event, costData, 'productCostPerUnitEdit')"
                                  (close)="onCompEditClose($event);" [data]='costData.productCost.costPerUnit'
                                  [EditMode]='false'>
                                </app-inline-edit>
                                <mat-icon (click)="OnValueEdit(productCostPerUnitEdit)">edit</mat-icon>
                              </td>
                              <td width="240">
                                <app-inline-edit #productCostUnitsEdit
                                  (update)="onCompEditUpdate($event, costData, 'productCostUnitsEdit')"
                                  (close)="onCompEditClose($event);" [data]='costData.productCost.units'
                                  [EditMode]='false'>
                                </app-inline-edit>
                                <mat-icon (click)="OnValueEdit(productCostUnitsEdit)">edit</mat-icon>
                              </td>
                              <td>USD</td>
                              <td width="240">
                                <app-inline-edit #productCostTotaPaidEdit
                                  (update)="onCompEditUpdate($event, costData, 'productCostTotaPaidEdit')"
                                  (close)="onCompEditClose($event);" [data]='costData.productCost.totaPaid'
                                  [EditMode]='false'>
                                </app-inline-edit>
                                <mat-icon (click)="OnValueEdit(productCostTotaPaidEdit)">edit</mat-icon>
                              </td>
                            </tr>
                            <tr>
                              <td>Shipping</td>
                              <td>
                                <app-inline-edit #shippingCostPerUnitEdit
                                  (update)="onCompEditUpdate($event, costData, 'shippingCostPerUnitEdit')"
                                  (close)="onCompEditClose($event);" [data]='costData.shippingCost.costPerUnit'
                                  [EditMode]='false'>
                                </app-inline-edit>
                                <mat-icon (click)="OnValueEdit(shippingCostPerUnitEdit)">edit</mat-icon>
                              </td>
                              <td>
                                <app-inline-edit #shippingCostUnitsEdit
                                  (update)="onCompEditUpdate($event, costData, 'shippingCostUnitsEdit')"
                                  (close)="onCompEditClose($event);" [data]='costData.shippingCost.units'
                                  [EditMode]='false'>
                                </app-inline-edit>
                                <mat-icon (click)="OnValueEdit(shippingCostUnitsEdit)">edit</mat-icon>
                              </td>
                              <td>USD</td>
                              <td>
                                <app-inline-edit #shippingCostTotaPaidEdit
                                  (update)="onCompEditUpdate($event, costData, 'shippingCostTotaPaidEdit')"
                                  (close)="onCompEditClose($event);" [data]='costData.shippingCost.totaPaid'
                                  [EditMode]='false'>
                                </app-inline-edit>
                                <mat-icon (click)="OnValueEdit(shippingCostTotaPaidEdit)">edit</mat-icon>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td class="border-0" colspan="4"></td>
                              <td class="p-0">
                                <table class="table">
                                  <tr>
                                    <td class="w-50">Total CoGS</td>
                                    <td>
                                      {{ costData.totalPaid }}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <!-- <div class="col-12">
                    {{costData | json}}
                  </div> -->
                    <div class="col-10 d-flex justify-content-center">
                      <button mat-button mat-raised-button class="secondary-btn btn-fill mr-4"
                        [disabled]="costData.shippingCost.costPerUnit > 0 || costData.productCost.costPerUnit > 0 ? false : true"
                        (click)="saveCOGS(products)">
                        {{textService.currentText.save}}
                      </button>
                      <button mat-button mat-raised-button (click)="cancel(products, i)"
                        class="secondary-btn btn-fill btn-outline white">
                        {{textService.currentText.cancel}}
                      </button>
                    </div>
                    <div class="col-2 d-flex justify-content-center">
                      <button mat-raised-button color="warn" class="delete" (click)="delete(products, i)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-5">
        <div class="col-12 mt-3">
          <button mat-button mat-raised-button (click)="addNewCostData(products)"
            class="secondary-btn btn-fill w-auto px-5">
            Add CoGS Period
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ninja-notification [active]="userService.showNotification" [message]="notificationMessage" [title]="notificationTitle">
</ninja-notification>