import {Component, HostListener, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {TextService} from '../../../../services/text.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TemplateService} from '../../../../services/template.service';
import {Functions} from '../../../../services/functions';
import {OrdersService} from '../../../../services/orders.service';

@Component({
  selector: 'ninja-last-messages-sent',
  templateUrl: './last-messages-sent.component.html',
  styleUrls: ['./last-messages-sent.component.scss']
})

export class LastMessagesSentComponent implements OnInit {
  public emailList: any;
  public templateList: any;
  public filters: any;
  public pageSize = 20;
  public prevToken: any;
  public nextToken: any;
  public isNextDisabled: boolean;

  constructor(public userService: UserService,
              public textService: TextService,
              public functions: Functions,
              public activatedRoute: ActivatedRoute,
              public ordersService: OrdersService,
              public templateService: TemplateService,
              public router: Router) {
    this.filters = {
      templateId: '',
      purchasedAfter: '',
      purchasedBefore: '',
      shippedAfter: '',
      shippedBefore: '',
      orderId: '',
      templatesId: '',
      buyerEmail: '',
      excludeTemplatesId: [],
      includeTemplatesId: [],
    };
  }

  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.page) {
        this.userService.currentPage = params.page;
      }
      if (params.templateId) {
        this.filters.includeTemplatesId.push(+params.templateId);
        // this.filters.templateId = +params.templateId;
      }
    });
    this.templateService.getTemplatesName(this.userService.currentUser.id).subscribe(templates => {
      this.templateList = templates.list;
    });
    this.search();
  }

  @HostListener('window:click', ['$event'])
  public checkFullScreen(event: MouseEvent): void {
    if (!event.target['classList'].contains('template-list') && !event.target['classList'].contains('more')) {
      for (let i = 0; i < this.emailList.length; i++ ) {
        this.emailList[i].showMoreTemplates = false;
      }
    }
  }

  public goBack(): void {
    if (this.userService.currentPage === 'fba') {
      this.router.navigate(['/dashboard/automated-messaging/fba']);
    }
    if (this.userService.currentPage === 'fbm') {
      this.router.navigate(['/dashboard/automated-messaging/fbm']);
    }
  }

  public changeBuyerBlackList(value, buyerEmail): void {
    this.ordersService.excludeBuyer(this.userService.currentUser.id, value, buyerEmail).subscribe(data => {
      if (data.isSuccess) {
        this.userService.showNotification = true;
        this.userService.notificationMessage = this.textService.currentText.optionChanged;
        this.userService.notificationTitle = this.textService.currentText.success;
      }
    });
  }

  public changeOrderlackList(value: any, orderId: any): void {
    let isFBM = false;
    if (this.userService.currentPage === 'fbm') {
       isFBM = true;
    }
    this.ordersService.excludeOrder(this.userService.currentUser.id, value, orderId, isFBM).subscribe(data => {
      if (data.isSuccess) {
        this.userService.showNotification = true;
        this.userService.notificationMessage = this.textService.currentText.optionChanged;
        this.userService.notificationTitle = this.textService.currentText.success;
      }
    });
  }

  public clear(): void {
    this.filters = {
      purchasedAfter: '',
      purchasedBefore: '',
      shippedAfter: '',
      shippedBefore: '',
      orderId: '',
      templatesId: '',
      buyerEmail: '',
      excludeTemplatesId: '',
      includeTemplatesId: ''
    };
  }

  public search(token?): void {
    const dataForSearch = {
      publicId: this.userService.currentUser.id,
      // templateId: this.filters.templateId ? this.filters.templateId : undefined,
      purchasedAfter:  this.filters.purchasedAfter ? `${this.filters.purchasedAfter.getFullYear()}-${this.filters.purchasedAfter.getMonth() + 1}-${this.filters.purchasedAfter.getDate()}` : undefined,
      purchasedBefore:  this.filters.purchasedBefore ? `${this.filters.purchasedBefore.getFullYear()}-${this.filters.purchasedBefore.getMonth() + 1}-${this.filters.purchasedBefore.getDate()}` : undefined,
      shippedAfter:  this.filters.shippedAfter ? `${this.filters.shippedAfter.getFullYear()}-${this.filters.shippedAfter.getMonth() + 1}-${this.filters.shippedAfter.getDate()}` : undefined,
      shippedBefore:  this.filters.shippedBefore ? `${this.filters.shippedBefore.getFullYear()}-${this.filters.shippedBefore.getMonth() + 1}-${this.filters.shippedBefore.getDate()}` : undefined,
      orderId: this.filters.orderId ? this.filters.orderId : undefined,
      buyerEmail: this.filters.buyerEmail ? this.filters.buyerEmail : undefined,
      excludeTemplatesId: this.filters.excludeTemplatesId ? this.filters.excludeTemplatesId : undefined,
      includeTemplatesId: this.filters.includeTemplatesId ? this.filters.includeTemplatesId : undefined,
      token: token ? token : undefined,
    }
    this.userService.loaderObesrver.next(true);
    this.templateService.getLastEmails(dataForSearch).subscribe(emails => {
      this.nextToken = emails.nextToken;
      if ((this.nextToken % 20) > 0) {
        this.isNextDisabled = true;
      } else {
        this.prevToken = emails.prevToken;
        this.isNextDisabled = false;
      }
      this.emailList = emails.lastEmailsArray;
      this.userService.loaderObesrver.next(false);
    });
  }

}
