import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { CouponsService } from '../../../services/coupons.service';
import { MatDialog } from '@angular/material/dialog';
import { CouponDialogComponent } from '../../dialogs/coupon-dialog/coupon-dialog.component';
import { GoogleAnalyticsEventsService } from '../../../services/google-analytics.service';
import { TextService } from "../../../services/text.service";
import { Functions } from "../../../services/functions";
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'ninja-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})

export class CouponsComponent implements OnInit {
  public coupons: any;
  public notificationMessage: any;
  public notificationTitle: any;

  constructor(public userService: UserService,
    public couponsService: CouponsService,
    public functions: Functions,
    public textService: TextService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public matDialog: MatDialog,
    public mixpanelService: MixpanelService,
  ) {
    this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    this.userService.loaderObesrver.next(true);
    this.couponsService.getCoupons(this.userService.currentUser.id).subscribe((data) => {
      this.coupons = data;
      this.userService.loaderObesrver.next(false);
    });
  }

  public getCouponCode(couponId: any, index: any): void {
    this.coupons[index].couponsLeft--;
    this.couponsService.getCouponCode(this.userService.currentUser.id, couponId).subscribe((data: any) => {
      this.userService.copyToClipboard(index, data.couponCode);
      this.notificationMessage = 'Coupon successfully copied to clipboard';
      this.notificationTitle = 'Coupon copied';
      this.userService.showNotification = true;
    }
    );
  }

  public deleteCoupon(couponId, i): void {
    this.couponsService.deleteCoupons(this.userService.currentUser.id, couponId).subscribe((deletedCoupon) => {
      if (deletedCoupon) {
        this.coupons.splice(i, 1);
      }
    });
  }

  public uploadCoupon(coupon: any, editMode: boolean): void {
    this.matDialog.open(CouponDialogComponent, {
      data: {
        'dialogFor': 'upload-coupon',
        'edit': editMode,
        'coupon': coupon
      },
    }).afterClosed().subscribe((result) => {
      if (result === 'edit') {
        this.couponsService.getCoupons(this.userService.currentUser.id).subscribe((data) => {
          this.coupons = data;
          this.notificationMessage = this.textService.currentText.couponEdited;
          this.notificationTitle = this.textService.currentText.success;
          this.userService.showNotification = true;
        });
      } else if (result === 'new') {
        this.couponsService.getCoupons(this.userService.currentUser.id).subscribe((data) => {
          this.coupons = data;
          this.notificationMessage = this.textService.currentText.couponCreated;
          this.notificationTitle = this.textService.currentText.couponCreatedTitle;
          this.userService.showNotification = true;
        });
      }
    });
  }
}
