import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {TextService} from '../../../../services/text.service';
import { MatDialog } from '@angular/material/dialog';
import {AccountPermissionsDialogComponent} from '../../../dialogs/account-permissions-dialog/account-permissions-dialog.component';

@Component({
  selector: 'ninja-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss']
})

export class UserPermissionsComponent implements OnInit {
  public vaList = [];
  public notificationMessage: any;
  public notificationTitle: any;
  constructor(public userService: UserService,
              public textService: TextService,
              public matDialog: MatDialog) {}

  public ngOnInit () {
    this.getVaList(true);
  }

  public getVaList (refreshList) {
    if (refreshList) {
      this.userService.loaderObesrver.next(true);
      this.userService.getVaList().subscribe(vaList => {
        this.vaList = vaList;
        this.userService.loaderObesrver.next(false);
      })
    }
  }

  public showCreateUserDialog(va?): void {
    this.matDialog.open(AccountPermissionsDialogComponent, {
      data: {
        'va': va,
      }
    }).afterClosed().subscribe((data) => {
      this.getVaList(data);
    });
  }

  public deleteVaUser(va?): void {
    this.userService.deleteVaUser(va.id).subscribe(data => {
      this.getVaList(true);
      this.notificationMessage = this.textService.currentText.deletePermission;
      this.notificationTitle = this.textService.currentText.success;
      this.userService.showNotification = true;
    })
  }
}
