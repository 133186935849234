import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ConstService} from '../../../../services/const-service';
import {Functions} from '../../../../services/functions';
import {Router} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {AutoResponseDialogComponent} from '../../../dialogs/auto-response-dialog/auto-response-dialog.component';
import {TextService} from '../../../../services/text.service';
import {FbService} from '../../../../services/fb.service';
import {ConfirmationDialogComponent} from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'ninja-auto-response',
  templateUrl: './auto-response.component.html',
  styleUrls: ['./auto-response.component.scss']
})

export class AutoResponseComponent implements OnInit {
  public isFilters: boolean;
  public autoReplyList: any;
  public currentAutoReplyList: any;
  public pages: any;
  public filters = {
    status: null,
    page: null,
    name: null,
    keyword: null
  };
  public firstTime = false;
  constructor(public matDialog: MatDialog,
              public constService: ConstService,
              public userService: UserService,
              public textService: TextService,
              public fbService: FbService,
              public ref: ChangeDetectorRef,
              public router: Router,
              public functions: Functions) {
  }

  public ngOnInit(): void {
    this.userService.loaderObesrver.next(true);
    this.fbService.getPagesFb(this.userService.currentUser.id).subscribe((data) => {
      this.pages = data;
      this.fbService.getAutoReply(this.userService.currentUser.id).subscribe((list) => {
        if (list.length === 0) {
          this.firstTime = true;
        }
        this.autoReplyList = list;
        this.currentAutoReplyList = JSON.parse(JSON.stringify(this.autoReplyList));
        this.userService.loaderObesrver.next(false);
      });
    });
  }

  public valueToggle(checker: boolean): string {
    return checker ? 'On' : 'Off';
  }

  public showAutoResponseDialog(data, editMode): void {
    this.matDialog.open(AutoResponseDialogComponent, {
      data: {
        data: data,
        editMode: editMode
      },
    }).afterClosed().subscribe((list) => {
      Array.isArray(list) ? this.currentAutoReplyList = list : null;
    });
  }

  public checkFilters(): void {
    this.currentAutoReplyList = JSON.parse(JSON.stringify(this.autoReplyList));
    if (this.filters.name) {
      this.liveSearchName();
    }
    if (this.filters.keyword) {
      this.liveSearchKeyword();
    }
    if (this.filters.status !== null) {
      this.filterByStatus();
    }
    if (this.filters.page) {
      this.filterByPage();
    }
  }

  public liveSearchName(): void {
    this.currentAutoReplyList = this.currentAutoReplyList.filter(this.liveSearchNameCallback.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchNameCallback(value: any): boolean {
    return value.ruleName.toLowerCase().indexOf(this.filters.name.toLowerCase()) !== -1;
  }

  public liveSearchKeyword(): void {
    this.currentAutoReplyList = this.currentAutoReplyList.filter(this.liveSearchKeywordCallback.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchKeywordCallback(value: any): boolean {
    return value.keyword.toLowerCase().indexOf(this.filters.keyword.toLowerCase()) !== -1;
  }

  public filterByStatus(): void {
    this.currentAutoReplyList = this.currentAutoReplyList.filter(this.isStatus.bind(this));
    this.ref.detectChanges();
  }

  public isStatus(value: any): boolean {
    return value.status === this.filters.status;
  }

  public filterByPage(): void {
    this.currentAutoReplyList = this.currentAutoReplyList.filter(this.isCorrectPage.bind(this));
    this.ref.detectChanges();
  }

  public isCorrectPage(value: any): boolean {
    return value.pageName.toLowerCase() === this.filters.page.toLowerCase();
  }

  public deleteAutoReply(ruleId): void {
    this.fbService.deleteAutoReply(this.userService.currentUser.id, ruleId).subscribe(res => {
      if (res.isSuccess) {
        for (let i = 0; this.currentAutoReplyList.length > i; i++) {
          if (this.currentAutoReplyList[i].ruleId === ruleId) {
            this.currentAutoReplyList.splice(i, 1);
          }
        }
        this.userService.showNotification = true;
        this.userService.notificationTitle = this.textService.currentText.success;
        this.userService.notificationMessage = this.textService.currentText.deleteAutoResponsSuccessText;
      }
    });
  }

  public clearFilter(): void {
    this.filters = {
      status: null,
      page: null,
      name: null,
      keyword: null
    };
    this.currentAutoReplyList = JSON.parse(JSON.stringify(this.autoReplyList));
    this.ref.detectChanges();
  }

  // public showOverwriteDialog(list) {
  //   let dataToSave = list.data;
  //   dataToSave.isEditMode = true;
  //   dataToSave.ruleId = list.ruleId;
  //   this.matDialog.open(ConfirmationDialogComponent, {
  //     data: {
  //       'dialogFor': 'auto-reply-overwrite',
  //       'ruleName': list.ruleName,
  //       'data': list.data
  //     }
  //   }).afterClosed().subscribe(res => {
  //     if (res) {
  //       this.fbService.saveRule(dataToSave).subscribe(result => {
  //         this.currentAutoReplyList = result
  //         this.userService.showNotification = true;
  //         this.userService.notificationTitle = this.textService.currentText.success;
  //         this.userService.notificationMessage = this.textService.currentText.saveResponseSuccess;
  //       });
  //     }
  //   });
  // }
}
