import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { UserPermissionsComponent } from './account/user-permissions/user-permissions.component';
import { LinkAccountsComponent } from './account/link-accounts/link-accounts.component';
import { BillingComponent } from './account/billing/billing.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { SuperUserComponent } from './super-user/super-user.component';
import { FbaComponent } from './automated-messaging/fba/fba.component';
import { FbmComponent } from './automated-messaging/fbm/fbm.component';
import { BulkComponent } from './automated-messaging/bulk/bulk.component';
import { LastMessagesSentComponent } from './automated-messaging/last-messages-sent/last-messages-sent.component';
import { AsinAlertsComponent } from './asin-alerts/asin-alerts.component';
import { FacebookNotificationsComponent } from './facebook-notifications/facebook-notifications.component';
import { OrdersComponent } from './orders/orders.component';
import { DownloadReportComponent } from './orders/download-report/download-report.component';
import { SendMessageComponent } from './orders/send-message/send-message.component';
import { CouponsComponent } from './coupons/coupons.component';
import { AffiliateComponent } from './affiliate/affiliate.component';
import { HijackedComponent } from './hijacked/hijacked.component';
import { MoneyBackComponent } from './money-back/money-back.component';
import { InviteFriendComponent } from './invite-friends/invite-friend.component';
import { AsinSpotComponent } from './asin-spot/asin-spot.component';
import { AddRegionComponent } from './add-region/add-region.component';
import { CanDeactivateGuard, CanActivateTeam } from './diactivate.guadr';
import { KeywordsOptimizerComponent } from './keywords-optimizer/keywords-optimizer.component';
import { SubscribersComponent } from './facebook-bot-messanger/subscribers/subscribers.component';
import { FacebookGrantAccessComponent } from './facebook-bot-messanger/facebook-grant-access/facebook-grant-access.component';
import { ConversationManagerComponent } from './facebook-bot-messanger/conversation-manager/conversation-manager.component';
import { SequencesComponent } from './facebook-bot-messanger/sequences/sequences.component';
import { FAQComponent } from './FAQ/FAQ.component';
import { CreateNewConversationComponent } from './facebook-bot-messanger/create-new-conversation/create-new-conversation.component';
import { AutoResponseComponent } from './facebook-bot-messanger/auto-response/auto-response.component';
import { BotMenuComponent } from './facebook-bot-messanger/bot-menu/bot-menu.componet';
import { CreateNewTemplateComponent } from './automated-messaging/create-new-template/create-new-template.component';
import { InvoicingComponent } from './invoicing/invoicing.component';
import { CreateNewInvoiceComponent } from './invoicing/create-new-invoice/create-new-invoice.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { AccountSettingsComponent } from './user-settings/account-settings/account-settings.component';
import { ProductSettingsComponent } from './user-settings/product-settings/product-settings.component';
import { ExpensesComponent } from './user-settings/expenses/expenses.component';
import { DashboardSchedulesComponent } from './dashboard-schedules/dashboard-schedules.component';
import { OrdersDashboardComponent } from './orders-dashboard/orders-dashboard.component';
//
const routes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivateChild: [CanActivateTeam],
        children: [
            {
                path: 'orders-dashboard', component: OrdersDashboardComponent,

            },
            {
                path: 'dashboard-schedules', component: DashboardSchedulesComponent,

            },
            {
                path: '', pathMatch: 'full', redirectTo: 'orders-dashboard'

            },
            { path: 'account/user-permissions', component: UserPermissionsComponent },
            { path: 'account/link-accounts', component: LinkAccountsComponent },
            { path: 'account/billing', component: BillingComponent },
            { path: 'upgrade', component: UpgradeComponent },
            { path: 'super-user', component: SuperUserComponent },
            { path: 'automated-messaging/fba', component: FbaComponent },
            { path: 'automated-messaging/fbm', component: FbmComponent },
            { path: 'automated-messaging/bulk', component: BulkComponent },
            { path: 'automated-messaging/last-messages-sent', component: LastMessagesSentComponent },
            { path: 'asin-alerts', component: AsinAlertsComponent },
            { path: 'facebook-notifications', component: FacebookNotificationsComponent },
            { path: 'orders', component: OrdersComponent },
            { path: 'orders/download-report', component: DownloadReportComponent },
            { path: 'orders/send-message', component: SendMessageComponent },
            { path: 'coupons', component: CouponsComponent },
            { path: 'affiliate', component: AffiliateComponent },
            { path: 'hijacked', component: HijackedComponent },
            { path: 'money-back', component: MoneyBackComponent },
            { path: 'invite-friend', component: InviteFriendComponent },
            { path: 'asin-tracker', component: AsinSpotComponent },
            { path: 'money-back', component: MoneyBackComponent },
            { path: 'add-region', component: AddRegionComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'keywords-optimizer', component: KeywordsOptimizerComponent },
            { path: 'facebook-bot-manager/subscribers', component: SubscribersComponent },
            { path: 'facebook-bot-manager/grant-access', component: FacebookGrantAccessComponent },
            { path: 'facebook-bot-manager/conversation-manager', component: ConversationManagerComponent },
            { path: 'facebook-bot-manager/sequences', component: SequencesComponent },
            { path: 'FAQ', component: FAQComponent },
            {
                path: 'facebook-bot-manager/conversation-manager/new-conversation',
                component: CreateNewConversationComponent
            },
            { path: 'facebook-bot-manager/auto-response', component: AutoResponseComponent },
            { path: 'facebook-bot-manager/menu', component: BotMenuComponent },
            {
                path: 'automated-messaging/add',
                component: CreateNewTemplateComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            { path: 'invoicing', component: InvoicingComponent },
            { path: 'invoicing/create-new-invoice', component: CreateNewInvoiceComponent },
            {
                path: 'user/settings', component: UserSettingsComponent, children: [
                    {
                        path: 'account-settings',
                        component: AccountSettingsComponent,
                        canDeactivate: [CanDeactivateGuard]
                    },
                    { path: 'product-settings', component: ProductSettingsComponent },
                    { path: 'expenses', component: ExpensesComponent }
                ]
            },
        ]
    }
];
//
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})

export class DashboardRoute {
}