<div class="item" *ngIf="data?.type === 'TXT' || data?.type === 'VARIABLE_TEXT'">
      <div class="message" >
          {{data.value || textService.currentText.enterText}}
        </div>
        <div class="sub-row" *ngIf="data.childs?.length > 0 && data.active">
            <ng-container *ngFor="let item of data.childs">
                <ninja-conversation-preview [data]="item" *ngIf="data.active">

                </ninja-conversation-preview>
            </ng-container>
        </div>
</div>
<div class="item inline" *ngIf="data?.type === 'QuickReply'">
  <div class="button-holder">
      <div class="single-button" [class.active-button]="data.active">
          {{data?.value || textService.currentText.enterText}}
      </div>
  </div>
    <div class="sub-col" *ngIf="data.active">
        <ng-container *ngFor="let item of data.childs">
            <ninja-conversation-preview [data]="item"> </ninja-conversation-preview>
        </ng-container>
    </div>
</div>
<div class="item" *ngIf="data?.type === 'IMAGE'">
      <div class="image">
          <img [src]="data.value ? data.value : 'http://via.placeholder.com/350x150'" alt="">
      </div>
    <div class="sub-col">
      <ng-container *ngFor="let item of data.childs">
        <ninja-conversation-preview [data]="item"> </ninja-conversation-preview>
      </ng-container>
    </div>
</div>
<div class="item" *ngIf="data?.type === 'template'">
      <div class="template">
          <div class="template-image">
              <img [src]="data.image ? data.image : 'http://via.placeholder.com/350x150'" alt="">
          </div>
          <div class="template-title">
              <div class="title">{{data?.templateTitle || textService.currentText.title}}</div>
              <div class="title sub">{{data?.templateSubTitle || textService.currentText.subTitle}}</div>
          </div>
          <div class="template-buttons">
              <ng-container *ngFor="let item of data.childs">
                  <div class="button">
                      {{item.name || textService.currentText.botButtonTitle}}
                  </div>
                  <div class="sub-col" *ngIf="item.active">
                      <ng-container *ngFor="let item of item.childs">
                          <ninja-conversation-preview [data]="item"></ninja-conversation-preview>
                    </ng-container>
                  </div>
              </ng-container>
          </div>
      </div>
</div>
