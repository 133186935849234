<div class="dashboard one-screen" [class.wide]="userService?.smallSidebar">
  <div class="dashboard-container justify-content-center">
    <!-- <div class="region-block" >
      <div class="region-item" *ngIf="constService.usRegion.indexOf(activatedRoute.snapshot.queryParams.id) !== -1">
        <img src="../../../../assets/images/united-states.svg" alt="">
        <h3>{{textService.currentText.AmazonNorthAmericaAccount}}</h3>
        <p>{{textService.currentText.amazonRegions}}</p>
        <input type="text"
               class="add-region-input"
               [class.input-disabled]="emailInputDisabled"
               [disabled]="emailInvalid || emailInputDisabled"
               [placeholder]="textService.currentText.amazonEmail"
               [autocomplete]="false"
               #usInput
               [class.has-danger]="emailInvalid">
        <button mat-button
                mat-raised-button
                (click)="checkInput(usInput, links.usLink)"
                class="primary-btn">
          {{textService.currentText.addRegion}}
        </button>
      </div>
      <div class="region-item" *ngIf="constService.euRegion.indexOf(activatedRoute.snapshot.queryParams.id) !== -1">
        <img src="../../../../assets/images/european-union.svg" alt="">
        <h3>{{textService.currentText.europianUnionAccount}}</h3>
        <p>{{textService.currentText.amazonRegions2}}</p>
        <input type="text"
               class="add-region-input"
               [class.input-disabled]="emailInputDisabled"
               [disabled]="emailInvalid || emailInputDisabled"
               [placeholder]="textService.currentText.amazonEmail"
               [autocomplete]="false"
               #euInput
               [class.has-danger]="emailInvalid">
        <button mat-button
                mat-raised-button
                (click)="checkInput(euInput, links.euLink)"
                class="primary-btn">
          {{textService.currentText.addRegion}}
        </button>
      </div>
      <div class="region-item" *ngIf="constService.feRegion.indexOf(activatedRoute.snapshot.queryParams.id) !== -1">
        <img src="../../../../assets/images/japan.svg" alt="">

        <h3>{{textService.currentText.AmazonJapanAccount}}</h3>
        <p>{{textService.currentText.amazonRegions3}}</p>
        <input type="text"
               class="add-region-input"
               [class.input-disabled]="emailInputDisabled"
               [disabled]="emailInvalid || emailInputDisabled"
               [placeholder]="textService.currentText.amazonEmail"
               [autocomplete]="false"
               [class.has-danger]="emailInvalid"
                #jpInput>
        <button mat-button
                mat-raised-button
        (click)="checkInput(jpInput, links.jpLink)"
                class="primary-btn">
          {{textService.currentText.addRegion}}
        </button>
      </div>
      <div class="region-item" *ngIf="constService.auRegion.indexOf(activatedRoute.snapshot.queryParams.id) !== -1">
        <img src="../../../../assets/images/australia.svg" alt="">

        <h3>{{textService.currentText.AmazonAustraliaAccount}}</h3>
        <p>{{textService.currentText.amazonRegions4}}</p>
        <input type="text"
               class="add-region-input"
               [class.input-disabled]="emailInputDisabled"
               [disabled]="emailInvalid || emailInputDisabled"
               [placeholder]="textService.currentText.amazonEmail"
               [autocomplete]="false"
               #auInput
               [class.has-danger]="emailInvalid">
        <button mat-button
                mat-raised-button
                (click)="checkInput(auInput, links.auLink)"
                class="primary-btn">
          {{textService.currentText.addRegion}}
        </button>
      </div>
    </div> -->
    <div class="choose-re">
      <div class="regions">
        <div class="region-item" *ngIf="constService.usRegion.indexOf(activatedRoute.snapshot.queryParams.id) !== -1">
          <div class="country-container">
            <img class="icon email" src="../../../../assets/images/northamerica.png" (click)="checkInput(usInput, links.AMAZON_COM)">
            <h3>{{textService.currentText.northAmericaAccount1}}</h3>
            <div class="marketplace first">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{textService.currentText.northAmericaMarketplaces}}</h5>
                  <ul>
                    <li (click)="checkInput(usInput, links.AMAZON_BRAZIL)">
                      <img src="../../../../assets/images/br1.png">
                      <span>com.br</span>
                    </li>
                    <li (click)="checkInput(usInput, links.AMAZON_CANADA)">
                      <img src="../../../../assets/images/ca.png">
                      <span>.ca</span>
                    </li>
                    <li (click)="checkInput(usInput, links.AMAZON_MEXICO)">
                      <img src="../../../../assets/images/mx.png">
                      <span>.com.mx</span>
                    </li>
                    <li (click)="checkInput(usInput, links.AMAZON_COM)">
                      <img src="../../../../assets/images/com.png">
                      <span>.com</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <input type="text" class="add-region-input" [class.input-disabled]="emailInputDisabled"
            [disabled]="emailInvalid || emailInputDisabled" [placeholder]="textService.currentText.amazonEmail"
            [autocomplete]="false" #usInput [class.has-danger]="emailInvalid">
        </div>
        <div class="region-item" *ngIf="constService.euRegion.indexOf(activatedRoute.snapshot.queryParams.id) !== -1">
          <div class="country-container">
            <img class="icon email" src="../../../../assets/images/europe.png" (click)="checkInput(euInput, links.AMAZON_UK)">
            <h3>{{textService.currentText.europianUnionAccount1}}</h3>
            <div class="marketplace second">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{textService.currentText.europianUnionMarketplaces}}</h5>
                  <ul>
                    <li (click)="checkInput(euInput, links.AMAZON_UAE)">
                      <img src="../../../../assets/images/ae.png">
                      <span>.ae</span>
                    </li>
                    <li (click)="checkInput(euInput, links.AMAZON_GERMANY)">
                      <img src="../../../../assets/images/de.png">
                      <span>.de</span>
                    </li>
                    <li (click)="checkInput(euInput, links.AMAZON_SPAIN)">
                      <img src="../../../../assets/images/es.png">
                      <span>.es</span>
                    </li>
                    <li (click)="checkInput(euInput, links.AMAZON_FRANCE)">
                      <img src="../../../../assets/images/fr.png">
                      <span>.fr</span>
                    </li>
                    <li (click)="checkInput(euInput, links.AMAZON_UK)">
                      <img src="../../../../assets/images/uk.png">
                      <span>.co.uk</span>
                    </li>
                    <li (click)="checkInput(euInput, links.AMAZON_INDIA)">
                      <img src="../../../../assets/images/in.png">
                      <span>.in</span>
                    </li>
                    <li (click)="checkInput(euInput, links.AMAZON_ITALY)">
                      <img src="../../../../assets/images/it.png">
                      <span>.it</span>
                    </li>
                    <li (click)="checkInput(euInput, links.AMAZON_NETHERLANDS)">
                      <img src="../../../../assets/images/nl1.png">
                      <span>.nl</span>
                    </li>
                    <li (click)="checkInput(euInput, links.AMAZON_SAUDI_ARABIA)">
                      <img src="../../../../assets/images/sa1.png">
                      <span>.sd</span>
                    </li>
                    <li (click)="checkInput(euInput, links.AMAZON_TURKEY)">
                      <img src="../../../../assets/images/tr1.png">
                      <span>.tr</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <input type="text" class="add-region-input" [class.input-disabled]="emailInputDisabled"
            [disabled]="emailInvalid || emailInputDisabled" [placeholder]="textService.currentText.amazonEmail"
            [autocomplete]="false" #euInput [class.has-danger]="emailInvalid">
        </div>
        <div class="region-item" *ngIf="constService.feRegion.indexOf(activatedRoute.snapshot.queryParams.id) !== -1">
          <div class="country-container">
            <img class="icon email" src="../../../../assets/images/fareast.png" (click)="checkInput(feInput, links.AMAZON_AUSTRALIA)">
            <h3>{{textService.currentText.farEastAccount}}</h3>
            <div class="marketplace third">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{textService.currentText.farEastMarketplaces}}</h5>
                  <ul>
                    <li (click)="checkInput(feInput, links.AMAZON_SINGAPOR)">
                      <img src="../../../../assets/images/sg1.png">
                      <span>.sg</span>
                    </li>
                    <li (click)="checkInput(feInput, links.AMAZON_JAPAN)">
                      <img src="../../../../assets/images/jp.png">
                      <span>.co.jp</span>
                    </li>
                    <li (click)="checkInput(feInput, links.AMAZON_AUSTRALIA)">
                      <img src="../../../../assets/images/au.png">
                      <span>.com.au</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <input type="text" class="add-region-input" [class.input-disabled]="emailInputDisabled"
            [disabled]="emailInvalid || emailInputDisabled" [placeholder]="textService.currentText.amazonEmail"
            [autocomplete]="false" #feInput [class.has-danger]="emailInvalid">
        </div>
      </div>
    </div>
  </div>
</div>
<ninja-notification [active]="userService.showNotification" [message]="notificationMessage" [title]="notificationTitle">
</ninja-notification>