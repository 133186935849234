import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';

@Injectable()

export class NotificationService extends UserService {

  public getNotificationsInfo(publicId: string): Observable<any> {
    return this.post('notification/get_notifications', {
      publicId: publicId
    });
  }

  public changeNotificationStatus(publicId: string, attribute: any, value: any): Observable<any> {
    return this.post('notification/save_notifications', {
      publicId: publicId,
      attribute: attribute,
      value: value
    });
  }

  public changeNotificationProducts(publicId: string, value: any, products: any): Observable<any> {
    return this.post('notification/save_notifications_products', {
      publicId: publicId,
      isAll: value,
      notificationProducts: products,
    });
  }

  public changeNotificationProductsTg(publicId: string, value: any, products: any): Observable<any> {
    return this.post('notification/save_notifications_products_tg', {
      publicId: publicId,
      tgIsAll: value,
      tgNotificationProducts: products,
    });
  }

  public changeSalesNumber(publicId: string, value: any): Observable<any> {
    return this.post('notification/save_number_sales', {
      publicId: publicId,
      gap: value,
    });
  }

  public changeSalesNumberTg(publicId: string, value: any): Observable<any> {
    return this.post('notification/save_number_sales_tg', {
      publicId: publicId,
      tgGap: value,
    });
  }

  public checkNotificationsData(publicId: string): Observable<any> {
    return this.post('notification/check_notifications_data', {
      publicId: publicId
    });
  }

  
}
