import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';

@Injectable()

export class InvoicingService extends UserService {

  public checkInvoiceAccess(publicId: string): Observable<any> {
    return this.post('invoicing/check_invoice_access', {
      publicId: publicId
    });
  }

  public invoiceAgreement(publicId: string): Observable<any> {
    return this.post('invoicing/grant_access', {
      publicId: publicId
    });
  }

  public getInvoices(publicId: string): Observable<any> {
    return this.post('invoicing/invoice_templates2', {
      publicId: publicId
    });
  }

  public getInvoice(publicId: string, invoiceTemplateId: any): Observable<any> {
    return this.post('invoicing/invoice_template', {
      publicId: publicId,
      invoiceTemplateId: invoiceTemplateId
    });
  }

  public saveIncoice(data: any): Observable<any> {
    return this.post('invoicing/save_invoice_template', data);
  }

  public deleteInvoice(publicId: string, invoiceTemplateId: any): Observable<any> {
    return this.post('invoicing/delete_invoice_template', {
      publicId: publicId,
      invoiceTemplateId: invoiceTemplateId
    });
  }

  public updateStatus(publicId: string, status: boolean, invoiceTemplateId: any): Observable<any> {
    return this.post('invoicing/update_status', {
      publicId: publicId,
      status: status,
      id: invoiceTemplateId
    });
  }

  public getOrderInfo(publicId: string, orderId: any, invoiceTemplateId: any): Observable<any> {
    return this.post('invoicing/order_info', {
      publicId: publicId,
      orderId: orderId,
      invoiceTemplateId: invoiceTemplateId
    });
  }

  public sendEmail(dataToSend): Observable<any> {
    return this.post('invoicing/send_email', dataToSend);
  }

}
