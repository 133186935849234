<div class="choose-re-container">
  <div class="choose-re">
    <div class="logo">
      <img src="assets/images/ninja-logo2.svg" alt="">
    </div>
    <h2>{{textService.currentText.addRegionTitle1}}</h2>
    <h4>{{textService.currentText.addRegionSubTitle4}}</h4>
    <h5>{{textService.currentText.addRegionSubTitle3}}</h5>
    <div class="regions">
      <div class="country-container">
        <!-- <a href="https://sellercentral.amazon.com/gp/mws/registration/register.html?id=9c1b9de0-68f5-4de2-b702-b49d6800bc61&returnPathAndParameters=%2Fregistration%3FwebApp_irpregistration%3D5488&Signature=yy4yZtbztEFnqelZuhABFIHmcckqXMyuqiVl%2FhL8uYk%3D&SignatureVersion=2&SignatureMethod=HmacSHA256&AWSAccessKeyId=AKIAJNAK6TZZVDZ2GDHQ"
          alt="USA">
          <img class="icon email" src="../../../../assets/images/northamerica.png">
          <h3>{{textService.currentText.northAmericaAccount1}}</h3>
        </a> -->
        <a (click)="navigateToAmazon(userLnks?.AMAZON_COM)" alt="australia">
          <img class="icon email" src="../../../../assets/images/northamerica.png">
          <h3>{{textService.currentText.northAmericaAccount1}}</h3>
        </a>
        <div class="marketplace">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">{{textService.currentText.northAmericaMarketplaces}}</h5>
              <ul>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_BRAZIL)">
                  <img src="../../../../assets/images/br1.png">
                  <span>com.br</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_CANADA)">
                  <img src="../../../../assets/images/ca.png">
                  <span>.ca</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_MEXICO)">
                  <img src="../../../../assets/images/mx.png">
                  <span>.com.mx</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_COM)">
                  <img src="../../../../assets/images/com.png">
                  <span>.com</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="country-container">
        <!-- <a href="https://sellercentral.amazon.co.uk/gp/mws/registration/register.html?id=37aa4964-203d-47eb-98fe-e79bdea1a68e&returnPathAndParameters=%2Fregistration%3FwebApp_irpregistration%3D5488&Signature=EHmmh1fMGtdUDclzRhbBrR4ir1WQVAJgp4wHP4prA%2F0%3D&SignatureVersion=2&SignatureMethod=HmacSHA256&AWSAccessKeyId=AKIAIOIDG6Z4JLCJZRBA"
          alt="United Kingdom">
          <img class="icon email" src="../../../../assets/images/europe.png">
          <h3>{{textService.currentText.europianUnionAccount1}}</h3>
        </a> -->
        <a (click)="navigateToAmazon(userLnks?.AMAZON_UK)" alt="australia">
          <img class="icon email" src="../../../../assets/images/europe.png">
          <h3>{{textService.currentText.europianUnionAccount1}}</h3>
        </a>
        <div class="marketplace">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">{{textService.currentText.europianUnionMarketplaces}}</h5>
              <ul>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_UAE)">
                  <img src="../../../../assets/images/ae.png">
                  <span>.ae</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_GERMANY)">
                  <img src="../../../../assets/images/de.png">
                  <span>.de</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_SPAIN)">
                  <img src="../../../../assets/images/es.png">
                  <span>.es</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_FRANCE)">
                  <img src="../../../../assets/images/fr.png">
                  <span>.fr</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_UK)">
                  <img src="../../../../assets/images/uk.png">
                  <span>.co.uk</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_INDIA)">
                  <img src="../../../../assets/images/in.png">
                  <span>.in</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_ITALY)">
                  <img src="../../../../assets/images/it.png">
                  <span>.it</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_NETHERLANDS)">
                  <img src="../../../../assets/images/nl1.png">
                  <span>.nl</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_SAUDI_ARABIA)">
                  <img src="../../../../assets/images/sa1.png">
                  <span>.sd</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_TURKEY)">
                  <img src="../../../../assets/images/tr1.png">
                  <span>.tr</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="country-container">
        <!-- <a href="https://sellercentral.amazon.com.au/gp/mws/registration/register.html?id=9ff5ee6f-d162-421a-b2da-70da7f7b26b0&returnPathAndParameters=%2Fregistration%3FwebApp_irpregistration%3D5488&Signature=pHZGwXFT4sZ%2FXW%2BavfpeY%2FeWt2UY%2FX3EoTeGRBTbjT0%3D&SignatureVersion=2&SignatureMethod=HmacSHA256&AWSAccessKeyId=AKIAIRT2ZU3NRWSM6JFA"
          alt="australia">
          <img class="icon email" src="../../../../assets/images/fareast.png">
          <h3>{{textService.currentText.farEastAccount}}</h3>
        </a> -->
        <a (click)="navigateToAmazon(userLnks?.AMAZON_AUSTRALIA)" alt="australia">
          <img class="icon email" src="../../../../assets/images/fareast.png">
          <h3>{{textService.currentText.farEastAccount}}</h3>
        </a>
        <div class="marketplace">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">{{textService.currentText.farEastMarketplaces}}</h5>
              <ul>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_SINGAPOR)">
                  <img src="../../../../assets/images/sg1.png">
                  <span>.sg</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_JAPAN)">
                  <img src="../../../../assets/images/jp.png">
                  <span>.co.jp</span>
                </li>
                <li (click)="navigateToAmazon(userLnks?.AMAZON_AUSTRALIA)">
                  <img src="../../../../assets/images/au.png">
                  <span>.com.au</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="regions">
      <div class="country-container">
        <img class="icon email"
             src="../../../../assets/images/united-states.svg">
        <h3>{{textService.currentText.northAmericaAccount}}</h3>
        <span>{{textService.currentText.amazonRegions}}</span>
        <input type="text"
               class="add-region-input us"
               [class.has-danger]="emailInvalid && currentRegion === 'us'"
               [disabled]="emailInvalid"
               [placeholder]="textService.currentText.amazonEmail"
               [autocomplete]="false"
               #usInput
               >
        <div class="form-group-button" (click)="test(usInput, 'us')">
          <a  (click)="navigateToAmazon(userLnks?.usLink, usInput)"
              mat-button>{{textService.currentText.choose}}</a>
        </div>
      </div>
      <div class="country-container">
        <img class="icon email"
             src="../../../../assets/images/european-union.svg">
        <h3>{{textService.currentText.europianUnionAccount}}</h3>
        <span>{{textService.currentText.amazonRegions2}}</span>
        <input type="text"
               class="add-region-input eu"
               [class.has-danger]="emailInvalid && currentRegion === 'eu'"
               [placeholder]="textService.currentText.amazonEmail"
               [disabled]="emailInvalid"
               [autocomplete]="false"
               #euInput
        >
        <div class="form-group-button" (click)="test(euInput, 'eu')">
          <a
              (click)="navigateToAmazon(userLnks?.euLink, euInput)"
              mat-button>{{textService.currentText.choose}}</a>
        </div>
      </div>
      <div class="country-container">
        <img class="icon email"
             src="../../../../assets/images/japan.svg">
        <h3>{{textService.currentText.AmazonJapanAccount}}</h3>
        <span>{{textService.currentText.amazonRegions3}}</span>
        <input type="text"
               class="add-region-input jp"
               [class.has-danger]="emailInvalid && currentRegion === 'jp'"
               [placeholder]="textService.currentText.amazonEmail"
               [disabled]="emailInvalid"
               [autocomplete]="false"
               #jpInput
        >
        <div class="form-group-button" (click)="test(jpInput, 'jp')">
          <a
              (click)="navigateToAmazon(userLnks?.jpLink, jpInput)"
              mat-button>{{textService.currentText.choose}}</a>
        </div>
      </div>
      <div class="country-container">
        <img class="icon email"
             src="../../../../assets/images/australia.svg">
        <h3>{{textService.currentText.AmazonAustraliaAccount}}</h3>
        <span>{{textService.currentText.amazonRegions4}}</span>
        <input type="text"
               class="add-region-input au"
               [class.has-danger]="emailInvalid && currentRegion === 'au'"
               [placeholder]="textService.currentText.amazonEmail"
               [disabled]="emailInvalid"
               [autocomplete]="false"
               #auInput
        >
        <div class="form-group-button" (click)="test(auInput, 'au')">
          <a  (click)="navigateToAmazon(userLnks?.auLink, auInput)"
              mat-button>{{textService.currentText.choose}}</a>
        </div>
      </div>
    </div> -->
    <div class="sing-out">
      <p>{{textService.currentText.orYouCan}} <span (click)="logOut()">{{textService.currentText.signOutHere}}</span>
      </p>
    </div>
  </div>
</div>