<div class="dashboard" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container">
    <div class="dashboard-header">
      <div class="head-container">
        <div class="left">
          <h2>{{textService.currentText.conversationManagerTitle}}</h2>
          <!--<div class="action-el">-->
            <!--<img src="../../../../assets/icons/play.svg" alt="">-->
            <!--<p>Watch short tutorial</p>-->
          <!--</div>-->
        </div>
        <div class="action-el">
          <p (click)="navigateToManager()">< Back to Conversation Manager</p>
        </div>
      </div>
      <div class="filters-row">
        <div class="filter-holder">
          <div class="label-name">
            <span>{{textService.currentText.name}} </span>
          </div>
          <input matInput
                 type="text"
                 [(ngModel)]="conversationName">
        </div>
        <div class="filter-holder custom-selects">
          <div class="label-name">
            <span>{{textService.currentText.page}}</span>
          </div>
          <div class="select-holder">
            <div (click)="functions.setDropDownPosition(allProductsSelect, 'allProducts')"
                 #allProductsSelect
                 [class.active]="allProducts.panelOpen"
                 class="select-wrapper">
              <mat-form-field>
                <mat-select shouldPlaceholderFloat="false"
                            panelClass="default-dropdown allProducts"
                            #allProducts
                            [(ngModel)]="fbPageId"
                            (selectionChange)="getTesterName()"
                            disableRipple
                            name="fbPageId">
                  <mat-option *ngFor="let select of fbPages" [value]="select.pageId">
                    {{ select.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="filter-holder custom-selects">
          <div class="label-name">
            <span>{{textService.currentText.triggerBy}}</span>
          </div>
          <div class="select-holder">
            <div (click)="functions.setDropDownPosition(allProductsSelect2, 'allProducts2')"
                 #allProductsSelect2
                 [class.active]="allProducts2.panelOpen"
                 class="select-wrapper">
              <mat-form-field>
                <mat-select shouldPlaceholderFloat="false"
                            panelClass="default-dropdown allProducts2"
                            #allProducts2
                            [(ngModel)]="triggerBy"
                            (selectionChange)="warninngForSpecText()"
                            disableRipple
                            name="fbPageId">
                  <mat-option *ngFor="let select of constService.triggersBy" [value]="select.viewValue">
                    {{ select.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="filter-holder" *ngIf="triggerBy === 'TEXT'">
          <div class="label-name">
            <span>{{textService.currentText.keyword}}</span>
          </div>
          <input matInput type="text" [(ngModel)]="triggerKeyword">
        </div>
        <div class="filter-holder">
          <div class="label-name">
            <span>{{textService.currentText.payload}}</span>
          </div>
          <input matInput type="text" [value]="currentId" disabled>
        </div>
        <div class="filter-holder custom-selects">
          <div class="label-name">
            <span>{{textService.currentText.tag}}</span>
          </div>
          <div class="select-holder">
          <div (click)="functions.setDropDownPosition(convTagSelect, 'convTag')"
               #convTagSelect
               [class.active]="convTag.panelOpen"
               class="select-wrapper">
            <mat-form-field>
              <mat-select shouldPlaceholderFloat="false"
                          [placeholder]="conversationTag?.tagName || 'Tag'"
                          panelClass="default-dropdown convTag"
                          #convTag
                          [(ngModel)]="conversationTag"
                          disableRipple
                          name="conversationTag">
                <mat-option [value]="undefined">

                </mat-option>
                <mat-option *ngFor="let select of userService.tags" [value]="select">
                  {{ select.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        </div>

        <!--<button mat-button mat-raised-button class="default-btn clear-btn" type="button">-->
          <!--Clear-->
          <!--all-->
        <!--</button>-->
        <!--<div class="opt-tooltip">-->
          <!--<span>?</span>-->
        <!--</div>-->
        <button mat-button
                mat-raised-button
                (click)="JSONChecker()"
                class="default-btn b-side__btn">
          {{textService.currentText.getJson}}
        </button>
        <!--<div class="opt-tooltip">-->
          <!--<span>?</span>-->
        <!--</div>-->
      </div>
    </div>
  </div>
  <div id="bot-editor"
       class="bottom-container bot"
       [class.fixed]="fixedPhone"
       [class.full-screen]="fullScreenMode">
    <div class="wrapper-left">
      <div class="dashboard-container left">
        <div class="conversation-list" [style.width.px]='width'>
          <div class="start">
            <h4>{{textService.currentText.start}}</h4>
          </div>
          <ninja-conversation-bot-menu-navigation
              [data]="fbService.conversation">
          </ninja-conversation-bot-menu-navigation>
        </div>
        <div class="conversation-constructor">
          <div class="dragger grabber">
            <img src="assets/icons/drag.svg" alt="" class="grabber">
          </div>
          <div class="bot-head">
            <div class="add-buttons">
              <button mat-button
                      (click)="addEvent(fbService.conversation, newTemplate(fbService.textConversationAction)); focusTextEl('.active')">
                <img src="assets/icons/bot-text.svg">
                <span>+ {{textService.currentText.text}}</span>
              </button>
              <button mat-button
                      (click)="addEvent(fbService.conversation, 'QuickReply'); focusTextEl('.active-button-text')">
                <img src="assets/icons/bot-btn.svg">
                + {{textService.currentText.button}}
              </button>
              <button mat-button
                      (click)="addEvent(fbService.conversation, newTemplate(fbService.imageConversationAction)); focusTextEl('.active')">
                <img src="assets/icons/bot-image.svg">
                + {{textService.currentText.image}}
              </button>
              <button mat-button
                      (click)="addEvent(fbService.conversation, newTemplate(fbService.templateConversationAction))">
                <img src="assets/icons/bot-template.svg">
                + {{textService.currentText.template}}
              </button>
              <button mat-button
                      (click)="addEvent(fbService.conversation, newTemplate(fbService.actionConversationAction))">
                <img src="assets/icons/bot-template.svg">
                + {{textService.currentText.action}}
              </button>
              <button mat-button
                      class="full-screen"
                      [class.enabled]="fullScreenMode"
                      (click)="fullScreenMode = !fullScreenMode">
                <img src="assets/icons/crossed-arrows.svg">
                {{textService.currentText.fullScreen}}
              </button>
                <button mat-button
                        (click)="saveFlag = true; saveConversation(false)"
                        class="test">
                    {{textService.currentText.test}}
                </button>
            </div>
              <div class="item-holder">
                  <mat-checkbox
                      [(ngModel)]="autoHumanize"
                      disableRipple>AutoHumanize Conversatuion</mat-checkbox>
                  <!--<div class="opt-tooltip">-->
                  <!--<span>?</span>-->
                  <!--</div>-->
              </div>
            <div class="start">
              <h4>{{textService.currentText.start}}</h4>
            </div>
          </div>
            <div class="scroll-holder">
              <div class="conversation-holder" #conversationHolder>
                <ng-container *ngFor="let option of fbService.conversation; let i = index">
                  <ninja-conversation-bot-option-constructor
                    (emitConversationData)="emitConversationData($event, i)"
                    (emitTextData)="emitTextData(option, $event, i)"
                    (setActiveEmitter)="setActive($event, i)"
                    (setImgUrl)="setImgUrl($event, i)"
                    (setActionData)="setActionData($event, i)"
                    (setTemplateData)="setTemplateData($event, i)"
                    [isActive]="option.active"
                    [currIndex]="i"
                    [checkNewEl]="checkNewEl"
                    [data]="option"></ninja-conversation-bot-option-constructor>
                </ng-container>
              </div>
            </div>
        </div>
      </div>
      <div class="bottom-line">
        <div class="buttons">
          <button mat-button class="blue" (click)="saveFlag = true; saveConversation(false)">{{textService.currentText.test}}</button>
          <button mat-button (click)="resetTester()">{{textService.currentText.addTester}}</button>
        </div>
        <div class="test">
            <div class="tester" (click)="resetTester()">
                <div class="tester-photo">
                  <img [src]="currentTester?.image || 'assets/icons/user-icon.svg'" alt="">
                </div>
                <div class="tester-description">
                  <span class="bold">Tester:</span>
                  {{currentTester?.firstName ? currentTester?.firstName : textService.currentText.withoutTesters}}
                </div>
              </div>
              <ng-container *ngIf="testOnThisPage ? testers?.length > 0 : embeddedTesters.length > 0">
                <button class="dots" [matMenuTriggerFor]="menu" ></button>
                <mat-menu xPosition="before"
                          yPosition="above"
                          class="testers-sub-menu"
                          #menu="matMenu"
                          [overlapTrigger]="false">
                  <ng-container *ngFor="let tester of testOnThisPage ? testers : embeddedTesters; let i = index">
                    <div class="tester"  (click)="setTester(tester)">
                      <div class="tester-photo">
                        <img [src]="tester?.image || 'assets/icons/user-icon.svg'" alt="">
                      </div>
                      <div class="tester-description">
                        <span class="bold">{{textService.currentText.tester}} {{i + 1}}{{': '}} &nbsp;</span>
                        {{tester?.firstName}} {{tester?.lastName }}
                      </div>
                      <div class="close-btn" (click)="showDeleteTesterConfirm(tester); $event.stopPropagation(); $event.preventDefault()">

                      </div>
                    </div>
                  </ng-container>
                </mat-menu>
              </ng-container>
          <div class="refresh-tester" (click)="getTesterName();">
            <img [class.loading]="testerLoading" src="assets/icons/icon-refresh.svg" alt="">
          </div>

        </div>
        <div class="link" (click)="this.saveConversation(null, true)">
          <a class="action">
            <img src="assets/icons/link.svg" alt="">
            <a>
              {{textService.currentText.botLinkToConversation}}
            </a>
          </a>
          <!--<div class="opt-tooltip">-->
            <!--<span>?</span>-->
          <!--</div>-->
        </div>
        <div class="buttons trigger-block">
          <span
              [class.active]="testOnThisPage"
              class="slide-button-text"
          >{{textService.currentText.testConv}}</span>
          <mat-slide-toggle
              disableRipple
              [checked]="testOnThisPage"
              (click)="testOnThisPage = !testOnThisPage; switchTesterList(); $event.stopPropagation(); $event.preventDefault()"
              [class.uncheckedStatus]="!testOnThisPage"
          >
          </mat-slide-toggle>
          <span
              [class.active]="!testOnThisPage"
              class="slide-button-text">{{textService.currentText.testConv2}}</span>
        </div>
        <div class="buttons">
          <button mat-button (click)="deleteAll()">{{textService.currentText.deleteAll}}</button>
          <button mat-button class="blue" (click)="saveConversation(true)">{{textService.currentText.saveAll}}</button>
        </div>
      </div>
    </div>
    <div class="dashboard-container right">
      <h2>{{textService.currentText.preview}}</h2>
      <div class="phone-holder">
        <div class="phone-preview">
          <div class="header">
            <p>{{textService.currentText.favStore}}</p>
          </div>
          <div class="chat-holder">
            <ng-container *ngFor="let previewItem of fbService.conversation">
                <ninja-conversation-preview [data]="previewItem">

                </ninja-conversation-preview>
            </ng-container>
          </div>
          <div class="footer">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ninja-notification [active]="userService.showNotification"
                    [message]="notificationMessage"
                    [title]="notificationTitle"
></ninja-notification>
<div class="fb-customerchat"
     logged_in_greeting="Click 'Chat Now' to test your conversation here"
     logged_out_greeting="Log in to test your conversation here"
     page_id="309813392833359"
     [ref]="refLink"
     theme_color="#7646ff">
</div>
