import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ConstService} from '../../../services/const-service';
import {Functions} from '../../../services/functions';
import {FormBuilder, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import {GoogleAnalyticsEventsService} from '../../../services/google-analytics.service';
import {TextService} from "../../../services/text.service";
import {AddAsinDialogComponent} from '../../dialogs/add-asin-dialog/add-asin-dialog.component';

@Component({
    selector: 'ninja-asin-spot',
    templateUrl: './asin-spot.component.html',
    styleUrls: ['./asin-spot.component.scss']
})

export class AsinSpotComponent implements OnInit {
    public date: any;
    public currentAsins: any;
    public errors: any = {};
    public errorMessage = 'This field is required';
    public asinChannels: any;

    constructor(public userService: UserService,
                public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
                public textService: TextService,
                public functions: Functions,
                public constService: ConstService,
                public matDialog: MatDialog,
                public fb: FormBuilder) {
    }

    public ngOnInit(): void {
        this.userService.loaderObesrver.next(true);
        this.asinChannels = JSON.parse(JSON.stringify(this.constService.salesChannels));
        this.asinChannels.splice(0, 1);
        this.userService.getAsinData(this.userService.currentUser.id).subscribe((data) => {
            this.currentAsins = data;
            this.userService.loaderObesrver.next(false);
        });
    }

    public deleteAsin(asin): void {
        this.userService.deleteAsinSopt(asin.asin, asin.marketPlace, asin.keyword).subscribe(data => {
            this.currentAsins = data;
        });
    }

    public checkAsin(asin): void {
        this.userService.checkAsinSpot(asin.asin, asin.marketPlace, asin.keyword);
    }

    public getAsinChartData(asin: string, keyword: string, marketplace: string) {
        return this.userService.get(`asin/chart_data?id=${this.userService.currentUser.id}&asin=${asin}&keyword=${keyword}&marketplace=${marketplace}`).subscribe();
    }

    public showLimitDialog(): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'asin-limit',
            },
        }).afterClosed().subscribe();
    }

    public showSuccessDialog(marketplace): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'asin-search-added',
                marketplace: marketplace
            },
        }).afterClosed().subscribe(() => {
            // this.clearFilters();
        });
    }

    public showMessageDialog(message): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'asin-message',
                message: message
            },
        }).afterClosed().subscribe();
    }


    public openAddAsinDialog(): void {
        this.matDialog.open(AddAsinDialogComponent, {
            data: {
                'dialogFor': 'asin-message'
            },
        }).afterClosed().subscribe(res => {
            if (res === 'update') {
                this.ngOnInit();
            }
        });
    }

}
