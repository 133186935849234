import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { GoogleAnalyticsEventsService } from '../../../services/google-analytics.service';
import { TextService } from '../../../services/text.service';
import { Functions } from "../../../services/functions";
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'ninja-hijacked',
  templateUrl: './hijacked.component.html',
  styleUrls: ['./hijacked.component.scss']
})

export class HijackedComponent implements OnInit {
  public hjList: any;
  public hjListPaginator = [];
  public pageSizeOptions = [100];
  public pageSize = 100;

  constructor(
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public userService: UserService,
    public functions: Functions,
    public textService: TextService,
    public mixpanelService: MixpanelService) {
      this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    this.userService.loaderObesrver.next(true);
    this.userService.getHijacked().subscribe((list) => {
      this.hjList = list;
      if (list.length < 100) {
        this.pageSize = list.length;
      }
      this.getUpdatePaginator(0);
      this.userService.loaderObesrver.next(false);
    });
  }

  public getUpdatePaginator(index) {
    if (this.hjList.length < index * 100) {
      index = 1;
    }
    const start = index * (this.pageSize - 1);
    const end = this.hjList.length > 99 ? start + (this.pageSize - 1) : this.hjList.length;
    this.hjListPaginator = this.hjList.slice(start, end);
    this.hjListPaginator.forEach(element => {
      let marketPlaceChangeIcon = element.channel.split(".");
      let mpImage = 'assets/images/noImage.png';
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "com") {
        mpImage = 'assets/images/marketplace.png'
      }
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "ca") {
        mpImage = 'assets/images/flag_canada.svg'
      }
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "com" && marketPlaceChangeIcon[2] == "mx") {
        mpImage = 'assets/images/flag_mexico.svg'
      }
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "co" && marketPlaceChangeIcon[2] == "uk") {
        mpImage = 'assets/images/flag_uk.svg'
      }
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "fr") {
        mpImage = 'assets/images/flag_france.svg'
      }
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "de") {
        mpImage = 'assets/images/flag_germany.svg'
      }
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "it") {
        mpImage = 'assets/images/flag_italy.svg'
      }
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "es") {
        mpImage = 'assets/images/flag_spain.svg'
      }
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "co" && marketPlaceChangeIcon[2] == "jp") {
        mpImage = 'assets/images/japan.svg'
      }
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "com" && marketPlaceChangeIcon[2] == "au") {
        mpImage = 'assets/images/australia.svg'
      }
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "in") {
        mpImage = 'assets/images/india.svg'
      }
      if (marketPlaceChangeIcon && marketPlaceChangeIcon[1] == "ae") {
        mpImage = 'assets/images/UAE_Flag.svg'
      }
      element.channel = mpImage;
      console.log(element);
    });

  }
}
