<div class="inline-edit" >
    <div *ngIf='!editMode' class='inline-edit' alt="{{data}}"> {{ data  }}</div>
    <div *ngIf='editMode' class="inline-edit-editmode">
        <input #inlineEdit  [(ngModel)]='data' class='cellInput'
        autofocus  type='edit' (keydown.enter)='onOK();' maxlength="10" />
    <div class="inline-edit-buttons">
        <button mat-icon-button class="inline-edit-ok" (click)="onOK();"></button>
        <button mat-icon-button class="inline-edit-cancel" (click)="editMode=false"></button>
    </div>
    </div>
</div>