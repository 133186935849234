import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfitbreakdownpopupComponent } from '../popups/profitbreakdownpopup/profitbreakdownpopup.component';
import { ListComponent } from '../popups/list/list.component';
import * as moment from 'moment';
import { ErrorsComponent } from '../popups/errors/errors.component';
import { graphic } from 'echarts';
import { ShareService } from 'src/app/services/share.service';
import { UserService } from 'src/app/services/user.service';
import { StorageBrowser } from 'src/app/services/storage.browser';
import { MatMenuTrigger } from '@angular/material/menu';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'app-profitsection',
  templateUrl: './profitsection.component.html',
  styleUrls: ['./profitsection.component.scss']
})
export class ProfitsectionComponent implements OnInit {
  @Input() timeframeData: any;
  @Input() defaultData: any;
  @Input() formData: any;
  @Input() boxNumber: any;
  service: any;
  title: string;
  subTitle: string;
  @ViewChild('customDate', { static: true }) customDate: MatMenuTrigger;
  currencySymbol: any;
  updateUnitsSoldChartOptions: any;
  updateRevenueChartOptions: any;
  customrangeshow: boolean = false;
  customRangeFormData: any;
  @Output() updatedNewData = new EventEmitter<string>();
  @ViewChild(DaterangepickerComponent, { static: true }) pickerInline: DaterangepickerComponent;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'Month To Date': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Year To Date': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };
  customDateType = ["TODAY", "YESTERDAY", "LAST_7_DAYS", "LAST_30_DAYS", "MONTH_TO_DATE", "LAST_MONTH", "YEAR_TO_DATE", "LAST_YEAR", "CUSTOM"];
  disableProfitBreakdownDialog: boolean = false;
  //@ViewChild("unitssoldchart", { static: true }) unitssoldchart: ElementRef;
  @ViewChild("revenuechart", { static: true }) revenuechart: ElementRef;
  sharedChartOptions: any = {
    responsive: true
  };
  maxUnitSoldVal: any = [];
  // Units Sold Chart 2
  unitsSoldChartOptions: any = Object.assign({
    color: ['#0fc3f0'],
    grid: {
      top: "1px",
      bottom: "1px",
      height: "80px"
    },
    xAxis: {
      type: 'category',
      data: [],
      show: false
    },
    yAxis: {
      type: 'value',
      show: false
    },
    series: [
      { // For shadow
        type: 'bar',
        // itemStyle: {
        //   color: new graphic.LinearGradient(
        //     0, 0, 0, 1,
        //     [
        //       { offset: 0, color: '#F4FFFF' },
        //       { offset: 0.5, color: '#DBFFFF' },
        //       { offset: 1, color: '#C2FFFF' }
        //     ]
        //   )
        // },
        itemStyle: {
          color: new graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#FFFFFF' },
              { offset: 0.5, color: '#FFFFFF' },
              { offset: 1, color: '#FFFFFF' }
            ]
          )
        },
        barGap: '-100%',
        barCategoryGap: '40%',
        data: [],
        animation: false
      }, {
        itemStyle: {
          color: new graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#5ee2a0' },
              { offset: 1, color: 'rgba(94, 226, 160, 0.5)' }
            ]
          ),
        },
        data: [],
        type: 'bar'
      }],
    tooltip: {
      position: function (canvasMousePos, params, tooltipDom, rect, sizes) {
        var margin = 10; // How far away from the mouse should the tooltip be
        var overflowMargin = 5; // If no satisfactory position can be found, how far away from the edge of the window should the tooltip be kept
        var canvasRect = tooltipDom.parentElement.getElementsByTagName('canvas')[0].getBoundingClientRect();
        var mouseX = canvasMousePos[0] + canvasRect.x;
        var mouseY = canvasMousePos[1] + canvasRect.y;
        var tooltipWidth = sizes.contentSize[0];
        var tooltipHeight = sizes.contentSize[1];
        var xPos = mouseX + margin;
        var yPos = mouseY - margin - tooltipHeight;
        if (xPos + tooltipWidth >= document.documentElement.clientWidth) {
          xPos = mouseX - margin - tooltipWidth;
          if (xPos <= 0)
            xPos = overflowMargin;
        }
        if (yPos <= 0) {
          yPos = mouseY + margin;
          if (yPos + tooltipHeight >= document.documentElement.clientHeight)
            yPos = overflowMargin;
        }
        return [xPos - canvasRect.x, yPos - canvasRect.y]
      },
      trigger: 'axis',
      backgroundColor: '#fff',
      textStyle: { color: '#4d4f5c' },
      axisPointer: {
        animation: true
      },
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      formatter: function (params) {
        return params[0].axisValue;
      }
    },
    dataZoom: [
      {
        type: "inside"
      }
    ],
  });

  // Revenue Chart
  revenueChartOptions: any = Object.assign({
    color: ['#0fc3f0'],
    grid: {
      top: "1px",
      bottom: "1px",
      height: "80px"
    },
    xAxis: {
      type: 'category',
      data: [],
      show: false
    },
    yAxis: {
      type: 'value',
      show: false
    },
    series: [
      { // For shadow
        type: 'bar',
        // itemStyle: {
        //   color: new graphic.LinearGradient(
        //     0, 0, 0, 1,
        //     [
        //       { offset: 0, color: '#F4FFFF' },
        //       { offset: 0.5, color: '#DBFFFF' },
        //       { offset: 1, color: '#C2FFFF' }
        //     ]
        //   )
        // },
        itemStyle: {
          color: new graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#FFFFFF' },
              { offset: 0.5, color: '#FFFFFF' },
              { offset: 1, color: '#FFFFFF' }
            ]
          )
        },
        barGap: '-100%',
        barCategoryGap: '40%',
        data: [],
        animation: false
      },
      {
        itemStyle: {
          color: new graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#5ee2a0' },
              { offset: 1, color: 'rgba(94, 226, 160, 0.5)' }
            ]
          ),
        },
        data: [],
        type: 'bar'
      }],
    tooltip: {
      position: function (canvasMousePos, params, tooltipDom, rect, sizes) {
        var margin = 10; // How far away from the mouse should the tooltip be
        var overflowMargin = 5; // If no satisfactory position can be found, how far away from the edge of the window should the tooltip be kept
        var canvasRect = tooltipDom.parentElement.getElementsByTagName('canvas')[0].getBoundingClientRect();
        var mouseX = canvasMousePos[0] + canvasRect.x;
        var mouseY = canvasMousePos[1] + canvasRect.y;
        var tooltipWidth = sizes.contentSize[0];
        var tooltipHeight = sizes.contentSize[1];
        var xPos = mouseX + margin;
        var yPos = mouseY - margin - tooltipHeight;
        // if (xPos + tooltipWidth <= document.documentElement.clientWidth) {
        xPos = mouseX - margin - tooltipWidth;
        if (xPos <= 0)
          xPos = overflowMargin;
        // }
        if (yPos <= 0) {
          yPos = mouseY + margin;
          if (yPos + tooltipHeight >= document.documentElement.clientHeight)
            yPos = overflowMargin;
        }
        return [xPos - canvasRect.x, yPos - canvasRect.y]
      },
      trigger: 'axis',
      backgroundColor: '#fff',
      textStyle: { color: '#4d4f5c' },
      axisPointer: {
        animation: true
      },
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      formatter: function (params) {
        return params[0].axisValue;
      }
    },
    dataZoom: [
      {
        type: "inside"
      }
    ],
  });

  constructor(private dialog: MatDialog, public mixpanelService: MixpanelService, private shareService: ShareService, public userService: UserService, public storageBrowser: StorageBrowser) {
    this.mixpanelService.init(null);
  }

  ngOnInit() {
    this.currencySymbol = this.storageBrowser.get('$Currency');
    if (this.timeframeData) {
      if (this.timeframeData.startDate !== undefined) {
        this.pickerInline.setStartDate(moment(this.timeframeData.startDate));
      }
      if (this.timeframeData.endDate !== undefined) {
        if (this.timeframeData.startDate === undefined) {
          this.pickerInline.setStartDate(moment(this.timeframeData.endDate));
        }
        this.pickerInline.setEndDate(moment(this.timeframeData.endDate));
      }
      if (this.timeframeData.timeFrame == 'LAST_7_DAYS') {
        this.pickerInline.chosenRange = 'Last 7 Days';
      } else if (this.timeframeData.timeFrame == 'LAST_30_DAYS') {
        this.pickerInline.chosenRange = 'Last 30 Days';
      } else if (this.timeframeData.timeFrame == 'TODAY') {
        this.pickerInline.chosenRange = 'Today';
      } else if (this.timeframeData.timeFrame == 'YESTERDAY') {
        this.pickerInline.chosenRange = 'Yesterday';
      } else if (this.timeframeData.timeFrame == 'MONTH_TO_DATE') {
        this.pickerInline.chosenRange = 'Month To Date';
      } else if (this.timeframeData.timeFrame == 'LAST_MONTH') {
        this.pickerInline.chosenRange = 'Last Month';
      } else if (this.timeframeData.timeFrame == 'YEAR_TO_DATE') {
        this.pickerInline.chosenRange = 'Year To Date';
      } else if (this.timeframeData.timeFrame == 'LAST_YEAR') {
        this.pickerInline.chosenRange = 'Last Year';
      }
      this.getTimeFrameTitle(this.timeframeData);
      if (this.timeframeData.unitsData && this.timeframeData.revenueData) {
        this.unitsSoldChartOptions.xAxis.data = this.timeframeData.unitsData.diagram.map((item) => { //item.fromDate);
          let changeVal = 0;
          if (item.change) {
            changeVal = item.change;
          }
          let changeDiff = item.value - item.valuePrev;
          let dynCls = '#4ad991';
          let arrowDef = 'trending_up';
          if (changeDiff < 0) {
            dynCls = '#ff6565';
            arrowDef = 'trending_down';
          }
          let fromDate = this.timeframeData.isSingleDay ? this.changeDateFormate(item.fromDate, 'DD/MM/YY HH:mm') : this.changeDateFormate(item.fromDate, 'DD/MM/YY');
          // let toDate = this.changeDateFormate(item.toDate, 'DD/MM/YY HH:mm');
          let toDate = this.changeDateFormate(item.toDate, 'HH:mm');
          let fromdatePrev = this.timeframeData.isSingleDay ? this.changeDateFormate(item.fromdatePrev, 'DD/MM/YY HH:mm') : this.changeDateFormate(item.fromdatePrev, 'DD/MM/YY');
          // let toDatePrev = this.changeDateFormate(item.toDatePrev, 'DD/MM/YY HH:mm');
          let toDatePrev = this.changeDateFormate(item.toDatePrev, 'HH:mm');
          //item.value;
          if (this.timeframeData.isSingleDay) {
            return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Units Sold</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td align="left" class="pr-4">' + fromDate + ' - ' + toDate + '</td><td align="right">' + this.changeNumberFormat(item.value) + '</td></tr><tr style="color:#a5a4bf"><td align="left">' + fromdatePrev + ' - ' + toDatePrev + '</td><td align="right">' + this.changeNumberFormat(item.valuePrev) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + this.changeNumberFormat(changeVal) + '%</span></td><td align="right" style="color:' + dynCls + '">' + this.changeNumberFormat(item.value - item.valuePrev) + '</td></tr></table>';
          }
          return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold" class="pr-4">Units Sold</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td align="left" class="pr-4">' + fromDate + '</td><td align="right">' + this.changeNumberFormat(item.value) + '</td></tr><tr style="color:#a5a4bf"><td align="left">' + fromdatePrev + '</td><td align="right">' + this.changeNumberFormat(item.valuePrev) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + this.changeNumberFormat(changeVal) + '%</span></td><td align="right" style="color:' + dynCls + '">' + this.changeNumberFormat(item.value - item.valuePrev) + '</td></tr></table>';
        });

        this.unitsSoldChartOptions.series[1].data = this.timeframeData.unitsData.diagram.map((item) => item.value);

        if (this.unitsSoldChartOptions.series[1].data.length > 0) {
          let maxUnitVal = this.unitsSoldChartOptions.series[1].data.reduce((a, b) => Math.max(a, b));
          for (var i = 0; i < this.unitsSoldChartOptions.series[1].data.length; i++) {
            this.unitsSoldChartOptions.series[0].data.push(maxUnitVal);
          }
        }

        this.revenueChartOptions.xAxis.data = this.timeframeData.revenueData.diagram.map((item) => { //item.fromDate);
          let changeVal = 0;
          if (item.change) {
            changeVal = item.change;
          }
          let changeDiff = item.value - item.valuePrev;
          let dynCls = '#4ad991';
          let arrowDef = 'trending_up';
          if (changeDiff < 0) {
            dynCls = '#ff6565';
            arrowDef = 'trending_down';
          }
          let fromDate = this.timeframeData.isSingleDay ? this.changeDateFormate(item.fromDate, 'DD/MM/YY HH:mm') : this.changeDateFormate(item.fromDate, 'DD/MM/YY');
          // let toDate = this.changeDateFormate(item.toDate, 'DD/MM/YY HH:mm');
          let toDate = this.changeDateFormate(item.toDate, 'HH:mm');
          let fromdatePrev = this.timeframeData.isSingleDay ? this.changeDateFormate(item.fromdatePrev, 'DD/MM/YY HH:mm') : this.changeDateFormate(item.fromdatePrev, 'DD/MM/YY');
          // let toDatePrev = this.changeDateFormate(item.toDatePrev, 'DD/MM/YY HH:mm');
          let toDatePrev = this.changeDateFormate(item.toDatePrev, 'HH:mm');
          //return item.value;
          if (this.timeframeData.isSingleDay) {
            return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold" class="pr-4">Units Revenue</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td class="pr-4" align="left">' + fromDate + ' - ' + toDate + '</td><td align="right">' + this.getCurrencySymbol(this.changeNumberFormat(item.value)) + '</td></tr><tr style="color:#a5a4bf"><td align="left">' + fromdatePrev + ' - ' + toDatePrev + '</td><td align="right">' + this.getCurrencySymbol(this.changeNumberFormat(item.valuePrev)) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + this.changeNumberFormat(changeVal) + '%</span></td><td align="right" style="color:' + dynCls + '">' + this.getCurrencySymbol(this.changeNumberFormat(item.value - item.valuePrev)) + '</td></tr></table>';
          }
          return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold" class="pr-4">Units Revenue</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td align="left">' + fromDate + '</td><td align="right">' + this.getCurrencySymbol(this.changeNumberFormat(item.value)) + '</td></tr><tr style="color:#a5a4bf"><td align="left">' + fromdatePrev + '</td><td align="right">' + this.getCurrencySymbol(this.changeNumberFormat(item.valuePrev)) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + this.changeNumberFormat(changeVal) + '%</span></td><td align="right" style="color:' + dynCls + '">' + this.getCurrencySymbol(this.changeNumberFormat(item.value - item.valuePrev)) + '</td></tr></table>';
        });

        this.revenueChartOptions.series[1].data = this.timeframeData.revenueData.diagram.map((item) => item.value);

        if (this.revenueChartOptions.series[1].data.length > 0) {
          let marevenueVal = this.revenueChartOptions.series[1].data.reduce((a, b) => Math.max(a, b));
          for (var i = 0; i < this.revenueChartOptions.series[1].data.length; i++) {
            this.revenueChartOptions.series[0].data.push(marevenueVal);
          }
        }

        if (this.defaultData.timeFrame == "secondTimeFrame") {
          this.unitsSoldChartOptions.series[1].itemStyle = {
            color: new graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#55d8fe' },
                { offset: 1, color: 'rgba(85, 216, 254, 0.5)' }
              ]
            ),
          }
          this.revenueChartOptions.series[1].itemStyle = {
            color: new graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#55d8fe' },
                { offset: 1, color: 'rgba(85, 216, 254, 0.5)' }
              ]
            ),
          }
        } else if (this.defaultData.timeFrame == "thirdTimeFrame") {
          this.unitsSoldChartOptions.series[1].itemStyle = {
            color: new graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#935ee2' },
                { offset: 1, color: 'rgba(147, 94, 226, 0.5)' }
              ]
            ),
          }
          this.revenueChartOptions.series[1].itemStyle = {
            color: new graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#935ee2' },
                { offset: 1, color: 'rgba(147, 94, 226, 0.5)' }
              ]
            ),
          }
        }

      }
    }
    // this.service = this.shareService.currentDate.subscribe(data => this.formData = data);
  }

  getTimeFrameTitle(timeFrameData) {
    if (timeFrameData.timeFrame == "TODAY") {
      this.title = 'TODAY';
      this.checkYearlyCompare('Yesterday');
    } else if (timeFrameData.timeFrame == "YESTERDAY") {
      this.title = 'Yesterday';
      this.checkYearlyCompare('Previous Day');
    } else if (timeFrameData.timeFrame == "LAST_7_DAYS") {
      this.title = '7 DAYS';
      this.checkYearlyCompare('Previous 7 DAYS');
    } else if (timeFrameData.timeFrame == "LAST_30_DAYS") {
      this.title = 'Last 30 DAYS';
      this.checkYearlyCompare('Previous 30 DAYS');
    } else if (timeFrameData.timeFrame == "LAST_MONTH") {
      this.title = 'Last Month';
      this.checkYearlyCompare('Previous Month');
    } else {
      this.title = moment(timeFrameData.startDate).format("DD/MM/YY") + " - " + moment(timeFrameData.endDate).format("DD/MM/YY");
      this.subTitle = moment(timeFrameData.startPrevious).format("DD/MM/YY") + " - " + moment(timeFrameData.endPrevious).format("DD/MM/YY");
    }
  }

  checkYearlyCompare(subTitle) {
    if (this.formData.yearlyCompare) {
      this.subTitle = 'Same date a year ago';
    } else {
      this.subTitle = subTitle;
    }
  }

  updateChart() {
    this.updateUnitsSoldChartOptions = {
      xAxis: {
        data: this.timeframeData.unitsData.diagram.map((item) => { //item.fromDate);
          let changeVal = 0;
          if (item.change) {
            changeVal = item.change;
          }
          let changeDiff = item.value - item.valuePrev;
          let dynCls = '#4ad991';
          let arrowDef = 'trending_up';
          if (changeDiff < 0) {
            dynCls = '#ff6565';
            arrowDef = 'trending_down';
          }
          let fromDate = this.timeframeData.isSingleDay ? this.changeDateFormate(item.fromDate, 'DD/MM/YY HH:mm') : this.changeDateFormate(item.fromDate, 'DD/MM/YY');
          let toDate = this.changeDateFormate(item.toDate, 'HH:mm');
          // let toDate = this.changeDateFormate(item.toDate, 'DD/MM/YY hh:mm');
          let fromdatePrev = this.timeframeData.isSingleDay ? this.changeDateFormate(item.fromdatePrev, 'DD/MM/YY HH:mm') : this.changeDateFormate(item.fromdatePrev, 'DD/MM/YY');
          // let toDatePrev = this.changeDateFormate(item.toDatePrev, 'DD/MM/YY hh:mm');
          let toDatePrev = this.changeDateFormate(item.toDatePrev, 'HH:mm');
          //item.value;
          // return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Units Sold</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td>' + fromDate + ' - ' + toDate + '</td><td>' + this.changeNumberFormat(item.value) + '</td></tr><tr style="color:#a5a4bf"><td>' + fromdatePrev + ' - ' + toDatePrev + '</td><td>' + this.changeNumberFormat(item.valuePrev) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + this.changeNumberFormat(changeVal) + '%</span></td><td style="color:' + dynCls + '">' + this.changeNumberFormat(item.value - item.valuePrev) + '</td></tr></table>';
          if (this.timeframeData.isSingleDay) {
            return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Units Sold</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td align="left" class="pr-4">' + fromDate + ' - ' + toDate + '</td><td align="right">' + this.changeNumberFormat(item.value) + '</td></tr><tr style="color:#a5a4bf"><td align="left">' + fromdatePrev + ' - ' + toDatePrev + '</td><td align="right">' + this.changeNumberFormat(item.valuePrev) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + this.changeNumberFormat(changeVal) + '%</span></td><td align="right" style="color:' + dynCls + '">' + this.changeNumberFormat(item.value - item.valuePrev) + '</td></tr></table>';
          }
          return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold" class="pr-4">Units Sold</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td align="left" class="pr-4">' + fromDate + '</td><td align="right">' + this.changeNumberFormat(item.value) + '</td></tr><tr style="color:#a5a4bf"><td align="left">' + fromdatePrev + '</td><td align="right">' + this.changeNumberFormat(item.valuePrev) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + this.changeNumberFormat(changeVal) + '%</span></td><td align="right" style="color:' + dynCls + '">' + this.changeNumberFormat(item.value - item.valuePrev) + '</td></tr></table>';
        })
      },
      series: [
        {
          data: [],
        },
        {
          data: this.timeframeData.unitsData.diagram.map((item) => item.value)
        }
      ]
    };

    if (this.updateUnitsSoldChartOptions.series[1].data.length > 0) {
      let maxUnitVal = this.updateUnitsSoldChartOptions.series[1].data.reduce((a, b) => Math.max(a, b));
      for (var i = 0; i < this.updateUnitsSoldChartOptions.series[1].data.length; i++) {
        this.updateUnitsSoldChartOptions.series[0].data.push(maxUnitVal);
      }
    }

    this.updateRevenueChartOptions = {
      xAxis: {
        data: this.timeframeData.revenueData.diagram.map((item) => { //item.fromDate);
          let changeVal = 0;
          if (item.change) {
            changeVal = item.change;
          }
          let changeDiff = item.value - item.valuePrev;
          let dynCls = '#4ad991';
          let arrowDef = 'trending_up';
          if (changeDiff < 0) {
            dynCls = '#ff6565';
            arrowDef = 'trending_down';
          }
          let fromDate = this.timeframeData.isSingleDay ? this.changeDateFormate(item.fromDate, 'DD/MM/YY HH:mm') : this.changeDateFormate(item.fromDate, 'DD/MM/YY');
          // let toDate = this.changeDateFormate(item.toDate, 'DD/MM/YY hh:mm');
          let toDate = this.changeDateFormate(item.toDate, 'HH:mm');
          let fromdatePrev = this.timeframeData.isSingleDay ? this.changeDateFormate(item.fromdatePrev, 'DD/MM/YY HH:mm') : this.changeDateFormate(item.fromdatePrev, 'DD/MM/YY');
          // let toDatePrev = this.changeDateFormate(item.toDatePrev, 'DD/MM/YY hh:mm');
          let toDatePrev = this.changeDateFormate(item.toDatePrev, 'HH:mm');
          //return item.value;
          // return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Units Revenue</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td>' + fromDate + ' - ' + toDate + '</td><td>' + this.getCurrencySymbol(this.changeNumberFormat(item.value)) + '</td></tr><tr style="color:#a5a4bf"><td>' + fromdatePrev + ' - ' + toDatePrev + '</td><td>' + this.getCurrencySymbol(this.changeNumberFormat(item.valuePrev)) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + this.changeNumberFormat(changeVal) + '%</span></td><td style="color:' + dynCls + '">' + this.getCurrencySymbol(this.changeNumberFormat(item.value - item.valuePrev)) + '</td></tr></table>';
          if (this.timeframeData.isSingleDay) {
            return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold" class="pr-4">Units Revenue</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td class="pr-4" align="left">' + fromDate + ' - ' + toDate + '</td><td align="right">' + this.getCurrencySymbol(this.changeNumberFormat(item.value)) + '</td></tr><tr style="color:#a5a4bf"><td align="left">' + fromdatePrev + ' - ' + toDatePrev + '</td><td align="right">' + this.getCurrencySymbol(this.changeNumberFormat(item.valuePrev)) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + this.changeNumberFormat(changeVal) + '%</span></td><td align="right" style="color:' + dynCls + '">' + this.getCurrencySymbol(this.changeNumberFormat(item.value - item.valuePrev)) + '</td></tr></table>';
          }
          return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold" class="pr-4">Units Revenue</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td align="left">' + fromDate + '</td><td align="right">' + this.getCurrencySymbol(this.changeNumberFormat(item.value)) + '</td></tr><tr style="color:#a5a4bf"><td align="left">' + fromdatePrev + '</td><td align="right">' + this.getCurrencySymbol(this.changeNumberFormat(item.valuePrev)) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + this.changeNumberFormat(changeVal) + '%</span></td><td align="right" style="color:' + dynCls + '">' + this.getCurrencySymbol(this.changeNumberFormat(item.value - item.valuePrev)) + '</td></tr></table>';
        })
      },
      series: [
        {
          data: [],
        },
        {
          data: this.timeframeData.revenueData.diagram.map((item) => item.value)
        }
      ]
    };

    if (this.updateRevenueChartOptions.series[1].data.length > 0) {
      let marevenueVal = this.updateRevenueChartOptions.series[1].data.reduce((a, b) => Math.max(a, b));
      for (var i = 0; i < this.updateRevenueChartOptions.series[1].data.length; i++) {
        this.updateRevenueChartOptions.series[0].data.push(marevenueVal);
      }
    }
  }

  getCurrencySymbol(value) {
    var currency_symbols = {
      'USD': '$', // US Dollar
      'AUD': '$',
      'CAD': '$',
      'MXN': '$',
      'GBP': '£', // British Pound Sterling
      'INR': '₹', // Indian Rupee
      'JPY': '¥', // Japanese Yen
    };
    let currency_name = this.storageBrowser.get('$Currency');
    if (currency_symbols[currency_name] !== undefined) {
      return currency_symbols[currency_name] + value;
    } else {
      return value;
    }
  }

  changeNumberFormat(data) {
    return Math.round(data * 100) / 100;
  }
  changeDateFormate(item, type) {
    return moment(item).utc().format(type);
  }

  getFormData() {
    let formData = JSON.parse(JSON.stringify(this.formData));
    if (this.defaultData.timeFrame == "firstTimeFrame") {
      formData.startDate = this.formData.firstTimeFrameBox.startDate;
      formData.endDate = this.formData.firstTimeFrameBox.endDate;
      formData.timeFrame = this.formData.firstTimeFrameBox.timeFrame;
    } else if (this.defaultData.timeFrame == "secondTimeFrame") {
      formData.startDate = this.formData.secondTimeFrameBox.startDate;
      formData.endDate = this.formData.secondTimeFrameBox.endDate;
      formData.timeFrame = this.formData.secondTimeFrameBox.timeFrame;
    } else if (this.defaultData.timeFrame == "thirdTimeFrame") {
      formData.startDate = this.formData.thirdTimeFrameBox.startDate;
      formData.endDate = this.formData.thirdTimeFrameBox.endDate;
      formData.timeFrame = this.formData.thirdTimeFrameBox.timeFrame;
    }
    delete formData["firstTimeFrameBox"];
    delete formData["secondTimeFrameBox"];
    delete formData["thirdTimeFrameBox"];
    return formData;
  }

  openUnitsSoldDialog() {
    const timeFrameDates = moment(this.timeframeData.startDate).format("DD/MM/YY") + " - " + moment(this.timeframeData.endDate).format("DD/MM/YY");
    const timeFramePDates = moment(this.timeframeData.startPrevious).format("DD/MM/YY") + " - " + moment(this.timeframeData.endPrevious).format("DD/MM/YY");
    //this.appLoaderService.open();
    this.userService.getUnitsSoldList("time_frame/", this.getFormData()).subscribe((res: any) => {
      if (res) {
        this.dialog.open(ListComponent, {
          panelClass: 'profitpopup',
          width: '550px',
          data: { dataSource: res, title: "Units Sold", timeFrame: timeFrameDates, previousDate: timeFramePDates }
        });
        // this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  openRevenueDialog() {
    const timeFrameDates = moment(this.timeframeData.startDate).format("DD/MM/YY") + " - " + moment(this.timeframeData.endDate).format("DD/MM/YY");
    const timeFramePDates = moment(this.timeframeData.startPrevious).format("DD/MM/YY") + " - " + moment(this.timeframeData.endPrevious).format("DD/MM/YY");
    //this.appLoaderService.open();
    this.userService.getRevenueList("time_frame/", this.getFormData()).subscribe((res: any) => {
      if (res) {
        this.dialog.open(ListComponent, {
          panelClass: 'profitpopup',
          width: '580px',
          data: { dataSource: res, title: "Revenue", timeFrame: timeFrameDates, previousDate: timeFramePDates }
        });
        //this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  openProfitDialog() {
    const timeFrameDates = moment(this.timeframeData.startDate).format("DD/MM/YY") + " - " + moment(this.timeframeData.endDate).format("DD/MM/YY");
    const timeFramePDates = moment(this.timeframeData.startPrevious).format("DD/MM/YY") + " - " + moment(this.timeframeData.endPrevious).format("DD/MM/YY");
    //this.appLoaderService.open();
    this.userService.getProfitList("time_frame/", this.getFormData()).subscribe((res: any) => {
      if (res) {
        this.dialog.open(ListComponent, {
          panelClass: 'profitpopup',
          width: '610px',
          data: { dataSource: res, title: "Profit", timeFrame: timeFrameDates, previousDate: timeFramePDates, formData: this.getFormData() }
        });
        //this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  openCostDialog() {
    const timeFrameDates = moment(this.timeframeData.startDate).format("DD/MM/YY") + " - " + moment(this.timeframeData.endDate).format("DD/MM/YY");
    const timeFramePDates = moment(this.timeframeData.startPrevious).format("DD/MM/YY") + " - " + moment(this.timeframeData.endPrevious).format("DD/MM/YY");
    //this.appLoaderService.open();
    this.userService.getCostList("time_frame/", this.getFormData()).subscribe((res: any) => {
      if (res) {
        this.dialog.open(ListComponent, {
          panelClass: 'profitpopup',
          width: '560px',
          data: { dataSource: res, title: "Costs", timeFrame: timeFrameDates, previousDate: timeFramePDates }
        });
        //this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  openMarginDialog() {
    const timeFrameDates = moment(this.timeframeData.startDate).format("DD/MM/YY") + " - " + moment(this.timeframeData.endDate).format("DD/MM/YY");
    const timeFramePDates = moment(this.timeframeData.startPrevious).format("DD/MM/YY") + " - " + moment(this.timeframeData.endPrevious).format("DD/MM/YY");
    //this.appLoaderService.open();
    this.userService.getMarginList("time_frame/", this.getFormData()).subscribe((res: any) => {
      if (res) {
        this.dialog.open(ListComponent, {
          panelClass: 'profitpopup',
          width: '500px',
          data: { dataSource: res, title: "Margin", timeFrame: timeFrameDates, previousDate: timeFramePDates, changeVal: this.timeframeData.margin }
        });
        //this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  openProfitBrakedownDialog() {
    this.userService.loaderObesrver.next(true);
    this.disableProfitBreakdownDialog = true;
    //this.appLoaderService.open();
    this.userService.getProfitBreakdownList("time_frame/", this.getFormData()).subscribe((res: any) => {
      if (res) {
        res.isOpen = false;
        res.formData = this.getFormData();
        this.dialog.open(ProfitbreakdownpopupComponent, {
          panelClass: 'profitbreakdownpopup',
          width: '470px',
          data: res
        });
        this.disableProfitBreakdownDialog = false;
        this.userService.loaderObesrver.next(false);
        //this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  choosedDate(event) {
    this.userService.loaderObesrver.next(true);
    this.customDate.closeMenu();
    let eventDate = '';
    eventDate = event.label.toUpperCase().replace(/-|\s/g, "_");
    let formData: any = {
      "startDate": moment(event.dates[0]).format("YYYY-MM-DD") + "T00:00:00.000Z",
      "endDate": moment(event.dates[1]).format("YYYY-MM-DD") + "T00:00:00.000Z",
      "timeFrame": eventDate,
    }
    if (this.defaultData.timeFrame == "firstTimeFrame") {
      this.formData.firstTimeFrameBox = formData;
    } else if (this.defaultData.timeFrame == "secondTimeFrame") {
      this.formData.secondTimeFrameBox = formData;
    } else if (this.defaultData.timeFrame == "thirdTimeFrame") {
      this.formData.thirdTimeFrameBox = formData;
    }
    this.gettimeFrameData();
  }

  customRange(event) {
    if (event.chosenLabel.includes("-")) {
      this.customrangeshow = true;
      this.customRangeFormData = {
        "startDate": moment(event.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
        "endDate": moment(event.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
        "timeFrame": 'CUSTOM',
      }
    }
  }

  updatePreset() {
    let userData = JSON.parse(JSON.stringify(this.storageBrowser.get('$UserToken$user')));
    this.userService.currentUser.preset = JSON.stringify(this.formData);
    userData.preset = JSON.stringify(this.formData);
    this.storageBrowser.set('$UserToken$user', userData);
  }

  gettimeFrameData() {
    if (this.storageBrowser.get('$UserToken$user')) {
      this.userService.getProfitDashboardData(this.formData).subscribe((res: any) => {
        if (res) {
          this.userService.loaderObesrver.next(false);
          if (this.defaultData.timeFrame == "firstTimeFrame") {
            this.timeframeData = res.timeframeDataFirst;
          } else if (this.defaultData.timeFrame == "secondTimeFrame") {
            this.timeframeData = res.timeframeDataSecond;
          } else if (this.defaultData.timeFrame == "thirdTimeFrame") {
            this.timeframeData = res.timeframeDataThird;
          }
          this.updatedNewData.emit(res);
          this.getTimeFrameTitle(this.timeframeData);
          this.updateChart();
          this.updatePreset();
          this.customrangeshow = false;
        }
      }, (err) => {
      });
    }
  }

  cancel() {
    this.customDate.closeMenu();
  }

  submit() {
    this.userService.loaderObesrver.next(true);
    if (this.defaultData.timeFrame == "firstTimeFrame") {
      this.formData.firstTimeFrameBox = this.customRangeFormData;
    } else if (this.defaultData.timeFrame == "secondTimeFrame") {
      this.formData.secondTimeFrameBox = this.customRangeFormData;
    } else if (this.defaultData.timeFrame == "thirdTimeFrame") {
      this.formData.thirdTimeFrameBox = this.customRangeFormData;
    }
    if (this.storageBrowser.get('$UserToken$user')) {
      this.userService.getProfitDashboardData(this.formData).subscribe((res: any) => {
        if (res) {
          this.userService.loaderObesrver.next(false);
          if (this.defaultData.timeFrame == "firstTimeFrame") {
            this.timeframeData = res.timeframeDataFirst;
          } else if (this.defaultData.timeFrame == "secondTimeFrame") {
            this.timeframeData = res.timeframeDataSecond;
          } else if (this.defaultData.timeFrame == "thirdTimeFrame") {
            this.timeframeData = res.timeframeDataThird;
          }
          this.updatedNewData.emit(res);
          this.getTimeFrameTitle(this.timeframeData);
          this.updateChart();
          this.customrangeshow = false;
        }
      }, (err) => {
      });
    }
  }

  ngOnDestroy() {
    // this.service.unsubscribe();
  }

}