import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {TextService} from '../../../../services/text.service';
import {SequencesService} from '../../../../services/sequences.service';
import {FbService} from '../../../../services/fb.service';
import {Functions} from '../../../../services/functions';
import {SequenceDialogComponent} from '../../../dialogs/sequence-dialog/sequence-dialog.component';
import {RuleDialogComponent} from '../../../dialogs/rule-dialog/rule-dialog.component';
import {ConfirmationDialogComponent} from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'ninja-sequences',
  templateUrl: './sequences.component.html',
  styleUrls: ['./sequences.component.scss']
})

export class SequencesComponent implements OnInit {
  public sequencesList: any;
  public defaultSequencesList: any;
  public currentPage: any;
  public pages: any;
  public followupRules: any;
  public followupRulesListPaginator: any;
  public selectedSequence: any;
  public sequencesListPaginator = [];
  public pageSizeOptions = [100];
  public pageSize = 100;
  public rulesPageSize = 100;

  constructor(public userService: UserService,
              public matDialog: MatDialog,
              public textService: TextService,
              public sequencesService: SequencesService,
              public ref: ChangeDetectorRef,
              public fbService: FbService,
              public functions: Functions) {
  }

  public ngOnInit(): void {
      this.userService.loaderObesrver.next(true);
      this.fbService.getPagesFb(this.userService.currentUser.id).subscribe((data) => {
        this.pages = data;
      });
      this.sequencesService.getSequencesList(this.userService.currentUser.id).subscribe(sequences => {
        if (sequences.length < 100) {
          this.pageSize = sequences.length;
        }
        this.defaultSequencesList = JSON.parse(JSON.stringify(sequences));
        this.sequencesList = sequences;
        this.defaultFilter();
        this.userService.loaderObesrver.next(false);
      });
  }

  public getUpdatePaginator(index): void {
    if (this.sequencesList.length < index * 100) {
      index = 1;
    }
    const start = index * (this.pageSize - 1);
    const end = this.sequencesList.length > 99 ? start + (this.pageSize - 1) : this.sequencesList.length;
    this.sequencesListPaginator = this.sequencesList.slice(start, end);
    this.ref.detectChanges();
  }

  public getUpdateRulesPaginator(index): void {
    if (this.followupRules.length < index * 100) {
      index = 1;
    }
    const start = index * (this.pageSize - 1);
    const end = this.followupRules.length > 99 ? start + (this.pageSize - 1) : this.followupRules.length;
    this.followupRulesListPaginator = this.followupRules.slice(start, end);
    this.ref.detectChanges();
  }

  public changeStatus(funnelId, funnelStatus): void {
    this.sequencesService.changeSequenceStatus(this.userService.currentUser.id, funnelId, !funnelStatus).subscribe();
  }

  public valueToggle(checker: boolean): string {
    return checker ? 'On' : 'Off';
  }

  public getFollowupRules(sequence, createRule?): void {
    this.selectedSequence = sequence;
    this.userService.loaderObesrver.next(true);
    this.sequencesService.getFollowupRules(this.userService.currentUser.id, sequence.funnelId).subscribe(rules => {
      if (rules.length < 100) {
        this.rulesPageSize = rules.length;
      }
      this.followupRules = rules;
      this.getUpdateRulesPaginator(0);
      this.userService.loaderObesrver.next(false);
      if (createRule) {
        this.showCreateNewRuleDialog(sequence)
      }
    });
  }

  public sortByPageId() {
    if (!this.currentPage) {
      return;
    }
    this.sequencesList = [];
    for (let i = 0; this.defaultSequencesList.length > i; i++) {
      if (this.defaultSequencesList[i].pageId === this.currentPage.pageId) {
        this.sequencesList.push(this.defaultSequencesList[i]);
      }
    }
    this.defaultFilter();
  }

  public showCreateNewRuleDialog(sequence, rule?): void {
    this.matDialog.open(RuleDialogComponent, {
      data: {
        sequence: sequence,
        rule: rule
      }
    }).afterClosed().subscribe(res => {
      if (res === 'getNewData') {
        this.userService.loaderObesrver.next(true);
        this.sequencesService.getFollowupRules(this.userService.currentUser.id, this.selectedSequence.funnelId).subscribe(rules => {
          if (rules.length < 100) {
            this.rulesPageSize = rules.length;
          }
          this.sequencesService.getSequencesList(this.userService.currentUser.id).subscribe(sequences => {
            if (sequences.length < 100) {
              this.pageSize = sequences.length;
            }
            this.sequencesList = sequences;
            this.getUpdatePaginator(0);
          });
          this.followupRules = rules;
          this.getUpdateRulesPaginator(0);
          this.userService.loaderObesrver.next(false);
          this.sortByPageId();
        });
      }
    });
  }

  public changeRuleStatus(rule): void {
    let dataToSend = JSON.parse(JSON.stringify(rule));
    dataToSend.publicId = this.userService.currentUser.id;
    dataToSend.ruleStatus = !dataToSend.ruleStatus
    this.sequencesService.saveRule(dataToSend).subscribe();
  }

  public showCreateNewSequenceDialog(): void {
    this.matDialog.open(SequenceDialogComponent).afterClosed().subscribe(res => {
      if (res && res === 'getNewData') {
        this.userService.loaderObesrver.next(false);
        this.sequencesService.getSequencesList(this.userService.currentUser.id).subscribe(sequences => {
          if (sequences.length < 100) {
            this.pageSize = sequences.length;
          }
          this.sequencesList = sequences;
          this.getUpdatePaginator(0);
          this.userService.loaderObesrver.next(false);
        });
      }
  });
  }

  public showDeleteSequenseDialog(funnelId) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'delete-confirm-sequence',
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.deleteSequence(funnelId);
      }
    })
  }

  public deleteRule(ruleId): void {
    this.sequencesService.deleteRule(this.userService.currentUser.id, ruleId, this.selectedSequence.funnelId).subscribe(res => {
      if (res.isSuccess) {
        for (let i = 0; this.followupRules.length > i; i++) {
          if (this.followupRules[i].ruleId === ruleId) {
            this.followupRules.splice(i, 1);
            this.getUpdateRulesPaginator(0);
          }
        }
        this.sequencesService.getSequencesList(this.userService.currentUser.id).subscribe(sequences => {
          if (sequences.length < 100) {
            this.pageSize = sequences.length;
          }
          this.sequencesList = sequences;
          this.defaultFilter();
        });
        this.userService.showNotification = true;
        this.userService.notificationTitle = this.textService.currentText.success;
        this.userService.notificationMessage = this.textService.currentText.deleteRuleSuccessText;
      }
    });
  }

  public defaultFilter(): void {
    const first = this.sequencesList.filter(this.isActive.bind(this));
    const second = this.sequencesList.filter(this.isInactive.bind(this));
    first.sort((a, b) => {
      if (a['conversationCount'] > b['conversationCount']) {
        return -1;
      } else if (a['conversationCount'] < b['conversationCount']) {
        return 1;
      } else {
        return 0;
      }
    });
    second.sort((a, b) => {
      if (a['conversationCount'] > b['conversationCount']) {
        return -1;
      } else if (a['conversationCount'] < b['conversationCount']) {
        return 1;
      } else {
        return 0;
      }
    });
    this.sequencesList = first.concat(second);
    this.getUpdatePaginator(0);
    this.ref.detectChanges();
  }

  public isActive(value: any): boolean {
    return value.funnelStatus === true;
  }

  public isInactive(value: any): boolean {
    return value.funnelStatus === false;
  }


  public scrollBottom(): void {
    window.scrollTo(0, document.body.scrollHeight);;
  }

  public deleteSequence(funnelId): void {
    this.sequencesService.deleteSequence(this.userService.currentUser.id, funnelId).subscribe(res => {
      if (res.isSuccess) {
        for (let i = 0; this.sequencesList.length > i; i++) {
          if (this.sequencesList[i].funnelId === funnelId) {
            this.sequencesList.splice(i, 1);
            this.getUpdatePaginator(0);
          }
        }
        this.selectedSequence = '';
        this.followupRules = [];
        this.getUpdateRulesPaginator(0);
        this.userService.showNotification = true;
        this.userService.notificationTitle = this.textService.currentText.success;
        this.userService.notificationMessage = this.textService.currentText.deleteSequenceSuccessText;
      }
    });
  }

}
