import {Component, Inject, OnInit} from '@angular/core';
import {Functions} from '../../../services/functions';
import {UserService} from '../../../services/user.service';
import {TextService} from '../../../services/text.service';
import {ConstService} from '../../../services/const-service';
import {AccountSettings} from '../../../services/account-settings';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CreateAsinInterface} from "../../../models/BaseModels";
import {forEach} from '@angular-devkit/schematics';

@Component({
    templateUrl: './add-asin-dialog.component.html',
    styleUrls: ['./add-asin-dialog.component.scss']
})

export class AddAsinDialogComponent implements OnInit {
    public products: any;
    public marketplace: any;
    public selectedProduct: any;
    public keywords: any;
    public localChannels: any;
    public asinSaveData: Array<CreateAsinInterface> = [];


    constructor(public textService: TextService,
                public constService: ConstService,
                public userService: UserService,
                public functions: Functions,
                public accountSettings: AccountSettings,
                public dialogRef: MatDialogRef<AddAsinDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    public ngOnInit(): void {
        this.userService.dialogLoader = true;
        this.localChannels = JSON.parse(JSON.stringify(this.constService.salesChannels));
        this.localChannels.splice(0, 1);
        this.accountSettings.getProductSettings(this.userService.currentUser.id).subscribe(res => {
            this.products = res.products;
            this.userService.dialogLoader = false;
        });
    }

    public saveAsin(): void {
        let marketplaceToSend = this.marketplace.split('.')[this.marketplace.split('.').length - 1].toUpperCase();
        if (marketplaceToSend === 'COM') {
            marketplaceToSend = 'US';
        }
        let keywordToSend = this.keywords.split(',');
        keywordToSend = keywordToSend.map(i => i.trim());
        if (!keywordToSend[keywordToSend.length - 1]) {
            keywordToSend.splice(-1, 1);
        }
        for (let i = 0; i < keywordToSend.length; i++) {
            this.asinSaveData.push({
                asin: this.selectedProduct.asin,
                keyword: keywordToSend[i],
                marketplace: marketplaceToSend
            });
        }
        this.userService.addAsinSpot(this.userService.currentUser.id, this.asinSaveData)
            .subscribe(res => {
                if (res.isSuccess) {
                    this.userService.showNotification = true;
                    this.userService.notificationTitle = this.textService.currentText.success;
                    this.userService.notificationMessage = this.textService.currentText.asinAddSuccess;
                    this.dialogRef.close('update');
                } else {
                    this.userService.showNotification = true;
                    this.userService.notificationTitle = this.textService.currentText.fail;
                    this.userService.notificationMessage = this.textService.currentText.dialogWentWrong;
                    this.dialogRef.close();
                }
            });
    }

}
