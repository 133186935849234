import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { TextService } from '../../../services/text.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { InvoicingService } from '../../../services/invoicing.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Functions } from "../../../services/functions";
import { ManagedAccountDialogComponent } from '../../dialogs/managed-account-dialog/managed-account-dialog.component';
import { SendInvoiceDialogComponent } from '../../dialogs/send-invoice-dialog/send-invoice-dialog.component';
import { ConstService } from '../../../services/const-service';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'invoicing',
  templateUrl: './invoicing.component.html',
  styleUrls: ['./invoicing.component.scss']
})

export class InvoicingComponent implements OnInit {
  public invoiceList: any;
  public autoSend = false;
  public Flag: any;
  public localChannels: any;
  public usedMp: any;

  constructor(public userService: UserService,
    public textService: TextService,
    public functions: Functions,
    public invoiceService: InvoicingService,
    public router: Router,
    public matDialog: MatDialog,
    public constService: ConstService,
    public mixpanelService: MixpanelService,
  ) {
    this.mixpanelService.init(null);
  }

  public ngOnInit() {
    this.userService.loaderObesrver.next(true)
    this.invoiceService.checkInvoiceAccess(this.userService.currentUser.id).subscribe((res) => {
      if (res.hasAccess === false) {
        this.showAccessDialog();
      } else {
        this.invoiceService.getInvoices(this.userService.currentUser.id).subscribe(data => {
          this.invoiceList = data.invoiceTemplates;
          this.usedMp = data.usedMp;
          this.userService.loaderObesrver.next(false);
          this.localChannels = JSON.parse(JSON.stringify(this.constService.salesChannels));
        });
      }
    });
  }

  public setFlag(flag) {
    // flag = flag.reduce(function(o, val) { o[val] = val; return o; }, {});
    for (let i = 0; i < flag.length; i++) {
      for (let j = 0; j < this.localChannels.length; j++) {
        if (this.localChannels[j].value == flag[i]) {
          console.log("this.localChannels[j].image", this.localChannels[j].image);
          return this.localChannels[j].image;
          // flag[i].push({image: this.localChannels[j].image});
        }
      }
    }
  }

  public checkMarketplace(marketplace) {
    for (let i = 0; i < marketplace.length; i++) {
      if (marketplace[i] == 'Amazon.co.uk' || marketplace[i] == 'Amazon.fr' || marketplace[i] == 'Amazon.it' || marketplace[i] == 'Amazon.de' || marketplace[i] == 'Amazon.es') {
        return true;
      } else {
        return false;
      }
    }
  }

  public showAccessDialog() {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'invoice-agreement',
      },
    }).afterClosed().subscribe(res => {
      if (res) {
        this.invoiceService.invoiceAgreement(this.userService.currentUser.id).subscribe(res => {
          if (res.isSuccess) {
            this.invoiceService.getInvoices(this.userService.currentUser.id).subscribe(data => {
              this.invoiceList = data.invoiceTemplates;
              this.usedMp = data.usedMp;
              this.userService.loaderObesrver.next(false);
            });
            this.matDialog.closeAll();
          }
        });
      } else {
        this.router.navigate(['/dashboard/dashboard-schedules']);
      }
    });
  }

  public showDeleteConfirm(invoice: any) {
    // if (invoice.templateName) {
    //   this.matDialog.open(ConfirmationDialogComponent, {
    //     data: {
    //       'dialogFor': 'delete-invoice-warning',
    //       'templateName': invoice.templateName,
    //     },
    //   }).afterClosed().subscribe();
    // } else {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'delete-confirm-invoice',
      },
    }).afterClosed().subscribe(res => {
      if (res) {
        this.deleteInvoice(invoice);
      }
    });
    // }
  }

  public showSendInvoiceDialog(invoiceTemplateId): void {
    this.matDialog.open(SendInvoiceDialogComponent, {
      data: {
        invoiceTemplateId: invoiceTemplateId
      }
    }).afterClosed().subscribe();
  }

  public deleteInvoice(invoice): void {
    this.invoiceService.deleteInvoice(this.userService.currentUser.id, invoice.invoiceTemplateId).subscribe(res => {
      if (res.isSuccess) {
        for (let i = 0; i < this.invoiceList.length; i++) {
          if (this.invoiceList[i].invoiceTemplateId === invoice.invoiceTemplateId) {
            this.usedMp = this.usedMp.filter(e => e !== invoice.invoiceTemplateSalesChannels[0]);
            this.invoiceList.splice(i, 1);
            this.userService.showNotification = true;
            this.userService.notificationMessage = this.textService.currentText.invoiceDeleted;
            this.userService.notificationTitle = this.textService.currentText.success;
          }
        }
      }
    });
  }

  public navigateToEdit(id: number): void {
    this.router.navigate(['/dashboard/invoicing/create-new-invoice'], { queryParams: { id: id, usedMp: this.usedMp } });
  }

  public navigateToDuplicate(id: number): void {
    this.router.navigate(['/dashboard/invoicing/create-new-invoice'], { queryParams: { id: id, duplicate: 'duplicate', usedMp: this.usedMp } });
  }

  public createNewInvoice(): void {
    this.router.navigate(['/dashboard/invoicing/create-new-invoice'], { queryParams: { newInvoice: this.usedMp } });
  }

  public updateStatus(invoice, status) {
    status = !status;
    this.invoiceService.updateStatus(this.userService.currentUser.id, status, invoice.invoiceTemplateId).subscribe(res => {
      if (res.success) {
        for (let i = 0; i < this.invoiceList.length; i++) {
          if (this.invoiceList[i].invoiceTemplateId === invoice.invoiceTemplateId) {
            this.invoiceList[i].status = !this.invoiceList[i].status;
            this.userService.showNotification = true;
            this.userService.notificationMessage = this.textService.currentText.invoiceUpdate;
            this.userService.notificationTitle = this.textService.currentText.success;
          }
        }
      }
    });
  }
}
