<div class="dashboard one-screen keywords" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container">
    <div class="dashboard-header">
      <div class="head-container">
        <h2>{{textService.currentText.mbTitle}}</h2>
        <div class="right-side">
          <!--<button mat-button-->
                  <!--class="default-btn"-->
                  <!--[class.active]="isAdjustment"-->
                  <!--(click)="googleAnalyticsEventsService.emitEvent('Button', 'Money back Switch to Adjustment', 'Click', 1);-->
                   <!--isAdjustment = true; getAdjustment()">-->
            <!--{{textService.currentText.mbTab1}}-->
          <!--</button>-->
          <!--<button mat-button-->
                  <!--class="default-btn"-->
                  <!--(click)="googleAnalyticsEventsService.emitEvent('Button', 'Money back Switch to Missing Inventory', 'Click', 1);-->
                  <!--isAdjustment = false; getInventory()"-->
                  <!--[class.active]="!isAdjustment">-->
            <!--{{textService.currentText.mbTab2}}-->
          <!--</button>-->
        </div>
      </div>
    </div>
    <div class="money-back-block">
      <div class="column">
        <h4>{{textService.currentText.auto}}</h4>
        <p>Plan Status:
          <ng-conatiner *ngIf="adjustmentRequests?.isActive">
            <span class="green">{{textService.currentText.active}}</span>
          </ng-conatiner>
          <ng-conatiner *ngIf="!adjustmentRequests?.isActive">
            <span class="red">{{textService.currentText.inactive}}</span>
          </ng-conatiner>
        </p>
        <p *ngIf="isAdjustment">UnResolved Requests: <span
            class="blue">{{adjustmentRequests?.unresolvedRequests}}</span></p>
        <p>{{textService.currentText.mbAmzAccount}}: {{adjustmentRequests?.amazonEmail}}</p>
        <p>{{textService.currentText.mbDaysBtwRequests}}: {{adjustmentRequests?.daysBetweenRequests}}</p>
        <p>{{textService.currentText.mbNumbersOfReqOnSingle}}: {{adjustmentRequests?.numberOfRequests}}</p>
        <button mat-button class="default-btn"
                (click)="googleAnalyticsEventsService.emitEvent('Button', 'Money back Edit', 'Click', 1); showAutoMoneyBackDialog()">{{textService.currentText.edit}}</button>
        <div class="next-text">
          <h4>{{textService.currentText.mbWhatNext}}</h4>
          <p>{{textService.currentText.mbWhatNextText1}}
            <br>
            <br>
            {{textService.currentText.mbWhatNextText2}}</p>
        </div>
      </div>
      <div class="column manual">
        <h4>{{textService.currentText.manual}} </h4>
        <div class="label-item">
          <span>{{textService.currentText.mbNumberOfRequests}} </span>
        </div>
        <ng-container name="form" [formGroup]="submitForm" novalidate>
          <input matInput
                 [class.has-danger]="errors.requests"
                 #requests
                 formControlName="requests"
                 [type]="typeOfRequest">
          <div class="txt">
            <img src="../../../assets/icons/info-icon.svg">
            <span>{{textService.currentText.mbOnSingleEmial}}</span>
          </div>
          <ng-container *ngIf="!isAdjustment">
            <div class="label-item">
              <span>{{textService.currentText.mbMoneyBackGuarantee}}</span>
            </div>
            <input matInput
                   *ngIf="!errors.moneyback"
                   formControlName="moneyback"
                   #moneyback
                   [type]="typeOfMoneyBack">
              <input matInput
                     *ngIf="errors.moneyback"
                     [class.has-danger]="errors.moneyback"
                     [value]="textService.currentText.errorMoreThan30">
            <div class="txt">
              <img src="../../../assets/icons/info-icon.svg">
              <span>{{textService.currentText.mbGuaranteeText}}</span>
            </div>
          </ng-container>
        </ng-container>
        <div class="dialog-row datepicker custom-selects borderless-input">
          <mat-checkbox (change)="clearBeforeDate()"
                        [(ngModel)]="before"
                        disableRipple>
            {{textService.currentText.mbOnlyAdjBefore}}
          </mat-checkbox>
          <div class="small-date">
            <mat-form-field>
              <input
                  matInput
                  [matDatepicker]="picker"
                  [disabled]="!before"
                  name="dateFrom"
                  [(ngModel)]="beforeDate"
                  [max]="endDate"
                  (click)="picker.open()"
                  (keydown)="$event.preventDefault()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="dialog-row datepicker custom-selects borderless-input no-margin">
          <mat-checkbox [(ngModel)]="after"
                        (change)="clearAfterDate()"
                        disableRipple>
            {{textService.currentText.mbOnlyAdjAfter}}
          </mat-checkbox>
          <div class="small-date">
            <mat-form-field>
              <input matInput
                     [matDatepicker]="picker2"
                     [(ngModel)]="afterDate"
                     [disabled]="!after"
                     [min]="startDate"
                     (click)="picker2.open()"
                     (keydown)="$event.preventDefault()">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <button mat-button
                mat-raised-button
                (click)="googleAnalyticsEventsService.emitEvent('Button', 'Money back Send Manual Form', 'Click', 1); sendManualForm()"
                class="primary-btn">
          {{textService.currentText.send}}
        </button>
      </div>
    </div>
  </div>
</div>

<ninja-notification [active]="userService.showNotification"
                    [message]="notificationMessage"
                    [title]="notificationTitle"
></ninja-notification>


