import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ConstService} from '../../../../services/const-service';
import {Functions} from '../../../../services/functions';
import {UserService} from '../../../../services/user.service';
import {TextService} from '../../../../services/text.service';
import {FbService} from '../../../../services/fb.service';
import {ConfirmationDialogComponent} from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import {ManageTagsDialogComponent} from "../../../dialogs/manage-tags-dialog/manage-tags-dialog.component";

@Component({
  selector: 'ninja-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss']
})

export class SubscribersComponent implements OnInit {
  public isFilters = true;
  public constSubscribersList;
  public subscribers;
  public pages: any;
  public conversationList: any;
  public tags: any;
  public filters = {
    page: null,
    name: '',
    tw: [],
    ntw: [],
    conversation: null
  };
  public subscribersListPaginator = [];
  public pageSizeOptions = [100];
  public pageSize = 100;
  public isBroadcastButton = true;
  public checkAllSubscribers: boolean;

  constructor(public matDialog: MatDialog,
              public constService: ConstService,
              public textService: TextService,
              public ref: ChangeDetectorRef,
              public userService: UserService,
              public fbSerice: FbService,
              public fbService: FbService,
              public functions: Functions) {
  }

  public ngOnInit(): void {
    this.fbService.facebookGetTags(this.userService.currentUser.id).subscribe((data) => {
      this.tags = data;
    });
    this.userService.loaderObesrver.next(true);
    this.fbService.getConversation(this.userService.currentUser.id).subscribe((data) => {
      this.conversationList = data;
    });
    this.fbService.getPagesFb(this.userService.currentUser.id).subscribe((data) => {
      this.pages = data;
    });
   this.getSubscribers();
  }

  public getConversationListByPage () {
    setTimeout(() => {
      this.fbSerice.getConversationsNames(this.userService.currentUser.id, this.filters.page.pageId).subscribe((data) => {
        this.conversationList = data;
        this.getUpdatePaginator(0);
      });
    })
  }

    @HostListener('window:click', ['$event'])
    public checkFullScreen(event: MouseEvent): void {
        if (!event.target['classList'].contains('tag-list') && !event.target['classList'].contains('more')) {
            for (let i = 0; i < this.subscribersListPaginator.length; i++ ) {
                this.subscribersListPaginator[i].showMoreTags = false;
            }
        }
    }

  public getSubscribers () {
    this.userService.loaderObesrver.next(true);
    this.fbService.getSubscribers(this.userService.currentUser.id).subscribe((data) => {
      this.constSubscribersList = data;
      this.subscribers = JSON.parse(JSON.stringify(this.constSubscribersList));
      if (data.length < 100) {
        this.pageSize = data.length;
      }
      this.getUpdatePaginator(0);
      this.userService.loaderObesrver.next(false);
    });
  }

  public getUpdatePaginator(index) {
    if (this.subscribers.length < index * 100) {
      index = 1;
    }
    const start = index * (this.pageSize - 1);
    const end = this.subscribers.length > 99 ? start + (this.pageSize - 1) : this.subscribers.length;
    this.subscribersListPaginator = this.subscribers.slice(start, end);
    this.ref.detectChanges();
  }

  public checkFilters(): void {
    this.subscribers = this.constSubscribersList;
    if (this.filters.ntw.length) {
      this.filterByNtw();
    }
    if (this.filters.tw.length) {
      this.filterByTw();
    }
    if (this.filters.page && this.filters.page.pageId) {
      this.filterByPage();
    }
    if (this.filters.name.length) {
      this.liveSearch();
    }
    this.ref.detectChanges();
    this.getUpdatePaginator(0)
  }

  public filterByPage(): void {
    this.subscribers = this.subscribers.filter(this.isCorrectPage.bind(this));
    this.ref.detectChanges();
    this.getUpdatePaginator(0)
  }

  public isCorrectPage(value: any): boolean {
    return value.pageName.toLowerCase() === this.filters.page.name.toLowerCase();
  }

  public filterByTw(): void {
    this.subscribers = this.subscribers.filter(this.isTw.bind(this));
    this.ref.detectChanges();
    this.getUpdatePaginator(0)
  }

  public isTw(value: any): any {
    let tags = [];
    for (let i = 0; value.tags.length > i; i++) {
      tags.push(value.tags[i].name)
    }
    for (let i = 0; this.filters.tw.length > i; i++) {
      if (tags.includes(this.filters.tw[i])) {
        return tags.includes(this.filters.tw[i]);
      }
    }
  }

  public checkAll(): void {
    for (let i = 0; this.subscribers.length > i; i++) {
      if (this.checkAllSubscribers) {
        this.subscribers[i].checked = true;
      } else {
        this.subscribers[i].checked = false;
      }

    }
  }

  public filterByNtw(): void {
    this.subscribers = this.subscribers.filter(this.isNtw.bind(this));
    this.ref.detectChanges();
    this.getUpdatePaginator(0);
  }

  public isNtw(value: any): any {
    let tags = [];
    for (let i = 0; value.tags.length > i; i++) {
      tags.push(value.tags[i].name)
    }
    let isTag = true;
    if (this.filters.ntw.length && !tags.length) {
      return true;
    }
    for (let i = 0; this.filters.ntw.length > i; i++) {
      if (tags.includes(this.filters.ntw[i])) {
        isTag = false;
      }
    }
    return isTag;
  }

  public liveSearch(): void {
    this.subscribers = this.subscribers.filter(this.liveSearchCallback.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchCallback(value: any): boolean {
    let fullName = value.firstName + value.lastName;
    return fullName.toLowerCase().indexOf(this.filters.name.toLowerCase()) !== -1;
  }

  public clearFilter(): void {
    this.filters = {
      page: null,
      name: '',
      tw: [],
      ntw: [],
      conversation: null
    };
    this.subscribers = JSON.parse(JSON.stringify(this.constSubscribersList));
    this.ref.detectChanges();
    this.getUpdatePaginator(0)
  }

  public startBroadcast() {
    if (!this.filters.conversation || !this.filters.conversation.conversationId || !this.filters.page || !this.filters.page.pageId) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'subscribers-att',
        },
      });
      return;
    }
    if (this.filters.conversation.isEmpty) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'custom',
          'title': this.textService.currentText.dialogOops,
          'message': this.textService.currentText.emptyConversation
        },
      });
      return
    }
    let subscribersList = [];
    let isChecked = false;
    for (let i = 0; this.subscribers.length > i; i++) {
      if (this.subscribers[i].checked) {
        subscribersList.push(this.subscribers[i].fbId);
        isChecked = true;
      }
    }
    if (subscribersList && !isChecked) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'start-broadcast-message'
        },
      }).afterClosed().subscribe(res => {
        if (res) {
            for (let i = 0; this.subscribers.length > i; i++) {
              subscribersList.push(this.subscribers[i].fbId);
            }
          const broadCastList = {
            publicId: this.userService.currentUser.id,
            conversationId: this.filters.conversation.conversationId,
            subscribers: subscribersList,
            pageToken: this.filters.page.pageToken,
            pageId: this.filters.page.pageId
          };
          this.isBroadcastButton = !this.isBroadcastButton;
          this.broadcast(broadCastList);
          return;
        }
      });
    } else {
      const broadCastList = {
        publicId: this.userService.currentUser.id,
        conversationId: this.filters.conversation.conversationId,
        subscribers: subscribersList,
        pageToken: this.filters.page.pageToken,
        pageId: this.filters.page.pageId
      };
      this.isBroadcastButton = !this.isBroadcastButton;
      this.broadcast(broadCastList);
    }
  }

  public deleteSubscriber (subscriber) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'delete-confirm-subscriber',
      },
    }).afterClosed().subscribe((res) => {
      if (res) {
         this.fbService.deleteSubscriber(this.userService.currentUser.id, subscriber.fbId, subscriber.pageId).subscribe((data) => {
           this.subscribersListPaginator.splice(this.subscribersListPaginator.indexOf(subscriber), 1);
           this.constSubscribersList.splice(this.constSubscribersList.indexOf(subscriber), 1);
           this.userService.showNotification = true;
           this.userService.notificationMessage = this.textService.currentText.deleteSubscriberTooltip;
           this.userService.notificationTitle = this.textService.currentText.success;
        })
      }
    });
  }

  public interrupt() {
    this.fbService.fbInterrupt(this.userService.currentUser.id).subscribe( res => {
      if (res.isSuccess) {
        this.isBroadcastButton = !this.isBroadcastButton;
      } else {
        this.isBroadcastButton = true;
        this.matDialog.open(ConfirmationDialogComponent, {
          data: {
            'dialogFor': 'custom',
            'title': this.textService.currentText.dialogOops,
            'message': this.textService.currentText.dialogInterrupt
          },
        });
      }
    });
  }

  public broadcast (broadCastList) {
    this.fbSerice.fbBroadcast(broadCastList).subscribe(() => {
      const interval = setInterval(() => {
        this.fbService.checkInterrupt(this.userService.currentUser.id).subscribe( res => {
          console.log(res)
          if (!res.isSuccess) {
            this.isBroadcastButton = !this.isBroadcastButton;
            clearInterval(interval);
          }
        });
      }, 2000);
    });
  }

  public openManageTagsDialog (subscriber) {
    this.matDialog.open(ManageTagsDialogComponent, {
      data: {
        'dialogFor': 'delete-confirm-subscriber',
        'data': subscriber,
        'tagList': this.tags,
        'pages': this.pages
      },
    }).afterClosed().subscribe((tags) => {
      if (tags) {
        subscriber.tags = tags;
      }
    })
  }
}
