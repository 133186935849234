import { Component, ElementRef, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { AccountSettings } from '../../../services/account-settings';

@Component({
  templateUrl: './edit-product-cost-dialog.component.html',
  styleUrls: ['./edit-product-cost-dialog.component.scss']
})

export class EditProductCostDialogComponent implements OnInit {
  products: any;
  @Input() updatedProductsListPaginator: any;
  productsListPaginator: any;
  constructor(
    public userService: UserService,
    public accountSettings: AccountSettings,
    public dialogRef: MatDialogRef<EditProductCostDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.accountSettings.getProductSettings(this.userService.currentUser.id).subscribe((data) => {
      this.productsListPaginator = data.products;
      for(let i = 0; i < data.products.length; i++) {
        if (this.data.product.sku == data.products[i].SKU || this.data.product.SKU == data.products[i].SKU) {
          this.products = data.products[i];
        }
      }
    });
  }

  public ngOnInit(): void {
    
  }

  cancel() {
    for(let i = 0; i < this.productsListPaginator.length; i++) {
      if (this.productsListPaginator[i].SKU == this.data.product.sku || this.productsListPaginator[i].SKU == this.data.product.SKU) {
        this.dialogRef.close(this.productsListPaginator[i]);
      }
    }
  }

}
