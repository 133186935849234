import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';

@Injectable()

export class MoneyBackService extends UserService {
  public currentUser: any;

  public moneybackGetInfo(publicId: string): Observable<any> {
    return this.post('money_back/get_info', {
      publicId: publicId
    });
  }

  public moneybackGetInventoryInfo(publicId: string): Observable<any> {
    return this.post('money_back/get_inventory_info', {
      publicId: publicId
    });
  }

  public moneybackSaveInventoryInfo(data: any): Observable<any> {
    return this.post('money_back/save_inventory_info', data);
  }

  public moneybackSaveInfo(data: any): Observable<any> {
    return this.post('money_back/save_info', data);
  }

  public moneybackGoInventory(data: any): Observable<any> {
    return this.post('money_back/go_inventory', data);
  }

  public moneybackGoInfo(data: any): Observable<any> {
    return this.post('money_back/go_info', data);
  }
}
