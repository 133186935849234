import { AfterViewInit, Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConstService } from '../../../../services/const-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Functions } from '../../../../services/functions';
import { UserService } from '../../../../services/user.service';
import { CouponsService } from '../../../../services/coupons.service';
import { MatDialog } from '@angular/material/dialog';
import { TemplateService } from '../../../../services/template.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SelectSpecificProductsDialogComponent } from '../../../dialogs/select-specific-products-dialog/select-specific-products.component';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { GoogleAnalyticsEventsService } from '../../../../services/google-analytics.service';
import { TextService } from '../../../../services/text.service';
import { InvoicingService } from '../../../../services/invoicing.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MixpanelService } from 'src/app/services/mixpanel.service';

declare let jQuery: any;
declare let $: any;

@Component({
  selector: 'ninja-create-new-template',
  templateUrl: './create-new-template.component.html',
  styleUrls: ['./create-new-template.component.scss']
})

export class CreateNewTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('name', { static: true }) name: ElementRef;
  @ViewChild('subject', { static: true }) subject: ElementRef;
  // @ViewChild('afterSelect', { static: false }) afterSelect: ElementRef;
  @ViewChild('defaultFbaSelect', { static: true }) defaultFbaSelect: ElementRef;
  afterSelect: ElementRef;
  @ViewChild('afterSelect', { static: false }) set content(content: ElementRef) {
    this.afterSelect = content;
  }
  public editor: any;
  public defaultFbaTemplate: any;
  public currentUploadProgress = 0;
  public templateChange: any;
  public errors: any = {};
  public fileSizeLeft = 7340032;
  public dataChanged = false;
  public isFileLoadStart = false;
  public redirectImmediately = false;
  public fileUploadError = false;
  public afterDisabled = false;
  public currentFiles = [];
  public fullScreen: boolean;
  public pasteToSubject = false;
  public pasteToSubject2 = false;
  public reviewRequest: boolean = false;
  public reviewRequestContent;
  public pageTitle: string;
  public backButton: string;
  public whenError = 'Select the timeframe';
  public afterError = 'Select the action';
  public promotionNumber = 'You must specify a valid number or uncheck the promotion condition';
  public isTooltip: boolean;
  public coupons: any;
  public selectedCoupon: any;
  public notificationMessage: any;
  public notificationTitle: any;
  public showProductTooltip: any;
  public skusList = [];
  public isFbm: boolean;
  public isHaveShortName: boolean;
  public invoiceList: any;
  public editorConfig: any;
  // public showAltEditor = true;
  public showTimingTooltip: boolean;
  public selectedVariable: string;
  public selectedVariable2: string;
  public showAltSubject: boolean;
  public showAltContent: boolean;
  public showExcludedProductTooltip: boolean;
  public excludedSkusList = [];
  public exclProducts: any = true;
  public localChannels: any;



  public editorOffset = 0;
  public editor2: any;
  // public a: any;
  // public b: any;


  public fbaForm = this.fb.group({
    name: ['',
      [Validators.required]],
    subject: ['',
      [Validators.required]],
    when: ['',
      [Validators.required]],
    after: ['',
      [Validators.required]],
    products: ['']
  });

  constructor(public constService: ConstService,
    public matDialog: MatDialog,
    public ngZone: NgZone,
    public fb: FormBuilder,
    public router: Router,
    public invoiceService: InvoicingService,
    public activatedRoute: ActivatedRoute,
    public templateService: TemplateService,
    public textService: TextService,
    public el: ElementRef,
    public userService: UserService,
    public couponsService: CouponsService,
    public functions: Functions,
    private sanitized: DomSanitizer,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public mixpanelService: MixpanelService) {
    this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    this.userService.showNotification = false;
    if (!this.userService.currentPage) {
      this.redirectImmediately = true;
      this.router.navigate(['/dashboard/automated-messaging/fba']);
    }
    this.editorConfig = {
      height: 200,
      fontNames: ['Quicksand', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Times New Roman', 'Georgia', 'Verdana'],
      fontNamesIgnoreCheck: ['Quicksand', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Times New Roman', 'Georgia', 'Verdana'],
      toolbar: [
        ['style', ['style']],
        ['fontsize', ['fontsize']],
        ['fontname', ['fontname']],
        ['color', ['color']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture', 'video']],
        ['height', ['height']],
        ['view', ['codeview']],
      ],
      popover: {
        image: [
          ['custom', ['imageAttributes']],
          ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
          ['float', ['floatLeft', 'floatRight', 'floatNone']],
          ['remove', ['removeMedia']]
        ],
      },
      fontSizes: ['6', '8', '10', '12', '14', '18', '24', '36', '48', '64', '72'],
      callbacks: {
        onImageUpload: (files) => {
          this.imageFileLoader(files);
        }
      },
      lang: 'en-US', // Change to your chosen language
      imageAttributes: {
        icon: '<i class="note-icon-pencil"/>',
        removeEmpty: false, // true = remove attributes | false = leave empty if present
        disableUpload: true // true = don't display Upload Options | Display Upload Options
      }
    };

    this.defaultFbaTemplate = {
      status: true,
      negative_feedback: false,
      refund: false,
      repeated_buyers: true,
      only_for_repeated_buyers: false,
      coupon_given: true,
      coupon_sign: '',
      coupon_value: null,
      promotion_value: '',
      channellist: [],
      productsskus: [],
      forallproducts: true,
      name: '',
      event: 'ITEM_SHIPPED',
      subject: '',
      attachment: '',
      timeframe: 2,
      templateid: null,
      isNew: false,
      products: true,
      excludedRepeated: 0,
      excludedRepeatedBuyerNoSkuForDays: 0
      // excludedProducts: []
    };
    this.selectedVariable = '<product-short-name>';
    this.selectedVariable2 = '<product-short-name>';
    if (this.userService.currentPage === 'fba') {
      this.pageTitle = this.textService.currentText.editFbaTemplteTitle;
      this.backButton = this.textService.currentText.backFbaTemplate;
    }
    if (this.userService.currentPage === 'fbm') {
      this.pageTitle = this.textService.currentText.editFbmTemplteTitle;
      this.backButton = this.textService.currentText.backFbmTemplate;
      this.afterDisabled = true;
    }
    this.userService.loaderObesrver.next(false);
    this.templateService.checkProductsShortNames(this.userService.currentUser.id).subscribe(data => {
      if (data.isHaveShortName) {
        this.isHaveShortName = data.isHaveShortName;
      }
    });
    if (this.userService.currentUser.permissions.isInvoicing) {
      this.invoiceService.getInvoices(this.userService.currentUser.id).subscribe(invoices => {
        this.invoiceList = invoices;
        this.invoiceList.unshift({ value: '', invoiceTemplateName: 'None' });
      });
    }
    if (this.activatedRoute.snapshot.queryParams.reviewRequest) {
      this.reviewRequest = true;
      this.constService.selectsDays = [
        { value: 5, viewValue: 'Five Days' },
        { value: 6, viewValue: 'Six Days' },
        { value: 7, viewValue: 'Seven Days' },
        { value: 8, viewValue: 'Eight Days' },
        { value: 9, viewValue: 'Nine Days' },
        { value: 10, viewValue: 'Ten Days' },
        { value: 14, viewValue: '2 Weeks' },
        { value: 21, viewValue: '3 Weeks' },
        { value: 30, viewValue: '1 Months' }
      ];
      this.defaultFbaTemplate.subject = 'Review Requests';
      this.defaultFbaTemplate.event = 'ITEM_DELIVERED';
      this.defaultFbaTemplate.timeframe = 7,
        this.defaultFbaTemplate.content = '<div style="max-width: 770px; display: block;margin: auto;"><p><img src="https://sandbox.quantifyninja.com/assets/images/amazon.png" style="width: 85px;"></p><p style="text-align: center; border-bottom:5px solid #ff9900;line-height:0;width: 100%;"><b style="font-family: inherit; font-size: inherit;"><span style="font-size: 30px;"><br></span></b></p><p style="text-align: center; "><b style="font-family: inherit; font-size: inherit;"><span style="font-size: 24px;">Your opinion matters!</span></b><br></p><p style="text-align: center; ">Seller &lt;seller_name&gt; request you to share your experience for your recent order with other Amazon shoppers. Please take a moment to review your recent Amazon purchase.</p><p style="text-align: center; border-bottom: 1px solid #ccc; line-height:0;width: 100%;"><br></p><p style="text-align: center; ">&lt;product-name&gt;</p><p style="text-align: center; "><span style="font-size: 24px;"><b>Rate this item</b></span></p><p style="margin-bottom: 0;"><img src="https://sandbox.quantifyninja.com/assets/images/star.png" style="width: 226px;margin: auto"></p></div>';
      this.reviewRequestContent = this.sanitized.bypassSecurityTrustHtml(this.defaultFbaTemplate.content);
      this.fbaForm.patchValue({
        subject: 'Review Requests'
      });
      this.fbaForm.controls.subject.disable();
      this.fbaForm.controls.after.disable();
    }
    this.activatedRoute.queryParams.subscribe((data) => {
      if (data.id) {
        this.defaultFbaTemplate.templateid = data.id;
        this.userService.loaderObesrver.next(true);
        this.templateService.getTemplateInfo(data.id, this.userService.currentUser.id).subscribe((template) => {
          this.skusList = template.productsskus.map(product => product.sku);
          this.excludedSkusList = template.excludedProducts.map(product => product.sku);
          this.userService.loaderObesrver.next(false);
          this.templateService.currentFbaTemplate = template;
          this.defaultFbaTemplate = template;
          this.defaultFbaTemplate.isNew = false;
          this.setPromValueSign(this.defaultFbaTemplate.coupon_sign);
          if (template.type == 'REQUEST_REVIEW') {
            this.reviewRequest = true;
            this.constService.selectsDays = [
              { value: 5, viewValue: 'Five Days' },
              { value: 6, viewValue: 'Six Days' },
              { value: 7, viewValue: 'Seven Days' },
              { value: 8, viewValue: 'Eight Days' },
              { value: 9, viewValue: 'Nine Days' },
              { value: 10, viewValue: 'Ten Days' },
              { value: 14, viewValue: '2 Weeks' },
              { value: 21, viewValue: '3 Weeks' },
              { value: 30, viewValue: '1 Months' }
            ];
            this.fbaForm.controls.subject.disable();
            this.fbaForm.controls.after.disable();
            this.defaultFbaTemplate.type = 'REQUEST_REVIEW';
            this.reviewRequestContent = this.sanitized.bypassSecurityTrustHtml(this.defaultFbaTemplate.content);
          } else {
            this.editor.summernote('code', this.defaultFbaTemplate.content);
          }
          if (this.defaultFbaTemplate.attachment && this.defaultFbaTemplate.attachment.length > 0) {
            this.currentFiles = this.defaultFbaTemplate.attachment.split(',');
          } else {
            this.currentFiles = [];
          }
          if (this.defaultFbaTemplate.aSubject) {
            this.addAltSubject();
            this.fbaForm.patchValue({
              subject2: this.defaultFbaTemplate.aSubject
            });
          }
          if (this.defaultFbaTemplate.aContent) {
            this.addAltContent();
            this.editor2.summernote('code', this.defaultFbaTemplate.aContent);
          }
          if (this.defaultFbaTemplate.productsskus.length > 0) {
            this.defaultFbaTemplate.forallproducts = false;
            this.defaultFbaTemplate.placeholder = 'Specific' + ' (' + this.defaultFbaTemplate.productsskus.length + ')';
          } else {
            this.defaultFbaTemplate.products = true;
          }
          if (this.excludedSkusList.length > 0) {
            this.exclProducts = 'test';
            this.defaultFbaTemplate.exludePlaceholder = 'Specific' + ' (' + this.excludedSkusList.length + ')';
          }
          this.templateChange = JSON.stringify(this.defaultFbaTemplate);
        });
      } else {
        if (this.userService.currentPage === 'fba') {
          this.pageTitle = this.textService.currentText.createFbaTemplateTitle;
          this.templateService.currentFbaTemplate = [];
        }
        if (this.userService.currentPage === 'fbm') {
          this.pageTitle = this.textService.currentText.createFbmTemplateTitle;
          this.templateService.currentFbmTemplate = [];
        }
        // this.removeAltSubject();
        // this.showAltEditor = false;
        this.defaultFbaTemplate.timing = 'MORNING';
        this.defaultFbaTemplate.isNew = true;
        this.templateChange = JSON.stringify(this.defaultFbaTemplate);
      }
      this.couponsService.getCoupons(this.userService.currentUser.id).subscribe((coupons) => {
        this.coupons = coupons;
      });
    });
    this.localChannels = JSON.parse(JSON.stringify(this.constService.salesChannels))
    this.localChannels.splice(0, 1);
  }


  public ngAfterViewInit(): void {
    this.ngZone.run(() => {
      this.editor = jQuery('#summernote');
      this.editor.summernote(this.editorConfig);
    });

    // this.el.nativeElement.querySelector('.btn-codeview').innerText = 'Source HTML';
    // this.el.nativeElement.querySelector('.btn-fullscreen').innerText = 'Full screen';
  }

  public ngOnDestroy(): void {
    $('.popover').hide();
    this.userService.currentPage = null;
    this.userService.showNotification = false;
  }

  public pushImageToEditor(link) {
    const text = `<img src='${link}'>`
    this.editor.summernote('restoreRange');
    this.editor.summernote('code', text);
    this.editor.summernote('saveRange');
  }

  public pushProductImageToEditor(editor): void {
    this[editor].summernote('saveRange');
    $('#summernote').summernote('insertImage', 'https://webapp.quantifyninja.com/assets/images/product-placeholder.png', function ($image) {
      $image.css('width', $image.width() / 3);
    });
    this[editor].summernote('restoreRange');
  }

  public pushToEditor(e, editor): void {
    if (e === '<product-image>') {
      this.pushProductImageToEditor(editor);
      return;
    }
    if (e === '<product-image-reviewlink>') {
      this[editor].summernote('saveRabge');
      this[editor].summernote('pasteHTML', '<a href="&lt;product-review-link&gt;"><img src="https://webapp.quantifyninja.com/assets/images/product-placeholder.png" style="width: 213.333px;"></a>');
      this[editor].summernote('restoreRange');
      return;
    }
    if (this.pasteToSubject) {
      this.defaultFbaTemplate.subject += e;
      return;
    }
    if (this.pasteToSubject2) {
      this.fbaForm.controls.subject2.patchValue(this.fbaForm.controls.subject2.value + e)
      return;
    }
    if (this.pasteToSubject) {
      this.defaultFbaTemplate.subject += e;
      return;
    }
    this.ngZone.run(() => {
      this[editor].summernote('saveRange');
      this[editor].summernote('insertText', e);
      this.pasteToSubject = false;
      this.pasteToSubject2 = false;
      this[editor].summernote('restoreRange');
    });
  }


  @HostListener('window:keypress', ['$event'])
  public checkFullScreen(event: KeyboardEvent): void {
    setTimeout(() => {
      if (event.target['offsetParent'].className === 'note-editing-area') {
        this.pasteToSubject = false;
        this.pasteToSubject2 = false;
        // this.editor.summernote('saveRange');
      }
    });
    if (event.key === 'Escape' && this.fullScreen) {
      this.editor.summernote('fullscreen.toggle');
      this.fullScreen = !this.fullScreen;
    }
  }

  @HostListener('window:click', ['$event'])
  public saveTarget(event): void {
    if (event.target['offsetParent']) {
      if (event.target.className === 'note-btn btn btn-default btn-sm btn-codeview active') {
        this.el.nativeElement.querySelectorAll('.dropdown-toggle')[4].disabled = true;
        let editor = document.getElementsByClassName('note-codable');
        editor[0].scrollTop = 0;
      }
      if (event.target.className === 'note-btn btn btn-default btn-sm btn-codeview') {
        this.el.nativeElement.querySelectorAll('.dropdown-toggle')[4].disabled = false;
        // this.editor.summernote('saveRange');
      }
      if (event.target.className === 'note-btn btn btn-default btn-sm btn-fullscreen active') {
        this.fullScreen = !this.fullScreen;
      }
      if (event.target.className === 'note-btn btn btn-default btn-sm btn-fullscreen') {
        this.fullScreen = !this.fullScreen;
      }

      if (event.target['offsetParent'].className === 'note-editing-area') {
        this.pasteToSubject = false;
        this.pasteToSubject2 = false;
        // this.editor.summernote('saveRange');
      }
    }
  }

  public fileUploader(e: any, input): void {
    if (!e.target.files) {
      return;
    }
    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    this.fileSizeLeft -= files[0].size;
    if (this.constService.fileFormats.indexOf(files[0].type) === -1) {
      this.fileUploadError = true;
      setTimeout(() => {
        this.fileUploadError = false;
      }, 2000);
      return;
    }
    if (this.fileSizeLeft <= 0 || files[0].name.includes(',')) {
      this.fileUploadError = true;
      setTimeout(() => {
        this.fileUploadError = false;
      }, 2000);
      return;
    }
    this.isFileLoadStart = true;
    const reader = new FileReader();
    this.userService.uploadFile(files);
    this.userService.fileLoaded().subscribe((data) => {
      this.dataChanged = true;
      if (this.currentFiles.length >= 0 && this.currentFiles.indexOf(JSON.parse(data.target.response).isSuccess) === -1) {
        this.currentFiles.push(JSON.parse(data.target.response).isSuccess);
      }
      this.isFileLoadStart = this.fileSizeLeft <= 0;
      this.currentUploadProgress = 0;
    });
    const subs = this.userService.getObserver().subscribe((data) => {
      if (data === 100) {
        subs.unsubscribe();
      }
      if (this.currentUploadProgress <= data) {
        this.currentUploadProgress = data;
      }
    });
    reader.readAsDataURL(files[0]);
    input.value = '';
  }

  public setPromValueSign(sign: string) {
    switch (sign) {
      case '<':
        this.defaultFbaTemplate.coupon_sign = 'LESS';
        break;
      case '>':
        this.defaultFbaTemplate.coupon_sign = 'GREATER';
        break;
      case '=':
        this.defaultFbaTemplate.coupon_sign = 'EQUALS';
        break;
      default:
        this.defaultFbaTemplate.coupon_sign = '';
    }
  }

  public deleteFile(index: number): void {
    this.currentFiles.splice(index, 1);
    this.isFileLoadStart = false;
  }

  public checkAltSubject(e) {
    if (e.checked) {
      this.addAltSubject();
    } else {
      this.removeAltSubject();
    }
  }

  public onRepeatedBuyersChange(event) {
    if (!event.value) {
      this.defaultFbaTemplate.only_for_repeated_buyers = false;
    }
  }

  public checkAltContent(e) {
    if (e.checked) {
      this.addAltContent();
    } else {
      this.removeAltContent();
    }
  }

  public addAltSubject(): void {
    this.showAltSubject = true;
    this.fbaForm.addControl('subject2', new FormControl('', Validators.required));
  }

  public addAltContent(): void {
    this.showAltContent = true;
    this.editor2 = jQuery('#summernote2');
    this.editor2.summernote(this.editorConfig);
    let btn1 = this.el.nativeElement.getElementsByClassName('btn-codeview');
    let btn2 = this.el.nativeElement.getElementsByClassName('btn-fullscreen');
    for (let i = 0; i < btn1.length; i++) {
      btn1[i].innerText = 'Source HTML';
    }
    for (let i = 0; i < btn2.length; i++) {
      btn2[i].innerText = 'Full screen';
    }
  }

  public removeAltContent(): void {
    this.showAltContent = false;
    this.editor2.summernote('code', '');
    this.editor2.summernote('destroy');
    this.defaultFbaTemplate.aContent = undefined;
  }

  public removeAltSubject() {
    this.fbaForm.removeControl('subject2');
    this.defaultFbaTemplate.aSubject = undefined;
    this.showAltSubject = false;
  }

  public imageFileLoader(files) {
    this.activatedRoute.queryParams.subscribe((data) => {
      this.userService.loaderObesrver.next(true);
      const sizeLimit = 2097152;
      if (files[0].size >= sizeLimit || this.constService.fileImageFormats.indexOf(files[0].type) === -1) {
        this.userService.loaderObesrver.next(false);
        this.showImageFileValidation();
        return;
      }
      if (data.id) {
        let inserted = false;
        this.userService.uploadImage(files, 'templates/upload_image', data.id);
        this.userService.fileLoaded().subscribe((fileeData) => {
          const imageLink = JSON.parse(fileeData.currentTarget.response).url.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
          if (!inserted) {
            this.editor.summernote('restoreRange');
            this.editor.summernote('insertImage', imageLink);
            this.editor.summernote('saveRange');
            inserted = true;
          }
          this.userService.loaderObesrver.next(false);
        });
      } else {
        this.userService.loaderObesrver.next(false);
        this.showSaveFirstDialog();
      }
    });
  }

  public showSpecProducts(): void {
    if (!this.defaultFbaTemplate.products) {
      this.matDialog.open(SelectSpecificProductsDialogComponent, {
        data: {
          'products': this.defaultFbaTemplate.productsskus,
          'isFbm': this.userService.currentPage === 'fba' ? false : true
        }
      }).afterClosed().subscribe(result => {
        if (result === 'empty') {
          this.redirectImmediately = true;
          this.showEmptyFbmProductsDialog();
        }
        if (result && result !== 'empty') {
          this.defaultFbaTemplate.products = this.defaultFbaTemplate.productsskus.length;
          this.defaultFbaTemplate.productsskus = result;
          if (result.length > 0) {
            this.defaultFbaTemplate.forallproducts = false;
            this.defaultFbaTemplate.placeholder = 'Specific' + ' (' + result.length + ')';
            this.skusList = [];
            for (let i = 0; i < this.defaultFbaTemplate.productsskus.length; i++) {
              this.skusList.push(this.defaultFbaTemplate.productsskus[i].sku);
            }
          } else {
            this.defaultFbaTemplate.products = true;
          }
        } else {
          this.defaultFbaTemplate.products = '';
          if (this.defaultFbaTemplate.productsskus.length > 0) {
            this.defaultFbaTemplate.forallproducts = false;
            this.defaultFbaTemplate.placeholder = 'Specific' + ' (' + this.defaultFbaTemplate.productsskus.length + ')';
            this.skusList = [];
            for (let i = 0; i < result.productsskus.length; i++) {
              this.skusList.push(result.productsskus[i].sku);
            }
          } else {
            this.defaultFbaTemplate.saveTemplate = true;
            this.defaultFbaTemplate.products = true;
          }
        }
        if (result && result.status) {
          this.defaultFbaTemplate.forallproducts = true;
        }
      });
    } else {
      this.defaultFbaTemplate.productsskus = [];
    }
  }

  public showExcludeSpecProducts(): void {
    if (!this.exclProducts) {
      this.matDialog.open(SelectSpecificProductsDialogComponent, {
        data: {
          'products': this.defaultFbaTemplate.excludedProducts,
          'isFbm': this.userService.currentPage === 'fba' ? false : true
        }
      }).afterClosed().subscribe(result => {
        if (result) {
          this.exclProducts = '';
          // this.defaultFbaTemplate.productsskus = result;
          if (result.length > 0) {
            this.defaultFbaTemplate.excludedProducts = result;
            this.defaultFbaTemplate.exludePlaceholder = 'Specific' + ' (' + result.length + ')';
            this.excludedSkusList = [];
            for (let i = 0; i < result.length; i++) {
              this.excludedSkusList.push(result[i].sku);
            }
          } else {
            this.exclProducts = true;
            this.excludedSkusList = [];
          }
        } else {
          this.exclProducts = '';
          this.excludedSkusList = [];
        }
      });
    } else {
      this.excludedSkusList = [];
      this.defaultFbaTemplate.excludedProducts = [];
    }
  }

  public checkChanges(): boolean {
    if (this.redirectImmediately) {
      return false;
    }
    this.dataChanged = this.templateChange !== JSON.stringify(this.defaultFbaTemplate);
  }

  public backToFbaList(): void {
    this.checkChanges();
    if (this.userService.currentPage === 'fba') {
      this.router.navigate(['dashboard/automated-messaging/fba']);
    } else {
      this.router.navigate(['dashboard/automated-messaging/fbm']);
    }
  }

  public selectAll(): void {
    this.defaultFbaTemplate.channellist = [];
  }

  public toggleFullScreenButton(): void {
    this.fullScreen = !this.fullScreen;
  }

  public enableEditor(): void {
    this.editor.summernote('enable');
  }

  public disableFullScreen(): void {
    this.editor.summernote('fullscreen.toggle');
    this.toggleFullScreenButton();
  }

  public saveTemplate(): void {
    if (this.defaultFbaTemplate.coupon_sign === '=' && this.defaultFbaTemplate.coupon_value === 0) {
      this.isTooltip = true;
      return;
    }
    if (this.reviewRequest) {
      this.defaultFbaTemplate.type = 'REQUEST_REVIEW';
    } else {
      this.defaultFbaTemplate.type = 'AUTOMATED';
      this.defaultFbaTemplate.content = this.editor.summernote('code');
    }
    this.defaultFbaTemplate.publicid = this.userService.currentUser.id;
    this.defaultFbaTemplate.attachment = this.currentFiles.toString();
    this.defaultFbaTemplate.forallproducts = !(this.defaultFbaTemplate.productsskus.length > 0);
    this.defaultFbaTemplate.excludedProducts = this.excludedSkusList;
    if (this.defaultFbaTemplate.channellist.length === 3) {
      this.defaultFbaTemplate.channellist = [];
    }
    if (this.fbaForm.controls.subject2) {
      this.defaultFbaTemplate.aSubject = this.fbaForm.value.subject2;
    }
    if (this.showAltContent) {
      this.defaultFbaTemplate.aContent = this.editor2.summernote('code');
    }
    if (this.defaultFbaTemplate.status) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'confirm-template',
          'data': this.defaultFbaTemplate,
          'page': this.userService.currentPage
        },
      }).afterClosed().subscribe(result => {
        if (result) {
          this.defaultFbaTemplate.excludedProducts = this.excludedSkusList;
          if (this.userService.currentPage === 'fba') {
            this.saveFBA();
          } else {
            this.saveFBM();
          }
        }
      });
    } else {
      this.defaultFbaTemplate.excludedProducts = this.excludedSkusList;
      if (this.userService.currentPage === 'fba') {
        this.saveFBA();
      } else {
        this.saveFBM();
      }
    }
  }

  public saveFBA(): void {
    this.templateService.saveTemplate(this.defaultFbaTemplate).subscribe((data) => {
      if (data.isSuccess) {
        this.defaultFbaTemplate.saveTemplate = true;
        if (this.defaultFbaTemplate.isNew) {
          this.router.navigate(['dashboard/automated-messaging/fba'],
            { queryParams: { created: true } });
        } else {
          this.router.navigate(['dashboard/automated-messaging/fba'],
            { queryParams: { created: false } });
        }
      } else {
        this.showSaveTemplateErrorDialog();
      }
    });
  }

  public saveFBM(): void {
    this.templateService.saveFBMTemplate(this.defaultFbaTemplate).subscribe((data) => {
      if (data.isSuccess) {
        this.defaultFbaTemplate.saveTemplate = true;
        if (this.defaultFbaTemplate.isNew) {
          this.router.navigate(['dashboard/automated-messaging/fbm'],
            { queryParams: { created: true } });
        } else {
          this.router.navigate(['dashboard/automated-messaging/fbm'],
            { queryParams: { created: false } });
        }
      } else {
        this.showSaveTemplateErrorDialog();
      }
    });
  }

  public showEmptyFbmProductsDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'fbm-missing-product'
      },
    }).afterClosed().subscribe((data) => {
      if (data === 'navigate') {
        this.router.navigate(['dashboard/automated-messaging/fba']);
      }
      if (data === 'report') {
        this.userService.reportFbmProductsMissing(this.userService.currentUser.sellerId).subscribe(res => {
          if (res.isSuccess) {
            this.userService.showNotification = true;
            this.notificationTitle = this.textService.currentText.success;
            this.notificationMessage = this.textService.currentText.fbmMissingProductsReport
          }
        });
      }
    });
  }

  public showImageFileValidation() {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.invalidFormat,
        'message': this.textService.currentText.imageLoadValidation
      },
    }).afterClosed().subscribe(() => {
      this.userService.loaderObesrver.next(false);
    });
  }

  public testTemplate(): void {
    if (this.userService.currentPage === 'fba') {
      this.isFbm = false;
    } else {
      this.isFbm = true;
    }
    const testTemplateData = {
      publicId: this.userService.currentUser.id,
      subject: this.defaultFbaTemplate.subject,
      productsSKU: this.skusList,
      invoiceTemplateId: this.defaultFbaTemplate.invoiceTemplateId,
      isFbm: this.isFbm,
      channels: this.defaultFbaTemplate.channellist,
      isSuperUser: this.userService.isSuperUser,
      content: this.editor.summernote('code'),
      attachment: this.currentFiles.toString()
    };
    if (!this.isHaveShortName && testTemplateData.content.includes('&lt;product-short-name&gt;')) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'short-name-awareness',
        },
      }).afterClosed().subscribe(result => {
        if (result) {
          this.userService.loaderObesrver.next(true);
          this.templateService.testTemplate(testTemplateData).subscribe((response) => {
            this.userService.showNotification = true;
            this.notificationMessage = response.message;
            if (response.message === 'Message was not sent .. please try again.') {
              this.notificationTitle = this.textService.currentText.fail;
            } else {
              this.notificationTitle = this.textService.currentText.success;
            }
            this.userService.loaderObesrver.next(false);
          });
        }
      });
    } else {
      this.userService.loaderObesrver.next(true);
      this.templateService.testTemplate(testTemplateData).subscribe((response) => {
        this.userService.showNotification = true;
        this.notificationMessage = response.message;
        if (response.message === 'Message was not sent .. please try again.') {
          this.notificationTitle = this.textService.currentText.fail;
        } else {
          this.notificationTitle = this.textService.currentText.success;
        }
        this.userService.loaderObesrver.next(false);
      });
    }
  }

  public canDeactivate(): any {
    this.defaultFbaTemplate.content = this.editor.summernote('code');
    this.checkChanges();
    if (!this.dataChanged || this.defaultFbaTemplate.saveTemplate) {
      return true;
    } else {
      return this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'leave-confirm',
        },
      }).afterClosed();
    }
  }

  private displayError(): void {
    if (!this.fbaForm.controls.name.valid) {
      this.errors.name = true;
      const old_value = this.fbaForm.value.name;
      this.fbaForm.patchValue({
        name: this.textService.currentText.emptyNameError
      });
      this.fbaForm.controls['name'].disable();
      this.name.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.fbaForm.controls.subject.valid) {
      this.errors.subject = true;
      const old_value = this.fbaForm.value.subject;
      this.fbaForm.patchValue({
        subject: this.textService.currentText.emptySubjectError
      });
      this.fbaForm.controls['subject'].disable();
      this.subject.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.fbaForm.controls.when.valid) {
      this.errors.when = true;
      this.resetErrorState('');
      return;
    }
    if (!this.fbaForm.controls.after.valid) {
      this.errors.after = true;
      this.resetErrorState('');
      return;
    }
    if (this.fbaForm.controls.subject2 && !this.fbaForm.controls.subject2.valid) {
      this.errors.subject2 = true;
      const old_value = this.fbaForm.value.subject2;
      this.fbaForm.patchValue({
        subject2: this.textService.currentText.emptySubjectError
      });
      this.fbaForm.controls['subject2'].disable();
      this.subject.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
  }

  public openOnlyRepBuyersTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'onlyRepBuyers-tooltip',
      },
    }).afterClosed().subscribe();
  }

  public checkForm(): void {
    if (!this.fbaForm.valid) {
      window.scrollTo(0, 0);
      this.displayError();
    } else {
      this.saveTemplate();
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (!this.fbaForm.controls.name.valid) {
        this.errors.name = false;
        this.fbaForm.patchValue({
          name: val,
        });
        this.fbaForm.controls['name'].enable();
      }
      if (!this.reviewRequest) {
        if (!this.fbaForm.controls.subject.valid) {
          this.errors.subject = false;
          this.fbaForm.patchValue({
            subject: val,
          });
          this.fbaForm.controls['subject'].enable();
        }
      }
      if (this.fbaForm.controls.subject2 && !this.fbaForm.controls.subject2.valid) {
        this.errors.subject2 = false;
        this.fbaForm.patchValue({
          subject2: val,
        });
        this.fbaForm.controls['subject2'].enable();
      }
      if (!this.fbaForm.controls.when.valid) {
        this.errors.when = false;
      }
      if (!this.fbaForm.controls.after.valid) {
        this.errors.after = false;
      }
    }, 2000);
  }


  public openStatusTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'status-tooltip',
      },
    }).afterClosed().subscribe();
  }

  public openFeedbackTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'feedback-tooltip',
      },
    }).afterClosed().subscribe();
  }

  public openOrderTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'order-tooltip',
      },
    }).afterClosed().subscribe();
  }

  public openRepBuyersTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'repBuyers-tooltip',
      },
    }).afterClosed().subscribe();
  }

  public openPromotionGivenTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'promotionGiven-tooltip',
      },
    }).afterClosed().subscribe();
  }

  public openPromotionValueTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'promotionValue-tooltip',
      },
    }).afterClosed().subscribe();
  }

  public openSpecSalesChannelsTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'specSalesChannels-tooltip',
      },
    }).afterClosed().subscribe();
  }

  public openCouponTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'addCoupon-tooltip',
      },
    }).afterClosed().subscribe();
  }

  public openVariablesTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'variables-tooltip',
      },
    }).afterClosed().subscribe();
  }

  public openAddFileTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'addfile-tooltip',
      },
    }).afterClosed().subscribe();
  }

  public openTimingTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.templateOption9,
        'message': this.textService.currentText.timingTooltipText,
      },
    }).afterClosed().subscribe();
  }

  public openExludeProductTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.excludeProducts,
        'message': this.textService.currentText.excludeProductsTootltip,
      },
    }).afterClosed().subscribe();
  }

  public openResetOpenRateConfirm(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'reset-openRate',
      },
    }).afterClosed().subscribe(res => {
      if (res) {
        this.templateService.resetOpenRate(this.userService.currentUser.id, this.defaultFbaTemplate.templateid).subscribe();
      }
    });
  }

  public showSaveTemplateErrorDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.saveTemplateErrorTilte,
        'message': this.textService.currentText.saveTemplateErrorText,
      },
    }).afterClosed().subscribe();
  }

  public showSaveFirstDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.imageUploading,
        'message': this.textService.currentText.imageUploadingMessage
      },
    }).afterClosed().subscribe(() => {
      this.userService.loaderObesrver.next(false);
    });
  }


}
