import {Component} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {TextService} from '../../../services/text.service';

@Component({
  selector: 'ninja-keywords-optimizer',
  templateUrl: './keywords-optimizer.component.html',
  styleUrls: ['./keywords-optimizer.component.scss']
})

export class KeywordsOptimizerComponent {
  public firstTextArea = '';
  public secondTextArea = '';
  public result = '';
  public resultField = [];
  public first = [];
  public second = [];

  constructor(public userService: UserService,
              public textService: TextService) {
  }

  public textOptimizer(): void {
    let tempFirstString = this.firstTextArea.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi," ");
    let secondFirstString = this.secondTextArea.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi," ");
    this.resultField = [];
    this.first = tempFirstString.split(' ');
    let tempSecond = secondFirstString.split(' ')
    for (let y = 0; tempSecond.length > y; y++) {
      tempSecond[y] = tempSecond[y].toLowerCase();
    }
    let tempFirst = tempFirstString.split(' ')
    for (let y = 0; tempFirst.length > y; y++) {
      if (!this.resultField.includes(tempFirst[y]) && this.resultField.indexOf(tempFirst[y].toLowerCase()) === -1) {
        this.resultField.push(tempFirst[y])
      }
    }
    if (tempFirst.length > 0) {
      for (let i = 0; tempFirst.length > i; i++) {
        if (tempSecond.indexOf(tempFirst[i].toLowerCase()) > -1
          && this.resultField[this.resultField.indexOf(tempFirst[i])]
          && tempFirst[i].toLowerCase() === this.resultField[this.resultField.indexOf(tempFirst[i])].toLowerCase()) {
          this.resultField.splice(this.resultField.indexOf(tempFirst[i]), 1);
        }
      }
      this.result = this.resultField.join(' ');
    }
  }
}
