<div class="upgrade-holder">
    <div class="close-btn" (click)="mixpanelService.track('q upgrade - subscribe
        master
        clicked',{'price':data.extra,'type':
        '4','popup':'ignored'});dialogRef.close(false)"></div>
    <div class="row">
        <div class="col-md-12">
            <h1 mat-dialog-title class="title">{{textService.currentText.dialogUpgradeTitle}}</h1>
            <div class="content">
                <h2 class="sub-title pt-4">{{textService.currentText.dialogUpgradeSubTitle}}</h2>
                <img class="img" src="../../../../assets/images/ninja_img.png"
                    />
            </div>
            <div class="p-top-60">
                <h1 class="text-title pt-3">What's included?</h1>
                <p class="text-content pt-2">Asin tracker - see the position of
                    your
                    products by keywords</p>
                <p class="text-content pt-2">Facebook bot - get more leads by
                    using
                    automated conversations on
                    Facebook and drive traffic to your Amazon pages</p>
            </div>
        </div>
        <div class="col-md-12 mt-5 d-flex justify-content-center">
            <div class="upgrade-price">
                <span class="price"> {{ '+$' + data.extra}}
                </span>
                <span class="month">per month</span>
            </div>
            <div class="upgrade-add pl-4">
                <span class="text-content"> Add One - Get many
                </span><br>
                <span class="pay-regions">Pay for one and get it for ALL your
                    regions!</span>
            </div>
        </div>
        <div class="col-md-12">
            <div class="text-center">
                <button class="subscribe-btn blue mt-4"
                    (click)="mixpanelService.track('q upgrade - subscribe master
                    clicked',{'price':data.extra,'type':'4','popup':'clicked'});dialogRef.close('MONTHLY_MASTER_NINJA')">
                    Try it now
                </button>
                <button class="subscribe-btn skip mt-4"
                    (click)="mixpanelService.track('q upgrade - subscribe master
                    clicked',{'price':data.extra,'type':4,'popup':'ignored'});dialogRef.close()">
                    Skip
                </button>
            </div>
        </div>
    </div>
</div>
