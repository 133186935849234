import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {Functions} from '../../../services/functions';
import {Router} from '@angular/router';
import {ConstService} from '../../../services/const-service';
import {UserService} from '../../../services/user.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import {TextService} from '../../../services/text.service';

export class State {
  constructor(public login: string, public id?: string) {
  }
}

@Component({
  selector: 'ninja-super-user',
  templateUrl: './super-user.component.html',
  styleUrls: ['./super-user.component.scss']
})

export class SuperUserComponent implements OnInit {
  @ViewChild('pass', { static: false }) passInput: ElementRef;

  public stateCtrl: FormControl;
  public filteredStates: Observable<any[]>;
  public errors = {passwordInvalid: false};
  public pass = '';
  public passwordInputType = 'password';
  public currentUser;
  public showUsers: boolean;
  public userList: State[];
  public superUserForm = this.fb.group({
    password: ['',
      [Validators.required]]
  });
  public noData = true;

  constructor(public matDialog: MatDialog,
              public userService: UserService,
              public textService: TextService,
              public fb: FormBuilder,
              public ref: ChangeDetectorRef,
              public router: Router,
              public constService: ConstService,
              public functions: Functions) {
    this.stateCtrl = new FormControl();
  }

  public ngOnInit(): void {
    if (this.userService.superUserActive) {
      this.showUsers = true;
    }
  }

  public loginSuperUser(): void {
    if (!this.superUserForm.valid) {
      this.errors.passwordInvalid = true;
      this.displayError(this.textService.currentText.errorEmptyPass);
      return;
    }
    this.userService.superUserCheck(this.superUserForm.controls['password'].value).subscribe((data) => {
      if (data.isSuccess) {
        this.userService.superUserActive = true;
        this.showUsers = !this.showUsers;
      }
    });
  }

  public getSuperUserList(): void {
    this.userService.superUserList().subscribe((data) => {
      this.userList = data.list;
      this.ref.detectChanges();
      this.filteredStates = this.stateCtrl.valueChanges
        .pipe(
          startWith(''),
          map(state => state ? this.filterStates(state) : this.userList.slice())
        );
    });
    this.noData = false;
  }

  public loginAsUser(): void {
    if (this.currentUser) {
      this.userService.superUser(this.currentUser).subscribe((data) => {
        this.userService.currentUser.id = data.publicId;
        this.userService.currentUser.sellerId = data.sellerId;
        this.userService.currentUser.emails = data.emails;
        this.userService.currentUser.emailFrom = data.emailFrom;
        this.userService.currentUser.marketplace = data.marketplace;
        this.userService.isSuperUser = true;
        this.ref.detectChanges();
        this.router.navigateByUrl('login', {skipLocationChange: true}).then(() =>
          this.router.navigate(['dashboard/dashboard-schedules']));
      });
    }
  }

  private displayError(message: string): void {
    this.userService.loaderObesrver.next(false);
    if (this.errors.passwordInvalid) {
      this.passwordInputType = 'text';
      const old_value = this.superUserForm.value.password;
      this.superUserForm.setValue({
        password: message
      });
      this.superUserForm.controls['password'].disable();
      this.passInput.nativeElement.blur();
      this.resetErrorState(old_value);
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.passwordInvalid) {
        this.passwordInputType = 'password';
        this.superUserForm.setValue({
          password: val,
        });
        this.errors.passwordInvalid = false;
        this.superUserForm.controls['password'].enable();
      }
    }, 2000);
  }

  public filterStates(name: string): Array<any> {
    return this.userList.filter(state =>
      state.login.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
}
