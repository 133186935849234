<script src="../../dashboard/dashboard-schedules/small-chart/small-chart.component.ts"></script>
<div class="main-dialog-container add-asin">
  <div class="close-btn"></div>
  <div class="header">
    <h2>{{textService.currentText.addKeywords}}</h2>
  </div>
  <div class="dialog-body">
    <div class="step">
      <div class="step-title">
        <div class="number" [class.active]="marketplace">1</div>
        <div>{{textService.currentText.selectMarketplace}}</div>
      </div>
      <div class="select-holder custom-selects">
        <div (click)="functions.setDropDownPosition(allSalesChannelsSelect, 'allSalesChannels')"
             #allSalesChannelsSelect [class.active]="allSalesChannels.panelOpen"
             class="select-wrapper">
          <mat-form-field>
            <mat-select [placeholder]="textService.currentText.selectMarketplace"
                        shouldPlaceholderFloat="false"
                        panelClass="default-dropdown allSalesChannels"
                        #allSalesChannels
                        disableRipple
                        [(ngModel)]="marketplace"
                        name="allSalesChannels">
              <mat-option *ngFor="let select of localChannels"
                          [value]="select.value">
                {{ select.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="step">
      <div class="step-title">
        <div class="number"
             [class.active]="selectedProduct">
          2
        </div>
        <div>{{textService.currentText.selectProductWithAsin}}</div>
      </div>
      <div class="selected-product-holder">
          <div class="col">
              <span class="main">
                {{textService.currentText.productName}}:
              </span>
              <div class="value">
                {{selectedProduct?.fullName || ''}}
              </div>
          </div>
          <div class="col">
            <span class="main">
              {{textService.currentText.asin}}:
            </span>
            <div class="value">
                {{selectedProduct?.asin || ''}}
              </div>
          </div>
          <div class="col">
            <span class="main">
              {{textService.currentText.SKU}}:
            </span>
            <div class="value">
                {{selectedProduct?.SKU || ''}}
              </div>
          </div>
      </div>
      <div class="table">
        <div class="table-header">
          <div class="tr">
            <div class="td">{{textService.currentText.productName}}</div>
            <div class="td">{{textService.currentText.asin}}</div>
            <div class="td">{{textService.currentText.SKU}}</div>
          </div>
        </div>
        <div class="table-body">
          <ng-container *ngFor="let product of products">
            <div class="tr"
                 (click)="selectedProduct = product;"
                 [class.selected]="product.SKU === selectedProduct?.SKU">
              <div class="td">{{product.fullName}}</div>
              <div class="td">{{product.asin}}</div>
              <div class="td">{{product.SKU}}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="step">
      <div class="step-title">
        <div class="number" [class.active]="keywords">
          3
        </div>
        <div>{{textService.currentText.addKeywordToProduct}}</div>
      </div>
      <input matInput
             type="text"
             [(ngModel)]="keywords"
             [placeholder]="textService.currentText.keywordPlaceholder">
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="primary-btn white" (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="primary-btn"
            [disabled]="!marketplace || !selectedProduct || !keywords ||  keywords?.length === 0"
            (click)="saveAsin()">
      {{textService.currentText.save}}
    </button>
  </div>
</div>
