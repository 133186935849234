import {Component} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {TextService} from '../../../services/text.service';

@Component({
  selector: 'ninja-invite-friend',
  templateUrl: './invite-friend.component.html',
  styleUrls: ['./invite-friend.component.scss']
})

export class InviteFriendComponent {

  constructor(public userService: UserService,
              public textService: TextService) {
  }
}
