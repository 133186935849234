import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { ConstService } from '../../../services/const-service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { Functions } from '../../../services/functions';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { TokenService } from '../../../services/token.service';
import { TextService } from '../../../services/text.service';
import { ContactUsDialogComponent } from '../../dialogs/contact-us-dialog/contact-us-dialog.component';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
    selector: 'ninja-dashboard-sidenav',
    templateUrl: './dashboard-sidenav.component.html',
    styleUrls: ['./dashboard-sidenav.component.scss']
})

export class DashboardSidenavComponent implements OnInit {
    // public currentCountry: any;
    public ordersPage: any;
    @ViewChild('scrollMe') private myScrollContainer: ElementRef;
    public lockMenu: any;
    @Output() links: EventEmitter<any> = new EventEmitter();;
    constructor(public matDialog: MatDialog,
        public ref: ChangeDetectorRef,
        public constService: ConstService,
        @Inject(DOCUMENT) private _document: Document,
        public userService: UserService,
        public textService: TextService,
        public tokenService: TokenService,
        public mixpanelService: MixpanelService,
        public router: Router,
        public functions: Functions) {
        this.mixpanelService.init(null);
        if (window.innerWidth < 1200) {
            router.events.subscribe((val) => {
                if (val instanceof NavigationEnd) {
                    this.userService.smallSidebar = false;
                }
            });
        }
    }


    public ngOnInit(): void {
        this.userService.currentCountry = this.userService.currentUser.marketplace || 'US';
    }

    scrollToBottom(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch (err) { }
    }


    public navigateToFbaOrders(): void {
        this.router.navigate(['dashboard/orders'],
            { queryParams: { page: 'fba' } });
        this.userService.currentPage === 'fba-orders';
    }

    public navigateToFbmOrders(): void {
        this.router.navigate(['dashboard/orders'],
            { queryParams: { page: 'fbm' } });
        this.userService.currentPage === 'fbm-orders';
    }

    public addRegion(): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'add-region',
            },
        }).afterClosed().subscribe();
    }

    public changeRegion(): void {
        this.userService.verifyAcc = true;
        if (this.userService.currentCountry === 'UK' || this.userService.currentCountry === 'US' || this.userService.currentCountry === 'FE') {
            for (let i = 0; i < this.userService.currentUser.emails.length; i++) {
                if (this.userService.currentCountry === 'UK') {
                    if (this.userService.currentUser.emails[i].submarket === 'IT') {
                        this.userService.currentCountry = 'IT';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'IT', 'page': 'menu' });
                    } else if (this.userService.currentUser.emails[i].submarket === 'UK') {
                        this.userService.currentCountry = 'UK';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'UK', 'page': 'menu' });
                    } else if (this.userService.currentUser.emails[i].submarket === 'ES') {
                        this.userService.currentCountry = 'ES';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'ES', 'page': 'menu' });
                    } else if (this.userService.currentUser.emails[i].submarket === 'FR') {
                        this.userService.currentCountry = 'FR';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'FR', 'page': 'menu' });
                    } else if (this.userService.currentUser.emails[i].submarket === 'DE') {
                        this.userService.currentCountry = 'DE';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'DE', 'page': 'menu' });
                    } else if (this.userService.currentUser.emails[i].submarket === 'EU') {
                        this.userService.currentCountry = 'EU';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'EU', 'page': 'menu' });
                    }
                } else if (this.userService.currentCountry === 'US') {
                    if (this.userService.currentUser.emails[i].submarket === 'CA') {
                        this.userService.currentCountry = 'CA';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'CA', 'page': 'menu' });
                    } else if (this.userService.currentUser.emails[i].submarket === 'MX') {
                        this.userService.currentCountry = 'MX';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'MX', 'page': 'menu' });
                    } else if (this.userService.currentUser.emails[i].submarket === 'US') {
                        this.userService.currentCountry = 'US';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'US', 'page': 'menu' });
                    }
                } else if (this.userService.currentCountry === 'FE') {
                    if (this.userService.currentUser.emails[i].submarket === 'JP') {
                        this.userService.currentCountry = 'JP';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'JP', 'page': 'menu' });
                    } else if (this.userService.currentUser.emails[i].submarket === 'AU') {
                        this.userService.currentCountry = 'AU';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'AU', 'page': 'menu' });
                    } else if (this.userService.currentUser.emails[i].submarket === 'SG') {
                        this.userService.currentCountry = 'SG';
                        this.mixpanelService.track('q change region - clicked', { 'region': 'SG', 'page': 'menu' });
                    }
                }
            }
        }
        for (let i = 0; this.userService.currentUser.emails.length > i; i++) {
            if (this.userService.currentUser.emails[i].submarket === this.userService.currentCountry) {
                this.userService.switchRegion(this.userService.currentUser.emails[i].subid).subscribe((userData) => {
                    this.userService.currentUser.id = userData.id;
                    if (!userData.isVerified) {
                        this.router.navigate(['dashboard/add-region'],
                            { queryParams: { id: this.userService.currentUser.emails[i].submarket } });
                    } else {
                        this.userService.verifyAcc = userData.isVerified;
                        this.constService.setRegions(userData);
                        this.userService.currentUser = userData;
                        this.ref.detectChanges();
                        this.tokenService.setToken(userData);
                        this.userService.regionChanged.next(true);
                        this.userService.getGrantAccessUrls(this.userService.currentUser.id).subscribe((links) => {
                            this.links.emit(links);
                        });
                        if (!this.userService.currentUser.hasActivePlan && !this.userService.currentUser.isTrial && !this.userService.currentUser.isVa) {

                            if (this.router.url.split('/')[2].includes('upgrade')) {
                                this.router.navigate(['dashboard/upgrade'], { queryParams: { region: this.userService.currentUser.marketplace } });
                                // this._document.defaultView.location.reload();
                                // console.log("this.userService.currentUser.marketplace", this.userService.currentUser.marketplace);
                            } else {
                                this.checkDestination();
                                // this.router.navigateByUrl('dashboard/upgrade', {skipLocationChange: true}).then(() =>
                                // this.router.navigate(['dashboard/dashboard-schedules']));
                            }
                        } else {
                            this.checkDestination();
                        }
                    }
                });
            }
        }
    }

    public navigateToFAQ() {
        if (this.router.url === '/dashboard/dashboard-schedules') {
            this.router.navigate(['dashboard/FAQ'], { queryParams: { hash: 'dashbaord-scroll' } });
        } else if (this.router.url.split('/')[3] === 'bulk') {
            this.router.navigate(['dashboard/FAQ'], { queryParams: { hash: 'bulk-scroll' } });
        } else if (this.router.url.split('/')[2] === 'automated-messaging') {
            this.router.navigate(['dashboard/FAQ'], { queryParams: { hash: 'automated-scroll' } });
        } else if (this.router.url === '/facebook-notifications') {
            this.router.navigate(['dashboard/FAQ'], { queryParams: { hash: 'notification-scroll' } });
        } else if (this.router.url === '/money-back') {
            this.router.navigate(['dashboard/FAQ'], { queryParams: { hash: 'money-back-scroll' } });
        } else if (this.router.url === '/dashboard/orders?page=fba' ||
            this.router.url === '/dashboard/orders?page=fbm' ||
            this.router.url === '/dashboard/orders/download-report' ||
            this.router.url === '/dashboard/orders/send-message') {

            this.router.navigate(['dashboard/FAQ'], { queryParams: { hash: 'orders-scroll' } });
        } else {
            this.router.navigate(['dashboard/FAQ']);
        }
    }

    public getImage(): string {
        if (this.userService.currentCountry === 'IT' || this.userService.currentCountry === 'UK' ||
            this.userService.currentCountry === 'FR' || this.userService.currentCountry === 'DE' ||
            this.userService.currentCountry === 'EU' || this.userService.currentCountry === 'ES' || this.userService.currentCountry === 'UAE' || this.userService.currentCountry === 'SA') {
            this.userService.currentCountry = 'UK';
        } else if (this.userService.currentCountry === 'CA' || this.userService.currentCountry === 'MX' ||
            this.userService.currentCountry === 'US') {
            this.userService.currentCountry = 'US';
        } else if (this.userService.currentCountry === 'JP' || this.userService.currentCountry === 'AU' ||
            this.userService.currentCountry === 'SG') {
            this.userService.currentCountry = 'FE';
        }
        return this.constService.countries.filter(item => item.value === this.userService.currentCountry)[0].image;
    }

    public showContactUs(): void {
        this.matDialog.open(ContactUsDialogComponent, {
            data: {},
        }).afterClosed().subscribe((res) => {
            if (res === true) {
                this.userService.notificationMessage = this.textService.currentText.headerMessageSent;
                this.userService.notificationTitle = this.textService.currentText.success;
                this.userService.showNotification = true;
            }
        });
    }

    public checkDestination(): any {
        if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isOrderDashboard) {
            return this.router.navigateByUrl('dashboard/upgrade', { skipLocationChange: true }).then(() =>
                this.router.navigate(['dashboard/orders-dashboard']));
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isDashboard) {
            return this.router.navigate(['dashboard/dashboard-schedules']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAutoTemplates) {
            return this.router.navigate(['dashboard/automated-messaging/fba']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAlerts) {
            return this.router.navigate(['dashboard/facebook-notifications']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isMoneyBack) {
            return this.router.navigate(['dashboard/money-back']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isHijacked) {
            return this.router.navigate(['dashboard/hijacked']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isOrders) {
            return this.router.navigate(['dashboard/orders'], { queryParams: { page: 'fba' } });
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isKeyWordOptimzer) {
            return this.router.navigate(['dashboard/keywords-optimizer']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isConversation) {
            return this.router.navigate(['dashboard/facebook-bot-manager/grant-access']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAsin) {
            return this.router.navigate(['dashboard/asin-tracker']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAffiliate) {
            return this.router.navigate(['dashboard/affiliate']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isCoupons) {
            return this.router.navigate(['dashboard/coupons']);
        } else {
            return this.router.navigate(['dashboard/upgrade']);
        }
    }
}
