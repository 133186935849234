import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {MoneyBackService} from '../../../services/money-back.service';
import { MatDialog } from '@angular/material/dialog';
import {MoneyBackDialogComponent} from '../../dialogs/money-back-dialog/money-back-dialog.component';
import {FormBuilder, Validators} from '@angular/forms';
import {GoogleAnalyticsEventsService} from '../../../services/google-analytics.service';
import {TextService} from '../../../services/text.service';

@Component({
  selector: 'ninja-money-back',
  templateUrl: './money-back.component.html',
  styleUrls: ['./money-back.component.scss']
})

export class MoneyBackComponent implements OnInit {
  @ViewChild('requests', { static: true }) requestsInput: ElementRef;
  @ViewChild('moneyback', { static: false }) moneybackInput: ElementRef;
  public adjustmentRequests: any;
  public isAdjustment = false;
  public typeOfRequest = 'number';
  public typeOfMoneyBack = 'number';
  public endDate: any;
  public errors: any = {};
  public startDate: any;
  public submitForm: any;
  public beforeDate: any;
  public afterDate: any;
  public after: any;
  public before: any;
  public notificationMessage: string;
  public notificationTitle: string;

  constructor(public userService: UserService,
              public textService: TextService,
              public moneyBackService: MoneyBackService,
              public fb: FormBuilder,
              public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
              public ref: ChangeDetectorRef,
              public matDialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.getInventory();
  }

  public getInventory(): void {
    this.clearAllDate();
    if (!this.isAdjustment) {
      this.submitForm = this.fb.group({
        requests: ['', [Validators.required, Validators.min(1), Validators.max(20)]],
        moneyback: ['', [Validators.required, Validators.min(30)]]
      });
      this.userService.loaderObesrver.next(true);
      this.moneyBackService.moneybackGetInventoryInfo(this.userService.currentUser.id).subscribe((data) => {
        this.adjustmentRequests = data;
        this.userService.loaderObesrver.next(false);
      });
    }
  }


  public clearAllDate() {
    this.after = false;
    this.before = false;
    this.clearAfterDate();
    this.clearBeforeDate();
  }

  public clearAfterDate() {
    if (!this.after) {
      this.afterDate = undefined;
    }
  }

  public clearBeforeDate() {
    if (!this.before) {
      this.beforeDate = undefined;
    }
  }

  public getAdjustment(): void {
    this.clearAllDate();
    if (this.isAdjustment) {
      this.submitForm = this.fb.group({
        requests: ['', [Validators.required, Validators.min(1), Validators.max(20)]]
      });
      this.userService.loaderObesrver.next(false);
      this.moneyBackService.moneybackGetInfo(this.userService.currentUser.id).subscribe((data) => {
        if (data.isSuccess === false) {
          this.adjustmentRequests = {
            amazonEmail: '',
            daysBetweenRequests: 0,
            isActive: false,
            numberOfRequests: 0,
            unresolvedRequests: data.unresolvedRequests,
          };
        } else {
          this.adjustmentRequests = data;
        }
        this.userService.loaderObesrver.next(false);
      });
    }
  }

  public showAutoMoneyBackDialog(): void {
    this.matDialog.open(MoneyBackDialogComponent, {
      data: {
        'dialogFor': 'auto-money-back',
        'adjustments': this.isAdjustment,
        'data': this.adjustmentRequests
      },
    }).afterClosed().subscribe((result) => {
      if (result) {
        if (this.isAdjustment) {
          this.getAdjustment();
        } else {
          this.getInventory();
        }
      }
    });
  }

  public sendManualForm(): void {
    if (this.checkForm()) {
      const moneyBackInfo = {
        publicId: this.userService.currentUser.id,
        numberOfRequests: this.submitForm.controls['requests'].value,
        beforeDate: this.beforeDate,
        afterDate: this.afterDate,
        lateRefundGuaranteeValue: undefined
      };
      if (!this.isAdjustment) {
        moneyBackInfo.lateRefundGuaranteeValue = this.submitForm.controls['moneyback'].value;
        this.moneyBackService.moneybackGoInventory(moneyBackInfo).subscribe(() => {
          this.userService.showNotification = true;
          this.notificationMessage = this.textService.currentText.emailHasBeenSent;
          this.notificationTitle = this.textService.currentText.success;
        });
      } else {
        this.moneyBackService.moneybackGoInfo(moneyBackInfo).subscribe(() => {
          this.userService.showNotification = true;
          this.notificationMessage = this.textService.currentText.emailHasBeenSent;
          this.notificationTitle = this.textService.currentText.success;
        });
      }
    }
  }

  public checkForm() {
    if (!this.submitForm.valid) {
      if (!this.submitForm.controls.requests.valid || this.submitForm.controls.requests.value > 20) {
        this.typeOfRequest = 'text';
        setTimeout(() => {
          this.errors.requests = true;
          this.displayError(this.textService.currentText.errorMoreThan0Less20);
          return;
        });
        return;
      }
      if (this.submitForm.controls.moneyback && !this.submitForm.controls.moneyback.valid) {
        this.typeOfMoneyBack = 'text';
        setTimeout(() => {
          this.errors.moneyback = true;
          this.displayError(this.textService.currentText.errorMoreThan30);
          return;
        });
      }
      return false;
    }
    return this.submitForm.valid;
  }

  private displayError(message?: string): void {
    if (!this.submitForm.controls.requests.valid) {
      this.errors.requests = true;
      const old_value = this.submitForm.value.requests;
      this.submitForm.patchValue({
        requests: message
      });
      this.ref.detectChanges();
      this.submitForm.controls['requests'].disable();
      this.requestsInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.moneyback.valid) {
      this.errors.moneyback = true;
      const old_value = this.submitForm.value.moneyback;
      // this.submitForm.patchValue({
      //   moneyback: message
      // });
      this.submitForm.controls['moneyback'].disable();
      this.moneybackInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.requests) {
        this.submitForm.patchValue({
          requests: val,
        });
        this.typeOfRequest = 'number';
        this.errors.requests = false;
        this.submitForm.controls['requests'].enable();
      }
      if (this.errors.moneyback) {
        this.submitForm.patchValue({
          moneyback: val,
        });
        this.typeOfMoneyBack = 'number';
        this.errors.moneyback = false;
        this.submitForm.controls['moneyback'].enable();
      }
    }, 2000);
  }
}
