import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { OrdersService } from '../../../services/orders.service';
import { ConstService } from '../../../services/const-service';
import { Functions } from '../../../services/functions';
import { SelectSpecificProductsDialogComponent } from '../../dialogs/select-specific-products-dialog/select-specific-products.component';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { GoogleAnalyticsEventsService } from '../../../services/google-analytics.service';
import { TextService } from '../../../services/text.service';
import { AddressDialogComponent } from '../../dialogs/address-dialog/address-dialog.component';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'ninja-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})

export class OrdersComponent implements OnInit {
  public showDetails = false;
  public isFilters: boolean;
  public dateTo = null;
  public dateFrom = null;
  public dateToSendFrom = null;
  public dateToSendTo = null;
  public currentTemplates = [];
  public filters: any;
  public page: any;
  public showProductTooltip: any;
  public prevToken: any;
  public nextToken: any;
  public isNextDisabled = true;
  public isPreviousDisabled = true;
  public minDateFrom: Date;
  isTooltip: any;

  constructor(public router: Router,
    public userService: UserService,
    public ordersService: OrdersService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public constService: ConstService,
    public textService: TextService,
    public functions: Functions,
    public matDialog: MatDialog,
    public ref: ChangeDetectorRef,
    public activatedRoute: ActivatedRoute,
    public mixpanelService: MixpanelService) {
    this.mixpanelService.init(null);
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['page'] == 'fba') {
        this.userService.loaderObesrver.next(true);
      }
      if (params['page'] == 'fbm') {
        this.userService.loaderObesrver.next(true);
      }

      setTimeout(() => {
        this.userService.loaderObesrver.next(false);
      }, 500);
    });
    this.filters = {
      orderType: null,
      allProducts: true,
      selectedProducts: [],
      buyerName: null,
      buyerEmail: null,
      orderId: null,
      refundOrders: null,
      pormotion: null,
      reviewRequest: null,
    };
  }


  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.page = params.page;
      this.dateTo = null;
      this.dateFrom = null;
      this.filters = {
        orderType: null,
        allProducts: true,
        selectedProducts: [],
        buyerName: null,
        buyerEmail: null,
        orderId: null,
        refundOrders: null,
        promotion: null,
        reviewRequest: null,
      };
      this.currentTemplates = [];
    });
    this.setMinDateFrom();
    this.searchOrders();
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.searchOrders();
    }
  }

  public setMinDateFrom(): void {
    this.minDateFrom = new Date();
    this.minDateFrom.setMonth(this.minDateFrom.getMonth() - 1);
  }

  public searchOrders(pageToken?): void {
    if (this.dateFrom !== null) {
      this.dateToSendFrom = `${this.dateFrom.getFullYear()}-${this.dateFrom.getMonth() + 1}-${this.dateFrom.getDate()}`;
    }
    if (this.dateTo !== null) {
      this.dateToSendTo = `${this.dateTo.getFullYear()}-${this.dateTo.getMonth() + 1}-${this.dateTo.getDate()}`;
    }

    if (this.filters.reviewRequest) {
      this.userService.loaderObesrver.next(true);
      const searchData = {
        publicId: this.userService.currentUser.id,
        from: this.dateToSendFrom,
        to: this.dateToSendTo,
        products: this.filters.selectedProducts,
        token: pageToken ? pageToken : undefined,

      }
      if (this.page === 'fba') {
        this.userService.loaderObesrver.next(true);
        this.ordersService.getFbaReviewOrdersData(searchData).subscribe((data) => {
          if (data.length === 0 && !dialogIsShown && this.userService.currentUser.isTrial && !this.userService.currentUser.hasActivePlan) {
            this.showTrialHistoryDialog();
          }
          this.nextToken = data.nextToken;
          this.prevToken = data.prevToken;
          if (this.nextToken > 0) {
            this.isNextDisabled = false;
          } else {
            this.isNextDisabled = true;
          }
          if (this.prevToken > 0) {
            this.isPreviousDisabled = false;
          } else {
            this.isPreviousDisabled = true;
          }
          this.currentTemplates = data.ordersList;
          this.currentTemplates.map(item => {
            let mpImage = this.get_flag(item.salesChannel.split("."));
            item["mpImage"] = mpImage;
          });
          this.updateOrderIndex();
          this.userService.loaderObesrver.next(false);
        });
      } else {
        this.ordersService.getFbmReviewOrdersData(searchData).subscribe((data) => {
          if (data.length === 0 && !dialogIsShown && this.userService.currentUser.isTrial && !this.userService.currentUser.hasActivePlan) {
            this.showTrialHistoryDialog();
          }
          this.nextToken = data.nextToken;
          this.prevToken = data.prevToken;
          if (this.nextToken > 0) {
            this.isNextDisabled = false;
          } else {
            this.isNextDisabled = true;
          }
          if (this.prevToken > 0) {
            this.isPreviousDisabled = false;
          } else {
            this.isPreviousDisabled = true;
          }
          this.currentTemplates = data.ordersList;
          this.currentTemplates.map(item => {
            let mpImage = this.get_flag(item.salesChannel.split("."));
            item["mpImage"] = mpImage;
          });
          this.updateOrderIndex();
          this.userService.loaderObesrver.next(false);
        });
      }

      return;
    }


    const searchData = {
      publicId: this.userService.currentUser.id,
      orderId: this.filters.orderId ? this.filters.orderId.trim() : null,
      buyerEmail: this.filters.buyerEmail ? this.filters.buyerEmail.trim() : null,
      buyerName: this.filters.buyerName ? this.filters.buyerName.trim() : null,
      from: this.dateToSendFrom,
      to: this.dateToSendTo,
      products: this.filters.selectedProducts,
      refundOrders: this.filters.refundOrders,
      promotion: this.filters.promotion,
      reviewRequest: this.filters.reviewRequest,
      token: pageToken ? pageToken : undefined,
    };
    const currentDate = new Date();
    const from = + new Date(this.dateFrom);
    const to = + new Date(this.dateTo);
    const dateToCompare = + new Date().setDate(currentDate.getDate() - 30);
    let dialogIsShown = false;
    if (this.dateFrom !== null && this.userService.currentUser.isTrial && !this.userService.currentUser.hasActivePlan) {
      if (from < dateToCompare) {
        this.showTrialHistoryDialog();
        dialogIsShown = true;
      }
    }
    if (!dialogIsShown) {
      if (this.dateTo !== null && this.userService.currentUser.isTrial && !this.userService.currentUser.hasActivePlan) {
        if (to < dateToCompare) {
          this.showTrialHistoryDialog();
        }
      }
    }
    if (this.page === 'fba') {
      this.userService.loaderObesrver.next(true);
      this.ordersService.getFbaOrdersData(searchData).subscribe((data) => {
        if (data.length === 0 && !dialogIsShown && this.userService.currentUser.isTrial && !this.userService.currentUser.hasActivePlan) {
          this.showTrialHistoryDialog();
        }
        this.nextToken = data.nextToken;
        this.prevToken = data.prevToken;
        if (this.nextToken > 0) {
          this.isNextDisabled = false;
        } else {
          this.isNextDisabled = true;
        }
        if (this.prevToken > 0) {
          this.isPreviousDisabled = false;
        } else {
          this.isPreviousDisabled = true;
        }
        this.currentTemplates = data.ordersList;
        this.currentTemplates.map(item => {
          let mpImage = this.get_flag(item.salesChannel.split("."));
          item["mpImage"] = mpImage;
        });
        this.updateOrderIndex();
        this.userService.loaderObesrver.next(false);
      });
    } else {
      this.userService.loaderObesrver.next(true);
      this.ordersService.getFbmOrdersData(searchData).subscribe((data) => {
        if (data.length === 0 && !dialogIsShown && this.userService.currentUser.isTrial && !this.userService.currentUser.hasActivePlan) {
          this.showTrialHistoryDialog();
        }
        this.nextToken = data.nextToken;
        this.prevToken = data.prevToken;
        if (this.nextToken > 0) {
          this.isNextDisabled = false;
        } else {
          this.isNextDisabled = true;
        }
        if (this.prevToken > 0) {
          this.isPreviousDisabled = false;
        } else {
          this.isPreviousDisabled = true;
        }
        this.currentTemplates = data.ordersList;
        this.currentTemplates.map(item => {
          let mpImage = this.get_flag(item.salesChannel.split("."));
          item["mpImage"] = mpImage;
        });
        this.updateOrderIndex();
        this.userService.loaderObesrver.next(false);
      });
    }
  }

  public updateOrderIndex(): void {
    let count = this.prevToken;
    
    if (this.currentTemplates.length) {
      for (let i = 0; i < this.currentTemplates.length; i++) {
        count++;
        this.currentTemplates[i].index = count;
      }
    }
  }

  public showTrialHistoryDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'trial-mounth-history',
      },
    }).afterClosed().subscribe();
  }

  public getFilterDisable(): boolean {
    return this.filters.reviewRequest;
  }

  public checkValue(): void {


    if (this.filters.orderType === 'refundOrders') {
      this.filters.refundOrders = true;
      this.filters.promotion = null;
      this.filters.reviewRequest = null;
    } else if (this.filters.orderType === 'promotions') {
      this.filters.promotion = true;
      this.filters.refundOrders = null;
      this.filters.reviewRequest = null;
    } else if (this.filters.orderType === 'reviewRequest') {
      this.filters.promotion = null;
      this.filters.refundOrders = null;
      this.filters.reviewRequest = true;

      this.searchOrders();
    } else {
      this.filters.refundOrders = null;
      this.filters.promotion = null;
      this.filters.reviewRequest = null;
    }
  }


  public goToSendMessage(order): void {
    this.activatedRoute.queryParams.subscribe(data => {
      this.page = data.page;
      if (this.page === 'fba') {
        this.userService.currentPage = 'fba-orders';
      } else {
        this.page = 'fbm';
        this.userService.currentPage = 'fbm-orders';
      }
    });
    this.router.navigate(['dashboard/orders/send-message'],
      { queryParams: { buyerEmail: order.buyerEmail, buyerName: order.buyerName, orderid: order.orderid } });
  }

  public showSpecProducts(): void {
    if (!this.filters.allProducts) {
      this.matDialog.open(SelectSpecificProductsDialogComponent, {
        data: {
          'products': this.filters.selectedProducts
        }
      }).afterClosed().subscribe(result => {
        if (result) {
          this.filters.selectedProducts = result;
          if (this.filters.selectedProducts.length > 0) {
            this.filters.allProducts = '';
            this.filters.productsPlaceholder = 'Specific' + ' (' + this.filters.selectedProducts.length + ')';
          } else {
            this.filters.allProducts = true;
            this.filters.selectedProducts = [];
          }
        } else {
          if (this.filters.selectedProducts.length > 0) {
            this.filters.allProducts = false;
            this.filters.allProducts = '';
            this.filters.productsPlaceholder = 'Specific' + ' (' + this.filters.selectedProducts.length + ')';
          } else {
            this.filters.allProducts = true;
            this.filters.selectedProducts = [];
          }
        }
      });
    } else {
      this.filters.products = [];
      this.filters.allProducts = true;
    }

  }

  public clearFilter(): void {
    this.currentTemplates = [];
    this.filters = {
      orderType: null,
      allProducts: true,
      selectedProducts: [],
      buyerName: null,
      buyerEmail: null,
      orderId: null,
      refundOrders: null,
      pormotion: null,
      reviewRequest: null,
    };
    this.dateTo = null;
    this.dateFrom = null;
  }

  public changeExcludeBuyer(value: boolean, buyerEmail: string): void {
    
    this.ordersService.excludeBuyer(this.userService.currentUser.id, value, buyerEmail).subscribe((data) => {
      if (data) {
        this.userService.showNotification = true;
        this.userService.notificationMessage = this.textService.currentText.optionChanged;
        this.userService.notificationTitle = this.textService.currentText.success;
      }
    });
  }

  public changeExcludeOrder(value: boolean, orderid: string, isFBM: boolean): void {
    this.ordersService.excludeOrder(this.userService.currentUser.id, value, orderid, isFBM).subscribe((data) => {
      if (data) {
        this.userService.showNotification = true;
        this.userService.notificationMessage = this.textService.currentText.optionChanged;
        this.userService.notificationTitle = this.textService.currentText.success;
      }
    });
  }

  public openOrderBlackList(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'order-blacklist',
      },
    }).afterClosed().subscribe();
  }

  public openBuyersBlackList(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'buyers-blacklist',
      },
    }).afterClosed().subscribe();
  }

  public onRequestReview(ev: any, order: any): boolean {
    this.ordersService.reviewRequestClicked(this.userService.currentUser.id, order.orderid, order.sku, this.page === 'fbm').subscribe();
    window.open(order.reviewRequestLink, "_blank");
    order.reviewRequestClicked = true;
    ev.stopPropagation();
    return false;
  }

  public onRequestReview1(order: any) {
    this.ordersService.reviewRequestClicked(this.userService.currentUser.id, order.orderid, order.sku, this.page === 'fbm').subscribe();
  }

  openAddressDialog(order) {
    //this.appLoaderService.open();
    this.matDialog.open(AddressDialogComponent, {
      width: '480px',
      data: { order: order }
    });
    //this.appLoaderService.close();

  }

  get_flag(marketPlaceChangeIcon1) {
    let mapImage = '';
    if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "ca") {
      mapImage = 'assets/images/flag_canada.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com" && marketPlaceChangeIcon1[2] == "mx") {
      mapImage = 'assets/images/flag_mexico.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "co" && marketPlaceChangeIcon1[2] == "uk") {
      mapImage = 'assets/images/flag_uk.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "fr") {
      mapImage = 'assets/images/flag_france.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "de") {
      mapImage = 'assets/images/flag_germany.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "it") {
      mapImage = 'assets/images/flag_italy.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "es") {
      mapImage = 'assets/images/flag_spain.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "co" && marketPlaceChangeIcon1[2] == "jp") {
      mapImage = 'assets/images/japan.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com" && marketPlaceChangeIcon1[2] == "au") {
      mapImage = 'assets/images/australia.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "in") {
      mapImage = 'assets/images/india.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "ae") {
      mapImage = 'assets/images/UAE_Flag.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com" && marketPlaceChangeIcon1[2] == "br") {
      mapImage = 'assets/images/Brazil_br.jpeg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com/Saudi-Arabia") {
      mapImage = 'assets/images/SoudiArabia.jpeg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com" && marketPlaceChangeIcon1[2] == "tr") {
      mapImage = 'assets/images/turkey_tr.jpeg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com") {
      mapImage = 'assets/images/marketplace.png'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "sg") {
      mapImage = 'assets/images/Singapore_sg.jpeg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "nl") {
      mapImage = 'assets/images/Netherlands_nl.jpeg'
    } else {
      mapImage = 'assets/images/noImage.png';
    }
    return mapImage;
  }
}
