import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { StorageBrowser } from '../../services/storage.browser';
import { AppConfigService } from '../../services/app-config.service';
@Injectable()
export class RouteGuardIsLoggedIn implements CanActivate {
  constructor(private userService: UserService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}

@Injectable()
export class CanActivateTeam implements CanActivateChild {
  constructor(public router: Router,
    public userService: UserService,
    public storageBrowser: StorageBrowser,
    public matDialog: MatDialog) {
  }

  canActivateChild(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    // this.userService.currentUser.permissions = this.storageBrowser.get('$UserToken$user').permissions;
    // debugger
    if (!this.userService.verifyAcc) {
      // this.openTAC();
      return false;
    }
    if (!this.userService.currentUser.permissions) {

      if (this.storageBrowser.get('$UserToken$user')) {
        this.userService.currentUser.permissions = this.storageBrowser.get('$UserToken$user').permissions;
      } else {
        this.router.navigate(['login']);
      }
    }
    if(AppConfigService.maintenance) {
      if(state.url === '/dashboard/orders-dashboard' || state.url === '/dashboard/dashboard-schedules') {
        return true;
      } else {
        this.maintenance();
        return false;
      }
    }
    if (this.userService.currentUser.permissions) {
      if ((!this.userService.currentUser.permissions.isOrderDashboard && state.url === '/dashboard/orders-dashboard') && !this.userService.currentUser.permissions.isDashboard) {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isDashboard && state.url === '/dashboard/dashboard-schedules') {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isMoneyBack && state.url === '/dashboard/money-back') {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isHijacked && state.url === '/dashboard/hijacked') {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isAsin && state.url === '/dashboard/asin-tracker') {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isCoupons && state.url === '/dashboard/coupons') {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isAffiliate && state.url === '/dashboard/affiliate') {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isCompetetorAlerts && state.url === '/dashboard/asin-alerts') {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isInvoicing && route.url[0].path === 'invoicing') {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isKeyWordOptimzer && state.url === '/dashboard/keywords-optimizer') {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isInvitations && state.url === '/dashboard/invite-friend') {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isAutoTemplates && route.url[0].path === 'automated-messaging') {
        this.openTAC();
        return false;
      }
      if (!this.userService.currentUser.permissions.isOrders && route.url[0].path === 'orders') {
        this.openTAC();
        return false;
      }
      if ((!this.userService.currentUser.permissions.isConversation || this.userService.currentUser.isMainAccount === false) && route.url[0].path === 'facebook-bot-manager') {
        this.openTAC();
        return false;
      }

    }
    return true;
  }

  public openTAC(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'permissionDenied',
      },
      disableClose: true
    }).afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/dashboard/upgrade']);
      }
    });
  }

  public maintenance(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'maintenance',
      },
      disableClose: true
    });
  }
}
