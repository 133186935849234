import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ConstService} from '../../../../services/const-service';
import {Functions} from '../../../../services/functions';
import {Router} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {ConfirmationDialogComponent} from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import {TextService} from '../../../../services/text.service';
import {FbService} from '../../../../services/fb.service';


@Component({
  selector: 'ninja-bot-menu',
  templateUrl: './bot-menu.component.html',
  styleUrls: ['./bot-menu.component.scss']
})

export class BotMenuComponent implements OnInit {
  public isFilters: boolean;
  public currentTemplates;
  public menuData;
  public pages;
  public page;
  public conversationList: any;
  public menuIndex = 0;
  public menuSubIndex = 0;
  public showMenuPreview: boolean;

  constructor(public matDialog: MatDialog,
              public constService: ConstService,
              public userService: UserService,
              public fbService: FbService,
              public textService: TextService,
              public ref: ChangeDetectorRef,
              public router: Router,
              public functions: Functions) {}

  public ngOnInit(): void {
    this.fbService.getPagesFb(this.userService.currentUser.id).subscribe((pages) => {
      this.pages = pages;
      this.page = pages[0];
      this.getConversations(this.page);
    });
    this.menuData = [{
      name: '',
    }
    ];
  }

  public createMenu(): void {
    this.fbService.createMenu(this.userService.currentUser.id, this.page.pageToken, this.menuData).subscribe( () => {
      this.userService.showNotification = true;
      this.userService.notificationTitle = this.textService.currentText.success;
      this.userService.notificationMessage = this.textService.currentText.saveMenuSuccess;
    });
  }

  public deleteMenuPage(): void {
    if (!this.page) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'delete-menu-without-pageId'
        },
      })
    } else {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'delete-menu'
        },
      }).afterClosed().subscribe(res => {
        if (res) {
          this.fbService.deleteMenu(this.userService.currentUser.id, this.page.pageToken).subscribe();
        }
      });
    }
  }

  public addMenuToThisLevel(e: any, el?: any): void {
    if (el.length < 3) {
      el.push(e);
    }
  }

  public addSub2Level(): void {
    this.menuData[this.menuIndex].menu2level = [];
    this.menuData[this.menuIndex].menu2level.push(
      {
        name: ''
      }
    );
  }

  public addSub3Level(): void {
    this.menuData[this.menuIndex].menu2level[this.menuSubIndex].menu3level = [];
    this.menuData[this.menuIndex].menu2level[this.menuSubIndex].menu3level.push({
      name: ''
    });
  }

  public showDeleteCommitTooltip(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'delete-commit-menu'
      },
    });
  }

  public menuName(e: any, index: any, el: any): void {
    el[index].name = e;
  }

  public deleteMenu(e, index, el): void {
    if (el.length !== 1) {
      el.splice(index, 1);
    }
  }

  public deleteSection(e: any, el: any, level: string): void {
    el[level] = undefined;
  }

  public setMargin2lvl(): number {
    if (this.menuIndex === 0) {
      return 47;
    }
    if (this.menuIndex === 1) {
      return 85;
    }
    if (this.menuIndex === 2) {
      return 123;
    }
  }

  public setMargin3lvl(): any {
    if (this.menuSubIndex === 0) {
      return this.setMargin2lvl() + 38;
    }
    if (this.menuSubIndex === 1) {
      return this.setMargin2lvl() + 76;
    }
    if (this.menuSubIndex === 2) {
      return this.setMargin2lvl() + 114;
    }
  }

  public getConversations (id) {
    this.fbService.getConversationsNames(this.userService.currentUser.id, id.pageId).subscribe((data) => {
      this.conversationList = data;
      this.ref.detectChanges();
    });
  }

}
