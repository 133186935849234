import {AfterViewInit, Component, Input} from '@angular/core';
import {FbService} from "../../../../../services/fb.service";

@Component({
  selector: 'ninja-conversation-bot-menu-navigation',
  templateUrl: './conversation-bot-menu-navigation.component.html',
  styleUrls: ['./conversation-bot-menu-navigation.component.scss']
})

export class ConversationBotMenuNavigationComponent implements AfterViewInit {
  @Input() data: boolean;
  public ulArray: any;

  constructor(public fbService: FbService) {}

  public ngAfterViewInit() {
    this.ulArray = document.getElementsByClassName('navigation-main');
    this.setPositions();
  }

  public setPositions() {
    for (let i = 0; i < this.ulArray.length; i++) {
      this.ulArray[i].style.paddingLeft = 10 + 'px';
    }
  }
}
