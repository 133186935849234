<div class="main-dialog-container main-dialog-container--spec-prod">
  <div class="close-btn" (click)="dialogRef.close(tagList)"></div>
  <div class="inner-dialog-container">
    <div class="dialog-content">
      <div class="header">
        <h2>Manage tags</h2>
        <!--<h3>{{textService.currentText.selectSpecProductsSub}}</h3>-->
      </div>
      <div class="dialog-row">
        <div class="label-name">
          <span>{{textService.currentText.addTags}}</span>
        </div>
        <div class="select-holder multy-check active custom-selects"
             (click)="functions.setDropDownPosition(notTagetWith, 'notTagetWith')"
             #notTagetWith>
          <mat-form-field>
            <mat-select
                shouldPlaceholderFloat="false"
                panelClass="default-dropdown notTagetWith"
                disableRipple
                #channelListSelect
                (closed)="detectTagDuplicate()"
                [(ngModel)]="currentTag">
              <mat-option *ngFor="let select of tags" [value]="select">
                {{ select.name }}
              </mat-option>
              <!--<div class="inner-select-box">-->
                <!--<button (click)="currentTag = []">Clear All</button>-->
                <!--<button class="primary" (click)="isTags = tags; saveTags(); channelListSelect.close()">Add</button>-->
              <!--</div>-->
            </mat-select>
          </mat-form-field>
        </div>
        <button class="label-name button-add-link"
                [disabled]="isDublicateTag"
                (click)="saveTags()"
                mat-button>
          <span>{{textService.currentText.addTag}}</span>
        </button>
      </div>

      <!--[compareWith]="compareFn"-->
      <!--(onClose)="setNewTags()"-->
      <!--(onOpen)="setCurrentList()"-->
      <div class="dialog-holder">
        <div class="table-container">
          <div class="table-wrapper spec-prod-wrapper">
            <div class="table-header-wrapper">
              <div class="table-header spec-prod-header full-width">

                <div class="td">{{textService.currentText.tag}}</div>
              </div>
              <div class="table-holder spec-prod-holder">
                <div class="table">
                  <div class="tr" *ngFor="let tags of tagList">

                    <div class="td">{{tags.name}}</div>
                    <div class="td" (click)="removeTag(tags)">
                      <img src="../../../../assets/icons/delete.svg" alt="">
                    </div>
                  </div>
                  <div class="tr not-found" *ngIf="!tagList?.length">
                    <div class="td not-found">
                      {{textService.currentText.noDataFound}}.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-footer single-btn">
        <button mat-button class="primary-btn" (click)="dialogRef.close(tagList)">{{textService.currentText.close}}</button>
      </div>
    </div>
  </div>
</div>
