import { ConstService } from './../../../services/const-service';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Functions } from '../../../services/functions';
import { AccountSettings } from '../../../services/account-settings';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { TemplateService } from '../../../services/template.service';
import { TextService } from '../../../services/text.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  templateUrl: './select-specific-products.component.html',
  styleUrls: ['./select-specific-products.component.scss'],
})
export class SelectSpecificProductsDialogComponent implements OnInit {
  public user: any;
  public filter: any;
  public isFilters: any;
  public checkAllProducts: boolean;
  public productsList: any;
  public productsListPaginator = [];
  public defaultList: any;
  public skuList: any;
  public filters: any;
  public pageSizeOptions = [100];
  public pageSize = 100;
  public isSingle = false;
  public showSaleChannel = false;
  public regionFlags: any;

  constructor(public accountSettings: AccountSettings,
    public userService: UserService,
    public ref: ChangeDetectorRef,
    public textService: TextService,
    public templateService: TemplateService,
    public activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<SelectSpecificProductsDialogComponent>,
    public functions: Functions, public el: ElementRef,
    public constService: ConstService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public mixpanelService: MixpanelService) {
    this.mixpanelService.init(null)
  }

  public ngOnInit(): void {
    this.filters = {
      name: '',
      asin: '',
      sku: ''
    };
    this.showSaleChannel = this.data.showSaleChannel;
    if (this.showSaleChannel) {
      var usrReg
      var usrArrReg = this.constService.countriesFlags.filter(item => item.value == this.userService.currentCountry);
      if (usrArrReg.length > 0)
        usrReg = usrArrReg[0].region;
      else
        usrReg = "North America";
      this.regionFlags = this.constService.countriesFlags.filter(item => item.region == usrReg);

    }
    this.isSingle = this.data.single;
    this.skuList = this.data.products;
    this.userService.dialogLoader = true;
    if (this.data.allProducts) {
      this.accountSettings.getAllProducts(this.userService.currentUser.id).subscribe((data) => {
        this.defaultList = data.products;
        this.productsList = data.products;
        for (let p = 0; p < this.productsList.length; p++) {
          this.productsList[p].saleChannel = this.userService.currentCountry;
        }
        //this.productsList = this.productsList.filter(this.isVisible.bind(this));
        if (this.productsList.length < 100) {
          this.pageSize = this.productsList.length;
        }
        this.getUpdatePaginator(0);
        this.userService.dialogLoader = false;
        if (this.skuList && this.skuList.length > 0) {
          for (let i = 0; i < this.skuList.length; i++) {
            for (let j = 0; j < this.productsList.length; j++) {
              if (this.skuList[i].sku === this.productsList[j].SKU) {
                this.productsList[j]['checked'] = true;
              }
            }
          }
        }
        this.setOrder();
      });
    } else if (this.data.isFbm) {
      this.accountSettings.getFbmProductSettings(this.userService.currentUser.id).subscribe((data) => {
        if (data.products.length === 0) {
          this.dialogRef.close('empty');
        }
        this.defaultList = data.products;
        this.productsList = data.products;
        if (data.products.length < 100) {
          this.pageSize = data.products.length;
        }
        this.getUpdatePaginator(0);
        this.userService.dialogLoader = false;
        if (this.skuList && this.skuList.length > 0) {
          for (let i = 0; i < this.skuList.length; i++) {
            for (let j = 0; j < this.productsList.length; j++) {
              if (this.skuList[i].sku === this.productsList[j].SKU) {
                this.productsList[j]['checked'] = true;
              }
            }
          }
        }
        this.setOrder();
      });
    } else {
      this.accountSettings.getProductSettings(this.userService.currentUser.id).subscribe((data) => {
        this.defaultList = data.products;
        this.productsList = data.products;
        this.productsList = this.productsList.filter(this.isVisible.bind(this));
        if (this.productsList.length < 100) {
          this.pageSize = this.productsList.length;
        }
        this.getUpdatePaginator(0);
        this.userService.dialogLoader = false;
        if (this.skuList && this.skuList.length > 0) {
          for (let i = 0; i < this.skuList.length; i++) {
            for (let j = 0; j < this.productsList.length; j++) {
              if (this.skuList[i].sku === this.productsList[j].SKU) {
                this.productsList[j]['checked'] = true;
              }
            }
          }
        }
        this.setOrder();
      });
    }
  }

  public getUpdatePaginator(index) {
    if (this.productsList.length < index * 100) {
      index = 1;
    }
    const start = index * (this.pageSize - 1);
    const end = this.productsList.length > 99 ? start + (this.pageSize - 1) : this.productsList.length;
    this.productsListPaginator = this.productsList.slice(start, end);
  }
  public setOrder(): void {
    const first = this.productsList.filter(this.isChecked.bind(this));
    const second = this.productsList.filter(this.isNotChecked.bind(this));
    this.productsList = first.concat(second);
    this.productsListPaginator = this.productsList;
    this.ref.detectChanges();
  }

  public isChecked(value: any): boolean {
    return value.checked;
  }

  public isNotChecked(value: any): boolean {
    return !value.checked;
  }

  public isVisible(value: any): boolean {
    return value.isHiden === false;
  }

  public clearFilters(): void {
    this.filters = {
      name: '',
      asin: '',
      sku: ''
    };
    this.checkFilters();
    this.getUpdatePaginator(0);
  }

  public checkAll(): void {
    for (let i = 0; this.productsList.length > i; i++) {
      if (!this.productsList[i].isHiden) {
        if (this.checkAllProducts) {
          this.productsList[i].checked = true;
        } else {
          this.productsList[i].checked = false;
        }
      }
    }
  }

  public saveCurrentCheckbox(e: any): void {
    if (this.isSingle && e.checked) {
      this.checkAllProducts = false;
      this.checkAll();
      e.checked = true;
    }

    for (let i = 0; this.productsList.length > i; i++) {
      if (this.productsList[i].SKU === e.SKU) {
        this.productsList[i].checked = e.checked;
      }
    }
  }

  public save(): void {
    const dataToSave = [];
    this.templateService.currentFbaTemplate = { productsskus: [] };
    for (let i = 0; this.productsList.length > i; i++) {
      if (this.productsList[i].checked) {
        dataToSave.push({
          sku: this.productsList[i].SKU,
          name: this.productsList[i].fullName,
          fullName: this.productsList[i].fullName,
          shortName: this.productsList[i].shortName,
          asin: this.productsList[i].asin,
          /// ADD FOR ALERT ASIN SELECTION
          img: this.productsList[i].image,
          saleChannel: this.userService.currentUser.marketplace
        });
      }
    }
    if (this.isSingle) {
      if (dataToSave.length == 0) {
        this.matDialog.open(ConfirmationDialogComponent, {
          data: {
            'dialogFor': 'custom',
            'title': "Alerts",
            'message': "No product selected"
          },
        });
        return;
      } else if ((this.showSaleChannel) && (!dataToSave[0].saleChannel)) {
        this.matDialog.open(ConfirmationDialogComponent, {
          data: {
            'dialogFor': 'custom',
            'title': "Alerts",
            'message': "No Sale channel selected"
          },
        });
        return;
      }
    }

    this.dialogRef.close(dataToSave);
  }

  public checkFilters(): void {
    this.productsList = JSON.parse(JSON.stringify(this.defaultList));
    if (this.filters.name.length > 0) {
      this.liveSearchName();
    }
    if (this.filters.asin.length > 0) {
      this.liveSearchAsin();
    }
    if (this.filters.sku.length > 0) {
      this.liveSearchSku();
    }
    this.getUpdatePaginator(0);
  }

  public liveSearchName(): void {
    this.productsList = this.productsList.filter(this.liveSearchNameCallback.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchNameCallback(value: any): boolean {
    return value.shortName.toLowerCase().indexOf(this.filters.name.toLowerCase()) !== -1;
  }

  public liveSearchAsin(): void {
    this.productsList = this.productsList.filter(this.liveSearchAsinCallback.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchAsinCallback(value: any): boolean {
    return value.asin.toLowerCase().indexOf(this.filters.asin.toLowerCase()) !== -1;
  }

  public liveSearchSku(): void {
    this.productsList = this.productsList.filter(this.liveSearchSkuCallback.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchSkuCallback(value: any): boolean {
    return value.SKU.toLowerCase().indexOf(this.filters.sku.toLowerCase().toLowerCase()) !== -1;
  }

  public getFlagFromSaleChannel(mp): string {
    if (!mp)
      return "";
    var country = this.constService.countriesFlags.filter(function (item) {
      return (item.value == mp);
    });
    if (!country.length)
      return "";
    return country[0].image;

  }

}
