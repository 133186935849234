import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, NgModule, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Functions } from '../../../../services/functions';
import { AccountSettings } from '../../../../services/account-settings';
import { UserService } from '../../../../services/user.service';
import { EditDialogComponent } from '../../../dialogs/edit-dialog/edit-dialog.component';
import { TextService } from '../../../../services/text.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';
@Component({
  selector: 'ninja-product-settings',
  templateUrl: './product-settings.component.html',
  styleUrls: ['./product-settings.component.scss'],
})

export class ProductSettingsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() updatedProductsListPaginator: any;
  public userProducts: any;
  public showDetails = false;
  showDetailedCost = false;
  public detailedCostshow = false;
  public changeCostValue: any;
  public defaultListProducts: any;
  public filtersName = '';
  public SKU = '';
  public notificationMessage: string;
  public notificationTitle: string;
  public asin = '';
  public checkAllProducts: boolean;
  public isFilters: boolean;
  public productsListPaginator: Array<any> = [];
  public pageSizeOptions: Array<number> = [100];
  public pageSize = 20;
  public dateTo = null;
  public dateFrom = null;
  oldCostData: any;
  id: string;
  public visibleTooltip: string = this.textService.currentText.productsVisibleTooltip;
  public invisibleTooltip: string = this.textService.currentText.productsInvisibleTooltip;

  constructor(public el: ElementRef,
    public matDialog: MatDialog,
    public functions: Functions,
    public ref: ChangeDetectorRef,
    public userService: UserService,
    public textService: TextService,
    public accountSettings: AccountSettings,
    public mixpanelService: MixpanelService) {
      this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    setTimeout(() => {
      if (this.userService.currentUser) {
        this.userService.loaderObesrver.next(true);
        this.accountSettings.currentStep = 2;
        this.accountSettings.settingsTitle = this.textService.currentText.productsTitle;
        this.accountSettings.settingsInfo = this.textService.currentText.productsSubTitle;
        this.textService.changeLoaderObserver().subscribe(() => {
          this.accountSettings.settingsTitle = this.textService.currentText.productsTitle;
          this.accountSettings.settingsInfo = this.textService.currentText.productsSubTitle;
        });
        this.accountSettings.getProductSettings(this.userService.currentUser.id).subscribe((data) => {
          data.products.map(item => {
            item.defaultCurrency = this.userService.convertCurrencySymbol(item.defaultCurrency);
          });
          this.userProducts = data.products;
          this.defaultListProducts = data.products;
          if (data.products.length < 20) {
            this.pageSize = data.products.length;
          }
          this.defaultFilter();
          this.getUpdatePaginator(0);
          this.userService.loaderObesrver.next(false);
        });
      }
    });
  }

  public getUpdatePaginator(index: number): void {
    if (this.userProducts.length < index * 20) {
      index = 1;
    }
    const start = index * (this.pageSize - 1);
    const end = this.userProducts.length > 19 ? start + (this.pageSize - 1) : this.userProducts.length;
    this.productsListPaginator = this.userProducts.slice(start, end);
  }

  public liveSearchName(): void {
    this.userProducts = this.userProducts.filter(this.liveSearchCallback.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchCallback(value): boolean {
    return value.shortName.toLowerCase().indexOf(this.filtersName.toLowerCase()) !== -1;
  }

  public liveSearchSku(): void {
    this.userProducts = this.userProducts.filter(this.liveSearchCallbackSku.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchCallbackSku(value): boolean {
    return value.SKU.toLowerCase().indexOf(this.SKU.toLowerCase()) !== -1;
  }

  public liveSearchAsin(): void {
    this.userProducts = this.userProducts.filter(this.liveSearchCallbackAsin.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchCallbackAsin(value: any): boolean {
    return value.asin.toLowerCase().indexOf(this.asin.toLowerCase()) !== -1;
  }

  public clearFilter(): void {
    this.filtersName = '';
    this.asin = '';
    this.SKU = '';
    this.userProducts = JSON.parse(JSON.stringify(this.defaultListProducts));
    this.defaultFilter();
    this.getUpdatePaginator(0);
    this.ref.detectChanges();
  }

  public checkFilters(): void {
    this.userProducts = JSON.parse(JSON.stringify(this.defaultListProducts));
    if (this.filtersName.length > 0) {
      this.liveSearchName();
    }
    if (this.asin.length > 0) {
      this.liveSearchAsin();
    }
    if (this.SKU.length > 0) {
      this.liveSearchSku();
    }
    this.defaultFilter();
    this.paginator.pageIndex = 0;
  }

  public changeCost(): void {
    for (let i = 0; this.userProducts.length > i; i++) {
      if (this.userProducts[i].checked && !this.userProducts[i].isHiden) {
        this.userProducts[i].cost = this.changeCostValue;
      }
    }
    this.saveSettings();
  }

  public saveSettings(): void {
    this.accountSettings.saveProductsSettings(this.userService.currentUser.id, this.userProducts).subscribe();
  }

  setHide(products) {
    this.accountSettings.setHideValue(this.userService.currentUser.sellerId, products.asin, products.SKU, { 'isHided': products.isHiden }).subscribe((data) => {
    });
  }

  public checkAll(): void {
    for (let i = 0; this.userProducts.length > i; i++) {
      if (this.checkAllProducts && !this.userProducts[i].isHiden) {
        this.userProducts[i].checked = true;
      } else {
        this.userProducts[i].checked = false;
      }
    }
  }

  public defaultFilter(): void {
    const first = this.userProducts.filter(this.isHidden.bind(this));
    const second = this.userProducts.filter(this.isVisible.bind(this));
    this.userProducts = second.concat(first);
    this.productsListPaginator = this.userProducts;
    this.ref.detectChanges();
  }

  public isHidden(value: any): boolean {
    return value.isHiden === true;
  }

  public isVisible(value: any): boolean {
    return value.isHiden === false;
  }

  public openEditDialog(product: any, index: number) {
    this.matDialog.open(EditDialogComponent, {
      data: {
        'dialogFor': 'edit-dialog',
        'products': product
      },
    }).afterClosed().subscribe(productInfo => {
      if (productInfo) {
        for (let i = 0; i < this.defaultListProducts.length; i++) {
          if (this.defaultListProducts[i].SKU === productInfo.SKU) {
            this.defaultListProducts[i] = productInfo;
          }
          // if (this.userProducts[i].SKU === productInfo.SKU) {
          //   this.userProducts[i] = productInfo;
          // }
        }
        for (let i = 0; i < this.productsListPaginator.length; i++) {
          if (this.productsListPaginator[i].SKU === productInfo.SKU) {
            this.productsListPaginator[i] = productInfo;
          }
        }
        this.notificationMessage = this.textService.currentText.productEdited;
        this.notificationTitle = this.textService.currentText.editProduct;
        this.userService.showNotification = true;
      }
    });
  }

}
