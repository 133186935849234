import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {OrdersService} from '../../../../services/orders.service';
import {ConstService} from '../../../../services/const-service';
import {Functions} from '../../../../services/functions';
import {TemplateService} from '../../../../services/template.service';
import { MatDialog } from '@angular/material/dialog';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ConfirmationDialogComponent} from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import {TextService} from '../../../../services/text.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

declare let jQuery: any;
declare let $: any;

@Component({
  selector: 'ninja-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})

export class SendMessageComponent implements OnInit, OnDestroy {
  @ViewChild('subject', { static: false }) subject;
  public content = '';
  public test: any;
  public editor: any;
  public redirectImmediately = false;
  public currentTemplates: any;
  public email = '';
  public name = '';
  public isExcluded = false;
  public sendCopy = false;
  public orderid: any;
  public templateId: any;
  public invoiceTemplateId: any;
  public filename: any = '';
  public errors: any = {};
  public errorMessage = 'Required';
  public editorFrame;
  public contentError = false;
  public myControl = new FormControl();
  public filteredOptions: Observable<string[]>;

  public sendMessageForm = this.fb.group({
    subject: ['',
      [Validators.required]],
  });

  constructor(public router: Router,
              public userService: UserService,
              public textService: TextService,
              public ordersService: OrdersService,
              public templateService: TemplateService,
              public constService: ConstService,
              public matDialog: MatDialog,
              public functions: Functions,
              public activatedRoute: ActivatedRoute,
              public fb: FormBuilder) {
  }

  public ngOnInit(): void {
    // if (!this.userService.currentPage) {
    //   this.redirectImmediately = true;
    //   this.router.navigate(['dashboard/orders'],
    //     {queryParams: {page: 'fba'}});
    // }
    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params.buyerEmail;
      this.name = params.buyerName;
      this.orderid = params.orderid;
    });
    this.editor = jQuery('#summernote');
    this.editor.summernote({
      height: 300,
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Times New Roman', 'Georgia', 'Verdana' ],
      fontNamesIgnoreCheck: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Times New Roman', 'Georgia', 'Verdana' ],
      toolbar: [
        ['style', ['style']],
        ['fontsize', ['fontsize']],
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['fontname', ['fontname']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture', 'video']],
        ['table', ['table']],
        ['misc', ['fullscreen', 'codeview', 'undo', 'redo']]
      ],
      fontSizes: ['6', '8', '10', '12', '14', '18', '24', '36', '48' , '64', '72'],
      callbacks: {
        onImageUpload: (files) => {
          this.imageFileLoader(files);
        }
      }
    });
    this.isExcluded = true;
    document.getElementsByClassName('note-editable')['0'].innerHTML = '';
    this.userService.loaderObesrver.next(true);
    this.templateService.getAllTemplatesName(this.userService.currentUser.id).subscribe(
      (data) => {
        this.currentTemplates = data.list;
        this.userService.loaderObesrver.next(false);
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => value ? this._filter(value) : this.currentTemplates.slice())
          );
      }
    );
  }

  private _filter(value: any): any[] {
    return this.currentTemplates.filter(template => template.name.toLowerCase().indexOf(value.toLowerCase()) === 0);
  }

  public ngOnDestroy(): void {
    $('.popover').hide();
    this.userService.currentPage = '';
  }

  public imageFileLoader(files) {
      this.userService.loaderObesrver.next(true);
      const sizeLimit = 2097152;
      const formats = ['image/jpeg', 'image/png', 'image/gif'];
      if (files[0].size >= sizeLimit || formats.indexOf(files[0].type) === -1) {
        this.userService.loaderObesrver.next(false);
        this.showImageFileValidation();
        return;
      }
      if (this.templateId) {
        let inserted = false;
        this.userService.uploadImage(files, 'templates/upload_image', this.templateId);
        this.userService.fileLoaded().subscribe((data) => {
          const imageLink = JSON.parse(data.currentTarget.response).url.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
            if (!inserted) {
              this.editor.summernote('restoreRange');
              this.editor.summernote('insertImage', imageLink);
              this.editor.summernote('saveRange');
              inserted = true;
            }
          this.userService.loaderObesrver.next(false);
        });
      } else {
        this.userService.loaderObesrver.next(false);
        this.showSaveFirstDialog();
      }
  }

  public showImageFileValidation() {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.invalidFormat,
        'message': this.textService.currentText.imageLoadValidation
      },
    }).afterClosed().subscribe(() => {
      this.userService.loaderObesrver.next(false);
    });
  }

  public openOrderBlackList(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'order-blacklist',
      },
    }).afterClosed().subscribe();
  }

  public showEditorValiadation(): void {
    this.editorFrame = document.getElementsByClassName('note-frame');
    this.editorFrame['0'].style.borderColor = '#c12336';
    this.contentError = true;
    setTimeout(() => {
      this.editorFrame = document.getElementsByClassName('note-frame');
      this.editorFrame['0'].style.borderColor = '#dddddd';
      this.contentError = false;
    }, 2000);
  }

  public sendMessage(): void {
    const messageData = {
      publicId: this.userService.currentUser.id,
      orderId: this.orderid.trim(),
      templateId: this.templateId,
      sendCopy: this.sendCopy,
      excludeOrder: this.isExcluded,
      invoiceTemplateId: this.invoiceTemplateId,
      fileName: this.filename,
      subject: this.subject,
      content: this.content,
      isSuperUser: this.userService.isSuperUser
    };
    this.ordersService.sendOrdersMessage(messageData).subscribe((data) => {
      if (data.isSuccess) {
        this.userService.notificationMessage = this.textService.currentText.messageSentSuccess;
        this.userService.notificationTitle = this.textService.currentText.success;
        this.userService.showNotification = true;
        this.router.navigate(['dashboard/orders'], {queryParams: {page: 'fba'}});
      }
    });
  }

  public backToOrders(): void {
    if (this.userService.currentPage === 'fba-orders') {
      this.router.navigate(['dashboard/orders'],
        {queryParams: {page: 'fba'}});
    } else {
      this.router.navigate(['dashboard/orders'],
        {queryParams: {page: 'fbm'}});
    }
  }

  public getTemplateInfo(select): void {
    this.templateId = select.id;
    if (select.invoiceTemplateId) {
      this.invoiceTemplateId = select.invoiceTemplateId;
    } else {
      this.invoiceTemplateId = undefined;
    }
    this.userService.loaderObesrver.next(true);
    this.ordersService.getTemplateContent(this.userService.currentUser.id, this.orderid, select.id).subscribe(
      (data) => {
        this.editor.summernote('code', data.content);
        this.content = data.content;
        this.subject = data.subject;
        this.filename = data.fileName;
        this.userService.loaderObesrver.next(false);
      });
  }

  public checkForm(): void {
    this.content = this.editor.summernote('code');
    if (!this.sendMessageForm.valid) {
      this.displayError();
    } else if (this.content.length === 0) {
      this.showEditorValiadation();
    } else {
      this.sendMessage();
    }

  }

  private displayError(): void {
    if (!this.sendMessageForm.controls.subject.valid) {
      this.errors.subject = true;
      this.resetErrorState();
      return;
    }
  }

  private resetErrorState(): void {
    setTimeout(() => {
      if (!this.sendMessageForm.controls.subject.valid) {
        this.errors.subject = false;
      }
    }, 2000);
  }

  public showSaveFirstDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.warning,
        'message': this.textService.currentText.saveBeforeUploadMessage
      },
    }).afterClosed().subscribe(() => {
      this.userService.loaderObesrver.next(false);
    });
  }
}

