<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container">
    <div class="dashboard-header">
      <h2>{{textService.currentText.smTitle}}</h2>
      <div class="back-link">
        <p class="action-el" (click)="backToOrders()">< {{textService.currentText.smBackToOrders}}</p>
      </div>
    </div>
    <div class="grid-row space-between">
      <div class="left-block">
        <div class="input-holder">
          <div class="label-name">
            <span>{{textService.currentText.name}}</span>
          </div>
          <input matInput
                 type="text"
                 [value]="name"
                 readonly
                 [placeholder]="textService.currentText.name">
        </div>
        <div class="input-holder">
          <div class="label-name">
            <span>Email</span>
          </div>
          <input matInput
                 type="text"
                 [value]="email"
                 readonly
                 [placeholder]="textService.currentText.emailExample">
        </div>
      </div>
      <div class="right-block">
        <mat-checkbox
            [checked]="isExcluded"
            [(ngModel)]="isExcluded"
            disableRipple>
          {{textService.currentText.smExcludeOrder}}
        </mat-checkbox>
        <div class="opt-tooltip" (click)="openOrderBlackList()">
          <span>?</span>
        </div>
        <mat-checkbox
            [checked]="sendCopy"
            [(ngModel)]="sendCopy"
            disableRipple>
          {{textService.currentText.smSendCopy}}
        </mat-checkbox>
      </div>
    </div>
    <div class="grid-row custom-selects">
      <div class="select-holder wide">
        <div class="label-name">
          <span>{{textService.currentText.template}}</span>
        </div>
        <form class="template-form">
          <mat-form-field class="example-full-width">
            <input type="text"
                   [placeholder]="textService.currentText.template"
                   aria-label="Number" matInput
                   [formControl]="myControl"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" class="custom-autocomplete">
              <mat-option (click)="getTemplateInfo(option)"  *ngFor="let option of filteredOptions | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
        <!--<div (click)="functions.setDropDownPosition(allProductsSelect, 'allProducts')" #allProductsSelect-->
             <!--[class.active]="allProducts.panelOpen"-->
             <!--class="select-wrapper">-->
          <!--<mat-form-field>-->
            <!--<mat-select shouldPlaceholderFloat="false"-->
                        <!--panelClass="default-dropdown allProducts"-->
                        <!--#allProducts-->
                        <!--disableRipple-->
                        <!--[(ngModel)]="test"-->
                        <!--name="allProducts">-->
              <!--<mat-option *ngFor="let select of currentTemplates"-->
                          <!--[value]="select.name"-->
                          <!--(click)="getTemplateInfo(select)">-->
                <!--{{ select.name }}-->
              <!--</mat-option>-->
            <!--</mat-select>-->
          <!--</mat-form-field>-->
        <!--</div>-->
      </div>
      <ng-container [formGroup]="sendMessageForm" name="form" novalidate>
        <div class="input-holder wide" [class.has-danger]="errors?.subject">
          <div class="label-name">
            <span>{{textService.currentText.subject}}</span>
          </div>
          <input matInput
                 *ngIf="!errors?.subject"
                 formControlName="subject"
                 name="subject"
                 type="text"
                 [placeholder]="textService.currentText.subject"
                 [(ngModel)]="subject">
          <input matInput
                 *ngIf="errors?.subject"
                 type="text"
                 [value]="errorMessage">
        </div>
      </ng-container>
    </div>
    <div class="editor-holder">
      <div class="editor-error" *ngIf="contentError">
        {{textService.currentText.errorRequired}}
      </div>
      <div id="summernote" class="editor"></div>
    </div>
    <div class="template-file">
      <div class="file-header">
        <p>{{textService.currentText.templateFile}}</p>
      </div>
      <div class="file-body">
        <ng-container *ngIf="filename?.length > 1">
          <div class="file-icon">
          </div>
          <p>{{filename}}</p>
        </ng-container>
      </div>
    </div>
    <button mat-button
            mat-raised-button
            (click)="checkForm()"
            class="primary-btn">
      {{textService.currentText.send}}
    </button>
  </div>
</div>
<ninja-notification [active]="userService.showNotification"
                    [message]="userService.notificationMessage"
                    [title]="userService.notificationTitle"
></ninja-notification>
