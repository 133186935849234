<div class="access-wrapper">
  <div class="access-main-block" *ngIf="grantAccesPage">
    <div class="checking-details" *ngIf="showProgressBar && !promoteFBbot">
      <h2>{{textService.currentText.grantAccessTitle3}}</h2>
      <div class="text">
        <p>{{textService.currentText.grantAccessSubTitle4}}</p>
      </div>
      <div class="loading-holder">
        <div class="indicator" [ngStyle]="{'width': currentUploadProgress +
          '%'}"></div>
      </div>
    </div>
    <div class="permission" *ngIf="!showError && !showProgressBar &&
      configurePermission">
      <img class="logo" src="assets/images/ninja-logo2.svg" alt="">
      <h2>Configure MWS Permission</h2>
      <h3>Grant Access Permissions to Upsellon Application</h3>
      <p>You've successfully granted access to your North America account, we
        are already synching your data :)</p>
      <p>You can setup more regions or click finish to go to your dashboard</p>
      <div class="regions">
        <div class="country-container">
          <div class="overlay-country">
            <img class="icon email"
              src="../../../../assets/images/northamerica.png"
              (click)="navigateToAmazon(userLnks?.AMAZON_COM)">
            <mat-checkbox [checked]="usaRegion" class="round-checkbox">{{textService.currentText.northAmericaAccount1}}
            </mat-checkbox>
          </div>
          <div class="flag-box">
            <button mat-button mat-raised-button class="primary-btn"
              class="success-btn"
              [ngClass]="!usaRegion ? 'blue-btn' : ''">
              {{usaRegion ? textService.currentText.permissionsGranted :
              textService.currentText.grantPermissions}}
            </button>
            <div class="marketplace first">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{textService.currentText.northAmericaMarketplaces}}</h5>
                  <ul>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_BRAZIL)">
                      <img src="../../../../assets/images/br1.png">
                      <span>com.br</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_CANADA)">
                      <img src="../../../../assets/images/ca.png">
                      <span>.ca</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_MEXICO)">
                      <img src="../../../../assets/images/mx.png">
                      <span>.com.mx</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_COM)">
                      <img src="../../../../assets/images/com.png">
                      <span>.com</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="country-container">
          <div class="overlay-country">
            <img class="icon email" src="../../../../assets/images/europe.png"
              (click)="navigateToAmazon(userLnks?.AMAZON_UK)">
            <mat-checkbox [checked]="euRegion" class="round-checkbox">{{textService.currentText.europianUnionAccount1}}
            </mat-checkbox>
          </div>
          <div class="flag-box">
            <button mat-button mat-raised-button class="primary-btn"
              class="success-btn"
              [ngClass]="!euRegion ? 'blue-btn' : ''">
              {{euRegion ? textService.currentText.permissionsGranted :
              textService.currentText.grantPermissions}}
            </button>
            <div class="marketplace second">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{textService.currentText.europianUnionMarketplaces}}</h5>
                  <ul>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_UAE)">
                      <img src="../../../../assets/images/ae.png">
                      <span>.ae</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_GERMANY)">
                      <img src="../../../../assets/images/de.png">
                      <span>.de</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_SPAIN)">
                      <img src="../../../../assets/images/es.png">
                      <span>.es</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_FRANCE)">
                      <img src="../../../../assets/images/fr.png">
                      <span>.fr</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_UK)">
                      <img src="../../../../assets/images/uk.png">
                      <span>.co.uk</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_INDIA)">
                      <img src="../../../../assets/images/in.png">
                      <span>.in</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_ITALY)">
                      <img src="../../../../assets/images/it.png">
                      <span>.it</span>
                    </li>
                    <li
                      (click)="navigateToAmazon(userLnks?.AMAZON_NETHERLANDS)">
                      <img src="../../../../assets/images/nl1.png">
                      <span>.nl</span>
                    </li>
                    <li
                      (click)="navigateToAmazon(userLnks?.AMAZON_SAUDI_ARABIA)">
                      <img src="../../../../assets/images/sa1.png">
                      <span>.sd</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_TURKEY)">
                      <img src="../../../../assets/images/tr1.png">
                      <span>.tr</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="country-container">
          <div class="overlay-country">
            <img class="icon email" src="../../../../assets/images/fareast.png"
              (click)="navigateToAmazon(userLnks?.AMAZON_AUSTRALIA)">
            <mat-checkbox [checked]="feRegion" class="round-checkbox">{{textService.currentText.farEastAccount}}
            </mat-checkbox>
          </div>
          <div class="flag-box">
            <button mat-button mat-raised-button class="primary-btn"
              class="success-btn"
              [ngClass]="!feRegion ? 'blue-btn' : ''">
              {{feRegion ? textService.currentText.permissionsGranted :
              textService.currentText.grantPermissions}}
            </button>
            <div class="marketplace third">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{textService.currentText.farEastMarketplaces}}</h5>
                  <ul>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_SINGAPOR)">
                      <img src="../../../../assets/images/sg1.png">
                      <span>.sg</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_JAPAN)">
                      <img src="../../../../assets/images/jp.png">
                      <span>.co.jp</span>
                    </li>
                    <li (click)="navigateToAmazon(userLnks?.AMAZON_AUSTRALIA)">
                      <img src="../../../../assets/images/au.png">
                      <span>.com.au</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="finish">
        <button mat-button mat-raised-button (click)="showPromoteFBbot()"
          class="primary-btn"
          class="secondary-btn btn-fill">
          {{textService.currentText.finish}}
        </button>
      </div>
    </div>
    <div class="finish-checking promote-fb" *ngIf="promoteFBbot">
      <img class="logo" src="assets/images/ninja-logo2.svg" alt="">
      <div class="success-title">
        <img class="img-ok" src="assets/images/active.png" alt="">
        We've successfully verified your account, you can start using Quantify
        Ninja.
      </div>
      <div class="fb-container">
        <div class="fb-text">
          <h1>
            Get real-time notifications!
          </h1>
          <h3>
            Directly to your Facebook\Telegram Messenger
          </h3>
          <div class="fb-bullets">
            <div><img src="assets/images/li-dot.png">Get instant notification
              for every new sale
            </div>
            <div><img src="assets/images/li-dot.png">Get instant notification
              for negative feedbacks
            </div>
            <div><img src="assets/images/li-dot.png">Interact and get the
              details you want – when you want
            </div>
          </div>
          <h4>
            <span class="grey-text">It’s just one click setup –</span> And
            completely free!
          </h4>
          <div class="fb-buttons">
            <div class="row">
              <div class="col-md-6 notification-btn">
                <img src="../../../../assets/icons/fb-icon.png" class="pb-2" />
                <button mat-button mat-raised-button class="secondary-btn fb-btn
                  btn-fill" (click)="openFBWindow()">Setup Facebook
                  Notifications</button>
              </div>
              <div class="col-md-6 notification-btn">
                <div class="telegram">
                  <img src="../../../../assets/icons/telegram-icon.png"
                    class="pb-2" /><p class="recommended">Recommended<br><span
                      class="edit-btn" (click)="showWhyDialog()">Why?</span></p>
                </div>
                <button mat-button mat-raised-button class="secondary-btn fb-btn
                  btn-fill" (click)="openTGWindow()">Setup Telegram Notifications</button>
              </div>
            </div>
            <div class="t-content">
              <p>We Recommend Telegram over Facebook - click here to find out
                why</p>
            </div>
            <div class="without-noti">
              <button (click)="navigateToDashboard()">Click here to get started
                without notifications</button>
            </div>
          </div>

        </div>
        <div class="fb-messanger">
          <img class="fb-ninja-messanger" src="assets/images/phone-noti.png"
            alt="">
          <div class="fb-youtube" (click)="showMessangerVideo()">
            <img src="assets/images/yt.png" alt="">
            How it works
          </div>
        </div>
      </div>
    </div>
    <div class="finish-checking" *ngIf="!showProgressBar &&
      (!configurePermission || !promoteFBbot || showError)">
      <img *ngIf="showError" src="assets/images/ninja-logo2.svg" alt="">
      <!-- <h2 *ngIf="!showError">{{textService.currentText.grantAccessTitle4}}</h2> -->
      <h2 *ngIf="showError">{{textService.currentText.grantAccessTitle5}}</h2>
      <div class="text">
        <!-- <p *ngIf="!showError">
          {{textService.currentText.grantAccessText1}}
        </p> -->
        <p *ngIf="showError && !isWentWrongError">
          {{textService.currentText.grantAccessText2}}
        </p>
        <p *ngIf="showError && !isWentWrongError" class="small-text">
          {{textService.currentText.grantAccessSellerID}}
          {{this.activatedRoute.snapshot.queryParams.Merchant}}
        </p>
        <p *ngIf="showError && !isWentWrongError" class="small-text">
          {{textService.currentText.userId}}
          {{this.activatedRoute.snapshot.queryParams.webApp_irpregistration}}
        </p>
        <p *ngIf="showError && isWentWrongError">
          {{textService.currentText.facebookConnectFailed}}
        </p>
      </div>
      <!-- <button *ngIf="!showError" class="mat-button primary-btn" (click)="navigateToDashboard()">
        {{textService.currentText.getStarted}}
      </button> -->
      <button *ngIf="showError" class="mat-button primary-btn"
        (click)="navigateToSignUp()">
        {{textService.currentText.gotIt}}</button>
    </div>

  </div>
  <div class="access-main-block" *ngIf="paymentRedirectStep1">
    <div class="finish-checking">
      <img src="assets/images/ninja-logo2.svg" alt="">
      <h2>{{textService.currentText.grantAccessTitle6}}</h2>
      <div class="text">
        <p>{{textService.currentText.grantAccessText3}}</p>
      </div>
      <div class="checking-details">
        <div class="loading-holder">
          <div class="indicator" [ngStyle]="{'width': currentUploadProgress +
            '%'}"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="access-main-block" *ngIf="paymentRedirectStep2">
    <div class="finish-checking">
      <img src="assets/images/ninja-logo2.svg" alt="">
      <h2>{{textService.currentText.grantAccessTitle4}}</h2>
      <div class="text">
        <p>{{textService.currentText.grantAccessText4}}</p>
      </div>
      <h2 class="counter">{{redirectCount}}</h2>
    </div>
  </div>
  <div class="access-main-block" *ngIf="paymentRedirectError">
    <div class="finish-checking">
      <img src="assets/images/ninja-logo2.svg" alt="">
      <h2>{{textService.currentText.grantAccessTitle5}}</h2>
      <div class="text">
        <p>{{textService.currentText.grantAccessText5}}</p>
      </div>
      <button class="mat-button primary-btn"
        (click)="navigateToDashboard()">{{textService.currentText.getStarted}}</button>
    </div>
  </div>
  <div class="access-main-block" *ngIf="redirectPayPal">
    <div class="finish-checking">
      <p><span class="rounded-number">{{titleNumber}}</span> {{title}}</p>
      <div class="checking-details">
        <div class="loading-holder">
          <div class="indicator" [ngStyle]="{'width': currentUploadProgress +
            '%'}"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="access-main-block" *ngIf="isNewRegionPayment">
    <div class="finish-checking add-region">
      <img src="assets/images/ninja-logo2.svg" alt="">
      <h2>{{textService.currentText.grantAccessTitle7}}</h2>
      <p class="big-margin">{{textService.currentText.grantAccessText6}}</p>
      <div class="add-region-details">
        <p>
          <span class="medium">{{textService.currentText.upgYourCurrPlan}}</span>
          {{changeRegionData[0]?.Plan || 'Basic'}}
        </p>
        <p>
          <span class="medium">{{textService.currentText.currentRegions}}
          </span> {{changeRegionData.length - 1}}
          ({{currentRegionsStr}})
        </p>
        <p>
          <span class="medium">{{textService.currentText.grantAccessText7}}
          </span> {{changeRegionData[0]?.RegionName}}
        </p>
        <p>
          <span class="medium">{{textService.currentText.price}}:</span> 15$/mo
        </p>
      </div>
      <div class="buttons-footer">
        <button mat-button class="default-btn"
          (click)="navigateToDashboard()">{{textService.currentText.cancel}}</button>
        <button mat-button class="primary-btn with-image"
          (click)="paymentProcess()">{{textService.currentText.upgPayWith}}
          <img src="../../../../assets/icons/logo-paypal-white.svg" alt=""></button>
      </div>
    </div>
  </div>
  <div class="access-main-block" *ngIf="fbError">
    <div class="finish-checking">
      <img src="assets/images/ninja-logo2.svg" alt="">
      <h2>{{textService.currentText.grantAccessTitle5}}</h2>
      <div class="text">
        <p>{{textService.currentText.facebookConnectFailed}}</p>
      </div>
    </div>
  </div>
  <div class="access-main-block" *ngIf="fbSuccess">
    <div class="finish-checking">
      <img src="assets/images/ninja-logo2.svg" alt="">
      <h2>{{textService.currentText.grantAccessTitle6}}</h2>
      <div class="text">
        <p>{{textService.currentText.facebookConnected}}</p>
      </div>
    </div>
  </div>
  <div class="access-main-block" *ngIf="isCustomPayment">
    <div class="finish-checking">
      <img src="assets/images/ninja-logo2.svg" alt="">
      <h2>{{textService.currentText.grantAccessTitle4}}</h2>
      <div class="text">
        <p>{{textService.currentText.grantAccessText4}}</p>
      </div>
      <h2 class="counter">{{redirectCount}}</h2>
    </div>
  </div>
</div>