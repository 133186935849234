import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UserService} from '../../../services/user.service';
import {ConstService} from '../../../services/const-service';
import {Functions} from '../../../services/functions';
import {FormBuilder, Validators} from '@angular/forms';
import {TextService} from '../../../services/text.service';

@Component({
    templateUrl: './expence-dialog.component.html',
    styleUrls: ['./expence-dialog.component.scss'],
})
export class ExpenceDialogComponent {
    @ViewChild('name', { static: true }) name: ElementRef;
    @ViewChild('from', { static: false }) from: ElementRef;
    @ViewChild('to', { static: false }) to: ElementRef;
    @ViewChild('amount', { static: true }) amount: ElementRef;
    @ViewChild('currency', { static: false }) currency: ElementRef;
    public errors: any = {};
    public currencyError = 'Currency must be selected';
    public selects = [
        {value: 'USD', viewValue: 'USD'},
        {value: 'EUR', viewValue: 'EUR'}
    ];

    public expences = {
        publicId: this.userService.currentUser.id,
        from: '',
        to: '',
        name: '',
        amount: null,
        currency: ''
    };
    public expencesForm = this.fb.group({
        name: ['',
            [Validators.required]],
        from: ['',
            [Validators.required]],
        to: ['',
            [Validators.required]],
        amount: ['',
            [Validators.required, Validators.pattern(/^[-+]?[0-9]*\.?[0-9]+$/)]],
        currency: ['',
            [Validators.required]]
    });

    constructor(public userService: UserService,
                public constService: ConstService,
                public textService: TextService,
                public functions: Functions,
                public fb: FormBuilder,
                public dialogRef: MatDialogRef<ExpenceDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    private displayError(): void {
        if (!this.expencesForm.controls.name.valid) {
            this.errors.name = true;
            const old_value = this.expencesForm.value.name;
            this.expencesForm.patchValue({
                name: this.textService.currentText.errorEmptyExpense,
            });
            this.expencesForm.controls['name'].disable();
            this.name.nativeElement.blur();
            this.resetErrorState(old_value);
            return;
        }
        if (!this.expencesForm.controls.from.valid) {
            this.errors.from = true;
            this.resetErrorState('');
            return;
        }
        if (!this.expencesForm.controls.to.valid) {
            this.errors.to = true;
            this.resetErrorState('');
            return;
        }
        if (!this.expencesForm.controls.amount.valid) {
            this.errors.amount = true;
            const old_value = this.expencesForm.value.amount;
            this.expencesForm.patchValue({
                amount: this.textService.currentText.errorAmountNotNumber
            });
            this.expencesForm.controls['amount'].disable();
            this.amount.nativeElement.blur();
            this.resetErrorState(old_value);
            return;
        }

        if (!this.expencesForm.controls.currency.valid) {
            this.errors.currency = true;
            this.resetErrorState('');
            return;
        }
    }

    public checkForm(): void {
        if (!this.expencesForm.valid) {
            this.displayError();
        } else {
            this.dialogRef.close({
                publicId: this.userService.currentUser.id,
                from: `${this.expencesForm.value.from.getFullYear()}-${this.expencesForm.value.from.getMonth() + 1}-${this.expencesForm.value.from.getDate()}`,
                to: `${this.expencesForm.value.to.getFullYear()}-${this.expencesForm.value.to.getMonth() + 1}-${this.expencesForm.value.to.getDate()}`,
                name: this.expencesForm.value.name,
                amount: this.expencesForm.value.amount * -1,
                currency: this.expencesForm.value.currency
            });
        }
    }

    private resetErrorState(val: string): void {
        setTimeout(() => {
            if (!this.expencesForm.controls.name.valid) {
                this.errors.name = false;
                this.expencesForm.patchValue({
                    name: val,
                });
                this.expencesForm.controls['name'].enable();
            }
            if (!this.expencesForm.controls.from.valid) {
                this.errors.from = false;
            }
            if (!this.expencesForm.controls.to.valid) {
                this.errors.to = false;
            }
            if (!this.expencesForm.controls.amount.valid) {
                this.errors.amount = false;
                this.expencesForm.patchValue({
                    amount: val,
                });
                this.expencesForm.controls['amount'].enable();
            }
            if (!this.expencesForm.controls.currency.valid) {
                this.errors.currency = false;
            }
        }, 2000);
    }
}
