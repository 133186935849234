<div matDialogTitle>
    <div class="d-flex w-100 align-items-center justify-content-between headingTitle mb-2" style="border-bottom: 3px solid #dbdbdb">
        <h2 class="mb-2 d-flex">
            <mat-icon>location_on</mat-icon> Shipping Address
        </h2>
        <mat-icon class="close cursor-pointer" (click)="closePopup()">close</mat-icon>
    </div>
    <!--  -->
</div>

<mat-dialog-content>
    <div class="row">
        <table class="table">
            <tr>
                <td>Shipping Address 1</td>
                <td>{{this.data.order.shipAddress1}}</td>
            </tr>
            <tr>
                <td>Shipping Address 2</td>
                <td>-</td>
            </tr>
            <tr>
                <td>City</td>
                <td>{{this.data.order.shipCity}}</td>
            </tr>
            <tr>
                <td>Shipping State</td>
                <td>{{this.data.order.shipState}}</td>
            </tr>
            <tr>
                <td>Shipping Country</td>
                <td>{{this.data.order.shipCountry}}</td>
            </tr>
            <tr>
                <td>Zip Code</td>
                <td>{{this.data.order.zip}}</td>
            </tr>
        </table>
        <!-- <div class="col-md-6">
            <p>Shipping Address 1</p>
            <p>Shipping Address 2</p>
            <p>City</p>
            <p>Shipping State</p>
            <p>Shipping Country</p>
            <p>Zip Code</p>
        </div>
        <div class="col-md-6">
            <p>{{this.data.order.shipAddress1}}</p>
            <p>-</p>
            <p>{{this.data.order.shipCity}}</p>
            <p>{{this.data.order.shipState}}</p>
            <p>{{this.data.order.shipCountry}}</p>
            <p>{{this.data.order.zip}}</p>
        </div> -->
    </div>
</mat-dialog-content>