import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { TextService } from '../../../services/text.service';
import { AccountSettings } from '../../../services/account-settings';
import { ConstService } from '../../../services/const-service';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  templateUrl: './edit-short-name-dialog.component.html',
  styleUrls: ['./edit-short-name-dialog.component.scss']
})

export class EditShortNameDialogComponent implements OnInit {
  submitForm = this.fb.group({
    shortName: ['', [Validators.required]]
  });
  salesChannelsList: any;
  loader: boolean = true;
  constructor(
    public userService: UserService,
    public accountSettings: AccountSettings,
    public fb: FormBuilder,
    public constService: ConstService,
    public textService: TextService,
    public dialogRef: MatDialogRef<EditShortNameDialogComponent>,
    public mixpanelService: MixpanelService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.mixpanelService.init(null);
    this.submitForm.patchValue({
      shortName: this.data.product.shortName ? this.data.product.shortName : this.data.product.title,
    });
    if(this.submitForm.controls.shortName) {
      this.accountSettings.getShortNames(this.userService.currentUser.id, this.data.product.SKU ? this.data.product.SKU : this.data.product.sku).subscribe((data) => {
        this.salesChannelsList = JSON.parse(JSON.stringify(this.constService.salesChannels));
        this.salesChannelsList.splice(this.salesChannelsList[0], 1);
        this.salesChannelsList.map(item => {
          item.value = data.products[0][item.dbShortName];
        });
        this.loader = false;
      });
    }
  }

  public ngOnInit(): void {
  }

  submit() {
    let formData = {
      "asin": this.data.product.asin,
      "sku": this.data.product.SKU ? this.data.product.SKU : this.data.product.sku,
      "defaultShortName": this.submitForm.controls.shortName.value,
    }
    for (let i = 0; i < this.salesChannelsList.length; i++) {
      if (this.salesChannelsList[i].viewValue == 'Amazon.com.au' && this.salesChannelsList[i].value) {
        formData["shortNameAU"] = this.salesChannelsList[i].value;
      }
      if (this.salesChannelsList[i].viewValue == 'Amazon.co.uk' && this.salesChannelsList[i].value) {
        formData["shortNameUK"] = this.salesChannelsList[i].value;
      }
      if (this.salesChannelsList[i].viewValue == 'Amazon.fr' && this.salesChannelsList[i].value) {
        formData["shortNameFR"] = this.salesChannelsList[i].value;
      }
      if (this.salesChannelsList[i].viewValue == 'Amazon.com.mx' && this.salesChannelsList[i].value) {
        formData["shortNameMX"] = this.salesChannelsList[i].value;
      }
      if (this.salesChannelsList[i].viewValue == 'Amazon.it' && this.salesChannelsList[i].value) {
        formData["shortNameIT"] = this.salesChannelsList[i].value;
      }
      if (this.salesChannelsList[i].viewValue == 'Amazon.es' && this.salesChannelsList[i].value) {
        formData["shortNameES"] = this.salesChannelsList[i].value;
      }
      if (this.salesChannelsList[i].viewValue == 'Amazon.com' && this.salesChannelsList[i].value) {
        formData["shortNameCOM"] = this.salesChannelsList[i].value;
      }
      if (this.salesChannelsList[i].viewValue == 'Amazon.de' && this.salesChannelsList[i].value) {
        formData["shortNameDE"] = this.salesChannelsList[i].value;
      }
      if (this.salesChannelsList[i].viewValue == 'Amazon.co.jp' && this.salesChannelsList[i].value) {
        formData["shortNameJP"] = this.salesChannelsList[i].value;
      }
      if (this.salesChannelsList[i].viewValue == 'Amazon.ca' && this.salesChannelsList[i].value) {
        formData["shortNameCA"] = this.salesChannelsList[i].value;
      }
    }
    this.accountSettings.saveShortName(this.userService.currentUser.sellerId, formData).subscribe((data) => {
      this.dialogRef.close(formData);
    });
  }

}
