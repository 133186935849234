<ninja-dashboard-sidenav (links)="grantAccessLinksEvent($event)"></ninja-dashboard-sidenav>
<ninja-dashboard-header [links]="grantAccessLinks"></ninja-dashboard-header>
<!--[ngStyle]="userService.dashboardLoader? {'display': 'block'} : {'display': 'none'}"-->
<!--*ngIf="userService.dashboardLoader"-->
<router-outlet>
  <div class="preloader-wrapper-dashboard" [style.display]="userService.dashboardLoader ? 'flex' : 'none'" [class.wide]="userService.smallSidebar">
    <div class="preloader">
      <img [src]="loaderImage" alt="preloader">
    </div>
  </div>
  <ninja-notification [active]="userService.showNotification" [message]="userService.notificationMessage" [title]="userService.notificationTitle">
  </ninja-notification>
</router-outlet>