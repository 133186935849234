<div class="product-settings-filters custom-selects">
  <div class="filters-container">
    <div class="a-side">
      <div class="btn-container" (click)="mixpanelService.track('q product settings - filters clicked');isFilters = !isFilters">
        <button mat-button mat-raised-button class="default-btn filter-btn" type="button" [class.active]="isFilters">
          {{textService.currentText.filters}}
        </button>
      </div>
      <form class="filters settings-filters custom-selects" [class.active]="isFilters">
        <div class="input-wrapper">
          <mat-form-field>
            <input matInput [placeholder]="textService.currentText.name" type="text" name="filtersName"
              [(ngModel)]="filtersName" (keyup)="checkFilters()">
            <div class="clear-input" *ngIf="filtersName.length > 0" (click)="filtersName = ''; checkFilters()"></div>
          </mat-form-field>
        </div>
        <div class="input-wrapper">
          <mat-form-field>
            <input matInput name="sku" [(ngModel)]="SKU" type="text" (keyup)="checkFilters()"
              [placeholder]="textService.currentText.SKU">
            <div class="clear-input" *ngIf="SKU.length > 0" (click)="SKU = ''; checkFilters()"></div>
          </mat-form-field>
        </div>
        <div class="input-wrapper">
          <mat-form-field>
            <input matInput type="text" (keyup)="checkFilters()" name="asin" [(ngModel)]="asin"
              [placeholder]="textService.currentText.asin">
            <div class="clear-input" *ngIf="asin.length > 0" (click)="asin = ''; checkFilters()"></div>
          </mat-form-field>
        </div>
        <button mat-button (click)="clearFilter()" mat-raised-button class="default-btn clear-btn" type="button">
          {{textService.currentText.clearAll}}
        </button>
      </form>
    </div>
  </div>
  <div class="b-side">
    <input matInput type="number" (keydown)="$event.key === 'e' ? $event.preventDefault() : null" id="filter-name"
      name="changeCostValue" [(ngModel)]="changeCostValue">
    <button mat-button class="default-btn change-cost" (click)="changeCost()"
      [disabled]="!changeCostValue || changeCostValue?.length === 0">Change cost
    </button>
  </div>
</div>
<div class="table-container">
  <div class="table-wrapper prod-set-wrapper">
    <div class="table-header-wrapper">
      <div class="table-header prod-set-header" [class.fullWidth]="userProducts?.length <= 5">
        <!-- <div class="td">
          <mat-checkbox disableRipple (change)="checkAll()" [(ngModel)]="checkAllProducts"></mat-checkbox>
        </div> -->
        <div class="td"></div>
        <div class="td" (click)="functions.sortColumns(userProducts, 'isHiden'); getUpdatePaginator(0)">
          {{textService.currentText.hide}}</div>
        <div class="td">{{textService.currentText.image}}</div>
        <div class="td" (click)="functions.sortColumns(userProducts, 'shortName'); getUpdatePaginator(0)">
          {{textService.currentText.shortName}}</div>
        <div class="td" (click)="functions.sortColumns(userProducts, 'SKU'); getUpdatePaginator(0)">
          {{textService.currentText.SKU}}</div>
        <div class="td" (click)="functions.sortColumns(userProducts, 'asin'); getUpdatePaginator(0)">
          {{textService.currentText.asin}}</div>
        <div class="td" (click)="functions.sortColumns(userProducts, 'shortName'); getUpdatePaginator(0)">
          {{textService.currentText.displayedName}}
        </div>
        <div class="td text-center" (click)="functions.sortColumns(userProducts, 'cost'); getUpdatePaginator(0)">
          <div class="d-flex flex-column">
            <span>{{textService.currentText.cogs}}</span>
            <span style="text-transform: capitalize;font-size: 11px;">(Per Unit)</span>
          </div>
        </div>
        <!-- <div class="td"></div> -->
      </div>
    </div>
    <div class="table-holder table-holder--prod-set" *ngIf="productsListPaginator?.length > 0">
      <div class="table prod-set-table">
        <div class="table-row" *ngFor="let products of productsListPaginator; let i = index"
          [class.checked]="products.checked" [class.disabled]="products.isHiden">
          <div class="tr" (click)="mixpanelService.track('q product settings - expand');products.showDetails = !products.showDetails;"
            [ngClass]="products.showDetails ? 'border-bottom' : ''">
            <!-- <div class="td">
            <mat-checkbox disableRipple [disabled]="products.isHiden" [(ngModel)]="products.checked"></mat-checkbox>
          </div> -->
            <div class="td">
              <ng-container *ngIf="!products.showDetails">+</ng-container>
              <ng-container *ngIf="products.showDetails">-</ng-container>
            </div>
            <div class="td">
              <!-- <div class="hide-icon" (click)="products.isHiden = !products.isHiden; $event.stopPropagation();
                products.checked ? products.checked = false : null; saveSettings()"
                [class.no-visible]="products.isHiden">
                <ninja-tooltip [isForProducts]="true" [text]="products.isHiden ? invisibleTooltip : visibleTooltip"
                  class="left-align hide-icon-tooltip">
                </ninja-tooltip>
              </div> -->
              <div class="hide-icon" (click)="mixpanelService.track('q product settings - hide');products.isHiden = !products.isHiden; $event.stopPropagation(); setHide(products)"
                [class.no-visible]="products.isHiden">
                <ninja-tooltip [isForProducts]="true" [text]="products.isHiden ? invisibleTooltip : visibleTooltip"
                  class="left-align hide-icon-tooltip">
                </ninja-tooltip>
              </div>
            </div>
            <div class="td">
              <div class="product-image-holder">
                <img [src]="products?.image" alt="">
              </div>
            </div>
            <div class="td">
              {{products.shortName}}
            </div>
            <div class="td">
              {{products.SKU}}
            </div>
            <div class="td">
              {{products.asin}}
            </div>
            <div class="td">
              {{products.shortName}}
            </div>
            <div class="td">
              {{products.defaultCurrency}}
              {{products.cost}}
            </div>
            <!-- <div class="td">
            <button class="edit-btn" (click)="openEditDialog(products, i)" [disabled]="products.isHiden">
              <div class="edit-btn-content">
                <div class="icon edit-icon"></div>
                <span>{{textService.currentText.edit}}</span>
              </div>
            </button>
          </div> -->
          </div>
          <app-product-cost [products]="products" *ngIf="products.showDetails" [productsListPaginator]="productsListPaginator" [updatedProductsListPaginator]="updatedProductsListPaginator"></app-product-cost>
        </div>
      </div>
    </div>
    <div class="table-holder" *ngIf="productsListPaginator?.length == 0">
      <div class="table">
        <div class="tr">
          <div class="td not-found">
            {{textService.currentText.noDataFound}}.
          </div>
        </div>
      </div>
    </div>
    <mat-paginator [length]="userProducts?.length" [pageSize]="pageSize" (page)="getUpdatePaginator($event.pageIndex)">
    </mat-paginator>
  </div>
</div>
<!-- <ninja-notification [active]="userService.showNotification" [message]="notificationMessage" [title]="notificationTitle">
</ninja-notification> -->