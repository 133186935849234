import {Component, Input, OnChanges} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'ninja-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})

export class NotificationComponent implements OnChanges {
  @Input() active: boolean;
  @Input() message: string;
  @Input() title: string;
  public timer: any;

  constructor(public userService: UserService) {
  }

  public ngOnChanges(): void {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.active = false;
      this.userService.showNotification = false;
    }, 6000);
  }
}
