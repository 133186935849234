import {Injectable} from '@angular/core';
import {ConstService} from './const-service';

@Injectable()

export class Functions {
  public currentKey: any;

  constructor(public constService: ConstService) {}

  public setDropDownPosition(myElement, className): void {
    const containerTop = myElement.getBoundingClientRect().top + 32 + 'px';
    const containerLeft = myElement.getBoundingClientRect().left + 'px';
    const containerWidth = myElement.getBoundingClientRect().width + 'px';
    setTimeout(() => {
      const elSelect = window.document.getElementsByClassName(className)[0] as HTMLElement;
      if (elSelect) {
        elSelect.parentElement.style.top = containerTop;
        elSelect.parentElement.style.left = containerLeft;
        elSelect.parentElement.style.minWidth = containerWidth;
        elSelect.parentElement.style.maxWidth = containerWidth;
      }
    }, 0);
  }

  public getColors (data, type): Array<string> {
    let colors = [];
    if (data[type] && data[type].length) {
      for (let i = 0; Math.ceil(data[type].length / 25) + 1 > i; i++) {
        colors = colors.concat([...this.constService.pieChartsColors]);
      }
    }
    return colors;
  }

  public sortColumns (array, key, isString?): any {
    if (this.currentKey === key) {
      array.sort((a, b) => {
        let x = a[key];
        let y = b[key];
        if (!a[key]) {
          a[key] = '';
        }
        if (!b[key]) {
          b[key] = '';
        }
        if (isString) {
          x = JSON.parse(JSON.stringify(a[key].toLowerCase()));
          y = JSON.parse(JSON.stringify(b[key].toLowerCase()));
        }
        if (x > y) {
          return -1;
        } else if (x < y) {
          return 1;
        } else {
          return 0;
        }
      });
      this.currentKey = null;
    } else {
      array.sort((a, b) => {
        let x = a[key];
        let y = b[key];
        if (!a[key]) {
          a[key] = '';
        }
        if (!b[key]) {
          b[key] = '';
        }
        if (isString) {
          x = JSON.parse(JSON.stringify(a[key].toLowerCase()));
          y = JSON.parse(JSON.stringify(b[key].toLowerCase()));
        }
        if (x < y) {
          return -1;
        } else if (x > y) {
          return 1;
        } else {
          return 0;
        }
      });
      this.currentKey = key;
    }
  }

  public sortOrders (array, key, stopWord?): any {
    if (this.currentKey === key) {
      array.sort((a, b) => {
        this.currentKey = null;
        if (a.ordersList[0][key] === stopWord || b.ordersList[0][key] === stopWord) {
          return 1;
        }
        if (a.ordersList[0][key] > b.ordersList[0][key]) {
          return -1;
        } else if (a.ordersList[0][key] < b.ordersList[0][key]) {
          return 1;
        } else {
          return 0;
        }
      });

    } else {
      array.sort((a, b) => {
        this.currentKey = key;
        if (a.ordersList[0][key] === stopWord || b.ordersList[0][key] === stopWord) {
          return -1;
        }
        if (a.ordersList[0][key] < b.ordersList[0][key]) {
          return -1;
        } else if (a.ordersList[0][key] > b.ordersList[0][key]) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

}
