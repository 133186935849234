import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { TermsDialogComponent } from '../../dialogs/terms-condition-dialog/terms-dialog.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConstService } from '../../../services/const-service';
import { TokenService } from '../../../services/token.service';
import { MixpanelService } from '../../../services/mixpanel.service';
import { StorageBrowser } from '../../../services/storage.browser';
import { GoogleAnalyticsEventsService } from '../../../services/google-analytics.service';
import { TextService } from '../../../services/text.service';
import { AbTestsService } from 'angular-ab-tests';

@Component({
  selector: 'ninja-sign-up-page',
  templateUrl: './sign-up-page.component.html',
  styleUrls: ['./sign-up-page.component.scss']
})

export class SignUpPageComponent implements OnInit {
  //@ViewChild('email', { static: true }) loginInput: ElementRef;
  @ViewChild('firstNameInput', { static: true }) firstNameInput: ElementRef;
  //@ViewChild('lastNameInput', { static: true }) lastNameInput: ElementRef;
  @ViewChild('passwordInput', { static: true }) passwordInput: ElementRef;
  @ViewChild('confirmPassInput', { static: true }) confirmPassInput: ElementRef;
  public passwordInputCaps = false;
  public firstNameInputCaps = false;
  public emailAllreadyUse = false;
  public lastNameInputCaps = false;
  public emailCaps = false;
  public confirmPassInputCaps = false;
  public checkboxTAC = false;
  public newRegion = false;
  public errors: any = {};
  public firstName = '';
  public lastName = '';
  public userEmail = '';
  public capsOn: any;
  public userPass = '';
  public userPassConfirm = '';
  public passwordInputType = 'password';
  public rePasswordInputType = 'password';
  public isPasswordTooltip = false;
  public confirmPass = false;
  public user: any;
  public loggedIn: any;
  public affiliate: any;
  public tooltipPassword = this.textService.currentText.passwordTooltip + ' ~!@#$%^&amp;*_-+=`|(){}[]:;"\'<>,.?/"';

  constructor(public fb: FormBuilder,
    public userService: UserService,
    public tokenService: TokenService,
    public constService: ConstService,
    public textService: TextService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public storageBrowser: StorageBrowser,
    public mixpanelService: MixpanelService,
    public matDialog: MatDialog,
    private authService: SocialAuthService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    public ref: ChangeDetectorRef,
    private abTestsService: AbTestsService) {
    this.mixpanelService.init(null);
    var ABVer=this.abTestsService.getVersion();
    this.mixpanelService.track('q sign up page - ' + ABVer);
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    this.capsOn = event.getModifierState && event.getModifierState('CapsLock');
  }

  public submitForm = this.fb.group({
    email: ['',
      [Validators.required,
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    password: ['',
      [Validators.required,
      Validators.minLength(6),
      Validators.pattern(/(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[a-zA-Z]).*$/)]],
    firstName: ['',
      [Validators.required,
      Validators.minLength(1)]],
   /* lastName: ['',
      [Validators.required,
      Validators.minLength(1)]],*/
    // checkboxName: [''],
    // confirmPassword: ['',
    //   [Validators.required,
    //   Validators.minLength(1)]]
  });

  public ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });
    this.isNewRegion();
    if (this.userService.currentUser.id && Object.keys(this.activatedRoute.snapshot.queryParams).length !== 0 &&
      this.activatedRoute.snapshot.queryParams.MWSAuthToken !== 'null' &&
      this.activatedRoute.snapshot.queryParams.MWSAuthToken &&
      this.activatedRoute.snapshot.queryParams.Marketplace !== 'null' &&
      this.activatedRoute.snapshot.queryParams.Merchant !== 'null' &&
      this.activatedRoute.snapshot.queryParams.webApp_irpregistration !== 'null' &&
      this.activatedRoute.snapshot.queryParams.Signature !== 'null') {
      const dataToVerify = {
        MWSAuthToken: this.activatedRoute.snapshot.queryParams.MWSAuthToken,
        Marketplace: this.activatedRoute.snapshot.queryParams.Marketplace,
        Merchant: this.activatedRoute.snapshot.queryParams.Merchant,
        webApp_irpregistration: this.activatedRoute.snapshot.queryParams.webApp_irpregistration,
        Signature: this.activatedRoute.snapshot.queryParams.Signature
      }
      this.userService.verifyUser(dataToVerify).subscribe((data) => {
        this.userService.currentUser.id = data.publicId;
        this.storageBrowser.set('$UserToken$tokenId', data.publicId);
        this.router.navigate(['grant-access'],
          {
            queryParams: {
              status: data.isSuccess,
              isNewRegion: this.newRegion,
              region: this.activatedRoute.snapshot.queryParams.Marketplace,
              id: this.activatedRoute.snapshot.queryParams.webApp_irpregistration
            }
          });
      });
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.a_id) {
        this.affiliate = params.a_id;
      }
      if (params.firstName /*&& params.lastName*/ && params.email) {
        this.firstName = params.firstName;
        //this.lastName = "empty";
        this.userEmail = params.email;
      }
    });
    this.userService.loader = false;
  }

  public agreeCheckboxChange(completed: boolean) {
    if (completed) {
      this.checkboxTAC = true;
    } else {
      this.checkboxTAC = false;
    }
  }

  public openTAC(): void {
    this.matDialog.open(TermsDialogComponent, {
      data: {
        'dialogFor': 'login-to-posting',
      },
    }).afterClosed().subscribe(result => {
      this.checkboxTAC = result;
      this.ref.detectChanges();
    });
  }

  public openPP(): void {
    this.matDialog.open(TermsDialogComponent, {
      data: {
        'dialogFor': 'privacy-policy',
      },
    }).afterClosed().subscribe();
  }

  public loginWithGoogle() {
    console.log(GoogleLoginProvider.PROVIDER_ID)
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res) => {
      console.log("Google", res)
      if (res.idToken && res.id) {
        this.signUpWithGoogle(res.idToken);
      }
    }).catch((err) => {
      console.log(err)
    });
  }

  public signUpWithGoogle(userToken) {
    this.userService.loader = true;
    if (this.activatedRoute.snapshot.queryParams.amazon_state !== undefined &&
      this.activatedRoute.snapshot.queryParams.selling_partner_id !== undefined &&
      this.activatedRoute.snapshot.queryParams.amazon_callback_uri !== undefined) {
      const data = {
        googleAccessToken: userToken,
        amazon_state: this.activatedRoute.snapshot.queryParams.amazon_state,
        amazon_callback_uri: this.activatedRoute.snapshot.queryParams.amazon_callback_uri,
        selling_partner_id: this.activatedRoute.snapshot.queryParams.selling_partner_id
      }
      this.userService.signUpWithGoogle(data).subscribe((res) => {
        console.log("AppStore", res);
        if (res.response || res.id) {
          const redirect_uri = 'https://webapp.quantifyninja.com/spa'
          const amazonUrl = 'https://sellercentral.amazon.com/apps/authorize/confirm/amzn1.sellerapps.app.ca82764b-41ed-46b1-83c6-298a96554243?amazon_state=' + this.activatedRoute.snapshot.queryParams.amazon_state + '&state=' + res.userId + '&redirect_uri=' + redirect_uri;
          window.location.replace(amazonUrl);
        } else if (!res.response && this.activatedRoute.snapshot.queryParams.selling_partner_id) {
          this.userService.loader = false;
          this.matDialog.open(ConfirmationDialogComponent, {
            data: {
              'dialogFor': 'selling-grant-access',
              'sellerId': this.activatedRoute.snapshot.queryParams.selling_partner_id
            },
          }).afterClosed().subscribe(result => { })
        } else {
          this.userService.loader = false;
        }
      });
    } else {
      this.userService.signUpWithGoogle({ googleAccessToken: userToken }).subscribe((userData) => {
        this.userService.loader = false;
        this.userService.superUserActive = false;
        if (userData.userId) {
          this.userService.currentUser['userId'] = userData.userId;
        }
        if (userData.isSuspended) {
          this.showAccountOnHoldDialog();
          return;
        }
        if (!userData.isVerified) {
          for (const prop in this.userService.currentUser) { if (this.userService.currentUser.hasOwnProperty(prop)) { delete this.userService.currentUser[prop]; } }
          this.userService.currentUser.id = userData.id;
          this.mixpanelService.identify(userData.userId);
          this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
          this.tokenService.setToken(userData);
          this.router.navigate(['choose-region']);
        } else {
          console.log(userData.sellerId);
          this.userService.currentUser = userData;
          this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
          if (this.userService.currentUser.language && this.constService.languages.indexOf(this.userService.currentUser.language) > 0) {
            this.textService.setLangBadge(this.userService.currentUser.language);
            this.textService.changeLang(this.textService.currentText, this.textService[`text${this.userService.currentUser.language}`]);
          } else {
            this.textService.setLangBadge('EN');
            this.textService.changeLang(this.textService.currentText, this.textService.textEN);
          }
          // this.tokenService.savePublicId(data.id);
          if (this.checkRegion(this.constService.arrayRegions, userData.marketplace)) {
            this.userService.currentUser.marketplace = userData.marketplace;
          }
          this.tokenService.setToken(userData);
          this.constService.setRegions(userData);
          this.checkDestination();
        }

      })
    }

  }

  public signUpWithFacebook() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((res) => {
      if (res.authToken && res.id) {
        this.signUpWithFb(res.authToken, res.id);
      }
    }).catch((err) => {
      console.log(err)
    });
  }

  public signUpWithFb(userToken, userId) {
    this.userService.loader = true;
    if (this.activatedRoute.snapshot.queryParams.amazon_state !== undefined &&
      this.activatedRoute.snapshot.queryParams.selling_partner_id !== undefined &&
      this.activatedRoute.snapshot.queryParams.amazon_callback_uri !== undefined) {
      const data = {
        fbAccessToken: userToken,
        fbUserId: userId,
        amazon_state: this.activatedRoute.snapshot.queryParams.amazon_state,
        amazon_callback_uri: this.activatedRoute.snapshot.queryParams.amazon_callback_uri,
        selling_partner_id: this.activatedRoute.snapshot.queryParams.selling_partner_id
      }
      this.userService.signUpWithFacebook(data).subscribe((res) => {
        console.log("Google", res)
        if (res.response || res.id) {
          this.userService.loader = false;
          const redirect_uri = 'https://webapp.quantifyninja.com/spa'
          const amazonUrl = 'https://sellercentral.amazon.com/apps/authorize/confirm/amzn1.sellerapps.app.ca82764b-41ed-46b1-83c6-298a96554243?amazon_state=' + this.activatedRoute.snapshot.queryParams.amazon_state + '&state=' + res.userId + '&redirect_uri=' + redirect_uri;
          window.location.replace(amazonUrl);
        } else if (!res.response && this.activatedRoute.snapshot.queryParams.selling_partner_id) {
          this.userService.loader = false;
          this.matDialog.open(ConfirmationDialogComponent, {
            data: {
              'dialogFor': 'selling-grant-access',
              'sellerId': this.activatedRoute.snapshot.queryParams.selling_partner_id
            },
          }).afterClosed().subscribe(result => { })
        } else {
          this.userService.loader = false;
        }
      });
    } else {
      this.userService.signUpWithFacebook({ fbAccessToken: userToken, fbUserId: userId }).subscribe((userData) => {
        this.userService.loader = false;
        this.userService.superUserActive = false;
        if (userData.userId) {
          this.userService.currentUser['userId'] = userData.userId;
        }
        if (userData.isSuspended) {
          this.showAccountOnHoldDialog();
          return;
        }
        if (!userData.isVerified) {
          for (const prop in this.userService.currentUser) { if (this.userService.currentUser.hasOwnProperty(prop)) { delete this.userService.currentUser[prop]; } }
          this.userService.currentUser.id = userData.id;
          this.mixpanelService.identify(userData.userId);
          this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
          this.tokenService.setToken(userData);
          this.router.navigate(['choose-region']);
        } else {
          console.log(userData.sellerId);
          this.userService.currentUser = userData;
          this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
          if (this.userService.currentUser.language && this.constService.languages.indexOf(this.userService.currentUser.language) > 0) {
            this.textService.setLangBadge(this.userService.currentUser.language);
            this.textService.changeLang(this.textService.currentText, this.textService[`text${this.userService.currentUser.language}`]);
          } else {
            this.textService.setLangBadge('EN');
            this.textService.changeLang(this.textService.currentText, this.textService.textEN);
          }
          // this.tokenService.savePublicId(data.id);
          if (this.checkRegion(this.constService.arrayRegions, userData.marketplace)) {
            this.userService.currentUser.marketplace = userData.marketplace;
          }
          this.tokenService.setToken(userData);
          this.constService.setRegions(userData);
          this.checkDestination();
        }

      })
    }

  }

  public signUp(): void {
    if (!this.submitForm.valid) {
      this.checkForm();
      return;
    }
    // if (!this.checkboxTAC) {
    //   this.openTAC();
    //   return;
    // }
    let pass = '';
    if (this.userPass === this.userPassConfirm) {
      pass = this.userPass;
    } else {
      if (this.userPassConfirm.length > 0) {
        this.errors.password = true;
        this.confirmPass = true;
        const old_value = this.submitForm.value.password;
        this.passwordInputType = 'text';
        this.submitForm.patchValue({
          password: 'Passwords do not match'
        });
        this.submitForm.controls['password'].disable();
        this.passwordInput.nativeElement.blur();
        this.resetErrorState(old_value);
        return;
      }
    }
    this.userService.loader = true;
    const res = {
      firstName: this.firstName,
      lastName: "last",
      email: this.userEmail,
      pass: this.userPass,
      affiliateId: this.affiliate
    };

    if (this.activatedRoute.snapshot.queryParams.amazon_state !== undefined &&
      this.activatedRoute.snapshot.queryParams.selling_partner_id !== undefined &&
      this.activatedRoute.snapshot.queryParams.amazon_callback_uri !== undefined) {
      res["amazon_state"] = this.activatedRoute.snapshot.queryParams.amazon_state;
      res["selling_partner_id"] = this.activatedRoute.snapshot.queryParams.selling_partner_id;
      res["amazon_callback_uri"] = this.activatedRoute.snapshot.queryParams.amazon_callback_uri;
      res["referer"] = this.activatedRoute.snapshot.queryParams.referer;
      console.log(this.activatedRoute.snapshot.queryParams.selling_partner_id)
      this.userService.signUpAndLogin(res).subscribe((data) => {
        this.userService.loader = false;
        if (data.response || data.id) {
          const redirect_uri = 'https://webapp.quantifyninja.com/spa'
          const amazonUrl = 'https://sellercentral.amazon.com/apps/authorize/confirm/amzn1.sellerapps.app.ca82764b-41ed-46b1-83c6-298a96554243?amazon_state=' + this.activatedRoute.snapshot.queryParams.amazon_state + '&state=' + data.userId + '&redirect_uri=' + redirect_uri;
          window.location.replace(amazonUrl);
        } else if (!data.response && this.activatedRoute.snapshot.queryParams.selling_partner_id) {
          this.userService.loader = false;
          this.matDialog.open(ConfirmationDialogComponent, {
            data: {
              'dialogFor': 'selling-grant-access',
              'sellerId': this.activatedRoute.snapshot.queryParams.selling_partner_id
            },
          }).afterClosed().subscribe(result => { })
        } else {
          this.emailAllreadyUse = true;
          this.displayError(data.message);
          this.userService.loader = false;
          return;
        }

      })
    } else {
      this.userService.signUp(res)
        .subscribe((data) => {
          this.userService.loader = false;
          if (data.response) {
            console.log("data.userId", data.userId)
            this.mixpanelService.identify(data.userId);
            this.mixpanelService.alias(data.userId);
            this.mixpanelService.track('q user sign up - completed');
            this.googleAnalyticsEventsService.emitPageView("/signup");
            this.goToLogin();
          } else {
            this.emailAllreadyUse = true;
            this.displayError(data.message);
            this.userService.loader = false;
            return;
          }
        });
    }

  }

  login(pass) {
    this.userService.login({
      'login': this.userEmail,
      'pass': pass
    }).subscribe(
      (res) => {
        this.userService.superUserActive = false;
        if (res.id === 0) {
          this.userService.currentUser = res;
          this.displayError(this.userService.currentUser.message);
          this.userService.loader = false;
          return;
        }
        if (res.id !== 0) {
          if (res.userId) {
            this.userService.currentUser['userId'] = res.userId;
          }
          this.userService.currentUser = res;
          this.storageBrowser.set('$UserToken$originSellerId', res.sellerId);
          if (this.userService.currentUser.language && this.constService.languages.indexOf(this.userService.currentUser.language) > 0) {
            this.textService.setLangBadge(this.userService.currentUser.language);
            this.textService.changeLang(this.textService.currentText, this.textService[`text${this.userService.currentUser.language}`]);
          } else {
            this.textService.setLangBadge('EN');
            this.textService.changeLang(this.textService.currentText, this.textService.textEN);
          }
          // this.tokenService.savePublicId(data.id);
          if (this.checkRegion(this.constService.arrayRegions, res.marketplace)) {
            this.userService.currentUser.marketplace = res.marketplace;
          }
          this.tokenService.setToken(res);
          this.constService.setRegions(res);
          this.router.navigate(['grant-access'], { queryParams: { promoteFBbot: "promoteFBbot" } });
        }
      },
      error => {
        this.userService.loader = false;
      });
  }

  public showAccountOnHoldDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'login-hold',
      },
    }).afterClosed().subscribe();
  }

  checkRegion(data: any, region: any): boolean {
    for (let i = 0; data.length > i; i++) {
      if (data[i] === region) {
        return true;
      }
    }
  }

  public goToLoginWithAddLink(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.a_id) {
        this.router.navigate(['login'],
          { queryParams: { a_id: params.a_id } });
      } else if (this.activatedRoute.snapshot.queryParams.amazon_state !== undefined &&
        this.activatedRoute.snapshot.queryParams.selling_partner_id !== undefined &&
        this.activatedRoute.snapshot.queryParams.amazon_callback_uri !== undefined) {
        const queryParam = {
          amazon_state: this.activatedRoute.snapshot.queryParams.amazon_state,
          selling_partner_id: this.activatedRoute.snapshot.queryParams.selling_partner_id,
          amazon_callback_uri: this.activatedRoute.snapshot.queryParams.amazon_callback_uri
        }
        this.router.navigate(['login'], { queryParams: queryParam });
      } else {
        this.router.navigate(['login']);
      }
    });
  }

  public goToLogin(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'signUp-confirm',
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.activatedRoute.queryParams.subscribe((params) => {
          if (params.a_id) {
            this.router.navigate(['login'],
              { queryParams: { a_id: params.a_id } });
          } else {
            this.router.navigate(['login']);
          }
        });
      }
    });
  }

  public checkForm(): boolean {
    if (!this.submitForm.valid) {
      if (!this.submitForm.controls.firstName.valid) {
        this.errors.firstName = true;
        this.displayError();
        return;
      }
      /*if (!this.submitForm.controls.lastName.valid) {
        this.errors.lastName = true;
        this.displayError();
        return;
      }*/
      if (!this.submitForm.controls.email.valid) {
        this.errors.emailInvalid = true;
        if (this.submitForm.controls.email.value.length === 0) {
          this.displayError(this.textService.currentText.errorRequired);
        } else {
          this.displayError(this.textService.currentText.errorIncorectEmail);
        }
        return;
      }
      if (!this.submitForm.controls.password.valid) {
        this.errors.password = true;
        this.displayError();
        return;
      }
      if (this.emailAllreadyUse) {
        this.errors.password = true;
        this.displayError();
        return;
      }
      // if (!this.submitForm.controls.confirmPassword.valid) {
      //   this.errors.confirmPassword = true;
      //   this.displayError();
      //   return;
      // }
      return false;
    }
    return this.submitForm.valid;
  }

  private displayError(message?: string): void {
    if (this.emailAllreadyUse) {
      this.errors.email = true;
      const old_value = this.submitForm.value.email;
      this.submitForm.patchValue({
        email: message
      });
      this.submitForm.controls['email'].disable();
      //this.loginInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (this.errors.password) {
      const old_value = this.submitForm.value.password;
      if (this.submitForm.controls.password.errors && this.submitForm.controls.password.errors['minLength']) {
        this.passwordInputType = 'text';
        this.submitForm.patchValue({
          password: this.textService.currentText.errorMoreThan6
        });
      }
      if (this.submitForm.controls.password.errors && this.submitForm.controls.password.errors['pattern']) {
        this.isPasswordTooltip = true;
        this.passwordInputType = 'text';
        this.submitForm.patchValue({
          password: this.textService.currentText.errorIncorectPassFormat
        });
        setTimeout(() => {
          this.isPasswordTooltip = false;
        }, 2000);
      }
      if (this.emailAllreadyUse) {
        this.passwordInputType = 'text';
        this.submitForm.patchValue({
          password: message
        });
      }
      if (this.submitForm.controls.password.value.length === 0) {
        this.passwordInputType = 'text';
        this.submitForm.patchValue({
          password: this.textService.currentText.errorRequired
        });
      }
      this.submitForm.controls['password'].disable();
      this.passwordInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (this.errors.firstName) {
      const old_value = this.submitForm.value.firstName;
      this.submitForm.patchValue({
        firstName: this.textService.currentText.errorRequired
      });
      this.submitForm.controls['firstName'].disable();
      this.firstNameInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    // if (this.errors.confirmPassword) {
    //   this.confirmPass = true;
    //   this.rePasswordInputType = 'text';
    //   const old_value = this.submitForm.value.confirmPassword;
    //   this.submitForm.patchValue({
    //     confirmPassword: this.textService.currentText.errorRequired
    //   });
    //   this.submitForm.controls['confirmPassword'].disable();
    //   this.confirmPassInput.nativeElement.blur();
    //   this.resetErrorState(old_value);
    //   return;
    // }
   /* if (this.errors.lastName) {
      const old_value = this.submitForm.value.lastName;
      this.submitForm.patchValue({
        lastName: this.textService.currentText.errorRequired
      });
      this.submitForm.controls['lastName'].disable();
      this.lastNameInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }*/
    if (this.errors.emailInvalid) {
      const old_value = this.submitForm.value.email;
      this.submitForm.patchValue({
        email: message
      });
      this.submitForm.controls['email'].disable();
      //this.loginInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.confirmPassword) {
        this.submitForm.patchValue({
          confirmPassword: val,
        });
        this.errors.confirmPassword = false;
        this.rePasswordInputType = 'confirmPassword';
        this.submitForm.controls['confirmPassword'].enable();
        this.confirmPass = false;
        return;
      }
      if (this.userPass !== this.userPassConfirm) {
        this.submitForm.patchValue({
          password: val,
        });
        this.errors.password = false;
        this.passwordInputType = 'password';
        this.submitForm.controls['password'].enable();
        this.confirmPass = false;
      }
      if (this.errors.password) {
        this.submitForm.patchValue({
          password: val,
        });
        this.errors.password = false;
        this.passwordInputType = 'password';
        this.submitForm.controls['password'].enable();
      }
      if (this.errors.emailInvalid) {
        this.submitForm.patchValue({
          email: val,
        });
        this.errors.emailInvalid = false;
        this.submitForm.controls['email'].enable();
      }
      if (this.emailAllreadyUse) {
        this.submitForm.patchValue({
          email: val,
        });
        this.errors.email = false;
        this.submitForm.controls['email'].enable();
      }
      if (this.errors.firstName) {
        this.submitForm.patchValue({
          firstName: val,
        });
        this.errors.firstName = false;
        this.submitForm.controls['firstName'].enable();
      }
      /*if (this.errors.lastName) {
        this.submitForm.patchValue({
          lastName: val,
        });
        this.errors.lastName = false;
        this.submitForm.controls['lastName'].enable();
      }*/
    }, 2000);
  }

  public showTooltip(e: any): void {
    this.isPasswordTooltip = e;
  }

  public isNewRegion(): void {
    for (let i = 0; Object.keys(this.constService.regionOriginValue).length > i; i++) {
      if (Object.keys(this.constService.regionOriginValue)[i] === this.activatedRoute.snapshot.queryParams.Marketplace
        && this.tokenService.getToken().user && this.tokenService.getToken().user.emails) {
        for (let j = 0; this.tokenService.getToken().user.emails.length > j; j++) {
          if (this.tokenService.getToken().user.emails[j].submarket ===
            this.constService.regionOriginValue[Object.keys(this.constService.regionOriginValue)[i]]) {
            this.newRegion = false;
          }
        }
      }
    }
  }

  public checkDestination(): any {
    if (!this.userService.currentUser.hasActivePlan && !this.userService.currentUser.isTrial && !this.userService.currentUser.isVa) {
      return this.router.navigate(['dashboard/upgrade']);
    }
    if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isOrderDashboard) {
      return this.router.navigate(['dashboard/orders-dashboard']);
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isDashboard) {
      return this.router.navigate(['dashboard/dashboard-schedules']);
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAutoTemplates) {
      return this.router.navigate(['dashboard/automated-messaging/fba']);
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAlerts) {
      return this.router.navigate(['dashboard/facebook-notifications']);
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isMoneyBack) {
      return this.router.navigate(['dashboard/money-back']);
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isHijacked) {
      return this.router.navigate(['dashboard/hijacked']);
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isOrders) {
      return this.router.navigate(['dashboard/orders'], { queryParams: { page: 'fba' } });
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isKeyWordOptimzer) {
      return this.router.navigate(['dashboard/keywords-optimizer']);
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isConversation) {
      return this.router.navigate(['dashboard/facebook-bot-manager/grant-access']);
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAsin) {
      return this.router.navigate(['dashboard/asin-tracker']);
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAffiliate) {
      return this.router.navigate(['dashboard/affiliate']);
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isCoupons) {
      return this.router.navigate(['dashboard/coupons']);
    } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isUpgrade) {
      return this.router.navigate(['dashboard/upgrade']);
    } else {
      if (this.userService.currentUser.isVa) {
        return this.router.navigate(['dashboard/user/settings/product-settings']);
      } else {
        return this.router.navigate(['dashboard/user/settings/account-settings']);
      }
    }
  }
}
