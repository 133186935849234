<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container">
    <div class="dashboard-header">
      <div class="head-container">
        <h2>{{textService.currentText.conversationManagerTitle}}</h2>
        <button mat-button mat-raised-button [routerLink]="'new-conversation'" class="primary-btn send">
          {{textService.currentText.createNewConversation}}
        </button>
      </div>
      <!--<div class="sub-head-container">-->
      <!--<p>-->
      <!--Some short explanation info about conversation manager and bot creation.-->
      <!--</p>-->
      <!--<p>-->
      <!--If it’s not necessary we can delete this text.-->
      <!--</p>-->
      <!--</div>-->
      <div class="filters-container">
        <div class="a-side">
          <div class="btn-container" (click)="isFilters = !isFilters">
            <button mat-button mat-raised-button class="default-btn filter-btn" type="button" [class.active]="isFilters">
              {{textService.currentText.filters}}
            </button>
          </div>
          <form class="filters custom-selects" [class.active]="isFilters">
            <div class="filter-col">
              <div class="label-name">
                <span>{{textService.currentText.name}}</span>
              </div>
              <input matInput type="text" (keyup)="checkFilters()" [(ngModel)]="filters.name" name="filterName">
              <div class="clear-input" *ngIf="filters.name?.length > 0" (click)="filters.name = null; checkFilters()"></div>
            </div>
            <div class="filter-col">
              <div class="label-name">
                <span>{{textService.currentText.page}}</span>
              </div>
              <div class="select-holder">
                <div (click)="functions.setDropDownPosition(taggedFrame, 'tagged')" #taggedFrame [class.active]="tagged.panelOpen"
                  class="select-wrapper">
                  <mat-form-field>
                    <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown tagged" disableRipple [(ngModel)]="filters.page"
                      (selectionChange)="checkFilters()" #tagged name="tagged">
                      <mat-option *ngFor="let select of pages" [value]="select.name">
                        {{ select.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="filter-col">
              <div class="label-name">
                <span>{{textService.currentText.triggerBy}}</span>
              </div>
              <div class="select-holder">
                <div (click)="functions.setDropDownPosition(noTaggedFrame, 'noTagged')" #noTaggedFrame [class.active]="noTagged.panelOpen"
                  class="select-wrapper">
                  <mat-form-field>
                    <mat-select shouldPlaceholderFloat="false" panelClass="default-dropdown noTagged" disableRipple [(ngModel)]="filters.triggerBy"
                      (selectionChange)="checkFilters()" #noTagged name="noTagged">
                      <mat-option *ngFor="let select of constService.triggersBy" [value]="select.value">
                        {{ select.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <button mat-button mat-raised-button class="default-btn clear-btn white" type="button" (click)="clearFilter()">
              {{textService.currentText.clearAll}}
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header conversation-manager-header" [class.full-width]="currentConversationList?.length <= 5">
            <div class="td"></div>
            <div class="td" (click)="functions.sortColumns(currentConversationList, 'name', true)">{{textService.currentText.name}}</div>
            <div class="td" (click)="functions.sortColumns(currentConversationList, 'pageName')">{{textService.currentText.page}}</div>
            <div class="td" (click)="functions.sortColumns(currentConversationList, 'triggered')">{{textService.currentText.triggerBy}}</div>
            <div class="td" (click)="functions.sortColumns(currentConversationList, 'link')">{{textService.currentText.conversationManagerDirectLink}}
            </div>
          </div>
        </div>
        <div class="table-holder conversation-manager-holder">
          <div class="tr" *ngFor="let conversation of currentConversationList; let i = index" (click)="editCoversation(conversation.conversationId)">
            <div class="td">
              <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"></button>
              <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                <button mat-menu-item>
                  <div class="list-inner-container" (click)="editCoversation(conversation.conversationId)">
                    <div class="menu-icon icon-edit"></div>
                    {{textService.currentText.edit}}
                  </div>
                </button>
                <button mat-menu-item (click)="deleteConversation(conversation.conversationId, i)">
                  <div class="list-inner-container">
                    <div class="menu-icon icon-delete"></div>
                    {{textService.currentText.delete}}
                  </div>
                </button>
              </mat-menu>
            </div>
            <div class="td">
              {{conversation.name}}
            </div>
            <div class="td">
              {{conversation.pageName}}
            </div>
            <div class="td">
              {{conversation.triggered}}
            </div>
            <div class="td" (click)="showLinkDialog(conversation.link); $event.stopPropagation()">
              {{conversation.link}}

              <!--<a [href]="conversation.link" target="_blank">{{conversation.link}}</a>-->
            </div>
          </div>
        </div>
        <div class="table-holder" *ngIf="currentConversationList?.length == 0 && firstTime === false">
          <div class="table">
            <div class="tr">
              <div class="td not-found">
                {{textService.currentText.noDataFound}}.
              </div>
            </div>
          </div>
        </div>
        <div class="table-holder" *ngIf="firstTime">
          <div class="table">
            <div class="tr">
              <div class="td not-found">
                <img src="../../../../../assets/icons/attention-grey.svg" alt="">
                {{textService.currentText.autoResponseText}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>