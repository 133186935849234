import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';

@Injectable()

export class AlertsService extends UserService {


    public fullSellerData(id): Observable<any> {
        return this.post('competetors_alerts/full_seller_data', {
            sellerId: id
        });
    }

    public UpdateAlertOptions(id,fbNotif ,emailNotif ): Observable<any> {
        return this.post('competetors_alerts/alert_option', {
            sellerId: id,
            fbNotif : fbNotif,
            emailNotif : emailNotif
        });
    }

    public UpdateConfigurationChange(id,source,state,IsMine ): Observable<any> {
        return this.post('competetors_alerts/configChange', {
            sellerId: id,
            change : source,
            value : state,
            isMine: IsMine
        });
    }

    public UpdateFullData(id,FullData ): Observable<any> {
        return this.post('competetors_alerts/addAsinAndCompetetor', {
            sellerId: id,
            competitorsFullData:FullData
        });
    }
}