import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConstService } from '../../../services/const-service';
import { TextService } from '../../../services/text.service';
import { GoogleAnalyticsEventsService } from '../../../services/google-analytics.service';
import { StorageBrowser } from '../../../services/storage.browser';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { CookieService } from 'ngx-cookie-service';
import { AbTestsService } from 'angular-ab-tests';

@Component({
    selector: 'ninja-mfa',
    templateUrl: './mfa.component.html',
    styleUrls: ['./mfa.component.scss']
})

export class MfaComponent implements OnInit {
    @ViewChild('mfaCode', { static: false }) loginInput: ElementRef;
    @ViewChild('pass', { static: false }) passInput: ElementRef;
    public userMfaCode: number;
    public rememberMe = true;
    public mfaCodeInput: boolean;
    public user;
    public queryParams: any;
    public errors: any = {};

    public mfaForm = this.fb.group({
        mfaCode: ['', [Validators.required]],
    });

    constructor(private router: Router,
        public userService: UserService,
        public constService: ConstService,
        public storageBrowser: StorageBrowser,
        public textService: TextService,
        public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
        public activatedRoute: ActivatedRoute,
        public matDialog: MatDialog,
        public mixpanelService: MixpanelService,
        public tokenService: TokenService,
        public fb: FormBuilder,
        public cookieService: CookieService,
        private abTestsService: AbTestsService) {
        this.mixpanelService.init(null);
        var ABVer = this.abTestsService.getVersion();
        this.mixpanelService.track('q mfa page - view - ' + ABVer);
    }

    public ngOnInit(): void {
        if (this.tokenService.getId()) {
            let remember = this.tokenService.getToken().rememberMe;
            this.userService.tokenCheck().subscribe((data) => {
                if (data.isLogged && this.userService.currentUser.user.isVerified) {
                    if (!remember) {
                        if (!this.cookieService.check('KeepSession')) {
                            return;
                        }
                    }
                    this.mixpanelService.track('q mfa page - KeepSession');
                    this.SetIdentity(this.userService.currentUser.user);
                    this.router.navigate(['dashboard/orders-dashboard']);
                } else {
                    return;
                }
            });
        }
        if (!this.userService.loginUser) {
            this.router.navigate(['login']);
        }
    }

    public login(): void {
        if (!this.checkForm()) {
            return;
        }
        this.userService.loader = true;
        this.userService.mfa({
            'mfaCode': this.userMfaCode,
            'login': this.userService.loginUser.login,
            'pass': this.userService.loginUser.pass
        }).subscribe(
            (userData) => {
                this.userService.loader = false;
                this.userService.superUserActive = false;
                if (userData.id === 0) {
                    this.userService.currentUser = userData;
                    this.displayError(this.userService.currentUser.message);
                    this.userService.loader = false;
                    this.mixpanelService.track('q mfa page - Mfa - error');
                    return;
                }
                this.SetIdentity(userData);
                if (userData.id !== 0) {
                    if (this.activatedRoute.snapshot.queryParams.amazon_state !== undefined &&
                        this.activatedRoute.snapshot.queryParams.selling_partner_id !== undefined &&
                        this.activatedRoute.snapshot.queryParams.amazon_callback_uri !== undefined) {
                        var user;
                        if (userData.emails) {
                            const data = userData.emails.find(data => (data.submarket === userData.marketplace));
                            user = data.subid;
                        } else {
                            user = userData.userId;
                        }
                        console.log("userId L", user);
                        const redirect_uri = 'https://webapp.quantifyninja.com/spa'
                        const amazonUrl = 'https://sellercentral.this.rememberMeamazon.com/apps/authorize/confirm/amzn1.sellerapps.app.ca82764b-41ed-46b1-83c6-298a96554243?amazon_state=' + this.activatedRoute.snapshot.queryParams.amazon_state + '&state=' + user + '&redirect_uri=' + redirect_uri;
                        window.location.replace(amazonUrl);
                    } else {

                        if (userData.userId) {
                            this.userService.currentUser['userId'] = userData.userId;
                        }
                        if (userData.isSuspended) {
                            this.showAccountOnHoldDialog();
                            return;
                        }
                        if (!userData.isVerified) {
                            for (const prop in this.userService.currentUser) { if (this.userService.currentUser.hasOwnProperty(prop)) { delete this.userService.currentUser[prop]; } }
                            this.userService.currentUser.id = userData.id;
                            this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
                            this.tokenService.setToken(userData, this.rememberMe);
                            this.router.navigate(['choose-region']);
                        } else {
                            console.log(userData.sellerId);
                            this.userService.currentUser = userData;
                            this.storageBrowser.set('$UserToken$originSellerId', userData.sellerId);
                            if (this.userService.currentUser.language && this.constService.languages.indexOf(this.userService.currentUser.language) > 0) {
                                this.textService.setLangBadge(this.userService.currentUser.language);
                                this.textService.changeLang(this.textService.currentText, this.textService[`text${this.userService.currentUser.language}`]);
                            } else {
                                this.textService.setLangBadge('EN');
                                this.textService.changeLang(this.textService.currentText, this.textService.textEN);
                            }
                            // this.tokenService.savePublicId(data.id);
                            if (this.checkRegion(this.constService.arrayRegions, userData.marketplace)) {
                                this.userService.currentUser.marketplace = userData.marketplace;
                            }
                            this.tokenService.setToken(userData, this.rememberMe);
                            this.constService.setRegions(userData);
                            this.checkDestination();
                        }
                    }
                }
            },
            error => {
                this.userService.loader = false;
                this.matDialog.open(ConfirmationDialogComponent, {
                    data: {
                        'dialogFor': 'custom',
                        'title': ' ',
                        'message': 'server is currently unavailable'
                    },
                }).afterClosed().subscribe();
            });
    }

    public resendCode() {
        this.userService.loader = true;
        this.userService.login({
            'login': this.userService.loginUser.login,
            'pass': this.userService.loginUser.pass
        }).subscribe(
            (userData) => {
                this.userService.loader = false;
            },
            error => {
                this.userService.loader = false;
            });
    }

    public goToLoginPage() {
        this.router.navigate(['login']);
    }

    public checkRegion(data: any, region: any): boolean {
        for (let i = 0; data.length > i; i++) {
            if (data[i] === region) {
                return true;
            }
        }
    }

    public checkForm(): boolean {
        this.errors = {};
        if (!this.mfaForm.valid) {
            if (!this.mfaForm.controls.mfaCode.valid) {
                this.errors.mfaCodeInvalid = true;
                if (this.mfaForm.controls.mfaCode.errors && this.mfaForm.controls.mfaCode.errors['required']) {
                    this.displayError(this.textService.currentText.errorRequired);
                } else {
                    this.displayError(this.textService.currentText.errorIncorectMfaCode);
                }
                return false;
            }
        }
        return this.mfaForm.valid;
    }

    private displayError(message: string): void {
        this.userService.loader = false;
        if (message === 'Verification code entered incorrectly. Please try again') {
            this.errors.mfaCodeInvalid = true;
            const old_value = this.mfaForm.value.mfaCode;
            this.mfaForm.patchValue({
                mfaCode: 'Mfa is invalid'
            });
            this.mfaForm.controls['mfaCode'].disable();
            this.loginInput.nativeElement.blur();
            this.resetErrorState(old_value);
            return;
        }
        if (this.errors.mfaCodeInvalid) {
            const old_value = this.mfaForm.value.mfaCode;
            this.mfaForm.patchValue({
                mfaCode: message
            });
            this.mfaForm.controls['mfaCode'].disable();
            this.loginInput.nativeElement.blur();
            this.resetErrorState(old_value);
            return;
        }
    }

    public showAccountOnHoldDialog(): void {
        this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                'dialogFor': 'login-hold',
            },
        }).afterClosed().subscribe();
    }

    private resetErrorState(val: string): void {
        setTimeout(() => {
            if (this.errors.mfaCodeInvalid) {
                this.mfaForm.patchValue({
                    mfaCode: val,
                });
                this.errors.mfaCodeInvalid = false;
                this.mfaForm.controls['mfaCode'].enable();
            }
        }, 2000);
    }

    public checkDestination(): any {
        this.mixpanelService.track('q mfa page - checkDestination');
        if (!this.userService.currentUser.hasActivePlan && !this.userService.currentUser.isTrial && !this.userService.currentUser.isVa) {
            return this.router.navigate(['dashboard/upgrade']);
        }
        if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isOrderDashboard) {
            return this.router.navigate(['dashboard/orders-dashboard']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isDashboard) {
            return this.router.navigate(['dashboard/dashboard-schedules']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAutoTemplates) {
            return this.router.navigate(['dashboard/automated-messaging/fba']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAlerts) {
            return this.router.navigate(['dashboard/facebook-notifications']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isMoneyBack) {
            return this.router.navigate(['dashboard/money-back']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isHijacked) {
            return this.router.navigate(['dashboard/hijacked']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isOrders) {
            return this.router.navigate(['dashboard/orders'], { queryParams: { page: 'fba' } });
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isKeyWordOptimzer) {
            return this.router.navigate(['dashboard/keywords-optimizer']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isConversation) {
            return this.router.navigate(['dashboard/facebook-bot-manager/grant-access']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAsin) {
            return this.router.navigate(['dashboard/asin-tracker']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isAffiliate) {
            return this.router.navigate(['dashboard/affiliate']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isCoupons) {
            return this.router.navigate(['dashboard/coupons']);
        } else if (this.userService.currentUser.permissions && this.userService.currentUser.permissions.isUpgrade) {
            return this.router.navigate(['dashboard/upgrade']);
        } else {
            if (this.userService.currentUser.isVa) {
                return this.router.navigate(['dashboard/user/settings/product-settings']);
            } else {
                return this.router.navigate(['dashboard/user/settings/account-settings']);
            }
        }
    }

    public SetIdentity(userData: any) {
        var userId = '';
        if (userData.userId) {
            this.mixpanelService.identify(userData.userId);
            userId = userData.userId;
        } else if (userData.emails) {
            const user = userData.emails.find(data => (data.submarket === userData.marketplace));
            this.mixpanelService.identify(user.subid);
            userId = user.subid;
        }
        this.mixpanelService.people(userData.email, userData.trialStarted, userId);
        this.mixpanelService.track('q mfa page - SetIdentity');
    }
}
