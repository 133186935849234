import {Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ConstService} from '../../../../services/const-service';
import {Functions} from '../../../../services/functions';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {GoogleAnalyticsEventsService} from '../../../../services/google-analytics.service';
import {TextService} from '../../../../services/text.service';
import {FbService} from '../../../../services/fb.service';
import {ConfirmationDialogComponent} from "../../../dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'ninja-facebook-grant-access',
  templateUrl: './facebook-grant-access.component.html',
  styleUrls: ['./facebook-grant-access.component.scss']
})

export class FacebookGrantAccessComponent implements OnInit {
  public fbLink: string;
  public showPage = false;

  constructor(public matDialog: MatDialog,
              public constService: ConstService,
              public userService: UserService,
              public textService: TextService,
              public fbService: FbService,
              public activatedRoute: ActivatedRoute,
              public router: Router,
              public functions: Functions,
              public googleAnalyticsEventsService: GoogleAnalyticsEventsService) {
  }

  public ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.success) {
      this.openFBFailDialog();
    }
    this.userService.loaderObesrver.next(true);
    this.fbService.checkAccess(this.userService.currentUser.id).subscribe((data) => {
      this.userService.loaderObesrver.next(false);
      this.showPage = true;
      if (!data.isSuccess) {
        this.fbLink = data.response;
      } else {
        this.router.navigate(['dashboard/facebook-bot-manager/conversation-manager']);
      }
    });
  }

  public goToFb() {
    if (this.fbLink) {
      window.location.href = this.fbLink;
    }
  }

  public openFBFailDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': this.textService.currentText.fail,
        'message': this.textService.currentText.facebookConnectFailed
      },
    }).afterClosed().subscribe();
  }
}
