<title>{{textService.currentText.pageNotFound}}</title>
<div class="main-wrapper">
  <div class="logo">
    <img src="assets/images/logo.svg" alt="">
  </div>
  <div class="not-found">
    <img src="assets/images/404-ninja.svg" alt="">
    <p>{{textService.currentText.pageNotFoundCaps}}</p>
    <div class="back-home">
      <p [routerLink]="'dashboard/dashboard-schedules'"
      >{{textService.currentText.backTo}} <span>{{textService.currentText.homepage}}</span></p>
    </div>
  </div>
</div>
