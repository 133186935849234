<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-header">
    <div class="head-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-7">
            <h2>{{textService.currentText.coupons}}</h2>
            <div class="sub-head-container">
              <p>
                {{textService.currentText.couponSubTitle}}
              </p>
            </div>
          </div>
          <div class="col-md-5 d-flex align-items-center justify-content-center justify-content-md-end mt-4 mt-md-0">
            <button mat-button mat-raised-button class="secondary-btn"
              (click)="mixpanelService.track('q coupons - upload clicked');googleAnalyticsEventsService.emitEvent('Button', 'Upload New Coupon', 'Click', 1); uploadCoupon(null, false)">
              {{textService.currentText.uploadNewCouponCaps}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-container">
    <h3 class="font-size-18 mr-3">{{textService.currentText.coupons}}</h3>
    <div class="table-container coupons">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header" [class.full-width]="coupons?.length <= 5">
            <div class="td"></div>
            <div class="td" (click)="functions.sortColumns(coupons, 'couponName', true)">
              {{textService.currentText.tableHeaders.couponName}}</div>
            <div class="td" (click)="functions.sortColumns(coupons, 'couponsLeft')">
              {{textService.currentText.tableHeaders.couponsLeft}}</div>
            <div class="td" (click)="functions.sortColumns(coupons, 'dateExpired')">
              {{textService.currentText.tableHeaders.expiryDate}}</div>
            <div class="td">{{textService.currentText.tableHeaders.getCouponCode}}e</div>
            <div class="td">{{textService.currentText.tableHeaders.CouponCode}}</div>
          </div>
        </div>
        <div class="table-holder" *ngIf="coupons?.length > 0">
          <div class="table" *ngFor="let coupon of coupons; let i = index">
            <div class="tr">
              <div class="td">
                <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"></button>
                <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                  <button mat-menu-item (click)="mixpanelService.track('q coupons - add more clicked');uploadCoupon(coupon, true);">
                    <div class="list-inner-container">
                      <div class="menu-icon icon-edit"></div>
                      {{textService.currentText.add}}
                    </div>
                  </button>
                  <button mat-menu-item (click)="mixpanelService.track('q coupons - delete coupons clicked');deleteCoupon(coupon.couponid, i)">
                    <div class="list-inner-container">
                      <div class="menu-icon icon-delete"></div>
                      {{textService.currentText.delete}}
                    </div>
                  </button>
                </mat-menu>
              </div>
              <div class="td">
                {{coupon.couponName}}
              </div>
              <div class="td">
                {{coupon.couponsLeft}}
              </div>
              <div class="td">
                {{coupon.dateExpired || 'Expired'}}
              </div>
              <div class="td">
                <button mat-button [disabled]="coupon.couponsLeft === 0"
                  (click)="mixpanelService.track('q coupons - get one code clicked');googleAnalyticsEventsService.emitEvent('Button', 'Get coupon code', 'Click', 1); getCouponCode(coupon.couponid, i)"
                  class="default-btn cancel">
                  {{textService.currentText.getOneCode}}
                </button>
              </div>
              <div class="td">
                <input mat-input [id]="i" class="code-input" readonly>
              </div>
            </div>
          </div>
        </div>
        <div class="table-holder" *ngIf="coupons?.length == 0">
          <div class="table">
            <div class="tr">
              <div class="td not-found">
                <img src="../../../../../assets/icons/attention-grey.svg" alt="">
                {{textService.currentText.noCouponMsg}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ninja-notification [active]="userService.showNotification" [message]="notificationMessage" [title]="notificationTitle">
</ninja-notification>