<div class="dashboard" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container a-side">
    <div class="dashboard-header">
      <div class="head-container">
        <h2>{{textService.currentText.botMenuTitle}}</h2>
      </div>
      <div class="sub-head-container">
        <p>{{textService.currentText.botMenuSubTitle1}} </p>
        <p>{{textService.currentText.botMenuSubTitle2}}.</p>
      </div>
    </div>
    <div class="row-content">
      <div class="input-item custom-selects">
        <div class="label-name">
          <span>{{textService.currentText.page}}</span>
        </div>
        <div class="select-holder">
          <div (click)="functions.setDropDownPosition(allProductsSelect, 'allProducts')" #allProductsSelect
               [class.active]="allProducts.panelOpen"
               class="select-wrapper">
            <mat-form-field>
              <mat-select [placeholder]="textService.currentText.selectPage"
                          shouldPlaceholderFloat="false"
                          panelClass="default-dropdown allProducts"
                          #allProducts
                          [(ngModel)]="page"
                          (selectionChange)="getConversations(page)"
                          disableRipple
                          name="allProducts">
                <mat-option *ngFor="let select of pages" [value]="select">
                  {{ select.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="levels-block">
      <div class="level">
        <h4 #topLevel>{{textService.currentText.botTopLevel}}</h4>
        <div class="menu-constructor-block">
          <ng-container>
            <ninja-bot-menu-constructor (addMenuToThisLevel)="addMenuToThisLevel($event, menuData)"
                                        [isDelete]="menuData.length === 1"
                                        [headerEdit]="true"
                                        [isAction]="false"
                                        [firstBlock]="true"
                                        [firstMenu]="true"
                                        [canAddMenu]="menuData.length < 3"
                                        [canAddSubMenu]="menuData[menuIndex] && menuData[menuIndex].menu2level && menuData[menuIndex].menu2level.length > 0 ? true : false"
                                        [name]="'Menu'"></ninja-bot-menu-constructor>
          </ng-container>
          <ng-container *ngFor="let menu of menuData; let i = index">
            <ninja-bot-menu-constructor (addMenuToThisLevel)="addMenuToThisLevel($event, menuData)"
                                        (click)="menuIndex = i"
                                        (menuName)="menuName($event, i, menuData)"
                                        (deleteMenu)="deleteMenu($event, i, menuData)"
                                        (addSub2Level)="addSub2Level()"
                                        (setAction)="menuData[i].action = $event"
                                        (setWebUrl)="menuData[i].action = $event.action; menuData[i].web_url = $event.web_url"
                                        (setActionId)="menuData[i].conversationId = $event"
                                        [isAction]="menuData[i].action"
                                        [isWebUrl]="menuData[i]?.web_url"
                                        [isDelete]="menuData.length === 1"
                                        [canAddMenu]="menuData.length < 3"
                                        [headerEdit]="false"
                                        [lastBlock]="(menuData.length - 1) === i"
                                        [conversationList]="conversationList"
                                        [canAddSubMenu]="menuData[menuIndex] && menuData[menuIndex].menu2level && menuData[menuIndex].menu2level.length > 0 ? true : false"
                                        [name]="menu.name"></ninja-bot-menu-constructor>
          </ng-container>
        </div>
      </div>
      <div class="level" *ngIf="menuData[menuIndex].menu2level?.length > 0">
        <h4 #secondLevel
            [ngStyle]="{'margin-bottom': setMargin2lvl() + 'px'}"
        >{{textService.currentText.botSecondLevel}}</h4>
        <div class="constructor-header option">
          <ng-container>
            <ninja-bot-menu-constructor
                (addMenuToThisLevel)="addMenuToThisLevel($event, menuData[menuIndex].menu2level)"
                (deleteSection)="deleteSection($event, menuData[this.menuIndex], 'menu2level')"
                [isDelete]="menuData.length === 1"
                [firstBlock]="true"
                [headerEdit]="true"
                [canAddMenu]="menuData.length < 3"
                [canAddSubMenu]="menuData[menuIndex] && menuData[menuIndex].menu2level && menuData[menuIndex].menu2level.length > 0 ? true : false"
                [name]="'Option'"></ninja-bot-menu-constructor>
          </ng-container>
        </div>
        <ng-container *ngFor="let menu2lvl of menuData[menuIndex].menu2level; let i = index">
          <ninja-bot-menu-constructor
              (addMenuToThisLevel)="addMenuToThisLevel($event, menuData[menuIndex].menu2level)"
              (menuName)="menuName($event, i, menuData[menuIndex].menu2level)"
              (addSub2Level)="addSub3Level()"
              (click)="menuSubIndex = i"
              (deleteMenu)="deleteMenu($event, i, menuData[menuIndex].menu2level)"
              (setAction)="menuData[menuIndex].menu2level[i].action = $event"
              (setWebUrl)="menuData[menuIndex].menu2level[i].action = $event.action;
              menuData[menuIndex].menu2level[i].web_url = $event.web_url"
              (setActionId)="menuData[menuIndex].menu2level[i].conversationId = $event"
              [isAction]="menuData[menuIndex].menu2level[i].action"
              [isWebUrl]="menuData[menuIndex].menu2level[i]?.web_url"
              [headerEdit]="false"
              [isDelete]="menuData[menuIndex].menu2level.length === 1"
              [lastBlock]="(menuData[menuIndex].menu2level.length - 1) === i"
              [conversationList]="conversationList"
              [canAddMenu]="menuData[menuIndex].menu2level.length < 3"
              [canAddSubMenu]="menuData[menuIndex]?.menu2level[menuSubIndex]?.menu3level?.length > 0 ? true : false"
              [name]="menu2lvl.name"></ninja-bot-menu-constructor>
        </ng-container>
      </div>
      <div class="level" *ngIf="menuData[menuIndex]?.menu2level?.length > 0 &&
      menuData[menuIndex]?.menu2level[menuSubIndex]?.menu3level?.length > 0">
        <h4 [ngStyle]="{'margin-bottom': setMargin3lvl() + 'px'}">{{textService.currentText.botThirdLevel}}</h4>
        <ng-container>
          <ninja-bot-menu-constructor
              (addMenuToThisLevel)="addMenuToThisLevel($event, menuData[menuIndex].menu2level[menuSubIndex].menu3level)"
              [isDelete]="menuData.length === 1"
              (deleteSection)="deleteSection($event, menuData[this.menuIndex].menu2level[menuSubIndex], 'menu3level')"
              [headerEdit]="true"
              [firstBlock]="true"
              [canAddMenu]="menuData.length < 3"
              [canAddSubMenu]="menuData[menuIndex] && menuData[menuIndex].menu2level && menuData[menuIndex].menu2level.length > 0 ? true : false"
              [name]="'Option1.2'"></ninja-bot-menu-constructor>
        </ng-container>
        <ng-container
            *ngFor="let menu3lvl of menuData[menuIndex].menu2level[menuSubIndex].menu3level; let i = index">
          <ninja-bot-menu-constructor
              (addMenuToThisLevel)="addMenuToThisLevel($event, menuData[menuIndex].menu2level[menuSubIndex].menu3level)"
              (menuName)="menuName($event, i, menuData[menuIndex].menu2level[menuSubIndex].menu3level)"
              (deleteMenu)="deleteMenu($event, i, menuData[menuIndex].menu2level[menuSubIndex].menu3level)"
              (setAction)="menuData[menuIndex].menu2level[menuSubIndex].menu3level[i].action = $event"
              (setWebUrl)="menuData[menuIndex].menu2level[menuSubIndex].menu3level[i].action = $event.action;
menuData[menuIndex].menu2level[menuSubIndex].menu3level[i].web_url = $event.web_url"
              (setActionId)="menuData[menuIndex].menu2level[menuSubIndex].menu3level[i].conversationId = $event"
              [isAction]="menuData[menuIndex].menu2level[menuSubIndex].menu3level[i].action"
              [isWebUrl]="menuData[menuIndex].menu2level[menuSubIndex].menu3level[i]?.web_url"
              [isDelete]="menuData[menuIndex].menu2level[menuSubIndex].menu3level.length === 1"
              [canAddMenu]="menuData[menuIndex].menu2level[menuSubIndex].menu3level.length < 3"
              [lastBlock]="(menuData[menuIndex].menu2level[menuSubIndex].menu3level.length) === i"
              [lastLevel]="true"
              [headerEdit]="false"
              [conversationList]="conversationList"
              [name]="menu3lvl.name"></ninja-bot-menu-constructor>
        </ng-container>
      </div>
    </div>
    <div class="bottom-row">
      <button mat-button
              mat-raised-button
              (click)="deleteMenuPage()"
              class="primary-btn white">
        {{textService.currentText.deleteMenu}}
      </button>
      <button mat-button
              (click)="createMenu()"
              mat-raised-button
              class="primary-btn">
        {{textService.currentText.commitMenu}}
      </button>
      <div class="opt-tooltip" (click)="showDeleteCommitTooltip()">
        <span>?</span>
      </div>
    </div>
  </div>
  <div class="dashboard-container b-side">
    <div class="dashboard-header">
      <div class="head-container">
        <h2>{{textService.currentText.preview}}</h2>
      </div>
      <div class="phone-holder">
        <div class="phone-preview">
          <div class="header">
            <p>{{textService.currentText.favStore}}</p>
          </div>
          <div class="chat-holder">
            <!--<ng-container *ngFor="let previewItem of fbService.conversation">-->
              <!--<ninja-conversation-preview [data]="previewItem">-->

              <!--</ninja-conversation-preview>-->
            <!--</ng-container>-->
          </div>
          <div class="footer">
            <div class="menu" (click)="showMenuPreview = !showMenuPreview">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
            <ninja-bot-menu-preview *ngIf="showMenuPreview" [menu]="menuData">

            </ninja-bot-menu-preview>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ninja-notification [active]="userService.showNotification"
                    [message]="userService.notificationMessage"
                    [title]="userService.notificationTitle"
></ninja-notification>
