import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ConstService} from '../../../../../services/const-service';
import {UserService} from '../../../../../services/user.service';
import {Functions} from '../../../../../services/functions';
import {FormControl} from '@angular/forms';
import {startWith, map} from 'rxjs/operators';
import {TextService} from '../../../../../services/text.service';
import {ConfirmationDialogComponent} from "../../../../dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'ninja-bot-menu-constructor',
  templateUrl: './bot-menu-option-constructor.component.html',
  styleUrls: ['./bot-menu-option-constructor.component.scss']
})

export class BotMenuOptionConstructorComponent {
  @Input() name: string;
  @Input() isWebUrl: string;
  @Input() isDelete: boolean;
  @Input() canAddMenu: boolean;
  @Input() isUrl: any;
  @Input() firstMenu: boolean;
  @Input() lastBlock: boolean;
  @Input() firstBlock: boolean;
  @Input() isAction: any;
  @Input() conversationList: any;
  @Input() headerEdit = true;
  @Input() canAddSubMenu: boolean;
  @Input() lastLevel: boolean;
  @Output() addMenuToThisLevel: EventEmitter<any> = new EventEmitter();
  @Output() deleteSection: EventEmitter<any> = new EventEmitter();
  @Output() setActionId: EventEmitter<any> = new EventEmitter();
  @Output() menuName: EventEmitter<any> = new EventEmitter();
  @Output() deleteMenu: EventEmitter<any> = new EventEmitter();
  @Output() addSub2Level: EventEmitter<any> = new EventEmitter();
  @Output() setAction: EventEmitter<any> = new EventEmitter();
  @Output() setWebUrl: EventEmitter<any> = new EventEmitter();
  public isFilters: boolean;
  public showMenu = false;
  public showUrlMenu = false;
  public isClose = false;
  public showMainMenu = true;
  public showOption = false;
  public currentTemplates: any;
  public selectedAction: any;
  public filteredStates: any;
  public webUrl: string;
  public selectedConversation: any;
  public stateCtrl: FormControl;

  constructor(public matDialog: MatDialog,
              public constService: ConstService,
              public userService: UserService,
              public textService: TextService,
              public router: Router,
              public functions: Functions) {
    this.stateCtrl = new FormControl();
  }

  public ngOnInit () {
    this.webUrl = this.isWebUrl
  }

  public init() {
    this.filteredStates = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this.filterStates(state) : this.conversationList.slice())
      );
  }

  public filterStates(name: string) {
    return this.conversationList.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  @HostListener('window:click', ['$event'])
  public checkFullScreen(event: MouseEvent): void {
    for (let i = 0; this.constService.dropdownCls.length > i; i++) {
      if (event.target['classList'].contains(this.constService.dropdownCls[i]) || event.target['classList'].value === '') {
        return;
      }
    }
    if (this.search('menu-close-checker', event['path']) === -1 && this.isClose && this.showMenu) {
      this.showMenu = false;
      this.isClose = false;
      this.showUrlMenu = false;
    }
  }

  public closeMenuFunc() {
    setTimeout(() => {
      if (this.showMenu) {
        this.isClose = true;
        this.showUrlMenu = true;
      }
    });
  }

  public search(nameKey, myArray) {
    for (let i = 0; i < myArray.length; i++) {
      return myArray[i].className.indexOf(nameKey);
    }
  }

  public warningDialog (title, subTitle, subTitle2?, isUrlmenu?) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'tag-webUrl-confiramation',
        title: title,
        subTitle: subTitle,
        subTitle2: subTitle2,
      },
    }).afterClosed().subscribe(res => {
      if (res) {
        this.showOption = !this.showOption;
        this.webUrl = undefined;
        this.setActionId.emit(undefined);
        this.setWebUrl.emit({web_url: undefined, action: undefined});
        this.selectedAction = undefined;
        this.selectedConversation = undefined;
        if (isUrlmenu) {
          this.showUrlMenu = !this.showUrlMenu
          this.showOption = false;
        }
      }
    });
  }

  public closeMenu() {
    this.showMenu = false;
    this.showUrlMenu = false;
    this.showMainMenu = true;
    this.showOption = false;
  }
}
