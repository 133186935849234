import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';

@Injectable()

export class OrdersService extends UserService {

  public getFbaOrdersData(data: any): Observable<any> {
    return this.post('orders/get_orders', data);
  }

  public getFbmOrdersData(data: any): Observable<any> {
    return this.post('orders/get_fbm_orders', data);
  }

  public getFbaReviewOrdersData(data: any): Observable<any> {
    return this.post('orders/review_orders_fba', data);
  }

    public getFbmReviewOrdersData(data: any): Observable<any> {
    return this.post('orders/review_orders_fbm', data);
  }
  
  public getTemplateContent(publicId: string, orderId: any, templateId: any): Observable<any> {
    return this.post('orders/template_content', {
      publicId: publicId,
      orderId: orderId,
      templateId: templateId
    });
  }

  public sendOrdersMessage(data: any): Observable<any> {
    return this.post('orders/send_message', data);
  }

  public excludeOrder(publicId: string, value: any, orderId: any, isFBM: any): Observable<any> {
    return this.post('orders/exclude_order', {
      publicId: publicId,
      isOnBlackList: value,
      orderId: orderId,
      isFBM: isFBM
    });
  }

  public excludeBuyer(publicId: string, value: any, buyerEmail: any): Observable<any> {
    return this.post('orders/exclude_buyer', {
      publicId: publicId,
      buyerEmail: buyerEmail,
      isBuyerOnBlackList: value
    });
  }

  public createReport(data: any): Observable<any> {
    return this.post('orders/create_report', data);
  }

  public createFBMReport(data: any): Observable<any> {
    return this.post('orders/create_fbm_report', data);
  }

  public uploadCustomAudience(data: any): Observable<any> {
    return this.post('orders/upload_custom_audience', data);
  }

  public checkAccess(publicId: string): Observable<any> {
    return this.post('orders/check_access', {
      publicId: publicId,
    });
  }


  public checkToken(publicId: string, code: string): Observable<any> {
    return this.post('orders/check_token', {
      publicId: publicId,
      code: code
    });
  }

  public getCustomAudience(publicId: string, userToken: string, adAccountId: string): Observable<any> {
    return this.post('orders/custom_audience', {
      publicId: publicId,
      userToken: userToken,
      adAccountId: adAccountId
    });
  }

  public createCustomAudience(publicId: string,
                              userToken: string,
                              adAccountId: string,
                              audienceName: string,
                              audienceDescription: string ): Observable<any> {
    return this.post('orders/create_custom_audience', {
      publicId: publicId,
      userToken: userToken,
      adAccountId: adAccountId,
      audienceName: audienceName,
      audienceDescription: audienceDescription
    });
  }

  public reviewRequestClicked(publicId: string,
                              orderId: any,
                              sku: string,
                              isFBM: any): Observable<any> 
    {
      return this.post('orders/review_request_clicked', {
        publicId: publicId,
        orderId: orderId,
        sku: sku,
        isFbm: isFBM
      });
    }
}
