import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment.prod';

@Injectable()

export class ErrorService extends UserService {

    public sendErrorReport(component, url, error, params): Observable<any> {
        return this.post('user/report', {
            sellerId: this.storageBrowser.get('$UserToken$user').sellerId,
            emailAddress: this.storageBrowser.get('$UserToken$user').email,
            component: component,
            request: url,
            error: error,
            params: params ? params : {},
            version: `${environment.VERSION.toString()}.${this.storageBrowser.get('$UserToken$user').version.toString()}`
        });
    }
}