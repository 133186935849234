<div class="main-dialog-container">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container confiramtion-dialog sequence">
    <div class="dialog-content" name="form" [formGroup]="submitForm" novalidate>
      <div class="header">
        <h2 *ngIf="data.rule">{{textService.currentText.editRule}}</h2>
        <h2 *ngIf="!data.rule">{{textService.currentText.createNewRule}}</h2>
      </div>
      <div class="dialog-row status">
        <div class="status-text">
          {{textService.currentText.ruleStatus}}
        </div>
        <mat-radio-group  formControlName="status" (change)="ruleData.ruleStatus = !ruleData.ruleStatus">
          <mat-radio-button disableRipple [value]="true">Active</mat-radio-button>
          <mat-radio-button disableRipple [value]="false">Inactive</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.name">
          <div class="label-name">
            <span>{{textService.currentText.ruleName}}</span>
          </div>
        <input matInput
               #ruleName1
               formControlName="name"
               name="name"
               type="text">
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.conversation">
        <div class="label-name">
          {{textService.currentText.botTriggerConversation}}
        </div>
        <div class="select-holder custom-selects">
          <div (click)="functions.setDropDownPosition(conversationFrame, 'conversation')"
               #conversationFrame
               *ngIf="!errors?.conversation"
               [class.active]="conversationId.panelOpen"
               class="select-wrapper">
            <mat-form-field>
              <mat-select
                          shouldPlaceholderFloat="false"
                          panelClass="default-dropdown conversation"
                          formControlName="conversation"
                          disableRipple
                          #conversationId
                          name="conv">
                <mat-option *ngFor="let select of conversationList" [value]="select">
                  {{ select.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <input matInput
                 *ngIf="errors?.conversation"
                 type="text"
                 name="subject"
                 [value]="textService.currentText.errorRequired">
        </div>
      </div>
      <div class="dialog-row" [class.has-danger]="errors?.timeFrame">
        <div class="label-name">
          {{textService.currentText.timeFrame}}
        </div>
        <div class="select-holder custom-selects">
          <div (click)="functions.setDropDownPosition(when, 'when')"
               #when
               *ngIf="!errors?.timeFrame"
               [class.active]="whenSelect.panelOpen"
               class="select-wrapper">
            <mat-form-field>
              <mat-select
                shouldPlaceholderFloat="false"
                panelClass="default-dropdown when"
                formControlName="timeFrame"
                disableRipple
                #whenSelect>
                <mat-option *ngFor="let select of constService.selectsDays" [value]="select">
                  {{ select.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <input matInput
                 *ngIf="errors?.timeFrame"
                 type="text"
                 name="subject"
                 [value]="textService.currentText.errorRequired">
        </div>
      </div>

    </div>
  </div>
  <div class="dialog-footer space-between">
    <button mat-button
            (click)="dialogRef.close()"
            class="primary-btn white">{{textService.currentText.cancel}}
    </button>
    <button mat-button class="primary-btn" (click)="saveRule()">{{textService.currentText.create}}</button>
  </div>
</div>
