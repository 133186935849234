<div class="dashboard one-screen" [class.wide]="userService.smallSidebar"
  *ngIf="userNotificationsData">
  <div class="dashboard-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="head-container">
            <h2>{{textService.currentText.notificationsTitle1}}</h2>
          </div>
          <div class="sub-head-container">
            <p>
              {{textService.currentText.notificationsSubTitle}}
              <span class="edit-btn action-el" (click)="showWhyDialog()">
                Why? </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="noti-settings-container">
    <mat-accordion multi="true">
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'85px'"
          [expandedHeight]="'85px'">
          <mat-panel-title>
            <mat-checkbox class="round-checkbox"
              [checked]="userNotificationsData.isTgNotifyForSales"
              [disabled]="true"></mat-checkbox>
            <div>
              <h3 class="head-container">{{textService.currentText.notificationsTG}}<sup class="sub-rec"> Recommended</sup></h3>
              <p class="head-container-sub pt-2" (click)="mixpanelService.track('q notifications - tg popup link clicked');showTgInstruction()">{{textService.currentText.getTgInstructions}}</p>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div>
              {{textService.currentText.fbSalesNotificaion1}}:
              <span class="ml-2"
                [ngClass]="userNotificationsData.isTgNotifyForSales ?
                'text-success' : 'text-danger'">{{userNotificationsData.isTgNotifyForSales
                ? textService.currentText.active :
                textService.currentText.inactive}}</span>
            </div>
          </mat-panel-description>

        </mat-expansion-panel-header>

        <ul class="ml-md-4 pl-md-5 mb-0">
          <li class="mb-3 d-flex flex-wrap flex-md-nowrap">
            <mat-checkbox class="square-checkbox"
              [(ngModel)]="userNotificationsData.isTgNotifyForSales"
              [checked]="userNotificationsData.isTgNotifyForSales"
              (click)="mixpanelService.track('q notifications - tg sales checked ');changeTgStatus('isTgNotifyForSales',userNotificationsData.isTgNotifyForSales);
              $event.stopPropagation(); $event.preventDefault()"
              [class.uncheckedStatus]="!userNotificationsData.isTgNotifyForSales">
              {{textService.currentText.fbSalesNotificaion1}}</mat-checkbox>
            <div class="custom-selects d-flex align-items-center ml-4 pl-3
              ml-md-5 pl-md-5 mt-3 mt-md-0">
              <span class="mr-4">On</span>
              <div (click)="functions.setDropDownPosition(timeSelect1, 'tgtime')"
                #timeSelect1 [class.active]="tgtime.panelOpen"
                class="select-wrapper">
                <mat-form-field>
                  <mat-select
                    [placeholder]="textService.currentText.notificationsOnEverySale"
                    shouldPlaceholderFloat="false"
                    panelClass="default-dropdown tgtime" #tgtime
                    (selectionChange)="mixpanelService.track('q notifications - facebook frequency selected',{'type': filtersTg.tgtime});changeSalesNumberGapTg(filtersTg.tgtime)"
                    disableRipple
                    [(ngModel)]="filtersTg.tgtime" name="tgtime">
                    <mat-option *ngFor="let select of
                      constService.facebookNotificationsAlertFilter"
                      [value]="select.value">
                      {{ select.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="custom-selects no-overflow d-flex align-items-center
              ml-4 pl-3 ml-md-5 pl-md-5 mt-3 mt-md-0">
              <span class="mr-4">For</span>
              <div class="select-holder" (mouseenter)="showProductTooltip1= true"
                (mouseleave)="showProductTooltip1= false">
                <div (click)="functions.setDropDownPosition(allProductsSelect,
                  'allTgProducts')" #allProductsSelect
                  [class.active]="allTgProducts.panelOpen" class="select-wrapper">
                  <mat-form-field>
                    <mat-select [placeholder]="filtersTg.placeholder"
                      shouldPlaceholderFloat="false"
                      panelClass="default-dropdown allTgProducts"
                      #allTgProducts disableRipple
                      [(ngModel)]="filtersTg.allProducts"
                      (selectionChange)="changeValueTg()" name="allProducts">
                      <mat-option *ngFor="let select of
                        constService.productsDashboard" [value]="select.value">
                        {{ select.viewValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="spec-product-tooltip"
                    *ngIf="filtersTg.productspec.length> 0 && showProductTooltip1
                    == true"
                    (mouseenter)="showProductTooltip1 = true"
                    (mouseleave)="showProductTooltip1 = false">
                    <h4>{{textService.currentText.selectedProductsUpperCase}}:</h4>
                    <ol>
                      <li *ngFor="let product of filtersTg.productspec">
                        <span>{{product.sku}}</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </mat-expansion-panel>

      <!-- FB -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'85px'"
          [expandedHeight]="'85px'">
          <mat-panel-title>
            <mat-checkbox class="round-checkbox"
              [checked]="userNotificationsData.isFBNotifyForSales ||
              userNotificationsData.isNegativeFeedbackOnFB"
              [disabled]="true"></mat-checkbox>
            <div>
              <h3 class="head-container">{{textService.currentText.notificationsFb}}</h3>
              <p class="head-container-sub pt-2" (click)="mixpanelService.track('q notifications - facebook popup link clicked');showFbInstruction()">{{textService.currentText.getFbInstructions}}</p>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div>
              {{textService.currentText.fbSalesNotificaion1}}:
              <span class="ml-2"
                [ngClass]="userNotificationsData.isFBNotifyForSales ?
                'text-success' : 'text-danger'">{{userNotificationsData.isFBNotifyForSales
                ? textService.currentText.active :
                textService.currentText.inactive}}</span>
            </div>
            <div>{{textService.currentText.notificationsFbNegative1}}:
              <span class="ml-2"
                [ngClass]="userNotificationsData.isNegativeFeedbackOnFB ?
                'text-success' : 'text-danger'">{{userNotificationsData.isNegativeFeedbackOnFB
                ? textService.currentText.active :
                textService.currentText.inactive}}</span>
            </div>
          </mat-panel-description>

        </mat-expansion-panel-header>

        <ul class="ml-md-4 pl-md-5 mb-0">
          <li class="mb-3 d-flex flex-wrap flex-md-nowrap">
            <mat-checkbox class="square-checkbox"
              [(ngModel)]="userNotificationsData.isFBNotifyForSales"
              [checked]="userNotificationsData.isFBNotifyForSales"
              (click)="mixpanelService.track('q notifications - facebook sales checked');changeFbStatus('isFBNotifyForSales',userNotificationsData.isFBNotifyForSales);
              $event.stopPropagation(); $event.preventDefault()"
              [class.uncheckedStatus]="!userNotificationsData.isFBNotifyForSales">
              {{textService.currentText.fbSalesNotificaion1}}</mat-checkbox>
            <div class="custom-selects d-flex align-items-center ml-4 pl-3
              ml-md-5 pl-md-5 mt-3 mt-md-0">
              <span class="mr-4">On</span>
              <div (click)="functions.setDropDownPosition(timeSelect, 'time')"
                #timeSelect [class.active]="time.panelOpen"
                class="select-wrapper">
                <mat-form-field>
                  <mat-select
                    [placeholder]="textService.currentText.notificationsOnEverySale"
                    shouldPlaceholderFloat="false"
                    panelClass="default-dropdown time" #time
                    (selectionChange)="mixpanelService.track('q notifications - facebook frequency selected',{'type': filtersFb.time});changeSalesNumberGap(filtersFb.time)"
                    disableRipple
                    [(ngModel)]="filtersFb.time" name="time">
                    <mat-option *ngFor="let select of
                      constService.facebookNotificationsAlertFilter"
                      [value]="select.value">
                      {{ select.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="custom-selects no-overflow d-flex align-items-center
              ml-4 pl-3 ml-md-5 pl-md-5 mt-3 mt-md-0">
              <span class="mr-4">For</span>
              <div class="select-holder" (mouseenter)="showProductTooltip= true"
                (mouseleave)="showProductTooltip= false">
                <div (click)="functions.setDropDownPosition(allProductsSelect,
                  'allProducts')" #allProductsSelect
                  [class.active]="allProducts.panelOpen" class="select-wrapper">
                  <mat-form-field>
                    <mat-select [placeholder]="filtersFb.placeholder"
                      shouldPlaceholderFloat="false"
                      panelClass="default-dropdown allProducts"
                      #allProducts disableRipple
                      [(ngModel)]="filtersFb.allProducts"
                      (selectionChange)="changeValueFb()" name="allProducts">
                      <mat-option *ngFor="let select of
                        constService.productsDashboard" [value]="select.value">
                        {{ select.viewValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="spec-product-tooltip"
                    *ngIf="filtersFb.productspec.length> 0 && showProductTooltip
                    == true"
                    (mouseenter)="showProductTooltip = true"
                    (mouseleave)="showProductTooltip = false">
                    <h4>{{textService.currentText.selectedProductsUpperCase}}:</h4>
                    <ol>
                      <li *ngFor="let product of filtersFb.productspec">
                        <span>{{product.sku}}</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <mat-checkbox class="square-checkbox"
              [checked]="userNotificationsData.isNegativeFeedbackOnFB"
              [(ngModel)]="userNotificationsData.isNegativeFeedbackOnFB"
              (click)="changeFbStatus('isNegativeFeedbackOnFB',userNotificationsData.isNegativeFeedbackOnFB);
              $event.stopPropagation();
              $event.preventDefault()"
              [class.uncheckedStatus]="!userNotificationsData.isNegativeFeedbackOnFB">
              {{textService.currentText.notificationsFbNegative1}}</mat-checkbox>
          </li>
        </ul>

        <!-- <div class="table-container facebook-notifications">
          <div class="table-wrapper">
            <div class="table-holder">
              <div class="table"> -->
        <!-- <div class="tr">
                  <div class="td">
                    <mat-slide-toggle [(ngModel)]="userNotificationsData.isFBNotifyForSales"
                      [checked]="userNotificationsData.isFBNotifyForSales" (click)="changeFbStatus('isFBNotifyForSales',userNotificationsData.isFBNotifyForSales);
                                $event.stopPropagation(); $event.preventDefault()"
                      [class.uncheckedStatus]="!userNotificationsData.isFBNotifyForSales" disableRipple>
                      {{valueToggle(userNotificationsData.isFBNotifyForSales)}}
                    </mat-slide-toggle>
                  </div>
                  <div class="td">
                    {{textService.currentText.fbSalesNotificaion}}
                  </div>
                  <div class="td custom-selects">
                    <div class="select-holder">
                      <div (click)="functions.setDropDownPosition(timeSelect, 'time')" #timeSelect
                        [class.active]="time.panelOpen" class="select-wrapper">
                        <mat-form-field>
                          <mat-select [placeholder]="textService.currentText.notificationsOnEverySale"
                            shouldPlaceholderFloat="false" panelClass="default-dropdown time" #time
                            (selectionChange)="changeSalesNumberGap(filters.time)" disableRipple
                            [(ngModel)]="filters.time" name="time">
                            <mat-option *ngFor="let select of constService.facebookNotificationsAlertFilter"
                              [value]="select.value">
                              {{ select.viewValue }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="td custom-selects no-overflow">
                    <div class="select-holder" (mouseenter)="showProductTooltip = true"
                      (mouseleave)="showProductTooltip = false">
                      <div (click)="functions.setDropDownPosition(allProductsSelect, 'allProducts')" #allProductsSelect
                        [class.active]="allProducts.panelOpen" class="select-wrapper">
                        <mat-form-field>
                          <mat-select [placeholder]="filters.placeholder" shouldPlaceholderFloat="false"
                            panelClass="default-dropdown allProducts" #allProducts disableRipple
                            [(ngModel)]="filters.allProducts" (selectionChange)="changeValue()" name="allProducts">
                            <mat-option *ngFor="let select of constService.productsDashboard" [value]="select.value">
                              {{ select.viewValue }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div class="spec-product-tooltip"
                          *ngIf="filters.productspec.length > 0 && showProductTooltip == true"
                          (mouseenter)="showProductTooltip = true" (mouseleave)="showProductTooltip = false">
                          <h4>{{textService.currentText.selectedProductsUpperCase}}:</h4>
                          <ol>
                            <li *ngFor="let product of filters.productspec">
                              <span>{{product.sku}}</span>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tr">
                  <div class="td">
                    <mat-slide-toggle [checked]="userNotificationsData.isNegativeFeedbackOnFB"
                      [(ngModel)]="userNotificationsData.isNegativeFeedbackOnFB" (click)="changeFbStatus('isNegativeFeedbackOnFB',userNotificationsData.isNegativeFeedbackOnFB);
                                $event.stopPropagation();
                                $event.preventDefault()"
                      [class.uncheckedStatus]="!userNotificationsData.isNegativeFeedbackOnFB" disableRipple>
                      {{valueToggle(userNotificationsData.isNegativeFeedbackOnFB)}}
                    </mat-slide-toggle>
                  </div>
                  <div class="td">
                    {{textService.currentText.notificationsFbNegative}}
                  </div>
                  <div class="td"></div>
                  <div class="td"></div>
                </div> -->
        <!--<div class="tr">-->
        <!--<div class="td">-->
        <!--<mat-slide-toggle-->
        <!--[checked]="userNotificationsData.isFBNotifyForReviews"-->
        <!--[(ngModel)]="userNotificationsData.isFBNotifyForReviews"-->
        <!--(click)="changeFbStatus('isFBNotifyForReviews',userNotificationsData.isFBNotifyForReviews); $event.stopPropagation(); $event.preventDefault()"-->
        <!--[class.uncheckedStatus]="!userNotificationsData.isFBNotifyForReviews"-->
        <!--disableRipple>-->
        <!--{{valueToggle(userNotificationsData.isFBNotifyForReviews)}}-->
        <!--</mat-slide-toggle>-->
        <!--</div>-->
        <!--<div class="td">-->
        <!--{{textService.currentText.notificationsFbReview}}-->
        <!--</div>-->
        <!--<div class="td"></div>-->
        <!--<div class="td"></div>-->
        <!--<div class="tr-cover" *ngIf="!isAvaliable">-->
        <!--<p>-->
        <!--* {{textService.currentText.notificationsOnlyMaster}}-->
        <!--</p>-->
        <!--</div>-->
        <!--</div>-->
        <!-- </div>
            </div>
          </div>
        </div> -->
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState= true"
        (closed)="panelOpenState= false">
        <mat-expansion-panel-header [collapsedHeight]="'85px'"
          [expandedHeight]="'85px'">
          <mat-panel-title>
            <mat-checkbox class="round-checkbox"
              [checked]="userNotificationsData.isAlertOnNegativeFeedback ||
              userNotificationsData.isAlertOnHijacked ||
              userNotificationsData.isReportEmailDaily"
              [disabled]="true"></mat-checkbox>
            <h3 class="head-container">{{textService.currentText.notificationsEmail1}}</h3>
          </mat-panel-title>
          <mat-panel-description>
            <table class="table-responsive">
              <tr>
                <td>{{textService.currentText.fbSalesNotificaion1}}:</td>
                <td><span class="ml-2"
                    [ngClass]="userNotificationsData.isAlertOnNegativeFeedback ?
                    'text-success' : 'text-danger'">{{userNotificationsData.isAlertOnNegativeFeedback
                    ? textService.currentText.active :
                    textService.currentText.inactive}}</span>
                </td>
              </tr>
              <tr>
                <td>{{textService.currentText.emailHijackNotifications}}:</td>
                <td><span class="ml-2"
                    [ngClass]="userNotificationsData.isAlertOnHijacked ?
                    'text-success' : 'text-danger'">{{userNotificationsData.isAlertOnHijacked
                    ? textService.currentText.active :
                    textService.currentText.inactive}}</span>
                </td>
              </tr>
              <tr>
                <td>{{textService.currentText.dailyReports}}:</td>
                <td><span class="ml-2"
                    [ngClass]="userNotificationsData.isReportEmailDaily ?
                    'text-success' : 'text-danger'">{{userNotificationsData.isReportEmailDaily
                    ? textService.currentText.active :
                    textService.currentText.inactive}}</span>
                </td>
              </tr>
            </table>
            <!-- <div>
              <p>{{textService.currentText.fbSalesNotificaion1}}:</p>
              <span class="ml-2"
                [ngClass]="userNotificationsData.isAlertOnNegativeFeedback ? 'text-success' : 'text-danger'">{{userNotificationsData.isAlertOnNegativeFeedback ? textService.currentText.active : textService.currentText.inactive}}</span>
            </div>
            <div>
              <p>{{textService.currentText.emailHijackNotifications}}:</p>
              <span class="ml-2"
                [ngClass]="userNotificationsData.isAlertOnHijacked ? 'text-success' : 'text-danger'">{{userNotificationsData.isAlertOnHijacked ? textService.currentText.active : textService.currentText.inactive}}</span>
            </div>
            <div>
              <p>{{textService.currentText.dailyReports}}:</p>
              <span class="ml-2"
                [ngClass]="userNotificationsData.isReportEmailDaily ? 'text-success' : 'text-danger'">{{userNotificationsData.isReportEmailDaily ? textService.currentText.active : textService.currentText.inactive}}</span>
            </div> -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="ml-md-4 pl-md-5 mb-0">
          <li class="mb-3">
            <mat-checkbox class="square-checkbox"
              [checked]="userNotificationsData.isAlertOnNegativeFeedback"
              [(ngModel)]="userNotificationsData.isAlertOnNegativeFeedback"
              (click)="mixpanelService.track('q notifications - email negative feedback checked');userNotificationsData.isAlertOnNegativeFeedback=
              !userNotificationsData.isAlertOnNegativeFeedback;
              changeStatus('isAlertOnNegativeFeedback',userNotificationsData.isAlertOnNegativeFeedback);
              $event.stopPropagation(); $event.preventDefault()"
              [class.uncheckedStatus]="!userNotificationsData.isAlertOnNegativeFeedback">
              {{textService.currentText.notificationsText1}}</mat-checkbox>
          </li>
          <li class="mb-3">
            <mat-checkbox class="square-checkbox"
              [checked]="userNotificationsData.isAlertOnHijacked"
              [(ngModel)]="userNotificationsData.isAlertOnHijacked"
              (click)="mixpanelService.track('q notifications - email hijacked checked');userNotificationsData.isAlertOnHijacked=
              !userNotificationsData.isAlertOnHijacked;
              changeStatus('isAlertOnHijacked',
              userNotificationsData.isAlertOnHijacked);
              $event.stopPropagation(); $event.preventDefault()"
              [class.uncheckedStatus]="!userNotificationsData.isAlertOnHijacked">
              {{textService.currentText.notificationsText3}}</mat-checkbox>
          </li>
          <li>
            <mat-checkbox class="square-checkbox"
              [checked]="userNotificationsData.isReportEmailDaily"
              [(ngModel)]="userNotificationsData.isReportEmailDaily"
              (click)="mixpanelService.track('q notifications - email emails summary checked');userNotificationsData.isReportEmailDaily=
              !userNotificationsData.isReportEmailDaily;
              changeStatus('isReportEmailDaily',
              userNotificationsData.isReportEmailDaily);
              $event.stopPropagation(); $event.preventDefault()"
              [class.uncheckedStatus]="!userNotificationsData.isReportEmailDaily">
              {{textService.currentText.notificationsText2}}</mat-checkbox>
          </li>
        </ul>

        <!-- <div class="table-container facebook-notifications email">
          <div class="table-wrapper">
            <div class="table-holder">
              <div class="table"> -->
        <!-- <div class="tr">
                  <div class="td w-100">
                    <mat-slide-toggle [checked]="userNotificationsData.isAlertOnNegativeFeedback"
                      [(ngModel)]="userNotificationsData.isAlertOnNegativeFeedback"
                      (click)="userNotificationsData.isAlertOnNegativeFeedback = !userNotificationsData.isAlertOnNegativeFeedback;
                                  changeStatus('isAlertOnNegativeFeedback',userNotificationsData.isAlertOnNegativeFeedback); $event.stopPropagation(); $event.preventDefault()"
                      [class.uncheckedStatus]="!userNotificationsData.isAlertOnNegativeFeedback" disableRipple>
                      {{valueToggle(userNotificationsData.isAlertOnNegativeFeedback)}}
                    </mat-slide-toggle>
                  </div>
                  <div class="td">
                    {{textService.currentText.notificationsText1}}
                  </div>
                </div>
                <div class="tr">
                  <div class="td w-100">
                    <mat-slide-toggle [checked]="userNotificationsData.isAlertOnHijacked"
                      [(ngModel)]="userNotificationsData.isAlertOnHijacked"
                      (click)="userNotificationsData.isAlertOnHijacked = !userNotificationsData.isAlertOnHijacked;
                      changeStatus('isAlertOnHijacked', userNotificationsData.isAlertOnHijacked); $event.stopPropagation(); $event.preventDefault() "
                      [class.uncheckedStatus]="!userNotificationsData.isAlertOnHijacked" disableRipple>
                      {{valueToggle(userNotificationsData.isAlertOnHijacked)}}
                    </mat-slide-toggle>
                  </div>
                  <div class="td">
                    {{textService.currentText.notificationsText3}}
                  </div>
                </div>
                <div class="tr">
                  <div class="td w-100">
                    <mat-slide-toggle [checked]="userNotificationsData.isReportEmailDaily"
                      [(ngModel)]="userNotificationsData.isReportEmailDaily"
                      (click)="userNotificationsData.isReportEmailDaily = !userNotificationsData.isReportEmailDaily;
                      changeStatus('isReportEmailDaily', userNotificationsData.isReportEmailDaily); $event.stopPropagation(); $event.preventDefault()"
                      [class.uncheckedStatus]="!userNotificationsData.isReportEmailDaily" disableRipple>
                      {{valueToggle(userNotificationsData.isReportEmailDaily)}}
                    </mat-slide-toggle>
                  </div>
                  <div class="td">
                    {{textService.currentText.notificationsText2}}
                  </div>
                </div> -->
        <!--<div class="tr">-->
        <!--<div class="td">-->
        <!--<mat-slide-toggle-->
        <!--[checked]="userNotificationsData.isEmailNotifyForReviews"-->
        <!--[(ngModel)]="userNotificationsData.isEmailNotifyForReviews"-->
        <!--(click)="userNotificationsData.isEmailNotifyForReviews = !userNotificationsData.isEmailNotifyForReviews;-->
        <!--changeStatus('isEmailNotifyForReviews', userNotificationsData.isEmailNotifyForReviews); $event.stopPropagation(); $event.preventDefault()"-->
        <!--[class.uncheckedStatus]="!userNotificationsData.isEmailNotifyForReviews"-->
        <!--disableRipple>-->
        <!--{{valueToggle(userNotificationsData.isEmailNotifyForReviews)}}-->
        <!--</mat-slide-toggle>-->
        <!--</div>-->
        <!--<div class="td">-->
        <!--{{textService.currentText.notificationsReview}}-->
        <!--</div>-->
        <!--<div class="tr-cover" *ngIf="!isAvaliable">-->
        <!--<p>-->
        <!--* {{textService.currentText.notificationsOnlyMaster}}-->
        <!--</p>-->
        <!--</div>-->
        <!--</div>-->
        <!-- </div>
            </div>
          </div>
        </div> -->
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- <div class="dashboard-container">

    <div class="dashboard-header second">
      <div class="head-container">
        <h2>{{textService.currentText.notificationsEmail}}</h2>
      </div>
    </div>
   
  </div> -->
</div>