import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {TextService} from '../../../../services/text.service';
import {ManagedAccountDialogComponent} from '../../../dialogs/managed-account-dialog/managed-account-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmationDialogComponent} from "../../../dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'ninja-link-accounts',
  templateUrl: './link-accounts.component.html',
  styleUrls: ['./link-accounts.component.scss']
})

export class LinkAccountsComponent implements OnInit {

  public vaList: any;

  constructor(public userService: UserService,
              public textService: TextService,
              public matDialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.userService.loaderObesrver.next(true);
    this.userService.getManagedList().subscribe(vaList => {
      this.vaList = vaList;
      this.userService.loaderObesrver.next(false);
    })
  }

  public showAddAccountDialog(): void {
    this.matDialog.open(ManagedAccountDialogComponent, {
      data: {
        'list': this.vaList,
      }
    }).afterClosed().subscribe(newAcc => {
      if (newAcc) {
        if (!this.userService.isSuperUser) {
          this.userService.detectAddSubAccount.next(newAcc);
        }
        this.vaList.push(newAcc);
        this.userService.notificationMessage = this.textService.currentText.linkedAccMsg;
        this.userService.notificationTitle = this.textService.currentText.success;
        this.userService.showNotification = true;
      }
    });
  }

  public deleteLinkConfirmation (va) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'link-delete-confirm',
        'email': va.email
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.updateManageList(va);
        this.userService.detectDeleteSubAccount.next(va);
        this.userService.notificationMessage = this.textService.currentText.unLinkedAccMsg;
        this.userService.notificationTitle = this.textService.currentText.success;
        this.userService.showNotification = true;
      }
    });
  }

  public updateManageList(va) {
    let list = [];
    this.vaList.splice(this.vaList.indexOf(va), 1);
    for (let i = 0; this.vaList.length > i; i++) {
      list.push(this.vaList[i].userId)
    }
    setTimeout(() => {
      this.userService.updateManagedList(list, this.userService.isSuperUser || false).subscribe()
    });
  }
}
