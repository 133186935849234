import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, Input } from '@angular/core';
import { TokenService } from '../../../services/token.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { ContactUsDialogComponent } from '../../dialogs/contact-us-dialog/contact-us-dialog.component';
import { StorageBrowser } from '../../../services/storage.browser';
import { environment } from '../../../../environments/environment.prod';
import { GoogleAnalyticsEventsService } from '../../../services/google-analytics.service';
import { TextService } from '../../../services/text.service';
import { SystemMessagesService } from '../../../services/system-messages.service';
import { ConstService } from '../../../services/const-service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'ninja-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})

export class DashboardHeaderComponent implements OnInit, AfterViewInit {
  public opened: boolean;
  public versionClient: any = environment.VERSION;
  public versionServer: string;
  public currentLang: any;
  public unreadReminder = true;
  public loggedIn = false;
  public showSystemMessages: boolean;
  @Input() links: any;
  public viewSystemMessages: boolean;
  // public messages: any;

  constructor(public tokenService: TokenService,
    public userService: UserService,
    public textService: TextService,
    public storageBrowser: StorageBrowser,
    public constService: ConstService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public activatedRoute: ActivatedRoute,
    public systemMessagesService: SystemMessagesService,
    public matDialog: MatDialog,
    public mixpanelService: MixpanelService,
    public ref: ChangeDetectorRef,
    private authService: SocialAuthService,
    public router: Router) {
    this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      this.loggedIn = (user != null);
      console.log("Login 2", this.loggedIn)
    });
    this.userService.getGrantAccessUrls(this.userService.currentUser.id).subscribe((links) => {
      this.links = links;
    });
    this.userService.subAccAdded().subscribe(data => {
      this.userService.currentUser.mangedAccounts.push(data)
      this.storageBrowser.remove('$UserToken$user');
      this.storageBrowser.set('$UserToken$user', this.userService.currentUser);
    });
    this.userService.subAccDeleted().subscribe(data => {
      for (let i = 0; this.userService.currentUser.mangedAccounts.length > i; i++) {
        if (this.userService.currentUser.mangedAccounts[i].email == data.email) {
          this.userService.currentUser.mangedAccounts.splice(
            this.userService.currentUser.mangedAccounts.indexOf(this.userService.currentUser.mangedAccounts[i]), 1)
          this.storageBrowser.remove('$UserToken$user');
          this.storageBrowser.set('$UserToken$user', this.userService.currentUser);
        }
      }
    });
    this.versionServer = this.userService.currentUser.version;
    this.currentLang = {
      text: this.textService.currentText.ENG,
      img: 'assets/images/uk-translate.svg'
    };
  }

  public ngAfterViewInit(): void {
    this.checkAccount();
  }

  public navigateToAmazon(): void {
    console.log("work")
    let link: string;
    // if (this.constService.usRegion.indexOf(this.userService.currentCountry) !== -1) {
    //   link = this.links.usLink
    // } else
    //   if (this.constService.feRegion.indexOf(this.userService.currentCountry) !== -1) {
    //     link = this.links.feLink
    //   } else
    // if (this.constService.jpRegion.indexOf(this.userService.currentCountry) !== -1) {
    //   link = this.links.jpLink
    // } else
    // if (this.constService.auRegion.indexOf(this.userService.currentCountry) !== -1) {
    //   link = this.links.auLink
    // } else
    // if (this.constService.euRegion.indexOf(this.userService.currentCountry) !== -1) {
    //   link = this.links.euLink
    // }
    if (this.userService.currentCountry == 'BR') {
      link = this.links.AMAZON_BRAZIL;
    } else if (this.userService.currentCountry == 'CA') {
      link = this.links.AMAZON_CANADA;
    } else if (this.userService.currentCountry == 'MX') {
      link = this.links.AMAZON_MEXICO;
    } else if (this.userService.currentCountry == 'US') {
      link = this.links.AMAZON_COM;
    } else if (this.userService.currentCountry == 'UAE') {
      link = this.links.AMAZON_UAE;
    } else if (this.userService.currentCountry == 'DE') {
      link = this.links.AMAZON_GERMANY;
    } else if (this.userService.currentCountry == 'ES') {
      link = this.links.AMAZON_SPAIN;
    } else if (this.userService.currentCountry == 'FR') {
      link = this.links.AMAZON_FRANCE;
    } else if (this.userService.currentCountry == 'UK') {
      link = this.links.AMAZON_UK;
    } else if (this.userService.currentCountry == 'IN') {
      link = this.links.AMAZON_INDIA;
    } else if (this.userService.currentCountry == 'IT') {
      link = this.links.AMAZON_ITALY;
    } else if (this.userService.currentCountry == 'NL') {
      link = this.links.AMAZON_NETHERLANDS;
    } else if (this.userService.currentCountry == 'SA') {
      link = this.links.AMAZON_SAUDI_ARABIA;
    } else if (this.userService.currentCountry == 'TR') {
      link = this.links.AMAZON_TURKEY;
    } else if (this.userService.currentCountry == 'SG') {
      link = this.links.AMAZON_SINGAPOR;
    } else if (this.userService.currentCountry == 'JP') {
      link = this.links.AMAZON_JAPAN;
    } else if (this.userService.currentCountry == 'AU') {
      link = this.links.AMAZON_AUSTRALIA;
    }
    window.location.href = link;
  }

  public navigateToAdvertising(): void {
    this.userService.advertitingGrantAccess().subscribe((res) => {
      if (res) {
        window.location.href = res.redirect;
        //window.open(res.redirect);
      }
    })
    return;
  }

  public hideNotification() {
    this.userService.hideNotification().subscribe((res) => {
      if (res.isSuccess) {
        this.userService.currentUser.isShowAuthNotification = false;
      }
    })
  }

  public logout(): void {
    this.authService.signOut(true).then((res) => {
      console.log(res)
    }).catch((err) => {
      console.log(err)
    });
    this.tokenService.clear();
    this.storageBrowser.remove('$UserToken$originSellerId');
    this.storageBrowser.remove('$LoginTime');
    this.storageBrowser.remove('Legend');
    window.location.reload(true);
    // this.router.navigate(['login']);
  }

  public toggleViewSystemMessages(): void {
    this.viewSystemMessages = !this.viewSystemMessages;
  }

  public toggleShowSystemMessage(): void {
    this.showSystemMessages = !this.showSystemMessages;
    if (!this.showSystemMessages && this.systemMessagesService.unreadMessages.length > 0) {
      this.clearSystemMessages();
    }
  }

  public clearSystemMessages(): void {
    this.systemMessagesService.clearSystemMessages(this.userService.currentUser.id).subscribe(() => {
      this.systemMessagesService.getSystemMessages(this.userService.currentUser.id).subscribe((messages) => {
        this.systemMessagesService.setMessages(messages);
      });
    });
  }

  public checkAccount(): void {
    let accountsArray;
    let result = 0;
    accountsArray = document.getElementsByClassName('switchAccount');
    for (let i = 0; i < accountsArray.length; i++) {
      if (accountsArray[i].classList.contains('active')) {
        result += 1;
      }
    }
    if (result === 0) {
      accountsArray[0].classList.add('active');
    }
    if (result > 0) {
      accountsArray[0].classList.remove('active');
    }
  }


  public showContactUs(): void {
    this.matDialog.open(ContactUsDialogComponent, {
      data: {},
    }).afterClosed().subscribe((res) => {
      if (res === true) {
        this.userService.notificationMessage = this.textService.currentText.headerMessageSent;
        this.userService.notificationTitle = this.textService.currentText.success;
        this.userService.showNotification = true;
      }
    });
  }

  @HostListener('window:click', ['$event'])
  public checkFullScreen(event: MouseEvent): void {
    if (!event.target['classList'].contains('menu')) {
      this.opened = false;
    }
    if (!event.target['classList'].contains('uread-reminder') && this.unreadReminder === true) {
      this.unreadReminder = false;
    }
    if (!event.target['classList'].contains('messages-holder') && !event.target['classList'].contains('system-messages-icon')) {
      if (this.showSystemMessages && this.systemMessagesService.unreadMessages && this.systemMessagesService.unreadMessages.length > 0) {
        this.clearSystemMessages();
      }
      this.showSystemMessages = false;
    }
  }

  public saveLanguage(lang): void {
    this.userService.saveLanguage(this.userService.currentUser.id, lang).subscribe();
  }

  public loginAsUser(data): void {
    this.userService.loaderObesrver.next(true);
    this.userService.superUser(data.hasOwnProperty('sellerId') ? data.sellerId : data).subscribe((data) => {
      if (!data.isVerified) {
        this.opened = false;
        this.userService.loaderObesrver.next(false);
        this.userService.currentUser.sellerId = data.sellerId;
        this.checkAccount();
        this.router.navigate(['dashboard/add-region'],
          { queryParams: { id: data.marketplace } });
        // this.matDialog.open(ConfirmationDialogComponent, {
        //   data: {
        //     'dialogFor': 'custom',
        //     'title': this.textService.currentText.grantAccessTitle5,
        //     'message': this.textService.currentText.expiredToken
        //   }
        // });
        return
      } else {
        this.userService.verifyAcc = true;
        if (!this.storageBrowser.get('$UserToken$originSellerId')) {
          this.storageBrowser.set('$UserToken$originSellerId', this.userService.currentUser.sellerId);
        }
        this.userService.currentUser.id = data.publicId;
        this.userService.currentUser.marketplace = data.marketplace;
        this.userService.currentUser.sellerId = data.sellerId;
        this.userService.currentUser.emails = data.emails;
        this.userService.currentUser.emailFrom = data.emailFrom;
        this.userService.currentUser.permissions = data.permissions;
        this.userService.currentUser.isTrial = data.isTrial;
        this.userService.currentUser.isVa = data.isVa;
        this.userService.currentUser.isMainAccount = data.isMainAccount;
        this.userService.currentUser.hasActivePlan = data.hasActivePlan;
        this.userService.updateSession(this.userService.currentUser.id).subscribe();
        this.storageBrowser.set('$UserToken$id', data.publicId);
        this.storageBrowser.set('$UserToken$tokenId', data.publicId);
        // this.storageBrowser.set('$UserToken$originSellerId', data.sellerId);
        this.storageBrowser.set('$UserToken$user', this.userService.currentUser);
        if (this.checkRegion(this.constService.arrayRegions, data.marketplace)) {
          this.userService.currentUser.marketplace = data.marketplace;
        }
        this.constService.setRegions(this.userService.currentUser);
        this.ref.detectChanges();
        this.opened = false;
        this.checkAccount();
        this.userService.loaderObesrver.next(false);
        this.userService.currentCountry = this.userService.currentUser.marketplace || 'US';
        this.router.navigateByUrl('dashboard/facebook-notifications', { skipLocationChange: true }).then(() =>
          this.router.navigate(['dashboard/dashboard-schedules']));
      }
    });
  }



  public checkRegion(data: any, region: any): boolean {
    for (let i = 0; data.length > i; i++) {
      if (data[i] === region) {
        return true;
      }
    }
  }
}
