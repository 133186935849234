import {Component} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {TextService} from '../../../../services/text.service';
import {Functions} from '../../../../services/functions';
import {ConstService} from '../../../../services/const-service';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'ninja-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})

export class BillingComponent {
  public invoiceList = [];
  public lang:any ;
  constructor(public userService: UserService,
              public textService: TextService,
              public functions: Functions,
              public matDialog: MatDialog,
              public constService: ConstService) {

  }

  public ngOnInit () {
    this.userService.loaderObesrver.next(true);
    this.userService.getInvoicingTransactions().subscribe(data => {
      this.invoiceList = data;
      this.userService.getLanguageInvoicing().subscribe(lang => {
        this.lang = lang.language;
        this.userService.loaderObesrver.next(false);
      })
    })
  }

  public invoiceNotFoundDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'message': this.textService.currentText.invoiceNotFoundText,
        'title': this.textService.currentText.invoiceNotFound,
      },
    }).afterClosed().subscribe();
  }

  public requestInvoiceDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'message': this.textService.currentText.requestInvoiceText,
        'title': this.textService.currentText.requestInvoice,
      },
    }).afterClosed().subscribe();
  }

  public getInvoicingLanguage () {
    this.userService.loaderObesrver.next(true);
    this.userService.getInvoicingLanguage(this.lang).subscribe(() => {
      this.userService.loaderObesrver.next(false);
    })
  }

  public requestInvoice (invoiceNumber) {
    this.userService.loaderObesrver.next(true);
    this.userService.requestInvoice(invoiceNumber).subscribe(() => {
      this.requestInvoiceDialog();
      this.userService.loaderObesrver.next(false);
    })
  }

  public downloadFile(id, value) {
    let file_path;
    if (value > 0) {
      file_path = `${this.userService._apiEndpoint}invoicing/download_invoice?filename=invoice${id}.pdf`;
    } else {
      file_path = `${this.userService._apiEndpoint}invoicing/download_invoice?filename=credit${id}.pdf`;
    }
    this.userService.loaderObesrver.next(true);
    this.userService.checkInvoiceIsExist(file_path).subscribe((data) => {
      this.userService.loaderObesrver.next(false);
      if (data.hasOwnProperty('isSuccess')) {
        this.invoiceNotFoundDialog();
      } else {
        const a: HTMLElement = document.createElement('A') as HTMLElement;
        a['href'] = file_path;
        a['download'] = file_path.substr(file_path.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // window.open(this.userService._apiEndpoint + 'orders/download_report', '_blank');
        // this.loader = false;
      }
    })
  }
}
