import { Component, OnInit, Input } from '@angular/core';
import { EChartOption } from 'echarts';
import { StorageBrowser } from 'src/app/services/storage.browser';

@Component({
  selector: 'app-orders-barchart',
  templateUrl: './orders-barchart.component.html'
})
export class OrdersBarchartComponent implements OnInit {
  @Input() topValues: any;
  echartPPUBarChangeOptions: any;
  echartPPUBarOptions: EChartOption = {
    responsive: true,
    //color: ['#ff7aac', '#d9df20', '#5792cc', '#8bc53e', '#6bf7aa'],
    tooltip: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      show: false,
      type: 'value',
      axisLine: {
        lineStyle: {
          color: "rgba(36, 56, 83, 0.5)"
        },
      },
      textStyle: { color: 'rgba(36, 56, 83, 0.5)' },
      axisLabel: {
        formatter: '{value}'
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      show: false,
      type: 'category',
      data: [],
      inverse: true,
      axisLine: {
        lineStyle: {
          color: "rgba(36, 56, 83, 0.5)"
        },
      },
      axisLabel: {
        show: false,
        position: 'left',
        formatter: function (value) {
          return value;
        }
      },
      splitLine: {
        show: false
      }
    },
    series: [
      {
        type: 'bar',
        data: [],
        smooth: true,
        showSymbol: false,
        label: {
          // normal: {
          //   fontSize: 14,
          //   color: 'rgba(36, 56, 83, 0.5)',
          //   position: 'right',
          //   show: true,
          //   formatter: function (val) {
          //     return '$' + val.value.toLocaleString();
          //   }
          // }
        },
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 50, 50]
          }
        },
        barMaxWidth: 15,
        //color: "rgba(36, 56, 83, 0.8)"
      }
    ]
  };

  constructor(public storageBrowser: StorageBrowser) {

  }

  ngOnInit() {
    setTimeout(() => {

      if (this.topValues) {
        let currency_name = this.storageBrowser.get('$Currency');
        this.echartPPUBarOptions.series[0].label = {
          normal: {
            fontSize: 14,
            color: 'rgba(36, 56, 83, 0.5)',
            position: 'insideBottomLeft',
            distance: 18,
            show: true,
            formatter: function (val) {
              let currency_symbols = {
                'USD': '$', // US Dollar
                'AUD': '$',
                'CAD': '$',
                'MXN': '$',
                'GBP': '£', // British Pound Sterling
                'INR': '₹', // Indian Rupee
                'JPY': '¥', // Japanese Yen
              };
              if (currency_symbols[currency_name] !== undefined) {
                return currency_symbols[currency_name] + val.value.toLocaleString();
              } else {
                return val.value.toLocaleString();
              }
            }
          },
        }
        let colorCode = ['#ff7aac', '#d9df20', '#5792cc', '#8bc53e', '#6bf7aa'];
        this.topValues.map((val, i) => {
          val.color = colorCode[i];
        });
        this.echartPPUBarOptions.series[0].data = this.topValues.map(val => {
          return {
            'value': val.value.toFixed(), 'itemStyle': { 'color': val.color }
          }
        });
        this.echartPPUBarOptions.yAxis.data = this.topValues.map(val => val.scopeName);
        this.echartPPUBarOptions.tooltip = {
          trigger: 'item',
          //position: ['35%', '5%'],
          extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
          textStyle: { color: '#4d4f5c', fontSize: 9 },
          backgroundColor: '#fff',
          formatter: function (params) {
            return '<h4 class="px-1 py-1" style="font-size:14px">' + params.name + '</h4>';
          },

        }
      }

    });
  }

};