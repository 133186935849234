<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-header">
    <!--(click)="invoiceNotFoundDialog()"-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="head-container">
            <h2>{{textService.currentText.billing}}</h2>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="filters-container" >-->
    <!--<div class="a-side" >-->
    <!--<div class="select-holder custom-selects">-->
    <!--<div (click)="functions.setDropDownPosition(event, 'events')" #event-->
    <!--[class.active]="events.panelOpen"-->
    <!--class="select-wrapper">-->
    <!--<mat-form-field>-->
    <!--<mat-select [placeholder]="textService.currentText.selectInvoiceLanguage"-->
    <!--#events-->
    <!--[(ngModel)]="lang"-->
    <!--(change)="getInvoicingLanguage()"-->
    <!--shouldPlaceholderFloat="false"-->
    <!--panelClass="default-dropdown events"-->
    <!--disableRipple-->
    <!--name="events">-->
    <!--<mat-option *ngFor="let select of constService.invoiceLanguage"-->
    <!--[value]="select.value">-->
    <!--{{ select.viewValue }}-->
    <!--</mat-option>-->
    <!--</mat-select>-->
    <!--</mat-form-field>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--&lt;!&ndash;<div class="b-side">&ndash;&gt;-->
    <!--&lt;!&ndash;<button mat-button mat-raised-button class="default-btn b-side__btn" (click)="downloadAll()">&ndash;&gt;-->
    <!--&lt;!&ndash;{{textService.currentText.downloadAllInvoices}}&ndash;&gt;-->
    <!--&lt;!&ndash;</button>&ndash;&gt;-->
    <!--&lt;!&ndash;</div>&ndash;&gt;-->
    <!--</div>-->
  </div>
  <div class="dashboard-container">
    <h3 class="font-size-18 mr-3">{{textService.currentText.billing}}</h3>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header" [class.full-width]="true">
            <div class="td">{{textService.currentText.invoiceDate}}</div>
            <div class="td">{{textService.currentText.invoiceNumber}}</div>
            <div class="td">{{textService.currentText.actions}}</div>
          </div>
        </div>
        <div class="table-holder table">
          <div class="tr" *ngFor="let invoice of invoiceList">
            <div class="td">{{invoice.date}}</div>
            <div class="td">{{invoice.invoiceNumber}}</div>
            <div class="td d-flex justify-content-center align-items-center text-center">
              <button mat-button class="primary-btn download" *ngIf="invoice.invoiceNumber"
                (click)="downloadFile(invoice.invoiceNumber, invoice.value)">
                {{textService.currentText.download}}
              </button>
              <button mat-button class="primary-btn download" *ngIf="invoice.isOld && !invoice.invoiceNumber" (click)="requestInvoice(invoice.date)">
                {{textService.currentText.requestInvoice}}
              </button>
              <!-- <span class="action-el" *ngIf="invoice.invoiceNumber"
                (click)="downloadFile(invoice.invoiceNumber, invoice.value)">
                {{textService.currentText.download}}
              </span> -->
              <!-- <span class="action-el" *ngIf="invoice.isOld && !invoice.invoiceNumber" (click)="requestInvoice(invoice.date)">
                {{textService.currentText.requestInvoice}}
              </span> -->
              <span class="action-el" #peding *ngIf="!invoice.isOld && invoice.invoiceNumber">
                {{textService.currentText.pending}}
              </span>
            </div>
          </div>
          <div class="tr not-found" *ngIf="!invoiceList.length">
            <div class="td not-found">
              {{textService.currentText.noDataFound}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>