import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Functions} from '../../../services/functions';
import {AccountSettings} from '../../../services/account-settings';
import {UserService} from '../../../services/user.service';
import {MoneyBackService} from '../../../services/money-back.service';
import {ConstService} from '../../../services/const-service';
import {FormBuilder, Validators} from '@angular/forms';
import {TextService} from '../../../services/text.service';

@Component({
  templateUrl: './money-back-dialog.component.html',
  styleUrls: ['./money-back-dialog.component.scss']
})

export class MoneyBackDialogComponent implements OnInit {
  public submitForm;
  public afterDate;
  public beforeDate;
  public dayInputType = 'number';
  public requestInputType = 'number';
  public moneybackInputType = 'number';
  public before = false;
  public after = false;
  public status = false;
  public errors: any = {};

  @ViewChild('email', { static: false }) emailInput: ElementRef;
  @ViewChild('days', { static: false }) daysInput: ElementRef;
  @ViewChild('requests', { static: false }) requestsInput: ElementRef;
  @ViewChild('moneyback', { static: false }) xf: ElementRef;

  constructor(public accountSettings: AccountSettings,
              public userService: UserService,
              public moneyBackService: MoneyBackService,
              public fb: FormBuilder,
              public constService: ConstService,
              public textService: TextService,
              public functions: Functions,
              public matDialog: MatDialog,
              public dialogRef: MatDialogRef<MoneyBackDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit(): void {
    if (this.data.data.isActive) {
      this.status = this.data.data.isActive;
    } else {
      this.status = false;
    }
    if (this.data.adjustments) {
      this.submitForm = this.fb.group({
        email: ['', [Validators.required,
          Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        days: ['', [Validators.required,
          Validators.min(1), Validators.max(100)]],
        requests: ['', [Validators.required,
          Validators.min(1), Validators.max(20)]]
      });
      this.submitForm.controls['email'].setValue(this.data.data.amazonEmail);
      this.submitForm.controls['days'].setValue(this.data.data.daysBetweenRequests);
      this.submitForm.controls['requests'].setValue(this.data.data.numberOfRequests);
    } else {
      this.submitForm = this.fb.group({
        email: ['', [Validators.required,
          Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        days: ['', [Validators.required,
          Validators.min(1), Validators.max(100)]],
        requests: ['', [Validators.required,
          Validators.min(1),
          Validators.max(20)]],
        moneyback: ['', [Validators.required, Validators.min(30)]]
      });
      this.submitForm.controls['email'].setValue(this.data.data.amazonEmail);
      this.submitForm.controls['days'].setValue(this.data.data.daysBetweenRequests);
      this.submitForm.controls['requests'].setValue(this.data.data.numberOfRequests);
      this.submitForm.controls['moneyback'].setValue(this.data.data.lateRefundGuranteeValue);
    }
    this.after = this.data.data.afterDate;
    this.before = this.data.data.beforeDate;
  }

  public check(): void {
    if (this.checkForm()) {
      const saveData = {
        publicId: this.userService.currentUser.id,
        beforeDate: this.beforeDate,
        afterDate: this.afterDate,
        numberOfRequests: this.submitForm.controls['requests'].value,
        daysBetweenRequests: this.submitForm.controls['days'].value,
        amazonEmail: this.submitForm.controls['email'].value,
        isActive: this.status,
        lateRefundGuranteeValue: ''
      };
      if (this.data.adjustments) {
        this.moneyBackService.moneybackSaveInfo(saveData).subscribe((data) => {
          this.closeDialog(data.isSuccess);
        });
      } else {
        saveData.lateRefundGuranteeValue = this.submitForm.controls['moneyback'].value;
        this.moneyBackService.moneybackSaveInventoryInfo(saveData).subscribe((data) => {
          this.closeDialog(data.isSuccess);
        });
      }
    }
  }

  public clearAfterDate(): void {
    this.afterDate = undefined;
  }

  public clearBeforeDate(): void {
    this.beforeDate = undefined;
  }

  public closeDialog(isSuccess: boolean): void {
    this.dialogRef.close(isSuccess);
  }

  public setPlaceholder(date: any): any {
    if (date && date.length > 0) {
      return date;
    }
  }

  public checkForm(): boolean {
    if (!this.submitForm.valid) {
      if (!this.submitForm.controls.email.valid) {
        this.errors.email = true;
        this.displayError(this.textService.currentText.errorRequired);
        return;
      }
      if (!this.submitForm.controls.days.valid) {
        this.dayInputType = 'text';
        setTimeout(() => {
          this.errors.days = true;
          this.displayError(this.textService.currentText.errorMoreThan0Less100);
        });
        return;
      }
      if (!this.submitForm.controls.requests.valid) {
        this.requestInputType = 'text';
        setTimeout(() => {
          this.errors.requests = true;
          this.displayError(this.textService.currentText.errorMoreThan0Less20);
        });
        return;
      }
      if (!this.submitForm.controls.moneyback.valid) {
        this.moneybackInputType = 'text';
        setTimeout(() => {
          this.errors.moneyback = true;
          this.displayError(this.textService.currentText.errorMoreThan30);
        });
        return;
      }
      return false;
    }
    return this.submitForm.valid;
  }

  private displayError(message?: string): void {
    if (!this.submitForm.controls.email.valid) {
      this.errors.email = true;
      const old_value = this.submitForm.value.email;
      this.submitForm.patchValue({
        email: message
      });
      this.submitForm.controls['email'].disable();
      this.emailInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.days.valid) {
      this.errors.days = true;
      const old_value = this.submitForm.value.days;
      this.submitForm.patchValue({
        days: message
      });
      this.submitForm.controls['days'].disable();
      this.daysInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.requests.valid) {
      this.errors.requests = true;
      const old_value = this.submitForm.value.requests;
      this.submitForm.patchValue({
        requests: message
      });
      this.submitForm.controls['requests'].disable();
      this.requestsInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.submitForm.controls.moneyback.valid) {
      this.errors.moneyback = true;
      const old_value = this.submitForm.value.moneyback;
      this.submitForm.patchValue({
        moneyback: message
      });
      this.submitForm.controls['moneyback'].disable();
      // this.moneybackInput.nativeElement.blur();
      this.resetErrorState(old_value);
      return;
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.email) {
        this.submitForm.patchValue({
          email: val,
        });
        this.errors.email = false;
        this.submitForm.controls['email'].enable();
        return;
      }
      if (this.errors.days) {
        this.submitForm.patchValue({
          days: val,
        });
        this.dayInputType = 'number';
        this.errors.days = false;
        this.submitForm.controls['days'].enable();
      }
      if (this.errors.requests) {
        this.submitForm.patchValue({
          requests: val,
        });
        this.requestInputType = 'number';
        this.errors.requests = false;
        this.submitForm.controls['requests'].enable();
      }
      if (this.errors.moneyback) {
        this.submitForm.patchValue({
          moneyback: val,
        });
        this.moneybackInputType = 'number';
        this.errors.moneyback = false;
        this.submitForm.controls['moneyback'].enable();
      }
    }, 2000);
  }
}
