<div class="main-dialog-container edit-dialog" *ngIf="data.dialogFor === 'edit-dialog'">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.editProduct}}</h2>
      </div>
      <form action="" class="custom-selects">
        <div class="form-side">
          <div class="form-row">
            <div class="inner-row">
              <div class="label-name">Per Unit Cost</div>
              <input type="number" (keydown)="$event.key === 'e' ? $event.preventDefault() : null"
                     matInput
                     [(ngModel)]="currentProduct.cost" name="cost">
            </div>
              <div class="inner-row">
                  <div class="label-name">{{textService.currentText.currency}}</div>
                  <div class="select-wrapper"
                       (click)="functions.setDropDownPosition(currency, 'defaultCurrencySelect')"
                       #currency
                       [class.active]="defaultCurrencySelect.panelOpen">
                      <mat-form-field>
                          <mat-select
                              shouldPlaceholderFloat="false"
                              panelClass="default-dropdown defaultCurrencySelect"
                              [(ngModel)]="currentProduct.currency"
                              name="defaultCurrency"
                              #defaultCurrencySelect
                              disableRipple>
                              <mat-option *ngFor="let select of localCurrencies" [value]="select.value">
                                  {{ select.viewValue }}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
              </div>
          </div>
        </div>
        <div class="table-container">
          <div class="table-wrapper">
            <div class="table-header-wrapper">
              <div class="table-header">
                <div class="td">{{textService.currentText.marketplace}}</div>
                <div class="td">{{textService.currentText.shortName}}</div>
                <div class="td">{{textService.currentText.displayedName}}</div>
              </div>
            </div>
            <div class="table-holder">
              <div class="table">
                <div class="tr tr-big">
                  <div class="td">{{textService.currentText.default}} <br>
                    ({{textService.currentText.ninjaAccount}})
                  </div>
                  <input matDialog
                         class="td"
                         [placeholder]="textService.currentText.notDefined"
                         [(ngModel)]="currentProduct.shortName"
                         name="shortName">
                  <input matDialog
                         class="td"
                         disabled
                         name="shortNameDefault"
                         [(ngModel)]="currentProduct.shortName"
                         [placeholder]="defaultProduct.shortName">
                </div>
                <ng-container *ngIf="showAllProducts">
                  <ng-container *ngIf="userService.currentCountry === 'UK'">
                    <div class="tr">
                        <div class="td">
                          {{textService.currentText.amazonUK}}
                        </div>
                        <input matDialog
                               class="td"
                               [placeholder]="textService.currentText.notDefined"
                               [(ngModel)]="currentProduct.shortNameUK"
                               name="shortNameUK">
                        <input matDialog
                               class="td"
                               disabled
                               name="shortNameDefaultUK"
                               [(ngModel)]="currentProduct.shortNameUK"
                               [placeholder]="currentProduct.shortName">
                        <div class="td">
                          <button class="reset-btn"
                                  [disabled]="currentProduct.shortNameUK.length === 0"
                                  (click)="currentProduct.shortNameUK = ''">Reset
                          </button>
                        </div>
                    </div>
                    <div class="tr">
                      <div class="td">
                        {{textService.currentText.amazonFR}}
                        </div>
                        <input matDialog
                                class="td"
                                [placeholder]="textService.currentText.notDefined"
                                [(ngModel)]="currentProduct.shortNameFR"
                                name="shortNameFR">
                        <input matDialog
                                class="td"
                                disabled
                                name="shortNameDefaultFR"
                                [(ngModel)]="currentProduct.shortNameFR"
                                [placeholder]="currentProduct.shortName">
                        <div class="td">
                            <button class="reset-btn"
                                    [disabled]="currentProduct.shortNameFR.length === 0"
                                    (click)="currentProduct.shortNameFR = ''">Reset
                            </button>
                          </div>
                    </div>
                    <div class="tr">
                        <div class="td">
                          {{textService.currentText.amazonDE}}
                        </div>
                        <input matDialog
                                class="td"
                                [placeholder]="textService.currentText.notDefined"
                                [(ngModel)]="currentProduct.shortNameDE"
                                name="shortNameDE">
                        <input matDialog
                                class="td"
                                disabled
                                name="shortNameDefaultDE"
                                [(ngModel)]="currentProduct.shortNameDE"
                                [placeholder]="currentProduct.shortName">
                        <div class="td">
                          <button class="reset-btn"
                                  [disabled]="currentProduct.shortNameDE.length === 0"
                                  (click)="currentProduct.shortNameDE = ''">Reset
                          </button>
                        </div>
                    </div>
                    <div class="tr">
                        <div class="td">
                          {{textService.currentText.amazonIT}}
                        </div>
                        <input matDialog
                                class="td"
                                [placeholder]="textService.currentText.notDefined"
                                [(ngModel)]="currentProduct.shortNameIT"
                                name="shortNameIT">
                        <input matDialog
                                class="td"
                                disabled
                                name="shortNameDefaultIT"
                                [(ngModel)]="currentProduct.shortNameIT"
                                [placeholder]="currentProduct.shortName">
                        <div class="td">
                          <button class="reset-btn"
                                  [disabled]="currentProduct.shortNameIT.length === 0"
                                  (click)="currentProduct.shortNameIT = ''">Reset
                          </button>
                        </div>
                    </div>
                    <div class="tr">
                        <div class="td">
                          {{textService.currentText.amazonES}}
                        </div>
                        <input matDialog
                                class="td"
                                [placeholder]="textService.currentText.notDefined"
                                [(ngModel)]="currentProduct.shortNameES"
                                name="shortNameES">
                        <input matDialog
                                class="td"
                                disabled
                                name="shortNameDefaultES"
                                [(ngModel)]="currentProduct.shortNameES"
                                [placeholder]="currentProduct.shortName">
                        <div class="td">
                          <button class="reset-btn"
                                  [disabled]="currentProduct.shortNameES.length === 0"
                                  (click)="currentProduct.shortNameES = ''">Reset
                          </button>
                        </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="userService.currentCountry === 'US'">
                    <div class="tr">
                      <div class="td">
                        {{textService.currentText.amazonCOM}}
                      </div>
                      <input class="td"
                            [(ngModel)]="currentProduct.shortNameCOM"
                            [placeholder]="textService.currentText.notDefined"
                            name="shortnameCOM">
                      <input class="td"
                            disabled
                            name="shortnameCOMDefault"
                            [(ngModel)]="currentProduct.shortNameCOM"
                            [placeholder]="currentProduct.shortName">
                      <div class="td">
                        <button class="reset-btn"
                                [disabled]="currentProduct.shortNameCOM.length === 0"
                                (click)="currentProduct.shortNameCOM = ''">Reset
                        </button>
                      </div>
                    </div>
                    <div class="tr">
                      <div class="td">
                        {{textService.currentText.amazonMX}}
                      </div>
                      <input matDialog
                            [(ngModel)]="currentProduct.shortNameMX"
                            class="td"
                            [placeholder]="textService.currentText.notDefined"
                            name="shortnamesJP">
                      <input matDialog
                            class="td"
                            disabled
                            name="shortnamesMXDefault"
                            [(ngModel)]="currentProduct.shortNameMX"
                            [placeholder]="currentProduct.shortName">
                      <div class="td">
                        <button class="reset-btn"
                                [disabled]="currentProduct.shortNameMX.length === 0"
                                (click)="currentProduct.shortNameMX = ''">Reset
                        </button>
                      </div>
                    </div>
                    <div class="tr">
                      <div class="td">
                        {{textService.currentText.amazonCA}}
                      </div>
                      <input matDialog
                            class="td"
                            [(ngModel)]="currentProduct.shortNameCA"
                            [placeholder]="textService.currentText.notDefined"
                            name="shortnamesCA">
                      <input matDialog
                            class="td"
                            disabled
                            name="shortnamesCADefault"
                            [(ngModel)]="currentProduct.shortNameCA"
                            [placeholder]="currentProduct.shortName">
                      <div class="td">
                        <button class="reset-btn"
                                [disabled]="currentProduct.shortNameCA.length === 0"
                                (click)="currentProduct.shortNameCA = ''">Reset
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="userService.currentCountry === 'AU'">
                      <div class="tr">
                          <div class="td">
                            {{textService.currentText.amazonAU}}
                          </div>
                          <input class="td"
                                [(ngModel)]="currentProduct.shortNameAU"
                                [placeholder]="textService.currentText.notDefined"
                                name="shortnameAU">
                          <input class="td"
                                disabled
                                name="shortNameAUDefault"
                                [(ngModel)]="currentProduct.shortNameAU"
                                [placeholder]="currentProduct.shortName">
                          <div class="td">
                            <button class="reset-btn"
                                    [disabled]="currentProduct.shortNameAU.length === 0"
                                    (click)="currentProduct.shortNameAU = ''">Reset
                            </button>
                          </div>
                        </div>
                  </ng-container>
                  <ng-container *ngIf="userService.currentCountry === 'JP'">
                      <div class="tr">
                          <div class="td">
                            {{textService.currentText.amazonJP}}
                          </div>
                          <input class="td"
                                [(ngModel)]="currentProduct.shortNameJP"
                                [placeholder]="textService.currentText.notDefined"
                                name="shortNameJP">
                          <input class="td"
                                disabled
                                name="shortnameJPDefault"
                                [(ngModel)]="currentProduct.shortNameJP"
                                [placeholder]="currentProduct.shortName">
                          <div class="td">
                            <button class="reset-btn"
                                    [disabled]="currentProduct.shortNameJP.length === 0"
                                    (click)="currentProduct.shortNameJP = ''">
                              {{textService.currentText.reset}}
                            </button>
                          </div>
                        </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="add-row">
          <button class="add-btn" [class.colorGrey]="showAllProducts" (click)="showAllProducts = true">
            + {{textService.currentText.addShortNameEachChannel}}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="default-btn" (click)="resetAll()">{{textService.currentText.resetAll}}</button>
    <button mat-button class="primary-btn" (click)="saveData()">{{textService.currentText.save}}</button>
  </div>
</div>
