<div class="header" [class.wide]="userService.smallSidebar">
  <span class="staging" *ngIf="userService._apiEndpoint ===
    'http://quantifyninjastaging-env.us-west-2.elasticbeanstalk.com/'">STAGING</span>
  <!--<div class="trial-message">-->
  <div class="trial-message" *ngIf="(userService.currentUser?.isTrial &&
    !userService.currentUser.hasActivePlan) ||
    userService.currentUser?.isShowAuthNotification ||
    (!userService.currentUser?.advertisingAccess &&
    userService.currentUser?.marketplace != 'JP')">
    <img src="../../../../assets/icons/alert-icon.svg" alt="">
    <ng-container *ngIf="userService.currentUser?.isShowAuthNotification">
      <p *ngIf="userService.currentUser?.numberOfDaysBeforeGrantAccess">{{textService.currentText.expireTokenText1}}
        <span *ngIf="userService.currentUser?.numberOfDaysBeforeGrantAccess">{{textService.currentText.expireTokenText2}}</span>
        <ng-container
          *ngIf="userService.currentUser?.numberOfDaysBeforeGrantAccess">
          {{userService.currentUser?.numberOfDaysBeforeGrantAccess}}
          <span class="normal-text">
            {{textService.currentText.expireTokenText3}}</span>
        </ng-container>
      </p>
      <p *ngIf="!userService.currentUser?.numberOfDaysBeforeGrantAccess">
        {{textService.currentText.expireTokenText5}}
        <span class="bold-text" (click)="mixpanelService.track('q banner - regrant access clicked', {'page':'dashboard-header'}); navigateToAmazon()">{{textService.currentText.here}}</span>
        {{textService.currentText.expireTokenText6}}
      </p>
      <button *ngIf="userService.currentUser?.numberOfDaysBeforeGrantAccess"
        class="upgrade-btn"
        (click)="navigateToAmazon()">{{textService.currentText.grantAccessBtn}}</button>
      <button class="upgrade-btn"
        [class.hide-button]="userService.currentUser?.numberOfDaysBeforeGrantAccess"
        (click)="hideNotification()">{{textService.currentText.hide}}</button>
    </ng-container>
    <ng-container *ngIf="userService.currentUser?.isTrial">
      <p>{{textService.currentText.trialMessage1}} <span>{{userService.currentUser.endTrial}}</span>
        {{textService.currentText.days}}.
        <span class="link" [routerLink]="'upgrade'">
          {{textService.currentText.clickHere}}</span>
        {{textService.currentText.trialMessage2}};
      </p>
      <button class="upgrade-btn" [routerLink]="'upgrade'">{{textService.currentText.upgrade}}</button>
    </ng-container>
    <ng-container *ngIf="!userService.currentUser?.advertisingAccess &&
      userService.currentUser?.marketplace != 'JP'">
      <p><span class="link" (click)="mixpanelService.track('q banner - ppc grant access clicked', {'page':'dashboard-header'});navigateToAdvertising()">
          {{textService.currentText.clickHere}}</span>
        {{textService.currentText.advertisingHeader}}
      </p>
      <button class="upgrade-btn" (click)="mixpanelService.track('q banner - ppc grant access clicked', {'page':'dashboard-header'});navigateToAdvertising()">{{textService.currentText.advertisingBtn}}</button>
    </ng-container>
  </div>
  <div class="head-actions">
    <div class="header-action" (click)="toggleShowSystemMessage();"
      (mouseenter)="mixpanelService.track('q header - notifications hover',{'page':'dashboard-header'});viewSystemMessages= true"
      (mouseleave)="viewSystemMessages= false">
      <div class="help-tooltip uread-reminder" *ngIf="unreadReminder &&
        systemMessagesService.unreadMessages?.length> 0">
        <p>{{textService.currentText.youHave}} <span class="bold">{{systemMessagesService.unreadMessages?.length
            || 0}}
            {{textService.currentText.unreadMessages}}</span></p>
      </div>
      <div class="message-counter"
        *ngIf="systemMessagesService.unreadMessages?.length> 0">
        {{ systemMessagesService.unreadMessages?.length }}
      </div>
      <div class="icon system-messages-icon"
        [class.unread]="systemMessagesService.unreadMessages?.length> 0"></div>
    </div>
    <div class="header-action">
      <div class="icon contact-icon" (click)="mixpanelService.track('q contact page - view',{'source':'header'});
        showContactUs()"></div>
    </div>
    <div class="header-action"
      (click)="googleAnalyticsEventsService.emitEvent('Button', 'Navigate to
      Settings', 'Click',
      1);mixpanelService.track('q settings page - view',{'source':'header'}); opened= !opened"
      *ngIf="userService.currentUser?.permissions?.isSettings"
      [routerLink]="this.userService.currentUser?.isVa ?
      'user/settings/product-settings' : 'user/settings/account-settings'">
      <div class="icon settings-icon">

      </div>
    </div>
  </div>
  <div class="header__b-side">
    <button class="language-change" [matMenuTriggerFor]="translateMenu">
      <img [src]="textService.currentLangTitle?.img" alt="">
      <!-- <span>{{textService.currentLangTitle?.text}} </span> -->
      <!-- <img class="arrow" src="assets/icons/arrow-down-translate.svg" alt=""> -->
    </button>
    <mat-menu xPosition="before" yPosition="above" class="lang-menu"
      #translateMenu="matMenu" [overlapTrigger]="false">
      <div class="languages">
        <button class="language-change"
          (click)="textService.changeLang(textService.currentText,
          textService.textEN);
          googleAnalyticsEventsService.emitEvent('Button', 'langChangedToEN',
          'Click', 1);mixpanelService.track('q change language -
          clicked',{'language':'EN','page':'dashboard-header'});
          saveLanguage('EN');
          textService.setLangBadge('EN');">
          <img src="assets/images/uk-translate.svg" alt="">
          <span>{{textService.currentText.ENG}}</span>
        </button>
        <button class="language-change"
          (click)="textService.changeLang(textService.currentText,
          textService.textES);
          googleAnalyticsEventsService.emitEvent('Button', 'langChangedToES',
          'Click', 1);mixpanelService.track('q change language -
          clicked',{'language':'ES','page':'dashboard-header'});
          saveLanguage('ES');
          textService.setLangBadge('ES')">
          <img src="assets/images/es-translate.svg" alt="">
          <span>{{textService.currentText.ES}}</span>
        </button>
        <button class="language-change"
          (click)="textService.changeLang(textService.currentText,
          textService.textRO);
          googleAnalyticsEventsService.emitEvent('Button', 'langChangedToRO',
          'Click', 1);mixpanelService.track('q change language -
          clicked',{'language':'RO','page':'dashboard-header'});
          saveLanguage('RO');
          textService.setLangBadge('RO');">
          <img src="assets/images/ro-translate.svg" alt="">
          <span>{{textService.currentText.RO}}</span>
        </button>
        <button class="language-change"
          (click)="textService.changeLang(textService.currentText,
          textService.textCN);
          googleAnalyticsEventsService.emitEvent('Button', 'langChangedToCH',
          'Click', 1);mixpanelService.track('q change language -
          clicked',{'language':'CN','page':'dashboard-header'});
          saveLanguage('CN');
          textService.setLangBadge('CN')">
          <img src="assets/images/ch-translate.svg" alt="">
          <span>{{textService.currentText.CN}}</span>
        </button>
      </div>
    </mat-menu>
    <div class="head-actions">
      <div class="header-action mr-0">
        <div class="icon user-icon menu" (click)="mixpanelService.track('q profile page - view',{'source':'header'});opened= !opened">
          <span class="menu">{{textService.currentText.user}} </span>
          <img class="arrow max-width-inherit mx-2 menu"
            src="assets/icons/arrow-down-translate.svg" alt="">
        </div>
        <div class="user-accounts-popup menu" [class.active]="opened">
          <div class="active-account menu">
            <div class="active-account__a-side menu">
              <div class="img-container user-avatar menu">
                <img src="../../../../assets/icons/user-icon.svg"
                  alt="user-avatar">
              </div>
              <div class="user-info menu">
                <div class="user-nickname menu">{{textService.currentText.user}}
                  1</div>
                <div class="user-email menu switchAccount"
                  *ngIf="!userService.currentUser.isVa" id="primary-acc"
                  (click)="storageBrowser.get('$UserToken$originSellerId') ?
                  loginAsUser(storageBrowser.get('$UserToken$originSellerId')) :
                  loginAsUser(storageBrowser.get('$UserToken$user').sellerId);
                  userService.currentUser.mainEmail= true">
                  {{userService.currentUser?.email ?
                  userService.currentUser?.email :
                  userService.currentUser?.emailFrom}}
                  <img src="../../../../assets/icons/acc-checkmark.svg" alt="">
                </div>
                <div class="user-email menu switchAccount"
                  *ngIf="userService.currentUser.isVa">
                  {{userService.currentUser?.email ?
                  userService.currentUser?.email :
                  userService.currentUser?.emailFrom}}
                  <img src="../../../../assets/icons/acc-checkmark.svg" alt="">
                </div>
              </div>
            </div>
            <div class="b-side">
              <button mat-button mat-raised-button class="default-btn logout-btn
                menu"
                (click)="googleAnalyticsEventsService.emitEvent('Button',
                'Navigate to Settings', 'Click',
                1);mixpanelService.track('q settings page - view',{'source':'header'});
                opened= !opened"
                *ngIf="userService.currentUser?.permissions?.isSettings"
                [routerLink]="'user/settings/account-settings'">
                {{textService.currentText.settings}}
              </button>
              <button mat-button mat-raised-button class="default-btn logout-btn
                menu"
                (click)="googleAnalyticsEventsService.emitEvent('Button',
                'Contact Us', 'Click', 1);
                mixpanelService.track('q contact page - view',{'source':'header'});
                showContactUs()">
                {{textService.currentText.contactUs}}
              </button>
            </div>
          </div>
          <ul class="account-list menu"
            *ngIf="userService.currentUser?.mangedAccounts?.length> 0">
            <li class="account-item menu" *ngFor="let email of
              userService.currentUser.mangedAccounts; let i= index">
              <div class="img-container user-avatar--list menu">
                <img src="../../../../assets/icons/user-icon.svg" alt="">
              </div>
              <div class="user-info menu switchAccount"
                (click)="mixpanelService.track('q change account - clicked',{'page':'dashboard-header'});loginAsUser(email);
                userService.currentUser.mainEmail= false"
                [class.active]="userService.currentUser.mangedAccounts[i]?.sellerId
                === userService.currentUser?.sellerId">
                User {{i+2}} ({{email.email}})
                <img src="../../../../assets/icons/acc-checkmark.svg" alt="">
              </div>
            </li>
          </ul>
          <div class="user-accounts-footer menu">
            <p> App ver: 0.{{versionClient}}.{{versionServer}}</p>
            <button mat-button mat-raised-button class="default-btn logout-btn
              menu" (click)="mixpanelService.track('q log out -
              clicked',{'page': 'dashboard-header'}); logout()">
              Log Out
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="messages-holder" [class.active]="showSystemMessages ||
      viewSystemMessages" *ngIf="showSystemMessages || viewSystemMessages">
      <ng-container *ngFor="let message of systemMessagesService.messages">
        <div class="messages-item" [class.has-seen]="message.messageIsHasSeen">
          <div class="message-row">
            <div class="status" [class.success]="false"
              [style.background-color]="message.messageTypeColor">
              {{message.messageTypeText}}
            </div>
            <div class="date">
              {{message.messageTime | date:'medium'}}
            </div>
          </div>
          <p>{{message.messageContent}}</p>
        </div>
      </ng-container>

      <div class="no-messages" *ngIf="systemMessagesService.messages?.length ===
        0">
        {{textService.currentText.noNotifications}}
      </div>
    </div>
  </div>
</div>