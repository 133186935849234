<div class="main-dialog-container">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container">
    <div class="dialog-content">
      <div class="header">
        <h2>{{textService.currentText.selectPost}}</h2>
      </div>
      <div class="dialog-holder">
        <div class="table-container">
          <div class="table-wrapper">
            <div class="table-header-wrapper">
              <div class="table-header full-width" [class.full-width]="posts?.length <= 2">
                <div class="tr">
                  <div class="td">

                  </div>
                  <div class="td">{{textService.currentText.content}}</div>
                  <div class="td">{{textService.currentText.postCreatedTime}}</div>
                </div>
              </div>
            </div>
            <div class="table-holder">
              <div class="table">
                <div class="tr" [class.checked]="post.checked" (click)="disableChecker(); post.checked = !post.checked; canSave = false" *ngFor="let post of posts">
                  <div class="td">
                    <mat-radio-button
                        [checked]="post.checked"
                        disableRipple></mat-radio-button>
                  </div>
                  <div class="td">{{post.message}}</div>
                  <div class="td">{{post.time}}</div>
                </div>
                <div class="tr no-data" *ngIf="posts?.length === 0">
                  <div class="td not-found">
                    {{textService.currentText.noDataFound}}.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="primary-btn white" (click)="dialogRef.close(false)">{{textService.currentText.back}}</button>
    <button mat-button class="primary-btn" (click)="emitPost()" [disabled]="canSave">{{textService.currentText.select}}</button>
  </div>
</div>
