<div class="expenses-head">
  <button mat-button class="secondary-btn" (click)="addExpence()">
    {{ textService.currentText.addExpense }}
  </button>
</div>
<div class="d-flex align-items-center">
  <h3 class="font-size-18 mr-3">{{ textService.currentText.expenseTitle }}</h3>
  <h6 class="dates ml-3">
    Dates : {{ defaultStartDate }}-{{ defaultEndDate }}
  </h6>

  <div class="ml-4">
    <!-- <mat-button-toggle-group class="marginleft" [(ngModel)]="selectedVal">
      <mat-button-toggle
        [value]="selectedVal"
        class="default-btn"
        (click)="
          mixpanelService.track('q time frame - clicked', {
            type: 'Custom',
            page: 'expenses'
          });
          $event.preventDefault()
        "
        [matMenuTriggerFor]="custom"
        #customDate="matMenuTrigger"
      >
        Custom
      </mat-button-toggle>
    </mat-button-toggle-group> -->
    <button
      mat-button
      class="customBtn"
      [value]="selectedVal"
      (click)="
        mixpanelService.track('q time frame - clicked', {
          type: 'Custom',
          page: 'expenses'
        });
        $event.preventDefault()
      "
      [matMenuTriggerFor]="custom"
      #customDate="matMenuTrigger"
    >
      Custom
    </button>
    <mat-menu #custom="matMenu" class="custom-menu-profit">
      <form class="dashboard-schedules-filter" (ngSubmit)="onSubmit()">
        <div
          class="row flex-column flex-md-row"
          (click)="$event.stopPropagation()"
          (keydown)="$event.stopPropagation()"
        >
          <div class="">
            <ngx-daterangepicker-material
              [showRangeLabelOnInput]="true"
              [autoApply]="true"
              [showCustomRangeLabel]="false"
              [alwaysShowCalendars]="true"
              [ranges]="ranges"
              [linkedCalendars]="true"
              [maxDate]="startDate"
              [keepCalendarOpeningWithRange]="true"
              (rangeClicked)="choosedDate($event)"
              (choosedDate)="choosedDate($event)"
              [showClearButton]="false"
              [locale]="{ applyLabel: 'Done' }"
            >
            </ngx-daterangepicker-material>
          </div>
          <div class="right-box ml-4">
            <div class="d-flex mt-4 justify-content-between">
              <button
                type="button"
                mat-button
                class="default-btn orange cancelBtn"
                (click)="cancelPicker()"
              >
                Cancel
              </button>
              <button mat-button class="default-btn applyBtn" type="submit">
                Apply
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-menu>
  </div>
  <div class="groupByBtn mr-3">
    <mat-form-field>
      <mat-label>Group By </mat-label>
      <mat-select
        [formControl]="groupby"
        multiple
        [disableOptionCentering]="true"
        (selectionChange)="changeGroupbySelection($event.value)"
      >
        <mat-option value="asin">Asin</mat-option>
        <mat-option value="type">Type</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="table-container">
  <div class="table-wrapper expenses-wrapper">
    <div class="table-header-wrapper">
      <div class="table-header expenses-header">
        <div class="td">IMAGE</div>
        <div class="td">
          {{ textService.currentText.asin }}
        </div>
        <div class="td">
          <!-- {{ textService.currentText.name }} -->
          Type
        </div>
        <div class="td">
          {{ textService.currentText.from }}
        </div>
        <div class="td">
          {{ textService.currentText.to }}
        </div>
        <div class="td">
          {{ textService.currentText.amount }}
        </div>
        <div class="td" *ngIf="this.groupby?.value?.length === 0 || !this.groupby?.value?.length">{{ textService.currentText.action }}</div>
      </div>
    </div>
    <div class="table-holder expenses-holder">
      <div class="table expenses-table" *ngIf="expences?.length > 0">
        <div class="tr" *ngFor="let expence of expences; let i = index" >
          <div class="td">
            <img [src]="expence?.img" height="43" width="41" *ngIf="expence?.img"  />
          </div>
          <div class="td">
            {{ expence.asin }}
          </div>
          <div class="td">
            {{ expence.name }}
          </div>
          <div class="td" [class.display-none]="!expence.edit">
            <mat-form-field>
              <input
                matInput
                [matDatepicker]="pickerFrom"
                [(ngModel)]="expence.from"
                name=""
                (click)="pickerFrom.open()"
                (keydown)="$event.preventDefault()"
                [max]="expence.to"
                [disabled]="!expence.edit"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerFrom"
              ></mat-datepicker-toggle>
              <mat-datepicker
                touchUi="true"
                panelClass="calendar-popup"
                #pickerFrom
              ></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="td" [class.display-none]="!expence.edit">
            <mat-form-field>
              <input
                matInput
                [matDatepicker]="pickerTo"
                [(ngModel)]="expence.to"
                name="to"
                (click)="pickerTo.open()"
                (keydown)="$event.preventDefault()"
                [min]="expence.from"
                [disabled]="!expence.edit"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerTo"
              ></mat-datepicker-toggle>
              <mat-datepicker
                touchUi="true"
                panelClass="calendar-popup"
                #pickerTo
              ></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="td">
            {{ expence.amount | number: "1.0-2" }}
          </div>
          <div class="td" *ngIf="this.groupby?.value?.length === 0 || !this.groupby?.value?.length">
            <button class="cancel-btn" *ngIf="expence.edit" (click)="cancel(i)">
              {{ textService.currentText.cancel }}
            </button>
            <button class="edit-btn" (click)="edit(i)" *ngIf="!expence.edit">
              <!-- <div class="edit-btn-content">
                <div class="icon edit-icon"></div>
                <span>{{textService.currentText.edit}}</span>
              </div> -->
              <img src="../../../../../assets/images/settings.png" />
              <span *ngIf="expence.edit" (click)="save(i)" class="action-el"
                >Save</span
              >
            </button>
            <button *ngIf="!expence.edit" (click)="deleteExpences(i)">
              <img src="../../../../../assets/images/delete.png" />
              <!-- <div class="icon delete-icon"></div>
              <span>{{textService.currentText.delete}}</span> -->
            </button>
          </div>
        </div>
      </div>
      <div class="tr justify-content-center" *ngIf="expences?.length === 0">
        No Data
      </div>
    </div>
    <div class="table-header-wrapper">
      <div class="table-header expenses-header font-weight-bold backGround">
        <div class="td">Total</div>
        <div class="td"></div>
        <div class="td"></div>
        <div class="td"></div>
        <div class="td"></div>
        <div class="td">
          {{ totalAmount | number: "1.0-2" }}
        </div>
        <div class="td"></div>
      </div>
    </div>
  </div>
</div>
<ninja-notification
  [active]="userService.showNotification"
  [message]="notificationMessage"
  [title]="notificationTitle"
>
</ninja-notification>
