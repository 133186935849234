import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfitbreakdownpopupComponent } from '../popups/profitbreakdownpopup/profitbreakdownpopup.component';
import { ListComponent } from '../popups/list/list.component';
import { ErrorsComponent } from '../popups/errors/errors.component';
import { graphic } from 'echarts';
import { ShareService } from 'src/app/services/share.service';
import * as moment from 'moment';
import { UserService } from 'src/app/services/user.service';
import { StorageBrowser } from 'src/app/services/storage.browser';

@Component({
  selector: 'app-todaysection',
  templateUrl: './todaysection.component.html',
  styleUrls: ['./todaysection.component.scss']
})
export class TodaysectionComponent implements OnInit {
  @Input() realTimeData: any;
  @Input() formData: any;
  service: any;
  currencySymbol: any;
  //formData: any;
  disableProfitBreakdownDialog: boolean = false;
  // @ViewChild("unitssoldchart", { static: true }) unitssoldchart: ElementRef;
  //@ViewChild("revenuechart", { static: true }) revenuechart: ElementRef;
  sharedChartOptions: any = {
    responsive: true
  };


  // Units Sold Chart
  unitsSoldChartOptions: any = Object.assign({
    color: ['#4ad991'],
    grid: {
      top: "1px",
      bottom: "1px",
      height: "80px"
    },
    xAxis: {
      type: 'category',
      data: [],
      show: false
    },
    yAxis: {
      type: 'value',
      show: false
    },
    series: [
      { // For shadow
        type: 'bar',
        itemStyle: {
          color: new graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#FDFFFF' },
              { offset: 0.5, color: '#E3FFFF' },
              { offset: 1, color: '#C9FFFF' }
            ]
          )
        },
        barGap: '-100%',
        barCategoryGap: '40%',
        data: [],
        animation: false
      },
      {
        data: [],
        type: 'bar'
      }],
    tooltip: {
      position: function (canvasMousePos, params, tooltipDom, rect, sizes) {
        var margin = 10; // How far away from the mouse should the tooltip be
        var overflowMargin = 5; // If no satisfactory position can be found, how far away from the edge of the window should the tooltip be kept
        var canvasRect = tooltipDom.parentElement.getElementsByTagName('canvas')[0].getBoundingClientRect();
        var mouseX = canvasMousePos[0] + canvasRect.x;
        var mouseY = canvasMousePos[1] + canvasRect.y;
        var tooltipWidth = sizes.contentSize[0];
        var tooltipHeight = sizes.contentSize[1];
        var xPos = mouseX + margin;
        var yPos = mouseY - margin - tooltipHeight;
        if (xPos + tooltipWidth >= document.documentElement.clientWidth) {
          xPos = mouseX - margin - tooltipWidth;
          if (xPos <= 0)
            xPos = overflowMargin;
        }
        if (yPos <= 0) {
          yPos = mouseY + margin;
          if (yPos + tooltipHeight >= document.documentElement.clientHeight)
            yPos = overflowMargin;
        }
        return [xPos - canvasRect.x, yPos - canvasRect.y]
      },
      trigger: 'axis',
      backgroundColor: '#fff',
      textStyle: { color: '#4d4f5c' },
      axisPointer: {
        animation: true
      },
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      formatter: function (params) {
        return params[0].axisValue;
      }
    },
    dataZoom: [
      {
        type: "inside"
      }
    ],
  });

  // Revenue Chart

  revenueChartOptions: any = Object.assign({
    color: ['#4ad991'],
    grid: {
      top: "1px",
      bottom: "1px",
      height: "80px"
    },
    xAxis: {
      type: 'category',
      data: [],
      show: false
    },
    yAxis: {
      type: 'value',
      show: false
    },
    series: [
      { // For shadow
        type: 'bar',
        itemStyle: {
          color: new graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#FDFFFF' },
              { offset: 0.5, color: '#E3FFFF' },
              { offset: 1, color: '#C9FFFF' }
            ]
          )
        },
        barGap: '-100%',
        barCategoryGap: '40%',
        data: [],
        animation: false
      },
      {
        data: [],
        type: 'bar'
      }],
    tooltip: {
      position: function (canvasMousePos, params, tooltipDom, rect, sizes) {
        var margin = 10; // How far away from the mouse should the tooltip be
        var overflowMargin = 5; // If no satisfactory position can be found, how far away from the edge of the window should the tooltip be kept
        var canvasRect = tooltipDom.parentElement.getElementsByTagName('canvas')[0].getBoundingClientRect();
        var mouseX = canvasMousePos[0] + canvasRect.x;
        var mouseY = canvasMousePos[1] + canvasRect.y;
        var tooltipWidth = sizes.contentSize[0];
        var tooltipHeight = sizes.contentSize[1];
        var xPos = mouseX + margin;
        var yPos = mouseY - margin - tooltipHeight;
        if (xPos + tooltipWidth <= document.documentElement.clientWidth) {
          xPos = mouseX - margin - tooltipWidth;
          if (xPos <= 0)
            xPos = overflowMargin;
        }
        if (yPos <= 0) {
          yPos = mouseY + margin;
          if (yPos + tooltipHeight >= document.documentElement.clientHeight)
            yPos = overflowMargin;
        }
        return [xPos - canvasRect.x, yPos - canvasRect.y]
      },
      trigger: 'axis',
      backgroundColor: '#fff',
      textStyle: { color: '#4d4f5c' },
      axisPointer: {
        animation: true
      },
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      formatter: function (params) {
        return params[0].axisValue;
      }
    },
    dataZoom: [
      {
        type: "inside"
      }
    ],
  });
  constructor(private dialog: MatDialog, private shareService: ShareService, public userService: UserService, public storageBrowser: StorageBrowser) { }

  ngOnInit() {
    this.currencySymbol = this.storageBrowser.get('$Currency');
    if (this.realTimeData) {
      this.unitsSoldChartOptions.xAxis.data = this.realTimeData.unitsData.diagram.map((item) => { //item.fromDate);
        let changeVal = 0;
        if (item.change) {
          changeVal = this.changeNumberFormat(item.change);
        }
        let changeDiff = item.value - item.valuePrev;
        let dynCls = '#4ad991';
        let arrowDef = 'trending_up';
        if (changeDiff < 0) {
          dynCls = '#ff6565';
          arrowDef = 'trending_down';
        }
        let fromDate = this.changeDateFormate(item.fromDate, 'DD/MM/YY hh:mm');
        let toDate = this.changeDateFormate(item.toDate, 'DD/MM/YY hh:mm');
        let fromdatePrev = this.changeDateFormate(item.fromdatePrev, 'DD/MM/YY hh:mm');
        let toDatePrev = this.changeDateFormate(item.toDatePrev, 'DD/MM/YY hh:mm');
        //item.value;
        return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Units Sold</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td>' + fromDate + ' - ' + toDate + '</td><td>' + this.changeNumberFormat(item.value) + '</td></tr><tr style="color:#a5a4bf"><td>' + fromdatePrev + ' - ' + toDatePrev + '</td><td>' + this.changeNumberFormat(item.valuePrev) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + changeVal + '%</span></td><td style="color:' + dynCls + '">' + this.changeNumberFormat(item.value - item.valuePrev) + '</td></tr></table>';
      });
      // // this.unitsSoldChartLabels = this.realTimeData.unitsData.diagram.map((item) => { return item.date.year + '/' + item.date.monthOfYear + '/' + item.date.dayOfMonth });
      // this.realTimeData.unitsData.diagram = this.realTimeData.unitsData.diagram.filter(item=>item.change>0)
      this.unitsSoldChartOptions.series[1].data = this.realTimeData.unitsData.diagram.map((item) => item.value);

      if (this.unitsSoldChartOptions.series[1].data.length > 0) {
        let maxUnitVal = this.unitsSoldChartOptions.series[1].data.reduce((a, b) => Math.max(a, b));
        for (var i = 0; i < this.unitsSoldChartOptions.series[1].data.length; i++) {
          this.unitsSoldChartOptions.series[0].data.push(maxUnitVal);
        }
      }

      this.revenueChartOptions.xAxis.data = this.realTimeData.revenueData.diagram.map((item) => { //item.fromDate);
        let changeVal = 0;
        if (item.change) {
          changeVal = this.changeNumberFormat(item.change);
        }
        let changeDiff = item.value - item.valuePrev;
        let dynCls = '#4ad991';
        let arrowDef = 'trending_up';
        if (changeDiff < 0) {
          dynCls = '#ff6565';
          arrowDef = 'trending_down';
        }
        let fromDate = this.changeDateFormate(item.fromDate, 'DD/MM/YY hh:mm');
        let toDate = this.changeDateFormate(item.toDate, 'DD/MM/YY hh:mm');
        let fromdatePrev = this.changeDateFormate(item.fromdatePrev, 'DD/MM/YY hh:mm');
        let toDatePrev = this.changeDateFormate(item.toDatePrev, 'DD/MM/YY hh:mm');
        //return item.value;
        return '<table><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Units Revenue</td><td><i class="material-icons" style="color:' + dynCls + ';font-size:16px">' + arrowDef + '</i></td></tr><tr><td>' + fromDate + ' - ' + toDate + '</td><td>' + this.changeNumberFormat(this.getCurrencySymbol(item.value)) + '</td></tr><tr style="color:#a5a4bf"><td>' + fromdatePrev + ' - ' + toDatePrev + '</td><td>' + this.changeNumberFormat(this.getCurrencySymbol(item.valuePrev)) + '</td></tr><tr><td style="color:#a5a4bf;float:left;font-weight:bold">Change ' + ' <span style="color:' + dynCls + '"> ' + changeVal + '%</span></td><td style="color:' + dynCls + '">' + this.changeNumberFormat(this.getCurrencySymbol(item.value - item.valuePrev)) + '</td></tr></table>';
      });
      this.revenueChartOptions.series[1].data = this.realTimeData.revenueData.diagram.map((item) => item.value);

      if (this.revenueChartOptions.series[1].data.length > 0) {
        let maxrevenVal = this.revenueChartOptions.series[1].data.reduce((a, b) => Math.max(a, b));
        for (var i = 0; i < this.revenueChartOptions.series[1].data.length; i++) {
          this.revenueChartOptions.series[0].data.push(maxrevenVal);
        }
      }

    }
    // this.service = this.shareService.currentDate.subscribe(data => this.formData = data);
  }

  getCurrencySymbol(value) {
    var currency_symbols = {
      'USD': '$', // US Dollar
      'AUD': '$',
      'CAD': '$',
      'MXN': '$',
      'GBP': '£', // British Pound Sterling
      'INR': '₹', // Indian Rupee
      'JPY': '¥', // Japanese Yen
    };
    let currency_name = this.storageBrowser.get('$Currency');
    if (currency_symbols[currency_name] !== undefined) {
      return currency_symbols[currency_name] + value;
    } else {
      return value;
    }
  }

  changeNumberFormat(data) {
    return Math.round(data * 100) / 100;
  }
  changeDateFormate(item, type) {
    return moment(item).format(type);
  }
  openUnitsSoldDialog() {
    this.userService.getUnitsSoldList("real_time/", this.formData).subscribe((res: any) => {
      if (res) {
        this.dialog.open(ListComponent, {
          panelClass: 'profitpopup',
          width: '420px',
          data: { dataSource: res, title: "Units Sold", "timeFrame": moment(this.realTimeData.date).format("DD/MM/YY"), "previousDate": moment(this.realTimeData.PreviousDate).format("DD/MM/YY") }
        });
        //this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  openRevenueDialog() {
    //this.appLoaderService.open();
    this.userService.getRevenueList("real_time/", this.formData).subscribe((res: any) => {
      if (res) {
        this.dialog.open(ListComponent, {
          width: '420px',
          panelClass: 'profitpopup',
          data: { dataSource: res, title: "Revenue", "timeFrame": moment(this.realTimeData.lastUpdated).format("DD/MM/YY"), "previousDate": moment(this.realTimeData.PreviousDate).format("DD/MM/YY") }
        });
        //this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  openProfitDialog() {
    //this.appLoaderService.open();
    this.userService.getProfitList("real_time/", this.formData).subscribe((res: any) => {
      if (res) {
        res.profitReal = 'profitReal';
        this.dialog.open(ListComponent, {
          panelClass: 'profitpopup',
          width: '420px',
          data: { dataSource: res, title: "Profit", "timeFrame": moment(this.realTimeData.lastUpdated).format("DD/MM/YY"), "previousDate": moment(this.realTimeData.PreviousDate).format("DD/MM/YY") }
        });
        // this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  openCostDialog() {
    //this.appLoaderService.open();
    this.userService.getCostList("real_time/", this.formData).subscribe((res: any) => {
      if (res) {
        this.dialog.open(ListComponent, {
          panelClass: 'profitpopup',
          width: '420px',
          data: { dataSource: res, title: "Costs", "timeFrame": moment(this.realTimeData.lastUpdated).format("DD/MM/YY"), "previousDate": moment(this.realTimeData.PreviousDate).format("DD/MM/YY") }
        });
        //this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  openMarginDialog() {
    //this.appLoaderService.open();
    this.userService.getMarginList("real_time/", this.formData).subscribe((res: any) => {
      if (res) {
        this.dialog.open(ListComponent, {
          panelClass: 'profitpopup',
          width: '420px',
          data: { dataSource: res, title: "Margin", "timeFrame": moment(this.realTimeData.lastUpdated).format("DD/MM/YY"), "previousDate": moment(this.realTimeData.PreviousDate).format("DD/MM/YY"), changeVal: this.realTimeData.margin }
        });
        //this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  openProfitBrakedownDialog() {
    this.userService.loaderObesrver.next(true);
    this.disableProfitBreakdownDialog = true;
    // this.appLoaderService.open();
    this.userService.getProfitBreakdownList("real_time/", this.formData).subscribe((res: any) => {
      if (res) {
        this.dialog.open(ProfitbreakdownpopupComponent, {
          width: '470px',
          panelClass: 'profitbreakdownpopup',
          data: res
        });
        this.disableProfitBreakdownDialog = false;
        this.userService.loaderObesrver.next(false);
        // this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  ngOnDestroy() {
    // this.service.unsubscribe();
  }

}