<div class="upgrade-container">
    <div class="row">
      <div
        *ngIf="!isShow"
        class="col-md-12 plan-padding col-12 col-lg-8 bg-white static-header"
      >
          <div class="arrow">
              <img src="../../../assets/images/arrow.png">
          </div>
        <div class="pageHeader">
          <h1 class="header-text">Upgrade to Ninja’s Growth Plan</h1>
          <h4 class="sub-header-text d-flex mt-3">
            ${{currPlan.basePricing}}/mo
            <p class="sub-header-text-1 m-0">(based on your monthly orders)</p>
          </h4>
        </div>
      </div>
      <div *ngIf="!isShow" class="col-md-12 plan-padding col-12 col-lg-8 bg-white scroll-container">
        <div class="">
          <div class="row margin-120">
            <div class="col-12">
              <h6>Plan Includes</h6>
            </div>
            <div class="col-12 col-md-6 plans">
              <ul class="p-0 mt-3">
                <li class="d-block pb-3">
                  <div class="d-flex align-items-center">
                    <img src="../../../assets/images/right.png" />
                    <h6 class="includes m-0 ml-3">Unlimited orders history</h6>
                  </div>
                </li>
                <li class="d-block pb-3">
                  <div class="d-flex align-items-center">
                    <img src="../../../assets/images/right.png" />
                    <h6 class="includes m-0 ml-3">Unlimited profit history</h6>
                  </div>
                </li>
                <li class="d-block pb-3">
                  <div class="d-flex align-items-center">
                    <img src="../../../assets/images/right.png" />
                    <h6 class="includes m-0 ml-3">
                      Unlimited post-purchase emails
                    </h6>
                  </div>
                </li>
                <li class="d-block pb-3">
                  <div class="d-flex align-items-center">
                    <img src="../../../assets/images/right.png" />
                    <h6 class="includes m-0 ml-3">Hijacked listing viewer</h6>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-6 plans">
              <ul class="p-0 mt-3">
                <li class="d-block pb-3">
                  <div class="d-flex align-items-center">
                    <img src="../../../assets/images/right.png" />
                    <h6 class="includes m-0 ml-3">Competitor alerts</h6>
                  </div>
                </li>
                <li class="d-block pb-3">
                  <div class="d-flex align-items-center">
                    <img src="../../../assets/images/right.png" />
                    <h6 class="includes m-0 ml-3">
                      Facebook, email & Telegram notifications
                    </h6>
                  </div>
                </li>
                <li class="d-block pb-3">
                  <div class="d-flex align-items-center">
                    <img src="../../../assets/images/right.png" />
                    <h6 class="includes m-0 ml-3">Unlimited invoiceg</h6>
                  </div>
                </li>
                <li class="d-block pb-3">
                  <div class="d-flex align-items-center">
                    <img src="../../../assets/images/right.png" />
                    <h6 class="includes m-0 ml-3">Facebot chatbot</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-5">
            <h5 class="boost-text">Boost Sales With Powerful Add-ons</h5>
            <div>
              <div *ngIf="addonsData['EU_REGION']!==undefined" class="d-flex flex-row mt-2 add-on {{currPlan.addOns.indexOf('EU_REGION')>=0?'ractangle-selected':'ractangle-white'}}">
                <div class="col-12 col-md-1 plan-icon">
                  <img src="../../../assets/images/reviews.png" />
                </div>
                <div class="col-12 col-md-3">
                  <h6 class="bold">Europe Region</h6>
                  <p class="normal-font-18">${{addonsData['EU_REGION'].pricing}}/mo</p>
                </div>
                <div class="col-12 col-md-5">
                  <p class="normal-font-16">
                    Automatically send review requests to your customers.
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <button *ngIf="currPlan.addOns.indexOf('EU_REGION')>=0" (click)="onAddonClick('EU_REGION')" class="btn-remove normal-font-14">Remove</button>
                  <button *ngIf="currPlan.addOns.indexOf('EU_REGION')<0" (click)="onAddonClick('EU_REGION')" class="btn-add normal-font-14">Add</button>
                </div>
              </div>
              <div *ngIf="addonsData['NA_REGION']!==undefined" class="d-flex flex-row mt-2 add-on {{currPlan.addOns.indexOf('NA_REGION')>=0?'ractangle-selected':'ractangle-white'}}">
                <div class="col-12 col-md-1 plan-icon">
                  <img src="../../../assets/images/na-region.png" />
                </div>
                <div class="col-12 col-md-3">
                  <h6 class="bold">North America Region</h6>
                  <p class="normal-font-18">${{addonsData['NA_REGION'].pricing}}/mo</p>
                </div>
                <div class="col-12 col-md-5">
                  <p>Automatically send review requests to your customers.</p>
                </div>
                <div class="col-12 col-md-3">
                  <button *ngIf="currPlan.addOns.indexOf('NA_REGION')>=0" (click)="onAddonClick('NA_REGION')"  class="btn-remove normal-font-14">Remove</button>
                  <button *ngIf="currPlan.addOns.indexOf('NA_REGION')<0" (click)="onAddonClick('NA_REGION')"  class="btn-add normal-font-14">Add</button>
                </div>
              </div>
              <div *ngIf="addonsData['FE_REGION']!==undefined" class="d-flex flex-row mt-2 add-on {{currPlan.addOns.indexOf('FE_REGION')>=0?'ractangle-selected':'ractangle-white'}}">
                <div class="col-12 col-md-1 plan-icon">
                  <img src="../../../assets/images/far-east.png" />
                </div>
                <div class="col-12 col-md-3">
                  <h6 class="bold">Far East Region</h6>
                  <p class="normal-font-18">${{addonsData['FE_REGION'].pricing}}/mo</p>
                </div>
                <div class="col-12 col-md-5">
                  <p>Automatically send review requests to your customers.</p>
                </div>
                <div class="col-12 col-md-3">
                  <button *ngIf="currPlan.addOns.indexOf('FE_REGION')>=0" (click)="onAddonClick('FE_REGION')" class="btn-remove normal-font-14">Remove</button>
                  <button *ngIf="currPlan.addOns.indexOf('FE_REGION')<0" (click)="onAddonClick('FE_REGION')" class="btn-add normal-font-14">Add</button>
                </div>
              </div>
              <div *ngIf="addonsData['REQUEST_REVIEW']!==undefined" class="d-flex flex-row mt-2 add-on {{currPlan.addOns.indexOf('REQUEST_REVIEW')>=0?'ractangle-selected':'ractangle-white'}}">
                <div class="col-12 col-md-1 plan-icon">
                  <img src="../../../assets/images/reviews.png" />
                </div>
                <div class="col-12 col-md-3">
                  <h6 class="bold">Automated Review Requests</h6>
                  <p class="normal-font-18">${{addonsData['REQUEST_REVIEW'].pricing}}/mo</p>
                </div>
                <div class="col-12 col-md-5">
                  <p>Automatically send review requests to your customers.</p>
                </div>
                <div class="col-12 col-md-3">
                  <button *ngIf="currPlan.addOns.indexOf('REQUEST_REVIEW')>=0" (click)="onAddonClick('REQUEST_REVIEW')" class="btn-remove normal-font-14">Remove</button>
                  <button *ngIf="currPlan.addOns.indexOf('REQUEST_REVIEW')<0" (click)="onAddonClick('REQUEST_REVIEW')" class="btn-add normal-font-14">Add</button>
                </div>
              </div>
                <div *ngIf="addonsData['ASIN_TRACKER']!==undefined" class="d-flex flex-row mt-2 add-on {{currPlan.addOns.indexOf('ASIN_TRACKER')>=0?'ractangle-selected':'ractangle-white'}}">
                <div class="col-12 col-md-1 plan-icon">
                  <img src="../../../assets/images/asin_tracker.png" />
                </div>
                <div class="col-12 col-md-3">
                  <h6 class="bold">ASIN tracker</h6>
                  <p class="normal-font-18">${{addonsData['ASIN_TRACKER'].pricing}}/mo</p>
                </div>
                <div class="col-12 col-md-5">
                  <p>Automatically send review requests to your customers.</p>
                </div>
                <div class="col-12 col-md-3">
                  <button *ngIf="currPlan.addOns.indexOf('ASIN_TRACKER')>=0" (click)="onAddonClick('ASIN_TRACKER')" class="btn-remove normal-font-14">Remove</button>
                  <button *ngIf="currPlan.addOns.indexOf('ASIN_TRACKER')<0"  (click)="onAddonClick('ASIN_TRACKER')" class="btn-add normal-font-14">Add</button>
                </div>
              </div>
              <div *ngIf="addonsData['DWONLOAD_ORDERS']!==undefined" class="d-flex flex-row mt-2 add-on {{currPlan.addOns.indexOf('DWONLOAD_ORDERS')>=0?'ractangle-selected':'ractangle-white'}}">
                <div class="col-12 col-md-1 plan-icon">
                  <img src="../../../assets/images/download-orders.png" />
                </div>
                <div class="col-12 col-md-3">
                  <h6 class="bold">Download Orders</h6>
                  <p class="normal-font-18">${{addonsData['DWONLOAD_ORDERS'].pricing}}/mo</p>
                </div>
                <div class="col-12 col-md-5">
                  <p>Automatically send review requests to your customers.</p>
                </div>
                <div class="col-12 col-md-3">
                  <button *ngIf="currPlan.addOns.indexOf('DWONLOAD_ORDERS')>=0"  (click)="onAddonClick('DWONLOAD_ORDERS')"  class="btn-remove normal-font-14">Remove</button>
                  <button *ngIf="currPlan.addOns.indexOf('DWONLOAD_ORDERS')<0"   (click)="onAddonClick('DWONLOAD_ORDERS')" class="btn-add normal-font-14">Add</button>
                </div>
              </div>
              <div *ngIf="addonsData['FACEBOOK_CHATBOT']!==undefined" class="d-flex flex-row mt-2 add-on {{currPlan.addOns.indexOf('FACEBOOK_CHATBOT')>=0?'ractangle-selected':'ractangle-white'}}">
                <div class="col-12 col-md-1 plan-icon">
                  <img src="../../../assets/images/fb-bot.png" />
                </div>
                <div class="col-12 col-md-3">
                  <h6 class="bold">Facebook Chatbot</h6>
                  <p class="normal-font-18">${{addonsData['FACEBOOK_CHATBOT'].pricing}}/mo</p>
                </div>
                <div class="col-12 col-md-5">
                  <p>Automatically send review requests to your customers.</p>
                </div>
                <div class="col-12 col-md-3">
                  <button *ngIf="currPlan.addOns.indexOf('FACEBOOK_CHATBOT')>=0"   (click)="onAddonClick('FACEBOOK_CHATBOT')"  class="btn-remove normal-font-14">Remove</button>
                  <button *ngIf="currPlan.addOns.indexOf('FACEBOOK_CHATBOT')<0"  (click)="onAddonClick('FACEBOOK_CHATBOT')" class="btn-add normal-font-14">Add</button>
                </div>
              </div>
              <div *ngIf="addonsData['PRODUCT_ALERS']!==undefined" class="d-flex flex-row mt-2 add-on {{currPlan.addOns.indexOf('PRODUCT_ALERS')>=0?'ractangle-selected':'ractangle-white'}}">
                <div class="col-12 col-md-1 plan-icon">
                  <img src="../../../assets/images/product-alert.png" />
                </div>
                <div class="col-12 col-md-3">
                  <h6 class="bold">Product Alerts</h6>
                  <p class="normal-font-18">${{addonsData['PRODUCT_ALERS'].pricing}}/mo</p>
                </div>
                <div class="col-12 col-md-5">
                  <p>Automatically send review requests to your customers.</p>
                </div>
                <div class="col-12 col-md-3">
                  <button *ngIf="currPlan.addOns.indexOf('PRODUCT_ALERS')>=0"   (click)="onAddonClick('PRODUCT_ALERS')" class="btn-remove normal-font-14">Remove</button>
                  <button *ngIf="currPlan.addOns.indexOf('PRODUCT_ALERS')<0" (click)="onAddonClick('PRODUCT_ALERS')" class="btn-add normal-font-14">Add</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isShow" class="col-md-12 plan-padding col-12 col-lg-8 bg-white">
          <div class="arrow">
              <img src="../../../assets/images/arrow.png">
          </div>
        <div class="pageHeader">
          <h1 class="header-text">Purchase Ninja’s Growth Plan</h1>
        </div>
        <div class="mt-5 d-flex flex-column radio-button-color">
          <mat-radio-button>
            <div class="d-flex">
              <div class="payment-image">
                <img src="../../../assets/images/visa-card.svg" />
              </div>
              <div class="payment-image">
                <img class="ml-3" src="../../../assets/images/mastercard.png" />
              </div>
              <div class="payment-image">
                <img
                  class="ml-3"
                  src="../../../assets/images/american-express.png"
                />
              </div>
              <div class="payment-image">
                <img class="ml-3" src="../../../assets/images/diners-club.png" />
              </div>
            </div>
          </mat-radio-button>
          <mat-radio-button class="mt-3">
            <img src="../../../assets/images/paypal.png" />
          </mat-radio-button>
        </div>
        <div class="mt-5 credit-form">
          <div class="row">
            <div class="col-md-6 col-12">
              <label class="normal-font-14">Card Holder’s First Name</label>
              <input
                matInput
                type="text"
                class="normal-font-14-color"
                placeholder="First Name"
              />
              <label class="normal-font-14 mt-3">Card Number</label>
              <input
                matInput
                type="text"
                maxlength="16"
                class="normal-font-14-color"
                placeholder="X X X X     X X X X     X X X X     X X X X"
                ng-pattern="/^\d{1,16}$/"
              />
              <label class="normal-font-14 mt-3">Company Name</label>
              <input
                matInput
                type="text"
                class="normal-font-14-color"
                placeholder="First Name"
              />
              <label class="normal-font-14 mt-3">City</label>
              <input
                matInput
                type="text"
                class="normal-font-14-color"
                placeholder="City name"
              />
              <label class="normal-font-14 mt-3">Country</label>
              <div class="select-wrapper custom-selects">
                <mat-form-field class="select">
                  <mat-select
                    [(ngModel)]="selectedValue"
                    name="country"
                    placeholder="Select Country"
                  >
                    <mat-option
                      *ngFor="let country of countries"
                      [value]="country.value"
                    >
                      {{ country.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <label class="normal-font-14">Last Name</label>
              <input
                matInput
                type="text"
                class="normal-font-14-color"
                placeholder="Last name"
              />
              <label class="normal-font-14 mt-3">Card Expiration and CVC</label>
              <input
                matInput
                type="text"
                class="normal-font-14-color"
                placeholder="MM / YY    CVC"
              />
              <label class="normal-font-14 mt-3">Address</label>
              <input
                matInput
                type="text"
                class="normal-font-14-color"
                placeholder="Street and number"
              />
              <label class="normal-font-14 mt-3">Postal Code</label>
              <input
                matInput
                type="text"
                class="normal-font-14-color"
                placeholder="Postal Code"
              />
              <label class="normal-font-14 mt-3">State</label>
              <div class="select-wrapper select custom-selects">
                <mat-form-field class="select">
                  <mat-select
                    [(ngModel)]="selectedValue"
                    name="state"
                    placeholder="Select State"
                    class="normal-font-14-color"
                  >
                    <mat-option
                      *ngFor="let state of states"
                      [value]="state.value"
                      class="normal-font-14-color"
                    >
                      {{ state.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <p class="normal-font-14 pb-3">
            Your privacy is super important to us - we’ll only use your
            information as described in our
            <a href="#" class="link">Terms of Service</a> and
            <a href="#" class="link">Privacy Policy.</a>
          </p>
        </div>
      </div>
      <div class="col-md-12 col-12 col-lg-4 payment amount-padding">
        <div class="w-100 ml-xl-3 bg-white payment-summary pt-3 pb-3">
          <div class="radio-row">
          <div
            class="d-flex justify-content-around normal-border pb-3 radio-button radio-button-color"
          >
          <mat-radio-group [(ngModel)]="paymentPeriod" name="paymentPeriod" (change)="onPeriodChange()">
              <mat-radio-button class="radio includes" value="monthly" >Monthly</mat-radio-button>
              <div class="payment-radio-seperator"></div>
              <div class="payment-radio">
                <mat-radio-button [checked]='true' class="includes"
                value="annual">Annual 
                  <p class="normal"> (Save 30%)</p></mat-radio-button
                >
              </div>
            </mat-radio-group>
          </div>
        </div>
          <div class="mt-3 px-4">
            <h6 class="includes">Your Plan</h6>
            <div class="d-flex justify-content-between growth-border pb-2">
              <p class="normal-font">Growth</p>
              <p class="normal-font-18">${{currPlan.basePricing}}</p>
            </div>
          </div>
          <div class="mt-3 px-4 growth-border pb-3">
            <h6 class="includes">Powerful Add-ons</h6>
            <div *ngIf="addonsData['REQUEST_REVIEW']!==undefined" class="d-flex justify-content-between">
              <p class="{{currPlan.addOns.indexOf('REQUEST_REVIEW')>=0?'normal-font-14':'normal-font-14-color'}}">Automated Review Requests</p>
              <p class="normal-font-18">{{currPlan.addOns.indexOf('REQUEST_REVIEW')>=0?'$'+addonsData['REQUEST_REVIEW'].pricing : '-' }}</p>
            </div>
            <div *ngIf="addonsData['PRODUCT_ALERS']!==undefined" class="d-flex justify-content-between">
              <p class="{{currPlan.addOns.indexOf('PRODUCT_ALERS')>=0?'normal-font-14':'normal-font-14-color'}}">Product Alerts</p>
              <p class="normal-font-18">{{currPlan.addOns.indexOf('PRODUCT_ALERS')>=0?'$'+addonsData['PRODUCT_ALERS'].pricing : '-' }}</p>
            </div>
            <div *ngIf="addonsData['FACEBOOK_CHATBOT']!==undefined" class="d-flex justify-content-between">
              <p class="{{currPlan.addOns.indexOf('FACEBOOK_CHATBOT')>=0?'normal-font-14':'normal-font-14-color'}}">Facebook Chatbot</p>
              <p class="normal-font-18">{{currPlan.addOns.indexOf('FACEBOOK_CHATBOT')>=0?'$'+addonsData['FACEBOOK_CHATBOT'].pricing : '-' }}</p>
            </div>
            <div *ngIf="addonsData['DWONLOAD_ORDERS']!==undefined" class="d-flex justify-content-between">
              <p class="{{currPlan.addOns.indexOf('DWONLOAD_ORDERS')>=0?'normal-font-14':'normal-font-14-color'}}">Download Orders</p>
              <p class="normal-font-18">{{currPlan.addOns.indexOf('DWONLOAD_ORDERS')>=0?'$'+addonsData['DWONLOAD_ORDERS'].pricing : '-' }}</p>
            </div>
            <div *ngIf="addonsData['ASIN_TRACKER']!==undefined" class="d-flex justify-content-between">
              <p class="{{currPlan.addOns.indexOf('ASIN_TRACKER')>=0?'normal-font-14':'normal-font-14-color'}}">ASIN Tracker</p>
              <p class="normal-font-18">{{currPlan.addOns.indexOf('ASIN_TRACKER')>=0?'$'+addonsData['ASIN_TRACKER'].pricing : '-' }}</p>
            </div>
            <div *ngIf="addonsData['EU_REGION']!==undefined" class="d-flex justify-content-between">
              <p class="{{currPlan.addOns.indexOf('EU_REGION')>=0?'normal-font-14':'normal-font-14-color'}}">Europe Region</p>
              <p class="normal-font-18">{{currPlan.addOns.indexOf('EU_REGION')>=0?'$'+addonsData['EU_REGION'].pricing : '-' }}</p>
            </div>
            <div *ngIf="addonsData['NA_REGION']!==undefined" class="d-flex justify-content-between">
              <p class="{{currPlan.addOns.indexOf('NA_REGION')>=0?'normal-font-14':'normal-font-14-color'}}">North America Region</p>
              <p class="normal-font-18">{{currPlan.addOns.indexOf('NA_REGION')>=0?'$'+addonsData['NA_REGION'].pricing : '-' }}</p>
            </div>
            <div *ngIf="addonsData['FE_REGION']!==undefined" class="d-flex justify-content-between">
              <p class="{{currPlan.addOns.indexOf('FE_REGION')>=0?'normal-font-14':'normal-font-14-color'}}">Far East Region</p>
              <p class="normal-font-18">{{currPlan.addOns.indexOf('FE_REGION')>=0?'$'+addonsData['FE_REGION'].pricing : '-' }}</p>
            </div>
            <!--<div class="d-flex justify-content-between normal-border pb-3">
              <p class="normal-font-14-color">Additional Region</p>
              <p class="ml-5">-</p>
            </div>-->
            <div class="d-flex justify-content-between mt-3">
              <p class="normal-font-16">Total per month</p>
              <p class="normal-font-18-bold">${{monthPrice | number:'1.2-2'}}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="normal-font-16">Total due today</p>
              <p class="normal-font-18-bold">${{paymentSum | number:'1.2-2'}}</p>
            </div>
          </div>
          <div class="text-center mt-4">
            <button class="btn-continue normal-font-16 btn-purchase" (click)="onSummeryClick()" [disabled]="monthPrice==0">Submit Purchase</button>
            <!--<button
              class="btn-continue normal-font-16"
              (click)="isShow ? openDialog() : openPurchasePayment()"
            >
              {{ isShow ? "Submit Purchase" : "Continue" }}
            </button>-->
          </div>
          <div class="d-flex justify-content-around mt-3 pl-3 pr-3">
            <div class="d-flex align-items-center">
              <img class="height-13" src="../../../assets/images/lock-icon.png" />
              <p class="m-0 ml-2 normal-font-14-secure">SSl Encrypted</p>
            </div>
            <div class="d-flex align-items-center">
              <img
                class="height-13"
                src="../../../assets/images/shieid-icon.png"
              />
              <p class="m-0 ml-2 normal-font-14-secure">Secure Payment</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  