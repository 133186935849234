<mat-card>
    <mat-card-title class="d-flex justify-content-between">
        <div class="d-flex">
            <h4 class="mb-0">{{this.data.title}}</h4>
        </div>
        <div class="d-flex" *ngIf="this.data.title == 'Promotions'">
            <span class="mb-0 font-size-14 pr-3" (click)="openPromotionSummary()">Open Promotions Summary</span>
            <span class="mb-0 close" (click)="closePopup()">X</span>
        </div>
        <div class="d-flex" *ngIf="this.data.title != 'Promotions'">
            <span class="mb-0 close" (click)="closePopup()">X</span>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="product-settings-filters custom-selects" *ngIf="this.data.title != 'Promotions Summary'">
            <div class="filters-container my-3 justify-content-start">
                <div class="btn-container" (click)="isFilters = !isFilters">
                    <button mat-button mat-raised-button class="default-btn filter-btn" type="button"
                        [class.active]="isFilters">
                        {{textService.currentText.filters}}
                    </button>
                </div>
                <form class="filters settings-filters custom-selects" [class.active]="isFilters">
                    <div class="input-wrapper" *ngIf="this.data.title == 'Promotions'">
                        <mat-form-field>
                            <input matInput [placeholder]="textService.currentText.promId" type="text" name="promotionid"
                                [(ngModel)]="promotionid" (keyup)="checkFilters()">
                            <div class="clear-input" *ngIf="promotionid.length > 0" (click)="promotionid = ''; checkFilters()">
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="input-wrapper">
                        <mat-form-field>
                            <input matInput [placeholder]="textService.currentText.name" type="text" name="filtersName"
                                [(ngModel)]="filtersName" (keyup)="checkFilters()">
                            <div class="clear-input" *ngIf="filtersName.length > 0" (click)="filtersName = ''; checkFilters()">
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="input-wrapper">
                        <mat-form-field>
                            <input matInput name="sku" [(ngModel)]="sku" type="text" (keyup)="checkFilters()"
                                [placeholder]="textService.currentText.SKU">
                            <div class="clear-input" *ngIf="sku.length > 0" (click)="sku = ''; checkFilters()"></div>
                        </mat-form-field>
                    </div>
                    <div class="input-wrapper" *ngIf="this.data.title == 'Orders'">
                        <mat-form-field>
                            <input matInput type="text" (keyup)="checkFilters()" name="asin" [(ngModel)]="asin"
                                [placeholder]="textService.currentText.asin">
                            <div class="clear-input" *ngIf="asin.length > 0" (click)="asin = ''; checkFilters()"></div>
                        </mat-form-field>
                    </div>
                    <div class="input-wrapper">
                        <mat-form-field>
                            <input matInput type="text" (keyup)="checkFilters()" name="quantity" [(ngModel)]="quantity"
                                [placeholder]="textService.currentText.quantity">
                            <div class="clear-input" *ngIf="quantity.length > 0" (click)="quantity = ''; checkFilters()"></div>
                        </mat-form-field>
                    </div>
                    <div class="input-wrapper">
                        <mat-form-field>
                            <input matInput type="text" (keyup)="checkFilters()" name="cost" [(ngModel)]="cost"
                                [placeholder]="textService.currentText.cost">
                            <div class="clear-input" *ngIf="cost.length > 0" (click)="cost = ''; checkFilters()"></div>
                        </mat-form-field>
                    </div>
                    <div class="input-wrapper" *ngIf="this.data.title == 'Orders'">
                        <mat-form-field>
                            <input matInput type="text" (keyup)="checkFilters()" name="totalProfit" [(ngModel)]="totalProfit"
                                [placeholder]="textService.currentText.totalProfit">
                            <div class="clear-input" *ngIf="totalProfit.length > 0" (click)="totalProfit = ''; checkFilters()"></div>
                        </mat-form-field>
                    </div>
                    <div class="input-wrapper" *ngIf="this.data.title == 'Promotions'">
                        <mat-form-field>
                            <input matInput type="text" (keyup)="checkFilters()" name="promotionValue" [(ngModel)]="promotionValue"
                                [placeholder]="textService.currentText.promoValue">
                            <div class="clear-input" *ngIf="promotionValue.length > 0" (click)="promotionValue = ''; checkFilters()"></div>
                        </mat-form-field>
                    </div>
                    <div class="input-wrapper">
                        <mat-form-field>
                            <input matInput type="text" (keyup)="checkFilters()" name="id" [(ngModel)]="id"
                                [placeholder]="textService.currentText.orderId">
                            <div class="clear-input" *ngIf="id.length > 0" (click)="id = ''; checkFilters()"></div>
                        </mat-form-field>
                    </div>
                    <button mat-button (click)="clearFilter()" mat-raised-button class="default-btn clear-btn" type="button">
                        {{textService.currentText.clearAll}}
                    </button>
                </form>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table" [ngClass]="this.data.title != 'Promotions Summary'?'orders':''" *ngIf="this.data.dataSource" matSort
                (matSortChange)="sortData($event)">
                <thead>
                    <tr>
                        <th class="matIcon">#</th>
                        <th *ngIf="this.data.title == 'Promotions Summary'" mat-sort-header="promotionName">Promotion Id</th>
                        <th *ngIf="this.data.title == 'Promotions Summary'" mat-sort-header="uses">Uses</th>
                        <th *ngIf="this.data.title == 'Promotions'" mat-sort-header="promotionid">Promotion Id</th>
                        <th *ngIf="this.data.title != 'Promotions Summary'" mat-sort-header="name" [ngClass]="this.data.title == 'Promotions' ? '' : 'order'">Product</th>
                        <th mat-sort-header="sku" *ngIf="this.data.title == 'Promotions'">Sku</th>
                        <th mat-sort-header="SKU" *ngIf="this.data.title == 'Orders'">Sku</th>
                        <th mat-sort-header="asin" *ngIf="this.data.title == 'Orders'">Asin</th>
                        <th *ngIf="this.data.title != 'Promotions Summary'" mat-sort-header="quantity">Quantity</th>
                        <th *ngIf="this.data.title != 'Promotions Summary'" mat-sort-header="cost">Cost</th>
                        <th mat-sort-header="totalProfit" *ngIf="this.data.title == 'Orders'">Total Profit</th>
                        <th mat-sort-header="promotionValue" *ngIf="this.data.title == 'Promotions'">Promo Value</th>
                        <th *ngIf="this.data.title != 'Promotions Summary'" mat-sort-header="id">Order Id</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let main of this.data.dataSource; let mainIndex = index" data-toggle="collapse" data-target="#accordion{{mainIndex}}"
                        class="clickable">
                        <td colspan="8" class="p-0 border-0" *ngIf="this.data.title != 'Promotions Summary'">
                            <table class="table" [ngClass]="this.data.title != 'Promotions Summary'?'orders':''">
                                <tr>
                                    <td class="matIcon">
                                        <span class="d-flex">
                                            <mat-icon (click)="expandedRow(mainIndex)"
                                                class="h1 mb-0 align-middle profit-breakdown-icon-color cursor-pointer">
                                                {{expandedElement == mainIndex ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
                                            {{mainIndex+1}}
                                        </span>

                                    </td>
                                    <td *ngIf="this.data.title == 'Promotions'">{{main.rowData[0].promotionid}}</td>
                                    <td matTooltip="{{main.rowData[0].name}}" [ngClass]="this.data.title == 'Promotions' ? '' : 'order'"><span class="short-name" (click)="editShortName(main.rowData[0])">
                                        <mat-icon class="mat-edit-icon">edit</mat-icon>
                                        {{main.rowData[0].name}}
                                    </span></td>
                                    <td class="word-break">{{this.data.title == 'Orders'?main.rowData[0].SKU:main.rowData[0].sku}}</td>
                                    <td *ngIf="this.data.title == 'Orders'">{{main.rowData[0].asin}}</td>
                                    <td>{{main.rowData[0].quantity}}</td>
                                    <td>
                                        <span (click)="editProductCost(main.rowData[0])">
                                            {{main.rowData[0].cost | currency: currencySymbol : 'symbol'}}
                                            <mat-icon class="mat-edit-icon">edit</mat-icon>
                                        </span>
                                    </td>
                                    <td *ngIf="this.data.title == 'Orders'">{{main.rowData[0].totalProfit | currency: currencySymbol : 'symbol' : '1.0-0'}}</td>
                                    <td *ngIf="this.data.title == 'Promotions'">{{main.rowData[0].promotionValue | currency: currencySymbol : 'symbol' : '1.0-0'}}</td>
                                    <td class="white-space-nowrap">{{main.id}}</td>
                                </tr>
                                <tr *ngIf="expandedElement == mainIndex">
                                    <td colspan="8" class="text-left" *ngIf="main.rowData[0].feesList.length > 0">
                                        <div class="feeslist pl-4 p-2" *ngFor="let mainS of main.rowData[0].feesList;">{{mainS.typeName}} :
                                            <b>{{mainS.Event | currency: currencySymbol : 'symbol' : '1.0-0'}}</b></div>
                                    </td>
                                    <td colspan="8" class="text-left" *ngIf="main.rowData[0].feesList.length == 0">
                                        <div class="feeslist pl-4 p-2">
                                            Order pending - no data available
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td *ngIf="this.data.title == 'Promotions Summary'">
                            {{mainIndex+1}}
                        </td>
                        <td *ngIf="this.data.title == 'Promotions Summary'">
                            {{main.promotionName}}
                        </td>
                        <td *ngIf="this.data.title == 'Promotions Summary'">
                            {{main.uses}}
                        </td>
                    </tr>
                    <!-- <tr>
                        <td colspan="3">
                            <div id="accordion0" class="collapse">Hidden by default</div>
                        </td>
                    </tr> -->
                    <!-- <tr *ngFor="let main of this.data.dataSource; let mainIndex1 = index">
                        <td colspan="8" *ngIf="expandedElement == mainIndex1">
                            <div class="feeslist" *ngFor="let mainS of main.rowData[0].feesList;">{{mainS.typeName}} :
                                <b>{{mainS.Event}}</b></div>
                        </td>
                    </tr> -->
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>