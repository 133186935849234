import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {TextService} from '../../../services/text.service';

@Component({
  templateUrl: './upgrade-business-details-dialog.component.html',
  styleUrls: ['./upgrade-business-details-dialog.component.scss']
})
export class UpgradeBusinessDetailsComponent implements OnInit{
  public errors: any = {};
  public bussinesDetailsForm: any;
  public showBusinessNameTooltip = false;
  public showBusinessRegNumberTooltip = false;


  constructor(public matDialog: MatDialog,
              public fb: FormBuilder,
              public textService: TextService,
              public dialogRef: MatDialogRef<UpgradeBusinessDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit(): void {
    this.bussinesDetailsForm = this.fb.group({
      name: ['', [Validators.required]],
      number: ['', [Validators.required]],
    });
  }


  public checkForm(): boolean {
    if (!this.bussinesDetailsForm.valid) {
      if (!this.bussinesDetailsForm.controls.name.valid) {
        this.errors.name = true;
        this.displayError('This field is required');
        return;
      }
      if (!this.bussinesDetailsForm.controls.number.valid) {
        setTimeout(() => {
          this.errors.number = true;
          this.displayError('This field is required');
        });
        return;
      }
      return false;
    }
    if (this.bussinesDetailsForm.valid) {
      this.dialogRef.close({
        status : true,
        name : this.bussinesDetailsForm.controls.name.value,
        number : this.bussinesDetailsForm.controls.number.value
      });
    }
  }


  private displayError(message?: string): void {
    if (!this.bussinesDetailsForm.controls.name.valid) {
      this.errors.name = true;
      const old_value = this.bussinesDetailsForm.value.name;
      this.bussinesDetailsForm.patchValue({
        name: message
      });
      this.bussinesDetailsForm.controls['name'].disable();
      this.resetErrorState(old_value);
      return;
    }
    if (!this.bussinesDetailsForm.controls.number.valid) {
      this.errors.number = true;
      const old_value = this.bussinesDetailsForm.value.number;
      this.bussinesDetailsForm.patchValue({
        number: message
      });
      this.bussinesDetailsForm.controls['number'].disable();
      this.resetErrorState(old_value);
      return;
    }
  }

  private resetErrorState(val: string): void {
    setTimeout(() => {
      if (this.errors.name) {
        this.bussinesDetailsForm.patchValue({
          name: val,
        });
        this.errors.name = false;
        this.bussinesDetailsForm.controls['name'].enable();
        return;
      }
      if (this.errors.number) {
        this.bussinesDetailsForm.patchValue({
          number: val,
        });
        this.errors.number = false;
        this.bussinesDetailsForm.controls['number'].enable();
      }
    }, 2000);
  }

}
