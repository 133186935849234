<div class="main-dialog-container edit-coupon" *ngIf="data.dialogFor === 'upload-coupon'">
  <div class="preloader-wrapper-dashboard" *ngIf="userService.dialogLoader">
    <div class="preloader">
      <img src="../../../assets/images/preloader.svg" alt="preloader">
    </div>
  </div>
  <div class="close-btn" (click)="mixpanelService.track('q coupons - upload cancel clicked');dialogRef.close(false)"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <div class="dialog-content" name="form" [formGroup]="submitForm" novalidate>
      <div class="header">
        <h2 *ngIf="!data.edit">{{textService.currentText.uploadCoupon}}</h2>
        <h2 *ngIf="data.edit">{{textService.currentText.addCoupon}}</h2>
      </div>
      <div class="dialog-row sub-row" [class.hidden]="data.edit" [class.has-danger]="errors?.couponName">
        <div class="label-name">
          <span>{{textService.currentText.couponsName}}</span>
        </div>
        <input matInput formControlName="couponName" #couponNameId [disabled]="data.edit" type="text">
      </div>
      <div class="dialog-row datepicker custom-selects borderless-input" [class.has-danger]="errors?.date">
        <div class="label-name">
          <span>{{textService.currentText.expirationDate}}</span>
        </div>
        <mat-form-field *ngIf="!errors?.date; else elseBlockData">
          <input #date matInput formControlName="date" [matDatepicker]="picker" name="dateFrom" [min]="currentDate" (click)="picker.open()"
            (keydown)="$event.preventDefault()">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker touchUi="true" panelClass="calendar-popup" #picker></mat-datepicker>
        </mat-form-field>
        <ng-template #elseBlockData>
          <mat-form-field>
            <input matInput type="text" [value]="textService.currentText.errorValidExpDate" disabled>
            <mat-datepicker panelClass="calendar-popup"></mat-datepicker>
          </mat-form-field>
        </ng-template>
      </div>
      <div class="dialog-row upload mb-0" [class.has-danger]="errors?.file">
        <div class="label-name">
          <span>{{textService.currentText.couponFile}}</span>
        </div>
        <input class="upload-Input" #input type="file" name="file" [attr.multiple]="null" (change)="fileUploader($event)" accept=".txt">
        <input type="text" #inputId formControlName="file" [value]="textInputValue" disabled class="error-input">
        <button mat-button class="default-btn cancel" (click)="input.click()">
          <img src="../../../assets/icons/upload.png">
        </button>
      </div>
      <div class="dialog-row mb-0">
        <div class="label-name">
          <span></span>
        </div>
        <div class="txt">
          <img src="../../../assets/icons/info-icon.svg">
          <span>{{textService.currentText.onlyTxt}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer justify-content-around">
    <button mat-button class="secondary-btn cancel" (click)="mixpanelService.track('q coupons - upload cancel clicked');dialogRef.close(false)">{{textService.currentText.cancel}}</button>
    <button mat-button class="secondary-btn" (click)="mixpanelService.track('q coupons - upload save clicked ');checkState()">{{textService.currentText.save}}</button>
  </div>
</div>