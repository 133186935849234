<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-container">
    <div class="dashboard-header">
      <div class="head-container">
        <h2>{{textService.currentText.subscribersTitle}}</h2>
      </div>
      <div class="sub-head-container">
        <p>
          {{textService.currentText.subscriberTotal}} <span>{{subscribers?.length}}</span>
        </p>
      </div>
      <div class="filters-container">
        <div class="a-side">
          <div class="btn-container" (click)="isFilters = !isFilters">
            <button
                mat-button
                mat-raised-button
                class="default-btn filter-btn"
                type="button"
                [class.active]="isFilters">
              {{textService.currentText.filters}}
            </button>
          </div>
          <form class="filters custom-selects" [class.active]="isFilters">
            <div class="select-holder">
              <div (click)="functions.setDropDownPosition(pageFrame, 'pages')" #pageFrame
                   [class.active]="pageId.panelOpen"
                   class="select-wrapper">
                <mat-form-field>
                  <mat-select [placeholder]="textService.currentText.selectPage"
                              shouldPlaceholderFloat="false"
                              panelClass="default-dropdown pages"
                              disableRipple
                              (selectionChange)="checkFilters(); getConversationListByPage()"
                              [(ngModel)]="filters.page"
                              #pageId
                              name="pageName">
                    <mat-option *ngFor="let select of pages" [value]="select">
                      {{ select.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="select-holder multy-check"
                 (click)="functions.setDropDownPosition(salesChannels, 'salesChannels')"
                 #salesChannels [class.active]="channelListSelect?.panelOpen">
              <mat-form-field>
                <mat-select [placeholder]="textService.currentText.taggedWith"
                            shouldPlaceholderFloat="false"
                            panelClass="default-dropdown salesChannels"
                            multiple
                            disableRipple
                            (selectionChange)="checkFilters()"
                            #channelListSelect
                            [(value)]="filters.tw">
                  <mat-option *ngFor="let select of tags" [value]="select.name">
                    {{ select.name }}
                  </mat-option>
                  <div class="inner-select-box">
                    <button (click)="filters.tw = []; checkFilters()">Clear All</button>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="select-holder multy-check"
                 (click)="functions.setDropDownPosition(notTagetWith, 'notTagetWith')"
                 #notTagetWith [class.active]="channelListSelect?.panelOpen">
              <mat-form-field>
                <mat-select [placeholder]="textService.currentText.notTaggedWith"
                            shouldPlaceholderFloat="false"
                            panelClass="default-dropdown notTagetWith"
                            multiple
                            (selectionChange)="checkFilters()"
                            disableRipple
                            #channelListSelect
                            [(value)]="filters.ntw">
                  <mat-option *ngFor="let select of tags" [value]="select.name">
                    {{ select.name }}
                  </mat-option>
                  <div class="inner-select-box">
                    <button (click)="filters.ntw = []; checkFilters()">Clear All</button>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="select-holder">
              <div (click)="functions.setDropDownPosition(conversationFrame, 'conversation')"
                   #conversationFrame
                   [class.active]="conversationId.panelOpen"
                   class="select-wrapper">
                <mat-form-field>
                  <mat-select [placeholder]="textService.currentText.conversaion"
                              shouldPlaceholderFloat="false"
                              panelClass="default-dropdown conversation"
                              disableRipple
                              [(ngModel)]="filters.conversation"
                              #conversationId
                              name="days">
                    <mat-option *ngFor="let select of conversationList" [value]="select">
                      {{ select.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="select-holder">
              <mat-form-field>
                <input matInput
                       [placeholder]="textService.currentText.name"
                       [(ngModel)]="filters.name" name="name"
                       (keyup)="checkFilters()">
              </mat-form-field>
              <div class="clear-input"
                   *ngIf="filters.name"
                   (click)="filters.name = '';
                   checkFilters()"></div>
            </div>
            <button mat-button
                    mat-raised-button
                    (click)="clearFilter()"
                    class="default-btn clear-btn"
                    type="button">
              {{textService.currentText.clearAll}}
            </button>
          </form>
        </div>
        <div class="b-side">
          <div class="loader-block" *ngIf="!isBroadcastButton">
            <img src="assets/icons/mini-load.gif" alt="">
          </div>
          <div class="refresh-btn loader-block">
            <button mat-button mat-raised-button
                    (click)="clearFilter(); getSubscribers();"
                    class="default-btn clear-btn">
              <img class="refresh-icon" src="../../../../../assets/icons/refresh.svg">
              <span class="refresh-text">{{textService.currentText.refresh}}</span>

            </button>
          </div>
          <button mat-button mat-raised-button
                  *ngIf="isBroadcastButton"
                  (click)="startBroadcast();"

                  class="default-btn clear-btn">
            {{textService.currentText.broadcast}}
          </button>
          <button mat-button mat-raised-button
                  *ngIf="!isBroadcastButton"
                  (click)="interrupt(); "
                  class="default-btn clear-btn">
            {{textService.currentText.interrupt}}
          </button>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header subscribers-header" [class.full-width]="subscribersListPaginator?.length < 5">
            <div class="tr">
              <div class="td"></div>
              <div class="td"><mat-checkbox disableRipple [(ngModel)]="checkAllSubscribers" (change)="checkAll()" >
                <img  src="../../../../../assets/icons/trigger-lightning.svg" alt="">
              </mat-checkbox></div>
              <div class="td">{{textService.currentText.photo}}</div>
              <div class="td" (click)="functions.sortColumns(subscribers, 'firstName'); getUpdatePaginator(0)">{{textService.currentText.name}}</div>
              <div class="td" (click)="functions.sortColumns(subscribers, 'pageName'); getUpdatePaginator(0)">{{textService.currentText.page}}</div>
              <div class="td" (click)="functions.sortColumns(subscribers, 'tags'); getUpdatePaginator(0)">{{textService.currentText.tag}}</div>
            </div>
          </div>
        </div>
        <div class="table-holder subscribers-holder">
          <div class="table subscribers-table" >
            <div class="tr" *ngFor="let subscriber of subscribersListPaginator; let k = index;"
                      [class.checked]="subscriber.checked">
              <div class="td">
                <button mat-button [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"></button>
                <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                  <button mat-menu-item (click)="deleteSubscriber(subscriber)">
                    <div class="list-inner-container">
                      <div class="menu-icon unsub-user-icon"></div>
                      {{textService.currentText.unsubscribeTheUser}}
                    </div>
                  </button>
                  <button mat-menu-item
                         (click)="openManageTagsDialog(subscriber)">
                    <div class="list-inner-container">
                      <div class="menu-icon icon-edit"></div>
                      {{textService.currentText.manageTags}}
                    </div>
                  </button>
                </mat-menu>
              </div>
              <div class="td"><mat-checkbox disableRipple [(ngModel)]="subscriber.checked">
                <img *ngIf="subscriber.checked" src="../../../../../assets/icons/trigger-lightning-active.svg" alt="">
                <img *ngIf="!subscriber.checked" src="../../../../../assets/icons/trigger-lightning.svg" alt="">
              </mat-checkbox></div>
              <div class="td">
                <img [src]="subscriber.profileImage" alt="">
              </div>
              <div class="td">
                {{subscriber.firstName}} {{subscriber.lastName}}
              </div>
              <div class="td">
                {{subscriber.pageName}}
              </div>
              <div class="td">
                <ng-container *ngFor="let tag of subscriber.tags; let i = index">
                    <span *ngIf="i <= 1"> {{tag.name}},</span>
                </ng-container>
                <span class="more" *ngIf="subscriber.tags.length > 2" (click)="subscriber.showMoreTags =  !subscriber.showMoreTags">
                    {{subscriber.tags.length - 2}} {{textService.currentText.moreLowercase}}
                    <div class="tag-list"
                         [class.last]="k === subscribersListPaginator.length - 1"
                         *ngIf="subscriber.showMoreTags">
                      <ng-container *ngFor="let tag of subscriber.tags; let j = index">
                          <div class="tag-item" *ngIf="j > 1">
                            {{tag.name}}
                          </div>
                      </ng-container>
                    </div>
                </span>

              </div>
            </div>
          </div>
        </div>
            <div class="tr" *ngIf="subscribers?.length == 0">
              <div class="td not-found">
                {{textService.currentText.noDataFound}}.
              </div>
            </div>
      </div>
    </div>
    <ng-container *ngIf="subscribers?.length">
    <mat-paginator [length]="subscribers?.length"
        [pageSize]="pageSize"
        (page)="getUpdatePaginator($event.pageIndex)">
    </mat-paginator>
    </ng-container>
  </div>
</div>

<ninja-notification [active]="userService.showNotification"
                    [message]="userService.notificationMessage"
                    [title]="userService.notificationTitle"
></ninja-notification>
