<div class="main-dialog-container terms" *ngIf="data.dialogFor === 'login-to-posting'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container">
    <div class="header">
      <h2>{{textService.currentText.TermsConditions}}</h2>
    </div>
    <div class="dialog-content text-left">

      <div class="dialog-message">
        <p>
          {{textService.currentText.TCText1}}
        </p>
        <h3>{{textService.currentText.TCTitle2}}</h3>
        <p>
          {{textService.currentText.TCText2}}
        </p>
        <h3>{{textService.currentText.TCTitle3}}</h3>
        <p>
          {{textService.currentText.TCText3}}
        </p>
        <h3>{{textService.currentText.TCTitle4}}</h3>
        <p>
          {{textService.currentText.TCText4}}
        </p>
        <h3>{{textService.currentText.TCTitle5}}</h3>
        <p>
          {{textService.currentText.TCText5}}
        </p>
        <h3>{{textService.currentText.TCTitle6}}</h3>
        <p>
          {{textService.currentText.TCText6}}
          <a href="http://www.amazon.com/gp/help/customer/display.html?nodeId=200441880">
            {{textService.currentText.TCAmazPolGuide}}
          </a>
          {{textService.currentText.TCText7}}
        </p>
        <p>
          {{textService.currentText.TCText8}}
        </p>
        <p>
          {{textService.currentText.TCText9}}
        </p>
        <h3>{{textService.currentText.TCTitle7}}</h3>
        <p>
          {{textService.currentText.TCText10}}
        </p>
        <p>
          {{textService.currentText.TCText11}}
        </p>
        <h3>{{textService.currentText.TCTitle8}}</h3>
        <p>
          {{textService.currentText.TCText12}}
        </p>
        <h3>{{textService.currentText.TCTitle9}}</h3>
        <p>
          {{textService.currentText.TCText13}}
        </p>
        <h3>{{textService.currentText.TCTitle10}}</h3>
        <p>
          {{textService.currentText.TCText14}}
        </p>
        <h3>{{textService.currentText.TCTitle11}}</h3>
        <p>
          {{textService.currentText.TCText15}}
        </p>
        <h3>{{textService.currentText.TCTitle12}}</h3>
        <p>
          {{textService.currentText.TCText16}}
        </p>
        <h3>{{textService.currentText.TCTitle13}}</h3>
        <p>
          {{textService.currentText.TCText17}}
        </p>
        <h3>{{textService.currentText.TCTitle14}}</h3>
        <p>
          {{textService.currentText.TCText18}}
        </p>
        <h3>{{textService.currentText.TCTitle15}}</h3>
        <p class="last">
          {{textService.currentText.TCText19}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn" (click)="dialogRef.close(true)">{{textService.currentText.iAgree}}</button>
  </div>
</div>


<div class="main-dialog-container terms" *ngIf="data.dialogFor === 'privacy-policy'">
  <div class="close-btn" (click)="dialogRef.close(false)"></div>
  <div class="inner-dialog-container">
    <div class="header">
      <h2>{{textService.currentText.privacyPolicy}}</h2>
    </div>
    <div class="dialog-content text-left">

      <div class="dialog-message">
        <p>
          {{textService.currentText.PPText1}}
          <a href="www.quantifyninja.com">{{textService.currentText.ninjaWebsite}}</a>
          {{textService.currentText.PPText2}}
          <br>
          {{textService.currentText.PPText3}}
            <br>
          {{textService.currentText.PPText4}}
        </p>
        <h3>{{textService.currentText.PPTitle1}}</h3>
        <p>
          {{textService.currentText.PPText5}}
          <br>
          {{textService.currentText.PPText6}}
            <br> {{textService.currentText.PPText7}}
            <br> {{textService.currentText.PPText8}}
            <br> {{textService.currentText.PPText9}}
            <br> {{textService.currentText.PPText10}}
        </p>
        <h3>{{textService.currentText.PPTitle2}}</h3>
        <p>
          {{textService.currentText.PPText11}}
          <br>
          {{textService.currentText.PPText12}}
            <br>
          {{textService.currentText.PPText13}}
        </p>
        <h3>{{textService.currentText.PPTitle3}}</h3>
        <p>
          {{textService.currentText.PPText14}}
          <br>
          {{textService.currentText.PPText15}}

        </p>
        <h3>{{textService.currentText.PPTitle4}}</h3>
        <p>
          {{textService.currentText.PPText16}}
        </p>
        <h3>{{textService.currentText.PPTitle5}}</h3>
        <p>
          {{textService.currentText.PPText17}}
        </p>
        <h3>{{textService.currentText.PPTitle6}}</h3>
        <p>
          {{textService.currentText.PPText18}}
          <br>
          {{textService.currentText.PPText19}}
            <br>
          {{textService.currentText.PPText20}}
        </p>
        <h3>{{textService.currentText.PPTitle7}}</h3>
        <p>
          {{textService.currentText.PPText21}}
        </p>
        <h3>{{textService.currentText.PPTitle8}}</h3>
        <p>
          {{textService.currentText.PPText22}}
            <br>
          {{textService.currentText.PPText23}}
        </p>
        <h3>{{textService.currentText.PPTitle9}}</h3>
        <p>
          {{textService.currentText.PPText24}}
        </p>
        <h3>{{textService.currentText.PPTitle10}}</h3>
        <p>
          {{textService.currentText.PPText25}}
          <br>
          {{textService.currentText.PPText26}}
          <a href="mailto:support@quantifyninja.com">{{textService.currentText.supportEmail}}</a>
          {{textService.currentText.PPText27}}
        </p>
        <h3>{{textService.currentText.PPTitle11}}</h3>
        <p>
          {{textService.currentText.PPText28}}
        </p>
        <h3>{{textService.currentText.PPTitle12}}</h3>
        <p>
          {{textService.currentText.PPText29}}
        </p>
        <h3>
          {{textService.currentText.contactUs}}
        </h3>
        <p class="last">
          {{textService.currentText.PPText30}}
          <a href="mailto:help@quantifyninja.com">{{textService.currentText.helpEmail}}</a>
          {{textService.currentText.PPText31}}
          <br>
          {{textService.currentText.PPText32}}
        </p>
      </div>
    </div>
  </div>
  <div class="dialog-footer single-btn">
    <button mat-button class="primary-btn" (click)="dialogRef.close(true)">{{textService.currentText.iAgree}}</button>
  </div>
</div>
