import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AccountSettings} from '../../../services/account-settings';
import {UserService} from '../../../services/user.service';
import {TextService} from '../../../services/text.service';


@Component({
  selector: 'ninja-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})

export class UserSettingsComponent {
  public isLastRoute = true;

  constructor(public accountSettings: AccountSettings,
              public router: Router,
              public textService: TextService,
              public userService: UserService)  {
  }

}
