<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="head-container">
            <h2>{{textService.currentText.hjTitle}}</h2>
          </div>
          <div class="sub-head-container">
            <p>
              <span class="action-el" [routerLink]="'../facebook-notifications'">
                {{textService.currentText.clickHere}}</span> {{textService.currentText.hjSubTitle}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-container d-flex flex-column">
    <h3 class="font-size-18 mr-3">{{textService.currentText.hijackedListings}}</h3>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header">
            <div class="td" (click)="functions.sortColumns(hjList, 'status'); getUpdatePaginator(0)">
              {{textService.currentText.status}}</div>
            <div class="td" (click)="functions.sortColumns(hjList, 'shortName'); getUpdatePaginator(0)">
              {{textService.currentText.productName}}</div>
            <div class="td" (click)="functions.sortColumns(hjList, 'SKU'); getUpdatePaginator(0)">
              {{textService.currentText.SKU}}</div>
            <div class="td" (click)="functions.sortColumns(hjList, 'offer'); getUpdatePaginator(0)">
              {{textService.currentText.condition}}</div>
            <div class="td text-center" (click)="functions.sortColumns(hjList, 'channel'); getUpdatePaginator(0)">
              {{textService.currentText.salesChannel}}</div>
            <div class="td" (click)="functions.sortColumns(hjList, 'newOffers'); getUpdatePaginator(0)">
              {{textService.currentText.newOffers}}</div>
            <div class="td" (click)="functions.sortColumns(hjList, 'usedOffers'); getUpdatePaginator(0)">
              {{textService.currentText.usedOffers}}</div>
            <div class="td">{{textService.currentText.hjViewOnAmazon}}</div>
          </div>
        </div>
        <div class="table-holder">
          <div class="table">
            <div class="tr" *ngFor="let hj of hjListPaginator">
              <div class="td">
                <img src="../../../../assets/images/active.png" alt="" *ngIf="hj.status === 'Green'">
                <img src="../../../../assets/icons/alert-icon.svg" alt="" *ngIf="hj.status === 'Yellow'">
                <img src="../../../../assets/images/InActive.png" alt="" *ngIf="hj.status === 'Red'">
              </div>
              <div class="td">
                {{hj.shortName}}
              </div>
              <div class="td">
                {{hj.SKU}}
              </div>
              <div class="td">
                {{hj.offer === 'NONE' ? 'N/A' : hj.offer}}
              </div>
              <div class="td">
                <img style="display: inline-block;" src="{{hj.channel}}" width="40" height="40" />
              </div>
              <div class="td">
                {{hj.newOffers}}
              </div>
              <div class="td">
                {{hj.usedOffers}}
              </div>
              <div class="td">
                <a (click)="mixpanelService.track('q hijacked - view on amazon', {'status': hj.status});googleAnalyticsEventsService.emitEvent('Button', 'HJ view on Amazon', 'Click', 1)" [href]="hj.amazonLink" target='_blank'
                  class="d-flex align-items-center">
                  View On Amazon
                  <img class="ml-1" src="../../../../assets/images/link.png" alt="Link">
                </a>
              </div>
            </div>
            <div class="tr" *ngIf="hjList?.length === 0">
              <div class="td not-found">
                {{textService.currentText.noDataFound}}.
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-paginator [length]="hjList?.length" [pageSize]="pageSize" (page)="getUpdatePaginator($event.pageIndex)">
      </mat-paginator>
    </div>
  </div>
</div>