<div class="main-dialog-container edit-dialog">
  <div class="close-btn" (click)="cancel()"></div>
  <div class="inner-dialog-container confirmation-dialog">
    <app-product-cost *ngIf="products" [products]="products" [productsListPaginator]="productsListPaginator" [updatedProductsListPaginator]="updatedProductsListPaginator"></app-product-cost>
  </div>
  <!-- <div class="dialog-footer justify-content-center">
    <button mat-button class="secondary-btn btn-fill mr-5" (click)="submit()">{{textService.currentText.save}}</button>
    <button mat-button class="secondary-btn btn-fill cancel"
      (click)="dialogRef.close(false)">{{textService.currentText.cancel}}</button>
  </div> -->
</div>